import useFormIntegration from "kemis-library/hooks/form";
import { Calendar } from 'primereact/calendar';
import { InputNumber } from "primereact/inputnumber";
import { useCallback, useEffect, useState } from "react";
import { Controller, FieldErrors } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Zod from "zod";

// Icons
import localAtmIcon from "~/assets/icons/local-atm.svg";
import personIcon from '~/assets/icons/person.svg';
import realStateAgentIcon from "~/assets/icons/real-state-agent.svg";

// Types
import { IBatchesProps, IParams } from "./types";

// Components
import UserDefault from "~/components/UserDefault";

// Services
import api from "~/services/api";

// Utils
import { STerrainProporsals } from "../utils/schema";

// Models
import { IMRealStateProduct } from "~/models/RealEstateProduct";
import { IMTerrainProposl } from "~/models/TerrainProposals";

// Hooks
import { useAuthContext } from "~/hooks/contexts/Auth";

// Partials
import { MessageError } from "./Partials/MessageError";

export function Proposal({ }: IBatchesProps) {
    const { user } = useAuthContext()
    const { id: projectID, terrainID } = useParams<IParams>();
    const [realEstateProduct, setRealEstateProduct] = useState<IMRealStateProduct | null>(null);
    const [batchesOnLoading, setBachesOnLoadig] = useState(false)
    const [maritalStatus, setMaritalStatus] = useState<{
        id: number
        marital_status: string
    }[]>([])
    const { register, handleSubmit, clearErrors, setError, formState, control, getValues } = useFormIntegration<Zod.infer<typeof STerrainProporsals>>({
    }, STerrainProporsals)

    useEffect(() => {
        api.get(`builders/marital-statuses`).then(resolver => {
            if (resolver.status !== 200) return

            setMaritalStatus(resolver.data)
        })
            .catch(_rejects => ({}))
    }, [])

    // RECOVERY REP
    useEffect(() => {
        if (!projectID) return;

        api.get<IMRealStateProduct>(`builders/real-estate-products/${projectID}`).then((resolver) => {
            if (resolver.status !== 200) return;

            setRealEstateProduct(resolver.data);
        })
            .catch((rejects) => {

            });
    }, [projectID]);

     // TODO - USE OF USECALLBACK FOR PATTERN PURPOSES
    const handleDownloadProposal = useCallback(async (projectId:number ,terrainId: number) => {
      try {
        const response = await api.get(
          `builders/real-estate-products/${projectId}/terrains/${terrainId}/proposals/generate`,
          {
            responseType: 'blob',
          }
        );
        const urlData = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlData;
        link.download = 'Proposta.docx';
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urlData);
          link.remove();
        }, 100);
      } catch (error) {
        await Swal.fire(
          'Opss...',
          'Ocorreu um erro, arquivo não encontrado.',
          'error'
        );
      }
    }, []);

    async function onSubmit(data: Partial<IMTerrainProposl>) {
        try {
            setBachesOnLoadig(true)


            if (!data || !terrainID || !projectID) throw new Error('')

            const batcheProporsalsResponse = await api.post(`builders/real-estate-products/${projectID}/terrains/${terrainID}/proposals`,
                {
                    ...data,
                    proponent_marital_status_id: data.proponent_marital_status,
                    owner_marital_status_id: data.owner_marital_status,
                    proponent_nid_number: `${data.proponent_nid_number}`,
                    owner_nid_number: `${data.owner_nid_number}`,
                    payment_method: `${data.payment_method}`,
                    terrain_id: terrainID
                }
            )

            if (batcheProporsalsResponse.status !== 201) throw new Error('')

            Swal.fire({
                icon: 'success',
                text: 'Proposta cadrastada com sucesso, aguarde para salvar o seu arquivo de proposta',
            })

            handleDownloadProposal( Number(projectID), Number(terrainID));
            setBachesOnLoadig(false)
        } catch (error) {
            setBachesOnLoadig(false)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel salvar a proposta'
            })
        }
    }

    async function onSubmitError(error: FieldErrors) {
        try {
            console.table(error)
            console.table(getValues())
        } catch (error) {
            // do anything
        }
    }

    return (

        <div className="h-auto flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t  max-sm:!p-[none] mb-20">
            <UserDefault home />

            <span className="text-[#707070] font-[400] mb-[1.35rem] max-sm:self-center">Preencha as seguintes informações</span>

            <div className="w-full h-auto flex flex-col md:items-center md:justify-center gap-[2.5rem] max-sm:mx-auto">
                <form className="flex max-sm:flex-col
                    md:flex-row md:flex-wrap
                    max-sm:gap-[2.187rem] md:gap-x-[9.375rem]
                    md:gap-y-[5rem]
                    max-sm:w-full md:w-[61.75rem]
                    md:min-h-[32.812rem] md:h-auto
                    max-sm:min-h-[32.812rem] max-sm:h-auto
                    max-sm:border-[0.1rem] max-sm:border-gray-200
                    max-sm:pt-[1rem] max-sm:pb-[1rem]
                    bg-white shadow-md
                    rounded-[0.937rem] md:p-[2.5rem]"
                    onSubmit={handleSubmit(onSubmit, onSubmitError)}
                    id="batches-form"
                    name="batches-form"
                >
                    <div className="max-sm:w-full md:flex-1 md:min-w-[14.312rem] md:max-w-[50%] flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem] gap-[1.5rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.395rem] ">
                            <span className="w-[3.125rem] h-[3.125rem] object-cover rounded-full bg-[#39A887] flex flex-row items-center justify-center">
                                <img src={personIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Proponente </span>
                        </div>
                        <div className="max-sm:w-full flex flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Nome</span>
                                <div className="w-full">
                                    <input
                                        {...register("proponent_name")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Ex: Cherry"
                                        defaultValue={user?.engenheiro?.nome || ''}
                                    />
                                </div>
                                {
                                    formState.errors.proponent_name && (
                                        <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.proponent_name.message}
                                        </span>
                                    )
                                }
                            </div>
                            <div className="flex flex-col
                                gap-[0.562rem] flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Documento</span>
                                <div className="w-full">
                                    <input
                                        {...register("proponent_document")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite seu documento"
                                        defaultValue={user?.engenheiro?.cpf || ''}
                                    />
                                </div>
                                {
                                    formState.errors.proponent_document && (
                                        <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.proponent_document.message}
                                        </span>
                                    )
                                }
                            </div>
                            {/* <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] -w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Nacionalidade</span>
                                <div className="w-full">
                                    <Controller name="proponent_nationality" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <select
                                                className="w-full pl-[0.558rem]
                                                    border-none bg-none
                                                    font-[400] leading-[1.375rem]
                                                    tracking-[-0.025rem] text-[1.062rem]
                                                    text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Selecione uma opção"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || 0}
                                                onChange={(event) => field.onChange(event.target.selectedOptions[0].text || '')}
                                            >
                                                <option value="">Selecione uma opção</option>
                                                <option value="brasil">Brasil</option>
                                                <option value="eua">Estados Unidos da America</option>
                                            </select>
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div> */}
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Profissão</span>
                                <div className="w-full">
                                    <input
                                        {...register("proponent_profession")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Exemplo: Contador"
                                        defaultValue={user?.engenheiro?.profession || ''}
                                    />
                                </div>
                                {
                                    formState.errors.proponent_profession && (
                                        <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.proponent_profession.message}
                                        </span>
                                    )
                                }
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Estado civil</span>
                                <div className="w-full">
                                    <Controller name="proponent_marital_status" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <select
                                                className="w-full pl-[0.558rem]
                                                    border-none bg-none
                                                    font-[400] leading-[1.375rem]
                                                    tracking-[-0.025rem] text-[1.062rem]
                                                    text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Selecione uma opção"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || 0}
                                                onChange={(event) => field.onChange(event.target.selectedOptions[0].value || '')}
                                            >
                                                <option value="">Selecione uma opção</option>
                                                {
                                                    maritalStatus.map(mStatus => (
                                                        <option
                                                            value={mStatus.id}
                                                            key={mStatus.id}
                                                            selected={Number(field.value) === mStatus.id && true}
                                                        >
                                                            {mStatus.marital_status}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">identidade</span>
                                <div className="w-full">
                                    <Controller name="proponent_nid_number" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                type="text"
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Digite o número aqui"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                defaultValue={field.value as unknown as number || undefined}
                                                maxLength={3}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Emissor da identidade</span>
                                <div className="w-full">
                                    <input
                                        {...register("proponent_nid_issuer")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Exemplo: Contador" />
                                    {
                                        formState.errors.proponent_nid_issuer && (
                                            <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.proponent_nid_issuer.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Data de emissão da identidade</span>
                                <div className="w-full">
                                    <Controller name="proponent_nid_issuing_state" control={control} render={({ fieldState, field }) => (
                                        <>
                                        {console.log(field.value)}
                                            <Calendar
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="DD/MM/AAAA"
                                                 {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                mask="99/99/9999"
                                                ref={field.ref}
                                               //value={field.value as unknown as Date || 0}
                                                onChange={(event) => field.onChange(event.value)}
                                                dateFormat="dd/mm/yy"
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-sm:w-full md:flex-1 md:min-w-[14.312rem] md:max-w-[50%] flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem] gap-[1.5rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.395rem] ">
                            <span className="w-[3.125rem] h-[3.125rem] object-cover rounded-full bg-[#39A887] flex flex-row items-center justify-center">
                                <img src={personIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Propietário </span>
                        </div>
                        <div className="max-sm:w-full flex flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Nome</span>
                                <div className="w-full">
                                    <input
                                        {...register("owner_name")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Ex: Cherry" />
                                </div>
                                {
                                    formState.errors.owner_name && (
                                        <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.owner_name.message}
                                        </span>
                                    )
                                }
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Documento</span>
                                <div className="w-full">
                                    <input
                                        {...register("owner_document")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite seu documento aqui" />
                                </div>
                                {
                                    formState.errors.owner_document && (
                                        <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.owner_document.message}
                                        </span>
                                    )
                                }
                            </div>
                            {/* <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] -w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Nacionalidade</span>
                                <div className="w-full">
                                    <Controller name="owner_nationality" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <select
                                                className="w-full pl-[0.558rem]
                                                    border-none bg-none
                                                    font-[400] leading-[1.375rem]
                                                    tracking-[-0.025rem] text-[1.062rem]
                                                    text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Selecione uma opção"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || 0}
                                                onChange={(event) => field.onChange(event.target.selectedOptions[0].text || '')}
                                            >
                                                <option value="">Selecione uma opção</option>
                                                <option value="brasil">Brasil</option>
                                                <option value="eua">Estados Unidos da America</option>
                                            </select>
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div> */}
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Profissão</span>
                                <div className="w-full">
                                    <input
                                        {...register("owner_profession")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Exemplo: Contador" />

                                </div>
                                {
                                    formState.errors.owner_profession && (
                                        <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.owner_profession.message}
                                        </span>
                                    )
                                }
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Estado civil</span>
                                <div className="w-full">
                                    <Controller name="owner_marital_status" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <select
                                                className="w-full pl-[0.558rem]
                                                    border-none bg-none
                                                    font-[400] leading-[1.375rem]
                                                    tracking-[-0.025rem] text-[1.062rem]
                                                    text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Selecione uma opção"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || 0}
                                                onChange={(event) => field.onChange(event.target.selectedOptions[0].value || '')}
                                            >
                                                <option value="">Selecione uma opção</option>
                                                {
                                                    maritalStatus.map(mStatus => (
                                                        <option
                                                            value={mStatus.id}
                                                            key={mStatus.id}
                                                            selected={Number(field.value) === mStatus.id && true}
                                                        >
                                                            {mStatus.marital_status}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">identidade</span>
                                <div className="w-full">
                                    <Controller name="owner_nid_number" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Digite o número aqui"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                maxLength={3}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Emissor da identidade</span>
                                <div className="w-full">
                                    <input
                                        {...register("owner_nid_issuer")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Exemplo: Contador" />
                                    {
                                        formState.errors.owner_nid_issuer && (
                                            <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.owner_nid_issuer.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Data de emissão da identidade</span>
                                <div className="w-full">
                                    <Controller name="owner_nid_issuing_state" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <Calendar
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="DD/MM/AAAA"
                                                 {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                mask="99/99/9999"
                                                ref={field.ref}
                                                //value={field.value as unknown as Date || 0}
                                                onChange={(event) => field.onChange(event.value)}
                                                dateFormat="dd/mm/yy"

                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="max-sm:w-full md:flex-1 md:min-w-[14.312rem] md:max-w-[50%] flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem] gap-[1.5rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.395rem] ">
                            <span className="w-[3.125rem] h-[3.125rem] object-cover rounded-full bg-[#39A887] flex flex-row items-center justify-center">
                                <img src={realStateAgentIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Propriedade </span>
                        </div>
                        <div className="max-sm:w-full flex flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Rua/Av.</span>
                                <div className="w-full">
                                    <input
                                        {...register("address")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite o endereço aqui"
                                        defaultValue={realEstateProduct && realEstateProduct.localization || ''}
                                    />

                                    {
                                        formState.errors.address && (
                                            <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.address.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Bairro</span>
                                <div className="w-full">
                                    <input
                                        {...register("neighborhood")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite o bairro aqui"
                                    />
                                    {
                                        formState.errors.neighborhood && (
                                            <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.neighborhood.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Cidade</span>
                                <div className="w-full">
                                    <input
                                        {...register("city")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite cidade/estado aqui" />

                                    {
                                        formState.errors.city && (
                                            <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.city.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Estado</span>
                                <div className="w-full">
                                    <input
                                        {...register("state")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite cidade/estado aqui" />

                                    {
                                        formState.errors.state && (
                                            <span className="font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                                {formState.errors.state.message}
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                w-full h-[4.25rem]
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">CEP</span>
                                <div className="w-full">
                                    <input
                                        {...register("zip_code")}
                                        type="text"
                                        className="w-full pl-[0.558rem] border-none
                                        bg-none font-[400] leading-[1.375rem]
                                        tracking-[-0.025rem] text-[1.062rem]
                                        text-[#414142]  placeholder:text-[0.9rem] h-full"
                                        placeholder="Digite o CEP aqui" />

                                </div>
                                {
                                    formState.errors.zip_code && (
                                        <span className="mt-3 font-semibold text-red-500 text-sm flex flex-row items-center justify-between">
                                            {formState.errors.zip_code.message}
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="max-sm:w-full md:flex-1 md:min-w-[14.312rem] md:max-w-[50%] flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem] gap-[1.5rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.395rem] ">
                            <span className="w-[3.125rem] h-[3.125rem] object-cover rounded-full bg-[#39A887] flex flex-row items-center justify-center">
                                <img src={localAtmIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Condições </span>
                        </div>
                        <div className="max-sm:w-full flex flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Total da proposta</span>
                                <div className="w-full">
                                    <Controller name="total" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0] !pb-[0]"
                                                placeholder="R$ 20,00"
                                                mode="currency"
                                                locale="pt-BR"
                                                currency="BRL"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}

                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Em dinheiro</span>
                                <div className="w-full">
                                    <Controller name="money" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0] !pb-[0]"
                                                placeholder="R$ 10,00"
                                                mode="currency"
                                                locale="pt-BR"
                                                currency="BRL"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem]
                                flex-1
                                h-[4.25rem] w-full
                                pb-[0.631rem]
                                border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Forma de pagamento do restante</span>
                                <div className="w-full">
                                    <Controller name="payment_method" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem]
                                                tracking-[-0.025rem] text-[1.062rem]
                                                text-[#414142]  placeholder:text-[0.9rem] h-auto !pt-[0] !pb-[0]"
                                                placeholder="Exemplo: 6 parcelas"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                maxLength={3}
                                                max={512}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <span className="max-sm:w-full md:w-[54.937rem] flex flex-1 flex-row items-center md:justify-end  max-sm:justify-center max-sm:mb-4">
                    <button
                        title="Salvar Lote"
                        type="submit"
                        disabled={batchesOnLoading}
                        form="batches-form"
                        className="w-[20.687rem] h-[3.375rem] rounded-[0.937rem]
                        flex flex-row items-center justify-center gap-[1rem] text-white font-bold
                        bg-[#39A887] hover:opacity-80
                        transition-all duration-[0.3s] disabled:cursor-not-allowed">
                        {
                            batchesOnLoading && (
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            )
                        }
                        Salvar
                    </button>
                </span>
            </div>
        </div>
    )
}
