/* FIXEM - eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BiPlus, BiUndo } from 'react-icons/bi';
import { BsFilter } from 'react-icons/bs';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { HiOutlineSearch } from 'react-icons/hi';
import { IoTriangleSharp } from 'react-icons/io5';
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from 'react-icons/md';

import {
  Body,
  Button,
  Container,
  FilterBox,
  FilterGroup,
  Header,
  Input,
  Loading,
  NoData,
  Pagination,
  Row,
  TableContent,
  Title,
} from './styles';

interface IData {
  [key: string]: any;
}

export interface IColumn<T = any> {
  id?: string | number;
  name: string | number | React.ReactNode;
  selector?: string | ((row: T, rowIndex: number) => React.ReactNode);
  sortable?: boolean;
  order?: boolean;
  cell?: (row: T, rowIndex: number) => React.ReactNode;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  right?: boolean;
  center?: boolean;
  ignoreRowClick?: boolean;
}

interface ITableProps {
  title?: string;
  addButton?: boolean;
  backButton?: boolean;
  columns: IColumn[];
  data: IData[];
  searchable?: boolean;
  pagination?: boolean;
  selectedPage?: number;
  totalData?: number;
  fromData?: number;
  toData?: number;
  className?: string;
  showFilter?: boolean;
  filters?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  exportToPdf?: boolean;
  exportToExcel?: boolean;
  take?: number;
  onChangeOrder?(column: string, order: 'ASC' | 'DESC'): void;
  onFilterClick?(): void;
  onClickAdd?(): void;
  onClickBack?(): void;
  onRowClicked?(e: IData): void;
  onRowDoubleClick?(e: IData): void;
  onSearch?(e: string): void;
  onChangePage?(e: number): void;
}

const Table: React.FC<ITableProps> = ({
  title,
  addButton,
  backButton,
  columns,
  data,
  searchable,
  pagination,
  selectedPage,
  totalData,
  fromData,
  toData,
  className,
  showFilter,
  filters,
  loading,
  exportToPdf,
  exportToExcel,
  take,
  onChangeOrder,
  onFilterClick,
  onClickAdd,
  onClickBack,
  onRowClicked,
  onRowDoubleClick,
  onSearch,
  onChangePage,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [pages, setPages] = useState<number[]>([]);
  const [columnsData, setColumnsData] = useState<IColumn[]>([]);
  const [firstButtonDisabled, setFirstButtonDisabled] = useState(true);
  const [lastButtonDisabled, setLastButtonDisabled] = useState(true);
  const [noDataWidth, setNoDataWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', (e) => {
        const element = e.target as HTMLDivElement;
        setScrollLeft(element.scrollLeft);
      });
      setNoDataWidth(contentRef.current.clientWidth);
    }
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  useEffect(() => {
    setColumnsData(columns);
  }, [columns]);

  const totalPages = useMemo(() => {
    if (totalData) {
      const pagesData = take
        ? Math.ceil(totalData / take)
        : Math.ceil(totalData / 3);
      return pagesData;
    }

    return 1;
  }, [take, totalData]);

  useEffect(() => {
    const pagesArr: number[] = [];
    for (let i = 1; i <= totalPages; i += 1) {
      pagesArr.push(i);
    }
    setPages(pagesArr);
    setLastButtonDisabled(totalPages === 1 || selectedPage === totalPages);
  }, [lastButtonDisabled, selectedPage, totalPages]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (onSearch) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  const handleClickRow = useCallback(
    (row) => {
      if (onRowClicked) {
        onRowClicked(row);
      }
    },
    [onRowClicked]
  );

  const handleDoubleClickRow = useCallback(
    (row) => {
      if (onRowDoubleClick) {
        onRowDoubleClick(row);
      }
    },
    [onRowDoubleClick]
  );

  const orderColumn = useCallback(
    (column, order) => {
      data.sort((a, b) => {
        if (!order) {
          if (a[column] > b[column]) {
            return -1;
          }
          if (a[column] < b[column]) {
            return 1;
          }
          return 0;
        }

        if (a[column] < b[column]) {
          return -1;
        }
        if (a[column] > b[column]) {
          return 1;
        }
        return 0;
      });
    },
    [data]
  );

  const handleSort = useCallback(
    (column) => {
      if (column.sortable) {
        const newColumnsData = columnsData.map((columnData) => {
          const newColumnData = columnData;
          if (columnData.selector === column.selector) {
            newColumnData.order = !column.order;
          } else {
            newColumnData.order = false;
          }

          return newColumnData;
        });
        if (onChangeOrder) {
          onChangeOrder(column.selector, !column.order ? 'ASC' : 'DESC');
        } else {
          orderColumn(column.selector, !column.order);
        }
        setColumnsData(newColumnsData);
      }
    },
    [columnsData, onChangeOrder, orderColumn]
  );

  const handleChangePage = useCallback(
    (page) => {
      if (onChangePage) {
        onChangePage(page);
      }
      if (page > 1 && page + 1 <= totalPages) {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(false);
      } else if (page === 1) {
        setFirstButtonDisabled(true);
        setLastButtonDisabled(false);
      } else if (page === totalPages) {
        setFirstButtonDisabled(false);
        setLastButtonDisabled(true);
      } else {
        setFirstButtonDisabled(true);
        setLastButtonDisabled(true);
      }
    },
    [onChangePage, totalPages]
  );

  const handleClickAdd = useCallback(() => {
    if (onClickAdd) {
      onClickAdd();
    }
  }, [onClickAdd]);

  const handleClickBack = useCallback(() => {
    if (onClickBack) {
      onClickBack();
    }
  }, [onClickBack]);

  const handleClickFilter = useCallback(() => {
    if (onFilterClick) {
      onFilterClick();
    }
  }, [onFilterClick]);

  return (
    <>
      <Container className={className}>
        {(title || addButton || backButton || searchable) && (
          <div
            className={`d-flex flex-wrap ${addButton ? 'justify-content-between' : 'justify-content-end'
              } align-items-center mb-5`}
          >
            {title && <h1 className="w-100 w-sm-auto mb-3 mb-sm-0">{title}</h1>}
            {addButton && backButton && (
              <div className="d-flex justify-content-left">
                <button
                  type="button"
                  className="add-button py-2 px-4 me-2 d-flex justify-content-center align-items-center"
                  onClick={handleClickAdd}
                >
                  <BiPlus size={20} color="#fff" /> Adicionar
                </button>
                <button
                  type="button"
                  className="back-button py-2 px-4 d-flex justify-content-center align-items-center"
                  onClick={handleClickBack}
                >
                  <BiUndo size={20} color="#777" /> Back
                </button>
              </div>
            )}
            {addButton && !backButton && (
              <div className="d-flex justify-content-left">
                <button
                  type="button"
                  className="add-button py-2 px-4 me-2 d-flex justify-content-center align-items-center"
                  onClick={handleClickAdd}
                >
                  <BiPlus size={20} color="#fff" /> Adicionar
                </button>
              </div>
            )}
            <div className="w-100 w-sm-auto d-flex align-items-center">
              {searchable && (
                <Input className="d-flex me-3" onChange={handleChange}>
                  <input type="text" placeholder="Buscar" />
                  <HiOutlineSearch size={24} color="#2A8BFD" />
                </Input>
              )}
              {onFilterClick && (
                <FilterGroup>
                  <button
                    type="button"
                    className="btn-action border-0"
                    onClick={handleClickFilter}
                  >
                    <BsFilter size={24} color="#414141" />
                  </button>
                  <FilterBox show={showFilter}>{filters}</FilterBox>
                </FilterGroup>
              )}
              {exportToPdf && (
                <button type="button" className="btn-action border-0 mx-2">
                  <FaFilePdf size={24} color="#FC5D4A" />
                </button>
              )}
              {exportToExcel && (
                <button type="button" className="btn-action border-0">
                  <FaFileExcel size={24} color="#6EB968" />
                </button>
              )}
            </div>
          </div>
        )}
        <TableContent>
          <div ref={contentRef} className="overflow-auto">
            <Header className="d-lg-block tb-header px-0">
              <div className="tb-row px-0 mx-0">
                {columnsData.map((column) => (
                  <Button
                    key={column.selector as React.Key}
                    type="button"
                    sorting={column.sortable}
                    onClick={() => handleSort(column)}
                    order={column.order}
                    disabled={!column.sortable}
                  >
                    {column.name} <IoTriangleSharp size={15} color="#7C7C7C" />
                  </Button>
                ))}
              </div>
            </Header>
            <Body className="tb-header-mobile tb-body">
              {data.length > 0 ? (
                <>
                  {data.map((rowData, index) => (
                    <Row
                      key={index.toString()}
                      className={`tb-row ${onRowClicked || onRowDoubleClick ? 'tb-row-hover' : ''
                        }`}
                      onClick={() => handleClickRow(rowData)}
                      onDoubleClick={() => handleDoubleClickRow(rowData)}
                    >
                      {columnsData.map((column) => (
                        <div key={column.selector as React.Key}>
                          <Title className="d-none d-lg-none">
                            {column.name}
                          </Title>
                          {column.cell ? (
                            <>{column.cell(rowData, index)}</>
                          ) : (
                            <>{rowData[column.selector as string]}</>
                          )}
                        </div>
                      ))}
                    </Row>
                  ))}
                </>
              ) : (
                <NoData width={noDataWidth} scrollLeft={scrollLeft}>
                  <p className="h4 text-center">Nenhum registro</p>
                </NoData>
              )}
            </Body>
          </div>
          <Loading active={loading}>
            <div className="loading-box d-flex flex-column align-items-center justify-content-center">
              <div className="spinner-border text-light" role="status" />
              <span className="d-block fw-bold">Carregando...</span>
            </div>
          </Loading>
        </TableContent>
        {pagination && (
          <Pagination className="justify-content-center justify-content-md-end pt-2 mt-4">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="w-100 w-sm-auto text-center">
                <small className="me-3 me-md-2">Procurar por página:</small>
                <select
                  onChange={(e) =>
                    handleChangePage(parseInt(e.target.value, 10))
                  }
                >
                  {pages.map((page) => (
                    <option key={page} value={page}>
                      {page}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-100 w-sm-auto d-flex justify-content-center mt-3 mt-sm-0">
                <small className="me-3 me-md-2">
                  {fromData}-{toData} de {totalData}
                </small>
                <div className="d-flex">
                  <button
                    type="button"
                    className="border-0 me-3"
                    disabled={firstButtonDisabled}
                    onClick={() =>
                      handleChangePage(selectedPage ? selectedPage - 1 : 0)
                    }
                  >
                    <MdOutlineArrowBackIos
                      size={15}
                      color={firstButtonDisabled ? '#aaaaaa' : '#676565'}
                    />
                  </button>
                  <button
                    type="button"
                    className="border-0 ms-3"
                    disabled={lastButtonDisabled}
                    onClick={() =>
                      handleChangePage(selectedPage ? selectedPage + 1 : 0)
                    }
                  >
                    <MdOutlineArrowForwardIos
                      size={15}
                      color={lastButtonDisabled ? '#aaaaaa' : '#676565'}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Pagination>
        )}
      </Container>
      {/* <Loading active={pdfInLoading} fixed>
        <div className="loading-box d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status" />
          <span className="d-block fw-bold">Gerando PDF</span>
        </div>
      </Loading> */}
    </>
  );
};

export default Table;
