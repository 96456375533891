import styled from 'styled-components';

export const Container = styled.section`
  .centrar {
    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
      font-style: normal;
    }
  }
  .content {
    display: flex;
    align-items: flex-end;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px; /* identical to box height */

    font-feature-settings: 'zero' on;

    color: #707070;
  }

  .project-name {
    display: flex;
    align-items: center;
    flex: none;
    flex-grow: 0;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px; /* identical to box height */

    color: #202020;

    @media (max-width: 1400px) {
      width: 200px;
    }

    @media (max-width: 1280px) {
      width: fit-content;
    }
  }

  .amount {
    display: flex;
    align-items: center;
    /* flex: none; */
    flex-grow: 1;
    text-align: right;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px; /* identical to box height */
    color: #202020;
  }
`;
