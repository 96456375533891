import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';

import { Container } from './styles';

const PerformanceComparator: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  const labels = useMemo(() => {
    const labelsData = [
      'Prestação do financiamento da construção',
      'Quanto custaria a prestação desse imóvel pronto',
      'Quanto custaria o aluguel desse imóvel pronto',
    ];
    return labelsData;
  }, []);

  const options = useMemo(
    () => ({
      options: {
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            borderRadius: 18,
          },
        },
        labels,
        colors: ['#AE2AFF', '#6DFCA6', '#168FFF'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enable: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: undefined,
            formatter: undefined,
          },
          y: {
            show: true,
            formatter(val: number) {
              return `${formatCurrency(val)}`;
            },
            title: {
              formatter: () => 'Valor:',
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          data: [
            serviceOrder.simulacao?.vlrParcInic || 0,
            (serviceOrder.simulacao?.vlrParcInic || 0) * 1.7,
            (serviceOrder.simulacao?.vlrParcInic || 0) * 1.1,
          ],
        },
      ],
    }),
    [labels, serviceOrder.simulacao?.vlrParcInic]
  );

  return (
    <Container className="container col-md-5 col-12 card-dashboard p-4">
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h2 className="h6 mb-0">Comparador de prestação</h2>
      </div>
      <Chart
        options={options.options}
        series={options.series}
        type="bar"
        className="m-4 mb-0"
        height={220}
      />

      <div className="ms-3">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot me-3 p-1" />
            <p className="mb-0 text-text" style={{ fontSize: '12px' }}>
              Prestação do financiamento da construção
            </p>
          </div>
          <p
            className="mb-0 s font-weight-700 "
            style={{ marginLeft: '40px', fontSize: '14px' }}
          >
            {formatCurrency(serviceOrder.simulacao?.vlrParcInic || 1)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-2 me-3 p-1" />
            <p className="mb-0 text-text" style={{ fontSize: '12px' }}>
              Quanto custaria a prestação desse imóvel pronto
            </p>
          </div>
          <p
            className="mb-0  s font-weight-700 "
            style={{ marginLeft: '40px', fontSize: '14px' }}
          >
            {formatCurrency((serviceOrder.simulacao?.vlrParcInic || 1) * 1.7)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-3 me-3 p-1" />
            <p className="mb-0 text-text" style={{ fontSize: '12px' }}>
              Quanto custaria o aluguel desse imóvel pronto
            </p>
          </div>
          <p
            className="mb-0 s font-weight-700"
            style={{ marginLeft: '40px', fontSize: '14px' }}
          >
            {formatCurrency((serviceOrder.simulacao?.vlrParcInic || 1) * 1.1)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PerformanceComparator;
