import React, { useCallback, useState } from 'react';

import { FiPaperclip } from 'react-icons/fi';
import { HiCamera } from 'react-icons/hi';
import Toast from '~/utils/toast';

import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';
import { Avatar } from './styles';

const AvatarEngineer: React.FC = () => {
  const { engineer, setLoading, setPhotoEngineer,photoEngineer } = useProfile();
  const [wallaperImg, setWallpaperImg] = useState<string | null>(null);

  const handleChangePhoto = useCallback(
    async (event) => {
      if(!event?.target?.files || event.target.files.length <= 0) return

      if (event.target.files[0].size < 2097152) {
        setLoading(true);
        try {
          const formImageAvatar = new FormData();
          formImageAvatar.append('file', event.target.files[0]);

          await api.post(
            `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/photo`,
            formImageAvatar
          );

          setPhotoEngineer(URL.createObjectURL(event.target.files[0]));
        } catch (err) {
          Toast.fire({
            icon: 'error',
            title: 'Ocorreu um erro ao alterar sua foto',
          });
        } finally {
          setLoading(false);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'O tamanho da imagem deve ser inferior a 2 megabytes',
        });
      }
    },
    [setLoading, setPhotoEngineer]
  );

  const handleSetWallpaper = useCallback(
    async (event) => {
      if(!event?.target?.files || event.target.files.length <= 0) return

      if (event.target.files[0].size < 2097152) {
        setLoading(true);
        try {
          const formImageWallpaper = new FormData();
          formImageWallpaper.append('file', event.target.files[0]);

          await api.post(
            `${process.env.REACT_APP_PREFIX_ROUTE}/wallpapers/wallpaper`,
            formImageWallpaper
          );
          setWallpaperImg(URL.createObjectURL(event.target.files[0]));
        } catch (err) {
          Toast.fire({
            icon: 'error',
            title: 'Ocorreu um erro ao alterar sua foto',
          });
        } finally {
          setLoading(false);
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'O tamanho da imagem deve ser inferior a 2 megabytes',
        });
      }
    },
    [setLoading, setWallpaperImg]
  );

  return (
    <>
      <div
        className="col-12 bg-sup"
        style={{
          backgroundImage: `url(${
            wallaperImg
            || `${process.env.REACT_APP_API_URL}/builders/wallpapers/wallpaper/${engineer.user_id}`
          })`,
        }}
      >
        <button
          type="button"
          className="d-flex btn-insert align-items-center justify-content-center mt-2 mb-5 border-0"
        >
          <label
            htmlFor="upload-image"
            style={{ cursor: 'pointer' }}
            className="d-flex flex-row"
          >
            <FiPaperclip size={20} color="#FFFFFF" className="mx-2" />
            <span className="fw-medium insert me-2">
              {!wallaperImg ? 'Inserir' : 'Alterar'}
            </span>
          </label>
          <input
            type="file"
            accept="image/*"
            className="d-none"
            onChange={(e) => handleSetWallpaper(e)}
            id="upload-image"
          />
        </button>
      </div>
      <div className="col-12 prof-avatar">
        <Avatar
          src={photoEngineer}
          className="mx-auto mt-n5"
        >
          <button
            type="button"
            className="d-flex camera border-0 align-items-center justify-content-center"
          >
            <label htmlFor="upload-wallpaper" style={{ cursor: 'pointer' }}>
              <HiCamera size={17} color="#fff" className="d-flex" />
            </label>
            <input
              type="file"
              accept="image/*"
              className="d-none"
              onChange={(e) => handleChangePhoto(e)}
              id="upload-wallpaper"
            />
          </button>
        </Avatar>
        <h2 className="fw-semibold h2 text-center name mt-4">
          {engineer?.nome}
        </h2>
        <h2 className="fw-light small text-center name mt-4 mb-5">
          {engineer?.profession}
        </h2>
      </div>
    </>
  );
};

export default AvatarEngineer;
