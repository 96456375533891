/* FIXEM - eslint-disable no-await-in-loop */
/* FIXEM - eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import comunidade from '~/assets/defaults/mcf-comunidade.png';
import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';
import { setToExternalURI } from '~/utils/parseURL';
import { Banner, Container } from './styles';

interface IAdvertisement {
  id: string;
  title: string;
  description: string;
  url: string;
  created_at: string;
  filename: string;
  origin_name: string;
}

const Marketing: React.FC = () => {
  const { validateEngineerData } = useProfile();
  const history = useHistory();
  const [advertisements, setAdvertisements] = useState<IAdvertisement[]>([]);
  const [indexSelected, setIndexSelected] = useState(0);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    api.get('builders/advertisements').then(async (res) => {
      try {
        setAdvertisements(res.data);
      } catch (error) {
        // do anything
      }
    });
  }, []);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleSelect = useCallback((index) => {
    setIndexSelected(index);
  }, []);

  const handlePrev = useCallback(() => {
    setIndexSelected((state) => (state === 0 ? advertisements.length - 1 : state - 1));
  }, [advertisements.length]);

  const handleNext = useCallback(() => {
    setIndexSelected((state) => (state + 1 === advertisements.length ? 0 : state + 1));
  }, [advertisements.length]);

  return (
    <Container className="position-relative px-2 px-lg-0 mb-4 mb-lg-0">
      {advertisements.length > 0 ? (
        <Carousel
          activeIndex={indexSelected}
          onSelect={handleSelect}
          className="w-100"
        >
          {advertisements.map((advertisement) => (
            <Carousel.Item key={advertisement.id}>
              <Banner
                onClick={() => setToExternalURI(advertisement.url)}
                src={`${process.env.REACT_APP_API_URL}/builders/advertisements/${advertisement.id}`}
                className="marketing-banner cursor-pointer"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className="bannner">
          <img src={comunidade} alt="comunidade" className="bannner" />
        </div>
      )}
      <div
        className={`arrows mt-2 ${advertisements.length > 0 ? 'd-flex' : 'd-none'
          } justify-content-end`}
      >
        <button
          type="button"
          className="border-0 ms-3 bg-transparent"
          onClick={handlePrev}
        >
          <IoIosArrowBack size={22} color="#707070" />
        </button>
        <button
          type="button"
          className="border-0 ms-3 bg-transparent"
          onClick={handleNext}
        >
          <IoIosArrowForward size={22} color="#707070" />
        </button>
      </div>
    </Container>
  );
};

export default Marketing;
