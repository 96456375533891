import styled from 'styled-components';

export const CardBoxContainer = styled.div`
  margin-top: 2.5rem;

  .box-details {
    padding: 0px 6rem;
    @media (max-width: 1211px) {
      padding: 0px 0rem;
      margin-left: 0;
    }
  }
`;
