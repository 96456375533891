import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import api from '~/services/api';
import { IFileDetails } from '~/models/Proponents/ProponentFile/FileDetails';

import { IMParticipant } from '~/models/Participant';
import { IMFileType } from '~/models/FileTypes';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import FormSendDocument from './Partials/FormSendDocument';

export interface IFileObject {
  id: string;
  file: File;
  percentage: number;
  loaded: number;
  totalSize: number;
  error: boolean;
}

interface IFileDeatilsProps extends IFileDetails {
  fileObject: IFileObject[];
}

interface IModalUploadParams {
  show: boolean;
  fileType: IMFileType;
  participant?: IMParticipant;
  seller?: number;
  onHide(): void;
  onChangeFiles?(): void;
  onSendFile?(): void;
}

const ModalUpload: React.FC<IModalUploadParams> = ({
  show,
  fileType,
  participant,
  seller,
  onHide,
  onChangeFiles,
  onSendFile,
}) => {
  const { serviceOrder } = useServiceOrder();
  const formViewRef = useRef<FormHandles>(null);
  const [observationClient, setObservationClient] = useState('');
  const [fileDataElement, setFileDataElement] = useState<IFileObject[]>([]);

  const handleClose = useCallback(() => {
    setFileDataElement([]);
    onHide();
  }, [onHide]);

  useEffect(() => {
    if (show) {
      setFileDataElement([]);
    }
  }, [show]);

  const handleSubmit = useCallback(() => {
    formViewRef?.current?.reset();
  }, []);

  const handleRefreshArray = useCallback(
    (idValue: string) => {
      setFileDataElement(
        fileDataElement.filter((dataElement) => dataElement.id !== idValue)
      );
    },
    [fileDataElement]
  );

  useEffect(() => {
    onChangeFiles && onChangeFiles();
  }, [fileDataElement, onChangeFiles]);

  const handleUpload = useCallback(
    async ({ data, observation, fileObject }: IFileDeatilsProps) => {
      try {
        const formUpload = new FormData();
        formUpload.append('file', data);
        formUpload.append('tpArquivo_id', String(fileType.id));
        formUpload.append('documento_extra', '0');
        formUpload.append('precisa_validacao', '0');
        formUpload.append(
          'obsCliente',
          observation.length > 0 ? observation : ''
        );

        if (participant && participant.type === 'spouse') {
          formUpload.append('conjuge_id', String(participant?.id));
        } else if (participant && participant.type === 'participant') {
          formUpload.append('participante_id', String(participant.id));
        } else if (seller) {
          formUpload.append('vendedor', String(seller));
        }

        const returnRequest = await api.post(
          `builders/service-orders/${serviceOrder.id}/files`,
          formUpload,
          {
            onUploadProgress: (event) => {
              const progress: number = Math.round(
                (event.loaded * 100) / event.total
              );

              setFileDataElement([
                ...fileObject.filter(
                  (filterObject) => filterObject.percentage !== 0
                ),
                {
                  id: '0',
                  file: data,
                  percentage: progress,
                  loaded: progress === 100 ? data.size : event.loaded,
                  totalSize: data.size,
                  error: false,
                },
              ]);

              onSendFile && onSendFile();
            },
          }
        );

        // RETORNAR ID REGISTRADO E ADICIONAR AO OBJETO
        setFileDataElement([
          ...fileObject.filter((filterObject) => filterObject.percentage !== 0),
          {
            id: returnRequest.data.file.id,
            file: data,
            percentage: 100,
            loaded: data.size,
            totalSize: data.size,
            error: false,
          },
        ]);

        handleSubmit();
      } catch (err) {
        await Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });

        setFileDataElement([
          ...fileObject.filter((filterObject) => filterObject.percentage !== 0),
          {
            id: '0',
            file: data,
            percentage: 0,
            loaded: 0,
            totalSize: data.size,
            error: true,
          },
        ]);

        handleSubmit();
      }
    },
    [
      handleSubmit,
      participant,
      fileType.id,
      serviceOrder.id,
      onSendFile,
      seller,
    ]
  );

  const onSelectFile = useCallback(
    (event) => {
      for (let i = 0; i < event.target.files.length; i += 1) {
        const newFileDataElement = fileDataElement;
        if (event.target.files[i] !== undefined) {
          if (
            event.target.files[i].type === 'image/jpeg'
            || event.target.files[i].type === 'application/pdf'
            || (event.target.files[i].type === 'application/vnd.ms-excel'
              && Number(fileType.id) === 11)
            || (event.target.files[i].type
              === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              && Number(fileType.id) === 11)
            || (event.target.files[i].type
              === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
              && Number(fileType.id) === 11)
          ) {
            newFileDataElement.push({
              id: '0',
              file: event.target.files[i],
              percentage: 0,
              loaded: 0,
              totalSize: 0,
              error: false,
            });

            handleUpload({
              data: event.target.files[i],
              id: serviceOrder.id,
              observation: observationClient,
              type: fileType.id.toString(),
              fileObject: newFileDataElement,
            });
          } else if (
            event.target.files[i].type
              === 'application/vnd.ms-excel.sheet.macroEnabled.12'
            || event.target.files[i].type
              === 'application/vnd.ms-excel.template.macroEnabled.12'
            || event.target.files[i].type
              === 'application/vnd.ms-excel.addin.macroEnabled.12'
            || event.target.files[i].type
              === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
          ) {
            Swal.fire({
              icon: 'warning',
              title:
                'Carregue um arquivo Excel que não possua nenhum macro aplicado',
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title:
                Number(fileType.id) !== 11
                  ? 'Carregue um arquivo de formato jpeg ou PDF'
                  : 'Carregue um arquivo de formato jpeg, PDF ou Excel',
            });
          }
        }
      }
    },
    [
      fileDataElement,
      handleUpload,
      observationClient,
      serviceOrder.id,
      fileType.id,
    ]
  );

  const onDropFile = useCallback(
    (data) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i] !== undefined) {
          if (
            data[i].type === 'image/jpeg'
            || data[i].type === 'application/pdf'
            || (data[i].type === 'application/vnd.ms-excel'
              && Number(fileType.id) === 11)
            || (data[i].type
              === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              && Number(fileType.id) === 11)
            || (data[i].type
              === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
              && Number(fileType.id) === 11)
          ) {
            handleUpload({
              data: data[i],
              id: serviceOrder.id,
              observation: observationClient,
              type: fileType.id.toString(),
              fileObject: fileDataElement,
            });

            if (data[i].type !== 'image/jpeg') {
              handleUpload({
                data: data[i],
                id: serviceOrder.id,
                observation: observationClient,
                type: fileType.id.toString(),
                fileObject: fileDataElement,
              });
            }
          } else if (
            data[i].type === 'application/vnd.ms-excel.sheet.macroEnabled.12'
            || data[i].type
              === 'application/vnd.ms-excel.template.macroEnabled.12'
            || data[i].type === 'application/vnd.ms-excel.addin.macroEnabled.12'
            || data[i].type
              === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
          ) {
            Swal.fire({
              icon: 'warning',
              title:
                'Carregue um arquivo Excel que não possua nenhum macro aplicado',
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title:
                Number(fileType.id) !== 11
                  ? 'Carregue um arquivo de formato jpeg ou PDF'
                  : 'Carregue um arquivo de formato jpeg, PDF ou Excel',
            });
          }
        }
      }
    },
    [
      fileDataElement,
      handleUpload,
      observationClient,
      serviceOrder.id,
      fileType.id,
    ]
  );

  const handleDragDrop = useCallback(
    (event) => {
      event.preventDefault();
      onDropFile(event.dataTransfer.files[0]);
    },
    [onDropFile]
  );

  return (
    <FormSendDocument
      show={show}
      fileType={fileType}
      fileDataElement={fileDataElement}
      formRef={formViewRef}
      onHandleClose={handleClose}
      onHandleSubmit={handleSubmit}
      onHandleDragDrop={handleDragDrop}
      onSelectFile={onSelectFile}
      onHandleRefreshArray={handleRefreshArray}
      onSetObservationClient={setObservationClient}
    />
  );
};

export default ModalUpload;
