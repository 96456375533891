import { FormHandles } from '@unform/core';
import { format, parse } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import Documents from '~/assets/img/Documents.png';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';
import FormMeasurement from './FormMeasurement';

interface IDataUpload {
  data: File;
  type: string;
}

export interface IFormData {
  calendar: string;
  percentage: string;
}

export interface IDocuments {
  id: string;
  os_id: string;
  file_url: string;
  nome?: string;
}

interface IModalSendMeasurement {
  show: boolean;
  approvedPercentage: number;
  onHide: () => void;
  onReload: () => void;
}

interface IParams {
  osId: string;
}

const ModalSendMeasurement: React.FC<IModalSendMeasurement> = ({
  show,
  approvedPercentage,
  onHide,
  onReload,
}) => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [dataFile, setDataFile] = useState<File>({} as File);
  const [dataFileVisualization, setDataFileVisualization] = useState(Documents);
  const [errorData, setErrorData] = useState('');
  const [errorPercentage, setErrorPercentage] = useState('');

  const dateCurrent = new Date();
  const [fileName, setFileName] = useState('');

  const handleClose = useCallback(() => {
    setErrorData('');
    setErrorPercentage('');
    setDataFileVisualization(Documents);
    setFileName('');
    onHide();
  }, [onHide]);

  const handleChangeDate = useCallback(
    (value) => {
      setErrorData('');
      formRef.current?.setErrors({});

      if (value.replaceAll('/', '').length > 7) {
        const valueYear = Number(value.replaceAll('/', '').substring(4));
        if (dateCurrent.getFullYear() - valueYear > 0) {
          setErrorData('Data inválida');
        }
      }
    },
    [dateCurrent]
  );

  const handleChangePercentage = useCallback(
    (value) => {
      setErrorPercentage('');
      formRef.current?.setErrors({});

      if (Number(value) > 100) {
        setErrorPercentage('Insira um valor menor que 100%');
      } else if (Number(value < 0)) {
        setErrorPercentage('Insira um valor menor que 100%');
      } else if (Number(value) + approvedPercentage > 100) {
        setErrorPercentage(
          `Porcentagem aprovada em ${approvedPercentage}%, insira um valor menor`
        );
      }
    },
    [approvedPercentage]
  );

  const searchFile = useCallback(
    async (nameFile: string) => {
      const arrayFiles = await api.get<IDocuments[]>(
        `/builders/oss/${params.osId}/arquivos/get`,
        {
          params: {
            participante_id: null,
            conjuge_id: null,
          },
        }
      );
      return arrayFiles.data.find((fileData) => fileData.nome === nameFile)?.id;
    },
    [params.osId]
  );

  const handleUpload = useCallback(
    // FIXME - eslint-disable-next-line consistent-return
    async ({ data, type }: IDataUpload) => {
      try {
        const formUpload = new FormData();
        formUpload.append('file', data);
        formUpload.append('tpArquivo_id', type);
        // VALIDATION NAME FILE
        const validateRegister = await searchFile(data.name);

        if (validateRegister === undefined) {
          await api.post(
            `/builders/oss/${params.osId}/arquivos/store`,
            formUpload
          );
        }
        return validateRegister;
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [params.osId, searchFile]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          calendar: Yup.string().required('Data de solicitação obrigatória'),
          percentage: Yup.string().required('Porcentagem obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (errorData.length === 0) {
          if (errorPercentage.length === 0) {
            // REALIZAR POST E COM O ID RETORNADO MANDAR PARA O BACKEND
            const formFile = new FormData();
            formFile.append('file', dataFile);

            const { calendar, percentage } = data;

            const date = parse(
              calendar.replaceAll('/', '-'),
              'dd-MM-yyyy',
              new Date()
            );

            const formatDate = format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");

            const idFile = await handleUpload({ data: dataFile, type: '18' });

            if (dataFile.size > 0 && idFile === undefined) {
              const idFileSearch = await searchFile(dataFile.name);

              const formData = {
                os_id: params.osId,
                porcentagem: Number(percentage),
                dt_solicitacao: formatDate,
                arquivo_solicitacao_id: Number(idFileSearch),
              };

              await api.post(
                `/builders/oss/${params.osId}}/medicoes`,
                formData
              );
            } else if (dataFile.size > 0 && idFile !== undefined) {
              Swal.fire('Opss...', 'Arquivo já encontrado no sistema', 'error');

              return;
            } else {
              const formData = {
                os_id: params.osId,
                porcentagem: Number(percentage),
                dt_solicitacao: formatDate,
              };

              await api.post(`/builders/oss/${params.osId}/medicoes`, formData);
            }
          }
        }

        onReload();
        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Medição enviada com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao salvar, tente novamente.',
            'error'
          );
        }
      }
    },
    [
      dataFile,
      errorData.length,
      errorPercentage.length,
      handleClose,
      handleUpload,
      onReload,
      params.osId,
      searchFile,
    ]
  );

  return (
    <FormMeasurement
      show={show}
      dataFileVisualization={dataFileVisualization}
      errorData={errorData}
      errorPercentage={errorPercentage}
      fileName={fileName}
      dataFile={dataFile}
      formRef={formRef}
      onHandleClose={handleClose}
      onHandleSubmit={handleSubmit}
      onHandleChangeDate={handleChangeDate}
      onHandleChangePercentage={handleChangePercentage}
      onHandleSetFileName={setFileName}
      onHandleSetDataFile={setDataFile}
      onHandleSetDataFileVisualization={setDataFileVisualization}
    />
  );
};

export default ModalSendMeasurement;
