import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Subtitle */

    color: #707070;
  }

  .text-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Title */

    color: #414142;
  }
`;
