import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IModal {
  size?: 'md';
}

export const Container = styled.div`
  width: 100%;

  .contenedorOS-bdi {
    position: absolute;
    width: 30%;
    height: 80px;
    display: flex;
    margin-left: 800px;

    @media (min-width: 1445px) {
      margin-left: 910px;
    }
  }

  .btn-os {
    width: 48%;
    border-radius: 24px;
    height: 43px;
    @media (max-width: 991px) {
      border-radius: 26px;
      width: 32%;
    }
  }

  .btn-bdi {
    width: 48%;
    border-radius: 24px;
    background-color: #414d49;
    color: white;
    height: 43px;
    @media (max-width: 991px) {
      border-radius: 26px;
      width: 32%;
    }
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  // centrar modal

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 150px;

  .modal-content {
    border: none;
    border-radius: 20px;
    // hacer borde sombreado
    box-shadow: 15px 10px 40px rgba(0, 0, 0, 0.25);
  }

  .sin-border {
    border: none;
  }

  .redondeo {
    border-radius: 12px;
  }

  .text-gerar {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;

    /* Title */

    color: #414142;
  }
  .d-none {
    display: none;
  }

  .modalContent {
    max-width: ${(props) => (props.size === 'md' ? '400px' : '500px')};
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .btn-submit {
    background-color: #39a887;
    color: #ffffff;
    font-weight: 600;
  }

  .link {
    color: #1762e1;
  }
`;
