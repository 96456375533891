import styled from 'styled-components';

export const Container = styled.div`
  hr {
    margin-top: 2%;
    height: 1px;
  }

  .container_button {
    padding: 0px 3rem;
    @media (max-width: 740px) {
      padding: 0px 21px !important;
    }
  }

  .btn-simulate-again {
    background: #f9f9f9;
    border-radius: 10px;
    color: #707070;
  }
`;

export const EnvironmentContainer = styled.div`
  margin: 2.5rem 3rem 0px 3rem;
  @media (max-width: 768px) {
    margin: 2.5rem 1rem 0px 1rem;
  }
  @media (max-width: 533px) {
    margin: 2.5rem -2rem 0px -2rem;
  }
  .card-dashboard {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
  }
`;
