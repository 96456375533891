import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef } from 'react';
import Input from '~/components/Input';
import { IMBoard } from '~/models/Board';
import { useKanban } from '~/pages/Kanban/Hooks/useKanban';
import api from '~/services/api';
import { Container, Modal } from '../styles';

interface IFormData {
  quadro: string;
}

interface IModalAddProps {
  handleClose: () => void;
}

const ModalAdd: React.FC<IModalAddProps> = ({ handleClose }) => {
  const formRef = useRef<FormHandles>(null);
  const { columns, updateDataColumn,setColumns } = useKanban();

  const handleAddTask = useCallback(
    async (data: IFormData) => {
      try {
        const dataFormProv = {
          ordem: columns.length,
          quadro: data.quadro,
        };
        await api
          .post<IMBoard>(
            `${process.env.REACT_APP_PREFIX_ROUTE}/boards`,
            dataFormProv
          )
          .then((response) => {
            updateDataColumn(response.data);
            setColumns([...columns, response.data]);
            handleClose();
          });
      } catch (err) {
        //
      }
    },
    [columns, handleClose, updateDataColumn]
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleAddTask}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar tarefa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="container">
            <div className="col-lg-12">
              <div className="row">
                <div>
                  <label>Título do quadro: *</label>
                  <Input className="form-input" name="quadro" />
                </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="buttons-group">
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-white border"
                onClick={handleClose}
              >
                Fechar
              </button>
              <button type="submit" className="btn-submit btn btn-secondary">
                Salvar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
};

export default ModalAdd;
