import React from 'react';

import { Container } from './styles';

interface IProps {
  setShowModal(show: boolean): void;
}

const AddBoxInfo: React.FC<IProps> = ({ setShowModal }) => (
  <Container className="d-flex align-items-center px-5 box-rezise">
    {/* <p className="mb-0 fw-semibold">
        Adicionar informações da Caixa
        <button
          type="button"
          className="button_clickeame bg-transparent text-primary fw-semibold border-0"
          onClick={() => setShowModal(true)}
        >
          <BsPlusCircleFill className="mb-1 mx-2" size={20} />
        </button>
      </p> */}
  </Container>
);

export default AddBoxInfo;
