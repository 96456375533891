import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory, useParams } from 'react-router-dom';

import { formatPrice } from '~/utils/format';

import Loading from '~/components/Loading';

import finishingBasic from '~/assets/defaults/finishing-basic.png';
import finishingHigh from '~/assets/defaults/finishing-high.png';
import finishingLow from '~/assets/defaults/finishing-low.png';
import finishingMedium from '~/assets/defaults/finishing-medium.png';
import basic from '~/assets/icons/basic.svg';
import high from '~/assets/icons/high.svg';
import low from '~/assets/icons/low.svg';
import medium from '~/assets/icons/medium.svg';
import { useOrderServiceBuilder } from '~/hooks/OrderServiceBuilder';

import flatLand from '~/assets/defaults/flat-land.png';
import slopeLand from '~/assets/defaults/slope-land.png';
import uphillLand from '~/assets/defaults/uphill-land.png';
import flat from '~/assets/icons/flat.svg';
import slope from '~/assets/icons/slope.svg';
import uphill from '~/assets/icons/uphill.svg';

import houseStyleConventionalGroundFloor from '~/assets/defaults/house-style-conventional-ground-floor.png';
import houseStyleConventionalTownhouse from '~/assets/defaults/house-style-conventional-townhouse.png';
import houseStyleModernGroundFloor from '~/assets/defaults/house-style-modern-ground-floor.png';
import houseStyleModernTownhouse from '~/assets/defaults/house-style-modern-townhouse.png';
import conventionalGroundFloor from '~/assets/icons/conventional-ground-floor.svg';
import conventionalTownhouse from '~/assets/icons/conventional-townhouse.svg';
import modernGroundFloor from '~/assets/icons/modern-ground-floor.svg';
import modernTownhouse from '~/assets/icons/modern-townhouse.svg';

import electricBasicElectrical from '~/assets/defaults/electric-basic-electrical.png';
import electricLuminotechnicalProject from '~/assets/defaults/electric-luminotechnical-project.png';
import basiclEletrical from '~/assets/icons/basic-electrical.svg';
import luminotechnicalProject from '~/assets/icons/luminotechnical-project.svg';

import hydraulicColdHotReuse from '~/assets/defaults/hydraulic-cold-hot-reuse.png';
import hydraulicColdHot from '~/assets/defaults/hydraulic-cold-hot.png';
import hydraulicCold from '~/assets/defaults/hydraulic-cold.png';
import coldHotReuse from '~/assets/icons/cold-hot-reuse.svg';
import coldHot from '~/assets/icons/cold-hot.svg';
import cold from '~/assets/icons/cold.svg';

import rightFoot from '~/assets/defaults/right-foot.png';
import rightFootNo from '~/assets/icons/right-foot-no.svg';
import rightFootYes from '~/assets/icons/right-foot-yes.svg';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMBudgetParameter } from '~/models/BudgetParameter';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import api from '~/services/api';
import { getAccumulatedTotal } from '~/utils/serviceOrderCalculations';
import Toast from '~/utils/toast';

import { useAuthContext } from '~/hooks/contexts/Auth';
import TopMenu from './TopMenu/index';
import {
  Bar, ButtonStandard, Container, Img, Step
} from './styles';

interface IParams {
  osId: string;
  slug?: string;
}

interface IParameter {
  tp_acabamento_id: number;
  tp_terreno_id: number;
  estilo_construcao_id: number;
  tp_eletrica_id: number;
  tp_hidraulica_id: number;
  pe_direito: number;
}

interface IOptionResponse {
  id: number;
  margem: number;
  imagem: string;
  vetor: string;
  tipo_acabamento: string;
  tipo_terreno: string;
  estilo_construcao: string;
  tipo_eletrica: string;
  tipo_hidraulica: string;
}

interface IOption {
  id: number;
  step_id: number;
  label: string;
  icon: string;
  photo: string;
  margem: number;
  selected?: boolean;
}

interface IStep {
  id: number;
  type: string;
  question: string;
  photo?: string;
  options: IOption[];
}

interface IEnvironment {
  id: number;
  item: number;
  metragem: number;
  vlrEstado: number;
  vlrAcabamento: number;
  vlrLucro: number;
  vlrFinal: number;
  ambiente_tamanho: {
    ambiente: {
      margem: number;
    };
  };
}
interface IOs {
  simulacao: {
    vlrFinanciado: number;
    vlrFgts: number;
    vlrEntrada: number;
    vlrTerreno: number;
  };
  terreno: {
    estado: {
      valor: number;
    };
  };
  orc_parametros: {
    acabamento: {
      id: number;
    };
    terreno: {
      id: number;
    };
    estilo_construcao: {
      id: number;
    };
    eletrica: {
      id: number;
    };
    hidraulica: {
      id: number;
    };
  };
}
interface IFinishing {
  percent: number;
  totalBudget: number;
  budget: number;
}

const Finishing: React.FC = () => {
  const { orderServiceBuilder } = useOrderServiceBuilder();
  const {user} = useAuthContext()
  const params = useParams<IParams>();
  const history = useHistory();
  const { validateEngineerData } = useProfile();
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [buttonStatus, setButtonStatus] = useState(true);
  const [completedStatus, setCompletedStatus] = useState(true);
  const [steps, setSteps] = useState<IStep[]>([]);
  const [step, setStep] = useState({} as IStep);
  const [finishing, setFinishing] = useState({} as IFinishing);
  const [optionsSeleted, setOptionsSeleted] = useState<IOption[]>([]);
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [budget, setBudget] = useState(0);
  const [loading, setLoading] = useState(true);
  const [finishingTitle, setFinishingTitle] = useState('Acabamento');

  // AUX Variables
  const OS_ID = params?.osId || serviceOrder?.id || -1

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  useEffect(() => {
    if (optionsSeleted.length === 0) {
      history.push(
        `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento`
      );
    }
  }, [history, optionsSeleted.length, OS_ID]);

  const assets = useMemo(
    () => [
      {
        type: 'acabamento',
        icon: high,
        photo: finishingHigh,
      },
      {
        type: 'acabamento',
        icon: medium,
        photo: finishingMedium,
      },
      {
        type: 'acabamento',
        icon: basic,
        photo: finishingBasic,
      },
      {
        type: 'acabamento',
        icon: low,
        photo: finishingLow,
      },
      {
        type: 'terreno',
        icon: uphill,
        photo: uphillLand,
      },
      {
        type: 'terreno',
        icon: slope,
        photo: slopeLand,
      },
      {
        type: 'terreno',
        icon: flat,
        photo: flatLand,
      },
      {
        type: 'estilo-da-casa',
        icon: modernTownhouse,
        photo: houseStyleModernTownhouse,
      },
      {
        type: 'estilo-da-casa',
        icon: conventionalTownhouse,
        photo: houseStyleConventionalTownhouse,
      },
      {
        type: 'estilo-da-casa',
        icon: modernGroundFloor,
        photo: houseStyleModernGroundFloor,
      },
      {
        type: 'estilo-da-casa',
        icon: conventionalGroundFloor,
        photo: houseStyleConventionalGroundFloor,
      },
      {
        type: 'eletrica',
        icon: luminotechnicalProject,
        photo: electricLuminotechnicalProject,
      },
      {
        type: 'eletrica',
        icon: basiclEletrical,
        photo: electricBasicElectrical,
      },
      {
        type: 'hidraulica',
        icon: coldHotReuse,
        photo: hydraulicColdHotReuse,
      },
      {
        type: 'hidraulica',
        icon: coldHot,
        photo: hydraulicColdHot,
      },
      {
        type: 'hidraulica',
        icon: cold,
        photo: hydraulicCold,
      },
      {
        type: 'pe-direito',
        icon: rightFootYes,
        photo: rightFoot,
      },
      {
        type: 'pe-direito',
        icon: rightFootNo,
        photo: rightFoot,
      },
    ],
    []
  );

 

  useEffect(() => {
    api
      .get<IOptionResponse[]>('queries/getacabamentos')
      .then(async (response) => {
        const responseOs = await api.get<IOs>(
          `builders/service-orders/${OS_ID}`
        );
        const responseEnvironments = await api.get(
          `/builders/service-orders/${OS_ID}/budget/environments`
        );

        let assetsParameters = assets.filter(
          (asset) => asset.type === 'acabamento'
        );
        let options: IOption[] = response.data.map((option, index) => ({
          id: option.id,
          step_id: 1,
          label: option.tipo_acabamento,
          icon: assetsParameters[index].icon,
          photo: assetsParameters[index].photo,
          margem: option.margem,
        }));

        let stepData: IStep = {
          id: 1,
          type: 'acabamento',
          question: 'Qual tipo de acabamento?',
          options,
          photo: responseOs.data.orc_parametros
            ? assetsParameters[responseOs.data.orc_parametros.acabamento.id - 1]
              .photo
            : assetsParameters[0].photo,
        };
        const data = [stepData];

        const responseLand = await api.get<IOptionResponse[]>(
          'queries/getterrenos'
        );

        assetsParameters = assets.filter((asset) => asset.type === 'terreno');
        options = responseLand.data.map((option, index) => ({
          id: option.id,
          step_id: 2,
          label: option.tipo_terreno,
          icon: assetsParameters[index].icon,
          photo: assetsParameters[index].photo,
          margem: option.margem,
        }));

        stepData = {
          id: 2,
          type: 'terreno',
          question: 'Em qual tipo de terreno deseja construir?',
          options,
          photo: responseOs.data.orc_parametros
            ? assetsParameters[responseOs.data.orc_parametros.terreno.id - 1]
              .photo
            : assetsParameters[0].photo,
        };

        data.push(stepData);

        const responseHouseStyle = await api.get<IOptionResponse[]>(
          'queries/getestilosconstrucao'
        );

        assetsParameters = assets.filter(
          (asset) => asset.type === 'estilo-da-casa'
        );
        options = responseHouseStyle.data.map((option, index) => ({
          id: option.id,
          step_id: 3,
          label: option.estilo_construcao,
          icon: assetsParameters[index].icon,
          photo: assetsParameters[index].photo,
          margem: option.margem,
        }));

        stepData = {
          id: 3,
          type: 'estilo-da-casa',
          question: 'Qual estilo de casa deseja ter?',
          options,
          photo: responseOs.data.orc_parametros
            ? assetsParameters[
              responseOs.data.orc_parametros.estilo_construcao.id - 1
            ].photo
            : assetsParameters[0].photo,
        };

        data.push(stepData);

        const responseElectric = await api.get<IOptionResponse[]>(
          'queries/gettiposeletrica'
        );

        assetsParameters = assets.filter((asset) => asset.type === 'eletrica');
        options = responseElectric.data.map((option, index) => ({
          id: option.id,
          step_id: 4,
          label: option.tipo_eletrica,
          icon: assetsParameters[index].icon,
          photo: assetsParameters[index].photo,
          margem: option.margem,
        }));

        stepData = {
          id: 4,
          type: 'eletrica',
          question: 'Em qual tipo de projeto elétrico?',
          options,
          photo: options[0].photo,
        };

        data.push(stepData);

        const responseHydraulic = await api.get<IOptionResponse[]>(
          'queries/gettiposhidraulica'
        );

        assetsParameters = assets.filter(
          (asset) => asset.type === 'hidraulica'
        );
        options = responseHydraulic.data.map((option, index) => ({
          id: option.id,
          step_id: 5,
          label: option.tipo_hidraulica,
          icon: assetsParameters[index].icon,
          photo: assetsParameters[index].photo,
          margem: option.margem,
        }));

        stepData = {
          id: 5,
          type: 'hidraulica',
          question: 'Em qual tipo de projeto de hidráulica?',
          options,
          photo: responseOs.data.orc_parametros
            ? assetsParameters[responseOs.data.orc_parametros.hidraulica.id - 1]
              .photo
            : assetsParameters[0].photo,
        };

        data.push(stepData);

        stepData = {
          id: 6,
          type: 'pe-direito',
          question: 'Pé direito duplo?',
          photo: rightFoot,
          options: [
            {
              id: 1,
              step_id: 6,
              label: 'Sim',
              icon: rightFootYes,
              photo: rightFoot,
              margem: 0,
            },
            {
              id: 2,
              step_id: 6,
              label: 'Não',
              icon: rightFootNo,
              photo: rightFoot,
              margem: 0,
            },
          ],
        };

        data.push(stepData);

        setEnvironments(responseEnvironments.data);
        setBudget(
          responseOs.data.simulacao.vlrFinanciado
          + responseOs.data.simulacao.vlrFgts
          + responseOs.data.simulacao.vlrEntrada
          - responseOs.data.simulacao.vlrTerreno
        );
        setSteps(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [assets, OS_ID]);

  useEffect(() => {
    if (environments.length > 0 && optionsSeleted.length > 0) {
      const accumulatedTotal = getAccumulatedTotal(serviceOrder.orc_parametros, serviceOrder.orc_ambientes);

      console.table({
        accumulatedTotal,
        optionsSeleted,
        budget: {
           percent: parseFloat(((accumulatedTotal * 100) / budget).toFixed(2)),
          totalBudget: accumulatedTotal,
          budget,
        }
      })
      
      setFinishing({
        percent: parseFloat(((accumulatedTotal * 100) / budget).toFixed(2)),
        totalBudget: accumulatedTotal,
        budget,
      });
    }
  }, [environments, optionsSeleted, serviceOrder, budget]);

  useEffect(() => {
    if (steps.length > 0) {
      api
        .get<IParameter>(`/builders/oss/${OS_ID}/budget/parameters`)
        .then((response) => {
          if (response.data?.tp_acabamento_id) {
            const options = steps.map((stepData) => {
              let option: IOption | undefined;
              switch (stepData.type) {
                case 'acabamento':
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.tp_acabamento_id
                  );
                  break;
                case 'terreno':
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.tp_terreno_id
                  );
                  break;
                case 'estilo-da-casa':
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.estilo_construcao_id
                  );
                  break;
                case 'eletrica':
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.tp_eletrica_id
                  );
                  break;
                case 'hidraulica':
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.tp_hidraulica_id
                  );
                  break;

                default:
                  option = stepData.options.find(
                    (optionData) => optionData.id === response.data.pe_direito
                  );
                  break;
              }
              return option;
            });
            setOptionsSeleted(options as IOption[]);
            setCompletedStatus(false);
          }
        });
    }
  }, [OS_ID, steps]);

  useEffect(() => {
    if (steps.length > 0) {
      let stepSelected = steps.find(
        (stepCheckData) => params.slug && stepCheckData.type === params.slug
      );

      if (!stepSelected) {
        // FIXME - eslint-disable-next-line prefer-destructuring
        stepSelected = steps[0];
      }
      setButtonStatus(true);
      setStep(stepSelected);
    }
  }, [params.slug, steps]);

  useEffect(() => {
    setStep((state) => {
      const newStep = { ...state };
      if (Object.keys(newStep).length > 0) {
        let option: IOption | undefined;
        const newOptions = state.options.map((optionData) => {
          option = optionsSeleted.find((optionSelectedData) => (
            optionSelectedData.id === optionData.id
            && newStep.id === optionSelectedData.step_id
          ));

          if (option && optionData.id === option.id) {
            return {
              ...optionData,
              selected: true,
            };
          }

          return {
            ...optionData,
            selected: false,
          };
        });

        if (option) {
          newStep.photo = option.photo;
        }
        newStep.options = newOptions;
      }

      return newStep;
    });
  }, [optionsSeleted, params.slug]);

  const handleClickOpiton = useCallback(
    (option: IOption) => {
      const newOptions = step.options.map((optionData) => {
        if (optionData.id === option.id) {
          return {
            ...optionData,
            selected: true,
          };
        }

        return {
          ...optionData,
          selected: false,
        };
      });

      const newStep = { ...step };
      newStep.photo = option.photo;
      newStep.options = newOptions;

      const newOptionsSeleted = optionsSeleted.slice();
      const optionSelectedIndex = newOptionsSeleted.findIndex((optionData) => newStep.options.some(
        (optionCheck) => optionCheck.id === optionData.id
          && optionData.step_id === newStep.id
      ));

      if (optionSelectedIndex >= 0) {
        newOptionsSeleted[optionSelectedIndex] = option;
      } else {
        newOptionsSeleted.push(option);
      }
      setButtonStatus(false);

      setOptionsSeleted(newOptionsSeleted);
      setStep(newStep);
    },
    [optionsSeleted, step]
  );

  const handleClickAcabamento = useCallback(() => {
    setFinishingTitle('Acabamento');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleClickTerreno = useCallback(() => {
    setFinishingTitle('Terreno');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/terreno`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleClickEstilodacasa = useCallback(() => {
    setFinishingTitle('Estilo da casa');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/estilo-da-casa`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleClickEletrica = useCallback(() => {
    setFinishingTitle('Elétrica');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/eletrica`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleClickHidraulica = useCallback(() => {
    setFinishingTitle('Hidráulica');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/hidraulica`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleClickPeDereito = useCallback(() => {
    setFinishingTitle('Pé direito');
    history.push(
      `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/pe-direito`
    );
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleClickNext = useCallback(() => {
    switch (params.slug) {
      case 'terreno':
        setFinishingTitle('Estilo da casa');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/estilo-da-casa`
        );
        break;
      case 'estilo-da-casa':
        setFinishingTitle('Elétrica');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/eletrica`
        );
        break;
      case 'eletrica':
        setFinishingTitle('Hidráulica');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/hidraulica`
        );
        break;
      case 'hidraulica':
        setFinishingTitle('Pé direito');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/pe-direito`
        );
        break;
      case 'pe-direito':
        break;
      default:
        setFinishingTitle('Terreno');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/terreno`
        );
    }
  }, [history, OS_ID, params.slug]);

  const handleClickPrevious = useCallback(() => {
    switch (params.slug) {
      case 'terreno':
        setFinishingTitle('Acabamento');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento`
        );
        break;
      case 'estilo-da-casa':
        setFinishingTitle('Terreno');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/terreno`
        );
        break;
      case 'eletrica':
        setFinishingTitle('Estilo da casa');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/estilo-da-casa`
        );
        break;
      case 'hidraulica':
        setFinishingTitle('Elétrica');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/eletrica`
        );
        break;
      default:
        setFinishingTitle('Hidráulica');
        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento/hidraulica`
        );
    }
  }, [history, OS_ID, params.slug]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      if (optionsSeleted.length === 6) {
        const formData = {
          tp_acabamento_id: optionsSeleted[0].id,
          tp_terreno_id: optionsSeleted[1].id,
          estilo_construcao_id: optionsSeleted[2].id,
          tp_eletrica_id: optionsSeleted[3].id,
          tp_hidraulica_id: optionsSeleted[4].id,
          pe_direito: optionsSeleted[5].id,          
          margem: serviceOrder.orc_parametros?.margem || user?.construtech?.bdi || 30,
        };

        const parameters = await api.post<IMBudgetParameter>(
          `/builders/oss/${OS_ID}/budget/parameters`,
          formData
        );

        setServiceOrder({
          ...serviceOrder,
          orc_parametros: parameters.data,
        });

        if (environments.length > 0) {
          const budgetRoom = await api.post<IMBudgetRoom[]>(
            `builders/service-orders/${OS_ID}/budget/environments`,
            {
              ambientes: environments,
            }
          );
          setServiceOrder({
            ...serviceOrder,
            orc_ambientes: budgetRoom.data,
          });
        }

        Toast.fire({
          icon: 'success',
          title: 'Parametros definidos com sucesso',
        });

        history.push(
          `${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/sobre-a-casa/quarto/quantidade`
        );
      } else {
        Toast.fire({
          icon: 'error',
          title: 'É necessario definir todos os parametros',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    optionsSeleted,
    orderServiceBuilder,
    OS_ID,
    environments,
    history,
    serviceOrder,
    setServiceOrder,
    user
  ]);

  return (
    <Container buttonStatus>
      <TopMenu dashboardView={false} establishedParameters={false} />
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex align-items-center">
              <Link
                to={`${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}`}
              >
                <RiArrowLeftSLine size={28} color="#B8B9BA" />
              </Link>
              <h1 className="h4 mb-0 ms-3 fw-semibold text-dark-gray">
                Monte sua casa
              </h1>
            </div>
          </div>
          <div className="col-lg-2 part">
            <span>
              Parte
              {step && Object.keys(step).length > 0
                ? step.id.toString().padStart(2, '0')
                : '01'}
              /06
            </span>
            <div className="dropdown-div">
              <p>{finishingTitle}</p>
              <div className="finishing-green fw-semibold">
                <NavDropdown
                  title={<MdKeyboardArrowDown size={40} />}
                  id="nav-dropdown"
                >
                  <br />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        active={!params.slug}
                        className="w-100 text-center"
                        onClick={handleClickAcabamento}
                      >
                        <h4>Acabamento</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="4.3">
                    <div>
                      <Step
                        active={params.slug === 'terreno'}
                        className="w-100 text-center"
                        onClick={handleClickTerreno}
                      >
                        <h4>Terreno</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        active={params.slug === 'estilo-da-casa'}
                        className="w-100 text-center"
                        onClick={handleClickEstilodacasa}
                      >
                        <h4>Estilo da casa</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        active={params.slug === 'eletrica'}
                        className="w-100 text-center"
                        onClick={handleClickEletrica}
                      >
                        <h4>Elétrica</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        active={params.slug === 'hidraulica'}
                        className="w-100 text-center"
                        onClick={handleClickHidraulica}
                      >
                        <h4>Hidráulica</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="4.4">
                    <div>
                      <Step
                        active={params.slug === 'pe-direito'}
                        className="w-100 text-center"
                        onClick={handleClickPeDereito}
                      >
                        <h4>Pé direito</h4>
                      </Step>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-1 px-0" />
        <div className="row mb-3 py-5 px-3 px-lg-5">
          <div className="col-lg-2 border-steps">
            <Step
              active={!params.slug}
              className="w-100 text-center"
              onClick={handleClickAcabamento}
            >
              Acabamento
            </Step>
          </div>
          <div className="col-lg-2 border-steps">
            <Step
              active={params.slug === 'terreno'}
              className="w-100 text-center"
              onClick={handleClickTerreno}
            >
              Terreno
            </Step>
          </div>
          <div className="col-lg-2 border-steps">
            <Step
              active={params.slug === 'estilo-da-casa'}
              className="w-100 text-center"
              onClick={handleClickEstilodacasa}
            >
              Estilo da casa
            </Step>
          </div>
          <div className="col-lg-2 border-steps">
            <Step
              active={params.slug === 'eletrica'}
              className="w-100 text-center"
              onClick={handleClickEletrica}
            >
              Elétrica
            </Step>
          </div>
          <div className="col-lg-2 border-steps">
            <Step
              active={params.slug === 'hidraulica'}
              className="w-100 text-center"
              onClick={handleClickHidraulica}
            >
              Hidráulica
            </Step>
          </div>
          <div className="col-lg-2 border-steps">
            <Step
              active={params.slug === 'pe-direito'}
              className="w-100 text-center"
              onClick={handleClickPeDereito}
            >
              Pé direito
            </Step>
          </div>
          <div className="col-12 mt-5">
            {step && Object.keys(step).length > 0 && (
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <Img src={step.photo as string} className="mx-auto" />
                </div>
                <div className="col-lg-6">
                  <h2 className="h6 align-items-center text-center mb-4 mt-4 mt-lg-0">
                    {step.question}
                  </h2>
                  <div className="row align-items-center justify-content-center justify-content-lg-start">
                    {step.options.map((option) => (
                      <div key={option.id} className="col-3 col-sm-3">
                        <ButtonStandard
                          type="button"
                          className="d-flex flex-column justify-content-center align-items-center mx-auto mb-3 mb-sm-0"
                          selected={option.selected}
                          onClick={() => handleClickOpiton(option)}
                        >
                          <img src={option.icon} alt={option.label} />
                          <span>{option.label}</span>
                        </ButtonStandard>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {Object.keys(finishing).length > 0 && (
            <>
              <div className="col-12 mt-5">
                <Bar
                  percent={
                    Number.isNaN(finishing.percent) ? 0 : finishing.percent
                  }
                >
                  <div>
                    <p className="mb-0">
                      {Number.isNaN(finishing.percent) ? 0 : finishing.percent}%
                    </p>
                  </div>
                </Bar>
              </div>
              <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap mt-2">
                <p className="small normal-lg w-100 w-lg-auto mb-0">
                  Total atingido no orçamento
                </p>
                <p className="text-light-gray small mb-0">
                  {formatPrice(finishing.totalBudget)}
                </p>
              </div>
              <div className="d-flex justify-content-lg-end">
                <p className="text-primary fw-semibold small normal-lg">
                  Total disponível para construção:{' '}
                  {formatPrice(finishing.budget)}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="espacio col-sm-8 col-lg-4 d-flex justify-content-center">
            {params.slug && (
              <button
                type="button"
                className="btn"
                onClick={handleClickPrevious}
                style={{
                  backgroundColor: '#39A887',
                  borderRadius: '20px',
                  width: '62px',
                  height: '54px',
                }}
              >
                <RiArrowLeftSLine size={24} color="#fff" />
              </button>
            )}
            {params.slug === 'pe-direito' ? (
              <button
                type="button"
                className="btn btn-primary   justify-content-end"
                onClick={handleSubmit}
                style={{
                  backgroundColor: '#39A887',
                  borderRadius: '20px',
                  width: '331px',
                  height: '54px',
                  marginLeft: '10px',
                }}
              >
                <b
                  style={{
                    color: buttonStatus && completedStatus ? 'white' : 'white',
                  }}
                >
                  Próximo
                </b>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary  justify-content-end"
                style={{
                  backgroundColor: '#39A887',
                  borderRadius: '20px',
                  width: '331px',
                  height: '54px',
                }}
                onClick={handleClickNext}
              >
                <b
                  style={{
                    color: buttonStatus && completedStatus ? 'white' : 'white',
                  }}
                >
                  Próximo
                </b>
              </button>
            )}
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default Finishing;
