import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsArrowCounterclockwise } from 'react-icons/bs';

import Loading from '~/components/Loading';
import PropertyEnvironments from '~/pages/ServiceOrderDashboard/Pages/ServiceOrderData/Components/PropertyEnvironments';

import { EnvironmentContainer, Container } from './styles';

interface IParams {
  osId: string;
}

const BuildYourHouse: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleClickRefazer = useCallback(() => {
    setLoading(true);
    if (params.osId !== undefined) {
      history.push(`${process.env.PUBLIC_URL}/simulador/${params.osId}`);
      setLoading(false);
    } else {
      history.push(`${process.env.PUBLIC_URL}/simulador`);
      setLoading(false);
    }
  }, [history, params.osId, setLoading]);

  return (
    <>
      <Container>
        <div className="container_button col-12 my-3 mt-5">
          <button
            type="button"
            className="btn btn-simulate-again ms-auto px-4"
            onClick={handleClickRefazer}
          >
            <span className="fw-semibold">Refazer a Simulação</span>{' '}
            <BsArrowCounterclockwise size={20} color="#707070" />
          </button>
        </div>

        <EnvironmentContainer>
          <PropertyEnvironments />
        </EnvironmentContainer>
      </Container>
      <Loading active={loading} />
    </>
  );
};

export default BuildYourHouse;
