import styled from 'styled-components';

export const Container = styled.div`
  .left {
    @media screen and (max-width: 1199px) {
      margin-left: 5%;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0;
    }
  }
  .right {
    @media screen and (max-width: 1199px) {
      margin-left: 2%;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0;
    }
  }
`;
