import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IMConstrutech } from '~/models/Construtech';
import { IMEngineer } from '~/models/Engineer';
import api from '~/services/api';

import { useAuthContext } from '~/hooks/contexts/Auth';

interface ProfileContextData {
  engineer: IMEngineer;
  construtech: IMConstrutech;
  photoEngineer: string;
  loading: boolean;
  validateEngineerData: boolean;
  setEngineer: (engineer: IMEngineer) => void;
  setConstrutech: (construtech: IMConstrutech) => void;
  setPhotoEngineer: (photoEngineer: string) => void;
  setLoading: (loading: boolean) => void;
}

export const ProfileContext = createContext<ProfileContextData>(
  {} as ProfileContextData
);

export const ProfileProvider: React.FC = ({ children }) => {
  const { user } = useAuthContext();
  const [engineer, setEngineer] = useState<IMEngineer>({} as IMEngineer);
  const [construtech, setConstrutech] = useState<IMConstrutech>(
    {} as IMConstrutech
  );
  const [photoEngineer, setPhotoEngineer] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [validateEngineerData, setValidateEngineerData] = useState(true);

  const handleSetEngineer = useCallback(
    (engineerData: IMEngineer) => {
      setEngineer((state) => ({ ...state, ...engineerData }));
    },
    [setEngineer]
  );

  useEffect(() => {
    if (user?.id && !engineer?.id) {
      api
        .get<IMEngineer>(`${process.env.REACT_APP_PREFIX_ROUTE}/engineer`)
        .then((response) => {
          setEngineer(response.data);
          setValidateEngineerData(!!response.data.id);
        });
    }
  }, [user, engineer]);

  const handleSetConstrutech = useCallback(
    (construtechData: IMConstrutech) => {
      setConstrutech((state) => ({ ...state, ...construtechData }));
    },
    [setConstrutech]
  );

  const serviceOrderParams = useMemo(() => {
    return {
      engineer,
      construtech,
      photoEngineer,
      loading,
      validateEngineerData,
      setEngineer: handleSetEngineer,
      setConstrutech: handleSetConstrutech,
      setPhotoEngineer,
      setLoading,
    };
  }, [
    engineer,
    construtech,
    photoEngineer,
    loading,
    validateEngineerData,
    handleSetEngineer,
    handleSetConstrutech,
  ]);

  return (
    <ProfileContext.Provider value={serviceOrderParams}>
      {children}
    </ProfileContext.Provider>
  );
};

export function useProfile(): ProfileContextData {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within an ProfileProvider');
  }

  return context;
}
