import React, { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import AddFinancing from '~/assets/icons/add-financing.svg';
import { useClient } from '~/hooks/Client';
import initialsName from '~/utils/initialsName';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IDataForm } from '../ModalForm';

interface IModalProps {
  onSelectAvatar: (showForm: number, data: IDataForm) => void;
}

const Avatars: React.FC<IModalProps> = ({ onSelectAvatar }) => {
  const { client } = useClient();
  const { serviceOrder } = useServiceOrder();

  const handleAvatarSelected = useCallback(
    (formNumber: number, data: IDataForm) => {
      onSelectAvatar(formNumber, data);
    },
    [onSelectAvatar]
  );

  return (
    <div className="d-flex justify-content-end">
      <button
        type="button"
        className="btn p-0"
        onClick={() => {
          handleAvatarSelected(1, {
            ...client,
            salary: client.salary ? client.salary.toFixed(2) : '0',
            fgts_amount: client.fgts_amount
              ? client.fgts_amount.toFixed(2)
              : '0',
          });
        }}
      >
        <div
          className="profile-icon d-flex justify-content-center align-items-center me-2"
          style={{ backgroundColor: '#8675FE' }}
        >
          <OverlayTrigger
            overlay={(props) => (
              <Tooltip id={client?.document} {...props}>
                {client?.name}
              </Tooltip>
            )}
            placement="top"
          >
            <p
              className="mb-0 p-2"
              data-bs-toggle="tooltip"
              title="Some tooltip text!"
            >
              {client?.name ? initialsName(client?.name) : 'N/A'}
            </p>
          </OverlayTrigger>
        </div>
      </button>
      {serviceOrder.conjuge && (
        <button
          type="button"
          className="btn p-0"
          onClick={() => {
            handleAvatarSelected(2, {
              ...serviceOrder.conjuge,
              salary: serviceOrder.conjuge?.vlrRenda
                ? serviceOrder.conjuge.vlrRenda.toFixed(2)
                : '0',
              fgts_amount: serviceOrder.conjuge?.vlrFgts
                ? serviceOrder.conjuge.vlrFgts.toFixed(2)
                : '0',
            });
          }}
        >
          <div className="profile-icon d-flex justify-content-center align-items-center me-2 bg-warning">
            <OverlayTrigger
              overlay={(props) => (
                <Tooltip id={serviceOrder.conjuge?.cpf} {...props}>
                  {serviceOrder.conjuge?.cpf}
                </Tooltip>
              )}
              placement="top"
            >
              <p
                className="mb-0 p-2"
                data-bs-toggle="tooltip"
                title="Some tooltip text!"
              >
                {serviceOrder.conjuge?.nome
                  ? initialsName(serviceOrder.conjuge?.nome)
                  : 'N/A'}
              </p>
            </OverlayTrigger>
          </div>
        </button>
      )}
      {serviceOrder.participantes?.map((participant) => (
        <button
          type="button"
          className="btn p-0"
          onClick={() => {
            handleAvatarSelected(3, {
              ...participant,
              salary: participant.vlrRenda
                ? participant.vlrRenda.toFixed(2)
                : '0',
              fgts_amount: participant.vlrFgts
                ? participant.vlrFgts.toFixed(2)
                : '0',
            } as IDataForm);
          }}
        >
          <div
            className="profile-icon d-flex justify-content-center align-items-center me-2"
            style={{ backgroundColor: '#FF718B' }}
          >
            <OverlayTrigger
              overlay={(props) => (
                <Tooltip id={participant?.cpf} {...props}>
                  {participant?.nome}
                </Tooltip>
              )}
              placement="top"
            >
              <p
                className="mb-0 p-2"
                data-bs-toggle="tooltip"
                title="Some tooltip text!"
              >
                {participant?.nome ? initialsName(participant?.nome) : 'N/A'}
              </p>
            </OverlayTrigger>
          </div>
        </button>
      ))}
      <button
        type="button"
        className="btn p-0"
        onClick={() => handleAvatarSelected(3, {} as IDataForm)}
      >
        <div className="add-financing d-flex align-items-center justify-content-center">
          <img src={AddFinancing} alt="" className="p-2" />
        </div>
      </button>
    </div>
  );
};

export default Avatars;
