import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '~/services/api';

import { IMServiceOrderFile } from '~/models/ServiceOrderFile';
import { Modal, ModalIframe } from './styles';

interface IModalVisualizerProps {
  show: boolean;
  measurement: string;
  id_document: number;
  onHide: () => void;
}

interface IParams {
  osId: string;
}

const ModalVisualizer: React.FC<IModalVisualizerProps> = ({
  show,
  measurement,
  id_document,
  onHide,
}) => {
  const params = useParams<IParams>();
  const [documentFile, setDocumentFile] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentName, setDocumentName] = useState('');

  const loadDocument = useCallback(() => {
    if (id_document !== null || '') {
      try {
        api
          .get<IMServiceOrderFile[]>(
            `/builders/oss/${params.osId}/arquivos/get`,
            {
              params: {
                id: id_document,
              },
            }
          )
          .then(async (datos) => {
            setDocumentFile(
              String(
                datos.data.find((document) => document.id === id_document)
                  ?.file_url
              )
            );

            setDocumentType(
              String(
                datos.data.find((document) => document.id === id_document)
                  ?.mimeType
              )
            );

            setDocumentName(
              String(
                datos.data.find((document) => document.id === id_document)?.nome
              )
            );
          });
      } catch (err) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao carregar as medições!',
          'error'
        );
      }
    }
  }, [params.osId, id_document]);

  useEffect(() => {
    loadDocument();
  }, [loadDocument]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        close
        size={documentType === 'application/pdf' ? 'xl' : 'lg'}
      >
        <Modal.Header className="border-0" closeButton>
          <b>Medição {measurement}</b>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="border-0 box-image">
            {documentType === 'application/pdf' ? (
              <ModalIframe src={documentFile} title={documentName} />
            ) : (
              <img src={documentFile} alt={documentName} className="w-75" />
            )}
          </div>
          <div className="buttons-group mt-4">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Fechar
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0" />
      </Modal>
    </>
  );
};

export default ModalVisualizer;
