import React, { useCallback, useRef, useState } from 'react';
import { BsDash, BsPlus } from 'react-icons/bs';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import InputRadio from '~/components/InputRadio';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';
import { IMTerrain } from '~/models/Terrain';
import api from '~/services/api';

import OwnerBox from './Partials/OwnerBox';
import { Container } from './styles';

export interface IStepOwner {
  id: number;
  osID: number;
  name: string;
  email: string;
  cellphone: string;
  errorName: boolean;
  errorEmail: boolean;
  errorCellphone: boolean;
  createElement: boolean;
}

interface IParams {
  setStep: (step: number) => void;
}

const Owner: React.FC<IParams> = ({ setStep }) => {
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [stepQuantity, setStepQuantity] = useState(
    serviceOrder?.terreno?.qtdProprietarios ?? 0
  );
  const [stepOwner, setStepOwner] = useState<IStepOwner[]>([]);

  const handleClickSubtract = useCallback(() => {
    if (stepQuantity > 0) {
      const newSteps = stepQuantity;
      setStepQuantity(newSteps - 1);

      const newStepOwner = stepOwner.filter(
        (element, index) => index < stepOwner.length - 1
      );

      setStepOwner(newStepOwner);
    }
  }, [stepOwner, stepQuantity]);

  const handleClickPlus = useCallback(() => {
    if (stepQuantity >= 0) {
      const newSteps = stepQuantity;
      setStepQuantity(newSteps + 1);
      const newStepOwner = stepOwner;
      newStepOwner.push({
        id: newSteps + 1,
        osID: serviceOrder.id ?? 0,
        name: '',
        email: '',
        cellphone: '',
        errorName: false,
        errorEmail: false,
        errorCellphone: false,
        createElement: true,
      });

      setStepOwner(newStepOwner);
    }
  }, [stepOwner, stepQuantity, serviceOrder.id]);

  // HERE
  const handleChangeQuantity = useCallback(
    (value) => {
      if (stepQuantity === 0) {
        for (let i = 0; i < value; i += 1) {
          const newSteps = stepQuantity;
          setStepQuantity(newSteps + i);
          const newStepOwner = stepOwner;
          newStepOwner.push({
            id: i + 1,
            osID: serviceOrder.id ?? 0,
            name: '',
            email: '',
            cellphone: '',
            errorName: false,
            errorEmail: false,
            errorCellphone: false,
            createElement: true,
          });

          setStepOwner(newStepOwner);
        }
      } else if (value < stepQuantity) {
        const newOwner = [];
        for (let i = 0; i < value; i += 1) {
          const newSteps = stepQuantity;
          setStepQuantity(newSteps - 1);

          newOwner.push(stepOwner[i]);
        }
        setStepOwner(newOwner);
      } else if (value > stepQuantity) {
        for (let i = stepQuantity; i < value; i += 1) {
          const newSteps = stepQuantity;
          setStepQuantity(newSteps + i);
          const newStepOwner = stepOwner;
          newStepOwner.push({
            id: i + 1,
            osID: serviceOrder.id ?? 0,
            name: '',
            email: '',
            cellphone: '',
            errorName: false,
            errorEmail: false,
            errorCellphone: false,
            createElement: true,
          });

          setStepOwner(newStepOwner);
        }
      } else if (value === 0) {
        setStepOwner([]);
      }
      if (String(value).trim() === '') {
        setStepQuantity(0);
        setStepOwner([]);
      } else {
        const quantityValue = parseInt(value, 10);
        setStepQuantity(quantityValue);
      }
    },
    [stepOwner, stepQuantity, serviceOrder.id]
  );

  const handleSubmit = useCallback(
    async (data: IMTerrain) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          tipoPessoa: Yup.string().required('O tipo de pessoa é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const terreno = {
          ...serviceOrder.terreno,
          ...data,
          tipoPessoa: data.tipoPessoa,
          qtdProprietarios: stepQuantity,
        };

        api.put<IMTerrain>(
          `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${serviceOrder.id}/terrain/${serviceOrder.terreno?.id}`,
          terreno
        );

        setServiceOrder({
          ...serviceOrder,
          terreno,
        });
        setStep(4);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      }
    },
    [serviceOrder, setServiceOrder, setStep, stepQuantity]
  );

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={serviceOrder?.terreno}
        className="col-12"
      >
        <div className="row px-3">
          <div className="col-lg-6 mb-5">
            <div className="w-100">
              <label>Tipo de pessoa</label>
              <InputRadio
                name="tipoPessoa"
                className="mt-2 justify-content-start"
                options={[
                  {
                    id: 1,
                    value: 'Física',
                  },
                  {
                    id: 2,
                    value: 'Jurídica',
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-5">
            <div className="container-counter">
              <div className="col-3 col-lg-1">
                <button
                  type="button"
                  className="border-0 bg-transparent mx-auto mx-lg-0 d-block"
                  onClick={handleClickSubtract}
                >
                  <BsDash size={24} color="#39A887" />
                </button>
              </div>
              <div className="col-3 col-lg-8 counter">
                <input
                  name="qtdProprietarios"
                  type="number"
                  placeholder="0"
                  min={0}
                  className="insert w-100 text-center border-0"
                  value={Number(stepQuantity) || ''}
                  onChange={(e) => handleChangeQuantity(e.target.value)}
                  style={{ backgroundColor: 'transparent' }}
                />
              </div>
              <div className="col-3 col-lg-1">
                <button
                  type="button"
                  className="border-0 bg-transparent mx-auto d-block"
                  onClick={handleClickPlus}
                >
                  <BsPlus size={24} color="#39A887" />
                </button>
              </div>
            </div>
          </div>
          {stepOwner.length === 9999
            && stepOwner.map((owner, index) => (
              <div className="col-lg-12 mb-5">
                <OwnerBox
                  key={owner.id}
                  id={owner.id}
                  ownerNumber={index + 1}
                  nameValidate={owner.errorName}
                  emailValidate={owner.errorEmail}
                  cellphoneValidate={owner.errorCellphone}
                  defaultValues={{
                    name: owner.name,
                    email: owner.email,
                    cellphone: owner.cellphone,
                  }}
                />
              </div>
            ))}
        </div>
        <div className="col-lg-auto d-flex justify-content-end gap-2">
          <button
            type="button"
            className="btn btn-primary fw-semibold px-2"
            onClick={() => setStep(2)}
          >
            <MdOutlineKeyboardDoubleArrowLeft size={20} />
          </button>
          <button type="submit" className="btn btn-primary fw-semibold px-5">
            Próximo
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default Owner;
