import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IImg {
  src: string;
}

export const Modal = styled(ModalComponent)`
  .new-file-box {
    display: flex;
    justify-content: end;
    button {
      display: flex;
      b {
        font-size: 16px;
      }
    }
  }
  .aproved {
    background-color: #cde9e1 !important;
    color: #079269 !important;
    border: #01cc9d !important;
  }

  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    .modal-header {
      margin: 0px 2rem;
      margin-top: 2%;
      padding: 0px 4.5%;
    }

    h4 {
      font-weight: bold;
      font-size: 25px;
      line-height: 20px;
      color: #414141;
      line-height: 2rem;
    }

    label {
      font-weight: 500;
      color: #414141;
    }

    .input {
      border: 1px solid #d9d9d9;
      border-radius: 10px;

      input {
        font-weight: 500;
        color: #605e5e;
      }
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .box-footer {
      display: flex;
      justify-content: center;
    }

    .buttons-group {
      button {
        width: 350px;
        height: 50px;
        border-radius: 10px;
        border: 2px solid transparent;
        margin-bottom: 15px;

        @media (max-width: 425px) {
          width: 300px;
        }

        @media (max-width: 375px) {
          width: 250px;
        }
      }

      button[type='button'] {
        color: #ffff;
        font-weight: 500;
        border-color: #d9d9d9;
        background: #01cc9d;
      }
    }
  }
`;

export const Img = styled.div<IImg>`
  background: url(${(props) => props.src}), rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
`;

export const BoxDocument = styled.div`
  border-color: white;
  padding: 2.5%;
  border-radius: 5px;
  margin: 0.5% 0px;
  p {
    margin-left: 2%;
  }
  .obs-corresp {
    color: #707070;
  }
`;

export const InformationBox = styled.div`
  border: 0.5px solid;
  border-color: #fed29f;
  background-color: #fff5eb;
  padding: 2.5%;
  border-radius: 5px;
  text-align: center;
`;
