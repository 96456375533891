import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .img-box {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    .modal-header {
      margin: 0px 2rem;
      margin-top: 2%;
      padding: 0px 4.5%;
    }

    h4 {
      font-weight: bold;
      font-size: 25px;
      line-height: 20px;
      color: #414141;
      //margin-left: 2rem;
      margin-top: 20px;
      line-height: 2rem;
    }

    label {
      font-weight: 500;
      color: #414141;
    }

    .input {
      border: 1px solid #d9d9d9;
      border-radius: 10px;

      input {
        font-weight: 500;
        color: #605e5e;
      }
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .box-footer {
      display: flex;
      justify-content: center;
    }

    .buttons-group {
      button {
        width: 350px;
        height: 50px;
        border-radius: 10px;
        border: 2px solid transparent;
        margin-bottom: 15px;

        @media (max-width: 425px) {
          width: 300px;
        }

        @media (max-width: 375px) {
          width: 250px;
        }
      }

      button[type='button'] {
        color: #ffff;
        font-weight: 500;
        border-color: #d9d9d9;
        background: #01cc9d;
      }
    }
  }
`;

export const DocumentImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3% 2%;
  border: 1px dashed black;
  border-radius: 20px;
  background-color: #f5f5f6;

  .img-props {
    width: 25%;
    margin-top: 1rem;

    @media (max-width: 991px) {
      margin-top: 1rem;
    }

    @media (max-width: 375px) {
      margin-top: 0.8rem;
    }
  }
  .box-image-input {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5% 0px 0px 0px;
  }
  .box-select {
    display: flex;
    margin-top: 5px;
    color: #68bba3;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  .image-input {
    display: none;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 7rem;

  @media (max-width: 991px) {
    padding: 1rem 2rem;
  }

  label {
    font-weight: bold;
    span {
      color: #cacbcb;
    }
  }
  .form-input {
    margin-top: 15px;
    border: none;
    border-bottom: 0.3px solid #b8b9ba;
    border-radius: 0;
  }
`;
