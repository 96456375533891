import { Dialog } from 'primereact/dialog';
import { PropsWithChildren } from 'react';

// Models
import { IMTournament } from '~/models/Tournament';

interface IBoxAwardsProps extends PropsWithChildren<any> {
  onHide: () => void;
  show: boolean;
  tournament: IMTournament;
}

export function BoxTournament({ onHide, show, tournament }: IBoxAwardsProps) {
  return (
    <Dialog
      onHide={onHide}
      visible={show}
      header={`Premiação ${tournament?.name}`}
      className="w-1/2 sm-w-full"
    >
      <div className="flex flex-col flex-nowrap gap-3 mt-3">
        <p
          className="font-normal text-md"
          dangerouslySetInnerHTML={{
            __html: tournament?.description,
          }}
        />
        {!tournament?.description?.length && 'Nenhum regulamento disponível'}
      </div>
    </Dialog>
  );
}
