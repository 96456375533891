import React from 'react';

import { Modal, ModalDetail } from './styles';

interface IModalDetails {
  showDetailsAmortization: boolean;
  handleCloseDetails: () => void;
}

const ModalDetails: React.FC<IModalDetails> = ({
  showDetailsAmortization,
  handleCloseDetails,
}) => (
  <ModalDetail
    show={showDetailsAmortization}
    onHide={handleCloseDetails}
    size="l"
    centered
  >
    <Modal.Header closeButton>
      <div>
        <h2>Mais informações</h2>
        <p className="my-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
          >
            <path
              d="M4.53753 11.7579C1.13211 6.82104 0.5 6.31437 0.5 4.5C0.5 2.01471 2.51471 0 5 0C7.48529 0 9.5 2.01471 9.5 4.5C9.5 6.31437 8.86789 6.82104 5.46247 11.7579C5.23899 12.0807 4.76098 12.0807 4.53753 11.7579ZM5 6.375C6.03554 6.375 6.875 5.53554 6.875 4.5C6.875 3.46446 6.03554 2.625 5 2.625C3.96446 2.625 3.125 3.46446 3.125 4.5C3.125 5.53554 3.96446 6.375 5 6.375Z"
              fill="#39A887"
            />
          </svg>
          Apenas estados de SP, RJ, MG , PR e todos Alphavilles Brasil
        </p>
      </div>
    </Modal.Header>
    <Modal.Body className="p-0">
      <div>
        <ul>
          <li>100 % online</li>
          <li>100 % financiado</li>
          <li>Zero de entrada</li>
        </ul>
        <div className="modal-detail-amortization_data">
          <p>Carência de 6 meses</p>
          <p>Taxa- 0,5 a.m + ipca</p>
        </div>
      </div>
    </Modal.Body>
  </ModalDetail>
);

export default ModalDetails;
