import { useCallback, useEffect, useMemo, useState } from "react";

// Types
import { useAuthContext } from "../contexts/Auth";
import { IOnEvent, IUsePersistent } from "./types";

/**
 * 
 * @param param0 
 * @returns 
 */
export function usePersistent<R>({
    dataBaseName,
    objectStoreKey, 
    objectStoreName,
    objecStoreVersion = 1,
    objectStoreData,
    objectStoreIsArray, 
    objectStoreIsNested, 
    objectStoreIsObject }: IUsePersistent<R>) {

    // STATUSES
    const [success, setSuccess] = useState<boolean>(false)
    const [onFinish, setOnFinish] = useState<boolean>(false)
    const [onProgress, setOnProgress] = useState<boolean>(true)
    const [onSuccess, setOnSuccess] = useState<IOnEvent | null>(null)
    const [onCompleted, setOnCompleted] = useState<IOnEvent | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [onError, setOnError] = useState<IOnEvent | null>(null)
    const [abort, setAbort] = useState<boolean>(false)
    const [onResult, setOnResult] = useState<R[]>([])    

    // STATEMENTS
    const [INDEXED_DB_STATEMENT, setIndexedDBStatement] = useState<IDBDatabase | null>(null)
    const [INDEXED_DB_TRANSITION_STATEMENT, setIndexedDBTransitionStatement] = useState<IDBObjectStore | null>(null)
    const [INDEXED_DB_COLLECTION, setIndexedDBCollectionStatement] = useState<IDBObjectStore | null>(null)

    const { user } = useAuthContext()

    // AUX Variables
    const HAS_INDEXED_DB = useMemo(() => (!!window?.indexedDB),[])
    const OBJECT_STORE_DATA = useMemo(() => ({...objectStoreData, ...user}),[objectStoreData, user])
    const {KEY_EMAIL, KEY_ID} = useMemo(() => ({
        // @ts-ignore
        KEY_EMAIL: OBJECT_STORE_DATA?.email || null,
        // @ts-ignore
        KEY_ID: OBJECT_STORE_DATA?.id || null
    }), [OBJECT_STORE_DATA])

    useEffect(() => {
        if(!HAS_INDEXED_DB) return;

        const REQUESTED_STATEMENT = window.indexedDB.open(dataBaseName, objecStoreVersion)

        REQUESTED_STATEMENT.onupgradeneeded = (event) => {
            console.table({event})

            // @ts-ignore
            const RESULT_STATEMENT: IDBDatabase<any> | null = (event?.target?.result || null) as IDBDatabase<any> | null;

            if(!getObjectsStoreNames()?.contains(objectStoreName)) 
                setCreateObjectStore(RESULT_STATEMENT);
        }

        REQUESTED_STATEMENT.onsuccess = (event) => {

            // @ts-ignore
            const RESULT_STATEMENT: IDBDatabase<any> | null = (event?.target?.result || null) as IDBDatabase<any> | null
            
            setIndexedDBStatement(RESULT_STATEMENT)            

            setOnSuccess(() => ({
                code: 200,
                message: 'O banco de dados foi inicializado com sucesso',
                stack: [
                    {
                        hook: 'usePersistent',
                        function: 'useEffect',
                        line: 34,
                        where: 'onsuccess callback'
                    }
                ]
            }));

            (RESULT_STATEMENT as IDBDatabase).onerror = handlerIndexedDatabaseError;
            (RESULT_STATEMENT as IDBDatabase).onclose = handlerIndexedDatabaseError;
            (RESULT_STATEMENT as IDBDatabase).onabort = handlerIndexedDatabaseError;
        }

        REQUESTED_STATEMENT.onerror = (_event) => {
            setError(true);
            setOnError(() => ({
                code: -1,
                message: 'Não foi possivel inicializar o banco de dados',
                stack: [
                    {
                        hook: 'usePersistent',
                        function: 'useEffect',
                        line: 34,
                        where: 'onerror callback'
                    }
                ]
            }));
        }
    },[HAS_INDEXED_DB])

    useEffect(() => {
        if(!INDEXED_DB_STATEMENT) return;        

        handleSetOnStore()

        return () => {
            INDEXED_DB_STATEMENT.close();
        }
    }, [INDEXED_DB_STATEMENT])

    useEffect(() => {
        if(!user || !INDEXED_DB_STATEMENT) return;

        console.log('USEEFFECT @ SET ON STORE BY USER AUTH CONTEXT')
        console.table({user, KEY_EMAIL, KEY_ID})

        handleSetOnStore()
    },[user])

    const handleSetOnStore = useCallback(async () => {
        try {
            if(!INDEXED_DB_STATEMENT)
                throw new Error('The database statement is missing');

            if(!OBJECT_STORE_DATA || Object.keys(OBJECT_STORE_DATA).length <= 0)
                throw new Error('The object store data is missing to set on store');
            
            setOnProgress(true)
            setOnFinish(false);

            if(!(await setGetAndPutOnStore())) {
                await setAddOnStore();                
            }
        } catch (error) {
            console.table(error)
        } finally {
            setOnFinish(true);
            setOnProgress(false);
        }
    }, [INDEXED_DB_STATEMENT, OBJECT_STORE_DATA])

    const setAddOnStore = useCallback(async () => {
        try {
            if(!INDEXED_DB_STATEMENT) throw new Error('The database statement is missing');

            const result = await new Promise((resolver, reject) => {
                const INDEXED_DB_TRANSITION_STATEMENT = INDEXED_DB_STATEMENT
                    .transaction(objectStoreName, 'readwrite')
                    .objectStore(objectStoreName);
    
                if(!INDEXED_DB_TRANSITION_STATEMENT)
                    throw new Error('The database transaction statement is missing');
    
                const INDEXED_DB_TRANSITION_ADD_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .add(OBJECT_STORE_DATA);
    
                INDEXED_DB_TRANSITION_ADD_STATEMENT.onsuccess = (event) => {
                    setOnSuccess(() => ({
                        code: 200,
                        message: 'Transação de registro concluida com sucesso',
                        origin: 'INDEXED_DB_TRANSITION_STATEMENT@ADD',
                        stack: [
                            {
                                hook: 'usePersistent',
                                function: 'useEffect',
                                line: 121,
                                where: 'onsuccess callback' 
                            }
                        ]
                    }))

                    // @ts-ignore
                    resolver(event.target?.result || null)
                }
    
                INDEXED_DB_TRANSITION_ADD_STATEMENT.onerror = (event:any) => {
                    const ERROR_CODE = event?.target?.errorCode || -1
                    const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'
    
                    setOnError(() => ({
                            code: ERROR_CODE,
                            message: ERROR_MESSAGE,
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@ADD',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'useEffect',
                                    line: 121,
                                    where: 'onerror callback' 
                                }
                            ]
                    }))

                    reject(null)
                }
            })

            return result
        } catch (error) {
            console.table(error)
        }
    },[INDEXED_DB_STATEMENT, OBJECT_STORE_DATA])

    /**
     * @deprecated
     */
    const setPutOnStore = useCallback(async () => {
        try {
            if(!INDEXED_DB_STATEMENT) throw new Error('The database statement is missing');
            if(!INDEXED_DB_TRANSITION_STATEMENT) throw new Error('The database transition statement is missing');
            if(!KEY_ID || !KEY_EMAIL) throw new Error('The KEYS to put is not valid');

            const result = new Promise((resolver, reject) => {
                const INDEXED_DB_REQUEST_PUT_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .put(OBJECT_STORE_DATA);
    
                INDEXED_DB_REQUEST_PUT_STATEMENT.onsuccess = (event) => {
                    setOnSuccess(() => ({
                        code: 200,
                        message: 'Transação de atualização de registro concluida com sucesso',
                        origin: 'INDEXED_DB_TRANSITION_STATEMENT@PÙT',
                        stack: [
                            {
                                hook: 'usePersistent',
                                function: 'setPutOnStore',
                                line: 161,
                                where: 'onsuccess callback',
                                event
                            }
                        ]
                    }))

                    // @ts-ignore
                    resolver(event.targe?.result || null);
                }
    
                INDEXED_DB_REQUEST_PUT_STATEMENT.onerror = (event:any) => {
                    const ERROR_CODE = event?.target?.errorCode || -1
                    const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'
    
                    setOnError(() => ({
                            code: ERROR_CODE,
                            message: ERROR_MESSAGE,
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@PUT',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'useEffect',
                                    line: 121,
                                    where: 'onerror callback' 
                                }
                            ]
                    }))

                    reject(null)
                }
            })

            return result;
        } catch (error) {
            console.table({error})
        }
    },[INDEXED_DB_STATEMENT, KEY_EMAIL, KEY_ID, OBJECT_STORE_DATA, INDEXED_DB_TRANSITION_STATEMENT])

    const setGetAndPutOnStore = useCallback(async () => {
     try {
            if(!INDEXED_DB_STATEMENT) throw new Error('The datbase statement is missing');
            if(!KEY_ID || !KEY_EMAIL) throw new Error('The KEYS to GET is not valid');

            console.log('setGetAndPutOnStore')
            console.table({
                KEY_EMAIL,
                KEY_ID
            })

            const INDEXED_DB_TRANSITION_STATEMENT = INDEXED_DB_STATEMENT
                .transaction(objectStoreName, 'readwrite')
                .objectStore(objectStoreName);

            if(!INDEXED_DB_TRANSITION_STATEMENT)
                throw new Error('The database transaction statement is missing');

            const result: R | null = await new Promise((resolver, reject) => {
                const INDEXED_DB_REQUEST_GET_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .get(KEY_EMAIL);

                INDEXED_DB_REQUEST_GET_STATEMENT.onsuccess = (event) => {
                    
                    setOnSuccess(() => ({
                        code: 200,
                        message: 'Transação de recuperação de registro concluida com sucesso',
                        origin: 'INDEXED_DB_TRANSITION_STATEMENT@GET',
                        stack: [
                            {
                                hook: 'usePersistent',
                                function: 'setGetOnStore',
                                line: 221,
                                where: 'onsuccess callback',
                                event 
                            }
                        ]
                    }))

                    const INDEXED_DB_REQUEST_PUT_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .put(OBJECT_STORE_DATA);
    
                    INDEXED_DB_REQUEST_PUT_STATEMENT.onsuccess = (event) => {
                        setOnSuccess(() => ({
                            code: 200,
                            message: 'Transação de atualização de registro concluida com sucesso',
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@PÙT',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'setPutOnStore',
                                    line: 161,
                                    where: 'onsuccess callback',
                                    event
                                }
                            ]
                        }))

                        // @ts-ignore
                        resolver(event.targe?.result || null);
                    }
        
                    INDEXED_DB_REQUEST_PUT_STATEMENT.onerror = (event:any) => {
                        const ERROR_CODE = event?.target?.errorCode || -1
                        const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'
        
                        setOnError(() => ({
                                code: ERROR_CODE,
                                message: ERROR_MESSAGE,
                                origin: 'INDEXED_DB_TRANSITION_STATEMENT@PUT',
                                stack: [
                                    {
                                        hook: 'usePersistent',
                                        function: 'useEffect',
                                        line: 121,
                                        where: 'onerror callback' 
                                    }
                                ]
                        }))

                        reject(null)
                    }

                    // @ts-ignore
                    resolver(event.target?.result || null);
                    // APPLY PUT TO SAME TRANSACTION STATEMENT IN ORDER OF LIFECYCLE
                }

                INDEXED_DB_REQUEST_GET_STATEMENT.onerror = (event:any) => {
                    const ERROR_CODE = event?.target?.errorCode || -1
                    const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'

                    setOnError(() => ({
                            code: ERROR_CODE,
                            message: ERROR_MESSAGE,
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@GET',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'useEffect',
                                    line: 221,
                                    where: 'onerror callback' 
                                }
                            ]
                    }))

                    setIndexedDBTransitionStatement(null)

                    reject(null)
                }
            })

            if(result) setOnResult([result]);

            return result;
        } catch (error) {
            console.table({error})
        }
    },[INDEXED_DB_STATEMENT, KEY_EMAIL, KEY_ID, OBJECT_STORE_DATA])

    const setGetOnStore = useCallback(async () => {
     try {
            if(!INDEXED_DB_STATEMENT) throw new Error('The datbase statement is missing');
            if(!KEY_ID || !KEY_EMAIL) throw new Error('The KEYS to GET is not valid');

            console.log('setGetOnStore')
            console.table({
                KEY_EMAIL,
                KEY_ID
            })

            const INDEXED_DB_TRANSITION_STATEMENT = INDEXED_DB_STATEMENT
                .transaction(objectStoreName, 'readwrite')
                .objectStore(objectStoreName);

            if(!INDEXED_DB_TRANSITION_STATEMENT)
                throw new Error('The database transaction statement is missing');

            const result: R | null = await new Promise((resolver, reject) => {
                const INDEXED_DB_REQUEST_GET_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .get(KEY_EMAIL);

                INDEXED_DB_REQUEST_GET_STATEMENT.onsuccess = (event) => {
                    
                    setOnSuccess(() => ({
                        code: 200,
                        message: 'Transação de recuperação de registro concluida com sucesso',
                        origin: 'INDEXED_DB_TRANSITION_STATEMENT@GET',
                        stack: [
                            {
                                hook: 'usePersistent',
                                function: 'setGetOnStore',
                                line: 221,
                                where: 'onsuccess callback',
                                event 
                            }
                        ]
                    }))

                    // @ts-ignore
                    resolver(event.target?.result || null);
                    // APPLY PUT TO SAME TRANSACTION STATEMENT IN ORDER OF LIFECYCLE
                }

                INDEXED_DB_REQUEST_GET_STATEMENT.onerror = (event:any) => {
                    const ERROR_CODE = event?.target?.errorCode || -1
                    const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'

                    setOnError(() => ({
                            code: ERROR_CODE,
                            message: ERROR_MESSAGE,
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@GET',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'useEffect',
                                    line: 221,
                                    where: 'onerror callback' 
                                }
                            ]
                    }))

                    setIndexedDBTransitionStatement(null)

                    reject(null)
                }
            })

            if(result) setOnResult([result]);

            return result;
        } catch (error) {
            console.table({error})
        }
    },[INDEXED_DB_STATEMENT, KEY_EMAIL, KEY_ID, OBJECT_STORE_DATA])

    const setGetAllOnStore = useCallback(async () => {
        if(!INDEXED_DB_STATEMENT) throw new Error('The datbase statement is missing');

            const result: R[] | null = await new Promise((resolver, reject) => {                

                if(!KEY_ID || !KEY_EMAIL) throw new Error('The KEYS to put is not valid');

                const INDEXED_DB_TRANSITION_STATEMENT = INDEXED_DB_STATEMENT
                    .transaction(objectStoreName, 'readwrite')
                    .objectStore(objectStoreName);

                if(!INDEXED_DB_TRANSITION_STATEMENT)
                    throw new Error('The database transaction statement is missing');

                const INDEXED_DB_TRANSITION_ADD_STATEMENT = INDEXED_DB_TRANSITION_STATEMENT
                    .getAll();

                INDEXED_DB_TRANSITION_ADD_STATEMENT.onsuccess = (event) => {
                    setOnSuccess(() => ({
                        code: 200,
                        message: 'Transação de recuperação de registro concluida com sucesso',
                        origin: 'INDEXED_DB_TRANSITION_STATEMENT@ALL',
                        stack: [
                            {
                                hook: 'usePersistent',
                                function: 'setGeAlltOnStore',
                                line: 301,
                                where: 'onsuccess callback' 
                            }
                        ]
                    }))

                    // @ts-ignore
                    resolver(event.target?.result || null);
                }

                INDEXED_DB_TRANSITION_ADD_STATEMENT.onerror = (event:any) => {
                    const ERROR_CODE = event?.target?.errorCode || -1
                    const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'

                    setOnError(() => ({
                            code: ERROR_CODE,
                            message: ERROR_MESSAGE,
                            origin: 'INDEXED_DB_TRANSITION_STATEMENT@ALL',
                            stack: [
                                {
                                    hook: 'usePersistent',
                                    function: 'useEffect',
                                    line: 350,
                                    where: 'onerror callback' 
                                }
                            ]
                    }))

                    reject(null)
                }
            })

            return result
    }, [])

    function setCreateObjectStore(idbDatabaseStatement: IDBDatabase | null = null){
        try {
            if(!idbDatabaseStatement) throw new Error('The database statement is missing');

            const OBJECT_STORE_STATEMENT = (idbDatabaseStatement as IDBDatabase)?.createObjectStore(
                objectStoreName, 
                {
                    keyPath: 'email',
                    // autoIncrement: true
                }
            )

            OBJECT_STORE_STATEMENT?.createIndex('name', 'name', {unique: false})
            OBJECT_STORE_STATEMENT?.createIndex('email', 'email', {unique: true})
            OBJECT_STORE_STATEMENT?.createIndex('id', 'id', {unique: true})

            if(!OBJECT_STORE_STATEMENT) return;            

            OBJECT_STORE_STATEMENT.transaction.oncomplete  = (event) => {
                console.log('OBJECT_STORE_STATEMENT @ TRANSACTION COMPLETED')
                console.table({event})

                setIndexedDBStatement(idbDatabaseStatement);
                setIndexedDBCollectionStatement(OBJECT_STORE_STATEMENT || null);

                setOnCompleted(() => ({
                    code: 200,
                    message: 'A coleção de usuários foi criada com sucesso',
                    stack: [
                        {
                            hook: 'usePersistent',
                            function: 'useEffect',
                            line: 35,
                            where: 'oncompleted callback'
                        }
                    ]
                }));
            }

            OBJECT_STORE_STATEMENT.transaction.onerror  = (event: any) => {
                console.log('OBJECT_STORE_STATEMENT @ TRANSACTION ERROR')
                console.table({event})

                const ERROR_CODE = event?.target?.errorCode || -1
                const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'

                setOnError(() => ({
                    code: ERROR_CODE,
                    message: ERROR_MESSAGE,
                    stack: [
                        {
                            hook: 'usePersistent',
                            function: 'useEffect',
                            line: 35,
                            where: 'onerror callback'
                        }
                    ]
                }));

                idbDatabaseStatement.close();
            }
        } catch (error) {
            
        }
    }

    function getObjectsStoreNames(idbDatabaseStatement: IDBDatabase | null = null) {
        try {
            if(!idbDatabaseStatement) throw new Error('The database statement is missing');

            return idbDatabaseStatement.objectStoreNames;
        } catch (error) {
            
        }
    }

    function handlerIndexedDatabaseError(event: any) {
        try {
            const ERROR_CODE = event?.target?.errorCode || -1
            const ERROR_MESSAGE = event?.target?.message || 'Houve um erro na operação'

            setOnError(() => ({
                code: ERROR_CODE,
                message: ERROR_MESSAGE,
                origin: 'handlerIndexedDatabaseError',
                stack: [
                    {
                        hook: 'usePersistent',
                        function: 'handlerIndexedDatabaseError',
                        line: 82,
                        where: 'onerror callback' 
                    }
                ]
            }))
        } catch (error) {
            
        }
    }

    function handlerIndexedDatabaseSuccess(event: any) {
        try {
            const SUCCESS_CODE = event?.target?.successCode || -1
            const SUCCESS_MESSAGE = event?.target?.message || 'Operação concluida com sucesso'

            setOnSuccess(() => ({
                code: SUCCESS_CODE,
                message: SUCCESS_MESSAGE,
                origin: 'handlerIndexedDatabaseSuccess',
                stack: [
                    {
                        hook: 'usePersistent',
                        function: 'handlerIndexedDatabaseSuccess',
                        line: 104,
                        where: 'onsuccess callback' 
                    }
                ]
            }))
        } catch (error) {
            
        }
    }

    // console.table({
    //     HAS_INDEXED_DB,
    //     INDEXED_DB_STATEMENT,
    //     INDEXED_DB_COLLECTION        
    // })

    return {
        INDEXED_DB_STATEMENT,
        handleSetOnStore,
        onCompleted,
        onSuccess,
        onError,
        onResult,
        onFinish,
        onProgress,
        success,
        error,
        abort,
    }
}