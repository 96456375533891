import {
  FC, useCallback, useEffect, useRef, useState
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import getValidationErrors from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import { useServiceOrder } from '~/hooks/ServiceOrder';

interface IPersonalData {
  name?: string;
  document?: string;
  email?: string;
  celphone?: string;
}

interface IParams {
  setStep: (step: number) => void;
}

const PersonalData: FC<IParams> = ({ setStep }) => {
  const formRef = useRef<FormHandles>(null);
  const [nameLengthError, setNameLengthError] = useState('');
  const [cpfError, setCPFError] = useState('');
  const [os, setOS] = useState({} as IPersonalData);
  const { serviceOrder, setServiceOrder } = useServiceOrder();

  useEffect(() => {
    setOS({
      name: serviceOrder?.cliente?.nome,
      document: serviceOrder?.cliente?.documento,
      email: serviceOrder?.cliente?.email,
      celphone: serviceOrder?.cliente?.celular,
    });
  }, [serviceOrder]);

  const handleValidateCPF = useCallback((value?: string) => {
    if (!value) return false;
    return cpfValidator.isValid(value.replace(/\D/g, ''));
  }, []);

  const handleSubmit = useCallback(
    async (data: IPersonalData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          document: Yup.string().required('O CPF é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatório'),
          celphone: Yup.string().required('O celular é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const nameParts = data?.name?.trim().split(' ');

        if (
          (nameParts && nameParts?.length <= 1)
          || !handleValidateCPF(data.document)
        ) {
          nameParts && nameParts.length <= 1
            ? setNameLengthError('Digite seu nome completo')
            : setNameLengthError('');

          handleValidateCPF(data.document) === false
            ? setCPFError('CPF incorreto')
            : setCPFError('');
        } else {
          setNameLengthError('');
          setCPFError('');

          setServiceOrder({
            ...serviceOrder,
            cliente: {
              ...serviceOrder.cliente,
              nome: data.name,
              documento: data.document,
              email: data.email,
              celular: data.celphone,
            },
          });
          setStep(2);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleValidateCPF, serviceOrder, setServiceOrder, setStep]
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={os}
      className="col-12"
    >
      <div className="row px-3">
        <div className="col-lg-6 mb-5">
          <label className="w-100">
            Nome Completo: <b className="text-primary">*</b>
            <Input
              name="name"
              placeholder="Nome Completo"
              className="input mt-2"
            />
            {nameLengthError && (
              <span className="small text-danger error">{nameLengthError}</span>
            )}
          </label>
        </div>
        <div className="col-lg-6 mb-5">
          <label className="w-100">
            CPF: <b className="text-primary">*</b>
            <InputMask
              kind="cpf"
              name="document"
              placeholder="000.000.000-00"
              className="input mt-2"
              value={os.document}
            />
            {cpfError && (
              <span className="small text-danger error">{cpfError}</span>
            )}
          </label>
        </div>
        <div className="col-lg-6 mb-5">
          <label className="w-100">
            Email: <b className="text-primary">*</b>
            <Input
              type="email"
              name="email"
              placeholder="email@example.com."
              className="input mt-2"
            />
          </label>
        </div>
        <div className="col-lg-6 mb-5">
          <label className="w-100">
            Celular: <b className="text-primary">*</b>
            <InputMask
              kind="cel-phone"
              name="celphone"
              placeholder="()"
              className="input mt-2"
              value={os.celphone}
            />
          </label>
        </div>
      </div>
      <div className="button-group d-flex justify-content-end mt-5 mb-5 px-3">
        <button type="submit" className="btn btn-primary btn-next fw-semibold">
          Próximo
        </button>
      </div>
    </Form>
  );
};

export default PersonalData;
