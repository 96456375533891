import styled from 'styled-components';

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.div`
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .notify {
    border-radius: 50%;
    background: #f5f5f5;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 46px;
    height: 46px;

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      border-radius: 24px;
      right: 12px;
      top: 12px;
    }
  }

  .search {
    border: 1px solid #e4e4e4;
    border-radius: 35px;
    height: 56px;
    background-color: #ffffff;
    :hover {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    :hover input {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    input {
      color: #414142;
      ::placeholder {
        color: #b9b8bc;
      }
    }
  }
  .espacio {
    @media (max-width: 992px) {
      width: 70vw;
    }
  }

  .btn-os {
    border-radius: 26px;
    width: 16px;
    height: 42px;
    color: white;
    background-color: #3aa787;
    :hover {
      background-color: #0a9169;
    }
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 200px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 150px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 260px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 150px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 200px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;
