import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px auto;
  height: 15%;
  gap: 52px;
  .p-relative {
    position: relative;
  }
  h2 {
    color: #414142;
    font-weight: 500 !important;
    font-size: 20px;
    margint-top: 7px !important;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    background-color: #96d9ff !important;
  }
  .dot-3 {
    background-color: #ffba69 !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
`;
