import styled from 'styled-components';

export const Container = styled.section`
  .box-post {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }
  .box-img {
    width: 300px;
    border-radius: 12px 12px 0 0;
  }

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* Title */

    color: #414142;
  }

  .color-verde {
    /* Primary */

    color: #39a887;
  }
  .bd-box-2 {
    width: 300px;
    @media (min-width: 700px) {
      width: 500px;
    }
  }

  .scroll {
    overflow-y: scroll;
    height: 100px;
    ::-webkit-scrollbar {
      width: 6px;
    }
  }
`;
