export function Mock() {
    return (
        <>
        {
            Array(4).fill(null).map((_d,f) => (
                <button
                    key={f}
                    type="button"
                    className='
                        flex
                        flex-col
                        items-center
                        justify-center
                        gap-[4px]
                        min-w-[77px]
                        md:w-auto
                        h-[77px]
                        p-3
                        rounded-[10px]
                        outline-none
                        bg-gray-200
                        animate-pulse
                        pointer-events-none
                        cursos-not-allowed
                    '
                >
                    <span className="w-full flex-1 bg-gray-300 animate-pulse rounded-md"></span>
                    <span className="w-full h-2 bg-gray-300 animate-pulse rounded-md"></span>
                </button>
            ))
        }
        </>
    )
}

export function MockStep() {
    return (
        <>
        {
            Array(4).fill(null).map((_d,f) => (
                <span
                    key={f}                    
                    className='
                        flex
                        flex-col
                        items-center
                        justify-center
                        gap-[4px]
                        flex-1
                        min-w-[216px]
                        h-[35px]
                        rounded-[10px]
                        outline-none
                        animate-pulse
                        pointer-events-none
                        cursos-not-allowed
                    '
                >
                    <span className="w-1/2 h-2 flex-1 bg-gray-300 animate-pulse rounded-[5px]"></span>
                    <span className="w-full h-4 bg-gray-200 animate-pulse rounded-[5px]"></span>
                </span>
            ))
        }
        </>
    )
}

export function MockImage() {
    return (
        <div
          className='
            w-full 
            md:w-1/2 
            h-[205px] 
            flex 
            flex-row
            items-center
            justify-center 
            p-[12px]
            rounded-md
            bg-gray-200
            animate-pulse
          '
        >
          <span
            className='
              w-2/3
              h-full
              flex
              flex-row
              flex-nowrap
              items-center
              justify-center
              rounded-[10px]
              overflow-hidden
              object-fill
              bg-gray-300
              animate-pulse
            '
          >
            <span className='w-full h-full'/>
          </span>
        </div>
    )
}

export function MockButton(){
    return (
        <div className='w-full h-14 mt-12 flex flex-row flex-nowrap items-center justify-end gap-4 rounded-md bg-gray-200 p-8 animate-pulse'>
            <span
                className='
                    flex 
                    w-[62px] 
                    h-[54px] 
                    rounded-[20px] 
                    bg-gray-300
                    animate-pulse
                '
            >            
            </span>
            <span
                className='
                    flex 
                    w-full
                    md:w-[331px]
                    h-[54px]
                    rounded-[20px] 
                    bg-gray-300
                    animate-pulse
                '
            >            
            </span>
            <span
                className='
                    flex 
                    w-full
                    md:w-[331px]
                    h-[54px]
                    rounded-[20px] 
                    bg-gray-300
                    animate-pulse
                '
            >            
            </span>
        </div>
    )
}