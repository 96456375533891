import { Form } from '@unform/web';
import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

// Utils
import getValidationErros from '~/utils/getValidationsErrors';

// Assets
import logo from '~/assets/logos/logo.svg';

// Components
import Input from '~/components/Input';

// Hooks
import { useClient } from '~/hooks/Client';
import { useAuthContext } from '~/hooks/contexts/Auth';

// Styles
import { FormHandles } from '@unform/core';
import { AxiosError } from 'axios';
import { Content } from '../../styles';

interface IFormData {
  email: string;
  password: string;
  route: string;
}


export function Main(){
  const formRef = useRef<FormHandles>(null);
  const { signIn, onSignIn } = useAuthContext();  
  const { client } = useClient();  

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {        
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = data;
        const formData = { email, password };
        await signIn(formData);
        
      } catch (error) {        
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else if (
          (error as unknown as AxiosError)?.response?.data?.error
          !== 'Unauthorized'
        ) {
          Swal.fire(
            'Opss...',
            'Assinatura vencida. Renove seu plano para ter acesso novamente ao sistema.',
            'error'
          );
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <Content className="bg-dark-1 col-lg-6 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center px-5 pt-4">
        <img src={logo} alt="logo" className="logo" />
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="px-sm-4 px-md-5 px-lg-0"
        >
          <div className="mb-5">
            <h1 className="h2 fw-semibold text-dark-gray">Entrar</h1>
            <p className="text-gray">
              Bem-vindo de volta! Por favor acesse sua conta e aproveite
            </p>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="mb-2 fw-medium">
              E-mail
            </label>
            <Input
              type="email"
              placeholder="name@example.com"
              name="email"
              className="input"
              value={client?.email}
              disabled={onSignIn}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password" className="mb-2 fw-medium">
              Senha
            </label>
            <Input
              type="password"
              placeholder="******"
              name="password"
              className="input"
              disabled={onSignIn}
            />
          </div>
          <div className="row">
            <div className="col" />
            <div className="col-6 mb-5 mt-2">
              <Link
                to={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
                className="text-gray text-end fw-medium d-flex justify-content-end"
              >
                Esqueceu a senha?
              </Link>
            </div>
          </div>
          <div className="mb-5">
            <button
              type="submit"
              disabled={onSignIn}
              title={`${onSignIn ? 'Aguarde...' : 'Entrar'}`}
              className="btn btn-primary mb-5 w-100 fw-semibold disabled:cursor-not-allowed"
            >
              Entrar
            </button>
            {process.env.REACT_APP_DEMO_APP === 'true' && (
              <>
                <p className="text-gray text-center">
                  Não possui conta?{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/cadastre-se`}
                    className="text-green fw-medium"
                  >
                    Cadastre-se
                  </Link>
                </p>
                <hr />
              </>
            )}
          </div>
        </Form>
    </Content>
  )
}