/* FIXEM - eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { BsWhatsapp } from 'react-icons/bs';
import { useAuthContext } from '~/hooks/contexts/Auth';
import { IMTask } from '~/models/BoardTask';
import { Container } from './styles';

interface IItemColumnProps {
  index: number;
  card: IMTask;
  iDx: string;
  nameList: string;
}

const ItemColumn: React.FC<IItemColumnProps> = ({ card, index, iDx }) => {
  const { user } = useAuthContext();
  const [name, setName] = useState<string>();

  const splitName = useCallback(async () => {
    let userName = '';
    const nameParts = user!.name.split(' ');
    userName = `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
    setName(userName);
  }, [user]);

  useEffect(() => {
    splitName();
  }, [splitName]);

  const handleClickWhatsapp = useCallback((value: string | undefined) => {
    const urlComplete = `https://api.whatsapp.com/send?phone=55${value
      ?.replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(
        ' ',
        ''
      )}&text=Olá Somos do MCF, muito obrigado por nos escolher!`;
    window.open(urlComplete, '_blank', 'noopener, noreferrer');
  }, []);

  return (
    <Draggable key={iDx} draggableId={`${iDx}-card`} index={index}>
      {(provided: any) => (
        <Container
          className={card?.id}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className="header-item-column">
            <div className="avatar-container">
              <p className=" fw-semibold small text-dark-gray mb-0">
                {card?.os_id} - {name}
              </p>
            </div>
          </div>
          <div className="px-2 pt-1 bg-light rounded fw-light small text-muted">
            <p className="mb-0 small">
              <b className="bold-element">Status:</b>{' '}
              {card.service_order?.status?.status}
            </p>
            <p className="mb-0 small">
              <b className="bold-element">Data:</b>{' '}
              {card.service_order?.created_at
                ? format(new Date(card.service_order.created_at), 'dd/MM/yyyy')
                : ''}
            </p>
            <p className="mb-0">{card.service_order?.cliente?.email}</p>
            {card.service_order?.cliente?.state?.sigla && (
              <p className="mb-0 small">
                {card.service_order.cliente?.state?.sigla},{' '}
                {card.service_order.cliente?.municipio}
              </p>
            )}
            {card.service_order?.cliente?.celular && (
              <div className="row">
                <div className="col-10">
                  <p className="mb-1 small">
                    {card.service_order.cliente?.celular}
                  </p>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={() => handleClickWhatsapp(card.service_order.cliente?.celular)
                    }
                  >
                    <BsWhatsapp size={20} color="#25D366" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </Container>
      )}
    </Draggable>
  );
};

export default ItemColumn;
