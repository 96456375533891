import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addYears,
  parseISO,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

import api from '~/services/api';
import { ITask } from '~/models/Home/Task';
import { IDayOfMonth } from '~/models/Home/DayOfMonth';
import { ITaskResponse } from '~/models/Home/TaskResponse';
import { IOption } from '~/components/Select';
import ModalAddTask from './Partials/Modals/ModalAddEditTask';
import ModalEditTask from './Partials/Modals/ModalAddEditTask';
import ModalTask from './Partials/Modals/ModalTask';
import CardTask from './Partials/Cards/CardTask';
import CardDate from './Partials/Cards/CardDate';

import { Container } from './styles';

const Tasks: React.FC = () => {
  const [showTask, setShowTask] = useState(false);
  const [showModaAddlTask, setShowModaAddlTask] = useState(false);
  const [showModalEditTask, setShowModalEditTask] = useState(false);
  const [taskSelected, setTaskSelected] = useState({} as ITask);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [daySelected, setDaySelected] = useState({} as IDayOfMonth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tasks, setTasks] = useState<ITask[]>([]);

  useEffect(() => {
    api
      .get<ITaskResponse[]>('builders/tasks', {
        params: {
          date: format(dateSelected, 'yyyy-MM-dd'),
        },
      })
      .then((response) => {
        const data = response.data.map<ITask>((task) => ({
          ...task,
          categories: task.categories?.split(','),
          date: format(parseISO(task.date), 'dd/MM/yyyy'),
          datetime: parseISO(task.date),
        }));

        setTasks(data);
      });
  }, [dateSelected]);

  const start = useMemo(() => startOfMonth(dateSelected), [dateSelected]);

  const end = useMemo(() => endOfMonth(dateSelected), [dateSelected]);

  const days = useMemo(() => eachDayOfInterval({ start, end }), [end, start]);

  const daysOfMonth = useMemo(() => {
    const datesAux: IDayOfMonth[][] = [];
    let dates: IDayOfMonth[] = [];
    days.forEach((day, index) => {
      const date = {
        date: format(day, 'dd'),
        dayOfWeek: `${format(day, 'EEE', {
          locale: ptBR,
        })[0].toUpperCase()}${format(day, 'EEE', { locale: ptBR }).slice(
          1,
          3
        )}`,
        fullDate: day,
      };
      dates.push(date);

      if ((index + 1) % 5 === 0 && dates.length > 0) {
        datesAux.push(dates);
        dates = [];
      }
      if (
        (index + 1) % 5 !== 0
        && dates.length > 0
        && index + 1 === days.length
      ) {
        datesAux.push(dates);
        dates = [];
      }
    });
    return datesAux;
  }, [days]);

  const months = useMemo(() => {
    const monthsAux: IOption[] = [];
    const currentDate = new Date();

    for (let i = 0; i < 12; i += 1) {
      const date = new Date(2023, i, 1);
      const name = format(date, 'MMMM', {
        locale: ptBR,
      });
      const month = {
        id: i,
        value: `${name[0].toUpperCase()}${name.slice(1, name.length)}`,
        selected: i === currentDate.getMonth(),
      };
      monthsAux.push(month);
    }

    return monthsAux;
  }, []);

  const years = useMemo(() => {
    const startYear = 2010;
    const endYear = addYears(new Date(), 30).getFullYear();
    const currentDate = new Date();

    const yearsAux: IOption[] = [];
    for (let year = startYear; year <= endYear; year += 1) {
      yearsAux.push({
        id: year,
        value: String(year),
        selected: year === currentDate.getFullYear(),
      });
    }

    return yearsAux;
  }, []);

  useEffect(() => {
    if (Object.keys(daySelected).length === 0) {
      const daysIndex = daysOfMonth.findIndex((datesAux) => {
        const dateAux = datesAux.find(
          (date) => parseInt(date.date, 10) === new Date().getDate()
        );
        return !!dateAux;
      });

      if (daysIndex >= 0) {
        const day = daysOfMonth[daysIndex].find(
          (date) => parseInt(date.date, 10) === new Date().getDate()
        );
        if (day) {
          setCurrentIndex(daysIndex);
          setDaySelected(day);
        }
      }
    }
  }, [daySelected, daysOfMonth]);

  const handleChangeMonth = useCallback(
    (e) => {
      const newDateSelected = new Date(
        dateSelected.getFullYear(),
        e.id,
        parseInt(daySelected.date, 10)
      );
      setDateSelected(newDateSelected);
    },
    [dateSelected, daySelected.date]
  );

  const handleChangeYear = useCallback(
    (e) => {
      const newDateSelected = new Date(
        e.id,
        dateSelected.getMonth(),
        parseInt(daySelected.date, 10)
      );
      setDateSelected(newDateSelected);
    },
    [dateSelected, daySelected.date]
  );

  const handleSelect = useCallback(
    (selectedDay: IDayOfMonth) => {
      const newDateSelected = new Date(
        dateSelected.getFullYear(),
        dateSelected.getMonth(),
        parseInt(selectedDay.date, 10)
      );
      setDateSelected(newDateSelected);
      setDaySelected(selectedDay);
    },
    [dateSelected]
  );

  const handlePrev = useCallback(() => {
    setCurrentIndex((state) => (state === 0 ? daysOfMonth.length - 1 : state - 1));
  }, [daysOfMonth.length]);

  const handleNext = useCallback(() => {
    setCurrentIndex((state) => (state + 1 === daysOfMonth.length ? 0 : state + 1));
  }, [daysOfMonth.length]);

  const handleClose = useCallback(() => {
    setShowTask(false);
    setShowModaAddlTask(false);
    setShowModalEditTask(false);
  }, []);

  const handleClickTasks = useCallback((task) => {
    setTaskSelected(task);
    setShowTask(true);
  }, []);

  return (
    <Container className="row d-flex px-4 py-2">
      <div className="sub-container-date mt-3">
        <CardDate
          months={months}
          years={years}
          currentIndex={currentIndex}
          daysOfMonth={daysOfMonth}
          daySelected={daySelected}
          onHandleSelect={handleSelect}
          onHandleChangeMonth={handleChangeMonth}
          onHandleChangeYear={handleChangeYear}
          onHandlePrev={handlePrev}
          onHandleNext={handleNext}
          onSetShowModaAddlTask={setShowModaAddlTask}
        />
      </div>

      <div className="mt-3 sub-container-task">
        <CardTask tasks={tasks} onHandleClickTasks={handleClickTasks} />
      </div>

      <ModalTask
        show={showTask}
        taskSelected={taskSelected}
        onSetShowModalTask={setShowModalEditTask}
        onHandleClose={handleClose}
      />

      <ModalAddTask
        show={showModaAddlTask}
        tasksData={tasks}
        onHandleClose={handleClose}
        onSetTasks={setTasks}
      />

      <ModalEditTask
        show={showModalEditTask}
        tasksData={tasks}
        taskSelected={taskSelected}
        onHandleClose={handleClose}
        onSetTasks={setTasks}
      />
    </Container>
  );
};

export default Tasks;
