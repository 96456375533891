import React, { PropsWithChildren, useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { formatPrice } from '~/utils/format';
import { Box, ChartContainer } from './styles';

interface ICardAnnualProjecttionProps extends PropsWithChildren<any> {
  os?: IMServiceOrder
}

const CardAnnualProjection: React.FC = ({ os }: ICardAnnualProjecttionProps) => {
  const { serviceOrder: OSState } = useServiceOrder();

  // @ts-ignore
  const serviceOrder: Required<IMServiceOrder> = os || OSState

  const categories = useMemo(() => {
    const years: number[] = [];
    for (let i = 0; i <= (serviceOrder.simulacao?.prazo || 1); i += 1) {
      years.push(new Date().getFullYear() + i);
    }

    return years;
  }, [serviceOrder.simulacao?.prazo]);

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        brush: {
          enabled: false,
          target: undefined,
          autoScaleYaxis: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories,
      },
      colors: serviceOrder.simulacao?.amortizacao?.color
        ? [serviceOrder.simulacao?.amortizacao?.color.replaceAll('##', '#')]
        : ['#000000'],
      tooltip: {
        y: {
          formatter: (value) => formatPrice(value),
        },
      },
    }),
    [categories, serviceOrder.simulacao?.amortizacao?.color]
  );

  const series = useMemo(() => {
    const data: number[] = [];
    if (serviceOrder.simulacao) {
      for (let i = 0; i <= (serviceOrder.simulacao.prazo || 1); i += 1) {
        const firstInstallment = parseFloat(
          String(serviceOrder.simulacao?.vlrParcInic)
        );
        const lastInstallment = parseFloat(
          String(serviceOrder.simulacao?.vlrParcFinal)
        );
        const annualExpense = (firstInstallment - lastInstallment)
          / (serviceOrder.simulacao.prazo || 1);

        const k = i * annualExpense;
        const installmentValue = firstInstallment - k;
        data.push(installmentValue);
      }
    }
    return [
      {
        name: 'Valor',
        data,
      },
    ];
  }, [serviceOrder.simulacao]);

  return (
    <>
      {serviceOrder.simulacao && (
        <Box className="px-4 py-5 p-lg-5 h-100 d-flex flex-column justify-content-between">
          <h3 className="h5">Projeção anual de parcelas</h3>
          <ChartContainer
            color={
              serviceOrder.simulacao.amortizacao?.color
                ? serviceOrder.simulacao.amortizacao?.color
                : '#000'
            }
            className="my-4 w-100"
          >
            <Chart type="area" options={options} series={series} />
          </ChartContainer>
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
            <div className="mb-3 mb-lg-3">
              <p className="mb-3">Tipo de financiamento</p>
              <h4 className="h5 fw-bold text-dark-gray">
                {serviceOrder.simulacao.amortizacao?.amortizacao}
              </h4>
            </div>
            <div>
              <p className="mb-3 text-lg-end">Valor</p>
              <h4 className="h5 fw-bold text-primary text-lg-end">
                {serviceOrder.simulacao?.vlrFinanciado
                  && formatPrice(serviceOrder.simulacao?.vlrFinanciado)}
              </h4>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default CardAnnualProjection;
