import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useHistory } from 'react-router-dom';

import EditTable from '~/assets/icons/edit-table.svg';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';
import { getAccumulatedTotal } from '~/utils/serviceOrderCalculations';
import { Container } from './styles';

interface IParameter {
  margem: number;
}

const BudgetAmount: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();
  const [budget, setBudget] = useState(0);
  const [financedValue, setFinancedValue] = useState(0);

  const landValue = useMemo(
    () => serviceOrder.simulacao?.vlrTerreno || 0,
    [serviceOrder.simulacao?.vlrTerreno]
  );

  useEffect(() => {
    const parameters: IParameter[] = [];

    if (serviceOrder.orc_parametros) {
      Object.values(serviceOrder.orc_parametros).forEach((parameterData) => {
        if (parameterData && typeof parameterData === 'object') {
          parameters.push(parameterData);
        }
      });
    }

    setBudget(getAccumulatedTotal(serviceOrder.orc_parametros, serviceOrder.orc_ambientes));

    if (serviceOrder.simulacao) {
      setFinancedValue(serviceOrder.simulacao.vlrFinanciado || 0);
    }
  }, [serviceOrder]);

  const handleClickEditar = useCallback(() => {
    history.push(
      `${process.env.PUBLIC_URL}/simulador/resultado/${serviceOrder?.id}`
    );
  }, [history, serviceOrder]);

  return (
    <Container className="container col-md-6 col-11 card-dashboard p-4 mb-3">
      <div className="mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do orçamento</h2>
          <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button>
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-1 mt-3">
        <div className="dot dot-1 me-1" />
        <div className="mb-0 sub-text">
          {Math.round((budget / financedValue) * 100).toFixed(0)}% do valor
          financiado
        </div>
        <div className="mb-0 ms-2 numeros" style={{ color: '#707070' }}>
          {formatCurrency(budget)}
        </div>
      </div>
      <hr className="m-4" />
      <div className="mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do terreno</h2>
          <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button>
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-2">
        <div className="dot dot-2 me-1" />
        <div className="mb-0 sub-text">
          {Math.round((landValue / financedValue) * 100).toFixed(0)}% do valor
          financiado
        </div>
        <div className="mb-0 numeros ms-auto" style={{ color: '#707070' }}>
          {formatCurrency(landValue)}
        </div>
      </div>
    </Container>
  );
};

export default BudgetAmount;
