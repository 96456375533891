import React, { createContext, useState, useContext, useCallback } from 'react';

export interface IBuilder {
  user_id: number;
  name: string;
  description: string;
  jobs?: number;
  score?: number;
  construtech?: {
    id: number;
    nome_fantasia: string;
    logo_url?: string;
    description: string;
    jobs: number;
    score: number;
  };
  engenheiro?: {
    id: number;
    nome: string;
    description?: string;
    telefone: string;
    foto_url?: string;
    // municipios: ICity[];
    // photos: IPhoto[];
    // scores: IScore[];
  };
}

interface BuilderContextData {
  builder: IBuilder;
  setBuilder(builder: IBuilder): void;
}

export const BuilderContext = createContext<BuilderContextData>(
  {} as BuilderContextData
);

export const BuilderProvider: React.FC = ({ children }) => {
  const [builder, setBuilder] = useState({} as IBuilder);

  const handleSetBuilder = useCallback((builderData: IBuilder) => {
    setBuilder(builderData);
  }, []);

  return (
    <BuilderContext.Provider value={{ builder, setBuilder: handleSetBuilder }}>
      {children}
    </BuilderContext.Provider>
  );
};

export function useBuilder(): BuilderContextData {
  const context = useContext(BuilderContext);

  if (!context) {
    throw new Error('useBuilder must be used within an BuilderProvider');
  }

  return context;
}
