import { differenceInDays } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import logo from '~/assets/logos/logo.svg';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import { Container } from './styles';

const BarInformation: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [openDays, setOpenDays] = useState(0);
  const [mcfDays, setMcfDays] = useState(0);

  const loadDataInformation = useCallback(() => {
    const dateToday = new Date();
    const dateCreate = new Date(serviceOrder.created_at);
    const dateCompleted = new Date(serviceOrder.last_log?.completed_at);
    const dateCreateMCF = new Date(serviceOrder.last_log?.created_at);

    if (String(dateCompleted).length <= 0) {
      setMcfDays(differenceInDays(dateToday, dateCreateMCF));
    }
    setOpenDays(differenceInDays(dateToday, dateCreate));
  }, [
    serviceOrder.created_at,
    serviceOrder.last_log?.completed_at,
    serviceOrder.last_log?.created_at,
  ]);

  useEffect(() => {
    loadDataInformation();

  }, [serviceOrder]);

  return (
    <Container>
      <div className="column-pending-box">
        <div className="pending-box">
          <div className="circle-box" />
          <b className="mx-2">{serviceOrder.status?.status}</b>
        </div>
      </div>

      <div className="column-mcf-box">
        {mcfDays > 0 ? (
          <div className="mcf-box">
            <div className="mcf-information d-flex align-items-center mx-3">
              <img src={logo} alt="logo" />
              <span className="mt-1">
                Com MCF há:{' '}
                <b>
                  {mcfDays} {mcfDays > 1 ? 'Dias' : 'Dia'}
                </b>
              </span>
            </div>

            <div className="os-information d-flex align-items-center mx-3">
              {Object.keys(serviceOrder).length <= 0 ? (
                <span className="w-full h-full bg-gray-200 animate-pulse" />
              ) : (
                <span className="mt-1">
                  Abertura OS há:{' '}
                  <b>
                    {openDays} {openDays > 1 ? 'Dias' : 'Dia'}
                  </b>
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="mcf-box-open">
            <div className="os-information d-flex align-items-center mx-3">
              {Object.keys(serviceOrder).length <= 0 ? (
                <span className="w-full h-full bg-gray-200 animate-pulse" />
              ) : (
                <span className="mt-1">
                  Abertura OS há:{' '}
                  <b>
                    {openDays} {openDays > 1 ? 'Dias' : 'Dia'}
                  </b>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default BarInformation;
