import React from 'react';

import { Container } from './styles';

interface ISimulation {
  prazo: number;
  taxa: number;
}

interface IParams {
  simulation: ISimulation;
}

const avis: React.FC<IParams> = ({ simulation }) => (
  <Container>
    <div className="border-verde ms-5">
      <div className="m-4 ">
        <div className="d-flex  align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <h3 className="mb-0 font-weight-700 text-black">
              Seu Crédito foi aprovado! 🎉
            </h3>
          </div>
        </div>
        <div className="d-flex  align-items-center mb-4">
          <div className="col-11 d-flex text-dark text-opacity-50 align-items-center">
            <h3 className="mb-0">
              Parabéns, seu crédito foi aprovado, agora você pode prosseguir
              para os próximos passos
            </h3>
          </div>
        </div>
      </div>
      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex text-dark text-opacity-25 align-items-center">
            <h3 className="mb-0">Valor solicitado: </h3>
          </div>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex text-dark align-items-center">
            <h3 className="mb-0 font-weight-700">
              {' '}
              R${simulation.prazo}0000
            </h3>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default avis;
