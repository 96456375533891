import styled from 'styled-components';

interface IButton {
  new?: boolean;
}

interface IContainer {
  opened?: boolean;
}

export const Button = styled.button<IButton>`
  color: #414142;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  position: relative;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f1e8;
    height: 46.29px;
    width: 46.29px;
    border-radius: 50%;
  }

  :before {
    content: '';
    transition-duration: 0.3s;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${(props) => (props.new ? '#c64d4d' : 'transparent')};
    top: 2px;
    right: 3px;
  }
`;

export const Container = styled.div<IContainer>`
  transition-duration: 0.3s;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
  z-index: 2000;

  h4 {
    color: #414142;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
  }

  > button {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: default !important;
  }

  > div {
    transition-duration: 0.3s;
    position: relative;
    right: ${(props) => (props.opened ? 0 : '-1000px')};
    width: 320px;
    height: 100%;
    overflow: auto;

    > button {
      color: #949494;
      font-size: 20px;
    }
  }
  .box-notificattions {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(66vh);
    padding: 2.5%;
    ::-webkit-scrollbar {
      width: 10px;
    }
  }
`;

export const Notification = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 10px;

  .boton-icon-check {
    border-radius: 40px;
    border: none;
    font-size: 50px;
    line-height: 6px;
    color: #ffffff;
    background: #39a887;

    :hover {
      transition: 0.3s;
      background: #ffffff;
      color: #39a887;
    }

    small {
      color: #a8a8a8;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
    }

    div {
      div:last-child {
        width: calc(100% - 50px);
        padding-left: 10px;

        h6 {
          color: #414142;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #b8b9ba;
        }

        a {
          background: #39a887;
          border-radius: 80px;
          border: none;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: #ffffff;
        }
      }
    }

    + div {
      margin-top: 20px;
    }
  }
`;

export const Avatar = styled.div`
  width: 37px;
  height: 37px;
  background: #e8f9f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
