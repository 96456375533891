import React from 'react';
import { addYears, format } from 'date-fns';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';
import { Container, Title } from './styles';

const AmortizationSimulation: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  return (
    <Container className="container py-2 col-md-5 col-12 card-dashboard justify-content-between p-4 mb-3">
      <div>
        <Title
          color={
            serviceOrder?.simulacao?.amortizacao?.color
              ? serviceOrder.simulacao.amortizacao.color
              : '#000000'
          }
        >
          {serviceOrder?.simulacao?.amortizacao?.amortizacao}
        </Title>
        <div className="value-box">
          <p className="valore">Valor total financiado</p>
          <b className="numerico">
            {formatCurrency(serviceOrder.simulacao?.vlrFinanciado || 0)}
          </b>
        </div>
      </div>
      <div className="mx-0 pt-2">
        <div className="d-flex subtitle align-items-center ">
          <div className="col-7 d-flex align-items-center">
            <div className="me-3 dot" />
            <div className="w-75 mt-4">
              <h4 className="mb-0">Primeira Parcela</h4>
              <small className="fechas">
                {format(new Date(), 'dd/MM/yyyy')}
              </small>
            </div>
          </div>
          <p className="mb-0 s ms-auto numeros">
            {formatCurrency(serviceOrder.simulacao?.vlrParcInic || 0)}
          </p>
        </div>
        <hr className="dot-link" />
        <div className="d-flex subtitle align-items-center mb-2">
          <div className="col-7 d-flex align-items-center">
            <div className="me-3 dot" />
            <div className="w-75">
              <h4 className="mb-0 mt-3">Última Parcela</h4>
              <small className="fechas">
                {format(
                  addYears(new Date(), serviceOrder?.simulacao?.prazo || 0),
                  'dd/MM/yyyy'
                )}
              </small>
            </div>
          </div>
          <div className="mb-0 s ms-auto numeros">
            {formatCurrency(serviceOrder.simulacao?.vlrParcFinal || 0)}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AmortizationSimulation;
