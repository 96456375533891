import { format, parseISO } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import {
  PropsWithChildren, useCallback, useEffect, useState
} from 'react';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import { useDatatable } from '~/hooks/Datatable';

// Components

// Hooks

// Services
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';

interface IHistoryPoints {
  id: number;
  product: string;
  data: Date;
  aprovado: boolean;
  value: number;
  point: number;
}

interface IHistoryPointsResponse extends IHistoryPoints {
  index: number;
}

interface IAddHistoryPointsProps extends PropsWithChildren<any> {
  show: boolean;
  onHide: () => void;
}

export default function AddHistoryPoints({
  onHide,
  show,
}: IAddHistoryPointsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [historyPoints, setHistoryPoints] = useState<IHistoryPointsResponse[]>(
    []
  );
  const {
    search, per_page, page, column, order
  } = useDatatable();

  const handlerFetchHistoryPoints = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await api.get<IHistoryPointsResponse[]>(
        'builders/purchased-products',
        {
          params: {
            search,
            per_page,
            page,
            column,
            order,
          },
        }
      );

      if (response.status !== 200) throw new Error('Não foi possivel recuperar o historico de pontuação');

      setIsLoading(false);

      const data = response.data?.map(
        (historyPoint: IHistoryPoints, index) => ({
          ...historyPoint,
          index,
          data: (historyPoint?.data
            ? format(
              parseISO(
                new Date(historyPoint.data).toLocaleDateString('pt-BR')
              ),
              'dd/MM/yy - hh:mm'
            )
            : format(new Date(), 'dd/MM/yy - hh:mm')) as unknown as Date,
        })
      );

      setHistoryPoints(data);
    } catch (error) {
      setIsLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Não foi possivel recuperar o historico',
      });
    }
  }, [search, per_page, page, column, order]);

  useEffect(() => {
    if (show) handlerFetchHistoryPoints();
    return setHistoryPoints([]);
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <Dialog
        visible={show}
        onHide={onHide}
        header="Historico de pontuação"
        className="w-[60%] sm-w-full"
      >
        {historyPoints?.length > 0 && (
          <table
            className="p-datatable-table p-datatable-scrollable-table"
            role="table"
            data-pc-section="table"
          >
            <thead
              className="p-datatable-thead"
              style={{
                backgroundColor: '#f8f9fa',
              }}
            >
              <tr role="row">
                <th
                  className="border-0 cursor-pointer"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div
                    className="p-column-header-content"
                    data-pc-section="headercontent"
                  >
                    <span
                      className="p-column-title"
                      data-pc-section="headertitle"
                    >
                      #
                    </span>
                  </div>
                </th>
                <th
                  className="border-0 cursor-pointer"
                  role="columnheader"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div className="p-column-header-content">
                    <span className="p-column-title">Produto</span>
                  </div>
                </th>
                <th
                  className="border-0 cursor-pointer"
                  role="columnheader"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div className="p-column-header-content">
                    <span className="p-column-title">Data</span>
                  </div>
                </th>
                <th
                  className="border-0 cursor-pointer"
                  role="columnheader"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div className="p-column-header-content">
                    <span className="p-column-title">Aprovado</span>
                  </div>
                </th>
                <th
                  className="border-0 cursor-pointer"
                  role="columnheader"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div className="p-column-header-content">
                    <span className="p-column-title">Valor</span>
                  </div>
                </th>
                <th
                  className="border-0 cursor-pointer"
                  role="columnheader"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: '1rem',
                  }}
                >
                  <div className="p-column-header-content">
                    <span className="p-column-title">Pontos</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="p-datatable-tbody">
              {historyPoints.map((history) => (
                <tr
                  role="row"
                  className="hover:bg-gray-100 cursor-default"
                  key={history.id}
                >
                  <td
                    className="border-0 cursor-pointer"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {history.id}
                  </td>
                  <td
                    className="border-0 cursor-pointer hover:bg-gray-100"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {history.product}
                  </td>
                  <td
                    className="border-0 cursor-pointer hover:bg-gray-100"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {history.data}
                  </td>
                  <td
                    className="border-0 cursor-pointer hover:bg-gray-100"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {history.aprovado ? 'Não' : 'Sim'}
                  </td>
                  <td
                    className="border-0 cursor-pointer hover:bg-gray-100"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {formatCurrency(history.value)}
                  </td>
                  <td
                    className="border-0 cursor-pointer hover:bg-gray-100"
                    role="gridcell"
                    style={{
                      color: 'black',
                      padding: '1rem',
                    }}
                  >
                    {history.point}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {historyPoints?.length <= 0 && <p>Nenhum historico disponível</p>}
      </Dialog>
      <Loading active={isLoading} />
    </>
  );
}
