import { getCookie, setCookie } from '~/utils/cookeis';

/**
 * Clear all local storage with security date
 * @readonly
 */
export function authMiddlewareSignOut(): void {
  try {
    setCookie('@HarveyBuilder:token', '', new Date());

    localStorage.removeItem('@HarveyBuilder:auth');
  } catch (error: any) {
    throw new Error(
      `Something is wrong, do not possible remove the token - ${
        error?.message || 'fatal error'
      }`
    );
  }
}

/**
 * Set to local storage the token of authentication
 * @param token The token of authentication
 */
export function authMiddlewareToken(token: string | null): void {
  try {
    if (!token) throw new Error(`The token of authentication is required`);

    // console.log(`CALLED authMiddlewareToken -> ${token}`)

    setCookie('@HarveyBuilder:token', token);
  } catch (error: any) {
    throw new Error(
      `Something is wrong, do not possible remove the token - ${
        error?.message || 'fatal error'
      }`
    );
  }
}

/**
 * Get the token of authentication from local storage
 * @param inject The 'inject' param will be deprecated
 * @returns string or null
 */
export function authMiddlewareGetToken(inject: boolean = false): string | null {
  try {
    const token = getCookie('@HarveyBuilder:token');

    if (!token) throw new Error('The token is not available');

    return token;
  } catch (error: any) {
    return null;
  }
}
