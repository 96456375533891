import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import api from '~/services/api';

import ModalSendMeasurement from './Components/ModalSendMeasurement';

import SimulationCard from './Components/SimulationCard';

import { CardBoxInformationsSub, Bar, Container } from './styles';

export interface IMeasurementData {
  id: string;
  numero: string;
  arquivo_solicitacao_id: number;
  dt_aprovacao: string;
  dt_solicitacao: string;
  porcentagem_aprovada: string;
  porcentagem_estimada: string;
  resultado_id: string;
  valor_aprovado: string;
}

interface IParams {
  osId: string;
}

const BuildYourHouse: React.FC = () => {
  const params = useParams<IParams>();
  const [showModalSendMeasurement, setShowModalMeasurement] = useState(false);
  const [measurement, setMeasurement] = useState<IMeasurementData[]>([]);
  const [sumPercentage, setSumPercentage] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleCalculatePercentage = useCallback((data: IMeasurementData[]) => {
    const sum = data.reduce(
      (accumulator, value) => accumulator + Number(value.porcentagem_aprovada),
      0
    );
    setSumPercentage(sum);
  }, []);

  const loadMeasurementData = useCallback(() => {
    setLoading(true);
    try {
      api
        .get<IMeasurementData[]>(`/builders/oss/${params.osId}/medicoes`)
        .then(async (datos) => {
          setMeasurement(datos.data);
          handleCalculatePercentage(datos.data);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      Swal.fire('Opss...', 'Ocorreu um erro ao carregar as medições!', 'error');
    }
  }, [handleCalculatePercentage, params.osId, setLoading]);

  useEffect(() => {
    loadMeasurementData();
  }, [loadMeasurementData, setLoading]);

  const handleReload = useCallback(() => {
    loadMeasurementData();
  }, [loadMeasurementData]);

  return (
    <Container>
      <div className="button-box">
        <div className="new-button">
          <button type="button" onClick={() => setShowModalMeasurement(true)}>
            <b>Adicionar nova medição</b>
          </button>
        </div>
      </div>

      <div className="box-bar-progress">
        <b>Progresso aprovado:</b>
        <div className="col-12 mt-5">
          <Bar percent={sumPercentage === 0 ? 0 : sumPercentage}>
            <div>
              <p
                className="mb-0"
                style={{
                  color: `${sumPercentage > 0 ? '#ffffff' : '#000000'}`,
                }}
              >
                {sumPercentage === 0 ? 0 : sumPercentage}%
              </p>
            </div>
          </Bar>
        </div>
      </div>

      <CardBoxInformationsSub className="row mt-5">
        {measurement.map((measurementCard) => (
          <SimulationCard measurement={measurementCard} />
        ))}
      </CardBoxInformationsSub>
      <ModalSendMeasurement
        approvedPercentage={sumPercentage}
        show={showModalSendMeasurement}
        onHide={() => setShowModalMeasurement(false)}
        onReload={handleReload}
      />

      <Loading active={loading} />
    </Container>
  );
};

export default BuildYourHouse;
