
import { Loader } from "@googlemaps/js-api-loader";
import { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

// Utils
import { debounce } from "~/utils/debounce";

// Types
import { IMapsCoordinates, IMapsProps } from "./types";

export function Maps({ searchInput, searchCoordinates, onDoSearch, onDataStream }: IMapsProps) {    
    const [geoCoding, setGeoCoding] = useState<google.maps.Geocoder | null>(null)
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [google, setGoogle] = useState<Loader | null>(null)
    const [coordinates, setCoordinattes] = useState<IMapsCoordinates>({
        lat: -23.5475000,
        lng: -46.6361100
    })

    // AUX Variables
    const MAP_TARGET_REF = useRef<HTMLDivElement>()

    const handlerGetLocations = useCallback(() => {
        try {
            if (!window.navigator) return

            navigator.geolocation.getCurrentPosition(cPositionData => {
                setCoordinattes({
                    lat: cPositionData.coords.latitude,
                    lng: cPositionData.coords.longitude,
                })
            }, (cPositionError) => {
                Swal.fire({
                    icon: 'warning',
                    text: 'Para uma melhor experiência, habilite a sua localização',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Minha Localização',
                    confirmButtonColor: '#39A887',
                    cancelButtonColor: '#e7e7e7',
                    cancelButtonText: 'Cancelar',
                })
                .then((action) => {
                    if (action.isConfirmed) handlerGetLocations()
                })
            }, {
                enableHighAccuracy: true,
                timeout: 50000
            })
        } catch (error) {
            // do anything
        }
    }, [])

    async function onSetMapWithAddress (
        mapCoordinates: IMapsCoordinates | null = null, 
        mp: google.maps.Map | null = null ,
        gc: google.maps.Geocoder | null = null,
        gl: Loader | null = null) {
        try {

            const MAP_STATEMENT = map || mp
            const GEO_CODING_STATEMENT = geoCoding || gc
            const GOOGLE_STATEMENT = google || gl

            if (!MAP_STATEMENT || !GEO_CODING_STATEMENT || !GOOGLE_STATEMENT) throw new Error('The statamet of map is not available')

            let searchParams: any = null

            // SET THE ADDRESS TEXT OR LITERAL COORDINATES
            if (!mapCoordinates) {
                if (searchInput && searchInput.trim() !== '') {
                    searchParams = { address: searchInput }
                } else if (searchCoordinates) {
                    searchParams = { location: { ...searchCoordinates } }
                }
            } else {
                searchParams = { location: { ...mapCoordinates } }
            }

            if (!searchParams) return
            
            // @ts-ignore
            console.table(MAP_STATEMENT?.Fg || {})

            MAP_STATEMENT.addListener('click', (mapsMouseEvent: any) => {
                const mapCoordinatesRaw: IMapsCoordinates = mapsMouseEvent?.latLng?.toJSON() || null

                if (mapCoordinatesRaw) onSetMapWithAddress(mapCoordinatesRaw, MAP_STATEMENT, GEO_CODING_STATEMENT, GOOGLE_STATEMENT)
            })            

            const {addressResult,status} = await new Promise<
                {
                    addressResult: google.maps.GeocoderResult[] | null, 
                    status: google.maps.GeocoderStatus
                }
                >((resolver, reject) => {
                    GEO_CODING_STATEMENT.geocode(
                    searchParams,
                    async (addressResult: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
                        resolver({
                            addressResult,
                            status
                        })                   
                    })                    
                })

            if (status !== "OK") {
                Swal.fire({
                    icon: 'warning',
                    text: 'Nenhum endereço foi encontrado',
                })

                return
            }

            if (!MAP_TARGET_REF.current) return                                                

            if (addressResult && addressResult?.length >= 1) {
                onDataStream!({
                    localization: addressResult[0].formatted_address,
                    latitude: addressResult[0].geometry.location.toJSON().lat || -0,
                    longitude: addressResult[0].geometry.location.toJSON().lng || -0
                }, '2')

                const { Marker } = await GOOGLE_STATEMENT.importLibrary("marker")
                const { InfoWindow } = await GOOGLE_STATEMENT.importLibrary("maps")                

                // console.table(MAP_STATEMENT.getCenter())
                // console.table(MAP_STATEMENT.getClickableIcons())
                // console.table(MAP_STATEMENT.getZoom())

                // MAP_STATEMENT.setZoom(20)

                // const markerStatement = new Marker({
                //     position: addressResult[0].geometry.location.toJSON(),
                //     clickable: true,
                //     map: MAP_STATEMENT
                // })

                // const infoWindow = new InfoWindow()

                // infoWindow.setContent(addressResult[0].formatted_address)
                // infoWindow.open(MAP_STATEMENT, markerStatement)
            }
        } catch (error) {
            console.table(error)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel buscar pelo endereço'
            })
        }
    }

    function handleGetMapID() {
        try {
            const canvas = document.createElement("canvas");

            if (canvas.getContext("webgl2")) {
                canvas.remove()
                return process.env.REACT_APP_GOOGLE_DEVELOPMENT_MAPID_VECTOR_WEBGL2 || ''
            } else {
                canvas.remove()
                return process.env.REACT_APP_GOOGLE_DEVELOPMENT_MAPID_RASTER || ''
            }
        } catch (error) {
            // do anything
        }
    }

    const initMap = useCallback(async ()  => {
        try {
            console.warn('INIT MAP WAS CALLED', new Date().toLocaleString('pt-BR'))
            
            if (!MAP_TARGET_REF.current) throw new Error('The REF to target of render is not valid')

            // Initialize and add the map
            let mapStatement: google.maps.Map

            const gl = new Loader({
                apiKey: process.env.REACT_APP_GOOGLE_DEVELOPMENT_MAPS || '',
                version: "weekly",
            });

            // Request needed libraries.
            const { Map } = await gl.importLibrary("maps")
            const { Geocoder } = await gl.importLibrary("geocoding")
            const { Marker } = await gl.importLibrary("marker")

            mapStatement = new Map(
                MAP_TARGET_REF.current as HTMLDivElement,
                {
                    zoom: 13,
                    heading: 0.2,
                    tilt: 0.3,
                    center: coordinates,
                    mapId: handleGetMapID(),
                    disableDefaultUI: false,
                    clickableIcons: false
                }
            );

            // @ts-ignore
            console.table(mapStatement)

            const GEO_CODING = new Geocoder()        

            if (!map) setMap(mapStatement)
            if (!geoCoding) setGeoCoding(GEO_CODING)
            if (!google) setGoogle(gl)
        
            mapStatement.addListener('click', (mapsMouseEvent: any) => {
                const mapCoordinates = mapsMouseEvent?.latLng?.toJSON() || null

                if (mapCoordinates) onSetMapWithAddress(mapCoordinates, mapStatement, GEO_CODING,gl)
            })
                        
            new Marker({
                map: mapStatement,
                position: coordinates,
                optimized: true,
                title: 'Você está aqui'
            })
        } catch (error) {
            console.table(error)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel iniciar o Mapa interativo'
            })
        }
    }, [MAP_TARGET_REF, map, geoCoding, google])

    // Initialize the render of Map
    useEffect(() => {
        if (document && window && MAP_TARGET_REF.current) {
            initMap()
        }
    }, [MAP_TARGET_REF])

    // Get the actual location of user
    useEffect(() => {
        handlerGetLocations()
    }, [])

    useEffect(() => {
        if (onDoSearch) debounce(100, onSetMapWithAddress)
    }, [onDoSearch])

    useEffect(() => {
        if(!map) return
        onSetMapWithAddress(coordinates)
    }, [coordinates])

    return (
        <div className={`md:w-[23.687rem] md:h-[11.562rem] max-sm:w-full max-sm:h-[4.375rem] rounded-[0.937rem] overflow-hidden bg-gray-100 ${!map && 'animate-pulse'} relative flex flex-col items-center justify-center`}>
            <div 
                id="builder-map" 
                // @ts-ignore
                ref={MAP_TARGET_REF} 
                className="w-full h-full"
            >
            </div>
        </div>
    )
}