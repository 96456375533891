import { formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// Icons
import calendar from '~/assets/icons/calendar.svg';
import checkCircle from '~/assets/icons/check-circle.svg';
import locationPin from '~/assets/icons/location-pin.svg';
import noImage from '~/assets/icons/no-image.svg';

// Interfaces
import { useHistory } from 'react-router-dom';
import { IBoxProps } from './types';

export function Project({
  id, name, localization, updated_at, photo_upload_id
}: IBoxProps): JSX.Element {
  const history = useHistory();

  // AUX Variables
  const PUBLISHED_AT = formatDistanceToNow(updated_at ? new Date(updated_at) : new Date('2023/01/01, 00:00:00'), {
    addSuffix: true,
    locale: ptBR,
  });

  function handlerOnClick() {
    try {
      history.push(`/produtos-imobiliario/projeto/${id}`);
    } catch (error) {
      history.goBack();
    }
  }

  return (
    <>
      <div
        className="max-sm:w-[9.687rem] max-sm:h-[9rem] md:w-[20.687rem] md:h-[20.062rem] max-sm:rounded-[0.937rem] md:rounded-[0.9375rem] flex flex-col items-start justify-start bg-white overflow-hidden shadow-sm hover:shadow-gray-700 transition-all duration-[0.3s] cursor-pointer"
        onClick={handlerOnClick}
        onKeyUp={handlerOnClick}
        role="button"
        tabIndex={-1}
      >
        <div className="overflow-hidden bg-gray-100 w-full flex-1 max-h-[11.25rem] min-h-[11.25rem] max-sm:max-h-[5rem]  max-sm:min-h-[5rem] object-fill flex flex-col items-center justify-center">
          {
            !photo_upload_id && (
              <img
                src={noImage}
                alt="Imagem do projeto"
                className="md:max-w-[5.187rem] max-sm:max-w-[3.187rem] h-auto"
                loading='lazy'
              />
            )
          }
          {
            photo_upload_id && (
              <img
                src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/${id}/photo`}
                alt="Imagem do projeto"
                className="max-w-full h-auto object-cover"
                loading='lazy'
              />
            )
          }
        </div>
        <div className="flex-1 w-full flex flex-col items-start justify-start gap-[0.875rem] pt-[0.875rem] pl-[1rem] pr-[1rem] pb-[0.875rem] box-border bg-white">
          <span className="font-semibold text-[1.125rem] text-[#707070] max-sm:text-[0.625rem]">
            {name}
          </span>

          <ul className="flex flex-col items-start w-full flex-1 gap-[0.1rem] max-sm:hidden">
            <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
              <img src={locationPin} alt="Icone generico" />
              <span className="overflow-hidden text-ellipsis">{localization}</span>
            </span>
            <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
              <img src={calendar} alt="Icone generico" />
              <span>Estudo concluído</span>
            </span>
            <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
              <img src={checkCircle} alt="Icone generico" />
              <span>Última modificação: {PUBLISHED_AT}</span>
            </span>
          </ul>
        </div>
      </div>
    </>
  );
}
