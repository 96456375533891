import styled from 'styled-components';

interface IProps {
  animation: string;
}

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  margin: 1.5% 0.5%;
  @media (max-width: 859px) {
    margin: 2%;
  }
`;

export const Container = styled.div`
  height: 300px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f2f2f2;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
  border-radius: 21px;
  padding: 5%;

  animation: ${(props: IProps) => {
    if (props.animation === 'direita') {
      return 'direita 0.5s  0s';
    }
    if (props.animation === 'esquerda') {
      return 'esquerda 0.5s 0s';
    }
    return '';
  }};

  @keyframes direita {
    0% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
    30% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      opacity: 0;
    }
  }

  @keyframes esquerda {
    0% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }

    30% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      opacity: 1;
    }

    100% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      opacity: 0;
    }
  }

  .h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Title */

    color: #414142;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  .aproved-box {
    color: #51dcbc;
  }

  .await-box {
    margin-left: 25%;
    color: black;
  }

  .no-file-box {
    margin-left: 50%;
  }

  .hr-box {
    display: flex;
    justify-content: center;
    hr {
      width: 90%;
    }
  }

  .box-button-visualize {
    padding: 1%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    background-color: #fcfcfc;
    height: 50px;
  }

  @media (max-width: 988px) {
    display: unset;
    height: 17%;
  }

  @media (max-width: 767px) {
    width: auto;
    height: auto;
  }
`;
