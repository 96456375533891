import React from 'react';

import logo from '~/assets/logos/logo.svg';

import { Container } from './styles';

interface IBarInformation {
  mcfDays: number;
  osDays: number;
}

const BarInformation: React.FC<IBarInformation> = ({ mcfDays, osDays }) => (
  <Container>
    <div className="column-mcf-box">
      {mcfDays > 0 ? (
        <div className="mcf-box">
          <div className="mcf-information d-flex align-items-center mx-3">
            <img src={logo} alt="logo" />
            <span className="mt-1">
              Com MCF há:{' '}
              <b>
                {mcfDays} {mcfDays > 1 ? 'Dias' : 'Dia'}
              </b>
            </span>
          </div>

          <div className="os-information d-flex align-items-center mx-3">
            <span className="mt-1">
              Abertura OS há:{' '}
              <b>
                {osDays} {osDays > 1 ? 'Dias' : 'Dia'}
              </b>
            </span>
          </div>
        </div>
      ) : (
        <div className="mcf-box-open">
          <div className="os-information d-flex align-items-center mx-3">
            <span className="mt-1">
              Abertura OS há:{' '}
              <b>
                {osDays} {osDays > 1 ? 'Dias' : 'Dia'}
              </b>
            </span>
          </div>
        </div>
      )}
    </div>
  </Container>
);

export default BarInformation;
