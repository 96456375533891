import React from 'react';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { Container } from './styles';

interface IStepMenuDataOs {
  contentData: string;
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const StepMenuDataOs: React.FC<IStepMenuDataOs> = ({
  contentData,
  setContentData,
}) => {
  const { serviceOrder } = useServiceOrder();

  return (
    <Container className="row my-3">
      <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
        <button
          type="button"
          className={`link-text w-100 py-2 btn ${
            contentData === 'ProponentDataOs' && 'active'
          }`}
          onClick={() => setContentData('ProponentDataOs')}
        >
          Dados da OS
        </button>
      </div>

      <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
        <button
          type="button"
          className={`link-text w-100 btn py-2 ${
            contentData === 'DataProponent' && 'active'
          }`}
          onClick={() => setContentData('DataProponent')}
        >
          Dados do Proponente
        </button>
      </div>

      <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
        <button
          type="button"
          className={`link-text btn w-100 py-2 ${
            contentData === 'SimulationBank' && 'active'
          }`}
          onClick={() => setContentData('SimulationBank')}
        >
          Banco (Simulação)
        </button>
      </div>

      {serviceOrder.finalidade_id !== 3 &&
        serviceOrder.finalidade_id !== 4 &&
        serviceOrder.finalidade_id !== 6 &&
        serviceOrder.finalidade_id !== 7 && (
          <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
            <button
              type="button"
              className={`link-text btn w-100 py-2 ${
                contentData === 'BuildYourHouse' && 'active'
              }`}
              onClick={() => setContentData('BuildYourHouse')}
            >
              Monte sua casa
            </button>
          </div>
        )}

      {(!serviceOrder?.cliente?.casado || serviceOrder?.conjuge?.email) && (
        <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
          <button
            type="button"
            className={`link-text btn w-100 py-2 ${
              contentData === 'WorkDocuments' && 'active'
            }`}
            onClick={() => setContentData('WorkDocuments')}
          >
            Documentos da Obra
          </button>
        </div>
      )}

      {serviceOrder?.status?.id >= 23 && (
        <div className="col-lg-2 col-6 d-flex justify-content-center align-items-center">
          <button
            type="button"
            className={`link-text btn w-100 py-2 ${
              contentData === 'Measurements' && 'active'
            }`}
            onClick={() => setContentData('Measurements')}
          >
            Medições
          </button>
        </div>
      )}
    </Container>
  );
};

export default StepMenuDataOs;
