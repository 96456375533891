// Icons
import { CgSpinner } from "react-icons/cg";
import { RiArrowLeftSLine } from "react-icons/ri";

// Types
import { useMemo } from "react";
import { TStepTargets } from "../../types";
import { IFooterProps } from "./types";

export function Footer({stepSelected, actualStepTypeIndex, 
        step_type, steps_mounted_base,
        onActualStepType, onFinished, 
        statuses, steps_mounted}: IFooterProps) {

    // AUX Variable
    const STEP_HAS_SELECTIONS = useMemo(() => (() => {

        let TOTAL_SELECTED = 0;
        const OPTION_PER_QUANTITY = Object.keys(stepSelected?.optionsPerQuantity || {})
        const OPTIONS_PER_QUANTITY_LENGTH = OPTION_PER_QUANTITY.length - 1 
        
        OPTION_PER_QUANTITY.forEach(d => {
            if(stepSelected?.optionsPerQuantity?.[d]?.options.some(d => d.selected)) {
                TOTAL_SELECTED += 1;
            }
        })        

        switch(step_type) {
            case 'quarto':
                if(!OPTION_PER_QUANTITY) return false;

                return TOTAL_SELECTED === OPTION_PER_QUANTITY.length;
            case 'banheiro':
                if(!OPTION_PER_QUANTITY) return false;

                return TOTAL_SELECTED === OPTION_PER_QUANTITY.length;
            // case 'closet':
            //     if(!OPTION_PER_QUANTITY) return false;

            //     return TOTAL_SELECTED === OPTION_PER_QUANTITY.length;
            case 'cozinha':             
                return stepSelected?.options.some(d => d.selected)
        
            case 'sala':
                return stepSelected?.options.some(d => d.selected)
        
            default:
                return true
        }
    }), [step_type, stepSelected])()

    return (
         <div
            data-selected={stepSelected && true}
            className='w-full 
                h-14 
                mt-12 
                md-[1728px]:mt-5
                hidden
                data-[selected=true]:flex 
                flex-row 
                flex-nowrap 
                items-center 
                justify-end gap-4
            '
        >
            <button
                type="button"
                title='Voltar'
                // DO A CAST TO ORIGINAL POSITION
                data-shouldshowme={(actualStepTypeIndex - 1) >=  1}
                disabled={statuses.onSubmiting}
                className='
                    hidden
                    data-[shouldshowme=true]:flex 
                    w-[62px] 
                    h-[54px] 
                    rounded-[20px] 
                    bg-[#39A887]
                    hover:bg-[#339578]
                    active:bg-[#339578]
                    transition-all
                    duration-[0.15s]
                    flex-row 
                    items-center 
                    justify-center
                    disabled:pointer-events-none
                    disabled:opacity-80
                '
                onClick={() => onActualStepType(step_type)}
            >
                <RiArrowLeftSLine size={24} color="#fff" />
            </button>

            <button
                type="button"
                title='Próximo'
                data-shouldshowme={actualStepTypeIndex >=  steps_mounted}
                disabled={statuses.onSubmiting || STEP_HAS_SELECTIONS === false}
                className='
                    flex 
                    data-[shouldshowme=true]:hidden
                    w-full
                    md:w-[331px]
                    h-[54px]
                    rounded-[20px] 
                    bg-[#39A887] 
                    hover:bg-[#339578]
                    active:bg-[#339578]
                    transition-all
                    duration-[0.15s]
                    flex-row 
                    items-center 
                    justify-center
                    text-white
                    font-bold
                    
                    disabled:opacity-80
                    disabled:cursor-not-allowed
                '
                onClick={() => onActualStepType(steps_mounted_base?.[actualStepTypeIndex].type as TStepTargets)}
            >
                Próximo
            </button>

            <button
                type="button"
                title='Finalizar'
                data-shouldshowme={actualStepTypeIndex >=  steps_mounted}
                disabled={statuses.onSubmiting || STEP_HAS_SELECTIONS === false}
                className='
                    hidden
                    data-[shouldshowme=true]:flex
                    w-full
                    md:w-[331px]
                    h-[54px]
                    rounded-[20px] 
                    bg-[#39A887]
                    hover:bg-[#339578]
                    active:bg-[#339578]
                    transition-all
                    duration-[0.15s]
                    flex-row 
                    items-center 
                    justify-center
                    text-white
                    font-bold
                    
                    disabled:opacity-80
                    disabled:cursor-not-allowed
                '
                onClick={onFinished}
            >
                <span 
                    data-onsubmit={statuses.onSubmiting}
                    className="hidden data-[onsubmit=false]:flex"
                >
                    Finalizar
                </span>
                <span 
                    data-onsubmit={statuses.onSubmiting}
                    className="hidden data-[onsubmit=true]:flex"
                >
                    <CgSpinner className="text-white animate-spin"/>
                </span>
            </button>
      </div>
    )
}