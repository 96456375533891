import styled from 'styled-components';

export const Container = styled.section`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: flex-end;
  font-feature-settings: 'zero' on;
  color: #707070;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #414142;
  }

  .subtitle {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }

  label {
    width: 300px;
  }
  .calculate {
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;

    ::placeholder {
      color: #adadad;
    }
  }
  .sub-bar {
    width: 100%;
  }
`;
