import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

// Icons
import emptyDashboardIcon from "~/assets/icons/empty_dashbord.svg";

// Components
import UserDefault from '~/components/UserDefault';

// Interfaces
import { useEffect, useState } from 'react';
import { IMRealStateProduct } from '~/models/RealEstateProduct';

// Types
import { IPropsProjects } from './types';

// Services
import api from '~/services/api';

// Partials
import { Box } from './Partials/Box';
import { Home } from './Partials/Home';

export function Projects({ children }: IPropsProjects): JSX.Element {
  const [realEstateProducs, setRealEstateProducts] = useState<IMRealStateProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleToPage() {
    try {
      history.push('/produtos-imobiliario/novo-projeto');
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel iniciar o processo de criação de projeto',
        icon: 'warning',
      });
    }
  }

  // RECOVERY ALL REPS (REAL ESTATE PRODUCT's)
  useEffect(() => {
    setLoading(true)
    api.get<IMRealStateProduct[]>('builders/real-estate-products').then((resolver) => {
      if (resolver.status !== 200) return;

      setRealEstateProducts(resolver.data);
      setLoading(false)
    })
      .catch((rejects) => {
        setLoading(false)
      });
  }, []);

  return (
    <div className="flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t h-screen">
      <UserDefault home />
      <div className="container mx-auto flex flex-col items-start justify-start gap-rep60">

        <span className="text-[1rem] font-[400] leading-normal text-[#707070] select-none cursor-default">
          Adicione propriedades para calcular a matitude do condomínio, receber <br />
          recomendações regionais e comparar propriedades à venda.
        </span>
        {
          realEstateProducs.length > 0 && (
            <Home data={realEstateProducs} />
          )
        }

        {
          !loading && realEstateProducs.length <= 0 && (
            <div className="w-full h-screen flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center md:mt-[-30%]">
                <span>
                  <img src={emptyDashboardIcon} alt="" />
                </span>
                <span className="text-[1rem] font-[400] text-[#B8B9BA] leading-normal">
                  Ao fazer um estudo de mercado, você verá isso aqui
                </span>

                <button type="button"
                  onClick={handleToPage}
                  title="Criar novo projeto"
                  className="w-[20.687rem] h-[3.5rem] mt-[2.5rem] rounded-[0.937rem] bg-[#39A887]
                    hover:bg-[#44c49d] duration-[0.3s] transition-colors
                   text-white  text-[1rem] font-bold flex flex-row items-center justify-center">
                  Novo estudo
                </button>
              </div>
            </div>
          )
        }

        {
          loading && (
            <div className="flex flex-row flex-wrap overflow-y-auto w-full h-auto max-sm:gap-x-[2.5rem] md:gap-x-[6.5rem] max-2xl:gap-x-[2.5rem] gap-y-[2.5rem] pb-[6.5rem]">
              <Box.Mock />
              <Box.Mock />
              <Box.Mock />
            </div>
          )
        }
      </div>
    </div>
  );
}
