import React from 'react';

import { BsDownload } from 'react-icons/bs';
import { useProject } from '~/pages/Projects/Hooks/useProject';
import { Container } from './styles';

interface Props {
  index: number;
  show: number[];
}

const Details: React.FC<Props> = ({ show, index }) => {
  const { projects } = useProject();

  return (
    <Container>
      {show.includes(index) && (
        <>
          <div className="row ps-3 detailed_description">
            <div className="col-auto">
              {projects[index].detailed_description}
            </div>
          </div>
          <div className="py-2" />
          <div className="col-auto ps-3">
            {projects[index].file_url && (
              <a
                href={projects[index].file_url}
                target="_blank"
                type="button"
                rel="noreferrer"
                className="button-proposal"
              >
                <BsDownload size={20} className="me-2 mb-1" />
                <b>Baixar Modelo de projeto</b>
              </a>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default Details;
