import { jsPDF } from "jspdf";
import { useCallback, useEffect, useMemo, useRef } from "react";
// @ts-ignore
// import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";

// Types
import { IPrintableProps } from "./types";

// Styles
import "./styles.css";

// Partials
import { IoPrint } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuthContext } from "~/hooks/contexts/Auth";
import { useServiceOrder } from "~/hooks/ServiceOrder";
import { IMServiceOrder } from "~/models/ServiceOrder";
import api from "~/services/api";
import { Amounts } from "./Partials/Amounts";
import { Data } from "./Partials/Data";
import { Environments } from "./Partials/Environments";
import { Home } from "./Partials/Home";
import { RealEstateDevelopments } from "./Partials/Red";
import { Summary } from "./Partials/Summary";

export function Printable(_props: IPrintableProps) {
    const {onRecovery} = useAuthContext()
    const {serviceOrder, setServiceOrder} = useServiceOrder()
    const params = useParams<{osId: string}>()

    // AUX Variable
    const PDF_STATEMENT = useMemo(() => new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
        compress: true        
    }),[])
    const MAIN_CONTAINER_TO_PRINT = useRef<HTMLDivElement>()

    const handlerToPDF =  useCallback(() => {
        try {
            const ELEMENT_MAIN = document.querySelector('#container-printable') as HTMLElement
            const BUTTON_ACT_PRINT = document.querySelector('#button-action-print') as HTMLButtonElement
            PDF_STATEMENT.html(document.querySelector('#container-printable') as HTMLElement, {
                x: 0,
                y: 0,
                autoPaging: true,               
            })
            // PDF_STATEMENT.save('minha_proposta.pdf')

            BUTTON_ACT_PRINT.setAttribute('hidden', 'true')
            html2canvas(ELEMENT_MAIN, {
                allowTaint: true,
                useCORS: true,
                logging: true,
                scale: 2
            }).then(canvas => {
                const CANVAS_URL_DATA = canvas.toDataURL("image/png",1);
                const CANVAS_LINK_TO_DOWNLOAD = document.createElement('a');
            
                CANVAS_LINK_TO_DOWNLOAD.setAttribute('download','minha_proposta.png');
                
                CANVAS_LINK_TO_DOWNLOAD.setAttribute('href', CANVAS_URL_DATA);

                CANVAS_LINK_TO_DOWNLOAD.click();

                CANVAS_LINK_TO_DOWNLOAD.remove();

                BUTTON_ACT_PRINT.removeAttribute('hidden')
                // canvas.toBlob((blobs) => {
                // }, "application/pdf",1);

                // PDF_STATEMENT.html(canvas, {
                //     autoPaging: false,
                //     image: {
                //         quality: 1,
                //         type: 'png'
                //     }
                // })
                
                // const CANVAS_LINK_TO_DOWNLOAD = document.createElement('a');
                
                // CANVAS_LINK_TO_DOWNLOAD.setAttribute('download','minha_proposta.png');
                // CANVAS_LINK_TO_DOWNLOAD.setAttribute('href', CANVAS_URL_DATA);

                // CANVAS_LINK_TO_DOWNLOAD.click();

                // CANVAS_LINK_TO_DOWNLOAD.remove();
                
                // PDF_STATEMENT.save('minha_proposta.pdf')
            })

            // html2pdf().set({
            //     margin: 0,
            //     image: { type: 'jpeg', quality: 0.98 },
            //     jsPDF: {
            //         orientation: 'p',
            //         unit: 'pt',
            //         format: 'a4',
            //         putOnlyUsedFonts: true,
            //         floatPrecision: 16 // or "smart", default is 16
            //     }                
            // }).from(ELEMENT_MAIN).toPdf().save('teste')

        } catch (error) {
            
        }
    },[PDF_STATEMENT])


    useEffect(() => {
        console.log(params, 'PRINT PARAMS')

        if(serviceOrder?.id && serviceOrder.id > 0 || !params?.osId) return;

        api.get<IMServiceOrder>(`${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${params.osId}`)
        .then(resolve => {
            setServiceOrder(resolve.data);
        })
    }, [])

    const handleDownloadProposal = useCallback(async () => {
        try {
            Swal.fire({
                timer: 2500,
                text: 'Sua proposta está sendo gerada...',
                icon: 'warning'
            })
            
            const response = await api.get(
                `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${serviceOrder?.id}/proposal/screenshot`,
                {
                    responseType: 'blob',
                }
            );

            const urlData = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlData;
            link.download = 'Proposal.pdf';
            
            link.click();
            
            setTimeout(() => {
                window.URL.revokeObjectURL(urlData);
                link.remove();
            }, 100);

        } catch (error) {        
            Swal.fire(
                'Opss...',
                'Ocorreu um erro, arquivo não encontrado.',
                'error'
            );
        }
    }, [serviceOrder?.id]);

    return <>
        {
            !serviceOrder?.id && !onRecovery &&  (
                <div className="
                        fixed 
                        left-[50%] md:right-[50%] 
                        top-[50%]
                        -translate-x-1/2
                        -translate-y-1/2
                        max-sm:w-11/12 
                        p-8 
                        w-1/2 h-[300px] 
                        z-[999] text-zinc-900
                        bg-zinc-100
                        shadow-xl
                        rounded-xl
                        border-1
                        border-zinc-400
                        flex flex-col items-center justify-between
                    "
                >
                    <h1 className="font-semibold max-sm:text-md max-sm:text-center text-xl">Aguardando OS para impressão e visualização</h1>
                    <h3 className="max-sm:text-center ">Caso não apareça, volte para a dashboard e selecione uma ordem de serviço</h3>
                    <Link
                        to="../../"
                        className="bg-[#20b174] 
                            rounded-xl 
                            flex flex-row 
                            items-center justify-center text-zinc-50 
                            font-semibold
                            max-sm:w-full
                            md:w-1/5
                            px-4
                            py-2
                        "
                    >
                        Voltar
                    </Link>
                </div>
            )
        }
      
        {
            serviceOrder?.id &&  (                
                <div id="container-printable" className="relative" ref={MAIN_CONTAINER_TO_PRINT as any}>            
                    <Home />
                    <Summary />
                    <Amounts />
                    <Environments />
                    <RealEstateDevelopments />
                    <Data />
                    <button
                        id="button-action-print"
                        onClick={() => handleDownloadProposal()}
                        title="Imprir documento"
                        className="w-12 h-12 
                            fixed right-24 bottom-24
                            rounded-full
                            bg-[#20b174]
                            flex items-center justify-center flex-row
                            transition-all duration-[0.37s]
                            shadow-md
                            scale-100
                            hover:scale-110                    
                            active:bg-[#1e8359]
                            print:hidden
                        "
                    >
                        <IoPrint color="#DCDCDC" size={24}/>
                    </button>
                </div>
            )
        }        
    </>
}