interface IEnvironment {
  vlrFinal?: number;
}

interface IParameter {
  margem: number;
}

interface IBudgetCalculation {
  environments: IEnvironment[];
  parameters: IParameter[];
}

const budgetCalculation = ({
  environments,
  parameters,
}: IBudgetCalculation): number => {
  
  // console.log(environments)
  // console.log(JSON.stringify(environments))
  // console.log(environments.length)
  console.log(`THE budgetCalculation WAS CALLED`)
  const ENVIRONMENTS_BY_ITEM_AMOUNT: any[] = []
  const PARAMETERS_BY_AMOUNT: any[] = []
  const totalEnvironmentValue = environments.reduce(
    (previousValue, currentValue) => {

      ENVIRONMENTS_BY_ITEM_AMOUNT.push({
        currentValue: currentValue.vlrFinal,
        previousValue
      })

      return previousValue + (currentValue.vlrFinal ?? 0);
    },
    0
  );
  
  const totalParameter = parameters.reduce((previousValue, currentValue) => {

    PARAMETERS_BY_AMOUNT.push({
        totalEnvironmentValue,  
        currentValue: currentValue.margem,
        previousValue
      })

    // TODO - APPLY A DOUBLE MARGEM ON AMOUNT OF PRICE FROM ENVIRONMENTS, THAT SHOULD BE REVIEWED
    return (previousValue + (totalEnvironmentValue * currentValue.margem)) / 100;
  }, 0);
  
  
  const total = totalEnvironmentValue + totalParameter;

  // console.log('AFTER PROCESSED: ',environments)
  // console.log('AFTER PROCESSED: ',JSON.stringify(environments))
  // console.log('AFTER PROCESSED: ',environments.length)
  
  console.table({
    // environments,
    // environmentsSTRING: JSON.stringify(environments),
    // parameters: JSON.stringify(parameters),
    ENVIRONMENTS_BY_ITEM_AMOUNT,
    ENVIRONMENTS_BY_ITEM_AMOUNT_RAW: JSON.stringify(ENVIRONMENTS_BY_ITEM_AMOUNT),
    PARAMETERS_BY_AMOUNT,
    PARAMETERS_BY_AMOUNT_RAW: JSON.stringify(PARAMETERS_BY_AMOUNT),
    totalParameter,
    totalEnvironmentValue,
    total,
  })

  return total;
};

export default budgetCalculation;
