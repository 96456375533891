import styled from 'styled-components';

export const Container = styled.div`
  background: #ffeded;
  /* alert - red */

  border: 1px solid #ff1a50;
  border-radius: 15px;

  .icon-information {
    display: flex;
    align-items: center;
    padding: 0% 3%;
  }

  .text-box {
    margin: 1% 0%;
  }

  .box-fill {
    display: flex;
    justify-content: space-between;
  }

  .box-button-fill {
    display: flex;
    justify-content: center;
    @media (max-width: 1199px) {
      justify-content: flex-start;
      margin-left: -1%;
    }
    @media (max-width: 408px) {
      margin-left: -10%;
    }
    @media (max-width: 322px) {
      margin-left: -15%;
    }
  }

  .button-fill {
    border: 0px;
    margin-right: 3%;
    color: #ffffff;
    background: #ff1a50;
    border-radius: 26px;
  }
`;
