import '@animxyz/core';
import * as Sentry from '@sentry/react';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter } from 'react-router-dom';

import Routers from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// import Routes from '~/routes';
import './index.css';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';
import { Persistent } from './pages/_Layouts/Persistent';

// AUX Variables
const SENTRY_REPORT: Sentry.ReportDialogOptions = {
  title: 'Estamos tendo problemas internos',
  subtitle: 'O suporte já foi notificado',
  subtitle2: 'Se você quiser nos ajudar, diga oque aconteceu abaixo',
  labelSubmit: 'Enviar',
  lang: 'pt-BR',  
  
};

function FallbackComponent() {
  return (
    <div className="w-50 h-50 p-4 rounded-xl bg-slate-100 border-1 border-gray-300 border-[0.1rem] flex flex-col gap-6 items-center justify-center m-auto absolute top-[50%] bottom-[50%] left-[25%] right-[25%] z-50">
      <hgroup>
        <h1 className="font-bold">Infelizmente ocorreu um erro inesperado!</h1>
        <h2 className="font-light text-center">
          Nosso suporte já foi avisado!
        </h2>
      </hgroup>

      <div className="flex flex-row items-center justify-between gap-4">
        <button
          onClick={() => window.location.reload()}
          type="button"
          title="Recarregar pagina"
          className="w-auto h-6 p-4 bg-emerald-500 font-semibold text-slate-100 rounded-xl flex flex-row items-center justify-center"
        >
          Tentar novamente
        </button>
        <button
          onClick={() =>
            Sentry.showReportDialog({
              lang: 'pt-BR',
              title: 'Nos ajude a resolver o problema',
              subtitle:
                'Conte mais sobre o erro, qual pagina ocorreu, entre outros...',
            })
          }
          type="button"
          title="Clique aqui para enviar o que aconteceu"
          className="w-auto h-6 p-4 hover:bg-gray-200 font-light text-slate-950 rounded-xl flex flex-row items-center justify-center transition-all"
        >
          Fazer feedback
        </button>
      </div>
    </div>
  );
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['*'],
      traceXHR: true,
      startTransactionOnPageLoad: true,
    }),
    new Sentry.Replay({
      networkCaptureBodies: true,
      networkDetailAllowUrls: [/^https:\/\/api\.mcf\.house/],
      networkRequestHeaders: ['Authorization', 'authorization'],
      networkResponseHeaders: ['body'],
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: true,
  environment: 'prod',
  enableTracing: true,
  beforeSendTransaction(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        ...SENTRY_REPORT,
      });
    }

    return event;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb;
  },
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        ...SENTRY_REPORT,
      });
    }

    return event;
  },
  
  debug: false,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.9,
  replaysOnErrorSampleRate: 0.9,
});

export function App () {  

  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <AppProvider>
          <Sentry.ErrorBoundary
            fallback={FallbackComponent}
            showDialog
            dialogOptions={SENTRY_REPORT}
            beforeCapture={({ setTag, getLastBreadcrumb },error) => {
              setTag('REACT@COMPONENT', 1);
              console.log('ErrorBoundary@beforeCapture', getLastBreadcrumb());              
            }}
          >
            { /* @ts-ignore */ }
            <Persistent>
              <Routers />
            </Persistent>
          </Sentry.ErrorBoundary>
        </AppProvider>
        <GlobalStyles />
      </BrowserRouter>
    </HttpsRedirect>
  )
}

export default Sentry.withProfiler(App, {
  includeUpdates: false,
});
