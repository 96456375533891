import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 20px;
  padding: 2% 3%;
  background-color: #f9f9f9;

  .title-owner-element {
    margin-bottom: 3%;
    font-weight: 600;
    font-size: 18px;
  }

  .cellphone-input {
    background-color: #f9f9f9;
  }

  .error {
    margin-top: 1.5%;
    font-size: 14px;
  }
`;
