import styled from 'styled-components';

export const Container = styled.div`
  .profile-icon {
    background-color: #03c2ff;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    color: #ffffff;
    font-size: 14px;
  }
  .profile-icon2 {
    background-color: #03c2ff;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    color: #000000;
    font-size: 14px;
  }
  .col-lg-12 {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
`;
