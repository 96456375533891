import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  .link-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    width: 87px;
    font-style: normal;
    height: 17px;
    line-height: 17px;
    border-radius: 5px;
    :hover {
      height: 37px;
      background-color: #f1f1f1;
      transition: 0.3s;
    }
  }
  .link-text.active {
    color: #fff;
    width: 135px;
    height: 37px;
    padding: 10px, 24px, 10px, 24px;
    background-color: #39a887;
    border-bottom: 1px solid #ffffff;
  }
`;
