import React, { useCallback, useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import Swal from 'sweetalert2';
import randomColor from '~/utils/colorGenerator';

// import { useAuthContext } from "~/hooks/contexts/Auth";

import { IBuilder, useBuilder } from '~/hooks/Builder';
import { useClient } from '~/hooks/Client';
import api from '~/services/api';

import Loading from '~/components/Loading';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMParticipant } from '~/models/Participant';
import { formatToFloat } from '~/utils/format';
import Avatars from './Avatars';
import ModalForm, { IDataForm } from './ModalForm';
import { Container } from './styles';

interface IUserData {
  osId: number;
}

const UserData: React.FC<IUserData> = ({ osId }) => {
  const [, setColor] = useState<string>();
  const [showForm, setShowForm] = useState(0);
  const { client, setClient } = useClient();
  const { setBuilder } = useBuilder();
  const { serviceOrder, setServiceOrder } = useServiceOrder();

  const [dataForm, setDataForm] = useState<IDataForm>({} as IDataForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const colorGenerator = randomColor();
    setColor(colorGenerator);
  }, []);

  const handleBuilder = useCallback(async () => {
    try {
      api
        .get(`${process.env.REACT_APP_PREFIX_ROUTE}/engineers`)
        .then((response) => {
          const data: IBuilder = {
            user_id: response.data.user.id,
            name: response.data.user.name,
            description: response.data.description,
            engenheiro: {
              id: response.data.id,
              nome: response.data.nome,
              description: response.data.description,
              telefone: response.data.telefone,
            },
          };
          setBuilder(data);
        });
      // setConstrutor({
      //   id: engineerResponse.data.engenheiro.id,
      //   name: engineerResponse.data.engenheiro.nome,
      // });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro, tente novamente',
      });
    }
  }, [setBuilder]);

  useEffect(() => {
    // handleBuilder();
  }, [handleBuilder]);
  const handleAvatarSelected = useCallback(
    (formNumber: number, data: IDataForm) => {
      setShowForm(formNumber);
      setDataForm(data);
    },
    [setShowForm, setDataForm]
  );

  const handleSubmitClient = useCallback(
    async (data: IDataForm) => {
      try {
        const formData = {
          nome: data.name,
          documento: data.document,
          profissao: data.profession,
          numPis: data.pis_number,
          nvEscolar_id: data.school_level_id,
          fgts: data.fgts,
          vlrFgts: data.fgts_amount ? formatToFloat(data.fgts_amount) : 0,
          vlrRenda: data.salary ? formatToFloat(data.salary) : 0,
        };

        if (client) {
          const response = await api.put(
            `/builders/customers/${client.id}`,
            formData
          );
          setClient({
            ...response.data,
            profession: response.data.profissao,
            pis_number: response.data.numPis,
            school_level_id: response.data.schooling_level_id,
          });
        } else {
          const response = await api.post('/builders/customers', formData);
          setClient({
            ...response.data,
            profession: response.data.profissao,
            pis_number: response.data.numPis,
            school_level_id: response.data.schooling_level_id,
          });
        }

        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [client, setClient]
  );

  const handleSubmitSpouse = useCallback(
    async (data: IDataForm) => {
      try {
        const formData = {
          os_id: osId,
          nome: data.name,
          cpf: data.document,
          profissao: data.profession,
          fgts: data.fgts,
          vlrFgts: data.fgts_amount ? formatToFloat(data.fgts_amount) : 0,
          vlrRenda: data.salary ? formatToFloat(data.salary) : 0,
          numPis: data.pis_number,
          nvEscolar_id: data.school_level_id,
        };

        if (serviceOrder.conjuge) {
          const response = await api.put(
            `/builders/service-orders/spouses/${serviceOrder.conjuge.id}`,
            formData
          );
          setServiceOrder({ ...serviceOrder, conjuge: response.data });
        } else {
          const response = await api.post(
            '/builders/service-orders/spouses/',
            formData
          );
          setServiceOrder({ ...serviceOrder, conjuge: response.data });
        }

        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [osId, serviceOrder, setServiceOrder]
  );

  const handleSubmitParticipant = useCallback(
    async (data: IDataForm) => {
      // api.get<IParticipantes>(`builders/participants/oss/$osId`);

      try {
        const participantes = await api.get<IMParticipant[]>(
          `builders/participants/oss/${osId}`
        );
        let esteParticipante: IMParticipant = {} as IMParticipant;
        participantes.data.forEach((part) => {
          if (part.cpf === data.document) {
            esteParticipante = part;
          }
        });

        const formData = {
          os_id: osId,
          nome: data.name,
          // documento: data.document,
          profissao: data.profession,
          numPis: data.pis_number,
          nvEscolar_id: data.school_level_id,
          cpf: data.document,
          fgts: data.fgts,
          vlrFgts: data.fgts_amount ? formatToFloat(data.fgts_amount) : 0,
          vlrRenda: data.salary ? formatToFloat(data.salary) : 0,
        };

        if (esteParticipante) {
          const response = await api.put(
            `/builders/participants/${esteParticipante.id}`,
            formData
          );

          const newParticipants = serviceOrder.participantes?.slice();
          if (newParticipants?.length) {
            const participantIndex = newParticipants.findIndex(
              (participant) => participant.id === esteParticipante.id
            );
            if (participantIndex && participantIndex >= 0) {
              newParticipants[participantIndex] = response.data;
              setServiceOrder({
                ...serviceOrder,
                participantes: newParticipants,
              });
            }
          }
        } else {
          const response = await api.post('/builders/participants/', formData);

          setServiceOrder({
            ...serviceOrder,
            participantes: [{ ...serviceOrder.participantes }, response.data],
          });
        }
        setShowForm(0);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [osId, serviceOrder, setServiceOrder]
  );

  // const handleDownloadProposal = async (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     const response = await api.get(
  //       `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${osId}/proposal`,
  //       {
  //         responseType: 'blob',
  //       }
  //     );
  //     const urlData = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = urlData;
  //     link.download = `Proposal.pdf`;
  //     link.click();
  //     setTimeout(() => {
  //       window.URL.revokeObjectURL(urlData);
  //       link.remove();
  //     }, 100);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire('Opss...', 'Ocorreu um erro, arquivo não encontrado.', 'error');
  //   }
  // };

  /*   const handleDownloadProposta = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // Faltaria agregar la ruta del archivo desde backend
    await api
      .get(`/${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${osId}/proposal`, {
        responseType: 'blob',
      })
      .then((response) => {
        FileDowload(response.data, 'proposta.pdf');
      });
  }; */

  const handleDownloadAnalysis = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await api.get(
        `builders/service-orders/${osId}/files/13`,
        {
          responseType: 'blob',
        }
      );
      const urlData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlData;
      link.download = 'Analise.pdf';
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(urlData);
        link.remove();
      }, 100);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire('Opss...', 'Ocorreu um erro, arquivo não encontrado.', 'error');
    }
  };

  /*   const handleDownloadAnalise = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // Faltaria agregar la ruta del archivo desde backend
    await api.get(`/service-orders/proposal`).then((response) => {
      FileDowload(response.data, 'proposta.pdf');
    });
  }; */
  return (
    <>
      <Container>
        <div className="row container">
          <div className="col">
            <Avatars onSelectAvatar={handleAvatarSelected} />

            <ModalForm
              show={showForm === 1}
              onSubmit={handleSubmitClient}
              data={dataForm}
              onHide={() => setShowForm(0)}
            />
            <ModalForm
              show={showForm === 2}
              onSubmit={handleSubmitSpouse}
              data={dataForm}
              onHide={() => setShowForm(0)}
            />
            <ModalForm
              show={showForm === 3}
              onSubmit={handleSubmitParticipant}
              data={dataForm}
              onHide={() => setShowForm(0)}
            />
          </div>
          <div className="mt-5 mt-lg-0">
            <div className="ms-4">
              <button
                type="button"
                className="ms-5 bg-[#01CC9D] w-[20rem] h-[1.625rem] rounded-[1.625rem] border-none
                  text-slate-50 flex flex-row items-center justify-center gap-3 hover:bg-[#41c2a4]"
                onClick={(e) => handleDownloadAnalysis(e)}
              >
                <BsDownload className="" /> Baixar analise de crédito
              </button>
            </div>
          </div>
        </div>
      </Container>
      <Loading active={loading} />
    </>
  );
};

export default UserData;
