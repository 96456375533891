import React, { useCallback, useEffect, useState } from 'react';
import { IoMdLock } from 'react-icons/io';
import api from '~/services/api';

import Toast from '~/utils/toast';
import { useProfile } from '~/hooks/Profile';
import { IMEngineer } from '~/models/Engineer';
import ModalChangePassword from './Components/ModalChangePassword';

import FormEngineer from './Components/Form';
import AvatarEngineer from './Components/Avatar';
import { Container } from './styles';

export interface IFormData {
  id?: number;
  user_id?: number;
  nome: string;
  cpf: string;
  profession?: string;
  celular?: string;
  description?: string;
}

const PersonalData: React.FC = () => {
  const { setEngineer, setLoading } = useProfile();
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      await api
        .get<IMEngineer>(`${process.env.REACT_APP_PREFIX_ROUTE}/engineer`)
        .then((response) => {
          setEngineer(response.data);
        });
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Ocorreu um erro ao carregar seus dados pessoais.',
      });
    } finally {
      setLoading(false);
    }
  }, [setEngineer, setLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container>
      <div className="container p-0 folder">
        <div className="row">
          <div className="col-xl-8 order-2 order-xl-1 left">
            <FormEngineer />
          </div>
          <div className="col-xl-4 order-1 order-xl-2 right">
            <div className="row mt-5 mx-auto mx-lg-auto me-lg-5 d-flex justify-content-center">
              <AvatarEngineer />
              <div className="mt-3 p-0 ms-3 d-flex justify-content-center">
                <button
                  type="button"
                  className="border-0 d-flex align-items-center btn-change-password"
                  onClick={() => setShowModalChangePassword(true)}
                >
                  <b>Alterar senha</b> <IoMdLock size={28} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword
        show={showModalChangePassword}
        onHide={() => setShowModalChangePassword(false)}
      />
    </Container>
  );
};

export default PersonalData;
