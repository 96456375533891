import styled from 'styled-components';

interface IBar {
  percent: number;
}

export const Container = styled.div`
  padding: 1%;
  margin: 5px;
  border-radius: 10px;

  .title {
    margin-left: 5%;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .error-box {
    font-size: 13px;
    color: red;
  }

  .total-size {
    display: flex;
    align-items: flex-end;
    justify-content: end;
  }
`;

export const Bar = styled.div<IBar>`
  background: #e1fcf4;
  border-radius: 38px;
  height: 15px;
  position: relative;
  overflow: hidden;

  div {
    transition-duration: 0.3s;
    border-radius: 38px;
    width: ${(props) => (props.percent >= 100 ? 100 : props.percent)}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00cc9d;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
`;
