import React from 'react';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';

import { Container } from './styles';

const EmAnalise: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  return (
    <Container className="container col-md-11 col-11 border border-warning border-3 card-dashboard justify-content-center p-4 mb-5">
      <div className="m-4">
        <div className="d-flex  align-items-center mb-4">
          <div className="col-7 d-flex align-items-center">
            <h3 className="mb-0 font-weight-700 text-black">
              Sua solicitação está em análise...
            </h3>
          </div>
        </div>
        <div className="d-flex  align-items-center mb-4">
          <div className="col-11 d-flex text-dark text-opacity-50 align-items-center ">
            <h3 className="mb-0 ">
              Estamos análisando os documentos, assim que aprovado, liberamos os
              próximos passos!
            </h3>
          </div>
        </div>
      </div>
      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex text-dark text-opacity-25 align-items-center">
            <h3 className="mb-0">Valor solicitado: </h3>
          </div>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="col-7 d-flex text-dark align-items-center">
            <h3 className="mb-0 font-weight-700">
              {' '}
              {formatCurrency(serviceOrder.simulacao?.vlrFinanciado || 0)}
            </h3>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EmAnalise;
