import React from 'react';

// import { AuthProvider } from './Auth';
import { DatatableProvider } from './Datatable';
import { DefaultDataProvider } from './DefaultData';
import { ProfileProvider } from './Profile';
import { ReleasesOssProvider } from './ReleaseOss';
import { ServiceOrderProvider } from './ServiceOrder';
import { ServiceOrderProviderFake } from './ServiceOrderFake';
import { SimulationProvider } from './Simulation';
import { ValidErrorProvider } from './ValidError';
import { AuthProvider } from './contexts/Auth';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ValidErrorProvider>
      <DatatableProvider>
        <ReleasesOssProvider>
          <ServiceOrderProvider>
            <ServiceOrderProviderFake>
              <DefaultDataProvider>
                <SimulationProvider>
                  <ProfileProvider>{children}</ProfileProvider>
                </SimulationProvider>
              </DefaultDataProvider>
            </ServiceOrderProviderFake>
          </ServiceOrderProvider>
        </ReleasesOssProvider>
      </DatatableProvider>
    </ValidErrorProvider>
  </AuthProvider>
);

export default AppProvider;
