import styled from 'styled-components';

export const CardBox = styled.div`
  padding: 0px 5px;

  .container_dashboard_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
  }

  .card-dashboard {
    flex-direction: column;
    width: 33%;
    height: 530px;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
    @media (max-width: 1440px) {
      height: 540px;
    }
    @media (max-width: 1059px) {
      width: 60%;
      height: auto;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 563px) {
      width: 100%;
    }
  }
`;

export const CardBoxInformationsSub = styled.div`
  padding: 0px 5px;

  .container_dashboard_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
  }

  .card-dashboard {
    flex-direction: column;
    width: 33%;
    height: 250px;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 10px;
    @media (max-width: 1440px) {
      height: 280px;
    }
    @media (max-width: 1290px) {
      height: 300px;
    }
    @media (max-width: 1216px) {
      height: 320px;
    }
    @media (max-width: 1059px) {
      width: 60%;
      height: auto;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 563px) {
      width: 100%;
    }
  }
`;

export const EnvironmentContainer = styled.div`
  .card-dashboard {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
  }
`;
