import React from 'react';

import { IMFinality } from '~/models/Finality';
import { IMSpouse } from '~/models/Spouse';
import { IMTerrain } from '~/models/Terrain';
import { IMClient } from '~/models/Client';
import { Container } from './styles';

interface IParams {
  client: IMClient;
  spouse?: IMSpouse;
  finality?: IMFinality;
  terreno?: IMTerrain;
  numPis: number;
  agency: string;
}

const avis: React.FC<IParams> = ({
  client,
  spouse,
  finality,
  terreno,
  numPis,
  agency,
}) => (
  <Container className="ms-3 ms-lg-5 card-dashboard p-1 p-lg-4 mb-5">
    <div className="mx-4 mt-4 m-lg-4">
      <div className="d-flex  align-items-center mb-4">
        <div className="col-7 d-flex align-items-center">
          <h4 className="mb-0 font-weight-700 text-black">
            Informações pessoais
          </h4>
        </div>
      </div>
    </div>
    <div className="row mb-0 mb-lg-4 ms-3">
      <div className="col-auto order-0">
        <div className="row">
          <div className="col-auto">
            Cliente:{' '}
            <strong className="text-dark">
              <small>{client.nome ? client.nome : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-1">
        <div className="row">
          <div className="col-auto">
            Telefone:{' '}
            <strong className="text-dark">
              <small>{client.celular ? client.celular : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-2">
        <div className="row">
          <div className="col-auto">
            Tipo:{' '}
            <strong className="text-dark">
              <small>
                {finality?.finalidade ? finality.finalidade : '-'}
              </small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-3">
        <div className="row">
          <div className="col-auto">
            Email:{' '}
            <strong className="text-dark">
              <small>{client.email ? client.email : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
    </div>
    <div className="row mb-0 mb-lg-4 ms-3">
      <div className="col-auto order-0">
        <div className="row">
          <div className="col-auto">
            Estado do Terreno:{' '}
            <strong className="text-dark">
              <small>
                {terreno?.estado?.nome ? terreno?.estado?.nome : '-'}
              </small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-1">
        <div className="row">
          <div className="col-auto">
            Municipio do Terreno:{' '}
            <strong className="text-dark">
              <small>{terreno?.municipio ? terreno?.municipio : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-2">
        <div className="row">
          <div className="col-auto">
            Agência:{' '}
            <strong className="text-dark">
              <small>{agency || '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-3">
        <div className="row">
          <div className="col-auto">
            Número do PIS:{' '}
            <strong className="text-dark">
              <small>{numPis || '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-4">
        <div className="row">
          <div className="col-auto">
            Casado:{' '}
            <strong className="text-dark">
              <small>{client.casado ? 'Sim' : 'Não'}</small>
            </strong>
          </div>
        </div>
      </div>
    </div>
    <div className="row mb-4 ms-3">
      <div className="col-auto order-0">
        <div className="row">
          <div className="col-auto">
            Cônjuge:{' '}
            <strong className="text-dark">
              <small>{spouse?.nome ? spouse.nome : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
      <div className="col-auto order-1">
        <div className="row">
          <div className="col-auto">
            CPF:{' '}
            <strong className="text-dark">
              <small>{client.documento ? client.documento : '-'}</small>
            </strong>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default avis;
