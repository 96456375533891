import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '~/hooks/contexts/Auth';

interface IParams {
  hash: string;
}

const AutoLogin: React.FC = () => {
  const { autoSignIn } = useAuthContext();
  const params = useParams<IParams>();
  useEffect(() => {
    autoSignIn(params.hash);
  }, [autoSignIn, params.hash]);

  return <div />;
};

export default AutoLogin;
