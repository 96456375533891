import React from 'react';
import { BsFilePost } from 'react-icons/bs';
import { CompleteData } from './styles';

const AguardandoLiberacao: React.FC = () => (
  <CompleteData>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <BsFilePost size={40} color="#FEBA6A" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>Aguardando assinatura das fichas</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-7">
            <p>Para prosseguir, Preencha suas fichas cadastrais</p>
          </div>
        </div>
      </div>
    </div>
  </CompleteData>
);

export default AguardandoLiberacao;
