import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

// Icons
import addHomeWork from '~/assets/icons/add-home-work.svg';
import checkGreenIcon from "~/assets/icons/check-green.svg";
import closeRedIcon from "~/assets/icons/close-red.svg";
import noImage from '~/assets/icons/no-image.svg';

// Types
import { ICompareProps, IParams } from "./types";

// Components
import UserDefault from "~/components/UserDefault";


// Services
import api from "~/services/api";

// Models
import { IMRealStateProduct } from "~/models/RealEstateProduct";
import { IMRealEstateProductHomeOptions } from "~/models/RealEstateProductHomeOption";
import { Mock } from "./Partials/Mock";

export function Compare({ }: ICompareProps) {
    const { id: projectID } = useParams<IParams>()
    const [realEstateProduct, setREP] = useState<IMRealStateProduct | undefined>(undefined)
    const [repOnLoading, setREPOnLoading] = useState<boolean>(true)
    const [options, setOptions] = useState<IMRealEstateProductHomeOptions[]>([])
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [loadingMensage, setLoadingMensage] = useState('');


    // RECOVERY study Ideal Real Estate and Indicator
    useEffect(() => {
        if (!projectID) {
            setREPOnLoading(false)
            return;
        }

        api.get<IMRealStateProduct>(`builders/real-estate-products/${projectID}`).then((resolver) => {
            if (resolver.status !== 200) {
                setREPOnLoading(false)
                return;
            }

            setREP(resolver.data)

            setREPOnLoading(false)
        })
            .catch((rejects) => {
                setREPOnLoading(false)
            });
    }, [projectID]);

    useEffect(() => {
        if (!realEstateProduct || realEstateProduct!.houses!.length <= 0) return;

        const optionsInMount: IMRealEstateProductHomeOptions[] = []

        for (let i = 0; i < realEstateProduct!.houses!.length; i++) {
            // @ts-ignore
            for (let j = 0; j < realEstateProduct!.houses[i]!.home_options!.length; j++) {
                // @ts-ignore
                let homeOption = realEstateProduct!.houses[i]!.home_options[j];

                if (optionsInMount.find(optionMount => optionMount.option === homeOption.option || optionMount.id === homeOption.id)) continue;

                optionsInMount.push(homeOption);
            }
        }

        console.table(optionsInMount)

        setOptions(optionsInMount)

    }, [realEstateProduct])

    // TODO - USE OF USECALLBACK FOR PATTERN PURPOSES
    const handleDownloadProposal = useCallback(async (projectId:number) => {
      setLoadingMensage('Aguarde, estamos processando sua análise');
      try {
        setLoading(true);
        const response = await api.get(
          `builders/real-estate-products/${projectId}/generate-analysis`,
          {
            responseType: 'blob',
          }
        );
        const urlData = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlData;
        link.download = 'Analysis.pdf';
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urlData);
          link.remove();
        }, 100);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        await Swal.fire(
          'Opss...',
          'Ocorreu um erro, arquivo não encontrado.',
          'error'
        );
      }
    }, []);

    return (
      <>
        <div className="flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t h-full mb-[20rem] max-sm:!p-[none]">
            <UserDefault home />
            <div className="w-full h-auto flex flex-col md:gap-[3.75rem] max-sm:gap-[2rem]"
            >
                {
                    // @ts-ignore
                    !repOnLoading && realEstateProduct && realEstateProduct?.houses?.length > 0 && (
                        <>
                            <div className="flex flex-row flex-nowrap items-center md:justify-end max-sm:justify-center w-full max-sm:p-[1rem]">
                               
                                <button data-downloading={loading} className="data-[downloading=true]:disabled data-[downloading=true]:cursor-not-allowed disabled:opacity-2 md:w-[20.687rem] max-sm:w-full h-[3.5rem] rounded-[0.937rem] bg-[#39A887]
                                        flex flex-row items-center justify-center font-bold text-white
                                        transition-all hover:opacity-80 active:opacity-80 duration-[0.3s]"
                                  onClick={() => handleDownloadProposal(Number(projectID))}>
	                              {
		                              loading && (<span className="flex justify-center">  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle
                                          className="opacity-25"
                                          cx="12"
                                          cy="12"
                                          r="10"
                                          stroke="currentColor"
                                          strokeWidth="4"></circle>
                                            <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                              </svg>  Aguarde....</span>)
	                               }
                                   { !loading && 'Download da análise'}
                                  </button> 
                            </div>

                            <div className="w-full md:min-h-[43.75rem] max-sm:min-h-[10rem] h-auto flex flex-row flex-nowrap">
                                <div className="flex flex-col md:min-w-[19rem] md:max-w-[19rem] max-sm:flex-1 max-sm:min-w-[10rem] h-auto pt-[5.937rem] gap-[0.5rem] ">
                                    <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070] bg-[#F1F3F2] flex flex-row flex-nowrap items-center justify-start">Súites</span>
                                    <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070]  flex flex-row flex-nowrap items-center justify-start">Banheiros</span>
                                    <span className="w-full h-[2.187rem] pl-[0.625rem] rounded-l-[5.437rem] text-[0.90rem] font-medium text-[#707070] bg-[#F1F3F2] flex flex-row flex-nowrap items-center justify-start">Vagas de garagem</span>

                                    {
                                        options?.map((cOption, index) => (
                                            <span key={index}
                                                className={`´w-full h-[2.187rem]
                                                    pl-[0.625rem] rounded-l-[5.437rem]
                                                    text-[0.90rem] font-medium
                                                    text-[#707070] ${index % 2 && 'bg-[#F1F3F2]'}
                                                    flex flex-row flex-nowrap
                                                    items-center justify-start
                                                `}
                                            >
                                                {cOption.option}
                                            </span>
                                        ))
                                    }
                                </div>
                                <div className="w-full flex flex-col overflow-hidden rounded-tr-[0.937rem] rounded-tl-[0.937rem]">
                                    <div className="w-full max-w-[100%] h-auto flex flex-row flex-nowrap overflow-x-auto">
                                        {
                                            realEstateProduct && realEstateProduct?.houses?.map((house, index) => (
                                                <div key={index} className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] group/lastItem gap-[0.5rem] relative">
                                                    <div className="flex flex-col
                                                        items-center justify-between
                                                        rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                                        overflow-hidden
                                                        w-[12.25rem] h-[5.437rem]
                                                    "
                                                    >
                                                        <span className="w-full h-full overflow-hidden object-cover bg-gray-100 flex flex-col items-center justify-center">
                                                            {
                                                                !house.photo_upload_id && (
                                                                    <img
                                                                        src={noImage}
                                                                        alt="Imagem do projeto"
                                                                        className="max-w-[2.187rem] h-auto"
                                                                        loading="lazy"
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                house.photo_upload_id && (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/houses/${house.id}/photo`}
                                                                        alt="Imagem do projeto"
                                                                        className="max-w-full h-auto object-cover"
                                                                        loading="lazy"
                                                                    />
                                                                )
                                                            }
                                                        </span>
                                                        <span className="absolute z-[999] top-[3.437rem] left-[0.1rem] w-full h-2 bg-[#39A887] group-first/lastItem:rounded-l-[1rem] group-last/lastItem:rounded-r-[1rem]"></span>
                                                        <span>{house.name}</span>
                                                    </div>

                                                    <span key={index}
                                                        className={`´w-full h-[2.187rem]
                                                            bg-[#F1F3F2]
                                                            text-[0.90rem] font-medium
                                                            text-[#707070]
                                                            flex flex-row flex-nowrap
                                                            items-center justify-start
                                                            pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                                        `}
                                                    >
                                                        {house.bedrooms}
                                                    </span>

                                                    <span key={index}
                                                        className={`´w-full h-[2.187rem] text-[0.90rem] font-medium
                                                            text-[#707070]
                                                            flex flex-row flex-nowrap
                                                            items-center justify-start
                                                            pl-[29%]
                                                `}
                                                    >
                                                        {house.bathrooms}
                                                    </span>

                                                    <span key={index}
                                                        className={`´w-full h-[2.187rem]  text-[0.90rem] font-medium bg-[#F1F3F2]
                                                            text-[#707070]
                                                            flex flex-row flex-nowrap
                                                            items-center justify-start
                                                            pl-[29%] group-last/lastItem:rounded-r-[1rem]
                                                `}
                                                    >
                                                        {house.parking_spaces}
                                                    </span>

                                                     {
                                                        // @ts-ignore
                                                        options.map((hOption, hIndex) => {
                                                            const houseAlreadyOption = house && house?.home_options?.find((cOption) => cOption.option === hOption.option && cOption.id === hOption.id)

                                                            if (houseAlreadyOption) return (
                                                                <span key={hIndex}
                                                                    className={`´w-full h-[2.187rem] ${(hIndex) % 2 && 'bg-[#F1F3F2]'} text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]`}
                                                                >
                                                                    <img src={checkGreenIcon} alt="" className="!max-width-[1rem] h-auto" />
                                                                </span>
                                                            )

                                                            if (!houseAlreadyOption) return (
                                                                <span key={hIndex}
                                                                    className={`´w-full h-[2.187rem] ${(hIndex) % 2 && 'bg-[#F1F3F2]'} text-[0.90rem] font-medium text-[#707070] flex flex-row flex-nowrap items-center justify-start pl-[25%] group-last/lastItem:rounded-r-[1rem]`}
                                                                >
                                                                    <img src={closeRedIcon} alt="" className="!max-width-[1rem] h-auto" />
                                                                </span>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    repOnLoading && !realEstateProduct && (
                        <Mock />
                    )
                }

                {
                    // @ts-ignore
                    !repOnLoading && realEstateProduct && realEstateProduct?.houses?.length <= 0 && (
                        <div className="w-full h-[50%] flex flex-col items-center justify-start gap-[2rem]">
                            <img src={addHomeWork} alt="" />
                            <span className="font-semibold text-[1rem] text-center max-sm:p-[2rem]">
                                Cadastre no minimo 10 casas para realizar a Analise Comparativa
                            </span>
                            <button
                                onClick={() => history.push(`/produtos-imobiliario/novo-projeto/${projectID}/5`)}
                                className="md:w-[17.312rem] max-sm:w-[70%]
                                    h-[10rem] max-sm:h-auto
                                    max-sm:p-[1rem] rounded-[0.937rem]
                                    bg-[#39A887] text-bold
                                    text-white hover:opacity-80
                                    transition-all duration-[0.3s]
                                    active:opacity-80
                                ">
                                Ir para o cadastro de casas
                            </button>
                        </div>
                    )
                }

            </div>
        </div>
        </>
    )
}
