import styled from 'styled-components';

export const Container = styled.div`
  .search {
    border: 1px solid #e4e4e4;
    border-radius: 35px;
    height: 56px;
    background-color: #ffffff;

    :hover {
      cursor: pointer;
      background-color: #f4f4f4;

      input {
        cursor: pointer;
        background-color: #f4f4f4;
      }
    }
  }
`;
