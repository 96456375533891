import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';


// Types
import { IStudyProps } from './types';

import attachMoneyIcon from '~/assets/icons/attach_money.svg';
import checkBoxOutlineBlankIcon from '~/assets/icons/check_box_outline_blank.svg';
import holidayVillageIcon from '~/assets/icons/holiday_village.svg';
import idealAreaBuildIcon from '~/assets/icons/ideal-area-build.svg';
import { ModalAI } from './Partials/ModalAI';


export function Study({realEstateProduct, projectID, onRealEstateHouseAmount, onRealStateHouseidealArea, onRealStateHouseidealAreaBuild}: IStudyProps) {  
  const history = useHistory();
  const [realEstateHouseAmount, setRealEstateHouseAmount] = useState(0);
  const [realStateHouseidealArea, setRealStateHouseidealArea] = useState(0);
  const [realStateHouseidealAreaBuild, setRealStateHouseidealAreaBuild] = useState(0);

  // AUX Variables
  const AMOUNT_BY_METERS = useMemo(() => {
    const HOUSES_AMOUNT_BY_VGV =  realEstateProduct?.houses?.reduce(
      (current, a) => {
        const t =
          Number(current.vgv || 0) + Number(a.vgv);

        return {
          vgv: t,
        };
      },
      { vgv: 0 }
    ).vgv || 1
    const HOUSES_AMOUNT_AREA_LENGTH =  realEstateProduct?.houses?.reduce(
      (current, a) => {
        const t =
          Number(current.house_area || 0) +
          Number(a.house_area);

        return {
          house_area: t,
        };
      },
      { house_area: 0 }
    ).house_area || 1
    const HOUSES_BY_METERS_RAW = HOUSES_AMOUNT_BY_VGV / HOUSES_AMOUNT_AREA_LENGTH
   
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(HOUSES_BY_METERS_RAW)

  }, [realEstateProduct])

  // MOUNT THE range LIST TO GET VGV MEDIA
  useEffect(() => {
    if (!realEstateProduct?.houses) return;

    const rangeAmounts: {
      [key: number]: {
        vgv: number;
        house_area: number;
        terrain_area: number;
      }[];
    } = {};

    // MOUT THE RANGE ESTRUCTURE
    for (let i = 0; i < realEstateProduct.houses.length || 0; i++) {
      const amountRaw = String(
        parseFloat(realEstateProduct.houses[i].vgv.toString() || '0')
      );

      const rangerHouse = {
        vgv: realEstateProduct.houses[i].vgv,
        house_area: realEstateProduct.houses[i].house_area,
        terrain_area: realEstateProduct.houses[i].terrain_area,
      };

      if (amountRaw.length - 1 === 3) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 4) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '0000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '9999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 5) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '00000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '99999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 6) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '000000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '999999');

        if (
          rangeAmounts[rangerIndex] &&
          rangerHouse.vgv >= parseFloat(rangerIndex.toString()) &&
          rangerHouse.vgv <= parseFloat(rangerIndexMax.toString())
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      } else if (amountRaw.length - 1 === 7) {
        const rangerIndex = parseInt(amountRaw.slice(0, 1) + '0000000');
        const rangerIndexMax = parseInt(amountRaw.slice(0, 1) + '9999999');

        if (
          rangeAmounts[rangerIndex] &&
          (rangerHouse.vgv >= parseFloat(rangerIndex.toString()) ||
            rangerHouse.vgv <= parseFloat(rangerIndexMax.toString()))
        ) {
          rangeAmounts[rangerIndex].push(rangerHouse);
        } else {
          rangeAmounts[rangerIndex] = [rangerHouse];
        }
      }
    }

    let f: {
        vgv: number;
        house_area: number;
        terrain_area: number;
      }[] = [];

    // const k = Object.keys(rangeAmounts) as unknown as number[];

    // console.table(k)
    console.table(rangeAmounts)
    
    // k.fo
    // for (let j = 0; j < k.length; j++) {
    //   if (rangeAmounts[k[j]].length <= 1) continue;

    //   if (f.length <= 0) {
    //     f.push();
    //     continue;
    //   }

    //   if (f && rangeAmounts[k[j]].length > f.length) {
    //     f = rangeAmounts[k[j]];
    //   } else if (j >= 1 && f && rangeAmounts[k[j - 1]].length > f.length) {
    //     f = rangeAmounts[k[j]];
    //   }
    // }

    Object.entries(rangeAmounts).forEach((entry) => entry[1].forEach(amounts => f.push(amounts)))

    console.table(rangeAmounts)
    console.table(f)

    if (f && f.length >= 2) {
      const houseAmount =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.vgv) + Number(b.vgv);
            return { vgv: v };
          },
          { vgv: 0 }
        ).vgv || 1) / f.length;

      const idealArea =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.house_area) + Number(b.house_area);
            return { house_area: v };
          },
          { house_area: 0 }
        ).house_area || 1) / f.length;

      const idealAreaBuild =
        (f?.reduce(
          (a, b) => {
            let v = 0;
            v = Number(a.terrain_area) + Number(b.terrain_area);
            return { terrain_area: v };
          },
          { terrain_area: 0 }
        ).terrain_area || 1) / f.length;

      console.log('Range Amount Media: ', houseAmount);
      console.log('Range Ideal Area Media: ', idealArea);
      console.log('Range ideal Area Build Media: ', idealAreaBuild);

      handlerOnRealEstateHouseAmount(houseAmount);
      handlerOnRealStateHouseidealArea(idealArea);
      handlerOnRealStateHouseidealAreaBuild(idealAreaBuild);
    }
  }, [realEstateProduct]);

  function handlerOnRealEstateHouseAmount(value: number = 0) {
    try {      
      setRealEstateHouseAmount(value);
      onRealEstateHouseAmount(value);
    } catch (error) {
      // do anything
    }
  }

  function handlerOnRealStateHouseidealArea(value: number = 0) {
    try {      
      onRealStateHouseidealArea(value);
      setRealStateHouseidealArea(value);
    } catch (error) {
      // do anything
    }
  }

  function handlerOnRealStateHouseidealAreaBuild(value: number = 0) {
    try {      
      onRealStateHouseidealAreaBuild(value);
      setRealStateHouseidealAreaBuild(value);
    } catch (error) {
      // do anything
    }
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-[2.5rem] bg-[#39A887] rounded-[0.937rem] p-[1rem] md:p-[1.875rem] md:pt-[1.25rem]">
      <div className="w-full flex flex-row flex-nowrap justify-between text-white leading-normal">
        <div className="flex flex-col">
          <span className="font-[400] text-[1.5rem] max-sm:text-[0.875rem]">
            Resultados do estudo
          </span>
          <span className="font-bold text-[1.625rem] max-sm:text-[0.875rem]">
            imobiliário ideal e indicador
          </span>
        </div>
        <div className='flex-1 flex flex-row items-center justify-end'>
          <ModalAI projectData={realEstateProduct} rep_id={realEstateProduct?.id || -1} />
        </div>
      </div>

      <div className="w-full flex md:flex-row md:flex-nowrap md:items-center md:justify-between flex-col items-end justify-start gap-[1.25rem] md:gap-0">
        <div className="flex-1 md:max-w-[39.753rem] w-full flex flex-col gap-[0.625rem] p-[1rem] bg-white rounded-[0.937rem]">
          <span className="font-bold leading-normal text-[1.125rem]">
            Maturidade do condomínio
          </span>

          <div className="flex flex-row flex-nowrap items-center justify-between gap-[1.25rem]">
            <div className="flex-1 h-[1.2rem] rounded-[6.25rem] bg-[#F1F1F1] flex flex-row items-center overflow-hidden">
              <span
                className={`flex flex-row items-center justify-center bg-[#A4DECD] w-full h-full rounded-[6.25rem] text-white
                    text-[0.8rem] select-none cursor-default transition-all duration-[0.3s]`}
                style={{
                  width: `${
                    realEstateProduct?.built_houses &&
                    realEstateProduct?.quantity_terrains
                      ? ((realEstateProduct?.built_houses || 1) /
                          (realEstateProduct?.quantity_terrains || 1)) *
                        100
                      : 0
                  }%
                        `,
                }}
              ></span>
            </div>
            <span className="text-[#414142] text-[1.125rem] font-semibold leading-normal cursor-default select-none">
              {
                // @ts-ignore
                realEstateProduct?.built_houses > 0 &&
                realEstateProduct?.quantity_terrains &&
                realEstateProduct?.quantity_terrains > 0
                  ? `${parseInt(
                      `${
                        ((realEstateProduct?.built_houses || 1) /
                          (realEstateProduct?.quantity_terrains || 1)) *
                        100
                      }`,
                      10
                    )}%`
                  : '0%'
              }
            </span>
          </div>
        </div>
        <button
          type="button"
          onClick={() =>
            history.push(`/produtos-imobiliario/lotes/${projectID}`)
          }
          className="flex-1 md:max-w-[18.125rem]
                w-[9.687rem] h-[3.375rem]
                flex flex-col items-center
                justify-center gap-[0.625rem]
                p-[0.625rem] bg-white
                hover:opacity-80 active:opacity-80
                transition-all duration-[0.3s]
                rounded-[0.937rem] hover:shadow-md
                active:shadow-md"
        >
          <span className="font-bold leading-normal text-[0.75rem] text-[#39A887]">
            Ver lotes carregados
          </span>
        </button>
      </div>

      <div className="w-full flex flex-col md:flex-row md:flex-nowrap items-start justify-start md:gap-[2.5rem] gap-[1.25rem]">
        <div
          className="
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]
            bg-IdealArea
          "
        >
          <span className="flex flex-row items-center justify-center  w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={idealAreaBuildIcon} />
          </span>
          <span className="w-full h-full  flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Área do terreno ideal</span>
            <span className="text-[#707070]">
              {
                // realEstateProduct
              }
              A partir de{' '}
              {realStateHouseidealArea.toLocaleString('pt-BR', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 2,
              })}m²
              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1
            flex
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]  
            bg-IdealAreaBuild"
        >
          <span className="flex flex-row items-center justify-center w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={checkBoxOutlineBlankIcon} />
          </span>
          <span className="w-full h-full  flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Área construída ideal</span>
            <span className="text-[#707070]">
              A partir de{' '}
              {
                realStateHouseidealAreaBuild.toLocaleString('pt-BR', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 2,
                }) + 'm²'
              }

              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem] 
            bg-AverageLandValue"
        >
          <span className="flex flex-row items-center justify-center  w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={attachMoneyIcon} />
          </span>
          <span className="w-full h-full flex flex-col  font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Valor médio de venda da casa</span>
            <span className="text-[#707070]">
              A partir de{' '}
              {
                Intl
                  .NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                  .format(realEstateHouseAmount)              
              }
              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>

        <div
          className=" 
            w-full
            h-[6.875rem]
            md:h-auto
            md:max-w-[18.125rem] 
            md:min-w-[9.687rem] 
            flex-1 
            flex 
            flex-col 
            gap-[0.937rem] 
            rounded-[0.937rem] 
            p-[1.5rem]
            md:p-[1.25rem]
            md:pt-[2.5rem]
            bg-AverageHouseValue"
        >
          <span className="flex flex-row items-center justify-center w-[3.312rem] h-[3.312rem] overflow-hidden">
            <img src={holidayVillageIcon} />
          </span>
          <span className="w-full h-full flex flex-col font-semibold leading-normal text-[0.75rem] text-[#414142]">
            <span>Valor médio de venda do imóvel por m²</span>
            <span className="text-[#707070]">
              {
                AMOUNT_BY_METERS + '/m²'
              }

              {!realEstateProduct && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-emerald-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 "
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </span>
          </span>
        </div>
      </div>

      <div className="w-full flex md:flex-row md:flex-nowrap flex-col items-center justify-center md:items-center md:justify-between p-[1.25rem] bg-white rounded-[0.937rem] max-sm:gap-[1rem]">
        <span className="font-semibold leading-normal md:text-[1.125rem] max-sm:text-[1rem] text-center cursor-default select-none">
          Análise das Casas
        </span>

        <button
          onClick={() =>
            history.push(`/produtos-imobiliario/compare/${projectID}`)
          }
          type="button"
          className="w-[9.375rem] h-[3.375rem]
                rounded-[0.937rem] bg-[#39A887]
                hover:opacity-80 active:opacity-80
                transition-all duration-[0.3s]
                text-white
                flex flex-row flex-nowrap
                items-center justify-center
                shadow-sm
              "
        >
          <span className="font-bold">Veja análise</span>
        </button>
      </div>
    </div>
  );
};
