import React from 'react';
import { BsClockFill } from 'react-icons/bs';
import { HiCalendar } from 'react-icons/hi';

import { ITask } from '~/models/Home/Task';

interface ICardTask {
  tasks: ITask[];
  onHandleClickTasks(value: ITask): void;
}

const CardTask: React.FC<ICardTask> = ({ tasks, onHandleClickTasks }) => (
  <div className="row max-h-[100%] overflow-y-auto">
    {tasks.length > 0 ? (
      <>
        {tasks.map((task) => (
          <button
            key={task.id}
            type="button"
            className="d-flex align-items-center reminder-task p-3 w-100 border-0"
            onClick={() => onHandleClickTasks(task)}
          >
            <div className="calendar p-3 me-2">
              <HiCalendar size={25} color="#39A887" />
            </div>
            <div>
              <h2 className="fw-semibold reminder text-start">
                {task.title}
              </h2>
              <span className="fw-light clock">
                <BsClockFill size={12} color="#707070" className="me-2" />
                {task.date} {task.period_start} - {task.period_end}
              </span>
            </div>
          </button>
        ))}
      </>
    ) : (
      <p className="no-data text-center">
        Nenhuma tafera cadastrada para esse dia
      </p>
    )}
  </div>
);

export default CardTask;
