import React from 'react';

import newverde from '~/assets/icons/newverde.svg';

import { useProject } from '~/pages/Projects/Hooks/useProject';
import { IMProject } from '~/models/Project';
import formatCurrency from '~/utils/formatCurrency';
import { Container } from './styles';

const SelectedProjects: React.FC = () => {
  const { selectedProjects } = useProject();

  return (
    <Container>
      <div className="row my-5 px-3 content">
        <div className="col">
          {Array.from(selectedProjects).map((project: IMProject) => (
            <>
              <div className="row">
                <div className="col-auto">
                  <img
                    src={newverde}
                    alt="newverde"
                    color="#707070"
                    id="Iconnaranja"
                  />
                </div>
                <div className="col-auto project-name">{project.project}</div>
                <div className="col-lg-auto d-lg-flex justify-content-lg-end pe-5 amount centrar">
                  {formatCurrency(project.gross_amount)}
                </div>
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SelectedProjects;
