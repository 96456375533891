import React, { useCallback, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import UserDefault from '~/components/UserDefault';
import { useAuthContext } from '~/hooks/contexts/Auth';
import ButtonsSearch from './Partials/ButtonsSearch';
import InputSearch from './Partials/InputSearch';
import TableOS from './Partials/Table';

import { Container, GeneralContainer } from './styles';

const SeviceOrder: React.FC = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [responsable, setResponsable] = useState<number | null>(null);

  const handleClickNovaOS = useCallback(() => {
    history.push('/finalidades');
  }, [history]);

  const handleButtonChange = useCallback((value: number | null) => {
    setResponsable(value);
  }, []);

  return (
    <GeneralContainer className="ms-lg-5">
      <div className="container">
        <div className="py-4" />
        <div className="mt-5 mt-lg-0">
          <Container className="w-100 px-lg-3 mt-5">
            <div className="mt-5 mt-lg-0">
              <UserDefault ordem />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 px-2">
              <div className="col-5">
                <InputSearch />
              </div>
              {(!user!.isConstrutor?.quantity
                || user!.isConstrutor?.quantity > user!.qtdOS) && (
                <div className="col-2">
                  <div>
                    <button
                      type="button"
                      className="border-0 w-100 w-lg-75 btn-os d-flex align-items-center justify-content-center"
                      onClick={handleClickNovaOS}
                    >
                      <BsPlus size={22} color="#ffffff" />
                      Nova OS
                    </button>
                  </div>
                </div>
              )}
              <div className="col-5">
                <ButtonsSearch onChange={handleButtonChange} />
              </div>
            </div>
            <div className="col">
              <div className="col-12">
                <TableOS responsible={responsable} />
              </div>
            </div>
          </Container>
        </div>
      </div>
    </GeneralContainer>
  );
};

export default SeviceOrder;
