import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import { RiArrowLeftSLine } from 'react-icons/ri';

import Loading from '~/components/Loading';

import UserAvatar from '~/components/UserAvatar';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useDefaultData } from '~/hooks/DefaultData';
import { Container } from './styles';
import Steps from './Steps';
import BarInformation from './BarInformation';

export interface IStepOwner {
  id: number;
  osID: string;
  name: string;
  email: string;
  cellphone: string;
  errorName: boolean;
  errorEmail: boolean;
  errorCellphone: boolean;
  createElement: boolean;
}

export interface IAnalysis {
  id: string;
  requested_value: string;
  amortization_id: string;
  deadline: string;
  rate: string;
  entry_amount: string;
  fgts_value: string;
  financed_amount: string;
  ready_property_value: string;
  final_installment_amount: string;
  initial_installment_amount: string;
  income_value: string;
}

export interface IClient {
  id: string;
  name: string;
  document: string;
  rg: string;
  profession: string;
  zip_code: string;
  address: string;
  neighborhood: string;
}

export interface ITerrains {
  address: string;
  city: string;
  city_id: string;
  district: string;
  ownersAmount: string;
  personType: string;
  state_id: string;
  zipcode: string;
}

interface IParams {
  osId: string;
}

export interface IRequestData {
  terreno_id: string;
  orientations: string;
}

const EmitirContrato: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { getServiceOrderData, serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();
  const [loading, setLoading] = useState(true);
  const [openDays, setOpenDays] = useState(0);
  const [mcfDays, setMcfDays] = useState(0);

  useEffect(() => {
    if (params.osId) {
      setDefaultData();
      getServiceOrderData(parseInt(params.osId, 10));
    } else {
      history.push('/home');
    }
  }, [params.osId, getServiceOrderData, setDefaultData, history]);

  useEffect(() => {
    if (serviceOrder) {
      const dateToday = new Date();
      const dateCreate = new Date(serviceOrder.created_at);
      const dateCompleted = new Date(serviceOrder?.last_log?.completed_at);
      const dateCreateMCF = new Date(serviceOrder?.last_log?.created_at);

      if (String(dateCompleted).length <= 0) {
        setMcfDays(differenceInDays(dateToday, dateCreateMCF));
      }
      setOpenDays(differenceInDays(dateToday, dateCreate));
      setLoading(false);
    }
  }, [serviceOrder]);

  return (
    <>
      <div className="">
        <Container className="container">
          <div className="row justify-content-between h-100">
            <div className="d-flex flex-row">
              <div className="d-flex col-8 align-items-center me-2">
                <button
                  type="button"
                  className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4 px-4"
                  onClick={() => history.goBack()}
                >
                  <RiArrowLeftSLine
                    className="back"
                    size={28}
                    color="#B8B9BA"
                  />
                </button>
                <div>
                  <h1 className="title-assinatura row mt-4">
                    Emitir contrato de construção e terreno
                  </h1>
                  <h1 className="title-sub row">
                    Para dar o próximo passo, preencha os dados para a emissão
                    do seu contrato
                  </h1>
                  <h1 className="title-sub row">de construção e terreno</h1>
                </div>
              </div>

              <div className="col-4 mt-2 d-flex justify-content-end align-items-center">
                <UserAvatar />
              </div>
            </div>
            <div className="bg-gray mt-2" />
            <BarInformation mcfDays={mcfDays} osDays={openDays} />
            <hr className="mt-3" />
            <Steps />
          </div>
        </Container>
      </div>
      <Loading active={loading} />
    </>
  );
};

export default EmitirContrato;
