// Types
import { IAmountProps } from "./types";

// Icons
import areaAmountIcon from "~/assets/icons/area-amont.svg";
import dolarIcon from "~/assets/icons/dolar-icon.svg";
import frameworkPrintIcon from "~/assets/icons/framework-print.svg";
import housePrintIcon from "~/assets/icons/house-print.svg";

// Styles
import { useServiceOrder } from "~/hooks/ServiceOrder";
import formatCurrency from "~/utils/formatCurrency";
import { getAccumulatedTotal, getSuggestedFootage } from "~/utils/serviceOrderCalculations";
import "./styles.css";

export function Amounts(_props: IAmountProps) {
    const {serviceOrder} = useServiceOrder()

    // AUX Variables
    const LENGHT_AREA = new Intl.NumberFormat("pt-BR", {
        style: 'unit', unit: 'meter'
    }).format(getSuggestedFootage(serviceOrder));

    return (
        <div className="general-container" id="amounts-container">
            <div id="amounts-header">
                <span className="general-text-bold">Valores</span>
                <span className="general-text-label">Todos os valores relacionados a OS</span>
            </div>

            <div className="general-box" id="amounts-main-box">
                <div id="amounts-main-box-content">
                    <div id="amounts-main-box-header">
                        <span id="amounts-tag">
                            {serviceOrder?.simulacao?.amortizacao?.amortizacao || 'N/A'}
                        </span>
                    </div>
                    <div id="amounts-main-box-columns">
                        <div className="main-box-column" id="box-column-one">
                            <span className="box-text-group">
                                <span className="general-text-bold">Valor Incial</span>
                                <span className="general-text-label">Prestação</span>
                            </span>
                            <span className="box-text-group">
                                <span className="general-text-bold">Valor Final</span>
                                <span className="general-text-label">Prestação</span>
                            </span>
                        </div>
                        <div className="main-box-column" id="box-column-two">
                            <span className="general-text-bold">
                                {formatCurrency(serviceOrder.simulacao?.vlrParcInic || 0)}
                            </span>
                            <span className="general-text-bold">
                               {formatCurrency(serviceOrder.simulacao?.vlrParcFinal || 0)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <span className="line-separator"></span>

            <ul className="amounts-ul">
                <li className="amounts-ul-li">
                    <span className="amount-li-icon">
                        <img src={dolarIcon} alt="" />
                    </span>
                    <span className="amounts-li-content">
                        <span className="general-text-label">Saldo do terreno: </span>
                        <span className="general-text-bold"> {formatCurrency(serviceOrder.simulacao?.vlrTerreno || 0)}</span>
                    </span>
                </li>    

                <li className="amounts-ul-li">
                    <span className="amount-li-icon">
                        <img src={areaAmountIcon} alt="" />
                    </span>
                    <span className="amounts-li-content">
                        <span className="general-text-label">Valor disponível para construção: </span>
                        <span className="general-text-bold">
                             {
                                formatCurrency(
                                    getAccumulatedTotal(
                                        serviceOrder.orc_parametros, 
                                        serviceOrder.orc_ambientes
                                    )
                                    + 
                                    (serviceOrder.simulacao?.vlrTerreno ?? 0)
                                )
                            }
                        </span>
                    </span>
                </li>                
            </ul>

            <span className="line-separator"></span>

            <ul className="amounts-ul">
                <li className="amounts-ul-li">
                    <span className="amount-li-icon">
                        <img src={housePrintIcon} alt="" />
                    </span>
                    <span className="amounts-li-content">
                        <span className="general-text-label">Tamanho do m² estimado:  </span>
                        <span className="general-text-bold">
                            {
                               LENGHT_AREA
                            }
                        </span>
                    </span>
                </li>   

                <li className="amounts-ul-li">
                    <span className="amount-li-icon">
                        <img src={frameworkPrintIcon} alt="" />
                    </span>
                    <span className="amounts-li-content">
                        <span className="general-text-label">Tipo de Acabamento: </span>
                        <span className="general-text-bold">{serviceOrder.orc_parametros?.acabamento?.tipo_acabamento || 'Indefinido'}</span>
                    </span>
                </li>                
            </ul>
        </div>
    )
}