import React, { useState } from 'react';

import Swal from 'sweetalert2';
import { useProject } from '~/pages/Projects/Hooks/useProject';
import api from '~/services/api';
import FinalPrices from './FinalPrices';
import SelectedProjects from './SelectedProjects';
import { Container } from './styles';

interface IRequestResponse {
  url: string;
}

const InnerContainer: React.FC = () => {
  const { totalNetAmount, inputNumberMeters, selectedProjects } = useProject();
  const [loading, setLoading] = useState(false);

  const handleBuyClick = async () => {
    try {
      setLoading(true);

      if (
        (inputNumberMeters !== 0 && !inputNumberMeters) ||
        inputNumberMeters < 80
      ) {
        setLoading(false);
        Swal.fire({
          icon: 'warning',
          text: 'O projeto precisar ter no minimo 80m²',
        });
        return;
      }

      if (!selectedProjects || selectedProjects?.length <= 0) {
        setLoading(false);
        Swal.fire({
          icon: 'warning',
          text: 'Selecione pelo menos um projeto',
        });
        return;
      }

      api
        .post<IRequestResponse>(
          `${process.env.REACT_APP_API_URL}/builders/projects`,
          { 
            projects: selectedProjects,
            footage: inputNumberMeters, 
            amount: totalNetAmount,
          }
        )
        .then((res) => {
          setLoading(false);

          Swal.fire(
            'Só mais alguns passos',
            'Obrigado! Enviamos um e-mail para você finalizar a compra',
            'success'
          );
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'No momento não é possível efetuar a compra',
            'error'
          );
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        'Opss...',
        'No momento não é possível efetuar a compra',
        'error'
      );
    }
  };

  return (
    <Container className="row m-3">
      <div className="px-3 py-4">
        <div className="col">
          <div className="row ps-3 title">
            Total com todos os seus projetos selecionados
          </div>
          <div className="row mt-4 ps-3 subtitle">
            Você irá agregar muito valor, com esses projetos personalizados para
            seu cliente!
          </div>
          <SelectedProjects />
          <FinalPrices />
        </div>
        <div className="col-auto my-4 ms-1 centrar">
          <button
            disabled={
              (inputNumberMeters !== 0 && !inputNumberMeters) ||
              inputNumberMeters < 80 ||
              !selectedProjects ||
              selectedProjects.length <= 0 ||
              loading
            }
            type="button"
            className={`button-proposal px-5 py-2 test1
              ${
                (inputNumberMeters !== 0 && !inputNumberMeters) ||
                inputNumberMeters < 80 ||
                !selectedProjects ||
                selectedProjects.length <= 0 ||
                (loading && 'cursor-not-allowed opacity-70')
              }`}
            onClick={handleBuyClick}
          >
            {loading ? 'Aguarde...' : <b>Comprar Projetos</b>}
          </button>
          {(inputNumberMeters !== 0 && !inputNumberMeters) ||
          inputNumberMeters < 80 ? (
            <p className="mt-5 text-sm text-red-400">
              OBS:. Nossa equipe da MCF Projetos segue o requisito inicial
              mínimo de metragem quadrada para construção, que é de 80 metros
              quadrados.
            </p>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default InnerContainer;
