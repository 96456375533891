import React, { useCallback, useEffect, useState } from 'react';

import { BsFillCalendarFill } from 'react-icons/bs';
import InputDate from '~/components/InputDate';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { useStep } from '~/pages/Simulation/Hooks/useStep';
import NextButton from '../NextButton';
import PreviousButton from '../PreviousButton';
import { Container } from './styles';

const InputBirthdate: React.FC = () => {
  const { step, setStep, totalSteps } = useStep();
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [birthdate, setBirthdate] = useState<Date>();
  const [errorData, setErrorData] = useState('');
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [difference, setDifference] = useState<number>(0);

  const handleClickOutsideCalendar = useCallback(
    (event) => {
      const calendarElement = document.getElementById('calendar');
      if (calendarElement) {
        const elements = calendarElement.childNodes;
        if (elements.length > 0) {
          const element = elements[0];
          if (
            !element.contains(event.target)
            && event.target.tagName.toLowerCase() !== 'input'
          ) {
            setActiveCalendar(false);
          }
        }
      }
    },
    [setActiveCalendar]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideCalendar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCalendar);
    };
  }, [handleClickOutsideCalendar]);

  const calculateDifference = useCallback((value: any) => {
    const ageDifMs = Date.now() - value;
    const ageDate = new Date(ageDifMs);
    setDifference(Math.abs(ageDate.getUTCFullYear() - 1970));
  }, []);

  useEffect(() => {
    calculateDifference(birthdate);
  }, [birthdate, calculateDifference]);

  const handleChangeBirthdate = useCallback(
    (value: Date) => {
      setActiveCalendar(false);
      setErrorData('');
      if (value !== undefined) {
        setErrorData('');
        setBirthdate(value);
      }
    },
    [setBirthdate, setErrorData]
  );

  const handleNextStep = useCallback(() => {
    if (!birthdate?.getDate()) {
      setErrorData('Necessário informar a data de nascimento');
    } else if (difference < 19 || difference > 100) {
      setErrorData('Sua idade não é elegivel');
    } else {
      setServiceOrder({
        ...serviceOrder,
        cliente: { dt_nascimento: birthdate },
      } as IMServiceOrder);
      setStep(totalSteps > 2 ? 3 : 5);
    }
  }, [
    birthdate,
    difference,
    setServiceOrder,
    serviceOrder,
    setStep,
    totalSteps,
  ]);

  const handleClickPrevious = useCallback(
    (target_step) => {
      setStep(target_step);
    },
    [setStep]
  );

  useEffect(() => {
    if (serviceOrder.cliente === undefined) {
      setBirthdate(undefined);
    } else {
      const newDate = new Date(String(serviceOrder.cliente.dt_nascimento));
      setBirthdate(newDate);
    }
  }, [serviceOrder]);

  return (
    <Container>
      <p className="font-qual mt-4">
        Qual a data de nascimento da pessoa mais velha nesse financiamento?
      </p>
      <div className="fondo-gris">
        <label className="font-renda mt-5 w-100">Data:</label>
        <InputDate
          name="birthdate"
          className="input input-date mt-2 w-50 fondo-gris"
          monthYearSelectable
          value={birthdate}
          active={activeCalendar}
          setActive={setActiveCalendar}
          idCalendar="calendar"
          onChange={handleChangeBirthdate}
          setError={setErrorData}
        />
        <div className="mobile">
          <BsFillCalendarFill
            id="calendarIcon"
            className=""
            onClick={() => setActiveCalendar(true)}
          />
        </div>
        {errorData && (
          <span className="small text-danger error">{errorData}</span>
        )}
      </div>
      <div className="row d-flex justify-content-end">
        <div className="col-1 d-flex justify-content-end">
          <PreviousButton
            onClick={() => handleClickPrevious(step - 1)}
            target_step={step - 1}
          />
        </div>
        <div className="col-auto d-flex justify-content-start">
          <NextButton onClick={handleNextStep} />
        </div>
      </div>
    </Container>
  );
};

export default InputBirthdate;
