import { useEffect, useMemo, useState } from "react";

// Icons
import { BiBrain } from "react-icons/bi";
import { RiBuildingLine, RiMoneyDollarCircleLine, RiToolsFill } from "react-icons/ri";
import { RxChevronDown, RxCross1, RxEnterFullScreen } from "react-icons/rx";
import NotoBrain from "~/assets/icons/noto-brain.svg";


// Contexxt
import { useAuthContext } from "~/hooks/contexts/Auth";

// Services
import api from "~/services/api";
import { AIGPT } from "~/services/openAI";

// Types
import { IChatCompletions, IPrompt, ISavedPrompt } from "~/services/openAI/types";
import { IModalAIProps, TPromptResponse, TPromptResponseKeys } from "./types";

// Styles
import "./styles/index.css";

export function ModalAI({projectData,rep_id}: IModalAIProps){
    const { user } = useAuthContext()
    const [mountedAIResponse, setMountedAIResponse] = useState<TPromptResponse>({})
    const [hasAIResponse, setHasAIResponse] = useState(false)
    const [onMountAIResponse, setOnMountAIResponse] = useState(false)
    const [onVisibility, setOnVisibility] = useState(false)
  
    // AUX Variables  
    const USER_PROMPT = (user?.name || `${new Date().getTime()}`).split(' ').join('-').toLocaleLowerCase()
    const SELECT_PROMPTS: {[index in TPromptResponseKeys]: IPrompt[]} = {
        'terrain-option':[{
            content: `Faça uma simulação ou utilize fontes reais, e retorne uma descrição contendo listas e pontos de interesse sobre os terrenos disponíveis da região do seguinte endereço: ${projectData?.localization}. Caso não tenha os dados ou não possa consultar fontes externas, retorne somente informações genéricas sobre compra de terrenos ou com uma descrição fictícia. Não precisa informar que você não tem acesso as informações específicas sobre terrenos disponíveis em determinada região sem consultar fontes externas`,
            name: USER_PROMPT,
            role: "user"
        }],
        'around-option':[{
            content: `Crie um descrição ficticia da regiao ou utilize fontes reais, e retorne uma descrição das construções e comércio ao redor da região do seguinte endereço: ${projectData?.localization}. Caso não tenha os dados ou não possa consultar fontes externas, retorne somente informações genéricas sobre compra de terrenos ou com uma descrição fictícia. Não precisa informar que você não tem acesso as informações específicas sobre terrenos disponíveis em determinada região sem consultar fontes externas`,
            name: USER_PROMPT,
            role: "user"
        }],
        'build-option':[{
            content: `Faça uma estimativa com base nos valores das perguntas anteriores ou caso seja possivel utilize fontes reais, e retorne a descrição de uma casa ideal para o melhor terreno da região do seguinte endereço: ${projectData?.localization}`,
            name: USER_PROMPT,
            role: "user"
        }],
        'houses-amount-option':[{
            content: `Faça um comparativo com base nos valores das perguntas anteriores ou caso seja possivel utilize fontes reais, e retorne uma lista em ordem crescente, com base nos dados dos seguintes links: ${projectData?.houses?.filter(h => h.ad_link).reduce((d,b) => {d.push(b.ad_link as never); return d;},[]).join(', ')}.`,
            name: USER_PROMPT,
            role: "user"
        }]
    };
    const PROMPTS: IPrompt[] = [
        {
        content: `Faça uma simulação ou utilize fontes reais, e retorne os terrenos disponíveis da região do seguinte endereço: ${projectData?.localization}. Retorne apenas a renda media dentro de uma tag nomeada rm, por exemplo <rm>R$ 1.000,00</rm>, caso não tenha as informações, responda com valor <rm>R$ 00,00</rm>`,
        name: USER_PROMPT,
        role: "user"
        },
        {
        content: `Faça uma simulação ou utilize fontes reais, e retorne a faixa etária media da região do seguinte endereço: ${projectData?.localization}. Retorne apenas a renda media dentro de uma tag nomeada fe, por exemplo <fe>36</fe>, caso não tenha as informações, responda com valor <fe>00</fe>`,
        name: USER_PROMPT,
        role: "user"
        },
        {
        content: `Faça uma estimativa com base nos valores das perguntas anteriores ou caso seja possivel utilize fontes reais, e retorne o valor de venda (VGV) dos imóveis da região do seguinte endereço: ${projectData?.localization}. Retorne apenas o valor medio de venda dentro de uma tag nomeada vgv, por exemplo <vgv>R$ 36.000,00</vgv>, caso não tenha as informações, responda com o valor <vgv>R$ 00,00</vgv>! E não deixe de retornar apenas a tag dessa pergunta, mas também as tags das perguntas anteriores`,
        name: USER_PROMPT,
        role: "user"
        },
        {
        content: `Faça uma estimativa com base nos valores das perguntas anteriores ou caso seja possivel utilize fontes reais, e retorne o valor de venda (VGV) dos imóveis da região do seguinte endereço: ${projectData?.localization}. Retorne apenas o valor medio de venda dentro de uma tag nomeada vgv, por exemplo <vgv>R$ 36.000,00</vgv>, caso não tenha as informações, responda com o valor <vgv>R$ 00,00</vgv>! E não deixe de retornar apenas a tag dessa pergunta, mas também as tags das perguntas anteriores`,
        name: USER_PROMPT,
        role: "user"
        }
    ];
    const OPEN_AI_GPT = useMemo(() => new AIGPT(user,null, PROMPTS, 4,'gpt-3.5-turbo'),[user,PROMPTS])

    async function onChatStream(messages: IPrompt[] = [], targetPrompt: TPromptResponseKeys = 'terrain-option'){
        try {
            setOnMountAIResponse(true)

            if(messages.length === 0){
                throw new Error ('you must provide');
            }
            
            OPEN_AI_GPT.set('localyType',targetPrompt)            

            const completions = await OPEN_AI_GPT.onChatStream(
                messages,
                null,
                setHasAIResponse,
                (arg:any) => getChatStreamData(targetPrompt, arg),
                false
            )

            OPEN_AI_GPT.set('prompts',SELECT_PROMPTS[targetPrompt])

            setOnMountAIResponse(false)
                
            const USER_DATA_STORAGED = OPEN_AI_GPT.getChatCompletionsStoraged()?.[user?.unique_id || '']
            const P_TARGET = 0
            
            if(USER_DATA_STORAGED) {
                // FILTER BY CONTEXT TYPE AND FORCE ONLY ARRAY OF LENGHT 1
                USER_DATA_STORAGED['prompts'] = [
                    (USER_DATA_STORAGED?.prompts.filter(p => p.origin.toString() === targetPrompt))?.[0] || []
                ]

                // It may be that the local store time is different from the runtime for these functions
                // That is why it is necessary to perform a forced update on the array of choices
                USER_DATA_STORAGED['choices'] = [...completions.choices,...USER_DATA_STORAGED['choices']]
            }

            // console.table({d: USER_DATA_STORAGED?.['prompts']})

            await OPEN_AI_GPT.setChatStreamDataToStorage(completions)
            await OPEN_AI_GPT.setOnExternalChatData(USER_DATA_STORAGED,P_TARGET ,rep_id)
        } catch (error) {
            setOnMountAIResponse(false)
            console.table(error);
        }
    }

    async function handleOnChatStrem(messages: IPrompt[] = [], targetPrompt: TPromptResponseKeys = 'terrain-option'){
        try {
            if(!targetPrompt) throw new Error('');

            if(!messages || messages.length <= 0) throw new Error('');

            if(mountedAIResponse[targetPrompt]) throw new Error('This prompt already an response');

            await onChatStream(messages, targetPrompt);
        } catch (error) {
            // do anything
        }
    }

    /**
     * Get and set localy all data output from completions
     * @param completions The completions
     * @returns 
     */
    function getChatStreamData(targetPrompt:TPromptResponseKeys = 'terrain-option' , completions: IChatCompletions | null) {
        try {
            if(!completions) throw new Error('The completions is not valid');

            setMountedAIResponse(d => {
                const old = {...d}

                return {
                    ...old,
                    [targetPrompt]: completions.choices[0].message.content
                }
            })
        } catch (error) {
            console.table(error)
        }
    }

    /**
     * Get all already completion from this "page", otherwise will be called the AIGPT Chat Stream
     * @returns 
     */
    async function getChatDataFromDatabase(){
        try {
            const response = await api.get<ISavedPrompt[]>(`builders/real-estate-products/${rep_id}/prompts`)

            if(response.status !== 200) throw new Error('');

            const RESPONSE_AI_RAW_DATA: TPromptResponse =  {
                "around-option": null,
                "build-option":  null,
                "houses-amount-option": null,
                "terrain-option": null
            }
            const RESPONSE_AI_MOUNT_DATA: TPromptResponse =  {}

            Object.keys(RESPONSE_AI_RAW_DATA).forEach(key => {
                const RESPONSE_AI_OLDEST = response.data.find(d => d.type_id === key);

                RESPONSE_AI_MOUNT_DATA[key as TPromptResponseKeys] = RESPONSE_AI_OLDEST?.response
            })
            
            if(Object.values(RESPONSE_AI_MOUNT_DATA).length <= 0) throw new Error('')

            setMountedAIResponse(RESPONSE_AI_MOUNT_DATA)
        } catch (error) {
            console.table(error)
        }
    }

    useEffect(() => {
        if(!rep_id) return

        getChatDataFromDatabase()
    },[rep_id])

    return (
        <div>
            <button type="button" onClick={() => setOnVisibility(!onVisibility)}>
                <img src={NotoBrain} alt="" />
            </button>
            {
                onVisibility && (
                    <div>
                        <div className="overlay-motion  fixed w-screen h-screen bg-black/10 backdrop:blur-2xl inset-0 z-[999]">
                            <div
                                className="
                                    content-motion absolute 
                                    w-full min-h-[50%] h-auto
                                    left-1/2
                                    top-1/2
                                    -translate-x-1/2 
                                    -translate-y-1/2
                                    md:w-[819px] md:h-[427.8px] 
                                    rounded-[15px] 
                                    p-5
                                    md:p-10 
                                    flex flex-col 
                                    max-sm:gap-2
                                    md:gap-10 
                                    bg-white
                                    shadow-lg
                                    z-[999]
                                "
                            >
                                <div className="w-full h-auto flex flex-row items-center justify-between">
                                    <div className="flex flex-col gap-2">
                                        <span className="cursor-default select-none text-sm md:text-[22px] leading-[26.63px] text-[#414142] font-semibold">Veja o diagnóstico das áreas</span>
                                        <span className="cursor-normal select-none text-xs md:text-[16px] md:leading-[20px] text-[#707070] font-normal">
                                            De acordo com sua localização o Harvey IA vai te trazer alguns dados da região.
                                        </span>
                                    </div>
                                    <span className="cursor-pointer text-black" onClick={() => setOnVisibility(false)}>
                                        <RxCross1 size={24} className="max-sm:w-[12px] max-sm:h-[12px] text-black" />
                                    </span>
                                </div>

                                <div className="w-full h-auto flex flex-col">
                                    <ul className="p-0 m-0 flex flex-col gap-4">
                                        <input type="radio" name="prompt-option-group" id="terrain-option" defaultChecked={false} className="hidden peer/PromptTerrainOption" />
                                        <input type="radio" name="prompt-option-group" id="around-option" defaultChecked={false} className="hidden peer/PromptAroundOption" />
                                        <input type="radio" name="prompt-option-group" id="build-option" defaultChecked={false} className="hidden peer/PromptBuildOption" />
                                        <input type="radio" name="prompt-option-group" id="houses-amount-option" defaultChecked={false} className="hidden peer/PromptAmountOption" />

                                        <li
                                            data-onmountresponse={onMountAIResponse}
                                            data-isheigth={ (mountedAIResponse['terrain-option']?.length || 0) >= 500 && true}
                                            className="list-none 
                                                w-full h-[65px] 
                                                flex flex-col 
                                                items-center
                                                transitions-motion 
                                                overflow-hidden
                                                rounded-[10px]
                                                px-[10px]
                                                md:px-[20px]
                                                data-[isheigth=false]:peer-checked/PromptTerrainOption:h-[200px]
                                                data-[isheigth=true]:peer-checked/PromptTerrainOption:h-[339px]
                                                peer-checked/PromptTerrainOption:[&_.arrow-option-motion]:rotate-180
                                                peer-checked/PromptTerrainOption:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:hover:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:active:bg-[#F1F1F1]
                                            "
                                        >
                                            <label
                                                htmlFor="terrain-option"
                                                data-onmountresponse={onMountAIResponse}
                                                className="
                                                    w-full
                                                    cursor-default
                                                    flex 
                                                    flex-row 
                                                    flex-nowrap 
                                                    items-center 
                                                    justify-between
                                                    transitions-motion
                                                    hover:border-none
                                                    data-[onmountresponse=true]:cursor-default
                                                    data-[onmountresponse=true]:pointer-events-nones
                                                "
                                            >
                                                <span
                                                    className="flex-1 flex flex-row flex-nowrap items-center justify-between">
                                                    <span
                                                        data-hascursorpointer={onMountAIResponse && true}
                                                        onClick={() => handleOnChatStrem(SELECT_PROMPTS['terrain-option'])}
                                                        className="flex-1 max-w-[90%] 
                                                            data-[hascursorpointer=false]:cursor-pointer 
                                                            py-[20px] 
                                                            flex flex-row 
                                                            flex-nowrap items-center 
                                                            justify-start gap-4
                                                        "
                                                    >
                                                        <RxEnterFullScreen size={24} className="text-[#39A887]"/>
                                                        <span className="font-semibold text-md md:text-[18px] text-[#414142]">Terrenos</span>
                                                    </span>
                                                    <span
                                                        title="Atualizar resposta"
                                                        data-shouldviewer={(mountedAIResponse['terrain-option'] && !onMountAIResponse) && true}
                                                        onClick={() => onChatStream(SELECT_PROMPTS['terrain-option'])}
                                                        className="w-1/4
                                                            hidden cursor-pointer 
                                                            data-[shouldviewer=true]:flex 
                                                            items-center justify-center
                                                            group/BrainHover
                                                        "
                                                    >
                                                        <BiBrain 
                                                            size={24} 
                                                            className="
                                                                transitions-motion
                                                                text-[#414142]
                                                                scale-100
                                                                group-hover/BrainHover:scale-125
                                                                group-hover/BrainHover:text-[#39A887]
                                                                max-sm:w-4 max-sm:h-4
                                                            "
                                                        />
                                                    </span>
                                                </span>
                                                <span className="transitions-motion arrow-option-motion cursor-default pointer-events-none">
                                                    <RxChevronDown size={24}  className="text-[#414142]"/> 
                                                </span>
                                            </label>
                                            <span className="block w-full h-[1px] bg-[#D6D6D6] self-center"></span>
                                            <div
                                                data-isheigth={ (mountedAIResponse['terrain-option']?.length || 0) >= 1000 && true}
                                                className="w-full 
                                                    py-[20px] 
                                                    flex flex-col items-center
                                                    data-[isheigth=true]:overflow-y-auto
                                                "
                                                style={{
                                                    height: 'calc(100% - 91px)'
                                                }}
                                            >
                                                <span
                                                    title="Pensando..."
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        on-mount-option-content 
                                                        on-mount-motion
                                                        w-12 h-full
                                                        hidden
                                                        data-[onmountresponse=true]:flex
                                                        items-center justify-center
                                                    "
                                                >
                                                    <img src={NotoBrain} alt=""/>
                                                </span>
                                                <span
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        w-full
                                                        h-auto
                                                        data-[onmountresponse=true]:hidden
                                                        text-[#707070] text-[15px] 
                                                        font-normal leading-[18.15px]
                                                    "
                                                >
                                                    <span
                                                        data-hascontent={mountedAIResponse['terrain-option'] && true}
                                                        className="flex data-[hascontent=true]:hidden"
                                                    >
                                                        Nenhuma informação disponível
                                                    </span>

                                                    <span
                                                        data-hascontent={mountedAIResponse['terrain-option'] && true}
                                                        className="h-full hidden data-[hascontent=true]:flex flex-col gap-2"
                                                    >
                                                        {mountedAIResponse['terrain-option']?.split("\n").map((p, index) => (
                                                            <p key={index}>{p}</p>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                        </li>

                                        <li
                                            data-onmountresponse={onMountAIResponse}
                                            data-isheigth={ (mountedAIResponse['around-option']?.length || 0) >= 500 && true}
                                            className="list-none 
                                                w-full h-[65px] 
                                                flex flex-col 
                                                items-center
                                                transitions-motion 
                                                overflow-hidden
                                                rounded-[10px]
                                                px-[10px]
                                                md:px-[20px]
                                                data-[isheigth=false]:peer-checked/PromptAroundOption:h-[200px]
                                                data-[isheigth=true]:peer-checked/PromptAroundOption:h-[339px]
                                                peer-checked/PromptAroundOption:[&_.arrow-option-motion]:rotate-180
                                                peer-checked/PromptAroundOption:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:hover:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:active:bg-[#F1F1F1]
                                            "
                                        >
                                            <label
                                                htmlFor="around-option"
                                                data-onmountresponse={onMountAIResponse}
                                                className="
                                                    w-full
                                                    cursor-default
                                                    flex 
                                                    flex-row 
                                                    flex-nowrap 
                                                    items-center 
                                                    justify-between
                                                    transitions-motion
                                                    hover:border-none
                                                    data-[onmountresponse=true]:cursor-default
                                                    data-[onmountresponse=true]:pointer-events-nones
                                                "
                                            >
                                                <span
                                                    className="flex-1 flex flex-row flex-nowrap items-center justify-between"
                                                >
                                                    <span
                                                        data-hascursorpointer={onMountAIResponse && true}
                                                        onClick={() => handleOnChatStrem(SELECT_PROMPTS['around-option'],'around-option')}
                                                        className="flex-1 
                                                            max-w-[90%] 
                                                            data-[hascursorpointer=false]:cursor-pointer 
                                                            py-[20px] 
                                                            flex flex-row 
                                                            flex-nowrap items-center 
                                                            justify-start gap-4
                                                        "
                                                    >
                                                        <RiBuildingLine size={24} className="text-[#39A887]"/>
                                                        <span className="font-semibold text-md md:text-[18px] text-[#414142]">Redondezas</span>
                                                    </span>
                                                    <span
                                                        title="Atualizar resposta"
                                                        data-shouldviewer={(mountedAIResponse['around-option'] && !onMountAIResponse) && true}
                                                        onClick={() => onChatStream(SELECT_PROMPTS['around-option'],'around-option')}
                                                        className="w-1/4
                                                            hidden cursor-pointer 
                                                            data-[shouldviewer=true]:flex 
                                                            items-center justify-center
                                                            group/BrainHover
                                                        "
                                                    >
                                                        <BiBrain 
                                                            size={24} 
                                                            className="
                                                                transitions-motion
                                                                text-[#414142]
                                                                scale-100
                                                                group-hover/BrainHover:scale-125
                                                                group-hover/BrainHover:text-[#39A887]
                                                                max-sm:w-4 max-sm:h-4
                                                            "
                                                        />
                                                    </span>
                                                </span>
                                                <span className="transitions-motion arrow-option-motion cursor-default pointer-events-none">
                                                    <RxChevronDown size={24}  className="text-[#414142]"/> 
                                                </span>
                                            </label>
                                            <span className="block w-full h-[1px] bg-[#D6D6D6] self-center"></span>
                                            <div
                                                data-isheigth={ (mountedAIResponse['around-option']?.length || 0) >= 1150 && true}
                                                className="w-full 
                                                    py-[20px] flex flex-col items-center
                                                    data-[isheigth=true]:overflow-y-auto
                                                "
                                                style={{
                                                    height: 'calc(100% - 91px)'
                                                }}
                                            >
                                                <span
                                                    title="Pensando..."
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        on-mount-option-content 
                                                        on-mount-motion
                                                        w-12 h-full
                                                        hidden
                                                        data-[onmountresponse=true]:flex
                                                        items-center justify-center
                                                    "
                                                >
                                                    <img src={NotoBrain} alt=""/>
                                                </span>
                                                <span 
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="w-full h-full
                                                        data-[onmountresponse=true]:hidden
                                                        text-[#707070] text-[15px] 
                                                        font-normal leading-[18.15px]
                                                    "
                                                >
                                                    <span
                                                        data-hascontent={mountedAIResponse['around-option'] && true}
                                                        className="flex data-[hascontent=true]:hidden"
                                                    >
                                                        Nenhuma informação disponível
                                                    </span>

                                                    <span
                                                        data-hascontent={mountedAIResponse['around-option'] && true}
                                                        className="hidden data-[hascontent=true]:flex flex-col gap-2"
                                                    >
                                                        {mountedAIResponse['around-option']?.split("\n").map((p, index) => (
                                                            <p key={index}>{p}</p>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                        </li>

                                        <li
                                            data-onmountresponse={onMountAIResponse}
                                            data-isheigth={ (mountedAIResponse['build-option']?.length || 0) >= 500 && true}
                                            className="list-none 
                                                w-full h-[65px] 
                                                flex flex-col                                        
                                                items-center
                                                transitions-motion 
                                                overflow-hidden
                                                rounded-[10px]
                                                px-[10px]
                                                md:px-[20px]
                                                data-[isheigth=false]:peer-checked/PromptBuildOption:h-[200px]
                                                data-[isheigth=true]:peer-checked/PromptBuildOption:h-[339px]
                                                peer-checked/PromptBuildOption:[&_.arrow-option-motion]:rotate-180
                                                peer-checked/PromptBuildOption:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:hover:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:active:bg-[#F1F1F1]
                                            "
                                        >
                                            <label
                                                htmlFor="build-option"
                                                data-onmountresponse={onMountAIResponse}
                                                className="
                                                    w-full
                                                    cursor-default
                                                    flex 
                                                    flex-row 
                                                    flex-nowrap 
                                                    items-center 
                                                    justify-between
                                                    transitions-motion
                                                    hover:border-none
                                                    data-[onmountresponse=true]:cursor-default
                                                    data-[onmountresponse=true]:pointer-events-nones
                                                "
                                            >
                                                <span
                                                    className="flex-1 flex flex-row flex-nowrap items-center justify-between"
                                                >
                                                    <span
                                                        data-hascursorpointer={onMountAIResponse && true}
                                                        onClick={() => handleOnChatStrem(SELECT_PROMPTS['build-option'],'build-option')}
                                                        className="flex-1 max-w-[90%] 
                                                            data-[hascursorpointer=false]:cursor-pointer 
                                                            py-[20px] 
                                                            flex flex-row 
                                                            flex-nowrap items-center 
                                                            justify-start gap-4
                                                        "
                                                    >
                                                        <RiToolsFill size={24} className="text-[#39A887]"/>
                                                        <span className="font-semibold text-md md:text-[18px] text-[#414142]">O que construir</span>
                                                    </span>
                                                    <span
                                                        title="Atualizar resposta"
                                                        data-shouldviewer={(mountedAIResponse['build-option'] && !onMountAIResponse) && true}
                                                        onClick={() => onChatStream(SELECT_PROMPTS['build-option'], 'build-option')}
                                                        className="w-1/4
                                                            hidden cursor-pointer 
                                                            data-[shouldviewer=true]:flex 
                                                            items-center justify-center
                                                            group/BrainHover
                                                        "
                                                    >
                                                        <BiBrain 
                                                            size={24} 
                                                            className="
                                                                transitions-motion
                                                                text-[#414142]
                                                                scale-100
                                                                group-hover/BrainHover:scale-125
                                                                group-hover/BrainHover:text-[#39A887]
                                                                max-sm:w-4 max-sm:h-4
                                                            "
                                                        />
                                                    </span>
                                                </span>
                                                <span className="transitions-motion arrow-option-motion cursor-default pointer-events-none">
                                                    <RxChevronDown size={24}  className="text-[#414142]"/> 
                                                </span>
                                            </label>
                                            <span className="block w-full h-[1px] bg-[#D6D6D6] self-center"></span>
                                            <div
                                                data-isheigth={ (mountedAIResponse['build-option']?.length || 0) >= 1000 && true}
                                                className="w-full
                                                    py-[20px]
                                                    flex flex-col items-center
                                                    data-[isheigth=true]:overflow-y-auto
                                                "
                                                style={{
                                                    height: 'calc(100% - 91px)'
                                                }}
                                            >
                                                <span
                                                    title="Pensando..."
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        on-mount-option-content 
                                                        on-mount-motion
                                                        w-12 h-full
                                                        hidden
                                                        data-[onmountresponse=true]:flex
                                                        items-center justify-center
                                                    "
                                                >
                                                    <img src={NotoBrain} alt=""/>
                                                </span>
                                                <span 
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        w-full h-full
                                                        data-[onmountresponse=true]:hidden
                                                        text-[#707070] text-[15px] 
                                                        font-normal leading-[18.15px]
                                                    "
                                                >
                                                    <span
                                                        data-hascontent={mountedAIResponse['build-option'] && true}
                                                        className="flex data-[hascontent=true]:hidden"
                                                    >
                                                        Nenhuma informação disponível
                                                    </span>

                                                    <span
                                                        data-hascontent={mountedAIResponse['build-option'] && true}
                                                        className="w-full h-full hidden data-[hascontent=true]:flex flex-col gap-2"
                                                    >
                                                        {mountedAIResponse['build-option']?.split("\n").map((p, index) => (
                                                            <p key={index}>{p}</p>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                        </li>

                                        <li
                                            data-onmountresponse={onMountAIResponse}
                                            data-isheigth={ (mountedAIResponse['houses-amount-option']?.length || 0) >= 500 && true}
                                            className="list-none 
                                                w-full h-[65px] 
                                                flex flex-col 
                                                items-center
                                                transitions-motion 
                                                overflow-hidden
                                                rounded-[10px]
                                                px-[10px]
                                                md:px-[20px]
                                                data-[isheigth=false]:peer-checked/PromptAmountOption:h-[200px]
                                                data-[isheigth=true]:peer-checked/PromptAmountOption:h-[339px]
                                                peer-checked/PromptAmountOption:[&_.arrow-option-motion]:rotate-180
                                                peer-checked/PromptAmountOption:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:hover:bg-[#F1F1F1]
                                                data-[onmountresponse=false]:active:bg-[#F1F1F1]
                                            "
                                        >
                                            <label
                                                htmlFor="houses-amount-option"
                                                data-onmountresponse={onMountAIResponse}
                                                className="
                                                    w-full
                                                    cursor-pointer
                                                    flex 
                                                    flex-row 
                                                    flex-nowrap 
                                                    items-center 
                                                    justify-between
                                                    transitions-motion
                                                    hover:border-none
                                                    data-[onmountresponse=true]:cursor-default
                                                    data-[onmountresponse=true]:pointer-events-nones
                                                "
                                            >
                                                <span
                                                    className="flex-1 flex flex-row flex-nowrap items-center justify-between"
                                                >
                                                    <span
                                                        data-hascursorpointer={onMountAIResponse && true}
                                                        onClick={() => handleOnChatStrem(SELECT_PROMPTS['houses-amount-option'],'houses-amount-option')}
                                                        className="flex-1 max-w-[90%] 
                                                            data-[hascursorpointer=false]:cursor-pointer
                                                            py-[20px] 
                                                            flex flex-row 
                                                            flex-nowrap items-center 
                                                            justify-start gap-4
                                                        "
                                                    >
                                                        <RiMoneyDollarCircleLine size={24} className="text-[#39A887]"/>
                                                        <span className="font-semibold text-md md:text-[18px] text-[#414142]">Valor médio das casas</span>
                                                    </span>
                                                    <span
                                                        title="Atualizar resposta"
                                                        data-shouldviewer={(mountedAIResponse['houses-amount-option'] && !onMountAIResponse) && true}
                                                        onClick={() => onChatStream(SELECT_PROMPTS['houses-amount-option'],'houses-amount-option')}
                                                        className="w-1/4
                                                            hidden cursor-pointer 
                                                            data-[shouldviewer=true]:flex 
                                                            items-center justify-center
                                                            group/BrainHover
                                                        "
                                                    >
                                                        <BiBrain 
                                                            size={24} 
                                                            className="
                                                                transitions-motion
                                                                text-[#414142]
                                                                scale-100
                                                                group-hover/BrainHover:scale-125
                                                                group-hover/BrainHover:text-[#39A887]
                                                                max-sm:w-4 max-sm:h-4
                                                            "
                                                        />
                                                    </span>
                                                </span>
                                                <span className="transitions-motion arrow-option-motion cursor-default pointer-events-none">
                                                    <RxChevronDown size={24}  className="text-[#414142]"/> 
                                                </span>
                                            </label>
                                            <span className="block w-full h-[1px] bg-[#D6D6D6] self-center"></span>
                                            <div
                                                data-isheigth={ (mountedAIResponse['houses-amount-option']?.length || 0) >= 1000 && true}
                                                className="w-full
                                                    py-[20px] 
                                                    flex flex-col items-center
                                                    data-[isheigth=true]:overflow-y-auto
                                                "
                                                style={{
                                                    height: 'calc(100% - 91px)'
                                                }}
                                            >
                                                <span
                                                    title="Pensando..."
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        on-mount-option-content 
                                                        on-mount-motion
                                                        w-12 h-full
                                                        hidden
                                                        data-[onmountresponse=true]:flex
                                                        items-center justify-center
                                                    "
                                                >
                                                    <img src={NotoBrain} alt=""/>
                                                </span>
                                                <span
                                                    data-onmountresponse={onMountAIResponse}
                                                    className="
                                                        w-full
                                                        data-[onmountresponse=true]:hidden
                                                        text-[#707070] text-[15px] 
                                                        font-normal leading-[18.15px]
                                                    "
                                                >
                                                    <span
                                                        data-hascontent={mountedAIResponse['houses-amount-option'] && true}
                                                        className="flex data-[hascontent=true]:hidden"
                                                    >
                                                        Nenhuma informação disponível
                                                    </span>

                                                    <span
                                                        data-hascontent={mountedAIResponse['houses-amount-option'] && true}
                                                        className="hidden data-[hascontent=true]:flex flex-col gap-2"
                                                    >
                                                        {mountedAIResponse['houses-amount-option']?.split("\n").map((p, index) => (
                                                            <p key={index}>{p}</p>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}