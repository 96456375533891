import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Services
import api from '~/services/api';

// Hooks
import { useProfile } from '~/hooks/Profile';

// Assets
import firstPlace from '~/assets/icons/first-place.svg';
import othersPlace from '~/assets/icons/others-place.svg';
import secondPlace from '~/assets/icons/second-place.svg';
import thirdPlace from '~/assets/icons/third-place.svg';

// Models
import { IMClassification } from '~/models/Classification';
import { IMTournament } from '~/models/Tournament';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Components
import UserDefault from '~/components/UserDefault';
import Awards from './Components/Awards';
import Marketing from './Components/Banner-Marketing';
import Boxes from './Components/Boxes';
import ButtonsHome from './Components/Buttons';
import ButtonsSearch from './Components/ButtonsSearch';

// Partials
import InputSearch from '../ServiceOrder/Partials/InputSearch';
import TableOS from '../ServiceOrder/Partials/Table';

// Styles
import Tasks from './Components/Tasks';
import { Container, GeneralContainer } from './styles';

const Home: React.FC = () => {
  const { user } = useAuthContext();
  const { validateEngineerData } = useProfile();
  const history = useHistory();
  const [responsible, setResponsable] = useState<number | null>(null);
  const [tournament, setTournament] = useState<IMTournament | null>(null);
  const [classifications, setClassifications] = useState<IMClassification[]>(
    []
  );
  const [hasUserClassified, setHasUserClassified] = useState<IMClassification | null>(null);

  // AUX Variables
  const ICONS_PLACES = [firstPlace, secondPlace, thirdPlace];

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleButtonChange = useCallback((value: number | null) => {
    setResponsable(value);
  }, []);

  const handleMountClassification = useCallback(async () => {
    if (!tournament) return;

    const responseClassifications = await api.get<IMClassification[]>(
      `builders/tournaments/${tournament.id}/ranking?limit=3`
    );

    if (!responseClassifications) return;

    const classificationsList = responseClassifications.data?.map((classification: IMClassification) => ({
      ...classification,
      point: Number(classification.point).toLocaleString('pt-BR', {
        maximumFractionDigits: 20,
        minimumFractionDigits: 0,
      }),
      image: ICONS_PLACES[classification.position - 1] ?? othersPlace,
    })) ?? [];

    // THE CODE BELOW INFER ACTUAL POSITION OF USER
    classificationsList.every((classification) => {
      if (
        classification?.user_id === user?.id
        && classification.position !== 3
      ) {
        setHasUserClassified(classification);
        return false;
      }

      return true;
    });

    setClassifications(classificationsList);

    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament]);

  useEffect(() => {
    api.get<IMTournament>('builders/tournaments/').then(async (response) => {
      if (!response.data?.id) return;

      setTournament(response.data);
    });
  }, []);

  useEffect(() => {
    handleMountClassification();
  }, [tournament]);

  return (
    <GeneralContainer className="ms-lg-5">
      <div className="py-4" />
      <div className="mt-5 mt-lg-0">
        <div className="container mt-5 mt-lg-0">
          <UserDefault home />
        </div>
        <Container className="w-100 px-lg-3 mt-5">
          <div className="container">
            <div className="row mt-5 mb-5">
              <div className="col-lg-8 px-lg-3">
                <Marketing />
              </div>
              <div className="col-lg-4 px-lg-0">
                {!tournament ? (
                  <div className="flex w-full h-[15rem] bg-gray-200 animate-pulse rounded-xl mb-4" />
                ) : (
                  <Awards
                    classifications={classifications}
                    userClassified={hasUserClassified}
                    tournament={tournament?.name || ''}
                  />
                )}

                <Boxes />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="flex-1">
                <InputSearch className="w-100" />
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0">
                <ButtonsHome />
              </div>
            </div>
            <div className="row my-5 justify-content-between">
              <div className="col-lg-8 tableContent pe-4">
                <ButtonsSearch onChange={handleButtonChange} />
                <TableOS responsible={responsible} />
              </div>
              <div className="col-lg-4">
                <Tasks />
                </div>
            </div>
          </div>
        </Container>
      </div>
    </GeneralContainer>
  );
};

export default Home;
