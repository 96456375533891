import { Column, ColumnProps } from 'primereact/column';
import {
  DataTable as DataTabePrime,
  DataTableRowClickEvent,
  DataTableStateEvent,
} from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import {
  PaginatorCurrentPageReportOptions,
  PaginatorFirstPageLinkOptions,
  PaginatorJumpToPageInputOptions,
  PaginatorLastPageLinkOptions,
  PaginatorNextPageLinkOptions,
  PaginatorPageLinksOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
  PaginatorTemplate,
} from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import React, { useCallback, useState } from 'react';

import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { useDatatable } from '~/hooks/Datatable';

export interface ITableData {
  toData: number;
  totalData: number;
  perPage: number;
  currentPage: number;
}

interface ITableProps {
  // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: ColumnProps[];
  loading: boolean;
  onRowClick?(data: DataTableRowClickEvent): void;
}

const DataTable: React.FC<ITableProps> = ({
  data,
  columns,
  loading,
  onRowClick,
}) => {
  const {
    per_page,
    totalRecords,
    order,
    setPage,
    setColumn,
    setOrder,
    setPerPage,
  } = useDatatable();
  const [first, setFirst] = useState(1);

  const onPage = useCallback(
    (e: DataTableStateEvent) => {
      setFirst(e.first);
      setPerPage(e.rows);
      setPage(e.page ? e.page + 1 : 1);
    },
    [setPerPage, setPage, setFirst]
  );

  const rowsPerPageDropdown = useCallback(
    (options: PaginatorRowsPerPageDropdownOptions) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 30, value: 30 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 500, value: 500 },
      ];

      return (
        <>
          <span className="sm:ml-10 text-black">Registros por página:</span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </>
      );
    },
    []
  );

  const currentPageReport = useCallback(
    (options: PaginatorCurrentPageReportOptions) => {
      return (
        <div className="flex sm:mr-10 mt-5 sm:mt-0">
          <span className="text-black w-32 text-center">
            {options.first} -{' '}
            {Number(options.rows) * Number(options.currentPage) >
              options.totalRecords
              ? options.totalRecords
              : Number(options.rows) * Number(options.currentPage)}{' '}
            de {options.totalRecords}
          </span>
        </div>
      );
    },
    []
  );

  const firstPageLink = useCallback(
    (options: PaginatorFirstPageLinkOptions) => {
      return (
        <Button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <AiOutlineDoubleLeft />
        </Button>
      );
    },
    []
  );

  const prevPageLink = useCallback((options: PaginatorPrevPageLinkOptions) => {
    return (
      <Button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <IoIosArrowBack />
      </Button>
    );
  }, []);

  const pageLinks = useCallback((options: PaginatorPageLinksOptions) => {
    if (
      (options.view.startPage === options.page &&
        options.view.startPage !== 0) ||
      (options.view.endPage === options.page &&
        options.page + 1 !== options.totalPages)
    ) {
      const className = classNames(options.className, { 'p-disabled': true });

      return (
        <span className={className} style={{ userSelect: 'none' }}>
          ...
        </span>
      );
    }

    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
      >
        {options.page + 1}
        <Ripple />
      </button>
    );
  }, []);

  const nextPageLink = useCallback((options: PaginatorNextPageLinkOptions) => {
    return (
      <Button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <IoIosArrowForward />
        <Ripple />
      </Button>
    );
  }, []);

  const lastPageLink = useCallback((options: PaginatorLastPageLinkOptions) => {
    return (
      <Button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <AiOutlineDoubleRight />
      </Button>
    );
  }, []);

  const jumpToPageInput = useCallback(
    (options: PaginatorJumpToPageInputOptions) => {
      return <>{options}</>;
    },
    []
  );

  const paginatorTemplate: PaginatorTemplate = {
    layout:
      'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: rowsPerPageDropdown,
    CurrentPageReport: currentPageReport,
    FirstPageLink: firstPageLink,
    PrevPageLink: prevPageLink,
    PageLinks: pageLinks,
    NextPageLink: nextPageLink,
    LastPageLink: lastPageLink,
    JumpToPageInput: jumpToPageInput,
  };

  const onSort = useCallback(
    (event: DataTableStateEvent) => {
      setColumn(event.sortField);
      setOrder(order === 'desc' ? 'asc' : 'desc');
    },
    [setColumn, setOrder, order]
  );

  return (
    <div className="flex flex-col text-center mt-5">
      <DataTabePrime
        dataKey="id"
        lazy
        value={data}
        rows={per_page}
        first={first}
        sortMode="single"
        responsiveLayout="scroll"
        breakpoint="960px"
        paginator
        emptyMessage="Nenhum registro encontrado."
        className="rounded-4 w-100"
        paginatorTemplate={paginatorTemplate}
        paginatorClassName="flex flex-col sm:flex-row"
        totalRecords={totalRecords}
        loading={loading}
        scrollable
        scrollHeight="500px"
        rowHover
        defaultSortOrder={order === 'asc' ? 1 : 0}
        sortOrder={order === 'asc' ? 1 : 0}
        onSort={onSort}
        onPage={onPage}
        onRowClick={onRowClick}
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            sortable={column.sortable}
            body={column.body}
            headerStyle={{
              color: 'black',
              backgroundColor: '#8c8c8c9aF',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            className="border-0 cursor-pointer"
          />
        ))}
      </DataTabePrime>
    </div>
  );
};

export default DataTable;
