import { format, parseISO } from 'date-fns';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';

import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdCheck, MdClose, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Table, { IColumn } from '~/components/Table';
import UserDefault from '~/components/UserDefault';

import Loading from '~/components/Loading';
import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';

import { Circle, Container, GeneralContainer } from './styles';

interface IOsResponse {
  id: number;
  key: string;
  created_at: string;
  construtor?: {
    name: string;
  };
  finality: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    vlrRenda: number;
    amortizacao?: {
      id?: number;
      title?: string;
      financedValue: string;
      entryValue?: string;
      realEntryValue?: string;
      fgtsAmount?: string;
      rate?: string;
      firstInstallmentDate?: string;
      firstInstallment: string;
      lastInstallmentDate?: string;
      lastInstallment: string;
      maxInstallment?: string;
      highlight?: boolean;
      deadline?: number;
      color?: string;
    };
  };
  cliente: {
    nome: string;
    dt_nascimento: string;
  };
}

interface IRequestResponse {
  id: number;
  status: {
    id: number;
    status: string;
  };
  created_at: string;
  os: IOsResponse;
  // builder: IBuilderLocal;
  answered?: boolean;
  // client: IClient;
}

interface IRequestData {
  data: IRequestResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IRequest {
  id: number;
  os_id: number;
  client: string;
  // builder: string;
  date: string;
  modality: string;
  status: string;
  answered?: boolean;
}

interface IData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const RequestedBudget: React.FC = () => {
  const history = useHistory();
  const { validateEngineerData } = useProfile();
  const [openRequests, setOpenRequests] = useState<IRequest[]>([]);
  const [answeredRequests, setAnsweredRequests] = useState<IRequest[]>([]);
  const [requestsData, setRequestsData] = useState<IData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const loadOpenRequests = useCallback(async (page: number) => {
    setLoadingRequests(true);
    try {
      const response = await api.get<IRequestData>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/requests/open`,
        {
          params: {
            page,
            take: 3,
          },
        }
      );
      const data: IRequest[] = response.data.data.map((request) => {
        const client = request.os.cliente.nome;
        const namePartsClient = client ? client.split(' ') : ' ';

        return {
          id: request.id,
          os_id: request.os.id,
          os_key: request.os.key,
          client: namePartsClient[2]
            ? `${namePartsClient[0]} ${namePartsClient[1]} ${namePartsClient[2]}`
            : `${namePartsClient[0]} ${namePartsClient[1]}`,
          date: format(parseISO(request.created_at), 'dd/MM/yyyy'),
          modality: request.os.finality ? request.os.finality.finalidade : '-',
          status: request.status.status,
          answered: request.answered,
        };
      });

      setRequestsData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        current_page: response.data.current_page,
      });
      setOpenRequests(data);
    } catch (error) {
      //
    } finally {
      setLoadingRequests(false);
    }
  }, []);

  const loadAnsweredRequests = useCallback(async (page: number) => {
    setLoadingRequests(true);
    try {
      const response = await api.get<IRequestData>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/requests/answered`,
        {
          params: {
            page,
            take: 3,
          },
        }
      );
      const data: IRequest[] = response.data.data.map((request) => {
        const client = request.os.cliente.nome;
        const namePartsClient = client ? client.split(' ') : ' ';

        return {
          id: request.id,
          os_id: request.os.id,
          os_key: request.os.key,
          client: namePartsClient[2]
            ? `${namePartsClient[0]} ${namePartsClient[1]} ${namePartsClient[2]}`
            : `${namePartsClient[0]} ${namePartsClient[1]}`,
          date: format(parseISO(request.created_at), 'dd/MM/yyyy'),
          modality: request.os.finality ? request.os.finality.finalidade : '-',
          status: request.status.status,
          answered: request.answered,
        };
      });

      setRequestsData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        current_page: response.data.current_page,
      });
      setAnsweredRequests(data);
    } catch (error) {
      //
    } finally {
      setLoadingRequests(false);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 1) {
      loadOpenRequests(1);
    } else if (activeTab === 2) {
      loadAnsweredRequests(1);
    }
  }, [activeTab, loadAnsweredRequests, loadOpenRequests]);

  const handleChangePageRequestOpen = useCallback(
    async (page: number) => {
      await loadOpenRequests(page);
    },
    [loadOpenRequests]
  );
  const handleChangePageRequestAnswered = useCallback(
    async (page: number) => {
      await loadAnsweredRequests(page);
    },
    [loadAnsweredRequests]
  );

  const handleChangeTab = useCallback(
    async (tab: number, page: number) => {
      if (tab === 1) {
        setActiveTab(tab);
        loadOpenRequests(page);
      } else if (tab === 2) {
        setActiveTab(tab);
        loadAnsweredRequests(page);
      }
    },
    [loadAnsweredRequests, loadOpenRequests]
  );

  const handleAcceptRequest = useCallback(
    async (id: number, status: number, page: number) => {
      const data = await api.get(
        `${process.env.REACT_APP_PREFIX_ROUTE}/requests/${id}`
      );
      const responseRequest = {
        id: data.data.id,
        os_id: data.data.os_id,
        builder_id: data.data.builder_id,
        status_id: status,
        answered: 1,
      };
      api
        .put(
          `${process.env.REACT_APP_PREFIX_ROUTE}/requests/${id}`,
          responseRequest
        )
        .then((_resolver) => loadOpenRequests(page));
    },
    [loadOpenRequests]
  );

  const handleClickChat = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/chat/clientes`);
  }, [history]);

  const handleOpenDetails = useCallback((os_id: string) => {
    const url = `${process.env.PUBLIC_URL}/detalhes-orcamento/${os_id}`;
    // history.push(`${process.env.PUBLIC_URL}/detalhes-orcamento/${os_id}`);
    return url;
  }, []);

  const columns = useMemo<IColumn[]>(() => [
    {
      name: 'Cliente',
      selector: 'client',
      // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => (
        <div className="d-flex align-items-center mt-5">
          {/* <AvatarTable src={row.avatar} /> */}

          <p className="ms-3 mb-0">{row.client}</p>
        </div>
      ),
    },
    {
      name: 'Modalidade',
      selector: 'modality',
      // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => (
        <p className="ms-3 mb-0 pe-2 mt-5">{row.modality}</p>
      ),
    },
    {
      name: 'Sobre o projeto',
      // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => (
        <Link
          className="d-flex align-items-center mt-5"
          to={`/detalhes-orcamento/${row.os_key || ''}`}
        >
          <p className="ms-3 mb-0 text-primary pe-2">Abrir Detalhes</p>
          <MdOutlineArrowForwardIos size={15} color="#39A887" />
        </Link>
      ),
    },

    {
      name: 'Sua resposta',
      selector: 'status',

      // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (row: any) => (
        <div className="row mt-3">
          {activeTab === 1 && (
            <div className="mt-3">
              <button
                type="button"
                className="border-0"
                onClick={() => handleAcceptRequest(row.id, 5, requestsData.current_page)
                }
              >
                <div className="col-auto ms-2" aria-hidden="true">
                  <Circle accept={1} className="d-flex align-items-center">
                    <MdCheck className="ms-1" size={28} color="#39A887" />
                  </Circle>
                </div>
                <div>Aceitar</div>
              </button>
              <button
                type="button"
                className="border-0 ms-4"
                onClick={() => handleAcceptRequest(row.id, 4, requestsData.current_page)
                }
              >
                <div className="col-auto ms-2">
                  <Circle accept={0} className="d-flex align-items-center">
                    <MdClose className="ms-1 " size={28} color="#ff3363" />
                  </Circle>
                </div>
                <div>Rejeitar</div>
              </button>
            </div>
          )}
          {activeTab === 2 && <div className="row">{row.status}</div>}
        </div>
      ),
    },
  ], [
    activeTab,
    handleAcceptRequest,
    handleOpenDetails,
    requestsData.current_page,
  ]);
  return (
    <GeneralContainer className="ms-lg-5">
      <div className="py-4" />
      <div className="mt-5 mt-lg-0">
        <div className="container mt-5 mt-lg-0">
          <UserDefault solicitacoes />
        </div>
        <Container className="w-100 px-lg-3 mt-5">
          <div className="container">
            <div className="d-flex align-items-center justify-content-start px-4 mb-5 ">
              <button type="button" className="chat" onClick={handleClickChat}>
                Chat
                <div className="rounded-circle color p-2 ms-2">
                  <IoChatbubbleEllipsesSharp
                    size={24}
                    color="#707070"
                    className="chat"
                  />
                </div>
              </button>
            </div>
            <div className="row mb-3 justify-content-between">
              <div className="d-flex col-lg-5 mb-0 align-items-center justify-content-start ps-5">
                <button
                  type="button"
                  className={`border-0 btn-build px-3 me-3 ${activeTab === 1 ? 'active' : ''
                    }`}
                  onClick={() => handleChangeTab(1, 1)}
                >
                  Novos Orçamentos
                </button>
                <button
                  type="button"
                  className={`border-0 btn-build px-3 ${activeTab === 2 ? 'active' : ''
                    }`}
                  onClick={() => handleChangeTab(2, 1)}
                >
                  Solicitações Respondidas
                </button>
              </div>
            </div>
            <div className="row mt-4 justify-content-between">
              <div className="col-lg-12 ">
                <div id="requests">
                  {activeTab === 1 ? (
                    <>
                      <Table
                        data={openRequests}
                        columns={columns}
                        pagination
                        fromData={requestsData.from || 0}
                        toData={requestsData.to || 0}
                        totalData={requestsData.total || 0}
                        selectedPage={requestsData.current_page || 1}
                        className="os-table bg-transparent px-0"
                        onChangePage={handleChangePageRequestOpen}
                      />
                      <Loading active={loadingRequests} />
                    </>
                  ) : (
                    <>
                      <Table
                        data={answeredRequests}
                        columns={columns}
                        pagination
                        fromData={requestsData.from || 0}
                        toData={requestsData.to || 0}
                        totalData={requestsData.total || 0}
                        selectedPage={requestsData.current_page || 1}
                        className="os-table bg-transparent px-0"
                        onChangePage={handleChangePageRequestAnswered}
                      />
                      <Loading active={loadingRequests} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </GeneralContainer>
  );
};

export default RequestedBudget;
