import React, { Ref, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Swal from 'sweetalert2';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';
import { IFormData } from '..';

import {
  Modal, DocumentImage, InputArea, ModalIframe
} from './styles';

interface IFormMeasurement {
  show: boolean;
  dataFileVisualization: string;
  errorData: string;
  errorPercentage: string;
  fileName: string;
  dataFile: File;
  formRef: Ref<FormHandles>;
  onHandleClose(): void;
  onHandleSubmit(data: IFormData): void;
  onHandleChangeDate(value: string): void;
  onHandleChangePercentage(value: string): void;
  onHandleSetFileName(value: string): void;
  onHandleSetDataFile(value: File): void;
  onHandleSetDataFileVisualization(value: string): void;
}

const FormMeasurement: React.FC<IFormMeasurement> = ({
  show,
  dataFileVisualization,
  errorData,
  errorPercentage,
  fileName,
  dataFile,
  formRef,
  onHandleClose,
  onHandleSubmit,
  onHandleChangeDate,
  onHandleChangePercentage,
  onHandleSetFileName,
  onHandleSetDataFile,
  onHandleSetDataFileVisualization,
}) => {
  const dateCurrent = new Date();

  const handlePrevent = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onSelectFile = useCallback(
    (event) => {
      onHandleSetFileName('');

      if (event.target.files[0] !== undefined) {
        if (
          event.target.files[0].type === 'image/jpeg'
          || event.target.files[0].type === 'application/pdf'
        ) {
          onHandleSetDataFile(event.target.files[0]);
          onHandleSetDataFileVisualization(
            URL.createObjectURL(event.target.files[0])
          );

          if (event.target.files[0].type !== 'image/jpeg') {
            onHandleSetFileName(event.target.files[0].name);
            onHandleSetDataFileVisualization(
              URL.createObjectURL(event.target.files[0])
            );
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Carregue um arquivo de formato jpeg ou PDF',
          });
        }
      }
    },
    [onHandleSetDataFile, onHandleSetDataFileVisualization, onHandleSetFileName]
  );

  const onDropFile = useCallback(
    (data) => {
      onHandleSetFileName('');

      if (data !== undefined) {
        if (data.type === 'image/jpeg' || data.type === 'application/pdf') {
          onHandleSetDataFile(data);
          onHandleSetDataFileVisualization(URL.createObjectURL(data));

          if (data.type !== 'image/jpeg') {
            onHandleSetFileName(data.name);
            onHandleSetDataFileVisualization(dataFileVisualization);
            onHandleSetDataFileVisualization(URL.createObjectURL(data));
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Carregue um arquivo de formato jpeg ou PDF',
          });
        }
      }
    },
    [
      dataFileVisualization,
      onHandleSetDataFile,
      onHandleSetDataFileVisualization,
      onHandleSetFileName,
    ]
  );

  const handleDragDrop = useCallback(
    (event) => {
      event.preventDefault();
      onDropFile(event.dataTransfer.files[0]);
    },
    [onDropFile]
  );

  return (
    <Modal show={show} onHide={onHandleClose} close size="lg">
      <Modal.Header className="border-0" closeButton>
        <h4>Nova medição</h4>
      </Modal.Header>
      <Modal.Body className="border-0 mx-5">
        <Form ref={formRef} onSubmit={onHandleSubmit} className="p-4">
          <InputArea className="row">
            <div className="col-lg-6">
              <label>Data de solicitação</label>
              <InputMask
                kind="datetime"
                options={{
                  format: 'DD/MM/YYYY',
                }}
                name="calendar"
                placeholder={`ex: ${
                  dateCurrent.getDate() > 9
                    ? dateCurrent.getDate()
                    : `${0}${dateCurrent.getDate()}`
                } /${
                  dateCurrent.getMonth() > 9
                    ? dateCurrent.getMonth() + 1
                    : `${dateCurrent.getMonth()}${1}`
                }/${dateCurrent.getFullYear()}`}
                className="form-input"
                onChange={(e) => onHandleChangeDate(e.target.value)}
              />
              {errorData && (
                <span className="small text-danger error">{errorData}</span>
              )}
            </div>

            <div className="col-lg-6">
              <label>Porcentagem estimada da obra</label>
              <Input
                className="form-input w-75"
                type="number"
                name="percentage"
                placeholder="ex: 00%"
                onChange={(e) => onHandleChangePercentage(e.target.value)}
              />
              {errorPercentage && (
                <span className="small text-danger error">
                  {errorPercentage}
                </span>
              )}
            </div>
          </InputArea>

          <div
            className="box-document"
            onDragEnter={handlePrevent}
            onDragLeave={handlePrevent}
            onDragOver={handlePrevent}
            onDrop={handleDragDrop}
          >
            <label className="mt-5">
              Subir arquivo: <span>(Opcional)</span>
            </label>

            <DocumentImage>
              {fileName.length > 0 ? (
                <ModalIframe src={dataFileVisualization} title={fileName} />
              ) : (
                <img
                  src={dataFileVisualization}
                  alt={dataFile.name}
                  className="img-props"
                />
              )}

              <div className="box-image-input">
                <span>Solte seu arquivo aqui ou</span>
                <label>
                  <div className="box-select">
                    <span>Procurar arquivo</span>
                  </div>
                  <input
                    type="file"
                    className="image-input"
                    accept="image/jpeg,application/pdf"
                    onChange={onSelectFile}
                  />
                </label>
              </div>
            </DocumentImage>
          </div>
          <div className="buttons-group">
            <button type="submit" className="btn-cancel me-2">
              Solicitar medição
            </button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 box-footer" />
    </Modal>
  );
};

export default FormMeasurement;
