// Styles
import { Container } from '../../styles';

export function Mock(): JSX.Element {
  return (
    <Container className="container me-5 mt-5 card-dashboard p-0 mb-5">
      <div className="ms-4 mt-4 m-lg-4">
        <div className="d-flex w-full align-items-center mb-4 bg-gray-200 animate-pulse h-[4rem]" />
      </div>
      <div className="flex flex-row flex-nowrap gap-3 ms-4 mt-4 m-lg-4">
        <div className="bg-gray-200 animate-pulse w-[10rem] h-[2rem]" />
        <div className="bg-gray-200 animate-pulse w-[5rem] h-[2rem]" />
        <div className="bg-gray-200 animate-pulse w-[2.5rem] h-[2rem]" />
      </div>
      <div className="flex flex-row flex-nowrap gap-3 ms-4 mt-4 m-lg-4">
        <div className="bg-gray-200 animate-pulse w-[10rem] h-[2rem]" />
        <div className="bg-gray-200 animate-pulse w-[5rem] h-[2rem]" />
        <div className="bg-gray-200 animate-pulse w-[2.5rem] h-[2rem]" />
      </div>
    </Container>
  );
}
