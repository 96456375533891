import styled from 'styled-components';

interface ImageItemProps {
  src: string;
}

interface ItotalImagenProps {
  total?: number;
}

export const Container = styled.div<ItotalImagenProps>`
  .image {
    position: relative;
    border-radius: 10px;
  }

  .anchoo {
    min-width: 1600px;
  }

  .ancho {
    min-width: calc(320px * ${(props) => props.total});
  }

  .box-image {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      height: 12px;
    }
  }
`;

export const ImageItem = styled.div<ImageItemProps>`
  background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  height: 200px;
`;
