import React, { useState } from 'react';

import { Container, Tab } from './styles';

import PaymentsPending from './Components/PaymentsPending';
import PaymentsPaid from './Components/PaymentsPaid';
import TabButtons from './Components/TabButtons';

const Payments: React.FC = () => {
  const [step, setStep] = useState(1);

  return (
    <Container>
      <div className="teste">
        <div className="mx-5">
          <div className="row text px-3 mb-2 pt-5 ms-0">Meus pagamentos</div>
          <div className="row text-2 px-3 mb-3 ms-0">
            Para prosseguir com a aquisição do seu Harvey, realize o pagamento
            para prosseguir
          </div>

          <TabButtons step={step} setStep={setStep} />
        </div>

        <Tab active={step === 1}>
          <PaymentsPending />
        </Tab>
        <Tab active={step === 2}>
          <PaymentsPaid />
        </Tab>
      </div>
    </Container>
  );
};

export default Payments;
