import styled from 'styled-components';

export const Container = styled.div`
  .disabled {
    pointer-events: none;
  }
  .text-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Blue - Wodo */

    color: #324351;
  }
  .text-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    font-feature-settings: 'zero' on;

    color: #707070;
  }
  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
`;
