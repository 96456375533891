import styled from 'styled-components';

import banner from '~/assets/banners/background-login.png';

interface IStep {
  active: boolean;
}

export const Container = styled.div`
  .line {
    margin: 16px 8rem;
  }

  .company {
    color: #414142;
  }

  .mandatory {
    height: 30px;
  }
`;

export const Content = styled.div`
  border-radius: 0px 33px 33px 0px;
  position: relative;
  margin: auto;
  @media screen and (min-width: 1000px) {
    padding-left: 52%;
  }
  @media screen and (min-width: 1400px) {
    padding-left: 62%;
  }
  img {
    position: absolute;
    top: 3%;
    right: 3%;
    @media screen and (min-width: 300px) {
      top: 1%;
      right: 1%;
      width: 90px;
    }
  }
  form {
    height: 50vh;
    margin: 10% auto;
    @media screen and (min-width: 1000px) {
      margin: 60% auto;
    }
    @media screen and (min-width: 1200px) {
      margin: 30% auto;
    }
    @media screen and (min-width: 1400px) {
      margin: 20% auto;
    }
    @media screen and (min-width: 2000px) {
      margin: 50% auto;
      height: 10vh;
    }

    .input {
      border-radius: 0;
      border-left: none;
      border-top: none;
      border-right: none;
    }

    .btn-next {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 100%;
      height: 50px;
    }
    label {
      font-size: 14px;
    }

    button.btn-primary {
      border-radius: 10px !important;
      font-size: 18px;
      height: 54px;
    }
  }

  @media screen and (min-width: 414px) {
    .btn-next {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 331px;
      height: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-next {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 335px;
      height: 50px;
    }

    img {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
`;

export const Background = styled.div`
  background: url(${banner}), #f7f9fb;
  background-size: 100%;
  background-position: left center;
  position: relative;

  @media (min-width: 992px) {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    flex: 0 0 auto;
    width: 50%;
    z-index: -1;
  }

  @media (min-width: 1400px) {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    flex: 0 0 auto;
    width: 61%;
    z-index: -1;
  }
`;

export const Step = styled.div<IStep>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  min-height: 325px;
  flex-direction: column;
  justify-content: center;
`;
