import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 5px;
  padding: 5px 30px 7px 15px;
  border-radius: 15px;

  :hover {
    cursor: text;
  }
  background-color: #fff;
  h2 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  input {
    width: 100%;
    padding: 0.3rem;
    :focus {
      outline: none;
      border-radius: 8px;
    }
  }
`;
