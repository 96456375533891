import styled from 'styled-components';

export const MembersNameBox = styled.div`
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
`;
export const Container = styled.div`
  .circle-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #e6e7e8;
    border-radius: 50%;
    margin-left: 10px;
  }
`;
