import { nanoid } from "nanoid";
/**
 * Will geenerate a random ID of 8 characters
 * @description This function generate a random ID of 8 characters will down probability of collision
 * @see https://zelark.github.io/nano-id-cc/
 * @returns A string of ID
 */
export function setNewGenrateID(){
    try {
        const ID_CREATED = nanoid(8)

        if(!ID_CREATED) throw new Error('');

        setGenerateID(ID_CREATED)

        return ID_CREATED
    } catch (error) {
        return ''
    }
}

/**
 * Storage on local storage the ID created
 * @description To decrease the propability of collision and new ID's generation, the ID will be storaged on local
 */
function setGenerateID(id: string | null = null){
    try {
        if(!id) throw new Error('')
            
        localStorage.setItem('MCF@UNIQUE_ID', id)
    } catch (error) {
        // do anything
    }
}

/**
 * Get the storage ID 
 * @return An string representing of ID
 */
export function getGenerateID(id: string | null = null){
    try {
        return localStorage.getItem('MCF@UNIQUE_ID')
    } catch (error) {
        // do anything
    }
}