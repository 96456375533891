import styled from 'styled-components';

interface IContent {
  fullscreen: boolean;
}

export const Container = styled.div`
  @media (max-width: 600px) {
    height: 100vh;
  }
`;

export const Content = styled.div<IContent>`
  @media (min-width: 200px) {
    width: ${(props) => (props.fullscreen ? '100%' : 'calc(100% - 10px)')};
  }
`;
