import React, { createContext, useCallback, useContext } from 'react';
import Swal from 'sweetalert2';
import { useAuthContext } from '~/hooks/contexts/Auth';

interface ValidErrorData {
  validError(error: Error, page?: string): void;
}

export const ValidErrorContext = createContext<ValidErrorData>(
  {} as ValidErrorData
);

export const ValidErrorProvider: React.FC = ({ children }) => {
  const { signOut } = useAuthContext();

  const validError = useCallback(
    (error, page) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === 'Token has expired'
      ) {
        signOut();
      } else if (page && page === 'login') {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao fazer login, verifique suas credenciais',
          'error'
        );
      } else {
        Swal.fire('Opss...', 'Ocorreu um erro, tente novamente.', 'error');
      }
    },
    [signOut]
  );

  return (
    <ValidErrorContext.Provider value={{ validError }}>
      {children}
    </ValidErrorContext.Provider>
  );
};

export function useValidError(): ValidErrorData {
  const context = useContext(ValidErrorContext);

  if (!context) {
    throw new Error('useValidError must be used within an ValidErrorProvider');
  }

  return context;
}
