import React from 'react';
import alert from '~/assets/icons/alert.svg';

const PaymentsPending: React.FC = () => (
  <>
    <div className="row px-3 mx-5">
      <div className="col-auto fondo6" />
      <div className="col-auto fondo7" />
      <div className="col fondo8" />
    </div>
    <div className="row fondo mx-5 mb-5 px-3 mt-4">
      <div className="row py-2" />
      <div className="row text-3 px-4">Valor:</div>
      <div className="row">
        <div className="col text-4">R$ 10,00</div>
        <div className="col-auto text-5 fondo2">
          <img
            src={alert}
            alt="alert"
            color="#707070"
            id="Iconrojo"
            className=" pe-2"
          />
          Seu pagamento irá vencer em 5 dias
        </div>
      </div>
      <div className="py-3" />

      <div className="row">
        <div className="col-auto text-6 mb-2">Data de aquisição:</div>
        <div className="col text-7 mb-2">31/ 12/ 2022</div>
      </div>
      <div className="row">
        <div className="col-auto text-6">Produto:</div>
        <div className="col text-7">Harvey 3.0</div>
      </div>
      <div className="row">
        <div className="col-auto text-6">Mês de referência:</div>
        <div className="col text-7">Dezembro</div>
        <div className="col-auto">
          <button type="button" className="fondo3 px-4 py-2">
            Solicitar pagamento
          </button>
        </div>
      </div>
      <div className="row py-2" />
    </div>
  </>
);

export default PaymentsPending;
