import React, { PropsWithChildren } from 'react';
import { BiInfoCircle } from 'react-icons/bi';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { Box } from './styles';

interface ICardSimulationConditionsProps extends PropsWithChildren<any> {
  os?: IMServiceOrder
}

const CardSimulationConditions: React.FC = ({ os }: ICardSimulationConditionsProps) => {
  const { serviceOrder: OSState } = useServiceOrder();

  // @ts-ignore
  const serviceOrder: Required<IMServiceOrder> = os || OSState

  return (
    <>
      {serviceOrder.simulacao && (
        <Box className="px-4 py-5 p-lg-5 h-100 d-flex flex-column justify-content-center">
          <h2 className="h4 mb-5 fw-medium">Condições da simulação</h2>
          <div className="d-flex justify-content-between mb-3">
            <p className="fw-medium">
              Prazo do financiamento &ensp;
              <BiInfoCircle />
            </p>
            <p className="text-primary fw-semibold">
              {(Math.round(serviceOrder.simulacao?.prazo || 1) || 1)} anos
              {/*Old result that returns with months included*/}
              {/* {serviceOrder.simulacao.prazo || 1 * 12} anos */}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="fw-medium">
              Taxa de juros &ensp;
              <BiInfoCircle />
            </p>
            <p className="text-primary fw-semibold">
              {serviceOrder.simulacao.taxa} a.a
            </p>
          </div>
        </Box>
      )}
    </>
  );
};

export default CardSimulationConditions;
