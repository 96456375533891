import styled from 'styled-components';

interface IAvatar {
  src: string;
}

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.div``;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;
