import styled from 'styled-components';

export const CompleteData = styled.div`
  background-color: #fffdea;
  left: 250px;
  top: 350px;
  width: 70%;
  border-radius: 20px;
  padding: 0%;
  font-size: medium;
  p {
    color: #b07735;
  }
`;
