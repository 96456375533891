import styled from 'styled-components';

interface ITab {
  active: boolean;
}

export const Container = styled.div`
  .teste {
    background: #ffffff;
    border: 1px solid #e4e4e4;
  }

  .fondo {
    background: #fbfbfb;
    border-radius: 10px;
  }

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    /* Black */

    color: #100f0f;
  }

  .text-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    letter-spacing: -0.408px;

    /* gray */

    color: #414142;
  }

  .text-3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #707070;
  }

  .text-4 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;

    color: #202020;
  }

  .text-5 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #927221;
  }

  .text-6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #707070;
  }

  .text-7 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #202020;
  }

  .text-8 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Black */

    color: #100f0f;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .text-9 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Gray */

    color: #a4a4a4;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .fondo2 {
    background: #fff1dd;
    border-radius: 15px;
  }

  .fondo3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    /* Neutral_colors/Dark */

    background: #202020;
    border-radius: 18px;
    border: none;
  }

  .fondo4 {
    border: none;
    background: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Black */

    color: #100f0f;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .fondo5 {
    border: none;
    background: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Gray */

    color: #a4a4a4;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .fondo6 {
    background: #0d0d0d;
    width: 98px;
    height: 4px;
  }
  .fondo7 {
    background: #efefef;
    width: 98px;
    height: 4px;
  }
  .fondo8 {
    background: #efefef;

    height: 4px;
  }

  .fondo8 {
    background: #efefef;
    border-radius: 15px;
  }

  .sup-bar {
    background: #39a887;
    border-radius: 15px;
    width: 73.79px;
    height: 7.2px;
    opacity: 1;
  }

  .sub-bar {
    border: 0.3px solid #b8b9ba;
  }

  .h2 {
    font-size: 24px;
  }

  .input {
    border: none;
    padding: 0px 10px;

    input {
      color: #414141;
    }
  }

  .btn-insert {
    background-color: #7b7b7b;
    width: 100px;
    height: 34px;
    border-radius: 24px;
  }

  .logo {
    font-size: 14px;
  }

  .caract-count {
    color: #b8b9ba;
  }

  .btn-border {
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 0px 0px;
    height: 37px;
    white-space: nowrap;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: transparent;
    -webkit-text-fill-color: #707070;
    transition: 0.3s;

    :hover {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .tabs {
    margin-bottom: -1px;

    .active {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .folder {
    border: 1px solid #e4e4e4;
    border-radius: 0px 0px 10px 10px;
  }

  .name {
    color: #324351;
  }

  .next-image {
    background-color: #ececec;
    border-radius: 10px;
    width: 130px;
    height: 130px;
  }

  .btn-save {
    background: #39a887;
    border-radius: 20px;
    color: #ffffff;
    width: 100%;
    height: 50px;
  }

  @media screen and (min-width: 414px) {
    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 331px;
      height: 50px;
    }
  }

  @media screen and (min-width: 768px) {
    .next-image {
      width: 165px;
      height: 165px;
    }
  }

  @media screen and (min-width: 992px) {
    .overflow-lg-auto {
      overflow: auto;
    }

    .add-img {
      width: max-content;
    }

    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 335px;
      height: 50px;
    }

    .next-image {
      width: 165px;
      height: 165px;
    }
  }
`;

export const Tab = styled.div<ITab>`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;
