import styled from 'styled-components';

interface IButton {
  index: number;
  show: number[];
}

export const Container = styled.div`
  width: fit-content;
`;

export const List = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  width: fit-content;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1065px) {
    display: block;
  }

  .item {
    @media (max-width: 1280px) {
      padding: 0;
      width: 380px;
    }
  }

  .fondo {
    background: #fbfbfb;
    border-radius: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .project-title {
    display: flex;
    align-items: center;
    flex: none;
    flex-grow: 0;
    width: 210px;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px; /* identical to box height */

    color: #202020;

    /* @media (max-width: 1280px) {
      width: fit-content;
    } */
  }
`;

export const Label = styled.label`
  display: block;
  position: relative;
  width: 20px;
  margin: 0 15px 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 10px;
    height: 20px;
    width: 20px;
    background-color: #efefef;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #69ab97;
    border: none;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: 1px solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Button = styled.div<IButton>`
  cursor: pointer;

  ${({ index, show }) => (show.includes(index) ? 'transform: rotate(180deg);' : '')};
`;
