import { format } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IMSimulation } from '~/models/Simulation';

import { IMImmobileType } from '~/models/InmobileTypes';
import api from '~/services/api';
import {
  calculateLimitValue,
  maximumDeadline,
  maximumFinancedValue,
  maximumInstallment,
  simulationCalculation,
} from '~/utils/simulationCalculations';

import { useDefaultData } from './DefaultData';
import { useServiceOrder } from './ServiceOrder';

interface IInstallment {
  title: string;
  installment: number;
  color: string;
  percent: number;
}

interface SimulationsContextData {
  simulations: IMSimulation[];
  targetLimitValue: number;
  setTargetLimitValue(targetLimit: number): void;
  installments: IInstallment[];
  loading: boolean;
  yearSelected: number;
  setYearSelected(year: number): void;
  maximumValueSelected: number;
  setMaximumValueSelected(value: number): void;
}

export const SimulationsContext = createContext<SimulationsContextData>(
  {} as SimulationsContextData
);

export const SimulationsProvider: React.FC = ({ children }) => {
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const { amortizations } = useDefaultData();
  const [simulations, setSimulations] = useState<IMSimulation[]>([]);
  const [targetLimitValue, setTargetLimitValue] = useState(0);
  const [yearSelected, setYearSelected] = useState(
    parseFloat(format(new Date(), 'yyyy'))
  );
  const [maximumValueSelected, setMaximumValueSelected] = useState(0);
  const [installments, setInstallments] = useState<IInstallment[]>([
    {
      title: '',
      installment: 0,
      color: '#fff',
      percent: 0,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const colors = useMemo(
    () => [
      '#71961E',
      '#5B4DBE',
      '#FFBE21',
      '#91C3E7',
      '#C64DA4',
      '#F474FF',
      '#9B70E1',
      '#FF7235',
    ],
    []
  );
  const [immobileTypes, setImmobileTypes] = useState<IMImmobileType[]>([]);

  const handleSetTargetLimitValue = useCallback(
    (newTargetValue: number) => {
      setTargetLimitValue(newTargetValue);
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...serviceOrder.simulacao,
          vlrFinanciado: serviceOrder?.simulacao?.vlrFinanciado || 0,
          vlrParcInic: serviceOrder?.simulacao?.vlrParcInic || 0,
          vlrParcFinal: serviceOrder?.simulacao?.vlrParcFinal || 0,
          vlrImovPronto: serviceOrder?.simulacao?.vlrImovPronto || 0,
          prazo: serviceOrder?.simulacao?.prazo || 0,
          vlrSolicitado: newTargetValue || 0,
        },
      });
    },
    [serviceOrder, setServiceOrder]
  );

  useEffect(() => {
    async function getImmobileTypes() {
      const immobileType = await api.get<IMImmobileType[]>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/furniture-types/all/4`
      );
      setImmobileTypes(immobileType.data);
    }
    if (!immobileTypes.length) {
      getImmobileTypes();
    }
  }, [setServiceOrder, serviceOrder, immobileTypes]);

  useEffect(() => {
    if (amortizations.length > 0 && immobileTypes.length > 0) {
      setLoading(true);
      const data: IMSimulation[] = amortizations.map(
        (amortizationData, index) => {
          let limitValue = 0;
          if (
            serviceOrder?.finalidade_id === 3 ||
            serviceOrder?.finalidade_id === 4 ||
            serviceOrder?.finalidade_id === 6 ||
            serviceOrder?.finalidade_id === 7
          ) {
            limitValue = calculateLimitValue(
              immobileTypes,
              serviceOrder,
              amortizationData
            );
          }

          const simulationResponse = simulationCalculation({
            amortization: amortizationData,
            age: serviceOrder.ageOlder || 0,
            revenue: serviceOrder.simulacao?.vlrRenda || 0,
            deadline: amortizationData.prazo || 1,
            targetInstallment: 0,
            limitValue,
            targetLimitValue,
          });

          return {
            ...simulationResponse,
            vlrSolicitado: targetLimitValue || simulationResponse.vlrFinanciado,
            amortizacao: {
              ...amortizationData,
              color: amortizationData?.color
                ? `#${amortizationData?.color}`
                : colors[index],
            },
          };
        }
      );
      setSimulations(data);
      if (!targetLimitValue) {
        setTargetLimitValue(maximumFinancedValue(data));
      }
      setLoading(false);
    }
  }, [amortizations, serviceOrder, targetLimitValue, colors, immobileTypes]);

  useEffect(() => {
    const installmentsData = [] as IInstallment[];
    const totalPeriod = maximumDeadline(simulations);
    const largestInstallment = maximumInstallment(simulations);

    simulations.forEach((simulation) => {
      const firstInstallment = simulation.vlrParcInic || 1;
      const lastInstallment = simulation.vlrParcFinal || 1;
      if (!Number.isNaN(firstInstallment) || !Number.isNaN(lastInstallment)) {
        const annualExpense =
          (firstInstallment - lastInstallment) / totalPeriod;

        const currentIinstallment =
          (yearSelected - parseInt(format(new Date(), 'yyyy'), 10)) *
          annualExpense;

        const installment = firstInstallment - currentIinstallment;

        installmentsData.push({
          title: simulation.amortizacao?.amortizacao || '',
          installment,
          color: simulation.amortizacao?.color || '#fff',
          percent: parseFloat(
            ((installment / largestInstallment) * 100).toFixed(2)
          ),
        });
        setInstallments(installmentsData);
      }
    });
  }, [simulations, yearSelected]);

  const simulationsParams = useMemo(() => {
    return {
      simulations,
      targetLimitValue,
      setTargetLimitValue: handleSetTargetLimitValue,
      installments,
      loading,
      yearSelected,
      setYearSelected,
      maximumValueSelected,
      setMaximumValueSelected,
    };
  }, [
    simulations,
    targetLimitValue,
    handleSetTargetLimitValue,
    installments,
    loading,
    yearSelected,
    setYearSelected,
    maximumValueSelected,
    setMaximumValueSelected,
  ]);

  return (
    <SimulationsContext.Provider value={simulationsParams}>
      {children}
    </SimulationsContext.Provider>
  );
};

export function useSimulations(): SimulationsContextData {
  const context = useContext(SimulationsContext);

  if (!context) {
    throw new Error(
      'useSimulations must be used within an SimulationsProvider'
    );
  }

  return context;
}
