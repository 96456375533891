import { Fragment, PropsWithChildren } from 'react';

// Assets
import trophy from '~/assets/icons/trophy.svg';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Models
import { IMClassification } from '~/models/Classification';

// Styles
import { Container } from './styles';

interface IAwardProps extends PropsWithChildren<any> {
  classifications: IMClassification[];
  userClassified: IMClassification | null;
  tournament: string;
}

export default function Awards({
  classifications,
  userClassified,
  tournament,
}: IAwardProps): JSX.Element {
  const { user } = useAuthContext();

  const CLASSIFICATIONS = userClassified
    ? classifications.slice(0, classifications.length - 1)
    : classifications;

  return (
    <>
      {CLASSIFICATIONS?.length > 0 && (
        <Container className="mb-4">
          <div className="d-flex align-items-center justify-content-between px-3 py-1 green-box">
            <h3 className="overflow-hidden text-ellipsis break-normal">
              {tournament}
            </h3>
            <img src={trophy} alt="trophy" />
          </div>
          <div>
            <ul className="px-2 px-lg-4 py-2">
              {CLASSIFICATIONS.map((classification) => (
                <Fragment key={classification.id}>
                  <li
                    className={`py-3 px-2 ${
                      classification.id === user!.id ? 'you' : ''
                    }`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img src={classification.image} alt="firstPlace" />
                        <p className="mb-0 mx-3">{classification.position}º</p>
                        <div className="d-flex align-items-center">
                          <p className="mb-0">{classification.name}</p>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-row flex-nowrap gap-1 items-center min-w-[20%] justify-end">
                        <b>{classification.point}</b>
                        <small>pnts</small>
                      </div>
                    </div>
                  </li>
                </Fragment>
              ))}

              {userClassified && (
                <li className="py-3 px-2 you">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <img src={userClassified.image} alt="" />

                      <p className="mb-0 mx-3">{userClassified.position}º</p>

                      <div className="d-flex align-items-center">
                        <p className="mb-0">{userClassified.name}</p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row flex-nowrap gap-1 items-center min-w-[20%] justify-end">
                      <b>{userClassified.point}</b>
                      <small>pnts</small>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </Container>
      )}
    </>
  );
}
