import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - var(--sidebar-width));

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;
