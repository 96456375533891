import { captureMessage, showReportDialog } from "@sentry/browser";
import { Form } from '@unform/web';
import { InputNumber as InputMoney } from "primereact/inputnumber";
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import Chart from 'react-apexcharts';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import InputRadio, { IOption } from '~/components/InputRadio';

import logo from '~/assets/logos/logo-gray.svg';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import {
  calculateEnvironmentsValues,
  getAmountStatement,
  getBudget
} from '~/utils/serviceOrderCalculations';

import Swal from 'sweetalert2';
import Input from '~/components/Input';
import { IEnvironment } from "~/hooks/BuildYourHouse/types";
import { useAuthContext } from "~/hooks/contexts/Auth";
import { IMBudgetParameter } from "~/models/BudgetParameter";
import { IMBudgetRoom } from "~/models/BudgetRoom";
import budgetCalculation from "~/utils/budgetCalculation";
import formatCurrency from '~/utils/formatCurrency';
import { IStep } from "~/utils/serviceOrder/types";
import { Ball, Box, Container } from './styles';

interface IEnvironmentResponseAxios {
  id: number;
  amb_tam_id: number;
  item: number;
  metragem: number;
  vlrEstado: number;
  vlrAcabamento: number;
  vlrLucro: number;
  vlrFinal: number;
  ambiente_tamanho: {
    ambiente_id: number;
    ambiente: {
      ambiente: string;
      margem: number;
    };
  };
}

const Summary: React.FC = () => {
  const history = useHistory();
  const params = useParams<{osId: string}>()
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const {user} = useAuthContext()
  const [budget, setBudget] = useState(0);
  const [accumulatedTotal, setAccumulatedTotal] = useState(0);
  const [changeEntryValue, setChangeEntryValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [diffBudget, setDiffBudget] = useState(budget - accumulatedTotal);
  const [changeEntryValueSelected, setChangeEntryValueSelected] = useState<IOption>({
    id: 'Não',
    value: 'Não',
  });
  const [bdi, setBdi] = useState(serviceOrder?.orc_parametros?.margem || 30);
  // const [bdi, setBdi] = useState(0);

  // AUX Variables
  const options = useMemo(
    () => ({
      labels: [
        'Total disponível para construção',
        'Valor do seu orçamento total',
        'Diferença entre seu valor e total disponível',
      ],
      colors: ['#5B4DBE', '#FFBE21', '#A1C64D'],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '80%',
          },
        },
      },
    }),
    []
  );  
  const HAS_ORC_ENVIRONMENTS = useMemo(() => !!serviceOrder.orc_ambientes?.length, [])
  const AMOUNT_ENVIRONMENT = useMemo(() => serviceOrder?.amountEnvironments || 0, [])  
  const ORC_AMBIENTES_CACHED = useMemo(() => serviceOrder.orc_ambientes, [])
  const ORC_PARAMETROS_CACHED = useMemo(() => serviceOrder.orc_parametros, [])

  useEffect(() => {
    api.get<IEnvironmentResponseAxios[]>(
    `/builders/service-orders/${serviceOrder.id ?? params?.osId}/budget/environments`
    ).then(response => {
      let lastEnvironmentType = '';

      const d:any[] = []

      // @ts-ignore
      const environmentsData: IEnvironment[] = response.data.map(
        (environment) => {
          const type = environment.ambiente_tamanho.ambiente.ambiente
            .toLowerCase()
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/ /g, '-');
  
            const finishingMargin = serviceOrder?.orc_parametros?.acabamento?.margem || 0;
  
            const DEFAULT_AMOUNT = {
              stateValue: environment.vlrEstado, 
              valueFinal: environment.vlrFinal, 
              valueFinishing: environment.vlrAcabamento,
              valueProfit: environment.vlrLucro
            }

            const AMOUNT_STATEMENT = getAmountStatement(
              (serviceOrder?.terreno?.estado?.valor || 0), // Valor do estado
              environment.ambiente_tamanho.ambiente.margem,  // Quarto eg: 25m² (25)
              environment.metragem,
              finishingMargin, // Acabamentos
              bdi
            )

            // TODO: THE CODE BELOW WILL BE DEPRECATED
            if(
              (DEFAULT_AMOUNT.stateValue + DEFAULT_AMOUNT.valueFinal + DEFAULT_AMOUNT.valueFinishing + DEFAULT_AMOUNT.valueProfit) 
              <= 
              0 
            ) 
            {              
            }

            DEFAULT_AMOUNT.stateValue = AMOUNT_STATEMENT.stateValue
            DEFAULT_AMOUNT.valueFinal = AMOUNT_STATEMENT.valueFinal
            DEFAULT_AMOUNT.valueFinishing = AMOUNT_STATEMENT.valueFinishing
            DEFAULT_AMOUNT.valueProfit = AMOUNT_STATEMENT.valueProfit

            const data = {
              id: environment.id,
              type,
              amb_tam_id: environment.amb_tam_id,
              ambiente_tamanho: {
                ambiente_id: environment.ambiente_tamanho.ambiente_id,
                margem: environment.ambiente_tamanho.ambiente.margem
              },
              ambiente_tamanho_id: environment.amb_tam_id,
              ambiente_id: environment.ambiente_tamanho.ambiente_id,
              item: environment.item,
              metragem: environment.metragem,
              vlrEstado: DEFAULT_AMOUNT.stateValue,
              vlrAcabamento: DEFAULT_AMOUNT.valueFinishing,
              vlrLucro: DEFAULT_AMOUNT.valueProfit,
              vlrFinal: DEFAULT_AMOUNT.valueFinal,
              selected: lastEnvironmentType !== type,
            };
  
            console.log(`SUMMARY@Comparasion of values from environment ${environment.ambiente_tamanho.ambiente.ambiente}/${environment.metragem} (DATABASE x RUNTIME)`,                
              {
                vlrEstado: environment.vlrEstado,
                vlrAcabamento: environment.vlrAcabamento,
                vlrLucro: environment.vlrLucro,
                vlrFinal: environment.vlrFinal,
              },
              {
               ...DEFAULT_AMOUNT
              }
            )              
  
            lastEnvironmentType = type;

            d.push(DEFAULT_AMOUNT)
  
            return {...data,
              ambiente_tamanho: {
                ...data.ambiente_tamanho,
                ambiente: environment.ambiente_tamanho.ambiente
              }
            };
        }
      );

      console.log('HERE TO COMPARE',JSON.stringify(d))
  
      setServiceOrder({
        // @ts-ignore
        orc_ambientes: environmentsData
      })

      // TODO: ONLY AVAILABLE IN RUNTIME TO STORE THE DEFAULT ORC AMBIENTES STATEMENT
      // @ts-ignore
      if(!(!!serviceOrder.DEFAULT_ORC_AMBIENTES)) {
        setServiceOrder({
          // @ts-ignore
          DEFAULT_ORC_AMBIENTES: environmentsData
        })

        console.log('DEFAULT_ORC_AMBIENTES')
      }
    });

  },[])

  useEffect(() => {

    const PARAMETERS_MOUNTED: Partial<IMBudgetParameter>[] = Object.keys(serviceOrder.orc_parametros || {})
    .filter( 
        // @ts-ignore
        key => typeof serviceOrder.orc_parametros[key] === 'object'
        &&
        handlerChecksAvailableParameter(key)
      )
      .map((p) => {
        console.log('PARAMETERS IN MOUNT')
        console.table(p)
        return {
          // @ts-ignore
          margem: (serviceOrder.orc_parametros[p] as unknown as IMBudgetParameter)?.margem
        }
      })

    const budgetValue = getBudget(serviceOrder);

    console.warn('SUMMARY ENV COMPARASION');
    console.table(serviceOrder?.orc_ambientes)
    console.table({PARAMETERS_MOUNTED})
    
    const ACCUMULATED_TOTAL_AMOUNT =  budgetCalculation({
      environments: serviceOrder?.orc_ambientes || [],
      // @ts-ignore
      parameters: PARAMETERS_MOUNTED
    });
    // const ACCUMULATED_TOTAL_AMOUNT = 0;
    
    // console.table({
    //   HAS_ORC_ENVIRONMENTS,
    //   PARAMETERS_MOUNTED,
    //   soOrcs: serviceOrder?.orc_ambientes || [],
    // })

    setBudget(budgetValue);
    setAccumulatedTotal(ACCUMULATED_TOTAL_AMOUNT);
    setDiffBudget(budgetValue - ACCUMULATED_TOTAL_AMOUNT);

  }, [serviceOrder.orc_ambientes]);

  useEffect(() => {
    // handleSuperSet(bdi, serviceOrder.simulacao?.vlrEntrada || 0)
  },[])

  const series = useMemo(
    () => [budget, accumulatedTotal, diffBudget],
    [budget, diffBudget, accumulatedTotal]
  );

  const handleChangeEntryValue = useCallback((option: IOption) => {
    setChangeEntryValueSelected(option);
    setChangeEntryValue(option.id === 'Sim');
  }, []);

  const setChangeBdiAndEntry = useCallback(
    (bdiValue: number, entry: number) => {
      // console.warn(`WAS CALLED WITHOUT A CHANGE BY USER: ${bdiValue}`)
      
      setBdi(bdiValue);
      
      // @ts-ignore
      console.log('CHANGE BDI ENTRY', serviceOrder.DEFAULT_ORC_AMBIENTES)
      
        setServiceOrder({
          simulacao: {
            ...serviceOrder.simulacao,
            vlrEntrada: entry,
          },        
          // TODO: APPLY THE ORIGINAL VALUE OR THE VALUE WITH 1% OF BDI?
          // TODO: IF BDI IS ZERO SO WILL BE APPLY THE DEFAULT ORC AMBIENTES THAT WAS MOUNTED ON FIRST LOAD OF THIS PAGE
          // @ts-ignore
          orc_ambientes:  bdiValue <= 0 ? serviceOrder.DEFAULT_ORC_AMBIENTES : calculateEnvironmentsValues(
            serviceOrder,
            bdiValue
          ),        
          orc_parametros: {
            ...serviceOrder?.orc_parametros,
            // TODO: THAT VALUE 'bdi' NOT IS THE 'margem' value
            margem: bdiValue
          }
        });      

      if(bdiValue <= 0) {
        setServiceOrder({          
          orc_parametros: ORC_PARAMETROS_CACHED          
        })
      }
    },
    [serviceOrder, setServiceOrder]
  );

  /**
   * Do a super set with the Service Order context
   * @description Will reset the values of orc_ambientes to default values without BDI and exec the change on BDI and apply a new calc with BDI
   */
  const handleSuperSet = useCallback((bdiValue: number, entry: number) => {
    try {

      if(!bdiValue || bdiValue <= 0 ) {
        // setServiceOrder({
        //   orc_ambientes: ORC_AMBIENTES_CACHED,
        //   orc_parametros: ORC_PARAMETROS_CACHED
        // })
        throw new Error('The BDI value is not valid')
      }

      // const RESTAURED_ENVIRONEMNTS = setResetServiceOrderEnvironments()
  
      // if(RESTAURED_ENVIRONEMNTS && RESTAURED_ENVIRONEMNTS.length <= 0)
      // {        
      //   throw new Error('Is not possible reset the env on service order ctx');
      // }
  
      // console.warn('WILL BE PRINTED THE RESULTS OF RESET PROCCESS');
      // console.table({
      //     RESTAURED_ENVIRONEMNTS: JSON.stringify(RESTAURED_ENVIRONEMNTS),
      //     RESTAURED_ENVIRONEMNTS_PARSED: RESTAURED_ENVIRONEMNTS
      //   }
      // );
      
    } catch (error: any) {
      console.warn(`Have a erro on 'handleSuperSet' call stack: ${error?.message || ''}`)      
    } finally {
      setChangeBdiAndEntry(bdiValue, entry);
    }
  }, [serviceOrder])

  /**
   * Apply a new statement os environments whitout BDI
   * @deprecated This function will be deprecated
   */
  const setResetServiceOrderEnvironments = useCallback(() => {
    try {
      if(!serviceOrder) throw new Error('The service order is not available');

      const DEFAULT_ENVIRONMENTS_CACHED: IStep[] = JSON.parse(sessionStorage.getItem('HARVEY_BUILDER_OS_DEFAULT_ENV') || "[]") || [];

      if(!DEFAULT_ENVIRONMENTS_CACHED || DEFAULT_ENVIRONMENTS_CACHED.length <= 0) 
        throw new Error('The main cache is not available');

      const RESTAURED_ENVIRONEMNTS: any[] = []
      
      DEFAULT_ENVIRONMENTS_CACHED.forEach( cachedEnv => {
        const FOUNDED_ENV_OPTION_SELECTED: IMBudgetRoom | undefined = serviceOrder.orc_ambientes?.find(orcAmb => orcAmb.ambiente_tamanho.ambiente_id === cachedEnv.id)
        const HAVE_OPTION_ON_CACHED_ENV = cachedEnv.size.options.find(sizeOption => sizeOption.id === FOUNDED_ENV_OPTION_SELECTED?.ambiente_tamanho_id)

        const OPTION_WITH_DEFAULT_AMOUNTS = !HAVE_OPTION_ON_CACHED_ENV ? null : {
          ...FOUNDED_ENV_OPTION_SELECTED,          
          vlrEstado: HAVE_OPTION_ON_CACHED_ENV?.stateValue || 0,
          vlrAcabamento: HAVE_OPTION_ON_CACHED_ENV?.valueFinishing || 0,
          vlrLucro: HAVE_OPTION_ON_CACHED_ENV?.valueProfit || 0,
          vlrFinal: HAVE_OPTION_ON_CACHED_ENV?.price || 0,
        }

        if(OPTION_WITH_DEFAULT_AMOUNTS && (OPTION_WITH_DEFAULT_AMOUNTS !== null || OPTION_WITH_DEFAULT_AMOUNTS !== 'null'))
        {
          RESTAURED_ENVIRONEMNTS.push(OPTION_WITH_DEFAULT_AMOUNTS)
        }
      })

      console.log(JSON.stringify(serviceOrder.orc_ambientes) )

      setServiceOrder({
        // @ts-ignore
        orc_ambientes: RESTAURED_ENVIRONEMNTS || serviceOrder.orc_ambientes
      })

      return RESTAURED_ENVIRONEMNTS.filter(d => d !== null);
    } catch (error) {      
      throw error
    }

  }, [serviceOrder])

  const handleClickNextDashboard = useCallback(async () => {
    setLoading(true)

    // AMBIENTES
    const ambientes = (serviceOrder?.orc_ambientes || [])
      .filter((environment) => !!environment.ambiente_tamanho.ambiente_id)
      .map((environment, index) => ({
        ...environment,
        item: index + 1,
      }));

    await api.post<IMBudgetRoom[]>(
      `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${serviceOrder.id}/budget/environments`,
      {
        ambientes,
      }
    ).catch(error => console.warn('Some thing its wrong with PUT /environments'));

    // ACABAMENTOS (PARAMETERS)

    const formData = {
      tp_acabamento_id: serviceOrder.orc_parametros?.acabamento?.id,
      tp_terreno_id: serviceOrder.orc_parametros?.terreno?.id,
      estilo_construcao_id: serviceOrder.orc_parametros?.estilo_construcao?.id,
      tp_eletrica_id: serviceOrder.orc_parametros?.eletrica?.id,
      tp_hidraulica_id: serviceOrder.orc_parametros?.hidraulica?.id,
      pe_direito: serviceOrder.orc_parametros?.pe_direito || false,
      margem: serviceOrder.orc_parametros?.margem,
    };

    await api.post<IMBudgetParameter>(
      `/builders/oss/${serviceOrder.id}/budget/parameters`,
      formData
    ).catch(error => console.warn('Some thing its wrong with PUT /parameters'));

    await api.put(
      `builders/service-orders/${serviceOrder.id}/simulations/${serviceOrder.simulacao?.id}/`,
      serviceOrder.simulacao
    ).then(() => {
      setLoading(false)
      history.push(`${process.env.PUBLIC_URL}/dashboard/${serviceOrder.id}`);
    }).catch(() => {
      Swal.fire({
        icon: 'warning',
        text: 'No momemnto não foi possivel gerar a Ordem de Serviço',
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'Fechar',
        confirmButtonText: 'Contatar Suporte',
        confirmButtonColor: '#39a887'
      }).then(resolver => {
        if(resolver.isConfirmed) {
          showReportDialog({
            lang: 'pt-BR',
            eventId: captureMessage('The service order do not created','fatal')
          })
        }
      })
      
      setLoading(false)
    });    
  }, [history, serviceOrder]);

  function handlerChecksAvailableParameter(key: string = ''){
    try {
      const AVAILABLE_PARAMETERS = ["acabamento", "terreno","estilo_construcao", "eletrica", "hidraulica"]

      return AVAILABLE_PARAMETERS.includes(key);
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      <Container>
        <div className="col-12 mt-5">
          <div className="row">
            <div className="col-lg-6">
              <Box className="h-100 p-5">
                <h2 className="h5 text-dark-gray fw-semibold">
                  Resultado do orçamento
                </h2>
                <div className="position-relative d-flex align-items-center justify-content-center">
                  <Chart
                    type="donut"
                    options={options}
                    series={series}
                    width={200}
                    height={200}
                    className="my-4"
                  />
                  <img
                    src={logo}
                    alt="logo"
                    className="logo position-absolute"
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#5B4DBE" className="me-2" />
                    <p className="mb-0">Total disponível para construção</p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(budget)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center my-3">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#FFBE21" className="me-2" />
                    <p className="mb-0">Valor do seu orçamento total</p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(accumulatedTotal)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center legend">
                    <Ball color="#A1C64D" className="me-2" />
                    <p className="mb-0">
                      Diferença entre seu valor e total disponível
                    </p>
                  </div>
                  <p className="mb-0 text-dark-gray fw-bold">
                    {formatPrice(diffBudget)}
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-lg-6">
              <Form
                onSubmit={() => false}
                className="h-100 px-5 pb-0 d-flex flex-column justify-content-start gap-5"
              >
                <div>
                  <label className="w-100">
                    BDI:
                    <span className="text-primary">*</span>
                    <Input
                      className="input"
                      type="number"
                      placeholder="00.00"
                      name="bdi"
                      defaultValue={bdi}
                      min={29}
                      onChange={(e) => handleSuperSet(
                        parseInt(e.target.value, 10),
                        serviceOrder.simulacao?.vlrEntrada ?? 0
                      )
                      }
                    />
                  </label>
                </div>
                <div>
                  <p>
                    Deseja alterar o valor da entrada?
                    <span className="text-primary">*</span>
                  </p>
                  <InputRadio
                    name="change_entry_value"
                    options={[
                      {
                        id: 'Sim',
                        value: 'Sim',
                      },
                      {
                        id: 'Não',
                        value: 'Não',
                      },
                    ]}
                    className="justify-content-start"
                    onChange={handleChangeEntryValue}
                    selected={changeEntryValueSelected}
                  />
                </div>
                {changeEntryValue && (
                  <label className="w-100 flex flex-col">
                    <span>
                      Valor de entrada: <span className="text-primary">*</span>                    
                    </span>
                    <InputMoney                      
                      name="entry"
                      placeholder="R$0,00"
                      inputClassName="input"
                      inputMode='numeric'
                      mode="currency"
                      currency='BRL'
                      locale='pt-BR'
                      minFractionDigits={2} 
                      maxFractionDigits={5}                   
                      defaultValue={formatCurrency(serviceOrder.simulacao?.vlrEntrada ?? 0)}
                      onChange={(e) => handleSuperSet(bdi, parseInt(e.value?.toString() || '0', 10))}
                    />
                  </label>
                )}
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary py-3 disabled:opacity-80 disabled:cursor-not-allowed flex flex-row items-center justify-center"
                  onClick={handleClickNextDashboard}
                >
                  {
                    loading ? 
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg> : 
                    'Continuar'
                  }                  
                </button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Summary;
