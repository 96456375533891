import React, { useCallback } from 'react';
import { HiCamera } from 'react-icons/hi';
import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';
import Toast from '~/utils/toast';
import { Avatar } from './styles';

const AvatarConstrutech: React.FC = () => {
  const { setLoading, construtech, setConstrutech } = useProfile();

  const handleChangeAvatar = useCallback(
    async (event) => {
      setLoading(true);
      try {
        const formImageAvatar = new FormData();
        formImageAvatar.append('file', event.target.files[0]);

        await api.post(
          `${process.env.REACT_APP_PREFIX_ROUTE}/construtech/logo`,
          formImageAvatar
        );

        setConstrutech({
          ...construtech,
          logo_url: URL.createObjectURL(event.target.files[0]),
        });
      } catch (err) {
        Toast.fire({
          icon: 'error',
          title: 'Ocorreu um erro ao alterar sua foto',
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, construtech, setConstrutech]
  );

  return (
    <>
      <div className="align-items-center mt-5">
        <div className="col-auto">
          <Avatar src={construtech.logo_url || ''} className="mx-auto">
            <button
              type="button"
              className="d-flex camera border-0 align-items-center justify-content-center"
            >
              <label htmlFor="upload-logo" style={{ cursor: 'pointer' }}>
                <HiCamera size={17} color="#fff" className="d-flex" />
              </label>
              <input
                type="file"
                accept="image/*"
                className="d-none"
                onChange={(e) => handleChangeAvatar(e)}
                id="upload-logo"
              />
            </button>
          </Avatar>
        </div>
      </div>
    </>
  );
};

export default AvatarConstrutech;
