import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

// Icons
import electricBasicElectrical from '~/assets/defaults/electric-basic-electrical.png';
import electricLuminotechnicalProject from '~/assets/defaults/electric-luminotechnical-project.png';
import finishingBasic from '~/assets/defaults/finishing-basic.png';
import finishingHigh from '~/assets/defaults/finishing-high.png';
import finishingLow from '~/assets/defaults/finishing-low.png';
import finishingMedium from '~/assets/defaults/finishing-medium.png';
import flatLand from '~/assets/defaults/flat-land.png';
import houseStyleConventionalGroundFloor from '~/assets/defaults/house-style-conventional-ground-floor.png';
import houseStyleConventionalTownhouse from '~/assets/defaults/house-style-conventional-townhouse.png';
import houseStyleModernGroundFloor from '~/assets/defaults/house-style-modern-ground-floor.png';
import houseStyleModernTownhouse from '~/assets/defaults/house-style-modern-townhouse.png';
import hydraulicColdHotReuse from '~/assets/defaults/hydraulic-cold-hot-reuse.png';
import hydraulicColdHot from '~/assets/defaults/hydraulic-cold-hot.png';
import hydraulicCold from '~/assets/defaults/hydraulic-cold.png';
import rightFoot from '~/assets/defaults/right-foot.png';
import slopeLand from '~/assets/defaults/slope-land.png';
import uphillLand from '~/assets/defaults/uphill-land.png';
import basiclEletrical from '~/assets/icons/basic-electrical.svg';
import basic from '~/assets/icons/basic.svg';
import coldHotReuse from '~/assets/icons/cold-hot-reuse.svg';
import coldHot from '~/assets/icons/cold-hot.svg';
import cold from '~/assets/icons/cold.svg';
import conventionalGroundFloor from '~/assets/icons/conventional-ground-floor.svg';
import conventionalTownhouse from '~/assets/icons/conventional-townhouse.svg';
import flat from '~/assets/icons/flat.svg';
import high from '~/assets/icons/high.svg';
import low from '~/assets/icons/low.svg';
import luminotechnicalProject from '~/assets/icons/luminotechnical-project.svg';
import medium from '~/assets/icons/medium.svg';
import modernGroundFloor from '~/assets/icons/modern-ground-floor.svg';
import modernTownhouse from '~/assets/icons/modern-townhouse.svg';
import rightFootNo from '~/assets/icons/right-foot-no.svg';
import rightFootYes from '~/assets/icons/right-foot-yes.svg';
import slope from '~/assets/icons/slope.svg';
import uphill from '~/assets/icons/uphill.svg';

// Services
import api from '~/services/api';

// Types
import { IOption, IOptionResponse, IParameter, IStep, IStepProps, IStepRecovery, ParametersRenamed, STEPS_AVAILABLE, TParameter, TStepTargets } from './types';

// Partials
import { CgSpinner } from 'react-icons/cg';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useBuildYourHouse } from '~/hooks/BuildYourHouse';
import { Mock, MockButton, MockImage, MockStep } from '../Mocks';

export function External({
    generalID,
    stepType,
    showNavigation,
    externalEnvironments,

    hasOS,

    urlEnvinromentCallback,
    urlFinishCallback,
    urlParametersCallback,    

    onSelectedStep, 
    onOptionsSelected, 
    onCompletedStatus,
    onSelectedStepSlug,
    onSelectedStepPosition,
    onFinishedCallback
  }: IStepProps) {

  const [stepSelected, setStepSelected] = useState<IStep | null>(null)
  const [optionsSelected, setOptionsSelected] = useState<IOption[]>([])
  const [actualStepType, setActualStepType] = useState<TStepTargets>( stepType || 'acabamento')
  const [actualStepTypeIndex, setActualStepTypeIndex] = useState<number>(1)
    
  // DATA FETCHS
  const [responseLand, setResponseLand] = useState<IOptionResponse[]>([])
  const [responseHouseStyle, setResponseHouseStyle] = useState<IOptionResponse[]>([])
  const [responseElectric, setResponseElectric] = useState<IOptionResponse[]>([])
  const [responseFinishes, setResponseFinishes] = useState<IOptionResponse[]>([])
  const [responseHydraulic,setResponseHydraulic] = useState<IOptionResponse[]>([])
  const [responseParameters, setResponseParameters] = useState<TParameter | null>(null)

  // Hooks
  const {actions,status} = useBuildYourHouse({
    exteriorEnvironments: externalEnvironments,
    urlEnvinromentCallback: urlEnvinromentCallback || '',
    urlFinishCallback:    urlFinishCallback || '',
    urlParametersCallback:     urlParametersCallback || '',
    onFinishedCallback: onFinishedCallback,
    hasOS
  })
  
  // AUX Variables  
  const RIGHT_FEET_COLUMN: IOption[] = [
    {
      id: 1,
      step_id: 6,
      label: 'Sim',
      icon: rightFootYes,
      photo: rightFoot,
      margem: 0,
    },
    {
      id: 2,
      step_id: 6,
      label: 'Não',
      icon: rightFootNo,
      photo: rightFoot,
      margem: 0,
    },
  ]
  const ASSETS = [
    {
      type: 'acabamento',
      icon: high,
      photo: finishingHigh,
    },
    {
      type: 'acabamento',
      icon: medium,
      photo: finishingMedium,
    },
    {
      type: 'acabamento',
      icon: basic,
      photo: finishingBasic,
    },
    {
      type: 'acabamento',
      icon: low,
      photo: finishingLow,
    },
    {
      type: 'terreno',
      icon: uphill,
      photo: uphillLand,
    },
    {
      type: 'terreno',
      icon: slope,
      photo: slopeLand,
    },
    {
      type: 'terreno',
      icon: flat,
      photo: flatLand,
    },
    {
      type: 'estilo-da-casa',
      icon: modernTownhouse,
      photo: houseStyleModernTownhouse,
    },
    {
      type: 'estilo-da-casa',
      icon: conventionalTownhouse,
      photo: houseStyleConventionalTownhouse,
    },
    {
      type: 'estilo-da-casa',
      icon: modernGroundFloor,
      photo: houseStyleModernGroundFloor,
    },
    {
      type: 'estilo-da-casa',
      icon: conventionalGroundFloor,
      photo: houseStyleConventionalGroundFloor,
    },
    {
      type: 'eletrica',
      icon: luminotechnicalProject,
      photo: electricLuminotechnicalProject,
    },
    {
      type: 'eletrica',
      icon: basiclEletrical,
      photo: electricBasicElectrical,
    },
    {
      type: 'hidraulica',
      icon: coldHotReuse,
      photo: hydraulicColdHotReuse,
    },
    {
      type: 'hidraulica',
      icon: coldHot,
      photo: hydraulicColdHot,
    },
    {
      type: 'hidraulica',
      icon: cold,
      photo: hydraulicCold,
    },
    {
      type: 'pe-direito',
      icon: rightFootYes,
      photo: rightFoot,
    },
    {
      type: 'pe-direito',
      icon: rightFootNo,
      photo: rightFoot,
    },
  ]
  const STEP_DATASOURCE = useMemo(() => ((step_target: TStepTargets) => {
    switch(step_target){
      case 'acabamento':
          return responseFinishes;
      case 'eletrica':
          return responseElectric;
      case 'estilo-da-casa':
          return responseHouseStyle;
      case 'terreno':
          return responseLand;
      case 'pe-direito':
          return RIGHT_FEET_COLUMN;
      case 'hidraulica':
          return responseHydraulic;
      default:
        []
    }
  }),[responseFinishes, responseElectric, responseHouseStyle, responseLand, RIGHT_FEET_COLUMN, responseHydraulic])

  const STEPS_MOUNTED = useMemo(() => {
    const step_in_mount: IStep[] = []

    STEPS_AVAILABLE.forEach((d,_index) => {
      const stepAlReadySelected = getFromStorageStepSelected(d.name)

      if(stepAlReadySelected && Object.keys(stepAlReadySelected).length > 0 && stepAlReadySelected.options.length > 0) {
        const STEP_ALREADY_MOUNTED_INDEX = step_in_mount.findIndex(a => a.type === stepAlReadySelected.type)

        if(STEP_ALREADY_MOUNTED_INDEX >= 0) {
          step_in_mount[STEP_ALREADY_MOUNTED_INDEX] = stepAlReadySelected
        }else{
          step_in_mount.push(stepAlReadySelected)
        }
      } else {

        if(!responseParameters) return

        let assetIcon = ASSETS.filter(
          (asset) => asset.type === d.name
        );
  
        const options = STEP_DATASOURCE(d.name)?.map((option, index) => {
          const LABEL = option.tipo_acabamento || option.tipo_eletrica || option.tipo_hidraulica || option.tipo_terreno || option.estilo_construcao || option.pe_direito || ''
          const ALREADY_SELECTED = Object.keys(option).find(k => responseParameters?.[k] === option.id) ? true : false
          const OPTION_BODY = {
            id: option.id,
            step_id: d.id,
            label: LABEL,
            icon: assetIcon[index].icon,
            photo: assetIcon[index].photo,
            margem: option.margem,
            selected: ALREADY_SELECTED
          }

          return OPTION_BODY
        }) || []
  
        step_in_mount.push({
          id: d.id,
          photo: assetIcon[0]?.photo || '',
          question: d.question,
          type: d.name,
          slug: d.slug || '',
          options
        })
      }
    })

    // console.table(step_in_mount)

    return step_in_mount
  },[STEP_DATASOURCE])  

  // GET ALL ALREADY "PARAMETERS" FROM THIS OS
  useEffect(() => {

    if(responseParameters || !urlParametersCallback) return

    api.get<IParameter>(urlParametersCallback).then(resolver => {
      if(resolver.status !== 200) return

      const parameterRenamed: TParameter  = {} as TParameter

      Object.keys(resolver.data).forEach(k => {        
        parameterRenamed[ParametersRenamed[k]] = resolver.data[k]
      })

      setResponseParameters(parameterRenamed)
    })
  },[])

  // SET ALL FETCHS BASE TO GET ALL DATA
  useEffect(() => {
    try {      
      if(responseLand.length > 0 && responseHouseStyle.length > 0 
        && 
        responseElectric.length > 0 && responseFinishes.length > 0 
      ) return

      Promise.all(
        [
          api.get<IOptionResponse[]>('queries/getterrenos'),
          api.get<IOptionResponse[]>('queries/getestilosconstrucao'),
          api.get<IOptionResponse[]>('queries/gettiposeletrica'),
          api.get<IOptionResponse[]>('queries/getacabamentos'),
          api.get<IOptionResponse[]>('queries/gettiposhidraulica'),        
        ]
      ).then((resolvers: AxiosResponse[] ) => {
          setResponseLand(resolvers[0].data)
          setResponseHouseStyle(resolvers[1].data)
          setResponseElectric(resolvers[2].data)
          setResponseFinishes(resolvers[3].data)
          setResponseHydraulic(resolvers[4].data)
      }).catch(rejects => console.table(rejects))
    } catch (error) {
      console.table(error)
      return
    }
    
  },[])

  // SET SELECTED A DEFAULT OPTION BY STEPTYPE (SLUG FROM ROUTE PARAMS)
  useEffect(() => {
    if (STEPS_MOUNTED.length <= 0 || !responseParameters) return

    let newStepSelected = STEPS_MOUNTED.find(
      (stepCheckData) => actualStepType && stepCheckData.type === actualStepType
    );

    if (!newStepSelected) {
      // FIXME - eslint-disable-next-line prefer-destructuring
      newStepSelected = STEPS_MOUNTED[0];
    }

    console.table(newStepSelected)

    if(onSelectedStep) onSelectedStep(newStepSelected);
    if(onCompletedStatus) onCompletedStatus(true)
    if(onSelectedStepSlug) onSelectedStepSlug(newStepSelected.slug || '')    

    setStepSelected(() => newStepSelected || null);
    setToStorageStepSelected(newStepSelected)
  }, [
    actualStepType,
    responseLand,
    responseHouseStyle, responseElectric,
    responseFinishes, responseHydraulic,
    responseParameters
  ]);

  // A NEW STEP TYPE BY PROP
  useEffect(() => {
    if(!stepType) return

    setActualStepType(stepType)
  },[stepType])

  // INFER THE INDEX POSITION FROM STEP TYPE BY STEPS MOUNTED
  useEffect(()=> {
    if(STEPS_MOUNTED.length <= 0 || !actualStepType) return

    const ACTUAL_STEP_TYPE_INDEX = STEPS_MOUNTED.findIndex(d => d.type === actualStepType )

    if(ACTUAL_STEP_TYPE_INDEX <= -1) return

    console.log((ACTUAL_STEP_TYPE_INDEX + 1) > STEPS_MOUNTED.length)

    if((ACTUAL_STEP_TYPE_INDEX + 1) > STEPS_MOUNTED.length) {
      setActualStepTypeIndex(ACTUAL_STEP_TYPE_INDEX)
      onSelectedStepPosition && onSelectedStepPosition(ACTUAL_STEP_TYPE_INDEX)
    }else{
      setActualStepTypeIndex(ACTUAL_STEP_TYPE_INDEX + 1)
      onSelectedStepPosition && onSelectedStepPosition(ACTUAL_STEP_TYPE_INDEX + 1)
    }
  }, [actualStepType])
 
  const setOptionSelected = useCallback(
    (option: IOption | null, hasCallback: boolean =false) => {
      try {
        if(!stepSelected || !option) return

        console.table(optionsSelected)

        const newOptions = stepSelected.options.map((optionData) => {
          return {
            ...optionData,
            selected: optionData.id === option.id && true,
          };
        });

        const newStep = { ...stepSelected };
        newStep.photo = option.photo;
        newStep.options = newOptions;

        const newOptionsSeleted = optionsSelected.slice();
        const optionSelectedIndex = newOptionsSeleted.findIndex((optionSelected) => 
          newStep.options.some((optionStep) => optionStep.id === optionSelected.id && optionSelected.step_id === newStep.id));
  
        console.log(optionSelectedIndex)

        if (optionSelectedIndex >= 0) {
          newOptionsSeleted[optionSelectedIndex] = option;
        } else {
          newOptionsSeleted.push(option);
        }

        console.table(newOptionsSeleted)
  
        if(onCompletedStatus) onCompletedStatus(true)

        if(hasCallback && onOptionsSelected) onOptionsSelected(newOptionsSeleted)
          
        setOptionsSelected(newOptionsSeleted);
        setStepSelected(newStep);

        setToStorageStepSelected(newStep)
      } catch (error) {
        if(onCompletedStatus) onCompletedStatus(false)
        setOptionsSelected([]);
      }

    },
    [optionsSelected, stepSelected]
  );

  /**
   * Handle to set a new selected options group and call the "finished function"
   */
  function handleOnFinished (){
    try {
      if(!stepSelected) throw new Error('Nenhum passo selecionado')

      /* -------------------------------------------------------------------------------------------------
       * CAN BE THAT THE USER DO NOT INTERACT WITH ALL STEPS AVAILABLES, SO ALL STEP DATA WILL BE RECOVERY FROM STEP DATA CREATE IN RUNTIME ON CREATION
       * OF THIS COMPONENT, AND FOR NOT LOST THE DATA THAT USER CHANGE THE CODE BELOW ARE EXECUTED
       * {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY}
       * THE CODE ABOVE WILL MAINTEN THE NEW DATA AND DATA FROM USER
       */
      const STEPS_MOUNTED_RECOVERY: IStepRecovery = {} as IStepRecovery

      STEPS_MOUNTED.forEach(d => {
        if(d.type) STEPS_MOUNTED_RECOVERY[d.type] = d
      })

      const STEPS_RECOVERY = getAllFromStorageStepsSelected() || {}
      const STEPS_SELECTED = Object.keys(STEPS_RECOVERY).length !== 6 ? {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY} : STEPS_RECOVERY

      if(!STEPS_SELECTED) throw new Error('Nenhum cache encontrado')

      /* -------------------------------------------------------------------------------------------------
      * FOR EACH STEP (D) WILL BE  SETTED A NEW/OLD OPTION SELECTED (Z) WITH BASE ON OPTIONS_SELECTED STATE
      * -------------------------------------------------------------------------------------------------
      * This is necessary because the forEach run several callbacks in tiny time
      * broken the time to React rerun the useCallback hook to update the function context with new state value
      */

      const newOptionsSeleted = optionsSelected.slice();
      
      if(optionsSelected.length < 6) {
        Object.keys(STEPS_SELECTED).forEach(d => {
          STEPS_SELECTED[d]?.options.forEach(z => {
            if(z.selected === false) return
  
            console.log(`${z.label}@${z.id}`, z.selected)
  
            const optionSelectedIndex = newOptionsSeleted.findIndex((optionSelected) => 
             z.id === optionSelected.id && optionSelected.step_id === STEPS_SELECTED[d]?.id); 
  
            if (optionSelectedIndex >= 0) {
              newOptionsSeleted[optionSelectedIndex] = z;
            } else {
              newOptionsSeleted.push(z);
            }
  
          })
        })
      }

      console.table(STEPS_SELECTED)
      console.table(newOptionsSeleted)

      /* -------------------------------------------------------------------------------------------------
       * THE CODE BELOW NOT WORK EN A TINY TIME WITH "onFinished"
       * -------------------------------------------------------------------------------------------------  
       * THE STATE DO NOT HAVE TIME TO UPDATE AND BECAUSE OF STATE UNUPDATED "onFinished" THROW A ERROR OF VALIDATION
       */
      //if(onOptionsSelected && typeof onOptionsSelected === 'function') onOptionsSelected(newOptionsSeleted)

      if(actions.setExterior && typeof actions.setExterior === 'function') actions.setExterior(newOptionsSeleted)
    } catch (error) {
     console.table(error)
    }
  }

  function setToStorageStepSelected(stepTarget: IStep | null){
    try {
      if(!stepSelected) return

      let stepsSelectedRecovery = getAllFromStorageStepsSelected()

      // console.table(stepsSelectedRecovery)

      if(stepsSelectedRecovery && Object.keys(stepsSelectedRecovery).length > 0) {
        stepsSelectedRecovery[actualStepType] = stepTarget
      }else{
        stepsSelectedRecovery = {[actualStepType]: stepTarget}
      }

      sessionStorage.setItem("MCF_BUY@STEPS_SELECTED_EXTERIOR", JSON.stringify(stepsSelectedRecovery))
    } catch (error) {
      console.table(error)
      // do anything
    }
  }

  function getFromStorageStepSelected(type: TStepTargets | null){
    try {
      return (JSON.parse(sessionStorage.getItem("MCF_BUY@STEPS_SELECTED_EXTERIOR") || "{}") as any)[type || 'acabamento'] as IStep | null
    } catch (error) {
      // do anything
    }
  }

  function getAllFromStorageStepsSelected(){
    try {
      return (JSON.parse(sessionStorage.getItem("MCF_BUY@STEPS_SELECTED_EXTERIOR") || "{}") as any) as {[index: string]: IStep | null}
    } catch (error) {
      // do anything
    }
  }

  function deleteAllFromStorageStepsSelected(){
    try {
      sessionStorage.removeItem("MCF_BUY@STEPS_SELECTED_EXTERIOR")
    } catch (error) {
      // do anything
    }
  }

  return (
    <>
      <nav
        data-showmew={showNavigation}
        className="
          w-full 
          h-auto 
          hidden
          md:data-[showmew=true]:flex
          max-sm:hidden
        "
      >
        <ul className='w-full flex flex-row items-center justify-start gap-2 overflow-x-auto'>
          {
            !STEPS_MOUNTED || STEPS_MOUNTED.length <= 0 && (
              <MockStep />
            )
          }
          {
            STEPS_MOUNTED.map(step => (
              <li
                key={step.id}
                role="button"
                tabIndex={-1}
                title={step.slug}
                onClick={() => setActualStepType(step.type as TStepTargets)}
                data-active={actualStepType === step.type}
                className='
                  flex-1
                  min-w-[216px]
                  h-[35px]
                  flex
                  flex-row items-center justify-center
                  list-none
                  px-[12px]
                  pb-[10px]
                  mb-[5px]
                  transition-all
                  duration-[0.3s]
                  border-b-transparent
                  border-b-[3px]
                  text-[#b8b9ba]
                  hover:text-[#414142]
                  active:text-[#414142]
                  cursor-pointer
                  data-[active=true]:cursor-not-allowed
                  data-[active=true]:pointer-events-none
                  data-[active=true]:border-b-[#079269]
                  data-[active=true]:text-[#414142]
                  data-[active=true]:font-semibold
                '
              >
                {step.slug}
              </li>
            ))
          }
        </ul>
      </nav>

      <span
        data-selected={stepSelected && true}
        className='
          hidden
          font-medium
          text-[#707070]
          leading-[26px]
          data-[selected=false]:inline-block
        '
      >
        Nenhum passo selecionado
      </span>

      <div
        className='
          w-full
          h-auto
          flex
          flex-col
          md:flex-row 
          md:items-center 
          md:justify-center
          mt-[48px]
          md-[1728px]:mt-12
        '
      >
        {
          !STEPS_MOUNTED || STEPS_MOUNTED.length <= 0 && (
            <MockImage />
          )
        }
        
        <div
          data-selected={stepSelected && true}
          className='
            w-full
            md:w-1/2 
            h-[205px] 
            hidden
            data-[selected=true]:flex
            flex-row
            items-center
            justify-center 
            px-[12px]
          '
        >
          <span
            className='
              w-2/3
              h-full
              flex
              flex-row
              flex-nowrap
              items-center
              justify-center
              rounded-[10px]
              overflow-hidden
              object-fill
            '
          >
            <img src={stepSelected?.photo || '#'} alt="Imagem representando o passo selecionado" loading="lazy" className='max-w-full h-auto'/>
          </span>
        </div>

        <div
          className='
            md:flex-1
            h-full
            px-12
            flex
            flex-col
            items-center
            justify-center
            gap-y-[12px]
          '
        >
          <span
            className='
              font-medium
              text-[#707070]
              leading-[26px]
            '
          >
            {stepSelected?.question || 'Selecione uma opção abaixo'}
          </span>

          <div
            className='
              w-full
              h-auto
              flex 
              flex-row
              flex-nowrap
              items-center
              md:justify-center
              max-sm:overflow-x-auto
              gap-x-[10px]
              md:gap-x-[48px]
              pb-4
              md:pb-0
            '
          >
            {
              ((stepSelected && stepSelected?.options.length <= 0) || !stepSelected) && (
                <Mock />
              )
            }
            {
              stepSelected?.options.map(option => (
                <button
                  key={option.id}
                  type="button"
                  title={option.label}
                  data-selected={option.selected || false}
                  onClick={() => setOptionSelected(option, true)}
                  className='
                    flex
                    flex-col
                    items-center
                    justify-center
                    gap-[4px]
                    min-w-[77px]
                    md:w-auto
                    h-[77px]
                    p-3
                    rounded-[10px]
                    transition-all
                    duration-[0.3s]
                    text-[11px]
                    text-[#414142]
                    outline-none
                    border-[1px]
                    border-[#e1eee4]
                    data-[selected=false]:hover:bg-[#e9f5f2]
                    data-[selected=false]:hover:border-[#9cd3c3]
                    data-[selected=false]:active:bg-[#e9f5f2]
                    data-[selected=false]:active:border-[#9cd3c3]
                    data-[selected=true]:bg-[#e9f5f2]
                    data-[selected=true]:border-[#9cd3c3]
                    data-[selected=true]:cursor-not-allowed
                    data-[selected=true]:pointer-events-none
                    overflow-hidden
                  '
                >
                  <img src={option.icon || ''} alt="Image representando a opção" loading='lazy' />
                  <span>{option.label}</span>
                </button>
              ))
            }
          </div>
        </div>
      </div>

      {
        !STEPS_MOUNTED || STEPS_MOUNTED.length <= 0 && (
          <MockButton />
        )
      }

      <div
        data-selected={stepSelected && true}
        className='w-full h-14 mt-3 md-[1728px]:pb-16 hidden data-[selected=true]:flex flex-row flex-nowrap items-center justify-end gap-4'>
        <button
          type="button"
          title='Voltar'
          // DO A CAST TO ORIGINAL POSITION
          data-shouldshowme={(actualStepTypeIndex - 1) >=  1}
          disabled={status.onSubmiting}
          className='
            hidden
            data-[shouldshowme=true]:flex 
            w-[62px] 
            h-[54px] 
            rounded-[20px] 
            bg-[#39A887]
            hover:bg-[#339578]
            active:bg-[#339578]
            transition-all
            duration-[0.15s]
            flex-row 
            items-center 
            justify-center
            disabled:pointer-events-none
            disabled:opacity-80
          '
          onClick={() => setActualStepType(STEPS_MOUNTED[(actualStepTypeIndex - 1) - 1].type as TStepTargets)}
        >
          <RiArrowLeftSLine size={24} color="#fff" />
        </button>

        <button
          type="button"
          title='Próximo'
          data-shouldshowme={actualStepTypeIndex >=  STEPS_MOUNTED.length}
          disabled={status.onSubmiting}
          className='
            flex 
            data-[shouldshowme=true]:hidden
            w-full
            md:w-[331px]
            h-[54px]
            rounded-[20px] 
            bg-[#39A887] 
            hover:bg-[#339578]
            active:bg-[#339578]
            transition-all
            duration-[0.15s]
            flex-row 
            items-center 
            justify-center
            text-white
            font-bold
            disabled:pointer-events-none
            disabled:opacity-80
          '
          onClick={() => setActualStepType(STEPS_MOUNTED[actualStepTypeIndex].type as TStepTargets)}
        >
          Próximo
        </button>

        <button
          type="button"
          title='Finalizar'
          data-shouldshowme={actualStepTypeIndex >=  STEPS_MOUNTED.length}
          disabled={status.onSubmiting}
          className='
            hidden
            data-[shouldshowme=true]:flex
            w-full
            md:w-[331px]
            h-[54px]
            rounded-[20px] 
            bg-[#39A887]
            hover:bg-[#339578]
            active:bg-[#339578]
            transition-all
            duration-[0.15s]
            flex-row 
            items-center 
            justify-center
            text-white
            font-bold
            disabled:pointer-events-none
            disabled:opacity-80
          '
          onClick={() => handleOnFinished()}
        >
          <span 
            data-onsubmit={status.onSubmiting}
            className="hidden data-[onsubmit=false]:flex"
          >
            Finalizar
          </span>
          <span 
            data-onsubmit={status.onSubmiting}
            className="hidden data-[onsubmit=true]:flex"
          >
            <CgSpinner className="text-white animate-spin"/>
          </span>
        </button>
      </div>
    </>      
  )
}