/* FIXEM - eslint-disable jsx-a11y/no-static-element-interactions */
/* FIXEM - eslint-disable jsx-a11y/click-events-have-key-events */
/* FIXEM - eslint-disable react/jsx-no-comment-textnodes */
import React, { useCallback, useEffect, useState } from 'react';
import { BsFillHouseFill, BsPlus } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { VscLock } from 'react-icons/vsc';
import { mutate } from 'swr';

// Assets
import avatar from '~/assets/defaults/avatar.png';
import fiftyMilion from '~/assets/defaults/fifty-milion.png';
import fiveMilion from '~/assets/defaults/five-milion.png';
import lineWhite from '~/assets/defaults/line-white.svg';
import line from '~/assets/defaults/line.svg';
import oneHundredMilion from '~/assets/defaults/one-hundred-milion.png';
import tenMilion from '~/assets/defaults/ten-milion.png';
import arrowLeftBlack from '~/assets/icons/arrow_left_black.svg';
import clockBlack from '~/assets/icons/clock_black.svg';
import firstPlace from '~/assets/icons/first-place.svg';
import othersPlace from '~/assets/icons/others-place.svg';
import secondPlace from '~/assets/icons/second-place.svg';
import thirdPlace from '~/assets/icons/third-place.svg';
import time from '~/assets/icons/time.svg';
import timeRed from '~/assets/icons/time_red.svg';
import timeYellow from '~/assets/icons/time_yellow.svg';
import trophyGreen from '~/assets/icons/trophy-green.svg';
import trophy from '~/assets/icons/trophy-white.svg';

// Hooks
import useFetch from '~/hooks/Fetchs/Fetch';

// Services
import api from '~/services/api';

// Models
import { IMAward } from '~/models/Award';
import { IMClassification } from '~/models/Classification';
import { IMTournament } from '~/models/Tournament';

// Partials
import { useAuthContext } from '~/hooks/contexts/Auth';
import AddHistoryPoints from './Partials/AddHistoryPoints';
import { AddPoint } from './Partials/AddPoints';
import { BoxAward } from './Partials/BoxAward';
import { BoxTournament } from './Partials/BoxTournament';

// Styles
import { Container, Content, Header } from './styles';

interface IAwardStatus {
  textColor: string;
  bgColor: string;
  icon: string;
}

const Awards: React.FC = () => {
  const { user } = useAuthContext();
  const [tournament, setTournament] = useState<IMTournament | null>(null);
  const [classifications, setClassifications] = useState<IMClassification[]>(
    []
  );
  const [accumulatedPoints, setAccumulatedPoints] = useState<string | number>(
    '0'
  );
  const [pendingApprovals, setPendingApprovals] = useState<string | number>(
    '0'
  );
  const [openOptionsDialog, setOpenOptionsDialog] = useState<boolean>(false);
  const [openHistoryPointDialog, setOpenHistoryPointDialog] = useState<boolean>(false);
  const [openAwardDialog, setOpenAwardDialog] = useState<boolean>(false);
  const [openTermDialog, setOpenTermDialog] = useState<boolean>(false);
  const [hasUserClassified, setHasUserClassified] = useState<IMClassification | null>(null);
  const [selectedAward, setSelectedAward] = useState<IMAward | null>(null);
  const [awardStatus, setAwardStatus] = useState<IAwardStatus>(
    {} as IAwardStatus
  );
  const { data: approvalsAndAccumulatedDATA, url: ApprovalsAndAccumulatedURL } = useFetch<{
    accumulatedTotal: string | number;
    waitingForApproval: number;
  }>({
    url: 'builders/purchased-products/total',
    forward: {
      accumulatedTotal: (data) => Number(data.accumulatedTotal).toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      }),
      waitingForApproval: (data) => Number(data.waitingForApproval).toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      }),
    },
  });

  // AUX Variables
  const ICONS_PLACES = [firstPlace, secondPlace, thirdPlace];

  const handleApprovalsAndAccumulated = async () => {
    mutate(ApprovalsAndAccumulatedURL);
  };

  const handleMountClassification = useCallback(async () => {
    if (!tournament) return;

    const responseClassifications = await api.get<IMClassification[]>(
      `builders/tournaments/${tournament.id}/ranking`
    );

    if (!responseClassifications) return;

    // TODO - CARREGAR A CLASSIFICAÇÃO DE USUÀRIOS VIA ROTA
    const classificationsList = responseClassifications.data?.map((classification: IMClassification) => ({
      ...classification,
      point: Number(classification.point).toLocaleString('pt-BR', {
        maximumFractionDigits: 20,
        minimumFractionDigits: 0,
      }),
      image: ICONS_PLACES[classification.position - 1] ?? othersPlace,
    })) ?? [];

    // THE CODE BELOW INFER ACTUAL POSITION OF USER
    responseClassifications.data.every((classification) => {
      if (classification?.user_id === user!.id) {
        setHasUserClassified(classification);
        return false;
      }

      return true;
    });

    setClassifications(classificationsList);

    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament]);

  const handleCloseOptionsDiaolog = useCallback(
    () => setOpenOptionsDialog(false),
    []
  );

  const handleOpenOptionsDiaolog = useCallback(
    () => setOpenOptionsDialog(true),
    []
  );

  const handleCloseAwardDiaolog = useCallback(
    () => setOpenAwardDialog(false),
    []
  );

  const handleCloseTermsTournamentDiaolog = useCallback(
    () => setOpenTermDialog(false),
    []
  );

  const handleOpenHistoryPointDialog = useCallback(
    () => setOpenHistoryPointDialog(true),
    []
  );

  const handleCloseHistoryPointDialog = useCallback(
    () => setOpenHistoryPointDialog(false),
    []
  );

  const handleOpenAwardDiaolog = useCallback((award: IMAward) => {
    if (award) {
      setSelectedAward(award);
      setOpenAwardDialog(true);
    }
  }, []);

  useEffect(() => {
    setAccumulatedPoints(approvalsAndAccumulatedDATA?.accumulatedTotal || 0);
    setPendingApprovals(approvalsAndAccumulatedDATA?.waitingForApproval || 0);
  }, [approvalsAndAccumulatedDATA]);

  useEffect(() => {
    if (tournament) handleMountClassification();
  }, [tournament, handleMountClassification]);

  useEffect(() => {
    api.get<IMTournament>('builders/tournaments/').then(async (response) => {
      if (!response.data?.id) return;

      setTournament(response.data);
    });
  }, [user]);

  useEffect(() => {
    handleApprovalsAndAccumulated();
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament]);

  useEffect(() => {
    if (!tournament) return;

    if (tournament.daysToStart >= 0 && tournament.daysToEnd >= 0) {
      setAwardStatus({
        textColor: '#1E8365',
        bgColor: '#D1F4EA',
        icon: time,
      });
    } else if (
      tournament.daysToStart >= 0
      && tournament.daysToEnd >= 10
      && tournament.daysToEnd <= 10
    ) {
      setAwardStatus({
        textColor: '#B4851F',
        bgColor: '#FFEEC8',
        icon: timeYellow,
      });
    } else if (tournament.daysToStart < 0 ?? tournament.daysToEnd < 0) {
      setAwardStatus({
        textColor: '#AB3F3F',
        bgColor: '#F6E1E0',
        icon: timeRed,
      });
    }
  }, [tournament]);

  return (
    <>
      <div className="d-flex justify-content-end pt-5 pt-lg-0">
        <Container>
          <div className="container">
            <Header className="d-flex align-items-center justify-content-between mt-4 pe-lg-5">
              <div className="d-flex flex-column flex-lg-row align-lg-items-center w-100 w-lg-auto mt-3 mt-lg-0">
                <h1 className="h3 mb-0">Premiações</h1>
                <button
                  type="button"
                  className="btn btn-primary rounded-pill d-flex align-items-center justify-content-center px-3 ms-lg-3 mt-4 mt-lg-0"
                  onClick={handleOpenOptionsDiaolog}
                >
                  <BsPlus size={32} color="#fff" />
                  <span className="font-[500]">Cadastrar pontos</span>
                </button>
              </div>
              <div className="d-none d-lg-flex">
                <img
                  src={avatar}
                  alt="avatar"
                  className="avatar rounded-circle align-self-center"
                />
                <div className="mx-2">
                  <p className="name">{user!.name}</p>
                  <p className="job">
                    {user!.isAdmin ? 'Admin' : 'Construtor'}
                  </p>
                </div>
                <IoIosArrowDown size={16} color="#707070" className="mt-1" />
              </div>
            </Header>
            <Content className="my-5">
              <div className="row">
                <div className="col-lg-8 order-0 mb-4 mb-lg-0">
                  <div className="banner p-3">
                    <div className="row">
                      <div className="col-lg-5 d-flex flex-column flex-lg-row align-items-lg-center">
                        <img
                          src={trophy}
                          alt="trophy"
                          className="w-lg-25 me-lg-2 my-3 my-lg-0 trophy"
                        />
                        <h2 className="h3 h2-lg">
                          Concorra a<br className="d-block d-lg-none" /> grandes
                          prêmios
                        </h2>
                      </div>
                      <div className="col-lg-7">
                        <h3 className="h6 h3-lg fw-medium">
                          Tenha toda sua trajetória registrada
                        </h3>
                        <div className="d-flex align-items-center my-3 mt-lg-0">
                          <BsFillHouseFill size={20} className="me-2" />
                          <p className="mb-0 small h6 fw-normal">
                            Receba em sua casa
                          </p>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-3 d-flex flex-column align-items-center">
                              <img
                                src={oneHundredMilion}
                                alt="oneHundredMilion"
                                className="w-100"
                              />
                              <div className="ball mt-3" />
                            </div>
                            <div className="col-3 d-flex flex-column align-items-center">
                              <img
                                src={fiftyMilion}
                                alt="fiftyMilion"
                                className="w-100"
                              />
                              <div className="ball mt-3" />
                            </div>
                            <div className="col-3 d-flex flex-column align-items-center">
                              <img
                                src={tenMilion}
                                alt="tenMilion"
                                className="w-100"
                              />
                              <div className="ball mt-3" />
                            </div>
                            <div className="col-3 d-flex flex-column align-items-center">
                              <img
                                src={fiveMilion}
                                alt="fiveMilion"
                                className="w-100"
                              />
                              <div className="ball mt-3" />
                            </div>
                          </div>
                          <div className="line align-items-center">
                            <img
                              src={line}
                              alt="line"
                              className="d-none d-block"
                            />
                            <img
                              src={lineWhite}
                              alt="line"
                              className="d-block d-lg-none"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0">
                  <div className="box p-4">
                    <div className="d-flex align-items-center my-3 align-items-center">
                      <img
                        src={trophyGreen}
                        alt="trophyGreen"
                        className="me-2"
                      />
                      <h2 className="h4 fw-medium mb-0">Prêmios</h2>
                    </div>

                    {!tournament && (
                      <>
                        <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] d-flex mb-4" />
                        <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] d-flex mb-4" />
                        <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] d-flex mb-4" />
                      </>
                    )}

                    {tournament
                      && tournament?.awards?.slice(0, 3).map((award, index) => (
                        <div
                          className="d-flex align-items-center my-3 cursor-pointer"
                          key={index}
                          title={award.title}
                          onClick={() => handleOpenAwardDiaolog(award)}
                        >
                          <div className="d-flex align-items-center">
                            <p className="mb-0">
                              <b>{award.position}º</b>
                            </p>
                            <img
                              src={
                                ICONS_PLACES[award.position - 1] ?? othersPlace
                              }
                              alt={`${award.position}ª lugar`}
                              className="award ms-2"
                            />
                          </div>
                          <p className="mb-0 ms-3">{award.description}</p>
                        </div>
                      ))}

                    {tournament && tournament.awards.length <= 0 && (
                      <p>Nenhum prêmio disponível</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-8 order-1 order-lg-0 mt-4">
                  <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between mb-4 mb-lg-0">
                    <h2 className="h2 fw-bold">Classificação</h2>
                    <div className="d-flex align-items-center mt-3 mt-lg-0" />
                  </div>
                  <div className="box table-data p-3">
                    <div className="d-flex header">
                      <div>
                        <p>Colocação</p>
                      </div>
                      <div>
                        <p>Nome</p>
                      </div>
                      <div>
                        <p>Pontos</p>
                      </div>
                    </div>
                    <div className="body position-relative h-auto max-h-[33rem]">
                      {!tournament && (
                        <>
                          <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] mb-4" />
                          <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] mb-4" />
                          <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] mb-4" />
                          <div className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] mb-4" />
                        </>
                      )}

                      {classifications?.map((classification) => (
                        <div
                          key={classification.id}
                          className="d-flex align-items-center"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={classification.image}
                              alt="firstPlace"
                              className="me-2"
                            />
                            <p className="mb-0">{classification.position}º</p>
                          </div>
                          <div className="d-flex align-items-center max-w-[73%]">
                            <p className="mb-0">{classification.name}</p>
                          </div>
                          <div className="d-flex flex-row flex-nowrap justify-center gap-1 align-items-center">
                            <b>{classification.point}</b>
                            <small>pnts</small>
                          </div>
                        </div>
                      ))}

                      {classifications.length <= 0 && (
                        <div>Nenhuma classificação disponível</div>
                      )}

                      {hasUserClassified && (
                        <div
                          className="d-flex align-items-cente my-position "
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                ICONS_PLACES[hasUserClassified.position - 1]
                                || othersPlace
                              }
                              alt="my place"
                              className="me-2"
                            />
                            <p className="mb-0">
                              {hasUserClassified.position}º
                            </p>
                          </div>
                          <div className="d-flex align-items-center max-w-[73%]">
                            <p className="mb-0">{user!.name}</p>
                          </div>
                          <div className="d-flex flex-row flex-nowrap justify-center gap-1 align-items-center">
                            <b>{hasUserClassified.point}</b>
                            <small>pnts</small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0 mt-4">
                  <div className="box p-4 mb-4">
                    <div className="d-flex flex-col my-3 ">
                      {!tournament && (
                        <>
                          <span className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem] mb-4" />
                          <span className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem]" />
                          <span className="bg-gray-200 animate-pulse w-full h-[1.875rem] rounded-[0.25rem]" />
                        </>
                      )}
                      <h2 className="h4 fw-medium mb-[1.13rem]">
                        {tournament?.name}
                      </h2>

                      {tournament && (
                        <div
                          className={`w-full h-[1.875rem] rounded-[0.25rem] bg-[${awardStatus.bgColor} d-flex flex-row flex-nowrap items-center gap-1 text-[${awardStatus.textColor}] pl-[0.37rem] pr-[0.37rem]`}
                          style={{
                            backgroundColor: awardStatus.bgColor,
                            color: awardStatus.textColor,
                          }}
                        >
                          <img src={awardStatus.icon} alt="" />
                          {tournament && (
                            <>
                              <span>
                                de:{' '}
                                {new Date(
                                  `${tournament?.start_date}T00:00:00`
                                ).toLocaleDateString('pt-BR')}
                              </span>
                              <span>
                                até:{' '}
                                {new Date(
                                  `${tournament?.end_date}T00:00:00`
                                ).toLocaleDateString('pt-BR')}
                              </span>
                            </>
                          )}
                        </div>
                      )}

                      {tournament && (
                        <span
                          className="mt-2 font-semibold cursor-pointer"
                          title="Visualizar o regulamento"
                          onClick={() => setOpenTermDialog(true)}
                        >
                          ver regulamento
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="box-green mb-4">
                    <p className="mb-2">Total de pontos acumulados</p>
                    <p className="h4">{accumulatedPoints}</p>
                  </div>

                  <div
                    onClick={handleOpenHistoryPointDialog}
                    className="box cursor-pointer pl-[1.56rem] pr-[1.75rem] pb-[1.62rem] pt-[1.62rem]  flex flex-row flex-nowrap items-center justify-between mb-4"
                  >
                    <div className="flex flex-row items-center gap-2">
                      <img src={clockBlack} alt="" />
                      <h1 className="font-semibold">Histórico de pontos</h1>
                    </div>
                    <img src={arrowLeftBlack} alt="" />
                  </div>

                  <div className="box">
                    <div className="points p-4">
                      <div className="px-4 py-2 mb-3">
                        <p className="mb-2">Seu total de pontos</p>
                        <p className="h4">{tournament?.totalPoints ?? 0}</p>
                      </div>
                      <div className="px-4 py-2">
                        <p className="mb-2 d-flex align-items-center">
                          Aprovações pendentes{' '}
                          <VscLock size={16} color="#000" className="ms-2" />
                        </p>
                        <p className="h4">{pendingApprovals}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </div>
        </Container>
      </div>
      {tournament && (
        <BoxAward
          award={selectedAward}
          onHide={handleCloseAwardDiaolog}
          show={openAwardDialog}
          tournament_id={tournament.id}
        />
      )}

      {tournament && (
        <BoxTournament
          tournament={tournament}
          onHide={handleCloseTermsTournamentDiaolog}
          show={openTermDialog}
        />
      )}

      {tournament && (
        <AddPoint
          onHide={handleCloseOptionsDiaolog}
          show={openOptionsDialog}
          callback={handleApprovalsAndAccumulated}
          tournament_id={tournament.id}
        />
      )}

      {tournament && (
        <AddHistoryPoints
          onHide={handleCloseHistoryPointDialog}
          show={openHistoryPointDialog}
          tournament_id={tournament.id}
        />
      )}
    </>
  );
};

export default Awards;
