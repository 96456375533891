import React, { useCallback } from 'react';
import { BsImage } from 'react-icons/bs';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { useProfile } from '~/hooks/Profile';
import { ImageItem, SectionAddImage } from './styles';

const Portfolio: React.FC = () => {
  const { setLoading, engineer, setEngineer } = useProfile();

  const onSelectFile = useCallback(
    async (event) => {

      if(!event?.target?.files || event?.target?.files?.length <= 0) return;

      if (event.target.files[0].size < 2097152) {
        setLoading(true);
        const formImage = new FormData();
        formImage.append('file', event.target.files[0]);

        await api
          .post(
            `${process.env.REACT_APP_PREFIX_ROUTE}/galleries/${engineer.id}`,
            formImage
          )
          .then((response) => {
            const gallery = engineer?.gallery
              ? [...engineer.gallery, response.data]
              : [response.data];
            setEngineer({
              ...engineer,
              gallery,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'O tamanho da imagem deve ser inferior a 2 megabytes',
        });
      }
    },
    [engineer, setEngineer, setLoading]
  );

  const handleDelete = useCallback(
    async (image) => {
      setLoading(true);
      await api.delete(
        `${process.env.REACT_APP_PREFIX_ROUTE}/galleries/${engineer.id}/${image.id}`
      );
      const gallery = engineer?.gallery?.filter((item) => item.id !== image.id);
      setEngineer({
        ...engineer,
        gallery,
      });
      setLoading(false);
    },
    [engineer, setEngineer, setLoading]
  );

  return (
    <>
      <div className="row folder mt-4 mx-0">
        <div className="col-12">
          <SectionAddImage>
            <section className="section">
              <div className="row">
                <div className="col-6 px-2">
                  <p className="h5 ps-1 px-lg-5 text-title ">Meus projetos</p>
                </div>
              </div>
              <div className="col-auto col-lg-6 mb-3 px-lg-5">
                <span>
                  Número de fotos : <b>{engineer?.gallery?.length}</b>
                </span>
              </div>
              <hr className="mx-5" />

              <div className="row align-items-center mx-lg-5">
                <div className="col-auto images">
                  <label className="label border w-100 px-4">
                    <BsImage size={70} color="#b8b9ba" className="icon" />
                    <input
                      type="file"
                      className="hola"
                      multiple
                      accept="image/*"
                      onChange={onSelectFile}
                    />
                    <div className="d-flex justify-content-center mt-4">
                      <span>Add imagens</span>
                    </div>
                  </label>
                </div>
                {engineer?.gallery
                  && engineer?.gallery
                    .map((image) => (
                      <div className="col-lg-3 mb-3 ancho" key={image.id}>
                        <ImageItem
                          src={`${process.env.REACT_APP_API_URL}/builders/galleries/${engineer.id}/${image.id}`}
                          className="image"
                        >
                          <button
                            type="button"
                            className="buttonDelete"
                            onClick={() => handleDelete(image)}
                          >
                            ✖
                          </button>
                        </ImageItem>
                      </div>
                    ))
                    .reverse()}
              </div>
            </section>
          </SectionAddImage>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
