import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Select, { IOption } from '~/components/Select';
import api from '~/services/api';
import getValidationErrors from '~/utils/getValidationsErrors';
import { useProfile } from '~/hooks/Profile';
import Toast from '~/utils/toast';
import { IMMunicipalEngenieers } from '~/models/MinicipalEngenieers';

interface IState {
  id: number;
  nome: string;
  code: number;
}

interface IMunicipality {
  id: number;
  name: string;
}

const FormCities: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const {
    setLoading, construtech, setEngineer, engineer
  } = useProfile();
  const [municipalities, setMunicipalities] = useState<IOption[]>([]);
  const [states, setStates] = useState<IOption[]>([]);
  const [stateSelected, setStateSelected] = useState({} as IState);
  const [stateSearch, setStateSearch] = useState('');
  const [citySearch, setCitySearch] = useState('');

  const handleLoadState = useCallback((value?: string) => {
    api
      .get<IState[]>('builders/states/', {
        params: {
          search: value,
        },
      })
      .then((response) => {
        const data: IOption[] = response.data.map((state) => ({
          id: state.id,
          value: state.nome,
          selected: false,
        }));
        setStates(data);
      });
  }, []);

  useEffect(() => {
    handleLoadState();
  }, [handleLoadState]);

  const handleSearchState = useCallback(
    (state: string) => {
      setStateSearch(state);
    },
    [setStateSearch]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleLoadState(stateSearch);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [handleLoadState, stateSearch]);

  const handleChangeState = useCallback(
    (state) => {
      setStateSelected(state);
    },
    [setStateSelected]
  );

  const handleLoadCity = useCallback(
    async (city?: string) => {
      if (stateSelected.id) {
        const response = await api.get<IMunicipality[]>(
          `municipalities/state/${stateSelected.id}`,
          {
            params: {
              search: city,
            },
          }
        );

        const data: IOption[] = response.data.map((municipalityData) => ({
          id: municipalityData.id,
          value: municipalityData.name,
          selected: false,
        }));

        setMunicipalities(data);
      }
    },
    [stateSelected, setMunicipalities]
  );

  const handleSearchCity = useCallback(
    (state: string) => {
      setCitySearch(state);
    },
    [setCitySearch]
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleLoadCity(citySearch);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [handleLoadCity, citySearch]);

  const handleSubmit = useCallback(
    async (data: IMMunicipalEngenieers) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          estado_id: Yup.string().required('Estado obrigatório'),
          municipio_id: Yup.string().required('Cidade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const municipality = municipalities.find(
          (municipalityData) => String(municipalityData.id) === String(data.municipio_id)
        );

        const formaData = {
          estado_id: data.estado_id,
          municipio_id: data.municipio_id,
          municipio: municipality?.value,
        };

        await api
          .post<IMMunicipalEngenieers[]>(
            `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/minicipalities`,
            formaData
          )
          .then((response) => {
            const { data: responseData } = response;
            setEngineer({
              ...engineer,
              municipios: responseData,
            });
            setCitySearch('');
          });

        Toast.fire({
          icon: 'success',
          title: !construtech
            ? 'Cadastro realizado com sucesso!'
            : 'Atualização de cadastro realizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, construtech, engineer, municipalities, setEngineer]
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div>
        <div className="row">
          <div className="col-auto col-sm-5 text-subtitle">
            <Select
              name="estado_id"
              placeholder="Selecione"
              className="input mt-2"
              options={states}
              onChange={handleChangeState}
              onChangeText={handleSearchState}
            />
          </div>
          <div className="col-auto col-sm-5 text-subtitle">
            <Select
              name="municipio_id"
              placeholder="Selecione"
              className="input mt-2"
              options={municipalities}
              onChangeText={handleSearchCity}
            />
          </div>
          <div className="col-2 text-subtitle">
            <button
              type="submit"
              className="d-block btn-save fw-semibold border-0 mx-auto ms-lg-auto me-lg-5"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FormCities;
