import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';

import EditTable from '~/assets/icons/edit-table.svg';
import { useDefaultData } from '~/hooks/DefaultData';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import { getSuggestedFootage } from '~/utils/serviceOrderCalculations';
import { Container } from './styles';

interface IDataBudgetE {
  quantity: IQuantityData[];
  data: IMBudgetRoom[];
}
interface IQuantityData {
  cant: number;
  amb: string;
}

const PropertyEnvironments: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();
  const { ambientes } = useDefaultData();
  const [metreage, setMetreage] = useState(0);
  const [orc_ambiente, setAmbientes] = useState<IMBudgetRoom[]>([]);
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );

  useEffect(() => {
    if (serviceOrder?.id) {
      api
        .get<IDataBudgetE>(
          `/builders/service-orders/${serviceOrder?.id}/budget/environments/get-quantities`
        )
        .then((array) => {
          const response: IQuantityData[] = array.data.quantity.map(
            (element) => ({
              cant: element.cant,
              amb: element.amb,
            })
          );
          setQuantityAmbientes(response);
        });
    }
  }, [serviceOrder?.id]);

  useEffect(() => {
    setMetreage(getSuggestedFootage(serviceOrder));
    setAmbientes(serviceOrder?.orc_ambientes || []);
  }, [serviceOrder]);

  const handleClickEditar = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/monte-sua-casa/${serviceOrder.id}`);
  }, [history, serviceOrder.id]);

  return (
    <Container className="container col-12 card-dashboard justify-content-between p-4 mb-3">
      <div className="row container">
        <div className="col-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="title h4 mb-0">Ambientes do Imóvel</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-0">
            <div className="description">Tamanho do m² estimado da casa.</div>
          </div>
          <div className="description">
            Acabamento escolhido:
            <span>
              {serviceOrder.orc_parametros?.acabamento?.tipo_acabamento}
            </span>
          </div>
        </div>
        <div className="d-flex subtitle align-items-center justify-content-end mb-4 col-6 row d-flex justify-content-end align-content-around">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="d-flex align-items-center btn py-0"
              onClick={handleClickEditar}
            >
              <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
            </button>
          </div>
          <div className="d-flex justify-content-end">
            <h3 className="mb-0 font-weight-700 ms-auto">
              <div className="mt-3">
                {typeof metreage === 'number' ? (
                  <b style={{ color: '#707070' }}>{metreage.toFixed(2)} m²</b>
                ) : (
                  <b style={{ color: '#707070' }}>indisponivel</b>
                )}
              </div>
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(ambientes.length / 2) >= index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center ms-3">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux, MapIndex) => (
                          <Fragment key={MapIndex}>
                            {aux.ambiente_tamanho.tamanho_id === 1
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div 
                                    className="col profile-icon2 d-flex justify-content-center align-items-center bg-g"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1
                                        && 'G'}
                                    </p>
                                  </div>
                                </div>
                            )}
                            {aux.ambiente_tamanho.tamanho_id === 2
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div 
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-m"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2
                                        && 'M'}
                                    </p>
                                  </div>
                                </div>
                            )}
                            {aux.ambiente_tamanho.tamanho_id === 3
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-p"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3
                                        && 'P'}
                                    </p>
                                  </div>
                                </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(ambientes.length / 2) < index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center ms-3">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux, ambientIndex) => (
                          <Fragment key={ambientIndex}>
                            {aux.ambiente_tamanho.tamanho_id === 1
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div 
                                    className="col profile-icon2 d-flex justify-content-center align-items-center bg-g"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1
                                        && 'G'}
                                    </p>
                                  </div>
                                </div>
                            )}
                            {aux.ambiente_tamanho.tamanho_id === 2
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div 
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-m"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2
                                        && 'M'}
                                    </p>
                                  </div>
                                </div>
                            )}
                            {aux.ambiente_tamanho.tamanho_id === 3
                              && aux.ambiente_tamanho.ambiente_id
                                === index + 1 && (
                                <div className="col-2">
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-p"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3
                                        && 'P'}
                                    </p>
                                  </div>
                                </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PropertyEnvironments;
