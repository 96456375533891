import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import { Container } from 'react-bootstrap';
import TextArea from '~/components/Textarea';
import Input from '~/components/Input';
import Loading from '~/components/Loading';

import api from '~/services/api';

import getValidationErros from '~/utils/getValidationsErrors';
import { useValidError } from '~/hooks/ValidError';

import attachment from '~/assets/defaults/attachment.svg';

import UserDefault from '~/components/UserDefault';
import Toast from '~/utils/toast';
import { useProfile } from '~/hooks/Profile';
import { Modal, GeneralContainer } from './styles';
import Card from './Components/Card';

interface IPostsResponse {
  id: number;
  title: string;
  description: string;
  url: string;
  file?: string;
}

const Marketing: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { validateEngineerData } = useProfile();
  const { validError } = useValidError();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [posts, setPosts] = useState<IPostsResponse[]>([]);
  const [imageSelected, setImageSelected] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState('');

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const loadPosts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get<IPostsResponse[]>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/tools`
      );

      setPosts(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  const handleClose = useCallback(() => {
    setShow(false);
    setThumbnail('');
    setImageSelected(null);
  }, []);

  const handleAddSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const formData = new FormData();
        const schema = Yup.object().shape({
          description: Yup.string().required('A descrição é obrigatória'),
          file: Yup.string().when('$exist', {
            is: (hasUpload: boolean) => hasUpload,
            then: Yup.string(),
            otherwise: Yup.string().required('A imagem é obrigatória'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            hasUpload: !!imageSelected,
          },
        });

        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('url', data.url);
        formData.append('file', imageSelected as File);
        await api.post(`${process.env.REACT_APP_PREFIX_ROUTE}/tools`, formData);

        loadPosts();

        setShow(false);

        Toast.fire({
          icon: 'success',
          title: 'Post inserido com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(Error(String(error)));
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [imageSelected, validError, loadPosts]
  );

  const handleSelectImage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const image = e.target.files[0];

      if (image) {
        setThumbnail(URL.createObjectURL(image));
        setImageSelected(image);
      } else {
        setThumbnail('');
        setImageSelected(null);
      }
    }
  }, []);

  // const handleRedirectFluxo = () => {
  //   history.push('/ferramentas/fluxo-de-acoes');
  // };

  return (
    <div>
      <GeneralContainer className="ms-lg-5 mb-3">
        <div className="py-4" />
        <div className="mt-5 mt-lg-0">
          <div className="container mt-5 mt-lg-0">
            <UserDefault ferramentas />
          </div>
        </div>
        <Container className="w-100 px-lg-3 mt-5">
          <div className="d-flex justify-content-center flex-wrap">
            {posts ? (
              <>
                {posts.map((post, i) => (
                  <Card
                    key={i}
                    id={post.id}
                    title={post.title}
                    description={post.description}
                    url={post.url}
                  />
                ))}
              </>
            ) : (
              <div className="w-100 px-5 py-2">
                Nenhuma postagem encontrada.
              </div>
            )}
          </div>
        </Container>
      </GeneralContainer>

      <Modal show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleAddSubmit}>
          <Modal.Header>
            <div className="w-100 md:w-8/12">
              <h4 className="color-onyx font-semibold mb-5 text-left">
                Novo Post
              </h4>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-wrap">
              <div className="w-100 px-3 mb-3">
                <label htmlFor="title">Título</label>

                <Input name="title" className="input" placeholder="Digite" />
              </div>
              <div className="w-100 px-3 mb-3">
                <label htmlFor="url">URL</label>

                <Input name="url" className="input" placeholder="Digite" />
              </div>
              <div className="w-100 px-3 mb-3">
                <label htmlFor="description">Descrição</label>
                <TextArea
                  name="description"
                  className="input"
                  rows={2}
                  placeholder="Digite"
                />
              </div>
              <div className="py-3 px-3 w-100">
                <label htmlFor="image">Imagem</label>
                {!thumbnail ? (
                  <label
                    htmlFor="file"
                    className="upload-file mt-4 justify-content-center"
                  >
                    <span className="mx-2 text-gray">
                      <div className="text-center py-3">
                        <img
                          src={attachment}
                          alt="attachment"
                          className="my-0"
                        />
                        <p className="mb-0 h6 mt-5">Suba seu arquivo aqui.</p>
                        <p className="font-bold underline text-file">
                          Procurar arquivo
                        </p>
                      </div>
                    </span>
                  </label>
                ) : (
                  <label
                    htmlFor="file"
                    className="file-bg upload-file-bg mt-4 justify-content-center"
                    style={{
                      backgroundImage: `url(${thumbnail})`,
                    }}
                  />
                )}
                <Input
                  onChange={handleSelectImage}
                  type="file"
                  name="file"
                  id="file"
                  className="d-none"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <div className="d-flex flex-wrap justify-content-end">
              <div className="w-full md:w-3/12 px-4 order-0 md:order-1 my-1 md:my-0">
                <button
                  type="submit"
                  className="btn-submit rounded-pill w-100 py-2 px-5 border-0"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading active={loading} />
    </div>
  );
};

export default Marketing;
