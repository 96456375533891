import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import Card from './components/Card';
import Slider from './components/Slider';

import { Container, ContainerOut } from './styles';

interface IProjeteiSuggestionProps {
  osId: number;
}

interface IDataBudgetE {
  quantity: IQuantityData[];
}

interface IQuantityData {
  cant: number;
  amb: string;
}

interface IProject {
  projectID: number;
  bathroomsNumber: number;
  suitesNumber: number;
  bedroomsNumber: number;
  garagesNumber: number;
  medias: IMedias[];
  link: string;
}

interface IMedias {
  source: string;
  title: string;
  description: string;
}

interface IResponseProject {
  results: IProject[];
}

const ProjeteiSuggestion: React.FC<IProjeteiSuggestionProps> = ({ osId }) => {
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    api
      .get<IDataBudgetE>(
        `/builders/service-orders/${osId}/budget/environments/get-quantities`
      )
      .then((array) => {
        const response: IQuantityData[] = array.data.quantity.map(
          (element) => ({
            cant: element.cant,
            amb: element.amb,
          })
        );
        setQuantityAmbientes(response);
      });
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quantityAmbientes[1]?.cant && quantityAmbientes[0]?.cant) {
      fetch(
        `https://buscador.123projetei.com/architecturalProjects?limit=10&skip=0&bathroomsNumber=${quantityAmbientes[1]?.cant}` +
          `&bedroomsNumber=${quantityAmbientes[0]?.cant}&garagesNumber=${
            quantityAmbientes[10]?.cant ?? 1
          }`
      )
        .then(async (response) => {
          const results: IResponseProject = await response.json();
          if (results?.results) {
            const projeteiData: IProject[] = results?.results;
            setProjects(projeteiData);
          }
        })
        .catch((_rejects) => ({}));
    }
  }, [quantityAmbientes]);

  return (
    <ContainerOut>
      <Container className="container card-dashboard col-md-12 col-11 p-5 pb-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="h6 mb-0">Projetos com base no seu resultado</h2>
        </div>
        <Slider>
          {projects.length > 0 &&
            projects.map((item) => (
              <Card
                bathroomsNumber={item.bathroomsNumber}
                bedroomsNumber={item.bedroomsNumber}
                garagesNumber={item.garagesNumber}
                medias={item.medias}
                projectID={item.projectID}
                suitesNumber={item.suitesNumber}
                key={item.projectID}
                linkRedirect={item.link}
              />
            ))}
        </Slider>
        <div className="pb-5" />
      </Container>
    </ContainerOut>
  );
};

export default ProjeteiSuggestion;
