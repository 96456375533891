import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { darken } from 'polished';

interface IBoxTagProps {
  color: string;
}

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 16px;
    border: none;
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .scroll {
    overflow-y: scroll;
    height: 100px;
    overflow-wrap: break-word;
  }

  .input-date {
    .calendar {
      top: 52px;

      .DayPicker-wrapper {
        background-color: #f9f9f9;

        .DayPicker-NavBar {
          .DayPicker-NavButton {
            left: unset;
          }

          .DayPicker-NavButton--prev {
            left: 15px;
          }

          .DayPicker-NavButton--next {
            right: 15px;
          }
        }

        .DayPicker-Months {
          .DayPicker-Month {
            border-radius: 10px;
          }

          .DayPicker-Caption {
            div {
              color: #444;
            }
          }

          .DayPicker-Day--available:not(.DayPicker-Day--outside) {
            background-color: #e4e4e4;
            transition-duration: 0.3s;

            :hover {
              background-color: ${darken(0.1, '#e4e4e4')};
            }
          }

          .DayPicker-Day--selected {
            background-color: ${darken(0.1, '#e4e4e4')} !important;
          }
        }

        .DayPicker-wrapper {
          min-height: 370px;
        }
      }
    }
  }

  .btn-submit-form {
    background: #39a887;
    border-radius: 20px;
    color: #ffffff;
  }

  .value {
    color: #b8b9ba;
  }
`;

export const BoxTag = styled.div<IBoxTagProps>`
  background-color: ${(props) => props.color}0a;
  width: max-content !important;
  margin: 5px 14px 5px 0;

  ::before {
    content: '';
    background-color: ${(props) => props.color};
    height: 13px;
    width: 13px;
    border-radius: 14px;
  }
`;
