import React from 'react';

import { Container } from './styles';

interface IProps {
  step: number;
  setStep(step: number): void;
}

const TabButtons: React.FC<IProps> = ({ step, setStep }) => (
  <Container>
    {step === 1 && (
    <div className="row ps-4">
      <div className="col-auto text-8 px-3 mb-3">
        <button type="button" className="fondo4">
          Pendentes
        </button>
      </div>
      <div className="col-auto text-9 px-3 mb-3">
        <button type="button" className="fondo5" onClick={() => setStep(2)}>
          Pagas
        </button>
      </div>
    </div>
    )}
    {step === 2 && (
    <div className="row ps-4">
      <div className="col-auto text-8 px-3 mb-3">
        <button type="button" className="fondo5" onClick={() => setStep(1)}>
          Pendentes
        </button>
      </div>
      <div className="col-auto text-9 px-3 mb-3">
        <button type="button" className="fondo4">
          Pagas
        </button>
      </div>
    </div>
    )}
  </Container>
);

export default TabButtons;
