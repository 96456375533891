import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';

import { Modal } from '../../../styles';

interface IModalPropsData {
  id: number;
  nome: string;
  documento: string;
  profissao: string;
}

interface IModalNameProps {
  show: boolean;
  profileValues: IModalPropsData;
  onHide: () => void;
}

const ModalName: React.FC<IModalNameProps> = ({
  show,
  profileValues,
  onHide,
}) => {
  const history = useHistory();
  const formAdd = useRef<FormHandles>(null);
  const [error, setError] = useState('');

  const handleClose = useCallback(() => {
    setError('');
    onHide();
  }, [onHide]);

  const onSubmit = useCallback(
    async (data: IModalPropsData) => {
      const { nome } = data;

      if (nome !== '') {
        if (data.nome.trim().split(' ').length > 1) {
          const formData = {
            nome,
            documento:
              profileValues.documento !== null ? profileValues.documento : '.',
            profissao:
              profileValues.profissao !== null ? profileValues.profissao : '.',
          };

          try {
            api.put(`builders/customers/${profileValues.id}`, formData);
            history.push(`${process.env.PUBLIC_URL}/solicitacoes`);

            handleClose();
          } catch (err) {
            if (err instanceof Yup.ValidationError) {
              const errors = getValidationErros(err);
              formAdd.current?.setErrors(errors);
            } else {
              Swal.fire(
                'Opss...',
                'Erro ao tentar atualizar nome de usuário',
                'error'
              );
            }
          }
        } else {
          setError('Digite seu nome completo');
        }
      } else {
        setError('Digite seu nome');
      }
    },
    [
      handleClose,
      history,
      profileValues.documento,
      profileValues.id,
      profileValues.profissao,
    ]
  );
  return (
    <>
      <Modal show={show} onHide={handleClose} close>
        <Form
          className="px-3 py-5 px-md-5"
          initialData={profileValues}
          onSubmit={onSubmit}
        >
          <Modal.Header className="border-0" closeButton>
            <h4 className="text-center w-100 h5 fw-semibold text-dark-gray">
              Editar Nome
            </h4>
          </Modal.Header>
          <Modal.Body className="pt-4 pb-5">
            <div>
              <label className="w-100">
                <span className="small">
                  Nome Completo: <span className="text-primary">*</span>
                </span>
                <Input
                  className="input"
                  placeholder="Nome Completo"
                  name="nome"
                />
                {error && (
                  <span className="small text-danger error">{error}</span>
                )}
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <button
              className="btn btn-primary w-100 w-md-75 py-2"
              type="submit"
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalName;
