import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ZodError } from 'zod';

// Icons
import { HiCheck } from 'react-icons/hi';
import arrowLeftIcon from '~/assets/icons/arrow-left-project-estate.svg';
import taskAltIcon from '~/assets/icons/task-alt.svg';
import trashIcon from '~/assets/icons/trash.svg';

// Interfaces
import { IParams, IStepData, IStepsRealEstateProduct } from './types';

// Components
import UserDefault from '~/components/UserDefault';

// Partials
import { Around } from './Partials/Around';
import { Data } from './Partials/Data';
import { Estate } from './Partials/Estate';
import { Home } from './Partials/Home';
import { Project } from './Partials/Project';

// Models
import {
  IMRealStateProduct,
  IRealEstateProductAround,
  IRealEstateProductLocation,
  IRealEstateProductRegionalData,
} from '~/models/RealEstateProduct';
import { IMRealEstateProductCondominiumOption } from '~/models/RealEstateProductCondominiumOption';
import { IMRealEstateProductHomeOptions } from '~/models/RealEstateProductHomeOption';
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';
import { IMRealEstateStandard } from '~/models/RealEstateStandard';

// Services
import api from '~/services/api';

// Utils
import { AuthContext } from '~/hooks/contexts/Auth/context';
import { AIGPT } from '~/services/openAI';
import { IUserPrompt } from '~/services/openAI/types';
import { debounce } from '~/utils/debounce';
import { SAround, SHouse, SProject, SRegionalData, SStandard } from '../utils/schemas';

export function RealEstateProject(): JSX.Element {
  const { id: projectID, toStep } = useParams<IParams>();
  const [targetStep, setTargetStep] = useState<string>('');
  const [projectIDRaw, setProjectIDRaw] = useState<number | null>(null);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const [finishButton, setFinishButton] = useState<boolean>(false);
  const [savedButton, setSavedButton] = useState<boolean>(false);
  const [updateButton, setUpdateButton] = useState<boolean>(false);
  const [deleteButton, setDeleteButton] = useState<boolean>(false);
  const [repOnSubmitting, setRepOnSubmitting] = useState<boolean>(false);
  const [projectOnLoading, setProjectOnLoading] = useState<boolean>(true);
  const [dataRestart, setDataRestart] = useState<boolean>(false);
  const [processRestart, setProcessRestart] = useState<boolean>(false);
  const [deleteTarget, setDeleteTarget] = useState<number>(-1);
  const [updateTarget, setUpdateTarget] = useState<number>(-1);
  const [stepsData, setStepsData] = useState<IStepsRealEstateProduct & { [key: string]: any }>({
    1: null,
    2: null,
    3: null,
    4: null,
    5: [],
  });
  const [dataStepStandard, setDataStepStandard] = useState<IMRealEstateStandard[]>([]);
  const { user } = useContext(AuthContext)

  // LOADING STATES
  const [housesOnLoading, setHousesOnLoading] = useState(true)

  const history = useHistory();

  // AUX variable
  const ICON_STEPSTYLE = useCallback(
    (actualtStep = '1') => {
      let ICON_STYLE = 'bg-[#F1F1F1] text-[#707070]';

      let step = '';

      if ((targetStep as unknown as number) <= 0 || targetStep.trim() === '') {
        step = actualtStep === toStep ? actualtStep : '';
      } else {
        step = targetStep === actualtStep ? targetStep : '';
      }

      switch (step) {
        case '1':
          ICON_STYLE = 'bg-[#39A887] text-[#FFF] border-[0.156rem] border-#A4DECD';
          break;
        case '2':
          ICON_STYLE = 'bg-[#39A887] text-[#FFF] border-[0.156rem] border-#A4DECD';
          break;
        case '3':
          ICON_STYLE = 'bg-[#39A887] text-[#FFF] border-[0.156rem] border-#A4DECD';
          break;
        case '4':
          ICON_STYLE = 'bg-[#39A887] text-[#FFF] border-[0.156rem] border-#A4DECD';
          break;
        case '5':
          ICON_STYLE = 'bg-[#39A887] text-[#FFF] border-[0.156rem] border-#A4DECD';
          break;
        default:
        // do anything
      }

      return ICON_STYLE;
    },
    [targetStep]
  );

  useEffect(() => {
    if (!toStep && targetStep === '') {
      setTargetStep('1');
    } else if (toStep) {
      setTargetStep(toStep);
    }
  }, []);

  // RECOVERY ALL STANDARD OPTIONS
  useEffect(() => {
    if (dataStepStandard.length > 0) return;

    api.get<IMRealEstateStandard[]>('builders/real-estate-products/standards').then((resolver) => {
      if (resolver.status !== 200) return;

      setDataStepStandard(resolver.data);
    })
      .catch((rejects) => {

      });
  }, []);

  // RECOVERY ALL HOUSES FROM PROJECT
  useEffect(() => {
    if (!projectID) {
      setHousesOnLoading(false)
      return;
    }

    api.get<IMRealEstateProductsHouse[]>(`builders/real-estate-products/${projectID}/houses`).then((resolver) => {
      if (resolver.status !== 200) return;

      setStepsData((step) => ({
        ...step,
        5: resolver?.data?.map((house) => ({
          data: {
            ...house,
            homeOptionsIds: house.home_options || []
          },
          filled: true,
          errorMap: null
        }))
      }));

      setHousesOnLoading(false)
    })
      .catch((rejects) => {
        setHousesOnLoading(false)
      });
  }, [projectID]);

  // RECOVERY REAL ESTATE PRODUCT
  useEffect(() => {
    if (!projectID) {
      setProjectOnLoading(false)
      return
    };

    api.get<IMRealStateProduct>(`builders/real-estate-products/${projectID}`).then((resolver) => {
      if (resolver.status !== 200) {
        setProjectOnLoading(false)
        return;
      }

      setStepsData((step) => ({
        ...step,
        1: {
          data: resolver.data.standard || {},
          filled: true,
          errorMap: null
        }
      }));

      setStepsData((step) => ({
        ...step,
        2: {
          data: {
            name: resolver.data.name || '',
            localization: resolver.data.localization || '',
            photo: resolver.data.photo,
            photo_upload_id: resolver.data.photo_upload_id,
            id: resolver.data.id
          },
          filled: true,
          errorMap: null
        }
      }));

      setStepsData((step) => ({
        ...step,
        3: {
          data: {
            quantity_terrains: resolver.data.quantity_terrains || 0,
            built_houses: resolver.data.built_houses || 0,
            condominiumOptionsIds: resolver.data.condominium_options || [],
          },
          filled: true,
          errorMap: null
        }
      }));

      setStepsData((step) => ({
        ...step,
        4: {
          data: {
            average_income_value: resolver.data.average_income_value || 0,
            average_age_residents: resolver.data.average_age_residents || 0,
            origin_residents: resolver.data.origin_residents || '',
          },
          filled: true,
          errorMap: null
        }
      }));

      setProjectOnLoading(false)
    })
      .catch((rejects) => {
        setProjectOnLoading(false)
      });
  }, [projectID]);

  // VERIFY THE STEP DATA, AND START STREAM DATA SAVE BY 3ª STEP
  useEffect(() => {
    if (!projectOnLoading) handleSetParseValidationSteps()
  }, [targetStep]);

  function onEnableNextButton(enable = false) {
    try {
      setEnableButton(enable);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel habilitar o botão',
        icon: 'warning',
      });
    }
  }

  function onEnableDataDeleteButton(enable = false, target = -1) {
    try {
      if (!target || target <= -1) {
        setDeleteButton(false);
        return;
      }

      setDeleteTarget(target);
      setDeleteButton(enable);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel habilitar o botão',
        icon: 'warning',
      });
    }
  }

  function onEnableDataUpdateButton(enable = false, target = -1) {
    try {
      if (target <= -1) {
        setUpdateButton(false);
        return;
      }

      setUpdateTarget(target);
      setUpdateButton(enable);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel habilitar o botão',
        icon: 'warning',
      });
    }
  }

  function onSetActualHousePositionToUpdate(housePosition: number = -1) {
    try {
      if (housePosition <= -1) setUpdateTarget(-1)

      setUpdateTarget(housePosition)
    } catch (error) {
      // do anything
    }
  }

  /**
   * Go back to last step
   */
  function onBackStepButton() {
    try {
      setTargetStep((step) => `${(step as unknown as number) - 1}`);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel voltar para o passo anterior',
        icon: 'warning',
      });
    }
  }

  /**
   * Show the finished button
   * @param enable
   */
  function onFinishNextButton(enable = false) {
    try {
      setFinishButton(enable);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel habilitar o botão',
        icon: 'warning',
      });
    }
  }

  /**
   * Receive all data from actual step and save on local state
   * @param args Data from step
   * @param caller Whon caller this function
   * @param callerDataPosition
   */
  function onDataStream(
    args:
      Partial<IMRealEstateStandard
        | IRealEstateProductLocation
        | IRealEstateProductAround
        | IRealEstateProductRegionalData
        | IMRealEstateProductsHouse
        | IMRealStateProduct>,
    caller: string,
    callerDataPosition: number = -1
  ) {
    try {
      if (
        (caller as unknown as number) <= -1
        || (caller as unknown as number) > 5
      ) throw new Error('');

      const cachedStepData = { ...stepsData };

      // console.log(caller, callerDataPosition, args);

      if (caller === '5') {
        if (callerDataPosition >= 0) {
          (cachedStepData[caller] as unknown as IStepData<IMRealEstateProductsHouse>[])
          [callerDataPosition] = {
            data: {
              ...((cachedStepData[caller] as unknown as IStepData<IMRealEstateProductsHouse>[])
              [callerDataPosition]?.data || {}),
              ...args as unknown as IMRealEstateProductsHouse
            },
            filled: false,
            errorMap: null
          };
        } else {
          (cachedStepData[caller] as unknown as IStepData<IMRealEstateProductsHouse>[]).push({
            data: args as unknown as IMRealEstateProductsHouse,
            filled: false,
            errorMap: null
          });
        }
      } else {
        cachedStepData[caller] = {
          data: {
            ...(cachedStepData[caller]?.data || {}),
            ...args
          },
          filled: false,
          errorMap: null,
        };
      }

      setStepsData(cachedStepData);

      if (caller === '1') onEnableNextButton!(true);

      if (((caller as unknown as number) > 1 || (caller as unknown as number) < 5) && !enableButton) onEnableNextButton(true);
    } catch (error: any) {
      Swal.fire({
        text: `Não foi possivel armazenar os dados do passo ${caller}`,
        icon: 'warning',
      });
    }
  }

  /**
   * Send all data from steps to API
   */
  async function setRealEstateProductToStorage() {
    try {
      setRepOnSubmitting(true)

      const OPEN_AI_GPT = new AIGPT(null,null,[],targetStep)
      const DATA_CHAT_STORAGED = OPEN_AI_GPT.getChatCompletionsStoraged()?.[user?.unique_id || '']  

      const data: { [key: string]: any } = {
        standard_id: stepsData[1]?.data.id || -1,
        name: stepsData[2]?.data.name || '',
        photo: stepsData[2]?.data.photo || '',
        localization: stepsData[2]?.data.localization || '',
        longitude: stepsData[2]?.data.longitude || '-0',
        latitude: stepsData[2]?.data.latitude || '0',
        quantity_terrains: stepsData[3]?.data.quantity_terrains || 0,
        built_houses: stepsData[3]?.data.built_houses || 0,
        average_income_value: stepsData[4]?.data.average_income_value || 0,
        average_age_residents: stepsData[4]?.data.average_age_residents || 0,
        origin_residents: stepsData[4]?.data.origin_residents || '',
        condominiumOptionsIds: stepsData[3]?.data.condominiumOptionsIds || '',
      }

      const dataForm = new FormData()

      Object.keys(data).forEach(rowKey => {

        if ((rowKey === 'condominiumOptionsIds' && typeof data[rowKey] === 'object') || (rowKey === 'condominium_options' && typeof data[rowKey] === 'object')) {
          (data[rowKey] as unknown as IMRealEstateProductCondominiumOption[])?.forEach((option, index) => {
            dataForm.append(`condominium_options[${index}]`, `${option.id}`)
          })
        } else {
          dataForm.append(rowKey, data[rowKey])
        }
      })

      const responseEstateProduct = await api.post<IMRealStateProduct>(`builders/real-estate-products/${projectID || projectIDRaw || ''}`, dataForm);

      if (responseEstateProduct.status !== 201) throw new Error('');

      // console.table({ projectID, projectIDRaw, toStep, updateTarget })

      if (targetStep === '5') {
        Swal.fire({
          icon: 'success',
          text: projectID || projectIDRaw ? 'Projeto atualizado com sucesso' : 'Projeto cadastrado com sucesso'
        });

        setSavedButton(true);
      }

      setProjectIDRaw(responseEstateProduct.data.id || -1)
      
      if(DATA_CHAT_STORAGED) await OPEN_AI_GPT.setOnExternalChatData(DATA_CHAT_STORAGED,0, responseEstateProduct.data.id)
        
      setRepOnSubmitting(false)
      // AUTO CHANGE TO STEP 5, IF THE SAVE WAS ON STEP 4
      if(Number(targetStep) === 4) setTargetStep('5')
    } catch (error) {
      setRepOnSubmitting(false)
      Swal.fire({
        icon: 'warning',
        text: 'Não foi possivel salvar seu projeto',
        confirmButtonText: 'Tentar Novamente',
        confirmButtonColor: '#39A887',
        showConfirmButton: true,
      }).then(resolver => {
        if(resolver.isConfirmed) setRealEstateProductToStorage()
      });
    }
  }

  async function setRealEstateProductDeleteFromStorage() {
    try {
      const responseEstateProduct = await api.delete<IMRealStateProduct>(`builders/real-estate-products/${projectID}/house`);

      if (responseEstateProduct.status !== 204) throw new Error('');

      Swal.fire({
        icon: 'success',
        text: 'Casa excluida com sucesso'
      });
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        text: 'Não foi possivel excluir sua casa'
      });
    }
  }

  async function setRealEstateProductHouseDeleteFromStorage() {
    try {
      setProcessRestart(false)

      const house = (stepsData[5] as IStepData<IMRealEstateProductsHouse>[])[deleteTarget];

      console.table(house)

      const responseEstateProduct = await api.delete<IMRealStateProduct>(`builders/real-estate-products/${(projectID || projectIDRaw)}/houses/${house.data.id || -1}`);

      if (responseEstateProduct.status !== 204) throw new Error('');

      const houseStep = (stepsData[5] as IStepData<IMRealEstateProductsHouse>[])
        // IS NOT BETTER WAY, BUT THE INDEX DO THE WORK
        .filter((_house, index) => index !== deleteTarget);

      setStepsData((steps) => ({
        ...steps,
        5: houseStep
      }));

      Swal.fire({
        icon: 'success',
        text: 'Projeto excluido com sucesso'
      });

      setProcessRestart(true)
    } catch (error) {
      setProcessRestart(false)
      Swal.fire({
        icon: 'warning',
        text: 'Não foi possivel excluir a casa selecionada'
      });
    }
  }

   /**
   * Send all chat open ai data to database
   * @param data The user metrics and conversations
   * @param pTarget The index target to get the correct prompt
   * @returns 
   */
  async function setChatStremDataToDatabase(data: IUserPrompt | null = null, pTarget: number = -1, rep_id: string | number | null = null ){
    try {

      const PROMPTS = (data?.prompts || []).filter(d => d.origin === Number(targetStep)).slice(0, 3)      

      if(!data || !data?.choices) throw new Error('1');

      if(!PROMPTS || PROMPTS.length <= 0) throw new Error('2');

      if(pTarget <= -1 || !PROMPTS[pTarget] || pTarget > PROMPTS.length ) throw new Error('3');

      const NEXT_PROMPT_TARGET = (pTarget + 1)

      const response = await api.post(`builders/real-estate-products/${rep_id}/prompts`, {
        type_id: targetStep,
        prompt: PROMPTS[pTarget].content,
        response: data.choices[0].message.content || '',
        model: data.model.name,
        price: data.model.price,
        output_price: data.model.output_price,
        price_by_token: data.model.price_by_token,
        token: data.prompts[pTarget].tokens,
      })

      if(response.status !== 201) throw new Error('4')

      console.table(PROMPTS)

      if(NEXT_PROMPT_TARGET <= PROMPTS.length && PROMPTS[NEXT_PROMPT_TARGET]) {
        await setChatStremDataToDatabase(data, NEXT_PROMPT_TARGET, rep_id)
        return
      }

      return;
    } catch (error) {
      console.table(error)
      return;
    }
  }

  function handleSetMountHouseDataForm(data: (IMRealEstateProductsHouse & { [key: string]: any }) | null = null): FormData {
    try {
      if (!data) throw new Error('')

      const dataForm = new FormData()

      Object.keys(data).forEach(rowKey => {
        if ((rowKey === 'homeOptionsIds' || (rowKey === 'home_options' && typeof data[rowKey] === 'object')) && data[rowKey]) {
          (data[rowKey] as unknown as IMRealEstateProductHomeOptions[]).forEach((option, index) => {
            dataForm.append(`home_options[${index}]`, `${option.id}`)
          })

        } else {
          let rowValue = data[rowKey]

          switch (rowKey) {
            case 'for_sale':
              rowValue = (rowValue !== 0 && !rowValue) ? false : +Boolean(rowValue)
              break;
            default:
            // do anything
          }

          dataForm.append(rowKey, rowValue)
        }
      })

      return dataForm
    } catch (error) {
      throw error
    }
  }
/**
 * Set all houses from step of houses, to API
 * This funciton works with recursivy strategy
 *
 * @param estateProductID
 * @param remainHouses
 * @param resolver
 * @returns
 */
  async function setRealEstateProductHouseToStorage(estateProductID: number | null
    = null, remainHouses = -1, resolver: (value: unknown) => void = () => ({})) {
    try {
      setProcessRestart(false)
      const remainHouseIndex = remainHouses > 0 ? remainHouses - 1 : remainHouses === 0 ? 0 : -1;

      if (!estateProductID) throw new Error('');

      if ((stepsData[5] as IStepData<IMRealEstateProductsHouse>[])?.length <= 0) return;

      if (remainHouses < 0 || remainHouseIndex < 0) return;

      if (remainHouses > (stepsData[5] as IStepData<IMRealEstateProductsHouse>[])?.length) return;

      const houses = (stepsData[5] as IStepData<IMRealEstateProductsHouse>[]);

      if (houses[remainHouseIndex].data.id) return;

      if (!houses[remainHouseIndex].data.home_options || houses[remainHouseIndex].data?.home_options!.length <= 0) {
        Swal.fire({
          icon: 'warning',
          text: 'Informe os detalhes do imóvel'
        })

        return
      }

      // VALIDA OS DADOS DA CASA, GERA UMA EXCEPTION EM CASOS DE INVALIDEZ
      SHouse.parse(houses[remainHouseIndex].data || {})

      const responseEstateProductHouse = await api.post<IMRealEstateProductsHouse>(
        `builders/real-estate-products/${estateProductID}/houses`,
        handleSetMountHouseDataForm(houses[remainHouseIndex].data));

      if (responseEstateProductHouse.status !== 201) throw new Error('');

      setStepsData(step => {
        step[5][updateTarget]['data'] = responseEstateProductHouse.data

        return step
      });

      if ((remainHouses - 1) >= 1 && remainHouseIndex >= 0) {
        await setRealEstateProductHouseToStorage(estateProductID,
          remainHouses - 1);
      }

      setProcessRestart(true)
      handleVerifyQuantityOfHouses()

      return;
    } catch (error) {
      setProcessRestart(false)
      Swal.fire({
        icon: 'warning',
        text: `Não foi possivel salvar a casa ${(stepsData[5] as IStepData<IMRealEstateProductsHouse>[])?.[remainHouses]?.data?.name || ''}`
      });

      handleSetErrorOnStepData(error)
    }
  }

  async function setUpdateRealEstateProductHouseToStorage(estateProduct: IMRealStateProduct | null
    = null) {
    try {
      setRepOnSubmitting(true)
      setProcessRestart(false)
      if (updateTarget <= -1) throw new Error('');

      const house = (stepsData[5] as IStepData<IMRealEstateProductsHouse>[])[updateTarget];

      if (!house || !house.data.id) throw new Error('');

      // VALIDA OS DADOS DA CASA, GERA UMA EXCEPTION EM CASOS DE INVALIDEZ
      // SHouse.parse(house.data || {})

      const responseEstateProductHouse = await api
        .post<IMRealEstateProductsHouse>(
          `builders/real-estate-products/${projectID}/houses/${house.data.id || -1}`,
          handleSetMountHouseDataForm((house.data as IMRealEstateProductsHouse))
        )

      if (responseEstateProductHouse.status !== 201) throw new Error('');

      setStepsData(step => {
        step[5][updateTarget]['data'] = responseEstateProductHouse.data

        return step
      });

      Swal.fire({
        icon: 'success',
        text: `Casa ${house.data.name} atualizada com sucesso`
      });

      setRepOnSubmitting(false)
      setProcessRestart(true)

      return;
    } catch (error) {
      handleSetErrorOnStepData(error)
      setRepOnSubmitting(false)
      setProcessRestart(false)

      Swal.fire({
        icon: 'warning',
        text: 'Não foi possivel atualizar os detalhes da casa'
      });
    }
  }

  async function handleSetHouseToStorage() {
    try {
      setRepOnSubmitting(true)

      // ESSA CONDIÇÃO SEMPRE VAI CADASTRAR UMA NOVA HOUSE PARA O PROJETO COM ID VINDO DA ROTA OU DO ESTADO LOCAL
      await setRealEstateProductHouseToStorage((projectIDRaw || (projectID as unknown as number)), stepsData[5].length);

      setRepOnSubmitting(false)
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        text: 'Não foi possivel salvar as casas, Tente novamente!'
      });
    }
  }

  /**
   * Set the validation on prev step with base on actual step, if no have erros, clear old erros and send to API
   */
  function handleSetParseValidationSteps() {
    try {
      const targetToNumber = parseInt(targetStep, 10)
      const targetParsed = targetToNumber === 1 ? targetToNumber : targetToNumber - 1

      switch (targetParsed) {
        case 1:
          SStandard.parse(stepsData[1]?.data || {})
          break;
        case 2:
          SProject.parse(stepsData[2]?.data || {})
          break;
        case 3:
          SAround.parse(stepsData[3]?.data || {})
          break;
        case 4:
          SRegionalData.parse(stepsData[4]?.data || {})
          break;
        default:
      }

      // LIMPA O MAP DE ERROS
      if (stepsData[(targetStep as unknown as number) - 1]) {
        // @ts-ignore
        setStepsData((sd) => {
          sd[(targetStep as unknown as number) - 1]['errorMap'] = null

          return sd
        })
      }
    } catch (error: any) {
      handleSetErrorOnStepData(error)

      if ((targetStep as unknown as number) > 1 && (targetStep as unknown as number) < 5) onBackStepButton()
    }
  }

  function handleSetErrorOnStepData(error: any) {
    try {
      console.table(error)
      if (!error || (targetStep as unknown as number) <= 0) return

      const targetToNumber = parseInt(targetStep, 10)
      const targetParsed = targetToNumber === 1 ? targetToNumber : targetToNumber - 1

      if (error instanceof ZodError) {
        if ((targetStep as unknown as number) >= 1 && (targetStep as unknown as number) < 5) {
          if (stepsData[targetParsed]) {
            setStepsData((sd) => {
              sd[targetParsed]['errorMap'] = error

              return sd
            })
          } else {
            const cachedStepData = { ...stepsData };

            cachedStepData[targetParsed] = {
              data: {},
              errorMap: error,
              filled: false
            }

            setStepsData(cachedStepData)
          }
        } else if (targetToNumber === 5) {
          if (updateTarget >= 0 && stepsData[5][updateTarget]) {
            setStepsData((sd) => {
              sd[5][updateTarget]['errorMap'] = error

              return sd
            })
          } else if (stepsData[5][stepsData[5]?.length]) {
            const cachedStepData = { ...stepsData };

            cachedStepData[5][stepsData[5]?.length] = {
              data: stepsData[5][stepsData[5]?.length].data || {} as IMRealEstateProductsHouse,
              errorMap: error,
              filled: false
            }

            setStepsData(cachedStepData)
          }
        }
      }
    } catch (error) {
      // do anything
    }
  }

  function handleVerifyQuantityOfHouses(){
    try {
      if((stepsData[5] as IStepData<IMRealEstateProductsHouse>[]).length < 10 ) return

      Swal.fire({
        icon: 'warning',
        text: 'Todas as casa já foram cadastradas',
        showConfirmButton: true,
        confirmButtonText: 'Ir para dashboard',
        confirmButtonColor: '#39A887',
        showCancelButton: true,
        cancelButtonText: 'Continuar'
      }).then(resolver => resolver.isConfirmed && history.push(`/produtos-imobiliario/projeto/${projectIDRaw || projectID}`))
    } catch (error) {
      // do anything
    }
  }

  return (
    <div className="max-sm:w-screen flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep pt-rep-t h-screen">
      <div className="container mx-auto flex flex-col items-start justify-start gap-rep60 max-sm:!w-screen max-sm:!p-[0]">
        <UserDefault home />

        <div className="w-full max-sm:flex max-sm:flex-col md:grid md:grid-flow-col md:auto-cols-auto md:gap-[9.437rem] md:min-h-[29.875rem] h-auto">
          {
            // Side bar of navigation
          }
          <div className="max-sm:w-full md:w-[13.437rem] h-full flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center md:items-start justify-start max-sm:hidden">
            <span className="flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center items-start max-sm:justify-center justify-start">
              <span className="w-auto h-auto flex md:flex-row max-sm:flex-col md:flex-nowrap items-center max-sm:justify-center gap-[1.25rem] relative">
                <span
                  onClick={() => setTargetStep('1')}
                  onKeyUp={() => setTargetStep('1')}
                  tabIndex={0}
                  role="button"
                  className={`flex flex-row flex-nowrap items-center justify-center rounded-full w-[1.875rem] h-[1.875rem]
                   ${ICON_STEPSTYLE('1')}
                  ${stepsData[1]?.data && (targetStep as unknown as number) > 1 && '!bg-[#39A887] '} text-[0.875rem] font-medium transition-all duration-[0.3s]`}
                >
                  {
                    stepsData[1]?.data && (targetStep as unknown as number) > 1 ? (
                      <HiCheck color="#FFF" size={18} />
                    ) : 1
                  }
                </span>
                <span className="font-semibold text-[#707070] cursor-default select-none">
                  Produto imobiliário
                </span>
              </span>
              <span className="max-sm:w-[1.875rem] md:h-[6.25rem] bg-[#B8B9BA] w-[0.1rem] md:ml-[0.875rem]" />
            </span>
            <span className="flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center items-start max-sm:justify-center justify-start">
              <span className="w-auto h-auto flex md:flex-row max-sm:flex-col md:flex-nowrap items-center max-sm:justify-center gap-[1.25rem] relative">
                <span
                  onClick={() => setTargetStep('2')}
                  onKeyUp={() => setTargetStep('2')}
                  tabIndex={0}
                  role="button"
                  className={`flex flex-row flex-nowrap items-center justify-center rounded-full w-[1.875rem] h-[1.875rem] ${ICON_STEPSTYLE(
                    '2'
                  )} ${stepsData[2]?.data && (targetStep as unknown as number) > 2 && '!bg-[#39A887]'} text-[0.875rem] font-medium transition-all duration-[0.3s]`}
                >
                  {
                    stepsData[2]?.data && (targetStep as unknown as number) > 2 ? (
                      <HiCheck color="#FFF" size={18} />
                    ) : 2
                  }
                </span>
                <span className="font-semibold text-[#707070] cursor-default select-none">Projeto</span>
              </span>
              <span className="max-sm:w-[1.875rem] md:h-[6.25rem] bg-[#B8B9BA] w-[0.1rem] md:ml-[0.875rem]" />
            </span>
            <span className="flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center items-start max-sm:justify-center justify-start">
              <span className="w-auto h-auto flex md:flex-row max-sm:flex-col md:flex-nowrap items-center max-sm:justify-center gap-[1.25rem] relative">
                <span
                  onClick={() => setTargetStep('3')}
                  onKeyUp={() => setTargetStep('3')}
                  tabIndex={0}
                  role="button"
                  className={`flex flex-row flex-nowrap items-center justify-center rounded-full w-[1.875rem] h-[1.875rem] ${ICON_STEPSTYLE(
                    '3'
                  )} ${stepsData[3]?.data && (targetStep as unknown as number) > 3 && '!bg-[#39A887]'} text-[0.875rem] font-medium transition-all duration-[0.3s]`}
                >
                  {
                    stepsData[3]?.data && (targetStep as unknown as number) > 3 ? (
                      <HiCheck color="#FFF" size={18} />
                    ) : 3
                  }
                </span>
                <span className="font-semibold text-[#707070] cursor-default select-none">Em Torno</span>
              </span>
              <span className="max-sm:w-[1.875rem] md:h-[6.25rem] bg-[#B8B9BA] w-[0.1rem] md:ml-[0.875rem]" />
            </span>
            <span className="flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center items-start max-sm:justify-center justify-start">
              <span className="w-auto h-auto flex md:flex-row max-sm:flex-col md:flex-nowrap items-center max-sm:justify-center gap-[1.25rem] relative">
                <span
                  onClick={() => setTargetStep('4')}
                  onKeyUp={() => setTargetStep('4')}
                  tabIndex={0}
                  role="button"
                  className={`flex flex-row flex-nowrap items-center justify-center rounded-full w-[1.875rem] h-[1.875rem] ${ICON_STEPSTYLE(
                    '4'
                  )} ${stepsData[4]?.data && (targetStep as unknown as number) > 4 && '!bg-[#39A887]'} text-[0.875rem] font-medium transition-all duration-[0.3s]`}
                >
                  {
                    stepsData[4]?.data && (targetStep as unknown as number) > 4 ? (
                      <HiCheck color="#FFF" size={18} />
                    ) : 4
                  }
                </span>
                <span className="font-semibold text-[#707070] cursor-default select-none">
                  Dados Regionais
                </span>
              </span>
              <span className="max-sm:w-[1.875rem] md:h-[6.25rem] bg-[#B8B9BA] w-[0.1rem] md:ml-[0.875rem]" />
            </span>
            <span className="flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:items-center items-start max-sm:justify-center justify-start">
              <span className="w-auto h-auto flex md:flex-row max-sm:flex-col md:flex-nowrap items-center max-sm:justify-center gap-[1.25rem] relative">
                <span
                  onClick={() => setTargetStep('5')}
                  onKeyUp={() => setTargetStep('5')}
                  tabIndex={0}
                  role="button"
                  className={`flex flex-row flex-nowrap items-center justify-center rounded-full w-[1.875rem] h-[1.875rem] ${ICON_STEPSTYLE(
                    '5'
                  )} ${stepsData[5]?.length > 0 && targetStep === '5' && '!bg-[#39A887]'} text-[0.875rem] font-medium transition-all duration-[0.3s]`}
                >
                  {
                    stepsData[5]?.length > 0 && targetStep === '5' ? (
                      <HiCheck color="#FFF" size={18} />
                    ) : 5
                  }
                </span>
                <span className="font-semibold text-[#707070] cursor-default select-none">
                  Adicionar Casas
                </span>
              </span>
              <span className="max-sm:w-[1.875rem] md:h-[6.25rem] bg-[#B8B9BA] w-[0.1rem] md:ml-[0.875rem] last-of-type:hidden" />
            </span>
          </div>

          {
            // Top bar of navigation
          }
          <div className="hidden max-sm:flex flex-row flex-nowrap items-center justify-start p-[1rem] gap-[2rem]">
            <div className="flex-1 flex bg-[#F1F1F1] rounded-[6.25rem]">
              <div
                className="rounded-[0.953rem] bg-[#A4DECD] transition-all duraion-[0.3s] h-[1.5rem]"
                style={{
                  width: `${(Number(targetStep) / 5) * 100}%`
                }}
              >
              </div>
            </div>
            <span className='font-bold text-[#414142] text-[1.25rem] leading-normal'>
              {(Number(targetStep) / 5) * 100}%
            </span>
          </div>

          {
            // Step Content
          }
          <div className="max-sm:w-full md:w-[53.375rem] h-full flex flex-col md:items-start max-sm:items-center md:justify-start max-sm:justify-center">

            {(targetStep === '1'
              || (targetStep.trim() === '' && toStep === '1')) && (
                <Estate
                  enableNextButtonFunction={onEnableNextButton}
                  onDataStream={onDataStream}
                  dataStep={stepsData['1']}
                  dataStepStandard={dataStepStandard}
                />
              )}

            {(targetStep === '2'
              || (targetStep.trim() === '' && toStep === '2')) && (
                <Project
                  enableNextButtonFunction={onEnableNextButton}
                  onDataStream={onDataStream}
                  dataStep={stepsData['2']}
                />
              )}

            {(targetStep === '3'
              || (targetStep.trim() === '' && toStep === '3')) && (
                <Around
                  enableNextButtonFunction={onEnableNextButton}
                  onDataStream={onDataStream}
                  dataStep={stepsData['3']}
                />
              )}

            {(targetStep === '4'
              || (targetStep.trim() === '' && toStep === '4')) && (
                <Data
                  enableNextButtonFunction={onEnableNextButton}
                  onDataStream={onDataStream}
                  dataStep={stepsData['4']}
                  projectData={stepsData['2']}
                  rep_id={projectID || projectIDRaw || ''}
                />
              )}

            {(targetStep === '5'
              || (targetStep.trim() === '' && toStep === '5')) && (
                <Home
                  enableNextButtonFunction={onEnableNextButton}
                  enableFinishButtonFunction={onFinishNextButton}
                  enableDataDeletesButtonFunction={onEnableDataDeleteButton}
                  enableDataUpdateButtonFunction={onEnableDataUpdateButton}
                  onBackStepButtonBack={onBackStepButton}
                  onSetActualHousePositionToUpdate={onSetActualHousePositionToUpdate}
                  onDataStream={onDataStream}
                  dataStep={stepsData['5'] as IStepData<IMRealEstateProductsHouse>[]}
                  idProject={(projectID as unknown as number) || projectIDRaw}
                  processRestart={processRestart}
                  housesOnLoading={housesOnLoading}
                />
              )}
          </div>
        </div>

        {
          // Bar actions
        }
        <div className="md:w-[53.375rem] max-sm:w-full flex md:flex-row max-sm:flex-col items-center md:justify-between max-sm:justify-center mb-[2rem] md:self-end max-sm:gap-[1rem] max-sm:p-[1rem]">
          <div className="max-sm:w-full flex-1 flex flex-row items-center max-sm:justify-center gap-[1rem]">
            {
              savedButton && (
                <span className="w-[11.375rem] h-[2.75rem] rounded-[0.937rem] flex flex-row items-center justify-center gap-[0.937rem] font-[600] text-[#39A887] bg-[#E1EEEA] object-fill">
                  <img src={taskAltIcon} alt="Icone generico de confirmação" />
                  Dados salvos
                </span>
              )
            }
            {
              deleteButton && (
                <span
                  title="Excluir casa"
                  className="cursor-pointer hover:scale-110 transition-transform duration-[0.3s]"
                  role="button"
                  tabIndex={0}
                  onClick={setRealEstateProductHouseDeleteFromStorage}
                  onKeyUp={setRealEstateProductHouseDeleteFromStorage}
                >
                  <img src={trashIcon} alt="" />
                </span>
              )
            }
          </div>

          <div className="max-sm:w-full flex max-sm:flex-col md:flex-row md:flex-nowrap items-center max-sm:justify-center md:justify-end gap-[0.937rem] ">
            {(targetStep as unknown as number) > 1
              && targetStep.trim() !== '' && (
                <button
                  title='Voltar para o passo anterior'
                  onClick={onBackStepButton}
                  type="button"
                  className="rounded-[0.937rem] max-sm:w-full md:w-[3.875rem] h-[3.375rem] bg-[#39A887] hover:bg-[#4acea6] transition-colors
                  duration-[0.3s] shadow-sm flex flex-row items-center justify-center flex-nowrap"
                >
                  <img
                    src={arrowLeftIcon}
                    alt="Um chevron de cor Branco, apontando pra esquerda"
                  />
                </button>
              )
            }

            <button
              disabled={repOnSubmitting }
              data-showme={(projectIDRaw && projectIDRaw >= 1 && targetStep === '5') && true}
              onClick={() => history.push(`/produtos-imobiliario/projeto/${projectIDRaw}`)}
              title="Ver projeto"
              type="button"
              className="rounded-[0.937rem]
                max-sm:w-full
                md:w-[20.687rem]
                h-[3.375rem]
                bg-[#f3f4f6]
                disabled:opacity-80
                disabled:cursor-not-allowed
                transition-colors
                duration-[0.3s]
                shadow-sm
                hidden
                data-[showme=true]:flex
                flex-row
                flex-nowrap items-center
                justify-center gap-[1rem]
                font-semibold text-[#414142]
                border-[2px]
                border-[#f3f4f6]
              "
            >
              Visualizar projeto
            </button>

            {!updateButton && (targetStep as unknown as number) <= 3 && Object.values((stepsData[targetStep] as IStepData<IMRealStateProduct>)?.data || {}).length > 0 && (
              <button
                disabled={repOnSubmitting}
                onClick={() => setTargetStep((step) => (step === ''
                  ? `${parseInt(toStep || '1', 10) + 1}` || '1'
                  : `${parseInt(step, 10) + 1}`
                ))
                }
                type="button"
                className="rounded-[0.937rem] max-sm:w-full md:w-[20.687rem] h-[3.375rem]
                  bg-[#39A887] hover:bg-[#4acea6]
                  disabled:opacity-80 disabled:cursor-not-allowed
                  transition-colors duration-[0.3s] shadow-sm flex flex-row
                  flex-nowrap items-center justify-center font-bold text-white"
              >
                Próximo Passo
              </button>
            )
            }


            {
              // CADASTRAR OU ATUALIZAR UMA REP (Real Estate Product)
            }
            {!updateButton && (targetStep as unknown as number) == 4 && (
              <button
                disabled={repOnSubmitting}
                onClick={() => debounce(200, setRealEstateProductToStorage)}
                title="Salvar projeto"
                type="button"
                className="rounded-[0.937rem] max-sm:w-full md:w-[20.687rem] h-[3.375rem] bg-[#39A887] hover:bg-[#4acea6]
                  disabled:opacity-80 disabled:cursor-not-allowed
                  transition-colors duration-[0.3s] shadow-sm flex flex-row flex-nowrap items-center justify-center font-bold text-white"
              >
                {
                  repOnSubmitting && (
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  )
                }
                Salvar Projeto
              </button>
            )
            }

            {
              // CADASTRAR OU ATUALIZAR AS CASAS
            }
            {!updateButton && (targetStep as unknown as number) == 5 && stepsData[5]?.length > 0 && (
              <button
                disabled={repOnSubmitting}
                onClick={() => debounce(200, handleSetHouseToStorage)}
                title="Salvar projeto"
                type="button"
                className="rounded-[0.937rem] max-sm:w-full md:w-[20.687rem] h-[3.375rem] bg-[#39A887] hover:bg-[#4acea6]
                  disabled:opacity-80 disabled:cursor-not-allowed
                  transition-colors duration-[0.3s] shadow-sm flex flex-row flex-nowrap items-center justify-center font-bold text-white"
              >
                {
                  repOnSubmitting && (
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  )
                }
                Salvar Casa(s)
              </button>
            )
            }

            {
              // BOTÃO DEDICADO PARA ATUALIZAR UMA CASA (house) ESPECIFICA POR updateTarget
              // UTILIZA O ESTADO GENERICO 'UPDATEBUTTON'
            }
            {updateButton &&
              updateTarget >= 0 &&
              targetStep === '5' &&
              stepsData[5]?.length > 0 && (
                <button
                  disabled={repOnSubmitting}
                  onClick={() => debounce(200, setUpdateRealEstateProductHouseToStorage)}
                  title="Atualizar casa"
                  type="button"
                  className="rounded-[0.937rem] max-sm:w-full md:w-[20.687rem] h-[3.375rem] bg-[#39A887] hover:bg-[#4acea6]
                  disabled:opacity-80 disabled:cursor-not-allowed
                  transition-colors duration-[0.3s] shadow-sm flex flex-row flex-nowrap items-center justify-center gap-[1rem] font-bold text-white"
                >
                  {
                    repOnSubmitting && (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )
                  }
                  Atualizar Casa
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
