export function Mock() {
    return (
        <div className={`w-full h-[4.375rem] 
                flex md:flex-row  max-sm:flex-col
                md:flex-nowrap md:items-center
                justify-start md:gap-[0.687rem]
                max-sm:gap-[2rem]  p-[1rem]
                rounded-[0.937rem] bg-gray-200 animate-pulse `}>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem]">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <span className="w-[7rem] text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
            </span>
            <button type="button"
                title="Propor Compra"
                className="flex-1 h-[2.312rem] 
                                                rounded-[0.937rem] flex flex-row items-center justify-center 
                                                text-white font-bold bg-gray-200 animate-pulse 
                                                hover:opacity-80 transition-all duration-[0.3s]">
            </button>
        </div>
    )
}