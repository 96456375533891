import {
  useCallback, useEffect,
  useState
} from 'react';

// Icons
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// Interfaces
import { IStepData } from '~/pages/RealEstateProduct/RealEstateProject/types';
import { IStepProps } from './types';

// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';

// Partials
import { Especify } from './Especify';
import { General } from './General';

export function Step({
  onDataStream, enableFinishButtonFunction, onStartProcess, housePosition, dataStep
}: IStepProps): JSX.Element {
  const [step, setStep] = useState<number>(1);

  // AUX Variabels
  const STEP_STYLE = useCallback(
    (stepTarget = -1) => {
      let style = 'text-[#B8B9BA] font-[500]';

      const actualStep = step === stepTarget ? stepTarget : -1;

      switch (actualStep) {
        case 1:
          style = 'text-[#414142] font-bold relative after:absolute after:w-full after:h-[0.3rem] after:bg-[#079269] after:rounded-tl-[0.187rem] after:rounded-tr-[0.187rem] after:left-[0rem] after:bottom-[0rem]';
          break;
        case 2:
          style = 'text-[#414142] font-bold relative after:absolute after:w-full after:h-[0.3rem] after:bg-[#079269] after:rounded-tl-[0.187rem] after:rounded-tr-[0.187rem] after:left-[0rem] after:bottom-[0rem]';
          break;
        default:
        // do anything
      }

      return style;
    },
    [step]
  );
  const BUTTONSTEP_STYLE = useCallback(
    (stepTarget = -1) => {
      let style = 'bg-[#F0F0F066] fill-[#F0F0F066]';

      const actualStep = step === stepTarget ? stepTarget : -1;

      switch (actualStep) {
        case 1:
          style = 'bg-[#F0F0F066] disabled:bg-[#F0F0F066] fill-[#F0F0F066] disabled:fill-[#F0F0F066]';
          break;
        case 2:
          style = 'bg-[#F0F0F066] disabled:bg-[#F0F0F066] fill-[#F0F0F066] disabled:fill-[#F0F0F066]';
          break;
        default:
        // do anything
      }

      return style;
    },
    [step]
  );

  useEffect(() => {
    if (step === 2) enableFinishButtonFunction?.(true);
  }, [step]);

  const setDataHouse = (data: Partial<IMRealEstateProductsHouse> | null = null) => {
    try {
      if (!data) throw new Error('');

      onDataStream!(data, '5', housePosition);
    } catch (error) {
      // 
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start min-h-[30.312rem] gap-[1.875rem]">
      <div className="max-sm:w-full flex flex-col gap-[2.5rem]">
        <span className="flex flex-row items-center gap-[1.125rem]">
          <button
            type="button"
            className="bg-none border-none outline-none"
            onClick={onStartProcess}
          >
            <IoIosArrowBack size={27} color="#B8B9BA" />
          </button>
          <span className="text-[#414142] font-[500] text-[1.25rem]">
            {
              (dataStep as IStepData<IMRealEstateProductsHouse>[])[housePosition]?.data?.name || 'Nova casa'
            }
          </span>
        </span>
        <p className="text-[#707070] font-[400] max-sm:w-full max-sm:text-center">
          Preencha as seguintes informações
        </p>
      </div>

      <div className="w-full h-auto flex flex-col gap-[2.5rem] bg-white rounded-[0.937rem] p-[1.875rem] shadow-sm overflow-hidden">
        <nav className="w-full flex flex-row items-center justify-between">
          <ul className="flex-1 flex flex-row items-center justify-start gap-[1.562rem]">
            <li>
              <button
                type="button"
                className={`pb-[0.562rem] ${STEP_STYLE(
                  1
                )} transition-all duration-[0.3s] border-none outline-none`}
                onClick={() => setStep(1)}
              >
                <span>Informações gerais</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`pb-[0.562rem] ${STEP_STYLE(
                  2
                )} transition-all duration-[0.3s] border-none outline-none`}
                onClick={() => setStep(2)}
              >
                Especificações
              </button>
            </li>
          </ul>
          <div className="w-auto flex flex-row items-center gap-[0.625rem] max-sm:hidden">
            <button
              onClick={() => setStep(1)}
              disabled={step === 1}
              type="button"
              className={`flex flex-row items-center justify-center border-none outlino-none rounded-full w-[1.5rem] h-[1.5rem] bg-[#F0F0F0] ${BUTTONSTEP_STYLE(
                1
              )}`}
              title="Passo Anterior"
            >
              <IoIosArrowBack size={14} />
            </button>
            <button
              onClick={() => setStep(2)}
              disabled={step === 2}
              type="button"
              className={`flex flex-row items-center justify-center border-none outlino-none rounded-full w-[1.5rem] h-[1.5rem] bg-[#F0F0F0] ${BUTTONSTEP_STYLE(
                2
              )}`}
              title="Próximo passo"
            >
              <IoIosArrowForward size={14} />
            </button>
          </div>
        </nav>

        {step === 1 && (
          <General
            onDataHouse={setDataHouse}
            dataStep={(dataStep as IStepData<IMRealStateProduct>[])[housePosition] || {}}
            onDataStream={onDataStream}
            housePosition={housePosition}
          />
        )}

        {step === 2 && (
          <Especify
            onDataHouse={setDataHouse}
            onDataStream={onDataStream}
            dataStep={(dataStep as IStepData<IMRealStateProduct>[])[housePosition] || {}}
            housePosition={housePosition}
          />
        )}
      </div>
    </div>
  );
}
