export function Mock(){
    return <div className="flex-1 h-screen px-5 pt-4 flex flex-col items-start justify-start gap-rep40">
        <div className="w-12 h-12 rounded-full bg-slate-200 animate-pulse self-end"></div>
        <div className="w-full h-full flex flex-col gap-4">
            <span className="w-full h-4 rounded-md bg-slate-200 animate-pulse"></span>
            <span className="w-1/2 h-8 rounded-md bg-slate-200 animate-pulse"></span>

            <span className="w-full h-8 rounded-md bg-slate-200 animate-pulse"></span>

            <span className="w-full h-1/2 rounded-md bg-slate-200 animate-pulse flex flex-col items-center justify-center">
                <span className="text-md text-slate-500">Aguarde só mais um pouco....</span>
            </span>

            <span className="w-full h-8 rounded-md bg-slate-200 animate-pulse"></span>

            <span className="w-full h-4 rounded-md bg-slate-200 animate-pulse"></span>
        </div>
    </div>
}