import React, { useCallback, useEffect, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';
import { IMFileType } from '~/models/FileTypes';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import { Container, MembersNameBox } from './styles';
import BoxDocumentGroups from '../BoxDocumentGroups';
import Seller from './Partials/Seller';

const SellersDocuments: React.FC = () => {
  const { serviceOrder, participants } = useServiceOrder();
  const [sellerSelected, setSellerSelected] = useState<number>(1);
  const [seeMore, setSeeMore] = useState(
    serviceOrder.status_id === 9 || serviceOrder.status_id === 11
  );
  const [fileTypes, setFileTypes] = useState<IMFileType[]>([]);
  const [loading, setLoading] = useState(false);

  const loadDocumentLists = useCallback(async () => {
    try {
      setLoading(true);
      const params = { vendedor: sellerSelected };
      await api
        .get<IMFileType[]>(`builders/file-types/${serviceOrder.id}`, {
          params: {
            ids: [1, 3, 4],
            ...params,
          },
        })
        .then((response) => {
          setFileTypes(response.data);
        });
    } catch (err) {
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os tipos de documentos!',
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [serviceOrder.id, sellerSelected]);

  useEffect(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  const handleUpdateFileTypes = useCallback(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  return (
    <div>
      <div className="mt-4">
        <b>Dados dos vendedores</b>
      </div>
      <Container className="px-2 mt-2 pb-2 d-flex flex-column">
        <div className="d-flex align-items-center w-full">
          <MembersNameBox className="col-10">
            {participants && (
              <>
                {Array.from(
                  Array(serviceOrder.terreno?.qtdProprietarios ?? 1).keys()
                ).map((value) => (
                  <Seller
                    key={value}
                    sellerId={value + 1}
                    onSelect={() => setSellerSelected(value + 1)}
                    borderSelected={sellerSelected === value + 1}
                  />
                ))}
              </>
            )}
          </MembersNameBox>
          <div className="col-2 d-flex justify-content-end me-4">
            {!seeMore ? (
              <button
                type="button"
                onClick={() => setSeeMore(true)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver mais <MdKeyboardArrowDown />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setSeeMore(false)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver menos <MdKeyboardArrowUp />
              </button>
            )}
          </div>
        </div>
        {seeMore && (
          <div>
            {fileTypes.map((fileType) => (
              <div key={fileType.id} className="mt-4">
                <BoxDocumentGroups
                  fileType={fileType}
                  seller={sellerSelected}
                  onChangeFiles={handleUpdateFileTypes}
                />
              </div>
            ))}
          </div>
        )}
      </Container>
      <Loading active={loading} />
    </div>
  );
};

export default SellersDocuments;
