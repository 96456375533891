import React, { useCallback, useState } from 'react';

import { Container } from './styles';

interface IColor {
  color: string;
}

interface IMembersName {
  sellerId: number;
  onSelect: () => void;
  borderSelected: boolean;
}

const Seller: React.FC<IMembersName> = ({
  sellerId,
  onSelect,
  borderSelected,
}) => {
  const [colors] = useState<IColor[]>([
    { color: '#01CC9D' },
    { color: '#bd3acb' },
    { color: '#8675FE' },
    { color: '#1fb6f2' },
    { color: '#f2d21f' },
    { color: '#5b9cb6' },
    { color: '#a3efd4' },
  ]);

  const handleClick = useCallback(() => {
    onSelect();
  }, [onSelect]);

  return (
    <Container
      key={sellerId}
      onClick={() => handleClick()}
      style={{
        border: borderSelected ? '2px solid #C9CACA' : '2px solid transparent',
      }}
      className="my-2"
    >
      <div
        className="name-initials mx-2"
        style={{
          backgroundColor: colors[sellerId - 1].color,
        }}
      >
        {`V${sellerId}`}
      </div>
      <span>
        <b className="mx-2">{`Vendedor ${sellerId}`}</b>
      </span>
    </Container>
  );
};

export default Seller;
