import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserDefault from '~/components/UserDefault';
import { useProfile } from '~/hooks/Profile';
import { GeneralContainer } from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const { validateEngineerData } = useProfile();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  return (
    <GeneralContainer className="ms-lg-5">
      <div className="py-4" />
      <div className="mt-5 mt-lg-0 mb-5">
        <div className="container mt-5 mt-lg-0">
          <UserDefault projetos />
        </div>
      </div>
    </GeneralContainer>
  );
};

export default Header;
