import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  padding: 0.2rem 0.7rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .name-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    height: auto;
    width: 2.5rem;
    border-radius: 50%;
    padding: 0.5rem;
  }
`;
