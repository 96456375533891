import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback, useRef, useEffect, useState
} from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { useDefaultData } from '~/hooks/DefaultData';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';

import InputRadio from '~/components/InputRadio';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMParticipant } from '~/models/Participant';
import api from '~/services/api';
import { formatToFloat } from '~/utils/format';
import { Modal } from './styles';

interface IDataForm {
  id?: number;
  name?: string;
  salary?: string;
  document?: string;
  profession?: string;
  fgts?: boolean;
  fgts_amount?: string;
  pis_number?: string;
  school_level_id?: number;
}

interface IModalProps {
  show: boolean;
  onHide: () => void;
}
interface ISchoolingLevel {
  id: number;
  schooling_level: string;
}
const ModalFormAddParticipant: React.FC<IModalProps> = ({ show, onHide }) => {
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const { schooling_levels, setDefaultData } = useDefaultData();
  const [updatadedSchoolingLevels, setUpdatadedSchoolingLevels] = useState<
    IOption[]
  >([]);
  const [schooling_level_type] = useState({} as ISchoolingLevel);
  const [hasFGTS, setHasFGTS] = useState(false);

  const handleUpdateSchoolLevel = useCallback(() => {
    const newSchoolingLevel = schooling_levels.map((schoolingLevel) => {
      if (schoolingLevel.id === schooling_level_type.id) {
        return { ...schoolingLevel, selected: true };
      }
      return { ...schoolingLevel };
    });
    setUpdatadedSchoolingLevels(newSchoolingLevel);
  }, [schooling_level_type.id, schooling_levels]);

  const handleChangeSchoolLevel = useCallback(
    (option) => {
      const newSchoolingLevel = schooling_levels.map((schoolingLevel) => {
        if (schoolingLevel.id === option.id) {
          return { ...schoolingLevel, selected: true };
        }
        return { ...schoolingLevel };
      });
      setUpdatadedSchoolingLevels(newSchoolingLevel);
    },
    [schooling_levels]
  );

  useEffect(() => {
    if (schooling_levels.length === 0) {
      setDefaultData();
    }
  }, [setDefaultData, schooling_levels]);

  useEffect(() => {
    handleUpdateSchoolLevel();
  }, [handleUpdateSchoolLevel]);

  const handleChangeHasFGTS = useCallback((option) => {
    setHasFGTS(option.value === 'Sim');
  }, []);

  const handleSubmit = useCallback(
    async (dataForm: IDataForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          salary: Yup.string().required('O valor da renda é obrigatório'),
          document: Yup.string().required('O documento é obrigatório'),
          profession: Yup.string().required('A profissão é obrigatório'),
          school_level_id: Yup.string().required(
            'A nível de escolaridade é obrigatório'
          ),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const formData: IMParticipant = {
          type: 'participant',
          os_id: serviceOrder?.id,
          nome: dataForm.name,
          profissao: dataForm.profession,
          numPis: dataForm.pis_number,
          nvEscolar_id: dataForm.school_level_id,
          cpf: dataForm.document,
          fgts: dataForm.fgts ? 1 : 0,
          vlrFgts: dataForm.fgts_amount
            ? formatToFloat(dataForm.fgts_amount)
            : 0,
          vlrRenda: dataForm.salary ? formatToFloat(dataForm.salary) : 0,
        };

        const newParticipant = {} as IMParticipant;
        await api
          .post<IMParticipant>('/builders/participants', formData)
          .then((response) => {
            newParticipant.id = response.data.id;
            newParticipant.nome = response.data.nome;
            newParticipant.profissao = response.data.profissao;
            newParticipant.numPis = response.data.numPis;
            newParticipant.nvEscolar_id = response.data.nvEscolar_id;
            newParticipant.cpf = response.data.cpf;
            newParticipant.fgts = response.data.fgts;
            newParticipant.vlrFgts = response.data.vlrFgts;
            newParticipant.vlrRenda = response.data.vlrRenda;
          });

        const participantsSO = serviceOrder?.participantes;
        participantsSO?.push(newParticipant);

        setServiceOrder({
          ...serviceOrder,
          participantes: participantsSO,
        });

        onHide();

        Swal.fire({
          icon: 'success',
          title: 'Cadastro efetuado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [serviceOrder, setServiceOrder, onHide]
  );

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
        <Modal.Header className="border-none ps-4 pt-4" closeButton>
          <h3>Cadastrar informações</h3>
        </Modal.Header>
        <Modal.Body className="mb-4 mx-2">
          <p>Selecione o perfil de quem deseja completar:</p>

          <div className="row mt-5">
            <h4>Dados:</h4>
            <div className="col-lg-12 mb-3 mt-4">
              <label>
                Nome Completo: <b className="text-primary">*</b>
              </label>

              <Input
                className="form-input"
                name="name"
                placeholder="Nome Completo"
              />
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                Renda: <b className="text-primary">*</b>
              </label>
              <InputMask
                kind="money"
                className="form-input"
                name="salary"
                placeholder="R$ 0,00"
              />
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                CPF: <b className="text-primary">*</b>
              </label>
              <InputMask
                kind="cpf"
                className="form-input"
                name="document"
                placeholder="000.000.000-00"
              />
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                Profissão: <b className="text-primary">*</b>
              </label>
              <Input
                className="form-input"
                name="profession"
                placeholder="Profissão"
              />
            </div>
            <div className="col-lg-3 mb-3 mt-4">
              <label>Possui FGTS ?</label>
              <InputRadio
                name="fgts"
                options={[
                  { id: 1, value: 'Sim' },
                  { id: 0, value: 'Não' },
                ]}
                selected={
                  hasFGTS ? { id: 1, value: 'Sim' } : { id: 0, value: 'Não' }
                }
                onChange={handleChangeHasFGTS}
                className="mt-4"
              />
            </div>
            {hasFGTS && (
              <div className="col-lg-6 mb-3 mt-4">
                <label>Valor FGTS</label>
                <InputMask
                  kind="money"
                  className="form-input"
                  name="fgts_amount"
                  placeholder="R$ 0,00"
                />
              </div>
            )}
            <div className="col-lg-6 mb-3 mt-4">
              <label>PIS:</label>
              <Input
                className="form-input"
                name="pis_number"
                placeholder="PIS"
              />
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                Nível de escolaridade: <b className="text-primary">*</b>
              </label>
              <Select
                type="name"
                placeholder="Seleccione"
                name="school_level_id"
                className="form-input"
                options={updatadedSchoolingLevels}
                onChange={handleChangeSchoolLevel}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons-group">
            <div>
              <button type="submit" className="btn-submit py-3">
                Completar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalFormAddParticipant;
