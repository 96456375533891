import React from 'react';

import Input from '~/components/Input';
import MaskInput from '~/components/InputMask';

import { Container } from './styles';

interface IDefaultValuesOwner {
  name: string;
  email: string;
  cellphone: string;
}

interface IOwnerBox {
  id: number;
  nameValidate: boolean;
  emailValidate: boolean;
  cellphoneValidate: boolean;
  defaultValues: IDefaultValuesOwner;
  ownerNumber: number;
}

const OwnerBox: React.FC<IOwnerBox> = ({
  id,
  nameValidate,
  emailValidate,
  cellphoneValidate,
  defaultValues,
  ownerNumber,
}) => (
  <Container className="row border" key={id}>
    <div className="col-12 title-owner-element mt-2">{`Proprietário ${
      ownerNumber < 10 ? `0${ownerNumber}` : ownerNumber
    }`}
    </div>

    <div className="row">
      <div className="col-md-4">
        <p>
          Nome Completo: <b className="text-primary">*</b>
        </p>
        <Input
          name={`name${id}`}
          className="input"
          placeholder="Digite"
          defaultValue={defaultValues.name}
        />
        {nameValidate && (
        <p className="text-danger error">Informe o nome completo</p>
        )}
      </div>
      <div className="col-md-4">
        <p>
          E-mail: <b className="text-primary">*</b>
        </p>
        <Input
          name="email"
          className="input"
          type="email"
          placeholder="email@example.com"
          defaultValue={defaultValues.email}
        />
        {emailValidate && <p className="text-danger error">Infome o email</p>}
      </div>
      <div className="col-md-4">
        <p>
          Celular: <b className="text-primary">*</b>
        </p>
        <MaskInput
          kind="cel-phone"
          name="cellphone"
          placeholder="(  ) "
          className="input cellphone-input"
          value={defaultValues.cellphone}
        />
        {cellphoneValidate && (
        <p className="text-danger error">Informe o celular completo</p>
        )}
      </div>
    </div>
  </Container>
);

export default OwnerBox;
