import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import EditTable from '~/assets/icons/edit-table.svg';
import { Container } from './styles';

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
}

interface IParams {
  simulation: ISimulation;
}
const BudgetAmount: React.FC<IParams> = ({ simulation }) => {
  const history = useHistory();
  const handleClickEditar = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/simulador/resultado`);
  }, [history]);
  return (
    <Container className="container row card-dashboard justify-content-center  p-4 mb-5">
      <div className="m-4 mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do orçamento</h2>
          <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button>
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-4">
        <div className="dot me-3" />
        <p className="mb-0 sub-text">75% do valor financiado</p>
        <p className="mb-0 font-weight-700 ms-auto">
          {' '}
          R${' '}
          {new Intl.NumberFormat('pt-BR', {
            maximumSignificantDigits: 10,
          }).format(
            simulation.vlrFinanciado
              + simulation.vlrEntrada
              + simulation.vlrFgts
              + simulation.vlrSubsidio
              - simulation.vlrTerreno
          )}
        </p>
      </div>
      <hr className="m-4" />
      <div className="m-4 mt-0">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-0">Valor do terreno</h2>
          <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button>
        </div>
      </div>
      <div className="d-flex subtitle align-items-center mx-4">
        <div className="dot dot-2 me-3" />
        <p className="mb-0 sub-text">75% do valor financiado</p>
        <p className="mb-0 font-weight-700 ms-auto">
          {' '}
          R${' '}
          {new Intl.NumberFormat('pt-BR', {
            maximumSignificantDigits: 10,
          }).format(simulation.vlrTerreno)}
        </p>
      </div>
    </Container>
  );
};

export default BudgetAmount;
