/* FIXEM - eslint-disable */
import { format, formatDistanceStrict, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

import pushNotification from '~/utils/pushNotification';
import api from '../api';

interface IMessage {
  id: number;
  user_id: number;
  content: string;
  date: string;
}

interface INotification {
  id: number;
  content: string;
  url?: string;
  read: boolean;
  date: string;
}

interface INotificationSocketEvents {
  user_id: number;
  opened: boolean;
  setNewNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setNotifications: React.Dispatch<React.SetStateAction<INotification[]>>;
}

let socket: WebSocket;

export const initializeSocketConnection = (
  path: string,
  setSocketInitialized: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/${path}`);
  setSocketInitialized(true);
};

export const initializeChatSocketEvents = (
  user_id: number,
  chat_id: number,
  setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>
): void => {
  socket.onopen = () => {
    const data = {
      user_id,
      chat_id,
    };

    socket.send(JSON.stringify(data));
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (parseInt(data.chat_id, 10) === chat_id) {
      const message = {
        id: data._id,
        user_id: data.user_id,
        date: format(new Date(), 'dd/MM/yyyy HH:mm'),
        content: data.message,
      };

      setMessages((state) => [message, ...state]);
    }
  };

  socket.onerror = (err) => {
    //
  };
};

export const initializeNotificationSocketEvents = ({
  user_id,
  opened,
  setNewNotification,
  setNotifications,
}: INotificationSocketEvents): void => {
  socket.onopen = () => {
    const data = {
      user_id,
    };

    socket.send(JSON.stringify(data));
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const date = formatDistanceStrict(parseISO(data.created_at), new Date(), {
      addSuffix: true,
      locale: ptBr,
    });

    const notification = {
      id: data._id,
      content: data.message,
      url: data.url,
      read: false,
      date,
    };

    if (!opened) {
      pushNotification(`${notification.content} ${notification.url || ''}`);
      setNewNotification(true);
    } else {
      api.put(`builders/notifications/${notification.id}`, {
        read: true,
      });
    }
    setNotifications((state) => [notification, ...state]);
  };

  socket.onerror = () => {
    //
  };
};

export const disconnectSocket = (): void => {
  if (socket) {
    socket.close();
  }
};
