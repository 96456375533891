/**
 * The Normalized way to clear all cache from BYH (Build Your House)
 * @description All function that do the samething of this function should be deprecate 
 * 
*/
export function clearTrash(){
    try {
        sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_EXTERIOR');
        sessionStorage.removeItem('MCF_BUY@ALREADY_INTERIOR_ENVIRONMENTS');
        sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_INTERIOR');
    } catch (error) {
        // do anything
    }
}