import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Icons
import areaIcon from '~/assets/icons/area-produto-imobiliario.svg';
import banheiraIcon from '~/assets/icons/banheira-produto-imobiliario.svg';
import carroIcon from '~/assets/icons/carrro-produto-imobiliario.svg';
import noImage from '~/assets/icons/no-image.svg';
import sofaIcon from '~/assets/icons/sofa-produto-imobiliario.svg';

// Interfaces
import { IHouseProps, IParams } from './types';

// Utils
import { formatPrice } from '~/utils/format';

export function House({
  id,
  name,
  house_area,
  vgv,
  parking_spaces,
  bathrooms,
  bedrooms,
  housePosition,
  photo_upload_id,
}: IHouseProps): JSX.Element {
  const { id: projectID, toStep } = useParams<IParams>();
  const history = useHistory();

  function handleToAddHouseStep(toHouse: number) {
    try {
      if ((toHouse !== 0 && !toHouse) || toHouse <= -1) throw new Error('');

      history.push(
        `/produtos-imobiliario/novo-projeto/${projectID}/${5}/${toHouse}`
      );
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel selecionar a casa',
        icon: 'warning',
      });
    }
  }

  return (
    <div    
      role="button"
      tabIndex={0}
      onClick={() => handleToAddHouseStep(housePosition)}
      onKeyUp={() => handleToAddHouseStep(housePosition)}
      className="
        house-box-item
        flex flex-col items-start
        justify-start md:gap-[0.625rem]
        max-sm:gap-[0.5rem]
        md:w-[9.875rem] max-sm:min-w-[9.937rem]
        max-sm:max-w-[9.937rem]
        md:h-[11.375rem] rounded-[0.312rem]
        pb-[0.375rem]
      bg-white
        overflow-hidden shadow-sm
        hover:shadow-md transition-shadow
        duration-[0.3s] cursor-pointer"
    >
      <div className="flex-1 flex flex-col w-full max-h-[75%] max-sm:min-h-[4rem] max-sm:max-h-[4rem] items-center justify-center overflow-hidden object-fill bg-gray-100">
        {!photo_upload_id && (
          <img
            src={noImage}
            alt="Imagem do projeto"
            className="max-w-[3.187rem] h-auto"
            loading="lazy"
          />
        )}
        {photo_upload_id && (
          <img
            src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/houses/${id}/photo`}
            alt="Imagem do projeto"
            className="max-w-full h-auto"
            loading="lazy"
          />
        )}
      </div>
      <div className="flex-1 w-full flex flex-col pl-[0.625rem] pr-[0.625rem] gap-[1rem]">
        <span className="text-[#707070] font-semibold leading-[1.1rem] text-center w-full">
          {name}
        </span>
        <div className="w-full flex flex-row flex-nowrap items-start justify-between">
          <div className="flex-1 max-w-[50%] flex flex-col flex-nowrap items-start justify-start ">
            <span className="flex-1 flex flex-row items-center gap-[0.125rem]">
              <img src={areaIcon} alt="icone generico de marcação" />
              <span className="font-[400] text-#B8B9BA text-[0.75rem]">
                {house_area}m²
              </span>
            </span>
            <span className="flex-1 flex flex-row items-center gap-[0.125rem]">
              <span className="font-[400] text-#707070 text-[0.75rem] uppercase leading-[1.375rem]">
                R$
              </span>
              <span className="font-[400] text-#B8B9BA text-[0.75rem]">
                {formatPrice(vgv).split('R$')[1]}
              </span>
            </span>
          </div>
          <div className="flex-1 max-w-[30%] flex flex-col flex-nowrap items-end justify-start">
            <span className="flex-1 flex flex-row items-center justify-start gap-[0.125rem]">
              <span className="w-[3rem] h-auto flex flex-col items-end justify-start">
                <img src={sofaIcon} alt="icone generico de marcação" />
              </span>
              <span
                className="w-[1rem] h-auto flex flex-col items-end justify-start overflow-hidden"
                title={`${bedrooms}`}
              >
                <span className="font-[400] text-#B8B9BA text-[0.75rem]">
                  {bedrooms}
                </span>
              </span>
            </span>
            <span className="flex-1 flex flex-row items-center justify-center gap-[0.125rem]">
              <span className="w-[3rem] h-auto flex flex-col items-end justify-start">
                <img src={banheiraIcon} alt="icone generico de marcação" />
              </span>
              <span
                className="w-[1rem] h-auto flex flex-col items-end justify-start overflow-hidden"
                title={`${bathrooms}`}
              >
                <span className="font-[400] text-#B8B9BA text-[0.75rem]">
                  {bathrooms}
                </span>
              </span>
            </span>
            <span className="flex-1 flex flex-row items-center justify-end gap-[0.125rem]">
              <span className="w-[3rem] h-auto flex flex-col items-end justify-start">
                <img src={carroIcon} alt="icone generico de marcação" />
              </span>
              <span
                className="w-[1rem] h-auto flex flex-col items-end justify-start overflow-hidden"
                title={`${parking_spaces}`}
              >
                <span className="font-[400] text-#B8B9BA text-[0.75rem]">
                  {parking_spaces}
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
