import React from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import AmortizationSimulation from './Components/AmortizationSimulation';
import BudgetAmount from './Components/BudgetAmount';
import DownloadDocumentsBox from './Components/DownloadDocumentsBox';
import PerformanceComparator from './Components/PerformanceComparator';
import PersonalInformationBox from './Components/PersonalInformationBox';
import ProjeteiSuggestion from './Components/ProjeteiSuggestion';
import PropertyEnvironments from './Components/PropertyEnvironments';
import PropertyValuation from './Components/PropertyValuation';
import ResourceComposition from './Components/ResourceComposition';
import SimulationConditions from './Components/SimulationConditions';
import AguardandoAnalise from './Components/Warnings/AguardandoAnalise';

import AguardandoAssinatura from './Components/Warnings/AguardandoAssinatura';
import AlgoErrado from './Components/Warnings/AlgoErrado';
import AutorizacaoEngenharia from './Components/Warnings/AutorizacaoEngenharia';
import AutorizacaoEnviada from './Components/Warnings/AutorizacaoEnviada';
import CompleteData from './Components/Warnings/CompleteData';
import ContratoPronto from './Components/Warnings/ContratoPronto';
import EmAnalise from './Components/Warnings/EmAnalise';
import InformarAssinatura from './Components/Warnings/InformarAssinatura';
import LaudoAprovado from './Components/Warnings/LaudoAprovado';
import Parabens from './Components/Warnings/Parabens';

import AguardandoLiberacao from './Components/Warnings/AguardandoLiberacao';
import CreditoAprovado from './Components/Warnings/CreditoAprovado';
import CreditoReprovado from './Components/Warnings/CreditoReprovado';
import LaudoReprovado from './Components/Warnings/LaudoReprovado';
import { Mock } from './Partials/Mock';
import {
  CardBox,
  CardBoxInformationsSub,
  EnvironmentContainer,
} from './styles';

interface IStepMenuDataOs {
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const ServiceOrderData: React.FC<IStepMenuDataOs> = ({ setContentData }) => {
  const { serviceOrder } = useServiceOrder();

  return (
    <>
      <PersonalInformationBox />

      {Object.keys(serviceOrder).length <= 0 ? (
        <Mock />
      ) : (
        <>
          {serviceOrder.status && (
            <>
              {serviceOrder.status.id === 2
                && !serviceOrder?.cliente?.nvEscolar_id && (
                  <div className="row mt-2">
                    <CompleteData setContentData={setContentData} />
                  </div>
              )}

              {serviceOrder.status.id === 4 && (
                <div className="row mt-5 mb-5">
                  <EmAnalise />
                </div>
              )}

              {serviceOrder.status.id === 7 && (
                <div className="row mt-5 mb-5">
                  <CreditoReprovado />
                </div>
              )}

              {serviceOrder.status.id === 8 && (
                <div className="row mt-5 mb-5">
                  <CreditoAprovado />
                </div>
              )}

              {serviceOrder.status.id === 11 && (
                <div className="row mt-2">
                  <AlgoErrado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 13 && (
                <div className="row mt-2">
                  <AutorizacaoEngenharia />
                </div>
              )}

              {serviceOrder.status.id === 14 && (
                <div className="row mt-2">
                  <AutorizacaoEnviada />
                </div>
              )}

              {serviceOrder.status.id === 15 && (
                <div className="row mt-2">
                  <LaudoReprovado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 17 && (
                <div className="row mt-2">
                  <LaudoAprovado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 18 && (
                <div className="row mt-2">
                  <AguardandoAssinatura setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 19 && (
                <div className="row mt-2">
                  <AguardandoAnalise />
                </div>
              )}

              {serviceOrder.status.id === 21 && (
                <div className="row mt-2">
                  <AguardandoLiberacao />
                </div>
              )}


              {serviceOrder.status.id === 22 && (
                <div className="row mt-2">
                  <ContratoPronto />
                </div>
              )}

              {serviceOrder.status.id === 23 && (
                <div className="row mt-2">
                  <Parabens />
                </div>
              )}
              {serviceOrder.status.id === 25 && (
                <div className="row mt-2">
                  <InformarAssinatura />
                </div>
              )}
            </>
          )}
          <DownloadDocumentsBox />
          {serviceOrder.finalidade_id !== 4
            && serviceOrder.finalidade_id !== 5
            && serviceOrder.finalidade_id !== 6 && (
              <>
                <CardBox className="row mt-4 container_dashboard_info align-items-start">
                  <ResourceComposition />
                  <PropertyValuation />
                  <PerformanceComparator />
                </CardBox>
                <CardBoxInformationsSub className="row container_dashboard_info align-items-start">
                  <AmortizationSimulation />
                  <SimulationConditions />
                  <BudgetAmount />
                </CardBoxInformationsSub>
                <EnvironmentContainer>
                  {serviceOrder.finalidade_id !== 4
                    && serviceOrder.finalidade_id !== 5 && (
                      <PropertyEnvironments />
                  )}

                  <ProjeteiSuggestion />
                </EnvironmentContainer>
              </>
          )}
        </>
      )}
    </>
  );
};

export default ServiceOrderData;
