import React from 'react';
import { BsEmojiLaughingFill } from 'react-icons/bs';
import { CompleteData } from './styles';

const Parabens: React.FC = () => (
  <CompleteData>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <BsEmojiLaughingFill size={40} color="#39A887" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>Parabéns! Deu tudo certo 🎉</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-7">
            <p>Agora é só iniciar as medições.</p>
          </div>
        </div>
      </div>
    </div>
  </CompleteData>
);

export default Parabens;
