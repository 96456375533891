import React from 'react';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';
import { Container } from './styles';

interface IParams {
  setStep: (step: number) => void;
}

const CreditAnalysis: React.FC<IParams> = ({ setStep }) => {
  const { serviceOrder } = useServiceOrder();

  return (
    <Container>
      <div className="row px-3">
        <div className="col-lg-6 mb-5">
          <div>
            Valor: <b className="text-primary">*</b>
          </div>
          <div className="mt-4 input">
            {formatCurrency(serviceOrder.simulacao?.vlrAprovado ?? 0)}
          </div>
        </div>
        <div className="col-lg-6 mb-5">
          <div>
            Orientações do correspondente quanto a aprovação de crédito:{' '}
            <b className="text-primary">*</b>
          </div>
          <div className="mt-4 input">
            {serviceOrder.simulacao?.comentario_analise}
          </div>
        </div>
      </div>
      <div className="col-lg-auto d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary fw-semibold px-5"
          onClick={() => setStep(2)}
        >
          Próximo
        </button>
      </div>
    </Container>
  );
};

export default CreditAnalysis;
