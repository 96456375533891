import React from 'react';
import { AiFillEdit } from 'react-icons/ai';

import { ITask } from '~/models/Home/Task';
import randomColor from '~/utils/colorGenerator';

import { BoxTag, Modal } from './styles';

interface IModalTask {
  show: boolean;
  taskSelected: ITask;
  onHandleClose(): void;
  onSetShowModalTask(value: boolean): void;
}

const ModalTask: React.FC<IModalTask> = ({
  show,
  taskSelected,
  onHandleClose,
  onSetShowModalTask,
}) => (
  <Modal show={show} onHide={onHandleClose}>
    {Object.keys(taskSelected).length > 0 && (
      <div className="p-4">
        <Modal.Header className="border-0" closeButton>
          <div className="d-flex align-items-center">
            <Modal.Title className="mb-0">{taskSelected.title}</Modal.Title>
            {taskSelected.user_id !== null || '' ? (
              <button
                type="button"
                className="border-0 bg-transparent ms-2"
                onClick={() => onSetShowModalTask(true)}
              >
                <AiFillEdit size={24} color="#707070" />
              </button>
            ) : (
              <></>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              <small>Data:</small>
              <p className="mb-0 ms-2 value">{taskSelected.date}</p>
            </div>
            <div className="col-lg-8 d-flex align-items-center">
              <small>Horário:</small>
              <p className="mb-0 ms-2 value">
                {taskSelected.period_start} - {taskSelected.period_end}
              </p>
            </div>
            <div className="col-12 d-flex flex-wrap my-4">
              {taskSelected.categories.map((category) => (
                <BoxTag
                  key={category}
                  color={randomColor()}
                  className="rounded-pill px-3 py-1 d-flex align-items-center"
                >
                  <span className="d-inline-block ms-2">{category}</span>
                </BoxTag>
              ))}
            </div>
            {taskSelected.description && (
              <div className="col-12">
                <small className="d-block mb-2">Descrição:</small>
                <div className="row">
                  <div
                    className="col-auto scroll"
                    // FIXME - eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: taskSelected.description,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </div>
    )}
  </Modal>
);

export default ModalTask;
