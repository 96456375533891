import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 8.5%;
  .p-relative {
    position: relative;
  }

  .numeros {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.0241176em;

    /* Subtitle */

    color: #707070;
  }

  .sub-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Subtitle */

    color: #707070;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;

    /* Title */

    color: #414142;
  }
  hr {
    background-color: #c7c7c7;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 25px;
    background-color: #ff9900;
  }
  .dot-2 {
    background-color: #48dcfc !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  @media (max-width: 988px) {
    //display: unset;
    //height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }
`;
