import React, { useCallback, useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import Loading from '~/components/Loading';
import { IOption } from '~/components/Select';

import avatar from '~/assets/defaults/avatar.png';
import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import ModalAdress from './Components/Modals/Adress';
import ModalContact from './Components/Modals/Contact';
import ModalName from './Components/Modals/Name';
import { Avatar, Container } from './styles';

interface IClients {
  id: number;
  nome: string;
  documento: string;
  profissao: string;
  email: string;
  bairro: string;
  celular: string;
  cep: string;
  complemento: string;
  endereco: string;
  estado_id: string;
  municipio: string;
  numero: number;
}

interface IProfileProps {
  client: IClients;
}

interface IMunicipality {
  id: number;
  name: string;
}

interface IState {
  name: string;
}

const Profile: React.FC = () => {
  const history = useHistory();
  const { signOut, user } = useAuthContext();
  const [showEditName, setShowEditName] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [profile, setProfile] = useState<IClients>({} as IClients);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState('');
  const [municipalities, setMunicipalities] = useState<IOption[]>([]);

  useEffect(() => {
    if (user && user?.id) {
      loadUserData();
    }
  }, []);

  const handleClickEditName = useCallback(() => {
    setShowEditName(true);
  }, []);

  const handleClickEditContact = useCallback(() => {
    setShowEditContact(true);
  }, []);

  const handleClickEditAddress = useCallback(() => {
    setShowEditAddress(true);
  }, []);
  const handleBack = (): void => {
    history.goBack();
  };

  const loadUserData = useCallback(() => {
    setLoading(true);
    api.get<IProfileProps>(`builders/users/${user!.id}`).then((response) => {
      setProfile({
        id: response.data.client.id,
        nome: response.data.client.nome,
        documento: response.data.client.documento,
        profissao: response.data.client.profissao,
        email: response.data.client.email,
        endereco: response.data.client.endereco,
        bairro: response.data.client.bairro,
        cep: response.data.client.cep,
        celular: response.data.client.celular,
        complemento: response.data.client.complemento,
        estado_id: response.data.client.estado_id,
        municipio: response.data.client.municipio,
        numero: response.data.client.numero,
      });
    });
  }, [user]);

  const handleLoadMunicipality = useCallback(async () => {
    const response = await api.get<IMunicipality[]>(
      `municipalities/state/${profile.estado_id}`
    );

    const data: IOption[] = response.data.map((municipalityData) => ({
      id: municipalityData.id,
      value: municipalityData.name,
      selected: true,
    }));

    setMunicipalities(
      data.filter((munici) => Number(munici.id) === Number(profile.municipio))
    );

    setLoading(false);
  }, [profile.estado_id, profile.municipio]);

  // CARREGAR ESTADO
  const handleLoadState = useCallback(() => {
    api.get<IState>(`builders/states/${profile.estado_id}`).then((response) => {
      const { data } = response;

      setStates(data.name);
    });
  }, [profile.estado_id]);

  useEffect(() => {
    handleLoadMunicipality();
    handleLoadState();
  }, [handleLoadMunicipality, handleLoadState]);

  return (
    <Container>
      <div className="container py-5 px-4">
        <div className="row align-items-center">
          <div className="col-6 col-lg-12 mb-lg-5 order-0">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4"
                onClick={handleBack}
              >
                <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
              </button>
              <p className="h5 h4-lg mb-0 ms-3 fw-semibold text-dark-gray">
                Perfil
              </p>
            </div>
          </div>
          <div className="col-lg-2 order-1 order-lg-0 mt-5 mt-lg-0">
            <Avatar src={avatar} className="mx-auto" />
          </div>
          <div className="col-lg-9 order-1 order-lg-0">
            <div>
              <div className="d-flex align-items-center mb-2 justify-content-center justify-content-lg-start mt-4 mt-lg-0">
                <h1 className="h4 h3-lg mb-0 me-3 me-lg-5 fw-semibold text-dark-gray">
                  {profile.nome}
                </h1>
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={handleClickEditName}
                >
                  <MdEdit size={24} color="#707070" />
                </button>
              </div>
              <p className="mb-0 small normal-lg text-center text-lg-start">
                Cliente Harvey
              </p>
            </div>
          </div>
          <div className="col-6 col-lg-1 order-0">
            <button
              type="button"
              className="ms-auto d-flex align-items-center bg-transparent border-0 btn-logout fw-semibold"
              onClick={() => signOut()}
            >
              <span>Sair</span>
              <FiLogOut size={24} color="#ff1a50" className="ms-3" />
            </button>
          </div>
          <div className="col-12 mt-5 order-1 order-lg-0">
            <div className="row border rouded-customs py-5 px-3">
              <div className="col-12 mb-4">
                <div className="d-flex align-items-center">
                  <p className="mb-0 me-3 fw-semibold text-dark-gray">
                    Contato
                  </p>
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={handleClickEditContact}
                  >
                    <MdEdit size={17} color="#707070" />
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="fw-medium small text-dark-gray mb-2">
                  <span className="small normal-lg fw-medium">E-mail</span>
                </p>
                <p className="fw-semibold small normal-lg">{profile.email}</p>
              </div>
              <div className="col-lg-6">
                <p className=" fw-medium small text-dark-gray mb-2">
                  <span className="small normal-lg fw-medium">Celular</span>
                </p>
                <p className="fw-semibold small normal-lg">{profile.celular}</p>
              </div>
              <div className="col-12 border-top mt-4 pt-4">
                <div className="d-flex align-center mb-4">
                  <p className="mb-0 me-3 fw-semibold text-dark-gray">
                    Endereço
                  </p>
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={handleClickEditAddress}
                  >
                    <MdEdit size={17} color="#707070" />
                  </button>
                </div>
                <div className="row">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">Rua</span>
                      </p>
                      <p className="fw-bold small normal-lg">
                        {profile.endereco !== null ? profile.endereco : '-'}
                      </p>
                    </div>
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">
                          Bairro
                        </span>
                      </p>
                      <p className="fw-bold small normal-lg">
                        {profile.bairro !== null ? profile.bairro : '-'}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">CEP</span>
                      </p>
                      <p className="fw-semibold small normal-lg">
                        {profile.cep !== null ? profile.cep : '-'}
                      </p>
                    </div>
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">N°</span>
                      </p>
                      <p className="fw-semibold small normal-lg">
                        {profile.numero !== null ? profile.numero : '-'}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">
                          Estado
                        </span>
                      </p>
                      <p className="fw-semibold small normal-lg">
                        {profile.estado_id !== null ? states : '-'}
                      </p>
                    </div>
                    <div className="col-md-6 mb-2">
                      <p className="fw-medium small text-dark-gray mb-2">
                        <span className="small normal-lg fw-medium">
                          Cidade
                        </span>
                      </p>
                      <p className="fw-semibold small normal-lg">
                        {profile.municipio !== null
                          ? municipalities.map((munici) => munici.value)
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalName
        show={showEditName}
        profileValues={{
          id: profile.id,
          nome: profile.nome,
          documento: profile.documento,
          profissao: profile.profissao,
        }}
        onHide={() => setShowEditName(false)}
      />
      <ModalContact
        show={showEditContact}
        user={{
          id: profile.id,
          nome: profile.nome,
          documento: profile.documento,
          profissao: profile.profissao,
        }}
        contact={{ email: profile.email, celular: profile.celular }}
        onHide={() => setShowEditContact(false)}
      />
      <ModalAdress
        show={showEditAddress}
        user={{
          id: profile.id,
          nome: profile.nome,
          documento: profile.documento,
          profissao: profile.profissao,
        }}
        adressData={{
          endereco: profile.endereco,
          bairro: profile.bairro,
          estado_id: profile.estado_id,
          municipio: profile.municipio,
          numero: profile.numero,
          cep: profile.cep,
        }}
        onHide={() => setShowEditAddress(false)}
      />
      <Loading active={loading} />
    </Container>
  );
};

export default Profile;
