import styled from 'styled-components';

export const Container = styled.div`
  transform: rotateX(180deg);
  .box-column {
    display: flex;
    overflow-x: auto;
    ::-webkit-scrollbar {
      height: 12px;
      cursor: pointer;
    }
  }
  .btn-add-column {
    gap: 0.4rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #39a887;
    border-radius: 48px;
    border: none;
    padding: 14px 16px;
    color: #fff;
    width: 175px;
    height: 36px;
  }
  #board_one {
    background: #ffe0da;
  }
  .div-horizontal {
    min-width: 550%;
    scrollbar-width: none;
  }

  .scroll {
    min-width: calc(405px * ${(props) => props.color});
  }
`;
