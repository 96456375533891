import React, { useCallback, useState } from 'react';

import initialsName from '~/utils/initialsName';
import { IMParticipant } from '~/models/Participant';

import { Container } from './styles';

interface IColor {
  color: string;
}

interface IMembersName {
  participant: IMParticipant;
  number: string;
  onSelect: (participantData: IMParticipant, color: string) => void;
  borderSelected: boolean;
}

const ParticipantsName: React.FC<IMembersName> = ({
  participant,
  number,
  onSelect,
  borderSelected,
}) => {
  const [colors] = useState<IColor[]>([
    { color: '#01CC9D' },
    { color: '#bd3acb' },
    { color: '#8675FE' },
    { color: '#1fb6f2' },
    { color: '#f2d21f' },
    { color: '#5b9cb6' },
    { color: '#a3efd4' },
  ]);

  const handleClick = useCallback(
    (participantData: IMParticipant) => {
      onSelect(participantData, colors[Number(number) % 2 === 0 ? 0 : 1].color);
    },
    [colors, number, onSelect]
  );

  const handleUpperCaseName = useCallback(() => {
    if (participant.nome) {
      const nameSplited = participant.nome.split(' ');
      const nameSplitedUpperCase = nameSplited.map(
        (name) => name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase()
      );
      return nameSplitedUpperCase[0];
    }

    return '';
  }, [participant.nome]);

  return (
    <Container
      key={(participant.type || '') + (participant.id || 0)}
      onClick={() => handleClick(participant)}
      style={{
        border: borderSelected ? '2px solid #C9CACA' : '2px solid transparent',
      }}
      className="my-2"
    >
      <div
        className="name-initials mx-2"
        style={{
          backgroundColor: colors[Number(number) % 2 === 0 ? 0 : 1].color,
        }}
      >
        {initialsName(participant.nome || '')}
      </div>
      <span>
        {number}.<b className="mx-2">{handleUpperCaseName()}</b>
      </span>
    </Container>
  );
};

export default ParticipantsName;
