import * as Zod from "zod";

export const SBatche = Zod.object({
    name: Zod.string({
        required_error: 'Preencha o campo Módulo',
        invalid_type_error: 'Valor incorreto'
    }),
    batch: Zod.string({
        required_error: 'Preencha o campo Quadro/Lote',
        invalid_type_error: 'Valor incorreto'
    }),
    area: Zod.number({
        required_error: 'Preencha o campo Área (m²)',
        invalid_type_error: 'Valor incorreto',
    }),
    amount: Zod.number({
        required_error: 'Preencha o campo Valor Total',
        invalid_type_error: 'Valor incorreto',
    }),
    square_meter: Zod.number({
        required_error: 'Preencha o campo R$/m²',
        invalid_type_error: 'Valor incorreto',
    }).nullable()
        .optional(),
    front: Zod.number({
        required_error: 'Preencha o campo Frente',
        invalid_type_error: 'Valor incorreto',
    }),
    side: Zod.number({
        required_error: 'Preencha o campo Lateral',
        invalid_type_error: 'Valor incorreto',
    }),
    bottom: Zod.number({
        required_error: 'Preencha o campo Fundo',
        invalid_type_error: 'Valor incorreto',
    }),
})

export const STerrainProporsals = Zod.object({
    proponent_name: Zod.string({
        required_error: 'Preencha o campo Nome',
        invalid_type_error: 'Valor inválido'
    }),
    // proponent_nationality: Zod.string({
    //     required_error: 'Preencha o campo Nacionalidade',
    //     invalid_type_error: 'Valor inválido'
    // }),
    proponent_profession: Zod.string({
        required_error: 'Preencha o campo Profissão',
        invalid_type_error: 'Valor inválido'
    }),
    proponent_marital_status: Zod.any({
        required_error: 'Preencha o campo Estado civil',
        invalid_type_error: 'Valor inválido'
    }),
    proponent_document: Zod.string({
        required_error: 'Preencha o campo',
        invalid_type_error: 'Valor inválido'
    }),
    proponent_nid_number: Zod.number({
        required_error: 'Preencha o campo Cédula de Identidade',
        invalid_type_error: 'Valor inválido'
    }),
    proponent_nid_issuer: Zod.string({
        required_error: 'Preencha o campo Emissor da identidade',
        invalid_type_error: 'Valor inválido'
    }),
    proponent_nid_issuing_state: Zod.any({
        required_error: 'Preencha o campo Data de emissão da identidade',
        invalid_type_error: 'Valor inválido'
    }),
    // proponent_address: Zod.string({
    //     required_error: 'Preencha o campo ',
    //     invalid_type_error: 'Valor inválido'
    // }),
    owner_name: Zod.string({
        required_error: 'Preencha o campo Nome',
        invalid_type_error: 'Valor inválido'
    }),
    // owner_nationality: Zod.string({
    //     required_error: 'Preencha o campo Nacionalidade',
    //     invalid_type_error: 'Valor inválido'
    // }),
    owner_profession: Zod.string({
        required_error: 'Preencha o campo Profissão',
        invalid_type_error: 'Valor inválido'
    }),
    owner_marital_status: Zod.any({
        required_error: 'Preencha o campo Estado civil',
        invalid_type_error: 'Valor inválido'
    }),
    owner_document: Zod.string({
        required_error: 'Preencha o campo Profissão',
        invalid_type_error: 'Valor inválido'
    }),
    owner_nid_number: Zod.number({
        required_error: 'Preencha o campo Identidade',
        invalid_type_error: 'Valor inválido'
    }),
    owner_nid_issuer: Zod.string({
        required_error: 'Preencha o campo Emissor da identidade',
        invalid_type_error: 'Valor inválido'
    }),
    owner_nid_issuing_state: Zod.any({
        required_error: 'Preencha o campo Data de emissão da identidade',
        invalid_type_error: 'Valor inválido'
    }),
    // owner_address: Zod.string({
    //     required_error: 'Preencha o campo ',
    //     invalid_type_error: 'Valor inválido'
    // }),
    address: Zod.string({
        required_error: 'Preencha o campo Rua/Av. ',
        invalid_type_error: 'Valor inválido'
    }),
    neighborhood: Zod.string({
        required_error: 'Preencha o campo Bairro.',
        invalid_type_error: 'Valor inválido'
    }),
    city: Zod.string({
        required_error: 'Preencha o campo Cidade',
        invalid_type_error: 'Valor inválido'
    }),
    state: Zod.string({
        required_error: 'Preencha o campo Estado',
        invalid_type_error: 'Valor inválido'
    }),
    zip_code: Zod.string({
        required_error: 'Preencha o campo CEP',
        invalid_type_error: 'Valor inválido'
    }),
    total: Zod.number({
        required_error: 'Preencha o campo Total da proposta',
        invalid_type_error: 'Valor inválido'
    }),
    money: Zod.number({
        required_error: 'Preencha o campo Em dinheiro',
        invalid_type_error: 'Valor inválido'
    }),
    payment_method: Zod.number({
        required_error: 'Preencha o campo Forma de pagamento do restante',
        invalid_type_error: 'Valor inválido'
    }).default(0)
})
// TODO - HABILITAR TIPOS ZodEffects no hook useFormIntegration
//.superRefine((schema, ctx) => {

//     const response: Zod.IssueData = {
//         validation: 'regex',
//         code: 'invalid_string',
//         message: '',
//         path: ['']
//     }

//     if (schema.proponent_document.match(schema.owner_document)) {
//         response.message = 'O documento do proponente não pode ser igual do Proprietario'
//     }

//     ctx.addIssue(response)
// })