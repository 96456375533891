import React, { useCallback, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsChatRightDotsFill, BsDownload } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import pic from '~/assets/pic/pic.png';

import UserAvatar from '~/components/UserAvatar';
import { useProfile } from '~/hooks/Profile';
import { CompleteData, Container, Container2 } from './styles';

interface IParams {
  os_id?: string;
}

const AssinaturaDos: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { validateEngineerData } = useProfile();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleBack = (): void => {
    history.goBack();
  };

  const handleHome = (): void => {
    history.push(`/dashboard/${params.os_id}`);
  };
  return (
    <div className="bg-white">
      <Container className="container mx-auto position-relative">
        <div className="row justify-content-between h-100">
          <div className="d-flex align-items-center mb-5">
            <div className="d-flex align-items-center me-2">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4 px-4"
                onClick={handleBack}
              >
                <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
              </button>
              <div>
                <h1 className="title-assinatura row mt-4">
                  Assinatura do contrato
                </h1>
                <h1 className="title-sub row">Assinatura On-line</h1>
              </div>
            </div>

            <div
              className="col-auto"
              style={{
                marginLeft: '40%',
                marginTop: '5%',
              }}
            >
              <div className="col-auto top-align">
                <UserAvatar />
              </div>
            </div>
          </div>
          <div className="ms-5">
            <CompleteData className="row ms-5">
              <div className="title-correspondente mt-4">
                <div className="col-auto me-2">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsChatRightDotsFill className="px-2 h1" />
                  </IconContext.Provider>
                </div>
                Correspondente:
              </div>
              <div className="sub-correspondente pb-4 ms-5">
                Texto variável, pois o correspondente sobe uma mensagem para o
                construtor instruindo
              </div>
            </CompleteData>
          </div>
          <div className="row ms-5">
            <div className="col-4 ms-5 mt-5">
              <img src={pic} alt="pic2" />

              <Container2>
                <div className="vl" />
              </Container2>
            </div>

            <div className="col pt-5">
              <div className="pt-5 title h1 title-sub">
                Assinatura do
                <div className="title-contrato ms-2"> contrato</div>
              </div>
              <div className="py-1" />
              <div className="title-sub">
                Seu contrato está pronto para ser assinado.
              </div>
              <div className="title-sub">
                Realize o download e faça a assinatura.
              </div>
              <div className="py-2" />

              <div className="row">
                <div className="col-auto">
                  <a 
                    href="/assinatura-online" download={true} 
                    className='flex flex-row flex-nowrap items-center gap-4'
                  >
                    <BsDownload color='#39A887' />
                    <h1 className="baixar">Baixar contrato</h1>
                  </a>
                </div>
              </div>

              <div className="py-5" />

              <button
                type="button"
                className="button-prox btn  btn-nexttt fw-semibold"
                onClick={handleHome}
              >
                Ir para dashboard
              </button>
            </div>
          </div>
        </div>
      </Container>
      <div className="py-5" />
      <div className="py-5" />
      <div className="py-4" />
    </div>
  );
};

export default AssinaturaDos;
