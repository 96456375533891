import styled from 'styled-components';

export const Container = styled.div`
  .p-relative {
    position: relative;
  }
  .border-verde {
    box-sizing: border-box;

    background: #ffffff;
    border: 3px solid #4cf299;
    /* square */

    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  .color {
    background-color: #8676ff;
  }
  h2 {
    color: #414142;
    font-weight: 600 !important;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    background-color: #96d9ff !important;
  }
  .dot-3 {
    background-color: #ffba69 !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h3 {
    font-size: 22px;
    font-weight: 500;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
`;
