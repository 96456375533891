import React from 'react';

interface IProps {
  children: React.ReactNode;
}

const Box: React.FC<IProps> = ({ children }: IProps) => (
  <div className="col-6 mb-3 mt-3 mt-lg-0">
    <div className="pres-box p-4 bg-white">{children}</div>
  </div>
);

export default Box;
