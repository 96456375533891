import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import alertverde from '~/assets/icons/alertverde.svg';
import { useProfile } from '~/hooks/Profile';

const PaymentsPaid: React.FC = () => {
  const { validateEngineerData } = useProfile();
  const redireccion = useHistory();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && redireccion.push('/perfil/dados-pessoais');
  }, [redireccion, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  return (
    <>
      <div className="row px-3  mx-5">
        <div className="col-auto fondo7" />
        <div className="col-auto fondo6" />
        <div className="col fondo8" />
      </div>
      <div className="row fondo mx-5 mb-5 px-3 mt-4">
        <div className="row py-2" />
        <div className="row text-3 px-4">Valor:</div>
        <div className="row">
          <div className="col text-4">R$ 10,00</div>
          <div className="col-auto text-10 fondo2">
            <img
              src={alertverde}
              alt="alertverde"
              color="#707070"
              id="Iconrojo"
              className=" pe-2"
            />
            Seu pagamento irá vencer em 5 dias
          </div>
        </div>
        <div className="py-3" />

        <div className="row">
          <div className="col-auto text-6 mb-2">Data de aquisição:</div>
          <div className="col text-7 mb-2">31/ 12/ 2022</div>
        </div>
        <div className="row">
          <div className="col-auto text-6 mb-2">Produto:</div>
          <div className="col text-7 mb-2">Harvey 3.0</div>
        </div>
        <div className="row">
          <div className="col-auto text-6">Mês de referência:</div>
          <div className="col text-7">Dezembro</div>
        </div>
        <div className="row py-2" />
      </div>
      <div className="row fondo mx-5 mb-5 px-3 mt-4">
        <div className="row py-2" />
        <div className="row text-3 px-4">Valor:</div>
        <div className="row">
          <div className="col text-4">R$ 10,00</div>
          <div className="col-auto text-10 fondo2">
            <img
              src={alertverde}
              alt="alertverde"
              color="#707070"
              id="Iconrojo"
              className=" pe-2"
            />
            Seu pagamento irá vencer em 5 dias
          </div>
        </div>
        <div className="py-3" />

        <div className="row">
          <div className="col-auto text-6 mb-2">Data de aquisição:</div>
          <div className="col text-7 mb-2">31/ 12/ 2022</div>
        </div>
        <div className="row">
          <div className="col-auto text-6 mb-2">Produto:</div>
          <div className="col text-7 mb-2">Harvey 3.0</div>
        </div>
        <div className="row">
          <div className="col-auto text-6">Mês de referência:</div>
          <div className="col text-7">Dezembro</div>
        </div>
        <div className="row py-2" />
      </div>
    </>
  );
};

export default PaymentsPaid;
