import styled from 'styled-components';

export const Container = styled.div`
  .box-image {
    display: flex;
    @media (max-width: 575px) {
      justify-content: center;
    }
  }

  .box-button {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 575px) {
      justify-content: center;
    }
  }

  .box-status {
    display: flex;
    justify-content: flex-start;
    @media (max-width: 575px) {
      margin-top: 20%;
      justify-content: center;
    }
  }

  .box-upload {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #f5f5f5;
    border-radius: 10px;
    align-items: center;
  }

  .send-button {
    border: 0px;
    background-color: #01cc9d;
    color: #ffffff;
    border-radius: 25px;
  }

  .button-modal {
    border: 0px;
    display: flex;
    align-items: center;
    font-style: normal;
    margin-left: 20px;
    background-color: transparent;
  }

  .status-box {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .button-left-arrow {
    border: 0;
    background-color: transparent;
    margin-left: 15%;
  }

  .image-input {
    display: none;
  }

  .label {
    cursor: pointer;
  }

  .box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .circle-icon {
    margin-left: -10%;
    margin-top: -30%;
    width: 20px;
    background-color: transparent;
  }
`;
