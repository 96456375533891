import React, { useEffect, useState } from 'react';

import { BiUserPlus } from 'react-icons/bi';
import { BsArrowUp, BsFileText } from 'react-icons/bs';

import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import Box from './box';
import { Container } from './styles';

interface IOsResponse {
  id: number;
  created_at: string;
  construtor?: {
    name: string;
  };
  finality: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    vlrRenda: number;
    amortizacao?: {
      id?: number;
      title?: string;
      financedValue: string;
      entryValue?: string;
      realEntryValue?: string;
      fgtsAmount?: string;
      rate?: string;
      firstInstallmentDate?: string;
      firstInstallment: string;
      lastInstallmentDate?: string;
      lastInstallment: string;
      maxInstallment?: string;
      highlight?: boolean;
      deadline?: number;
      color?: string;
    };
  };
  clientes: {
    nome: string;
    dt_nascimento: string;
  };
}

interface IRequestResponse {
  id: number;
  status: {
    id: number;
    status: string;
  };
  created_at: string;
  os: IOsResponse;
  // builder: IBuilderLocal;
  answered?: boolean;
  status_id?: number;
  // client: IClient;
}

interface IRequestData {
  data: IRequestResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Boxes: React.FC = () => {
  const { user, updateUser } = useAuthContext();
  const [cantNovoOrcamento, setCantNovoOrcamento] = useState(0);
  const [cantNewsMarketings, setCantNewsMarketings] = useState(0);
  const [porcReqAnswered] = useState(0);
  const formatDecimal = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    api
      .get<number>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/requests/news/quantity`
      )
      .then((response) => {
        setCantNovoOrcamento(response.data);
      });
    api
      .get<IRequestData>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/marketings/news`
      )
      .then((res) => {
        setCantNewsMarketings(res.data.total);
      });
    api
      .get<number>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/quantities`
      )
      .then((res) => {
        // updateUser({ ...user, qtdOS: res.data });
      });
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="row">
        <Box>
          <p className="font-novo in-box">Novos Orçamentos</p>
          <p className="h4 fw-bold value-box">
            <BiUserPlus size={24} color="#B8B9BA" /> {cantNovoOrcamento}
            <span className="red-ball ms-1" />
          </p>
          <p className="d-flex align-items-center justify-content-center text-center inter-box py-2 fw-medium">
            <BsArrowUp size={24} color="#39A887" className="me-2" />{' '}
            {formatDecimal.format(porcReqAnswered)}%
          </p>
        </Box>
        <Box>
          <p className="fw-semibold small in-box">Ordens de serviços</p>
          <p className="h4 fw-bold value-box">
            <BsFileText size={24} color="#B8B9BA" />
            <span>
              {user?.qtdOS && typeof user?.qtdOS === 'number' ? user.qtdOS : 0}
            </span>
          </p>
        </Box>
      </div>
    </Container>
  );
};

export default Boxes;
