import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c9;
  color: #b8b9ba;
  width: 100%;
  display: flex;
  align-items: center;
  height: 52px;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
    `}
`;
