import React from 'react';
import { Carousel } from 'react-bootstrap';

import { IDayOfMonth } from '~/models/Home/DayOfMonth';

interface ICarouselDate {
  currentIndex: number;
  daysOfMonth: IDayOfMonth[][];
  daySelected: IDayOfMonth;
  onHandleSelect(value: IDayOfMonth): void;
}

const CarouselDate: React.FC<ICarouselDate> = ({
  currentIndex,
  daysOfMonth,
  daySelected,
  onHandleSelect,
}) => (
  <Carousel activeIndex={currentIndex} className="w-100">
    {daysOfMonth.map((datesAux, idx) => (
      <Carousel.Item className="bg-transparent" key={idx}>
        <div className="d-flex">
          {datesAux.map((day, index) => (
            <button
              key={day.fullDate.toDateString()}
              type="button"
              className={`date-task px-3 pt-2 ${
                index === 4 ? 'me-0' : 'me-2'
              } ${day.date === daySelected.date ? 'active-date' : ''}`}
              onClick={() => onHandleSelect(day)}
            >
              <span className="h5 fw-semibold ">{day.date}</span>
              <h2 className="small fw-light week">{day.dayOfWeek}</h2>
            </button>
          ))}
        </div>
      </Carousel.Item>
    ))}
  </Carousel>
);

export default CarouselDate;
