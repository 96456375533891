import React from 'react';
import { BsFilePost } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import { Container } from './styles';

const ContratoPronto: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();

  return (
    <Container>
      <div className="col-md-12 d-flex">
        <div className="icon-information">
          <BsFilePost size={40} color="#39A887" />
        </div>
        <div className="col-md-11 text-box">
          <div className="d-flex mt-3">
            <b>Contrato pronto para assinar 🎉</b>
          </div>
          <div className="d-flex row box-fill">
            <div className="col-xl-7">
              <p>
                Estamos preparando tudo, assim que pronto, liberamos o contrato
                para assinatura
              </p>
            </div>

            <div className="col-xl-4 box-button-fill py-2">
              <button
                type="button"
                className="button-fill px-5"
                onClick={() => history.push(`/assinatura-online/${serviceOrder?.id}`)
                }
              >
                <b>Verificar assinatura</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContratoPronto;
