import { useEffect } from "react";

// Icons
import { BsDash, BsPlus } from "react-icons/bs";

// Types
import { IMainProps } from "./types";

// Partials
import { Mock } from "../../../Mocks";

// Styles
import "./styles.css";

export function Main({
        onPerQuantitySelected,
        onSelectOptionByQuantity, 
        onStepQuantityPlus, 
        onStepQuantitySubstract, 
        perQuantitySelected,
        onStepQuantity,
        onOptionSelected,
        showOptionPerQuantityREF,
        stepSelected}: IMainProps)
    {

    useEffect(() => {
        if((stepSelected?.quantity === 1 ) && true) onPerQuantitySelected(`option_1`);
    },[stepSelected])
    return (
        <div
            data-selected={stepSelected && true}
            className='
            w-full
            h-auto
            hidden
            data-[selected=true]:flex
            flex-col
            md:flex-row 
            md:items-center 
            md:justify-center
            mt-[48px]
            '
        >
            <div
                className='w-full 
                md:w-1/2 
                h-[205px] 
                flex 
                flex-row
                items-center
                justify-center 
                px-[12px]
            '
            >
                <span
                    className='
                    w-2/3
                    h-full
                    flex
                    flex-row
                    flex-nowrap
                    items-center
                    justify-center
                    rounded-[10px]
                    overflow-hidden
                    object-fill
                    '
                >
                    <img src={stepSelected?.photo || '#'} alt="Imagem representando o passo selecionado" loading="lazy" className='max-w-full h-auto'/>
                </span>
            </div>

            <div
                className='
                    md:flex-1
                    h-full
                    px-12
                    flex
                    flex-col
                    items-center
                    justify-center
                    gap-y-[12px]
                '
            >
                <span
                    className='
                        font-semibold
                        text-[#212529]
                        leading-[26px]
                        self-start
                    '
                >
                    {stepSelected?.question || stepSelected?.description || 'Selecione uma opção abaixo'}
                </span>

                <div
                    className='
                        w-full
                        h-auto
                        flex 
                        flex-col
                        items-center
                        pb-4
                        md:pb-0
                    '
                >
                    <input
                        type="checkbox"
                        name={`show-options-per-quantity-box-${stepSelected?.type}`}
                        id={`show-options-per-quantity-box-${stepSelected?.type}`}
                        defaultChecked={false}
                        className='peer/ShowOptionsBoxByQuantity hidden'
                        ref={showOptionPerQuantityREF}
                    />

                    {
                        stepSelected && stepSelected?.options.length <= 0 && (
                            <Mock />
                        )
                    }            

                    {
                        // CONTROLLER OF OPTION PER QUANTITY
                    }
                    <span
                        data-hasquantity={stepSelected?.hasQuantity && true}
                        className="w-full h-auto 
                            hidden 
                            data-[hasquantity=true]:flex 
                            peer-checked/ShowOptionsBoxByQuantity:!hidden
                            flex-row flex-nowrap 
                            items-center justify-center
                            border-b-[1px] border-b-gray-200 pb-2
                        "
                    >
                    <div className="col-3 col-lg-1">
                        <button
                            type="button"
                            className="border-0 bg-transparent mx-auto mx-lg-0 d-block"
                            onClick={onStepQuantitySubstract}
                        >
                            <BsDash size={24} color="#39A887" />
                        </button>
                    </div>
                    <div className="w-full counter">
                        <input
                            type="number"
                            placeholder="Informe uma quantidade"
                            inputMode='numeric'
                            min={1}
                            className="insert w-100 text-center border-0 fondo"
                            defaultValue={stepSelected?.quantity}
                            value={stepSelected?.quantity}
                            onChange={onStepQuantity}
                        />
                    </div>
                    <div className="col-3 col-lg-1">
                        <button
                            type="button"
                            className="border-0 bg-transparent mx-auto d-block"
                            onClick={onStepQuantityPlus}
                        >
                            <BsPlus size={24} color="#39A887" />
                        </button>
                    </div>
                    </span>

                    {
                    /* Default Box of options */
                    }
                    <span
                        data-shouldviewer={stepSelected?.hasQuantity && true}
                        className='
                            w-full h-auto
                            hidden
                            data-[shouldviewer=false]:flex
                            flex-row
                            flex-nowrap
                            items-center
                            md:justify-center
                            max-sm:overflow-x-auto
                            gap-x-[10px]
                            md:gap-x-[48px]
                        '
                    >
                    {
                        stepSelected?.options.map(option => (
                            <button
                                key={option.id}
                                type="button"
                                data-selected={option.selected || false}                    
                                onClick={() => onOptionSelected(option)}
                                className='
                                    options-box
                                    flex
                                    flex-col
                                    items-center
                                    justify-center
                                    gap-[4px]
                                    min-w-[77px]
                                    md:w-auto
                                    h-[77px]
                                    p-3
                                    rounded-[10px]
                                    transition-all
                                    duration-[0.3s]
                                    text-[11px]
                                    text-[#414142]
                                    outline-none
                                    border-[1px]
                                    border-[#e1eee4]
                                    data-[selected=false]:hover:bg-[#e9f5f2]
                                    data-[selected=false]:hover:border-[#9cd3c3]
                                    data-[selected=false]:active:bg-[#e9f5f2]
                                    data-[selected=false]:active:border-[#9cd3c3]
                                    data-[selected=true]:bg-[#e9f5f2]
                                    data-[selected=true]:border-[#9cd3c3]
                                    data-[selected=true]:cursor-not-allowed
                                    data-[selected=true]:pointer-events-none
                                    overflow-hidden
                                '
                            >
                                <img src={option.icon || ''} alt="Image representando a opção" loading='lazy' />
                                <span>{option.label}</span>
                            </button>
                        ))
                    }
                    </span>

                    {
                    /* Box of options per Quantity */
                    }
                    <span
                        data-shouldviewer={stepSelected?.hasQuantity && true}
                        className='
                            w-full h-auto
                            hidden
                            data-[shouldviewer=true]:peer-checked/ShowOptionsBoxByQuantity:!flex
                            flex-col
                            items-star
                            justify-start
                            gap-4
                        '
                    >
                        {
                            /* Bar navigation of quantities */
                        }
                        <span className='
                                w-full
                                flex
                                flex-row
                                flex-wrap
                                items-center
                                md:justify-start
                                max-sm:overflow-x-auto
                                gap-x-[10px]
                            '
                        >
                            {
                                Array((stepSelected?.quantity || 1) >= 1 ? stepSelected?.quantity : 0).fill(null).map( (_q, index) => {
                                    return (
                                        <span className='
                                                flex
                                                flex-row
                                                flex-nowrap
                                                items-center
                                                gap-x-[10px]
                                            '
                                        >
                                            <input 
                                                type="radio" 
                                                name={`option-per-quantity-${stepSelected?.name}`}
                                                id={`option-per-quantity-${stepSelected?.name}-${index + 1}`}
                                                disabled={!perQuantitySelected && true}
                                                className='peer/PerQuantity hidden'                                            
                                                defaultChecked={(perQuantitySelected === `option_${index + 1}` || perQuantitySelected === `option_${index + 1}` && index === 0) && true}
                                            />

                                            <label
                                                htmlFor={`option-per-quantity-${stepSelected?.name}-${index + 1}`}
                                                onClick={() => onPerQuantitySelected(`option_${index + 1}`)}
                                                data-falsychecked={(perQuantitySelected === `option_${index + 1}` || perQuantitySelected === `option_${index + 1}` && index === 0) && true}
                                                // data-falsychecked={false}
                                                className='
                                                    py-[1px] px-[10px]
                                                    flex
                                                    flex-row
                                                    items-center
                                                    justify-center
                                                    gap-4
                                                    bg-[#f5f5f5]
                                                    peer-checked/PerQuantity:bg-[#39a887]
                                                    peer-checked/PerQuantity:text-white
                                                    data-[falsychecked=true]:text-white
                                                    data-[falsychecked=true]:bg-[#39a887]
                                                    
                                                    rounded-[15px]
                                                    min-w-[80px]
                                                    text-[12px]
                                                    text-[#bdbdbd]
                                                    select-none
                                                    cursor-pointer
                                                    &_[.d]:
                                                '
                                            >
                                                {stepSelected?.name}
                                                {" "}
                                                {index + 1}
                                            </label>

                                            <span
                                                data-shouldvierw={(index + 1 < (stepSelected?.quantity || 0)) && true }
                                                className='
                                                    hidden
                                                    data-[shouldvierw=true]:inline-block
                                                    text-[#212529]
                                                    font-semibold
                                                '
                                            >
                                                /
                                            </span>
                                        </span>
                                    )
                                })
                            }
                        </span>

                        {
                            /* Bar navigation of box's by quantity selected */
                        }
                        <span className='
                                w-full
                                h-[80px]
                                flex
                                flex-row
                                flex-nowrap
                                items-center
                                overflow-hidden
                                gap-4
                                relative
                            '
                        >
                            {
                            Object.keys(stepSelected?.optionsPerQuantity || {}).map((key, index) => {
                                const ID_BOX = (stepSelected?.optionsPerQuantity?.[key].quantity || 1) - ((stepSelected?.optionsPerQuantity?.[key].quantity || 1) - index) + 1
                                return (
                                <span
                                    key={ID_BOX}
                                    data-shouldShowMe={perQuantitySelected === `option_${ID_BOX}` || stepSelected?.quantity === 1 && true}
                                    className='
                                        w-full
                                        flex
                                        flex-row
                                        flex-nowrap
                                        md:justify-start
                                        max-sm:overflow-x-auto
                                        gap-x-[10px]
                                        md:gap-x-[50px]
                                        absolute
                                        left-option-quantity-hidden
                                        bottom-0
                                        transition-all
                                        duration-[0.3s]
                                        data-[shouldShowMe=true]:!left-0
                                    '
                                >
                                    {
                                    (stepSelected?.optionsPerQuantity?.[key].options || []).map(option => (
                                        <button
                                        key={option.id}
                                        type="button"
                                        data-selected={option.selected || false}                    
                                        onClick={() => onSelectOptionByQuantity(option, `option_${ID_BOX}`)}
                                        className='
                                            options-box
                                            flex
                                            flex-col
                                            items-center
                                            justify-center
                                            gap-[4px]
                                            min-w-[77px]
                                            md:w-auto
                                            h-[77px]
                                            p-3
                                            rounded-[10px]
                                            transition-all
                                            duration-[0.3s]
                                            text-[11px]
                                            text-[#414142]
                                            outline-none
                                            border-[1px]
                                            border-[#e1eee4]
                                            data-[selected=false]:hover:bg-[#e9f5f2]
                                            data-[selected=false]:hover:border-[#9cd3c3]
                                            data-[selected=false]:active:bg-[#e9f5f2]
                                            data-[selected=false]:active:border-[#9cd3c3]
                                            data-[selected=true]:bg-[#e9f5f2]
                                            data-[selected=true]:border-[#9cd3c3]
                                            data-[selected=true]:cursor-not-allowed
                                            data-[selected=true]:pointer-events-none
                                            overflow-hidden
                                        '
                                        >
                                        <img src={option.icon || ''} alt="Image representando a opção" loading='lazy' />
                                        <span>{option.metragem}m²</span>
                                        </button>
                                    ))
                                    }
                                </span>
                                )
                            })
                            }
                        </span>
                    </span>

                    <label
                        htmlFor={`show-options-per-quantity-box-${stepSelected?.type}`}
                        title='Definir quantidades'
                        data-hasquantity={stepSelected?.hasQuantity && true}  
                        className="
                            w-auto h-auto 
                            font-semibold
                            text-[#39a887]
                            hover:text-[#079269]
                            cursor-pointer
                            hidden
                            peer-checked/ShowOptionsBoxByQuantity:data-[hasquantity=true]:flex 
                            flex-row flex-nowrap 
                            items-center justify-center
                            select-none
                            mt-4
                        "
                    >
                        Voltar
                    </label>

                    <label
                        htmlFor={`show-options-per-quantity-box-${stepSelected?.type}`}
                        title='Selecionar as metragens'
                        data-hasquantity={stepSelected?.hasQuantity && true}  
                        className="
                            w-auto h-auto 
                            font-semibold
                            text-[#39a887]
                            hover:text-[#079269]
                            cursor-pointer
                            hidden 
                            data-[hasquantity=true]:flex 
                            peer-checked/ShowOptionsBoxByQuantity:!hidden
                            flex-row flex-nowrap 
                            items-center justify-center
                            select-none
                            mt-4
                        "
                    >
                        Continuar
                    </label>
                </div>
            </div>
        </div>
    )
}