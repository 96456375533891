import useFormIntegration from "kemis-library/hooks/form";
import { InputNumber } from "primereact/inputnumber";
import { Controller, FieldErrors } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Zod from "zod";

// Icons
import checkBoxOutlineBlankIcon from '~/assets/icons/check_box_outline_blank.svg';
import requestQuoteIcon from "~assets/icons/requst_quote.svg";
import squareFootIcon from "~assets/icons/square_foot.svg";

import { useEffect, useState } from "react";

// Types
import { IParams } from "../../Project/types";
import { IBatchesProps } from "./types";

// Components
import UserDefault from "~/components/UserDefault";

// Services
import api from "~/services/api";

// Utils
import { SBatche } from "../utils/schema";

// Models
import { IMBatche } from "~/models/Batche";

// Partials
import { MessageError } from "./Partials/MessageError";

export function Batche({ }: IBatchesProps) {
    const { id: projectID, batchID } = useParams<IParams>();
    const [batchesOnLoading, setBachesOnLoadig] = useState(false)
    const { register, handleSubmit, control, watch, setValue } = useFormIntegration<Zod.infer<typeof SBatche>>({
        name: ''
    }, SBatche)

    // AUX Variables
    const METHOD_REQUEST = batchID && Number(batchID) >= 1 ? 'put' : 'post'
    const URL_REQUEST = batchID && Number(batchID) >= 1 ? `builders/real-estate-products/${projectID}/terrains/${batchID}` : `builders/real-estate-products/${projectID}/terrains`
    const amount = watch('amount')
    const area = watch('area')
    const amountByMeterSquare = parseFloat(((amount && amount > 0) && (area && area > 0) ? amount / area : 0).toFixed(2))

    async function onSubmit(data: Partial<Omit<IMBatche, 'square_meter'>>) {
        try {
            setBachesOnLoadig(true)

            if (!data || !projectID) throw new Error('')

            const batcheResponse = await api[METHOD_REQUEST](URL_REQUEST,
                {
                    ...data,
                    batch: `${data.batch}`,
                    real_estate_product_id: projectID,
                    amount: data.amount?.toFixed(2) || '0',
                    area: data.area?.toFixed(2) || '0',
                    square_meter: amountByMeterSquare || '0'
                }
            )

            if (batcheResponse.status !== 201) throw new Error('')

            Swal.fire({
                icon: 'success',
                text: 'Lote salvo com sucesso'
            })

            setBachesOnLoadig(false)
        } catch (error) {
            setBachesOnLoadig(false)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel salvar o Lote'
            })
        }
    }

    async function onSubmitError(error: FieldErrors) {
        try {
            // do anything
        } catch (error) {
            // do anything
        }
    }

    useEffect(() => {
        if (!batchID || Number(batchID) <= -1) return

        setBachesOnLoadig(true)

        api.get(`builders/real-estate-products/${projectID}/terrains/${batchID}`)
            .then(resolver => {
                Object.keys(resolver.data).forEach(key => setValue((key as unknown as any), resolver.data[key]))
                setBachesOnLoadig(false)
            })
            .catch(rejects => {
                Swal.fire({
                    icon: 'warning',
                    text: 'Não foi possivel recuperar o dados do lote',
                    confirmButtonColor: '#39A887',
                    confirmButtonText: 'Voltar'
                }).then(action => {
                    if (action.isConfirmed) history.back()
                })

                setBachesOnLoadig(true)
            })
    }, [batchID])

    return (
        <div className="flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t h-screen max-sm:!p-[none] mb-20">
            <UserDefault home />

            <span className="text-[#707070] font-[400] mb-[1.35rem] max-sm:self-center">Preencha as seguintes informações</span>

            <div className="w-full h-auto flex flex-col md:items-center md:justify-center gap-[2.5rem] max-sm:mx-auto">
                <form className="flex flex-col 
                    gap-[2.187rem] md:w-[54.937rem]
                    max-sm:w-full md:h-[32.812rem]
                    max-sm:min-h-[32.812rem] max-sm:h-full 
                    max-sm:border-[0.1rem] max-sm:border-gray-200
                    max-sm:pt-[1rem] max-sm:pb-[1rem]
                    bg-white shadow-md
                    rounded-[0.937rem] md:p-[2.5rem]"
                    onSubmit={handleSubmit(onSubmit, onSubmitError)}
                    id="batches-form"
                    name="batches-form"
                >
                    <div className="w-full flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.375rem] ">
                            <span className="w-[3.312rem] h-[3.312rem] object-cover">
                                <img src={checkBoxOutlineBlankIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Parcela </span>
                        </div>
                        <div className="max-sm:w-full flex md:flex-row md:flex-nowrap max-sm:flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem] flex-1 md:min-w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Módulo</span>
                                <div className="w-full">
                                    <input
                                        {...register("name")}
                                        type="text"
                                        className="max-sm:w-full pl-[0.558rem] border-none 
                                            bg-none font-[400] leading-[1.375rem] 
                                            tracking-[-0.025rem] text-[1.062rem] 
                                            text-[#707070]
                                            placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-full"
                                        placeholder="Ex: Cherry" />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem] flex-1 md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Quadro/Lote</span>
                                <div className="w-full">
                                    <Controller name="batch" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <input
                                                className="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                 text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                placeholder="Ex: 20k"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                defaultValue={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.target.value || '')}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem] flex-1 md:min-w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Área (m²)</span>
                                <div className="w-full">
                                    <Controller name="area" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                suffix="m²"
                                                locale="pt-BR"
                                                mode="decimal"
                                                placeholder="Ex: 550m²"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.375rem] ">
                            <span className="w-[3.312rem] h-[3.312rem] object-cover">
                                <img src={requestQuoteIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Valores </span>
                        </div>
                        <div className="flex md:flex-row md:flex-nowrap max-sm:flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem] md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Valor total</span>
                                <div className="w-full">
                                    <Controller name="amount" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                currency="BRL"
                                                locale="pt-BR"
                                                mode="currency"
                                                placeholder="Ex: R$ 1,99"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem] md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">R$/m²</span>
                                <div className="w-full">
                                    <Controller name="square_meter" control={control} render={({ fieldState, field, formState }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0] select-none cursor-not-allowed"
                                                currency="BRL"
                                                locale="pt-BR"
                                                suffix="/m²"
                                                mode="currency"
                                                placeholder="Ex: R$ 20,00/m²"
                                                readOnly={true}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                defaultValue={field.value as unknown as number || undefined}
                                                value={amountByMeterSquare}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col max-sm:pl-[1rem] max-sm:pr-[1rem] max-sm:gap-[1rem]">
                        <div className="w-full flex flex-row flex-nowrap items-center gap-[0.375rem]">
                            <span className="w-[3.312rem] h-[3.312rem] object-cover">
                                <img src={squareFootIcon} alt="" className="!max-w-full h-auto" />
                            </span>
                            <span className="font-semibold text-[1.125rem] text-[#707070]"> Medidas </span>
                        </div>
                        <div className="flex md:flex-row md:flex-nowrap max-sm:flex-col gap-[2.5rem]">
                            <div className="flex flex-col gap-[0.562rem] md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Frente</span>
                                <div className="w-full">
                                    <Controller name="front" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                 text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                locale="pt-BR"
                                                suffix="m"
                                                mode="decimal"
                                                placeholder="Ex: 200m"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem] md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Lateral</span>
                                <div className="w-full">

                                    <Controller name="side" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                 text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                locale="pt-BR"
                                                suffix="m"
                                                mode="decimal"
                                                placeholder="Ex: 20m"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                            <div className="flex flex-col gap-[0.562rem] md:w-[13.75rem] h-[4.25rem] max-sm:min-w-full pb-[0.631rem] border-b-[0.1rem] border-[#B8B9BA]">
                                <span className="text-[#707070] text-[0.875rem] font-bold">Fundo</span>
                                <div className="w-full">
                                    <Controller name="bottom" control={control} render={({ fieldState, field }) => (
                                        <>
                                            <InputNumber
                                                inputClassName="max-sm:w-full pl-[0.558rem] border-none
                                                bg-none font-[400] leading-[1.375rem] 
                                                tracking-[-0.025rem] text-[1.062rem] 
                                                 text-[#707070]
                                                placeholder:text-[#B8B9BA] placeholder:text-[0.9rem] h-auto !pt-[0]"
                                                locale="pt-BR"
                                                suffix="m"
                                                mode="decimal"
                                                placeholder="Ex: 300m"
                                                // {...field}
                                                disabled={field.disabled}
                                                name={field.name}
                                                ref={field.ref}
                                                value={field.value as unknown as number || undefined}
                                                onChange={(event) => field.onChange(event.value)}
                                            />
                                            <MessageError fieldState={fieldState} />
                                        </>
                                    )} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <span className="max-sm:w-full md:w-[54.937rem] flex flex-1 flex-row items-center md:justify-end  max-sm:justify-center max-sm:mb-4">
                    <button
                        title="Salvar Lote"
                        type="submit"
                        disabled={batchesOnLoading}
                        form="batches-form"
                        className="w-[20.687rem] h-[3.375rem] rounded-[0.937rem] 
                        flex flex-row items-center justify-center gap-[1rem] text-white font-bold 
                        bg-[#39A887] hover:opacity-80 
                        transition-all duration-[0.3s] disabled:cursor-not-allowed">
                        {
                            batchesOnLoading && (
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            )
                        }
                        Salvar
                    </button>
                </span>
            </div>
        </div>
    )
}