import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IMProject } from '~/models/Project';
import api from '~/services/api';

interface ProjectContextData {
  projects: IMProject[];
  selectedProjects: IMProject[];
  setSelectedProjects(
    selectedProject: IMProject,
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ): void;
  selectProject: IMProject;
  setSelectProject(selectProject: IMProject): void;
  totalNetAmount: number;
  totalGrossAmount: number;
  inputNumberMeters: number;
  sumTotalAmounts(inputValue: number): void;
}

export const ProjectContext = createContext<ProjectContextData>(
  {} as ProjectContextData
);

export const ProjectProvider: React.FC = ({ children }) => {
  const [projects, setProjects] = useState({} as IMProject[]);
  const [selectedProjects, setSelectedProjects] = useState<IMProject[]>([]);
  const [selectProject, setSelectProject] = useState({} as IMProject);

  const [inputNumberMeters, setInputNumberMeters] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const [totalGrossAmount, setTotalGrossAmount] = useState(0);

  const handleSetters = (data: IMProject[]) => {
    setProjects(data);
    setSelectProject(data[0]);
  };

  const handleSetProjects = useCallback(async () => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/builders/projects`)
      .then((res) => {
        handleSetters(res.data);
      });
  }, []);

  useEffect(() => {
    handleSetProjects();
  }, [handleSetProjects]);

  const sumTotalAmounts = useCallback(
    (inputValue) => {
      setInputNumberMeters(inputValue);

      Number.isNaN(inputValue)
        ? (setTotalNetAmount(0), setTotalGrossAmount(0))
        : (setTotalNetAmount(
          Array.from(selectedProjects)
            .map((project) => project.net_amount)
            .reduce((accumulator, current) => accumulator + current, 0)
          * inputValue
        ),
          setTotalGrossAmount(
            Array.from(selectedProjects)
              .map((project) => project.gross_amount)
              .reduce((accumulator, current) => accumulator + current, 0)
            * inputValue
          ));
    },
    [selectedProjects]
  );

  const handleSetSelectedProjects = useCallback(
    (selectedProject, event) => {
      const selProjectsArray = Array.from(selectedProjects);

      if (!event.target.checked) {
        const temp = [...selProjectsArray];
        const pos = selProjectsArray.indexOf(selectedProject);
        temp.splice(pos, 1);
        setSelectedProjects(temp);
      } else {
        setSelectedProjects(selProjectsArray.concat(selectedProject));
      }
    },
    [selectedProjects]
  );

  const handleSetSelectProject = useCallback((selectedProject) => {
    setSelectProject(selectedProject);
  }, []);

  useEffect(() => {
    sumTotalAmounts(inputNumberMeters);
  }, [inputNumberMeters, sumTotalAmounts]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        selectedProjects,
        setSelectedProjects: handleSetSelectedProjects,
        selectProject,
        setSelectProject: handleSetSelectProject,
        totalNetAmount,
        totalGrossAmount,
        sumTotalAmounts,
        inputNumberMeters
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export function useProject(): ProjectContextData {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error('useProject must be used within a ProjectProvider');
  }

  return context;
}
