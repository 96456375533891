import styled from 'styled-components';

export const Container = styled.section`
  .detailed_description {
    width: 385px;
    padding: 10px;
    word-wrap: break-word;
  }

  .button-proposal {
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fbfbfb;
    color: #39a887;
    border-radius: 20px;
    width: 250px;
    height: 40px;

    @media (max-width: 1199px) {
      margin-top: 3%;
    }
  }
`;
