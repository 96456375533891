import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts';

import logo from '~/assets/logos/logo-gray.svg';

import EditTable from '~/assets/icons/edit-table.svg';
import { Container, Box } from './styles';

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
}

interface IParams {
  simulation: ISimulation;
}

const ResourceComposition: React.FC<IParams> = ({ simulation }) => {
  const history = useHistory();
  const options = useMemo(
    () => ({
      options: {
        labels: ['Valor financiado', 'Valor de entrada', 'Valor do FGTS'],
        colors: ['#8675FE', '#FEBA6A', '#FF718B'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
      },
      series: [
        simulation.vlrFinanciado,
        simulation.vlrEntrada,
        simulation.vlrFgts,
      ],
    }),
    [simulation.vlrFinanciado, simulation.vlrEntrada, simulation.vlrFgts]
  );

  const handleClickEditar = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/simulador/resultado`);
  }, [history]);

  return (
    <Container className="container col-md-auto col-auto card-dashboard justify-content-between p-4 mb-5">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h6 mb-0">Composição do recursos</h2>
        <button
          type="button"
          className="d-flex align-items-center btn py-0"
          onClick={handleClickEditar}
        >
          <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
        </button>
      </div>
      <Box>
        <div className="position-relative d-flex align-items-center justify-content-center">
          <Chart
            options={options.options}
            series={options.series}
            type="donut"
            className="m-5 mb-0"
          />
          <img src={logo} alt="logo" className="logo position-absolute" />
        </div>
      </Box>
      <div className="m-4">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot me-3" />
          <p className="mb-0">Valor financiado</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrFinanciado)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-2 me-3" />
          <p className="mb-0">Valor de entrada</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrEntrada)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-3 me-3" />
          <p className="mb-0">Valor do FGTS</p>
          <p className="mb-0 font-weight-700 ms-auto">
            R${' '}
            {new Intl.NumberFormat('pt-BR', {
              maximumSignificantDigits: 10,
            }).format(simulation.vlrFgts)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ResourceComposition;
