import React, { useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useParams } from 'react-router-dom';

import * as Yup from 'yup';

import InputMask from '~/components/InputMask';
import getValidationErros from '~/utils/getValidationsErrors';

import api from '~/services/api';
import Toast from '~/utils/toast';
import { formatToFloat } from '~/utils/format';
import { Modal } from './styles';

interface IModalBudget {
  show: boolean;
  onHide: () => void;
}
export interface IDataForm {
  m2_value: string;
}
interface IParams {
  osId: string;
}

const ModalBudget: React.FC<IModalBudget> = ({ show, onHide }) => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();

  const handleSubmit = useCallback(
    async (data: IDataForm) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          m2_value: Yup.string().required('Precisa um valor'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.get(
          `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${params.osId}`
        );

        const formData = {
          ...response.data,
          m2_value: data.m2_value ? formatToFloat(data.m2_value) : 0,
        };

        await api.put(
          `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${params.osId}`,
          formData
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      }
      onHide();
    },
    [onHide, params.osId]
  );

  return (
    <Modal show={show} onHide={onHide} close>
      <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
        <Modal.Header className="border-none ps-4 pt-4" closeButton />
        <div className="modal-header">
          <h2 className="me-4">Orçamento</h2>
          <p>Digite o valor estimado para cada m²</p>
        </div>
        <Modal.Body className="mx-2">
          <label className="w-100 mt-5">
            Valor por m²
            <InputMask
              kind="money"
              name="m2_value"
              placeholder="ex: 0,00"
              className="input mt-2"
            />
          </label>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons_group">
            <Button type="submit" className="btn-submit button_submit py-3">
              Próximo
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalBudget;
