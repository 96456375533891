import React, { createContext, useState, useContext, useCallback } from 'react';

export interface ISimulation {
  revenue?: number;
  birthdate?: Date;
  os_id?: number;
  property_type?: number;
  property_price?: number;
  amortization?: {
    id?: number;
    title?: string;
    financedValue?: string;
    entryValue?: string;
    realEntryValue?: string;
    fgtsAmount?: string;
    rate?: string;
    firstInstallmentDate?: string;
    firstInstallment?: string;
    lastInstallmentDate?: string;
    lastInstallment?: string;
    maxInstallment?: string;
    highlight?: boolean;
    deadline?: number;
    selectedDeadline?: number;
    color?: string;
    targetLimitValue?: number;
    limitContractValue?: number;
    prazo?: number;
  };
}

interface SimulationContextData {
  simulation: ISimulation;
  setSimulation(simulation: ISimulation): void;
}

export const SimulationContext = createContext<SimulationContextData>(
  {} as SimulationContextData
);

export const SimulationProvider: React.FC = ({ children }) => {
  const [simulation, setSimulation] = useState(() => {
    const simulationData = localStorage.getItem('@HarveyBuilder:simulation');

    if (simulationData) {
      return JSON.parse(simulationData);
    }

    return {} as ISimulation;
  });

  const handleSetSimulation = useCallback((simulationData: ISimulation) => {
    localStorage.setItem(
      '@HarveyBuilder:simulation',
      JSON.stringify(simulationData)
    );
    setSimulation(simulationData);
  }, []);

  return (
    <SimulationContext.Provider
      value={{ simulation, setSimulation: handleSetSimulation }}
    >
      {children}
    </SimulationContext.Provider>
  );
};

export function useSimulation(): SimulationContextData {
  const context = useContext(SimulationContext);

  if (!context) {
    throw new Error('useSimulation must be used within an SimulationProvider');
  }

  return context;
}
