// Icons
import { BsCalendarEvent } from "react-icons/bs";

// Assets
import homeOSPDF from "~/assets/icons/harvey-print-property.png";
import harveyPrintSummaryHouse from "~/assets/img/harvey-print-summary-house.png";

// Types
import { ISummaryProps } from "./types";

// Styles
import { useMemo } from "react";
import { useServiceOrder } from "~/hooks/ServiceOrder";
import formatCurrency from "~/utils/formatCurrency";
import { getAccumulatedTotal, getAmountAfterRunning, SERVICER_ORDER_ONESEVEN } from "~/utils/serviceOrderCalculations";
import "./styles.css";


export function Summary(_props: ISummaryProps) {

    const {serviceOrder} = useServiceOrder()

    // AUX Variable
    const PROPERTY_AMOUNT = useMemo(() => formatCurrency(
        getAmountAfterRunning(serviceOrder.simulacao?.vlrFinanciado || 0, 
        serviceOrder.simulacao?.vlrEntrada || 0)
    ), [])
    
    return (
        <div id="summary-container" className="general-container">
            <div id="summary-header">
                <span id="summary-span-date">
                    <BsCalendarEvent color="#ABABAB"/>
                    Válido até {new Date().getFullYear()}
                </span>
                <span id="summary-span-text">
                    Bem-Vindo(a) ao projeto personalizado do seu sonho, acompanhe todas as informações.
                </span>
            </div>
            
            <div id="summary-box-real-estate-amount" className="general-box">
                <span id="summay-real-estate-amount-image">
                    <img src={homeOSPDF} />
                </span>
                <span className="general-text-label">Avaliação de mercado final do imóvel após executado:</span>
                <span className="general-text-bold"> { PROPERTY_AMOUNT } </span>
            </div>

            <span className="line-separator"></span>

            <div id="summary-content-box">
                <div id="summary-box-real-estate-result" className="general-box">
                    <span className="summary-text-group">
                        <span className="general-text-label">Valor disponível: </span>
                        <span className="general-text-bold">
                            {
                                formatCurrency(
                                    getAccumulatedTotal(
                                        serviceOrder.orc_parametros, 
                                        serviceOrder.orc_ambientes
                                    )
                                    + 
                                    (serviceOrder.simulacao?.vlrTerreno ?? 0)
                                )
                            }
                        </span>
                    </span>
                    <span className="summary-text-group">
                        <span className="general-text-label">Mais barato que comprar um imóvel novo: </span>
                        <span className="general-text-bold">
                            {formatCurrency((serviceOrder.simulacao?.vlrParcInic || 1) * SERVICER_ORDER_ONESEVEN)}
                        </span>
                    </span>
                </div>

                <div id="summary-box-real-estate-property" className="general-box">   
                    <img src={harveyPrintSummaryHouse} alt=""  />
                </div>
            </div>

            <div id="summary-footer-box">
                <div  className="general-box summary-box-real-estate-amounts">
                    <span className="general-text-label">Valor do Imóvel pronto: </span>
                    <span className="general-text-bold">{PROPERTY_AMOUNT}</span>
                </div>

                <span className="line-separator"></span>

                <div  className="general-box summary-box-real-estate-amounts">
                    <span className="general-text-label">Valor financiado: </span>
                    <span className="general-text-bold"> 
                        {formatCurrency(serviceOrder.simulacao?.vlrFinanciado || 0)}
                    </span>
                </div>

                <span className="line-separator"></span>

                <div  className="general-box summary-box-real-estate-amounts">
                    <span className="general-text-label">Valor de entrada: </span>
                    <span className="general-text-bold">
                         {formatCurrency(serviceOrder.simulacao?.vlrEntrada || 0)}
                    </span>
                </div>
                
            </div>

        </div>
    )
}