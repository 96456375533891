// Interfaces
import { InputNumber } from 'primereact/inputnumber';
import { IMRealStateProduct } from '~/models/RealEstateProduct';
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';
import { Image } from '~/pages/RealEstateProduct/Partials/Image';
import { MessageError } from '~/pages/RealEstateProduct/Partials/MessageError';
import { IStepData } from '~/pages/RealEstateProduct/RealEstateProject/types';
import { IGeneralProps } from './types';

export function General({
  onDataHouse, dataStep, onDataStream, housePosition
}: IGeneralProps) {
  return (
    <span className="flex flex-col gap-[1.562rem]">
      <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
        <div className="flex-1 md:max-w-[50%] max-sm:w-full flex flex-col gap-[0.812rem]">
          <span className="text-[#707070] font-semibold text-[0.875rem]">
            Nome
          </span>
          <input
            type="text"
            name=""
            id=""
            className="outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="Casa 1"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            onChange={(event) => onDataHouse({
              name: event.target.value || ''
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.name || ''}
          />

          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='name' />
        </div>
        <div className="flex-1 md:max-w-[50%] max-sm:w-full flex flex-col gap-[0.812rem]">
          <span className="text-[#707070] font-semibold text-[0.875rem]">
            Área do terreno
          </span>
          <InputNumber
            inputClassName="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] !pt-[0] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="800m²"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            mode='decimal'
            locale='pt-BR'
            suffix='m²'
            maxFractionDigits={4}
            onChange={({ value }) => onDataHouse({
              house_area: Number(value || '')
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.house_area || undefined}
            // @ts-ignore
            value={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.house_area || undefined}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='house_area' />
        </div>
      </div>
      <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
        <div className="flex-1 md:max-w-[48%] max-sm:w-full flex flex-col gap-[0.812rem]">
          <span className="text-[#707070] font-semibold text-[0.875rem]">
            Área construida
          </span>
          <InputNumber
            inputClassName="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] !pt-[0] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="520m²"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            mode='decimal'
            locale='pt-BR'
            suffix='m²'
            onChange={({ value }) => onDataHouse({
              terrain_area: Number(value || '')
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.terrain_area || undefined}
            // @ts-ignore
            value={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.terrain_area || undefined}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='terrain_area' />
        </div>
        <div className="flex-1 md:max-w-[48%] max-sm:w-full flex flex-col gap-[0.812rem]">
          <span className="text-[#707070] font-semibold text-[0.875rem]">
            VGV <span className="font-[400]">(Valor de mercado da casa)</span>
          </span>
          <InputNumber
            inputClassName="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] !pt-[0] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="R$ 2,54"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            mode='currency'
            currency='BRL'
            locale='pt-BR'
            onChange={({ value }) => onDataHouse({
              vgv: parseFloat(Number(value || '0').toFixed(2))
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.vgv || undefined}
            // @ts-ignore
            value={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.vgv || undefined}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='vgv' />
        </div>
      </div>
      <div className="flex md:flex-row md:flex-nowrap max-sm:flex-col gap-[2.187rem]">
        <div className="flex-1 md:max-w-[48%] max-sm:w-full flex flex-col gap-[2.187rem]">
          <div className="flex-1 flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Link de anuncio da casa na internet
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="www.vendasdeimoveis.com.br/678sdhbshfb"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => onDataHouse({
                ad_link: event.target.value || ''
              })}
              defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.ad_link || undefined}
              value={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.ad_link || undefined}
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='ad_link' />
          </div>
          <div className="flex-1 flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              O imóvel está à venda?{' '}
              <span className="font-[400]"></span>
            </span>
            <div className="flex flex-row flex-nowrap items-center gap-[1.5rem]">
              <input
                type="radio"
                name="sale_imovel"
                id="yes_imovel"
                className="peer/Yimovel hidden"
                onChange={(event) => event.target.checked && onDataHouse({
                  for_sale: true
                })}
                {
                ...Boolean((dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.for_sale || 0) === true ? { defaultChecked: true } : {}
                }
              />
              <input
                type="radio"
                name="sale_imovel"
                id="no_imovel"
                className="peer/Nimovel hidden"
                onChange={(event) => event.target.checked && onDataHouse({
                  for_sale: false
                })}
                {
                ...Boolean((dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.for_sale || 0) === false ? { defaultChecked: true } : {}
                }
              />

              <label
                title="Confirmar que o imovel esta á venda"
                htmlFor="yes_imovel"
                className="rounded-[0.625rem] w-[4.562rem] h-[2.312rem]
                  peer-checked/Yimovel:bg-[#A4DECD]
                   group-checked/imovel:border-none
                  border-[#E4E4E4] border-[0.1rem]
                  text-[#707070]
                  font-[400]
                  text-[0.875rem]
                  flex flex-row flex-nowrap items-center justify-center cursor-pointer"
              >
                Sim
              </label>
              <label
                title="Confirmar que o imovel não esta á venda"
                htmlFor="no_imovel"
                className="rounded-[0.625rem] w-[4.562rem] h-[2.312rem]
                    peer-checked/Nimovel:bg-[#A4DECD]
                    group-checked/imovel:border-none
                    border-[#E4E4E4] border-[0.1rem]
                    text-[#707070]
                    font-[400]
                    text-[0.875rem]
                    flex flex-row flex-nowrap items-center justify-center cursor-pointer"
              >
                Não
              </label>
            </div>
          </div>
        </div>
        <div className="flex-1 md:max-w-[48%] max-sm:w-full flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex-1 flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Imagem da fachada <span className="font-[400]">(opcional)</span>
            </span>
            <Image
              callerID="5"
              callerKey="photo"
              callerPosition={housePosition}
              dataStep={dataStep}
              onDataStream={onDataStream}
            />
          </div>
        </div>
      </div>
    </span>
  );
}
