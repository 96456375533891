import React, { useCallback } from 'react';

import { RiArrowRightSLine } from 'react-icons/ri';
import { Container } from './styles';

interface ICard {
  id: number;
  title: string;
  description: string;
  url?: string;
  handleClick?: () => void;
  // handleShowImage: (value: any) => void;
}

const Card: React.FC<ICard> = ({
  id,
  title,
  description,
  url,
  // FIXME - eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClick = () => { },
  // handleShowImage,
}) => {
  const openINewTab = useCallback((urlValue: string) => {
    let urlComplete = '';
    const urlSimple = urlValue.split('//');

    urlComplete = urlSimple.length > 1
      ? `https://${urlSimple[1]}`
      : `https://${urlSimple[0]}`;

    window.open(urlComplete, '_blank', 'noopener, noreferrer');
  }, []);

  return (
    <Container className="mb-4 me-4">
      <div key={id} className="box-post px-2 box-post py-4">
        <div className="bd-box-2 p-3">
          <div className="d-flex flex-column justify-content-between box-img">
            <img
              src={`${process.env.REACT_APP_API_URL}/tools/${id}`}
              alt="post"
              className="img-icon"
              width={65}
              height={65}
            />
          </div>
          <div className="relative d-flex align-items-center mb-3">
            <h2 className="text mt-2">{title}</h2>
          </div>
          <div className="scroll">
            <p className="p-description font-light text-sm ">{description}</p>
          </div>
          <div className="d-flex float-end mt-1">
            <button
              type="button"
              className="font-light text-sm border-0 link color-verde"
              onClick={() => (url ? openINewTab(url) : handleClick())}
            >
              <RiArrowRightSLine size={28} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Card;
