
import React from 'react';

// Assets
import logoHarvey from '~/assets/logos/logo-harvey.svg';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Styles
import { Background } from './styles';

// Partials
import { Composition } from './Partials';

const Login: React.FC = () => {
  const { onRecovery } = useAuthContext();  
  return (
    <>
      <div className="w-full h-screen flex flex-row flex-nowrap">
        <Background className="col-lg-6 col-xl-6 col-xxl-7 d-none d-lg-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <div>
            <img
              src={logoHarvey}
              alt="logo-harvey"
              className="mb-5 d-block mx-auto"
            />
            <h2 className="fw-light text-center company">
              <b className="fw-medium">Harvey</b> Construtor
            </h2>
            <hr className="line" />
            <p className="text-center">
              Aqui você encontrará todos os modelos de contratos e
              <br />
              Documentações Exclusivas que serão utilizados no
              <br />
              processos de financiamento habitacional.
            </p>
          </div>
        </Background>
        {
          onRecovery ? <Composition.Mock /> : <Composition.Main />
        }
      </div>
    </>

    // <Loading active={loading} />
  );
};

export default Login;
