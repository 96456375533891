import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: end;

  @media (max-width: 767px) {
    margin-top: 2%;
  }

  @media (max-width: 1043px) {
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 993px) {
    flex-wrap: wrap;
  }

  .column-pending-box {
    display: flex;
    justify-content: end;
  }

  .pending-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: #fff2ab;
    width: auto;
    height: 30px;
    b {
      font-size: small;
      margin-top: 1%;
    }
    @media (max-width: 1158px) {
      height: 45px;
    }
    @media (max-width: 993px) {
      height: 30px;
      margin-bottom: 15px;
    }
    @media (max-width: 548px) {
      height: 45px;
    }
    @media (max-width: 352px) {
      height: 4rem;
    }
  }

  .circle-box {
    margin-left: 6px;
    width: 20px;
    height: 20px;
    background-color: #feba6a;
    border-radius: 50%;
    @media (max-width: 548px) {
      width: 25px;
    }
    @media (max-width: 352px) {
      width: 40px;
      height: 20px;
    }
  }

  .column-mcf-box {
    display: flex;
    justify-content: end;
    @media (max-width: 841px) {
      margin-top: 3%;
    }
  }

  .mcf-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    background-color: #f4f4f4;
    margin-left: 2%;
    width: 450px;
    height: 30px;

    img {
      width: 15%;
    }
    b {
      font-size: small;
      margin-top: 1%;
    }

    @media (max-width: 447px) {
      width: 250px;
      flex-wrap: wrap;
      margin-bottom: 20%;
      .mcf-information {
        span {
          padding-left: 15px;
        }
      }
      .os-information {
        border-radius: 25px;
        margin-top: 5%;
        width: 100%;
        background-color: #f4f4f4;
        span {
          padding-left: 15px;
        }
      }
    }
  }
  .mcf-box-open {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: #f4f4f4;
    margin-left: 2%;
    width: 250px;
  }
`;
