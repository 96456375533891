import React, { useCallback, useEffect } from 'react';

import { useProfile } from '~/hooks/Profile';
import { IMConstrutech } from '~/models/Construtech';
import api from '~/services/api';
import Toast from '~/utils/toast';
import Banner from './Components/Banner';

import { Container } from './styles';
import FormConstrutech from './Components/Form';
import Cities from './Components/Cities';
import Social from './Components/Social';
import Portfolio from './Components/Portfolio';

const Construtech: React.FC = () => {
  const { setLoading, construtech, setConstrutech } = useProfile();

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      await api
        .get<IMConstrutech>(`${process.env.REACT_APP_PREFIX_ROUTE}/construtech`)
        .then((response) => {
          setConstrutech(response.data);
        });
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Ocorreu um erro ao carregar os dados da sua construtech.',
      });
    } finally {
      setLoading(false);
    }
  }, [setConstrutech, setLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Container className="px-4 pb-4">
        <div className="carddaos card text-white mx-6 mt-4">
          <Banner />
        </div>
        <FormConstrutech />
        {construtech?.id !== null && (
          <>
            <Cities />
            <Portfolio />
            <Social />
          </>
        )}
      </Container>
    </>
  );
};

export default Construtech;
