import styled from 'styled-components';

interface IAvatar {
  src: string;
}
interface IFileMarketing {
  url: string;
}
interface IIndex {
  index: number;
}

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const ContainerNew = styled.div`
  .ancho {
    display: flex;
    margin-left: 90px;
    width: 250px;
    @media (min-width: 700px) {
      display: flex;
      margin-left: 0px;
      width: 100%;
    }
  }
`;

export const Container4 = styled.div<IIndex>`
  align-items: start;
  justify-content: start;
  padding-bottom: 15px;
  width: 100vw;
  @media (min-width: 900px) {
    width: 40vw;
  }
  @media (min-width: 1200px) {
    width: 25vw;
  }

  margin-top: ${(props) => ![0, 1, 2].includes(props.index) && '0rem'};
  .borderr {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }
  .scroll {
    overflow-y: scroll;
    height: 100px;
    overflow-wrap: break-word;
  }
  .scroll2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .border-none {
    border: none;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* Title */

    color: #414142;
  }
  .description {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
  .utilizado {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
`;
export const Container2 = styled.div`
  align-items: start;
  justify-content: start;
  height: 70vh;
  width: 26vw;

  .borderr {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }
`;
export const Container3 = styled.div`
  > div {
    align-items: start;
    justify-content: start;
    width: 95vw;
    height: 20vh;
  }
`;

export const Background2 = styled.div<IFileMarketing>`
  background: url(${(props) => props?.url || ''}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
export const Container = styled.div`
  .borderr {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }
  .notify {
    border-radius: 50%;
    background: #f5f5f5;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 46px;
    height: 46px;

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      border-radius: 24px;
      right: 12px;
      top: 12px;
    }
  }

  .search {
    border: 1px solid #e4e4e4;
    border-radius: 35px;
    height: 56px;
  }

  .bottom-materais {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Secoundary */

    color: #079269;

    gap: 10px;
    border: 1px solid #ffffff;
    padding: 2px 2px 2px 2px;

    width: 90px;
    height: 30px;

    background: #e1f1ed;
    border-radius: 30px;
    cursor: default;
  }
  .bottom-downloads {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Secoundary */

    color: #079269;

    gap: 10px;
    border: 1px solid #ffffff;
    padding: 2px 2px 2px 2px;

    width: 148px;
    height: 30px;

    background: #ffffff;
    border-radius: 30px;
    :hover {
      background: #079269;
      color: #e1f1ed;
    }
  }

  .btn-os {
    border-radius: 26px;
    height: 56px;
  }

  input {
    color: #414142;
    ::placeholder {
      color: #b9b8bc;
    }
  }

  .carousel {
    background: linear-gradient(
        75.33deg,
        rgba(0, 0, 0, 0.2) -10.41%,
        rgba(0, 0, 0, 0) 62.93%
      ),
      #f4b000;
    height: 354px;
    border-radius: 12px;
  }

  .sun {
    color: #202020;
  }

  .btn-know {
    height: 46px;
    background: #ffffff;
    border-radius: 40px;
  }

  .pres-box {
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    width: 164px;
    height: 167px;
  }

  .in-box {
    color: #747475;
  }

  .value-box {
    position: relative;
    color: #27262e;

    .red-ball {
      background: #ff1a50;
      border-radius: 6px;
      width: 12px;
      height: 12px;
      position: absolute;
    }
  }

  .inter-box {
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    height: 46px;
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .tasks {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }

  .tasks {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    transition: 0.3ms;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .calendar {
    background: #ffffff;
    border-radius: 10px;
  }

  .reminder-task {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 160px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 120px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 130px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 100px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 150px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
