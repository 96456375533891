import { useCallback } from 'react';

// Interface
import { IMRealEstateStandard } from '~/models/RealEstateStandard';
import { IStepData } from '../../types';
import { IEstateProps } from './types';

// Images
import houseHighStandardImg from "~/assets/img/house_high_standard.png";
import houseMidStandardImg from "~/assets/img/house_mid_standard.png";

export function Estate({ onDataStream, dataStep, dataStepStandard }: IEstateProps) {
  // AUX Variables
  const STANDARD_SELECTED = useCallback((standard = -1) => {
    let standard_style = 'bg-[#39A887] hover:bg-[#4acea6] text-white';

    const standard_target = (dataStep as IStepData<IMRealEstateStandard>)?.data.id === standard ? standard : -1;

    switch (standard_target) {
      case 1:
        standard_style = 'text-[#39A887] border-[#39A887] bg-white border-[0.1rem]';
        break;
      case 2:
        standard_style = 'text-[#39A887] border-[#39A887] bg-white border-[0.1rem]';
        break;
      default:
      // do anything
    }

    return standard_style;
  }, [dataStep]);
  const STANDARD_IMAGE = (id = -1) => {
    let imageDefault = ""

    switch (id) {
      case 1:
        imageDefault = houseHighStandardImg
        break;
      case 2:
        imageDefault = houseMidStandardImg
        break;
      default:
      // do anything
    }

    return imageDefault
  }

  return (
    <div className="flex flex-col items-start justify-start gap-[1.875rem] w-full h-full ">
      <p className="text-[#707070] font-[400] max-sm:text-center max-sm:w-full">
        Escolha a que mais se encaixa com sua necessidade
      </p>

      <div className="flex md:flex-row md:flex-nowrap max-sm:flex-col gap-[3.125rem] overflow-x-auto max-sm:p-[1rem]">
        {
          dataStepStandard?.map((standard, index) => (
            <div key={index} className="md:w-[25.125rem] md:h-[23.062rem] flex flex-row flex-nowrap bg-white rounded-[0.937rem] shadow-sm overflow-hidden">
              <div className="flex-1 flex flex-row flex-nowrap items-center justify-center object-fill bg-gray-100">
                <img src={STANDARD_IMAGE(standard.id)} alt="" className='!max-w-full h-full' />
              </div>
              <div className="flex-1 flex flex-col  items-center justify-center gap-[6rem] max-sm:p-[1rem]">
                <span className="text-[#414142] text-[1.25rem] font-semibold overflow-hidden text-ellipsis text-center" title={standard.standard}>
                  {
                    standard.standard
                  }
                </span>
                {/* <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='id' /> */}
                <button
                  onClick={() => onDataStream!({
                    id: standard.id,
                    standard: standard.standard,
                  }, '1')}
                  type="button"
                  className={`w-[9.375rem] h-[3.375rem] rounded-[0.937rem]  transition-colors  duration-[0.3s] 
              flex flex-row flex-nowrap items-center justify-center shadow-sm ${STANDARD_SELECTED(standard.id)}`}
                >
                  {
                    dataStep && (dataStep as unknown as IStepData<IMRealEstateStandard>).data.id === standard.id ? '✓ Selecionado' : 'Selecione'
                  }
                </button>
              </div>
            </div>
          ))
        }

        {dataStepStandard && dataStepStandard?.length <= 0 && (
          <>
            <div className="md:w-[25.125rem] md:h-[23.062rem] flex flex-row flex-nowrap bg-white rounded-[0.937rem] shadow-sm overflow-hidden self-center cursor-default">
              <div className="flex-1 flex flex-row flex-nowrap items-center justify-center object-fill bg-gray-100 animate-pulse" />
              <div className="flex-1 flex flex-col  items-center justify-center gap-[6rem]">
                <span className="text-[#414142] w-1/2 h-[1rem] text-[1.25rem] font-semibold rounded-[0.937rem] bg-gray-100 animate-pulse" />
                <span
                  className={`md:w-[9.375rem] md:h-[3.375rem] rounded-[0.937rem]  transition-colors  duration-[0.3s] 
                  flex flex-row flex-nowrap items-center justify-center shadow-sm bg-gray-100 animate-pulse cursor-default`}
                />
              </div>
            </div>

            <div className="md:w-[25.125rem] md:h-[23.062rem] flex flex-row flex-nowrap bg-white rounded-[0.937rem] shadow-sm overflow-hidden self-center cursor-default">
              <div className="flex-1 flex flex-row flex-nowrap items-center justify-center object-fill bg-gray-100 animate-pulse" />
              <div className="flex-1 flex flex-col  items-center justify-center gap-[6rem]">
                <span className="text-[#414142] w-1/2 h-[1rem] text-[1.25rem] font-semibold rounded-[0.937rem] bg-gray-100 animate-pulse" />
                <span
                  className={`md:w-[9.375rem] md:h-[3.375rem] rounded-[0.937rem]  transition-colors  duration-[0.3s] 
                  flex flex-row flex-nowrap items-center justify-center shadow-sm bg-gray-100 animate-pulse cursor-default`}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
