import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IMBoard } from '~/models/Board';
import { IMTask } from '~/models/BoardTask';
import { IMServiceOrder } from '~/models/ServiceOrder';
import api from '~/services/api';

interface KanbanContextData {
  columns: IMBoard[];
  setColumns(columns: IMBoard[]): void;
  updateDataColumn(column: IMBoard): void;
  uid: number;
  setUid(id: number): void;
}

const firstBoardData: IMBoard = {
  id: 0,
  ordem: 0,
  quadro: 'Tarefas não atribuidas',
  tasks: [],
};

export const KanbanContext = createContext<KanbanContextData>(
  {} as KanbanContextData
);

export const KanbanProvider: React.FC = ({ children }) => {
  const [columns, setColumns] = useState([] as IMBoard[]);
  const [uid, setUid] = useState(999);

  const handleSetBoards = useCallback(async () => {
    try {
      await api
        .get<IMBoard[]>(`${process.env.REACT_APP_PREFIX_ROUTE}/boards`)
        .then((res) => {
          setColumns((state) => [...state, ...res.data]);
          // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
          setUid(Math.max(...res.data.map((cols: any) => cols.id)));
        });
    } catch {
      setColumns([{ ...firstBoardData }]);
    }
  }, []);

  const handleSetFirstBoardTasks = useCallback(async () => {
    try {
      await api
        .get<IMServiceOrder[]>(
          `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/tasks`
        )
        .then((res) => {
          const tasks = res.data.map((task, index: number) => ({
            id: 0,
            ordem: index,
            os_id: task.id || 0,
            quadro_id: 0,
            service_order: {
              id: task.id || 0,
              cliente: task.cliente,
              created_at: task.created_at,
              status: task.status,
            },
          } as IMTask));
          setColumns([{ ...firstBoardData, tasks }]);
        });
    } catch {
      setColumns([{ ...firstBoardData }]);
    } finally {
      handleSetBoards();
    }
  }, [handleSetBoards]);

  const handleUpdateDataColumn = (column: IMBoard) => {
    setColumns(columns.map((col) => (col.id === column.id ? column : col)));
  };

  useEffect(() => {
    handleSetFirstBoardTasks();
  }, [handleSetFirstBoardTasks]);

  return (
    <KanbanContext.Provider
      value={{
        columns,
        setColumns,
        updateDataColumn: handleUpdateDataColumn,
        uid,
        setUid,
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};

export function useKanban(): KanbanContextData {
  const context = useContext(KanbanContext);

  if (!context) {
    throw new Error('useKanban must be used within a KanbanProvider');
  }

  return context;
}
