import React, { useCallback, useEffect, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';
import { IMFileType } from '~/models/FileTypes';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import { Container } from './styles';
import BoxDocumentGroups from '../BoxDocumentGroups';

const ExtraDocuments: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [seeMore, setSeeMore] = useState(true);
  const [fileTypes, setFileTypes] = useState<IMFileType[]>([]);
  const [loading, setLoading] = useState(false);

  const loadDocumentLists = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .get<IMFileType[]>(`builders/file-types/${serviceOrder.id}`, {
          params: {
            grupo: [4, 5, 6, 7, 8, 9],
          },
        })
        .then((response) => {
          setFileTypes(response.data);
        });
    } catch (err) {
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os tipos de documentos!',
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [serviceOrder.id]);

  useEffect(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  const handleUpdateFileTypes = useCallback(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  return (
    <div>
      <div className="mt-4">
        <b>Documentos extras</b>
      </div>
      <Container className="px-2 mt-2 pb-2 d-flex flex-column">
        <div className="d-flex w-full">
          <div className="col-12 d-flex justify-content-end me-4 mt-3">
            {!seeMore ? (
              <button
                type="button"
                onClick={() => setSeeMore(true)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver mais <MdKeyboardArrowDown />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setSeeMore(false)}
                className="d-flex flex-row text-primary fw-bold align-items-center gap-2"
              >
                Ver menos <MdKeyboardArrowUp />
              </button>
            )}
          </div>
        </div>
        {seeMore && (
          <div>
            {fileTypes.map((fileType) => (
              <div key={fileType.id} className="mt-4">
                <BoxDocumentGroups
                  fileType={fileType}
                  onChangeFiles={handleUpdateFileTypes}
                />
              </div>
            ))}
          </div>
        )}
      </Container>
      <Loading active={loading} />
    </div>
  );
};

export default ExtraDocuments;
