import React, { useCallback, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import {
  BsArrowsAngleExpand,
  BsFillPatchPlusFill,
  BsFillStickiesFill,
  BsStickiesFill
} from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import UserDefault from '~/components/UserDefault';
import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';
import Toast from '~/utils/toast';
import {
  Background2,
  Container,
  Container2,
  Container3,
  GeneralContainer
} from './styles';

interface IMarketing {
  id: string;
  title: string;
  description: string;
  url: string;
  created_at: string;
  filename: string;
  marketing_user?: {
    id?: number;
    used?: number;
  };
  used?: number;
}

const Marketing: React.FC = () => {
  const { validateEngineerData } = useProfile();
  const history = useHistory();
  const [allMarketings, setAllMarketings] = useState<IMarketing[]>([]);
  const [newsMarketings, setNewsMarketings] = useState<IMarketing[]>([]);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    api.get(`${process.env.REACT_APP_PREFIX_ROUTE}/marketings/`).then((res) => {
      setAllMarketings(res.data);
    });
    api
      .get(`${process.env.REACT_APP_PREFIX_ROUTE}/marketings/news`)
      .then((res) => {
        setNewsMarketings(res.data.data);
      });
  }, []);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleClickMydownloads = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/marketing/meus-downloads`);
  }, [history]);

  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    marketing: IMarketing
  ) => {
    if (e.target.checked) {
      api.post(
        `${process.env.REACT_APP_PREFIX_ROUTE}/marketings/${marketing.id}`
      );
    } else if (!e.target.checked) {
      api.delete(
        `${process.env.REACT_APP_PREFIX_ROUTE}/marketings/${marketing.id}`
      );
    }
  };

  const handleCopyLink = useCallback(
    async (id: number) => {
      try {
        let link = '';
        const completeLink = allMarketings.find(
          (item) => Number(item.id) === id
        )?.description;
        if (completeLink === undefined) {
          link = '';
        } else {
          link = completeLink;
        }
        navigator.clipboard.writeText(link);

        Toast.fire({
          icon: 'success',
          title: 'Link copiado com sucesso!',
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [allMarketings]
  );

  return (
    <GeneralContainer className="ms-lg-5">
      <div className="py-4" />
      <div className="mt-5 mt-lg-0">
        <div className="container mt-5 mt-lg-0">
          <UserDefault marketing />
        </div>
        <Container className="w-100 px-lg-3 mt-5">
          <div className="container">
            <div className="px-5">
              <div className="row">
                <div className="col-auto ">
                  <h5 className="text-dark ">
                    Materiais da semana
                    <IconContext.Provider value={{ color: '#39A887' }}>
                      <BsFillPatchPlusFill className="px-2 h1 py-1" />
                    </IconContext.Provider>
                  </h5>
                </div>
                <div className="row col-lg gap gap-1 justify-content-end text-end">
                  <button type="button" className="bottom-materais">
                    Materiais
                  </button>
                  <button
                    type="button"
                    className="bottom-downloads"
                    onClick={handleClickMydownloads}
                  >
                    Meus downloads
                  </button>
                </div>
              </div>
            </div>
            <div className="row gap gap-4 py-3 px-5 text-black-50">
              {newsMarketings
                && newsMarketings
                  // .filter((m) => !m?.marketing_user?.used)
                  .map((marketing, index) => (
                    <Container2 key={index}>
                      <div className="col-auto px-4 rounded-3 shadow bg-body rounded">
                        <div className="py-1" />
                        <h6 className="justify-content-end text-end mt-4 text-dark">
                          <BsArrowsAngleExpand />
                        </h6>
                        <h4 className="mb-3 text-dark">{marketing.title}</h4>
                        <h5 className="">{marketing.description}</h5>
                        <div className="col-auto">
                          <Container3 className="d-flex">
                            <Background2
                              className="d-flex justify-content-start shadow p-5 mb-2 bg-body rounded"
                              url={marketing.url}
                            />
                          </Container3>
                        </div>
                        <div className="justify-content-end text-end text-success py-3">
                          <IconContext.Provider value={{ color: '#39A887' }}>
                            <BsFillStickiesFill className="h3 px-1 " />
                          </IconContext.Provider>
                          Copiar texto
                        </div>
                        <input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          value="Paneer"
                          defaultChecked={marketing?.used !== null}
                          onChange={(e) => handleChangeCheckbox(e, marketing)}
                        />
                        Utilizado
                        <br />
                        <br />
                      </div>
                    </Container2>
                  ))}
            </div>
            <div className="px-5">
              <div className="row">
                <div className="col-auto">
                  <h5 className="text-dark ">
                    Materiais Disponibilizados
                    <IconContext.Provider value={{ color: '#39A887' }}>
                      <BsFillPatchPlusFill className="px-2 h1 py-1" />
                    </IconContext.Provider>
                  </h5>
                </div>
              </div>
            </div>
            <div className="row pt-0 text-black-50 ms-0 ms-lg-5 pb-5 gap-4">
              {allMarketings
                && allMarketings
                  // .filter((m) => !m.marketing_user)
                  .map((marketing, i) => {
                    // console.table(marketing)
                    // console.log(!!marketing.filename)
                    // console.log(`${process.env.REACT_APP_API_URL}/marketings/${marketing.id}`)
                    return (
                      <div 
                        key={i}
                        data-hasimage={!!marketing.filename}
                        className='
                          max-sm:w-full
                          max-sm:h-auto
                          w-[346.6px]
                          data-[hasimage=true]:h-[448.8px]
                          h-auto
                          p-4
                          shadow
                          border
                          flex flex-col
                          items-start
                          justify-start
                          data-[hasimage=true]:justify-between
                          gap-4
                        '
                      >                        
                          <hgroup className='space-y-3'>
                            <h5 
                              className="                                
                                font-semibold
                                text-md
                                leading-[19px]
                                text-[#414142]
                              "
                            >
                              {marketing.title}
                            </h5>
                            <h6
                              className="w-full h-auto 
                                max-h-[100px]
                                break-words
                                overflow-y
                                text-sm
                                mt-3
                                font-medium
                                leading-[15px]
                                text-[#707070]
                                m-0
                              "
                            >
                              {marketing.description}
                            </h6>
                          </hgroup>

                          <div className="w-full h-auto bg-gray-200 flex flex-row flex-nowrap items-center justify-start gap-2">
                            <a 
                              type="href" 
                              href={marketing.url}
                              target='new_blank'
                              className='w-full h-auto overflow-hidden text-ellipsis whitespace-nowrap p-2'
                            >
                              {marketing.url}
                            </a>
                            <button
                              title='Copiar texto'
                              type="button"
                              className="border-0 col-auto text-primary bg-verdee"
                              onClick={() => handleCopyLink(Number(marketing.id))
                              }
                            >
                              <BsStickiesFill size={25} className="me-2" />
                            </button>
                          </div>

                          {
                            !!marketing.filename && (
                              <div
                                className='w-full h-full                                    
                                  rounded-[12px] 
                                  overflow-hidden 
                                  flex flex-row 
                                  items-center justify-center
                                  shadow p-5
                                '
                              >
                                <img 
                                  src={`${process.env.REACT_APP_API_URL}/marketings/${marketing.id}`} 
                                  alt=""
                                  className='max-w-full h-auto'
                                />                                
                              </div>
                            )
                          }
                          <div className="w-full flex flex-row items-center">
                            <div className="col-auto py-2 ">
                              <input
                                type="checkbox"
                                id="topping"
                                name="topping"
                                value="Paneer"
                                defaultChecked={
                                    marketing?.marketing_user !== null
                                  }
                                onChange={(e) => handleChangeCheckbox(e, marketing)
                                  }
                              />
                            </div>
                            <div className="col-auto px-1 utilizado">
                              Utilizado
                            </div>
                          </div>
                      </div>
                    )}
                  )}
            </div>
          </div>
        </Container>
      </div>
    </GeneralContainer>
  );
};

export default Marketing;
