/* FIXEM - eslint-disable */
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FaRegUserCircle, FaRocketchat } from 'react-icons/fa';
import { IoChevronBack, IoSend } from 'react-icons/io5';

import { Link, useParams } from 'react-router-dom';

import Textarea from '~/components/Textarea';
import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import {
  initializeChatSocketEvents,
  initializeSocketConnection,
} from '~/services/socket';
import { ChatArea, ChatText, Container } from './styles';

interface IMessageResponse {
  id: number;
  user_id: number;
  content: string;
  created_at: string;
}

interface IMessage {
  id: number;
  user_id: number;
  content: string;
  date: string;
}

interface IChatResponse {
  id: number;
  os_id: number;
  messages: IMessageResponse[];
  ordem_servico: {
    cliente?: {
      nome: string;
    }
    correspondente?: {
      name: string;
    }
  }
}

interface IChat {
  id: number;
  os_id: number;
  name: string;
}

interface IParams {
  type: string;
  chat_id?: string;
}

interface IFormData {
  message: string;
}

interface IMessageArea {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessagesArea: React.FC<IMessageArea> = ({ setLoading }) => {
  const params = useParams<IParams>();
  const { user } = useAuthContext();
  const [chat, setChat] = useState({} as IChat);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(2);
  const [loadingOldMessages, setLoadingOldMessages] = useState(false);

  useEffect(() => {
    if (chat.id) {
      initializeSocketConnection('chat', setSocketInitialized);
    }
  }, [chat.id]);

  useEffect(() => {
    if (socketInitialized) {
      initializeChatSocketEvents(user!.id, chat.id, setMessages);
    }
  }, [chat.id, socketInitialized, user]);

  useEffect(() => {
    const bodyChat = document.querySelector('.bodyChat');
    if (bodyChat) {
      bodyChat.scrollTo({ top: bodyChat.scrollHeight, behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (params.chat_id) {
      setLoading(true);
      api
        .get<IChatResponse>(`builders/chats/${params.chat_id}`, {
          params: {
            type: params.type === 'clientes' ? 'client' : 'correspondent'
          }
        })
        .then((response) => {
          let name = '';
          if (response.data.ordem_servico.cliente) {
            const nameParts = response.data.ordem_servico.cliente.nome.split(' ');
            name = `${nameParts[0]} ${nameParts[1]}`;
          }
          if (response.data.ordem_servico.correspondente) {
            const nameParts = response.data.ordem_servico.correspondente.name.split(' ');
            name = `${nameParts[0]} ${nameParts[1]}`;
          }
          setChat({
            id: response.data.id,
            os_id: response.data.os_id,
            name,
          });

          const dataMessages = response.data.messages.map((message) => ({
            id: message.id,
            user_id: message.user_id,
            content: message.content,
            date: format(parseISO(message.created_at), 'dd/MM/yyyy HH:mm'),
          }));

          setMessages(dataMessages);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.chat_id, setLoading, user!.id]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        const formData = {
          chat_id: chat.id,
          user_id: user!.id,
          content: data.message,
        };

        api.post('builders/chats/messages', formData);

        const message = {
          id: new Date().getTime(),
          user_id: user!.id,
          date: format(new Date(), 'dd/MM/yyyy HH:mm'),
          content: data.message,
        };

        setMessages((state) => [message, ...state]);

        const element = document.getElementById(
          'message'
        ) as HTMLTextAreaElement;
        if (element) {
          element.value = '';
        }
      } catch (error) { }
    },
    [chat.id, user!.id]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (e.target.value.length > 0) {
          const data = { message: e.target.value };
          e.target.value = '';

          handleSubmit(data);
        }
      }
    },
    [handleSubmit]
  );

  const loadOldMessages = useCallback(async () => {
    if (!loadingOldMessages) {
      setLoadingOldMessages(true);
      try {
        const newPage = page + 1;
        const response = await api.get(`builders/chats/messages/${chat.id}`, {
          params: {
            page: newPage,
          },
        });

        if (response.data && response.data.data.length > 0) {
          const data = response.data.data.map((message: IMessageResponse) => ({
            id: message.id,
            user_id: message.user_id,
            content: message.content,
            date: format(parseISO(message.created_at), 'dd/MM/yyyy HH:mm'),
          }));

          setMessages((state) => [...state, ...data]);
          setPage(newPage);
        }
        setLastPage(response.data.last_page);
      } finally {
        setLoadingOldMessages(false);
      }
    }
  }, [loadingOldMessages, page, chat.id]);

  const handleInfiniteLoad = useCallback(async () => {
    if (page < lastPage) {
      loadOldMessages();
    }
  }, [lastPage, loadOldMessages, page]);

  return (
    <Container>
      {Object.keys(chat).length > 0 ? (
        <Form onSubmit={handleSubmit}>
          <ChatArea>
            <div className="border-user d-flex flex-wrap align-items-center justify-content-between px-3 px-lg-5 pt-4 pb-3">
              <div className="d-flex align-items-center w-100 w-lg-auto">
                <FaRegUserCircle
                  size={24}
                  color="#C1C1C1"
                  className="d-none d-lg-block"
                />
                <Link
                  to={`${process.env.PUBLIC_URL}/chat/${params.type}`}
                  className="border-0 bg-transparent d-block d-lg-none"
                >
                  <IoChevronBack size={24} color="#C1C1C1" />
                </Link>

                <h2 className="ms-2 mb-0">{chat.name}</h2>
              </div>
              <div className="os-user ps-2 ps-lg-0 ms-4 ms-lg-0 mt-2 mt-lg-0">
                <span className="small normal-lg">
                  OS: {chat.os_id.toString().padStart(4, '0')}
                </span>
              </div>
            </div>
            <ChatText
              flipped
              scrollLoadThreshold={100}
              onInfiniteLoad={handleInfiniteLoad}
              className="bodyChat px-3 px-lg-5"
            >
              {messages.map((message) => (
                <Fragment key={message.id}>
                  {message.user_id === user!.id ? (
                    <div className="w-100 d-flex justify-content-end mb-2 my-message">
                      <div className="">
                        <div className="message-box bg-dark-gray py-2 px-4 mb-2">
                          {message.content}
                        </div>
                        <p className="date-message text-end">{message.date}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 d-flex mb-2 your-message">
                      <div className="">
                        <div className="message-box bg-light-gray py-2 px-4 mb-2">
                          {message.content}
                        </div>
                        <div className="date-message">{message.date}</div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </ChatText>
            <div className="border-message d-flex align-items-center ps-2 px-lg-4">
              <div className="col-sm-9 col-lg-10">
                <Textarea
                  id="message"
                  name="message"
                  className="w-100 border-0 textarea"
                  placeholder="Digite aqui..."
                  rows={2}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="col-sm-3 col-lg-2">
                <button
                  type="submit"
                  className="d-flex ms-auto px-3 px-lg-4 py-2 border-0"
                >
                  Enviar&nbsp;&nbsp;
                  <IoSend size={19} color="#fff" />
                </button>
              </div>
            </div>
          </ChatArea>
        </Form>
      ) : (
        <ChatArea>
          <div className="change-msg">
            <div className="icon">
              <FaRocketchat color="#9C9C9C" size={50} />
            </div>
            <p className="h1 text-center">Selecione uma conversa</p>
          </div>
        </ChatArea>
      )}
    </Container>
  );
};

export default MessagesArea;
