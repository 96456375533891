import { lighten } from 'polished';
import styled from 'styled-components';

interface IBanner {
  src: string;
}

export const Container = styled.div`
  div {
    .carousel-item {
      background-color: transparent !important;

      .marketing-banner {
        background-color: #fff;
        border: 1px solid #e4e4e4;
        border-radius: 12px;
        width: 100%;
        height: 354px;

        h1 {
          color: #27262e;
        }

        p {
          color: #27262e;
        }

        a {
          width: max-content;
          border-radius: 40px;
        }
      }
    }
  }

  .bannner {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 354px;
    border-radius: 12px;
    display: block;
  }

  .no-banner {
    border-radius: 12px;
    width: 100%;
    height: 354px;

    @media (max-width: 991px) {
      height: 162px;
    }

    background: ${lighten(0.5, '#747475')};

    p {
      color: #747475;
    }
  }

  .arrows {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 100%;

    button:nth-child(1) {
      position: absolute;
      left: 15px;
      top: 0;
    }

    button:nth-child(2) {
      position: absolute;
      right: 30px;
      top: 0;
    }
  }
`;

export const Banner = styled.a<IBanner>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 354px;
  border-radius: 12px;
  display: block;
`;
