import styled from 'styled-components';

interface Avatar {
  src: string;
}

export const Avatar = styled.div<Avatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: relative;

  .camera {
    position: absolute;
    background-color: #39a887;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    right: 0px;
    bottom: 0px;
  }
`;
