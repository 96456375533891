import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';

import EditTable from '~/assets/icons/edit-table.svg';
import { IMBudgetParameter } from '~/models/BudgetParameter';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import { Container } from './styles';

interface ISimulation {
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
  vlrSolicitado: number;
}

interface IParams {
  simulation: ISimulation;
  osId: number;
  parameters_orc: IMBudgetParameter;
  ambientes_orc: IMBudgetRoom[];
}
interface IDataBudgetE {
  quantity: IQuantityData[];
}
interface IQuantityData {
  cant: number;
  amb: string;
}
const PropertyEnvironments: React.FC<IParams> = ({
  simulation,
  osId,
  parameters_orc,
  ambientes_orc,
}) => {
  const [metreage, setMetreage] = useState(0);
  const [orc_ambiente, setAmbientes] = useState<IMBudgetRoom[]>([]);
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );
  const [data] = useState([
    { name: 'Quartos', id: 1, quantity: 0 },
    { name: 'Banheiro', id: 2, quantity: 0 },
    { name: 'Closets', id: 3, quantity: 0 },
    { name: 'Cozinha', id: 4, quantity: 0 },
    { name: 'Despensa', id: 5, quantity: 0 },
    { name: 'Área Gourmet', id: 6, quantity: 0 },
    { name: 'Área de serviço', id: 7, quantity: 0 },
    { name: 'Sala', id: 8, quantity: 0 },
    { name: 'Lavabo', id: 9, quantity: 0 },
    { name: 'Escritório', id: 10, quantity: 0 },
    { name: 'Garagem', id: 11, quantity: 0 },
    { name: 'Piscina', id: 12, quantity: 0 },
  ]);
  useEffect(() => {
    api
      .get<IDataBudgetE>(
        `/builders/service-orders/${osId}/budget/environments/get-quantities`
      )
      .then((array) => {
        const response: IQuantityData[] = array.data.quantity.map(
          (element) => ({
            cant: element.cant,
            amb: element.amb,
          })
        );
        setQuantityAmbientes(response);
      });
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const meteringEnvironments = ambientes_orc.reduce(
      (previousValue, currentValue) => previousValue + currentValue.metragem,
      0
    );
    const weightedAverage = ambientes_orc
      .map((enviroment) => enviroment.metragem * enviroment.vlrLucro)
      .reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      ) / meteringEnvironments;

    const dataBudget = simulation.vlrSolicitado
      + simulation.vlrFgts
      + simulation.vlrEntrada
      - simulation.vlrTerreno;

    setMetreage(dataBudget / weightedAverage);
    setAmbientes(ambientes_orc);
  }, [
    ambientes_orc,
    simulation.vlrEntrada,
    simulation.vlrFgts,
    simulation.vlrSolicitado,
    simulation.vlrTerreno,
  ]);
  const history = useHistory();
  const handleClickEditar = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/monte-sua-casa/${osId}`);
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Container className="container col-md-12 col-11 card-dashboard p-4 mb-5">
      <div className="mx-4 mt-0">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="h6 mb-0">Ambientes do Imóvel</h2>
          <button
            type="button"
            className="d-flex align-items-center btn py-0"
            onClick={handleClickEditar}
          >
            <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
          </button>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-0">
          <p>Tamanho do m² estimado da casa.</p>
          <h2 className="h6 mb-0 text-grey">
            Harvey sugere essa metragem para você construir
          </h2>
        </div>
        <p className="mb-0">
          Acabamento escolhido:{' '}
          <span> {parameters_orc?.acabamento?.tipo_acabamento}</span>
        </p>
      </div>
      <div className="d-flex subtitle align-items-center justify-content-end mb-4 mx-4">
        <h3 className="mb-0 font-weight-700 ms-auto">
          {metreage.toFixed(2)} m²
        </h3>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(data.length / 2) >= index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux) => (
                          <>
                            {aux.ambiente_tamanho.tamanho_id === 1
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon2 d-flex justify-content-center align-items-center bg-g" 
                                    title={`${aux.ambiente_tamanho.metragem}m²`}>
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1
                                        && 'G'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 2
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2">
                                  <div className="col profile-icon d-flex justify-content-center align-items-center bg-m" 
                                    title={`${aux.ambiente_tamanho.metragem}m²`}>
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2
                                        && 'M'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 3
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2">
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-p"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3
                                        && 'P'}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <div className="col-lg-6">
          {quantityAmbientes.map((item, index) => (
            <>
              {Math.ceil(data.length / 2) < index + 1 && (
                <div className="row colored-odd mx-1 my-3 py-2">
                  <div className="col-1 d-flex justify-content-center align-items-center">
                    <h4 className="mb-0">({item.cant})</h4>
                  </div>

                  <div className="col-10 d-flex align-items-center">
                    <div className="col-4">
                      <h4 className="mb-0 p-2">{item.amb}</h4>
                    </div>
                    <div className="col-6">
                      <div className="row gap-3">
                        {orc_ambiente.map((aux) => (
                          <>
                            {aux.ambiente_tamanho.tamanho_id === 1
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2">
                                  <div 
                                    className="col profile-icon2 d-flex justify-content-center align-items-center bg-g"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 1
                                        && 'G'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 2
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2" title={`${aux.ambiente_tamanho.metragem}m²`}>
                                  <div 
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-m"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 2
                                        && 'M'}
                                    </p>
                                  </div>
                                </div>
                              )}
                            {aux.ambiente_tamanho.tamanho_id === 3
                              && aux.ambiente_tamanho.ambiente_id
                              === index + 1 && (
                                <div className="col-2" title={`${aux.ambiente_tamanho.metragem}m²`}>
                                  <div
                                    className="col profile-icon d-flex justify-content-center align-items-center bg-p"
                                    title={`${aux.ambiente_tamanho.metragem}m²`}
                                    style={{ backgroundColor: '#8675FE' }}
                                  >
                                    <p className="mb-0 p-2">
                                      {aux.ambiente_tamanho.tamanho_id === 3
                                        && 'P'}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PropertyEnvironments;
