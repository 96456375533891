
// Assets
import harveyPrintUserCicleIcon from "~/assets/icons/harvery-print-user-circle.svg";

// Types
import { IDataProps } from "./types";

// Styles
import "./styles.css";

// Contexts
import { useServiceOrder } from "~/hooks/ServiceOrder";

export function Data(_props: IDataProps) {

    const {serviceOrder} = useServiceOrder()

    // AUX Variables
    const CONSTRUCTER_NAME_RAW = serviceOrder.construtor?.name?.split(' ') || []
    const CONSTRUCTER_NAME = `${CONSTRUCTER_NAME_RAW?.[0]?.substring(0,1) || 'N/'}${CONSTRUCTER_NAME_RAW?.[1]?.substring(0,1) || 'A'}`

    return (
        <div className="general-container" id="personal-data-container">
            <div id="personal-data-header">
                <span className="general-text-bold" id="personal-data-title">Dados</span>
                <span className="general-text-label" id="personal-data-description">Dados referente ao cliente e construtor</span>
            </div>

            <div className="general-box personal-data-box">
                <div className="personal-data-box-header">
                    <span className="general-text-bold personal-data-box-title">Dados do cliente</span>
                    <img src={harveyPrintUserCicleIcon} alt="" />
                </div>

                <div className="personal-data-box-content">
                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">OS: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.id || 'N/A'}</span>
                    </span>
                    <span className="personal-data-box-group-text">
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Cliente: </span>
                            <span className="personal-data-box-description general-text-bold">
                                {serviceOrder?.cliente?.nome || 'N/A'}
                            </span>
                        </span>
                        
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Telefone: </span>
                            <span className="personal-data-box-description general-text-bold">
                                {serviceOrder?.cliente?.telefone || 'N/A'}
                            </span>
                        </span>
                    </span>
                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Email: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.cliente?.email || 'N/A'}</span>
                    </span>

                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Tipo: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.finalidade?.finalidade || 'N/A'}</span>
                    </span>

                    <span className="personal-data-box-group-text">
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Estado do Terreno: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.terreno?.estado?.sigla|| 'N/A'}</span>
                        </span>

                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Municipio do terreno: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.terreno?.municipio|| 'N/A'}</span>
                        </span>
                    </span>

                     <span className="personal-data-box-group-text">
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Agência: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.cliente?.numero || 'N/A'}</span>
                        </span>

                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Número do PIS: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.cliente?.numPis || 'N/A'}</span>
                        </span>

                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Casado: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.cliente?.casado ? 'Sim' : 'Não'}</span>
                        </span>
                    </span>

                     <span className="personal-data-box-group-text">
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">Cônjuge: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.conjuge?.nome || 'N/A'}</span>
                        </span>
                        <span className="personal-data-box-row">
                            <span className="personal-data-box-label general-text-label">CPF: </span>
                            <span className="personal-data-box-description general-text-bold">{serviceOrder?.conjuge?.cpf || 'N/A'}</span>
                        </span>
                    </span>
                </div>
            </div>
            <div className="general-box personal-data-box">
                <div className="personal-data-box-header">
                    <span className="general-text-bold personal-data-box-title">
                        Dados do construtor
                    </span>
                    <div id="personal-wrapper-image">
                        <span 
                            data-showme={!!serviceOrder?.construtor?.engenheiro?.foto_url} 
                            className="personal-image data-[showme=false]:hidden"
                        >
                            <img src={serviceOrder?.construtor?.engenheiro?.foto_url || ''} alt="" />
                        </span>
                        <span className="personal-image">
                            {CONSTRUCTER_NAME}
                        </span>
                    </div>
                </div>

                <div className="personal-data-box-content">
                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Engenheiro: </span>
                        <span className="personal-data-box-description general-text-bold">
                            {
                                serviceOrder?.construtor?.engenheiro?.nome || serviceOrder?.construtor?.name || 'N/A'
                            }
                        </span>
                    </span>

                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Construtech: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.construtor?.construtech?.nome_fantasia || 'N/A'}</span>
                    </span>

                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Email: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.construtor?.email || 'N/A'}</span>
                    </span>

                    <span className="personal-data-box-row">
                        <span className="personal-data-box-label general-text-label">Telefone do responsável técnico: </span>
                        <span className="personal-data-box-description general-text-bold">{serviceOrder?.construtor?.construtech?.telefone || 'N/A'}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}