import { useHistory, useParams } from "react-router-dom";

// Icons
import boundingBoxIcon from "~/assets/icons/bounding-box.svg";
import terrainTrashIcon from "~/assets/icons/terrain-trash.svg";

// Types
import { GrSearch } from "react-icons/gr";
import { IBatchesProps } from "./types";

// Components
import { useEffect, useState } from "react";
import UserDefault from "~/components/UserDefault";

// Services
import api from "~/services/api";

// Types
import Swal from "sweetalert2";
import { IMBatche } from "~/models/Batche";
import { IParams } from "../../Project/types";
import { Mock } from "./Partials/Mock";

export function Table({ }: IBatchesProps) {
    const { id: projectID } = useParams<IParams>();
    const [batches, setBatches] = useState<IMBatche[]>([])
    const [batchesOnLoading, setBachesOnLoadig] = useState(true)
    const [batchesOnSearch, setBachesOnSearch] = useState(false)
    const [batchesBySearch, setBatchesBySearch] = useState<IMBatche[]>([])
    const history = useHistory()

    // RECOVERY study Ideal Real Estate and Indicator
    useEffect(() => {
        if (!projectID) {
            setBachesOnLoadig(false)
            return;
        }

        getBatches()

    }, [projectID]);

    async function handleSetDeleteBatch(batchName = "", batchID = -1) {
        try {
            if (batchID <= -1 || !projectID) throw new Error('')

            const response = await api.delete(`builders/real-estate-products/${projectID.trim()}/terrains/${batchID}`)

            if (response.status !== 200) throw new Error('')

            Swal.fire({
                icon: 'success',
                text: `O lote '${batchName}' foi excluido com sucesso`
            })

            setBachesOnLoadig(true)
            setBatches([])
            getBatches()
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                text: `Não foi possivel deletar o lote '${batchName}'`
            })
        }
    }

    function getBatches() {
        try {
            api.get<IMBatche[]>(`builders/real-estate-products/${projectID}/terrains`).then((resolver) => {
                if (resolver.status !== 200) {
                    setBachesOnLoadig(false)
                    return;
                }

                setBatches(resolver.data)

                setBachesOnLoadig(false)
            })
                .catch((rejects) => {
                    setBachesOnLoadig(false)
                });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'Não foi possivel recuperar os lotes'
            })
        }
    }

    async function handlerSearchByBatches(batchTerms: string | null = null) {
        try {
            setBachesOnSearch(true)

            if (!batchTerms || typeof batchTerms !== 'string' || batchTerms.trim() === '') {
                setBatchesBySearch([])
                throw new Error('')
            }

            setBatchesBySearch([])

            const batchTermsParsed = batchTerms.replace(/[^\w\s]/gi, '')

            const response = await api.get<IMBatche[]>(`builders/real-estate-products/${projectID}/terrains?search=${batchTermsParsed}`)

            if (response.status !== 200) throw new Error('')

            if (response.data.length <= 0) {
                Swal.fire({
                    icon: 'warning',
                    text: `Nenhum lote com o termo '${batchTerms}' foi encontrado`
                })
                
                setBachesOnSearch(false)

                return
            }

            setBachesOnSearch(false)
            setBatchesBySearch(response.data)
        } catch (error) {
            setBachesOnSearch(false)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel buscar pelo lote'
            })
        }
    }

    return (
        <div className="flex flex-col items-start justify-start gap-rep50 md:pl-rep md:pr-rep md:pt-rep-t h-screen mb-[20rem] max-sm:!p-[none]">
            <UserDefault home />

            {
                !batchesOnLoading && batches.length > 0 && (
                    <div className="w-100 h-auto flex flex-row items-center justify-between mt-[0.625rem] mb-[0.625rem]">
                        <div
                            className="flex-1 max-w-[43.875rem] flex flex-row items-center border-[0.15rem] h-[3.5rem]
                            pt-[1.093rem] pb-[1.093rem] pl-[2.284rem] pr-[2.284rem] gap-[1rem]
                            border-[#e4e4e4] rounded-[2.187rem] bg-white hover:bg-[#f4f4f4] group"
                        >
                            <GrSearch color="#707070" />
                            <input
                                type="search"
                                name="searc"
                                className="border-none w-full h-full bg-none outline-none placeholder:text-[#B9B8BC]"
                                placeholder="Procurar por quadro/lote"
                                onKeyPress={(event) => event.charCode === 13 && handlerSearchByBatches(event.currentTarget?.value || '')}
                            />
                            {
                                batchesOnSearch && batchesBySearch.length <= 0 && (
                                    <span className="w-auto h-auto flex flex-row items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-[#39A887]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
                                )
                            }
                        </div>
                        <button
                            onClick={() => history.push(`/produtos-imobiliario/novo/lote/${projectID}`)}
                            type="button"
                            className="flex-1 max-w-[20.687rem] h-[3.5rem] 
                            flex items-center justify-center 
                            pl-[1.687rem] pr-[1.687rem]
                            pt-[1.156rem] pb-[1.156rem] 
                            rounded-[0.9375rem] font-bold 
                            text-white bg-[#39A887] 
                            hover:opacity-80 transition-all duration-[0.3s]"
                        >
                            Cadastrar lote
                        </button>
                    </div>
                )
            }

            <div className="w-full h-auto flex md:flex-col max-sm:flex-row max-sm:flex-nowrap max-sm:justify-center max-sm:items-center">
                {
                    !batchesOnLoading && batches.length > 0 && (
                        <>
                            <div className="max-sm:flex-1  md:w-full flex p-[1rem]
                                md:flex-row max-sm:flex-col 
                                md:flex-nowrap md:items-center 
                                justify-start 
                                md:gap-[0.687rem] max-sm:gap-[2rem] ">
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold">
                                    Módulo
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Quadro/Lote
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Área (m²)
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Valor total
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    R$/m²
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Frente (m)
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Lateral (m)
                                </span>
                                <span className="w-[7rem] text-[1.062rem] text-[#707070] font-semibold md:text-center">
                                    Fundo (m)
                                </span>
                            </div>
                            <div className="max-sm:flex-1 md:w-full 
                                    flex 
                                    md:flex-col max-sm:flex-row max-sm:flex-nowrap
                                    max-sm:gap-[7.375rem] max-sm:overflow-x-auto 
                                    max-sm:overflow-y-hidden max-sm:rounded-[0.937rem]
                                    max-sm:pb-[1rem]  max-sm:pl-[1rem]
                                    items-start justify-start
                                "
                            >

                                {
                                    batchesBySearch?.length > 0 && (
                                        <span onClick={() => setBatchesBySearch([])} className="w-auto h-auto flex max-sm:items-center md:cursor-pointer" title="Limpar resultados da pesquisa">Limpar pesquisa</span>
                                    )
                                }

                                {
                                    batchesBySearch?.map((batche, index) => (
                                        <div key={index} className={`
                                            w-full max-sm:min-w-[17.375rem]
                                            flex md:flex-row  max-sm:flex-col
                                            md:flex-nowrap md:items-center
                                            justify-start md:gap-[0.687rem]
                                            max-sm:gap-[2rem]  p-[1rem]
                                            rounded-[0.937rem] ${index % 2 ? 'bg-[#F1F3F2]' : 'bg-white max-sm:shadow-md max-sm:border-[0.1] max-sm:border-gray-200'}`}>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] max-sm:text-center">
                                                {
                                                    batche.name
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.batch
                                                }k
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.area
                                                }m²
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.amount.toLocaleString('pt-BR', {
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 2,
                                                        maximumSignificantDigits: 2,
                                                        style: 'currency'
                                                    })
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.square_meter.toLocaleString('pt-BR', {
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 2,
                                                        maximumSignificantDigits: 2,
                                                        style: 'currency'
                                                    })
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.front
                                                }m
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.side
                                                }m
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.bottom
                                                }m
                                            </span>
                                            <button onClick={() => handleSetDeleteBatch(batche.name, batche.id)} title="Excluir" className="w-auto max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center cursor-pointer">
                                                <img src={terrainTrashIcon} alt="" />
                                            </button>
                                            <button
                                                type="button"
                                                title="Editar Terreno"
                                                onClick={() => history.push(`/produtos-imobiliario/proposta/lote/${projectID}/${batche.id}`)}
                                                className="flex-1 h-[2.312rem] 
                                                    max-sm:h-[4rem]
                                                    rounded-[0.937rem]
                                                    flex flex-row
                                                    items-center justify-center
                                                    font-bold  text-[#39A887]
                                                    text-[0.9rem] border-[#39A887] border-[1px]
                                                    hover:opacity-80 transition-all
                                                    duration-[0.3s]
                                                "
                                            >
                                                Editar
                                            </button>
                                            <button
                                                type="button"
                                                title="Propor Compra"
                                                onClick={() => history.push(`/produtos-imobiliario/proposta/lote/${projectID}/${batche.id}`)}
                                                className="flex-1 h-[2.312rem] 
                                                    max-sm:h-[4rem]
                                                    rounded-[0.937rem]
                                                    flex flex-row
                                                    items-center justify-center
                                                    text-white font-bold
                                                    text-[0.9rem] bg-[#39A887]
                                                    hover:opacity-80 transition-all
                                                    duration-[0.3s]
                                                "
                                            >
                                                P. compra
                                            </button>
                                        </div>
                                    ))
                                }
                                {
                                    batches.map((batche, index) => (
                                        <div key={index} className={`
                                            w-full max-sm:min-w-[17.375rem]
                                            flex md:flex-row  max-sm:flex-col
                                            md:flex-nowrap md:items-center
                                            justify-start md:gap-[0.687rem]
                                            max-sm:gap-[2rem]  p-[1rem]
                                            rounded-[0.937rem] ${index % 2 ? 'bg-[#F1F3F2]' : 'bg-white max-sm:shadow-md max-sm:border-[0.1] max-sm:border-gray-200'}`}>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] max-sm:text-center">
                                                {
                                                    batche.name
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.batch
                                                }k
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.area
                                                }m²
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.amount.toLocaleString('pt-BR', {
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 2,
                                                        maximumSignificantDigits: 2,
                                                        style: 'currency'
                                                    })
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.square_meter.toLocaleString('pt-BR', {
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 2,
                                                        maximumSignificantDigits: 2,
                                                        style: 'currency'
                                                    })
                                                }
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.front
                                                }m
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.side
                                                }m
                                            </span>
                                            <span className="w-[7rem] max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center">
                                                {
                                                    batche.bottom
                                                }m
                                            </span>
                                            <button onClick={() => handleSetDeleteBatch(batche.name, batche.id)} title="Excluir" className="w-auto max-sm:w-full text-[#707070] font-medium leading-normal text-[1.062rem] text-center cursor-pointer">
                                                <img src={terrainTrashIcon} alt="" />
                                            </button>
                                            <button
                                                type="button"
                                                title="Editar Terreno"
                                                onClick={() => history.push(`/produtos-imobiliario/atualizar/lote/${projectID}/${batche.id}`)}
                                                className="flex-1 h-[2.312rem] 
                                                    max-sm:h-[4rem]
                                                    rounded-[0.937rem]
                                                    flex flex-row
                                                    items-center justify-center
                                                    font-bold  text-[#39A887]
                                                    text-[0.9rem] border-[#39A887] border-[1px]
                                                    hover:opacity-80 transition-all
                                                    duration-[0.3s]
                                                "
                                            >
                                                Editar
                                            </button>
                                            <button
                                                type="button"
                                                title="Propor Compra"
                                                onClick={() => history.push(`/produtos-imobiliario/proposta/lote/${projectID}/${batche.id}`)}
                                                className="flex-1 h-[2.312rem] 
                                                    max-sm:h-[4rem]
                                                    rounded-[0.937rem]
                                                    flex flex-row
                                                    items-center justify-center
                                                    text-white font-bold
                                                    text-[0.9rem] bg-[#39A887]
                                                    hover:opacity-80 transition-all
                                                    duration-[0.3s]
                                                "
                                            >
                                                P. compra
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }

                {
                    !batchesOnLoading && batches.length <= 0 && (
                        <div className="flex flex-col items-center justify-center gap-[0.5rem] md:mt-[15%]  max-sm:self-center">
                            <span>
                                <img src={boundingBoxIcon} alt="" />
                            </span>
                            <span className="text-[#B8B9BA] font-[400]">
                                Ao carregar lotes, você os verá aqui
                            </span>

                            <button
                                title="Cadastrar novo Lote"
                                onClick={() => history.push(`/produtos-imobiliario/novo/lote/${projectID}`)}
                                type="button"
                                className="mt-[2.468rem] md:w-[20.687rem] 
                                    max-sm:w-full h-[3.5rem] 
                                    rounded-[0.937rem] bg-[#39A887]
                                    hover:opacity-80 active:opacity-80
                                    transition-all duration-[0.3s]
                                    text-white font-bold 
                                    text-[0.9rem]
                                "
                            >
                                Cadastrar lote
                            </button>
                        </div>
                    )
                }

                {
                    batchesOnLoading && batches.length <= 0 && (
                        <div className="w-full flex flex-col items-start justify-start gap-[1rem] ">
                            <Mock />
                            <Mock />
                            <Mock />
                            <Mock />
                        </div>
                    )
                }
            </div >
        </div >
    )
}