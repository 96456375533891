import styled, { css } from 'styled-components';

interface IImg {
  src: string;
}

interface IStep {
  active?: boolean;
}

interface IButtonStandard {
  selected?: boolean;
}

interface IBar {
  percent: number;
}
interface IButtonStatus {
  buttonStatus: boolean;
}

export const Container = styled.div<IButtonStatus>`
  .container-button-next {
    width: 300px;
  }
  #btn-next-step {
    position: relative;
    width: 100% !important;
  }
  .container-button-next:hover {
    #btn-next-step:disabled .tooltiptext {
      visibility: visible;
    }
  }
  .container {
    @media (max-width: 850px) {
      margin-top: 10%;
    }
  }
  .tooltiptext {
    visibility: hidden;
    width: 260px;
    background-color: #ccc;
    color: black;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;

    position: absolute;
    left: -20px;
    top: -34px;
    z-index: 1;
  }
  .part {
    color: #bdbdbd;
    display: flex;
    flex-direction: column;
    span {
      @media (max-width: 950px) {
        margin-left: 2.8rem;
      }
    }
    @media (max-width: 950px) {
      flex-direction: row;
    }
  }

  .dropdown-div {
    display: flex;
    p {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: right;

      color: #009e6e;
    }
    @media (max-width: 950px) {
      margin-left: 1rem;
    }
  }

  .finishing-green {
    width: 100%;
    margin-top: -18px;
    margin-left: -15px;
    .dropdown-menu {
      width: 45vw;
      height: 430px;
      border-radius: 20px;
      margin: 0;
    }
    .dropdown-toggle::after {
      display: none !important;
    }
    #nav-dropdown {
      color: #009e6e;
      display: none;

      h4 {
        :focus {
          color: black;
        }
      }
      @media (max-width: 950px) {
        display: flex;
      }
    }
  }
  .h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #707070;
  }
  .box {
    margin: auto;
    height: 488px;
    width: 865px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
  }

  hr {
    background-color: #f5f5f5;
    margin-left: -12px;
    margin-right: -12px;
  }

  .border-steps {
    height: 36px;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 5px;
    @media (max-width: 0px) {
      display: none;
    }
  }

  .espacio {
    align-items: center;
    @media (min-width: 991px) {
      margin-left: 710px;
    }
  }
`;

export const Step = styled.div<IStep>`
  border: 0;
  border-bottom: 3px solid transparent;
  padding-bottom: 10px;
  transition-duration: 0.3s;
  color: #b8b9ba;
  :hover {
    cursor: pointer;
  }

  ${(props) => props.active
    && css`
      border-color: #079269;
      color: #414142;
      font-weight: 600;
    `}
`;

export const Img = styled.div<IImg>`
  background: url(${(props) => props.src}), rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 359px;
  height: 204px;
  max-width: 300px;

  @media screen and (min-width: 576px) {
    max-width: unset;
    width: 445px;
    height: 232px;
  }

  @media screen and (min-width: 992px) {
    width: 360px;
    height: 205px;
  }
`;

export const ButtonStandard = styled.button<IButtonStandard>`
  border: 1px solid #e1eee4;
  width: 77px;
  height: 77px;
  border-radius: 10px;
  transition: 0.3s;
  color: #414142;
  font-size: 11px;

  :hover {
    background-color: #e9f5f2;
    border-color: #9cd3c3;
  }
  ${(props) => props.selected
    && css`
      background-color: #e9f5f2;
      border-color: #9cd3c3;
    `}
`;

export const Bar = styled.div<IBar>`
  background: #f1f1f1;
  border-radius: 38px;
  height: 16px;
  position: relative;
  overflow: hidden;

  div {
    transition-duration: 0.3s;
    border-radius: 38px;
    width: ${(props) => (props.percent >= 100 ? 100 : props.percent)}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    ${(props) => {
    if (props.percent >= 75) {
      return css`
          background: #ffa9a9;
        `;
    }

    if (props.percent >= 50) {
      return css`
          background: #ffdeb7;
        `;
    }

    return css`
        background: #9cd3c3;
      `;
  }}
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 12px;

    ${(props) => {
    if (props.percent >= 75) {
      return css`
          color: #dc4444;
        `;
    }

    if (props.percent >= 50) {
      return css`
          color: #e89c44;
        `;
    }

    return css`
        color: #079269;
      `;
  }}
  }
`;
