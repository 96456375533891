// Icons

// Hooks
import { usePersistent } from "~/hooks/Persistent";

// Types
import { IPersistentProps } from "./types";

// Models
import { IMUser } from "~/models/User";

// Partials
import { Mock } from "./Partials/Mock";

// Hooks
import { useAuthContext } from "~/hooks/contexts/Auth";

// Utils
import { getPathURI } from "~/utils/parseURL";

export function Persistent({children}: IPersistentProps) {
    const {onRecovery} = useAuthContext()
    const {
        abort, error, 
        onResult,onFinish, 
        onCompleted, success, 
        onError, onSuccess, onProgress
    } = usePersistent<IMUser | null>({
        dataBaseName: 'builders',
        objecStoreVersion: 2,
        objectStoreKey: 'id',
        objectStoreName: 'users',
        objectStoreData: null,
        objectStoreIsObject: true,
        objectStoreIsNested: true
    })

    // AUX Variables
    const ACTUAL_URI = getPathURI(window.location.href)
    const ACTUAL_URI_IS_NOT_LOGIN = ACTUAL_URI ? ACTUAL_URI.includes('/login') : true
    
    // console.table({
    //     abort, error, 
    //     onResult,onFinish, 
    //     onCompleted, success, 
    //     onError, onSuccess
    // })

    console.table({
            ACTUAL_URI_IS_NOT_LOGIN,
            uri: ACTUAL_URI,
            isNotLogin: ACTUAL_URI ? ACTUAL_URI.includes('/login') : true
        }
    )

    return <>
        <Mock.Notify showme={!onFinish && onProgress} />
        {
            !ACTUAL_URI_IS_NOT_LOGIN && onRecovery && (<Mock.Skeleton />)
        }
        {children}
    </>
}