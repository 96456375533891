import React from 'react';
import { BsClipboardCheckFill } from 'react-icons/bs';

import { Container } from './styles';

const AutorizacaoEnviada: React.FC = () => (
  <Container>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <BsClipboardCheckFill size={40} color="#39A887" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>Autorização enviada</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-8">
            <p>O engenheiro está autorizado para vistoria. Fique atento!</p>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default AutorizacaoEnviada;
