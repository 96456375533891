import styled from 'styled-components';

export const Container = styled.div`
  .mx-container {
    margin-left: 85.5px;
    margin-right: 85.5px;
  }

  .margin-top-negative {
    margin-top: -30px;
  }

  .textt {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;

    /* Title */

    color: #414142;
  }

  .margen-negative {
    margin-top: -30px;
  }
  .borderrr {
    border: 1px solid #ffffff;
  }
  .no-mobile {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .bg-defalt {
    background-color: #fcfcfc;
  }

  .separator {
    background: #b8b9ba;
    height: 5px;
    width: 40px;
    border-radius: 26px;
    padding-left: 50px;
  }

  .text-sub-2 {
    word-wrap: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 97px;
    color: #414142;
    span {
      font-weight: 250;
    }
  }

  .text-bot {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
  }

  .bg-gris {
    background: #f9f9f9;
  }

  .bg-gris3 {
    background: #414142;
    border-radius: 20px;
  }
  .text-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }

  .text-quem2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #838383;
  }
`;

interface BannerProps {
  src: string;
}

interface IAvatar {
  src: string;
}

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const Banner = styled.div<BannerProps>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  height: 70vh;
  background-position: center;
`;
