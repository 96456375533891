
export function getPathURI(link: string | null = null) {
    try {
        if(!link || link.includes('login')) throw new Error('The URL is empty or invalid')
        
        if (link.indexOf('/') !== -1) {
            link = link.substring(link.lastIndexOf('/'));
        } else {
            link = '/' + link;
        }

        return link;
    } catch (error) {
        return null
    }
 
}

export function setToExternalURI(link: string | null = null) {
    try {
        if(!link || link.trim() === '' || typeof link  !== 'string') throw new Error('The link is not valid');

        const NEW_EXTERNAL_URI = getPathURI(link)

        if(setPathURIStorage(NEW_EXTERNAL_URI)) {
            window.open(link, "new")
        }
    } catch(error: any) {
        throw error;
    }
}

export function setPathURIStorage(uri: string | null = null) {
    try {
        if(!uri) throw new Error('The URL is empty')
        
        sessionStorage.setItem('MCF_BUILDER@LAST_URI', uri)

        return true;
    } catch (error) {
        return null
    }
 
}

export function deletetPathURIStorage() {
    try {
        sessionStorage.removeItem('MCF_BUILDER@LAST_URI')

        return getPathURIStorage() && true
    } catch (error) {
        return null
    }
 
}

export function getPathURIStorage() {
    try {
        return sessionStorage.getItem('MCF_BUILDER@LAST_URI') || null
    } catch (error) {
        return null
    }
 
}

export function handlerSetNewLastURI() {
    try {
      console.table({
      pathname: location.pathname,
      hasPathname: !location.pathname.includes('login'),
      uri: getPathURI(location.pathname)
    })

    if(!location.pathname.includes('login')) setPathURIStorage(getPathURI(location.pathname))  
    } catch (error) {
      console.warn('Do not possible storage the last URI access');
    }
  }