import styled from 'styled-components';

export const Container = styled.div`
  /* margin-top: 6%;
  margin: 6% 6% 0px 6%;

  @media (max-width: 768px) {
    margin: 6% 0px 0px 0px;
  } */

  .initials-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .name-initials {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 70px;
    }
  }

  .form-input {
    border: none;
    border-bottom: 0.3px solid #b8b9ba;
    border-radius: 0;
  }

  .box-radio {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .button-save-box {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: center;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 0px;
    height: 55px;
    font-size: 18px;
  }

  .button-save {
    background-color: #39a887;
    color: #ffffff;
    width: 50%;
  }

  .button-delete {
    background-color: #e74c3c;
    color: #ffffff;
    padding: 0 20px;
  }
`;
