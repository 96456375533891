import React, { useEffect, useRef, useState } from 'react';
import MiniAvatar from '~/components/MiniAvatar';
import { useProfile } from '~/hooks/Profile';
import { useAuthContext } from '~/hooks/contexts/Auth';
import DropDownMenu from '../MiniAvatarDropDown';
import { Avatar, Container } from './styles';

const UserAvatar: React.FC = () => {
  const { user } = useAuthContext();
  const { photoEngineer } = useProfile();
  const [open, setOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleDropdownFocus = (state: boolean) => {
    setOpen(!state);
  };

  const handleClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Container>
      <div className="top-align">
        <div
          className="app-drop-down-container bg-transparent fondo"
          ref={dropdownRef}
        >
          <div
            className="user-login mt-2"
            onClick={() => handleDropdownFocus(open)}
            onKeyPress={() => handleDropdownFocus(open)}
            role="button"
            tabIndex={0}
          >
            <div className="me-4">
              <Avatar src={photoEngineer} className="mx-auto" />
            </div>
            <div className="me-4">
              <div>
                <p className="d-flex fw-semibold small text-dark-gray mb-0">
                  {user!.name}
                </p>
                <p className="fw-light"> Construtor </p>
              </div>
            </div>
            <div className="">
              <MiniAvatar />
              {open && <DropDownMenu />}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UserAvatar;
