import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { RiLockPasswordFill } from 'react-icons/ri';

import { Button } from 'react-bootstrap';

import Input from '~/components/Input';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { useProfile } from '~/hooks/Profile';
import { useAuthContext } from '~/hooks/contexts/Auth';
import { Modal } from './styles';

interface IModalProps {
  show: boolean;
  onHide: () => void;
}

interface IFormData {
  current_password: string;
  password: string;
  passwordConfirmation: string;
}

const ModalChangePassword: React.FC<IModalProps> = ({ show, onHide }) => {
  const { setLoading } = useProfile();
  const { user } = useAuthContext();
  const formRef = useRef<FormHandles>(null);
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setErrorPassword('');
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object({
          current_password: Yup.string().required('Informe a senha atual'),
          password: Yup.string()
            .min(8, 'senha é muito curta')
            .max(30, 'senha é muito longa')
            .required('Informe uma nova senha'),
          passwordConfirmation: Yup.string()
            .min(8, 'senha é muito curta')
            .max(30, 'senha é muito longa')
            .required('Confirme a nova senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!!data.password.match(/^(?=.*[a-z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra minúscula');
          setLoading(false);
          return;
        } else if (!!data.password.match(/^(?=.*[A-Z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra maiúscula');
          setLoading(false);
          return;
        } else if (!!data.password.match(/^(?=.*[0-9])/) !== true) {
          setErrorPassword('Necessário ao menos um número');
          setLoading(false);
          return;
        } else if (!!data.password.match(/^(?=.*[!@#%&])/) !== true) {
          setErrorPassword('Necessário ao menos uma caractere especial');
          setLoading(false);
          return;
        } else if (data.password === data.passwordConfirmation) {
          const { password, current_password } = data;

          const formData = {
            email: user!.email,
            current_password,
            password,
          };

          await api.put(`builders/users/${user?.id || -1}/password`, formData);
          setLoading(false);
          Toast.fire({
            icon: 'success',
            title: 'Senha alterada com sucesso!',
          });
        } else {
          setErrorConfirmPassword('As senhas não correspondem');
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao tentar alterar sua senha, verifique a senha atual informada',
            'error'
          );
        }
      }
    },
    [setLoading, user]
  );

  return (
    <Modal show={show} onHide={onHide} close>
      <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
        <Modal.Header className="border-none ps-4 pt-4" closeButton>
          <div className="d-flex align-items-center">
            <h2 className="me-4 mt-2">Mudar a senha</h2>{' '}
            <RiLockPasswordFill size={32} />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-2">
          <div className="py-5">
            <div className="mb-4">
              <label htmlFor="password_confirmation" className="mb-2 fw-medium">
                Senha atual
              </label>
              <Input
                type="password"
                placeholder="Senha atual"
                name="current_password"
                className="input"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="mb-2 fw-medium">
                Nova senha
              </label>
              <Input
                type="password"
                placeholder="Nova senha"
                name="password"
                className="input"
              />
              {errorPassword && (
                <span className="small text-danger error">{errorPassword}</span>
              )}
            </div>
            <div>
              <label htmlFor="password_confirmation" className="mb-2 fw-medium">
                Confirmar nova senha
              </label>
              <Input
                type="password"
                placeholder="Repetir senha"
                name="passwordConfirmation"
                className="input"
              />
              {errorConfirmPassword && (
                <span className="small text-danger error">
                  {errorConfirmPassword}
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons_group">
            <Button type="submit" className="btn-submit button_submit py-3">
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalChangePassword;
