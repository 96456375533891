import styled from 'styled-components';

export const Container = styled.div`
  .text-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    font-feature-settings: 'zero' on;

    color: #707070;
  }
  .bg-verdee {
    background: #39a887;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }

  .bg-gris {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 0px 0px 12px 12px;
    height: auto;
  }

  .sup-bar {
    background: #39a887;
    border-radius: 15px;
    width: 73.79px;
    height: 7.2px;
    opacity: 1;
  }

  .carddaos {
    background: #39a887;
    border-radius: 15px;
  }

  .divEndereco {
    padding-top: 50px;
    border: none;
    background: #ffffff;
    border-radius: 15px;
  }

  .endeDados {
    margin-left: 300px;
  }

  .ojobtn {
    background: #414142;
    border-radius: 25px;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
  }

  .ojobtn:hover {
    box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
  }

  .btnShare {
    margin-top: 20px;
    float: right;
    // box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    .btntelegram:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnfacebook:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnwhatsapp:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btntwitter:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnlinkedin:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnreddit:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
  }

  .alterarbtn {
    background: #079269;
    border-radius: 25px;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
  }

  .alterarbtn:hover {
    box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    background: #06835e;
  }

  .sub-bar {
    border: 0.3px solid #b8b9ba;
  }

  .h2 {
    font-size: 24px;
  }

  .input {
    // border: 0.3px solid #b8b9ba;
    border: none;
    padding: 0px 10px;

    input {
      color: #414141;
    }
  }

  .btn-insert {
    background-color: #7b7b7b;
    width: 100px;
    height: 34px;
    border-radius: 24px;
  }

  .logo {
    font-size: 14px;
  }

  .caract-count {
    color: #b8b9ba;
  }

  .btn-border {
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 0px 0px;
    height: 37px;
    white-space: nowrap;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: transparent;
    -webkit-text-fill-color: #707070;
    transition: 0.3s;

    :hover {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .tabs {
    margin-bottom: -1px;

    .active {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .folder {
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 10px 10px;
    background: #ffffff;
  }

  .name {
    color: #324351;
  }

  .btn-save {
    background: #39a887;
    border-radius: 20px;
    color: #ffffff;
    width: 100%;
    height: 50px;
  }

  .textArea {
    border: 1px solid #e4e4e4;
  }

  @media screen and (min-width: 414px) {
    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 331px;
      height: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 335px;
      height: 50px;
    }
  }
`;
