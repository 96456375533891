import React from 'react';

import { useProfile } from '~/hooks/Profile';
import { Container } from './styles';

interface IProps {
  step: string;
  setStep(step: 'PersonalData' | 'Construtech' | 'Payments'): void;
}

const TabButtons: React.FC<IProps> = ({ step, setStep }) => {
  const { engineer } = useProfile();

  return (
    <Container>
      <div className="ancho">
        <button
          type="button"
          className={`small btn-border px-3 me-1 ${
            step === 'PersonalData' && 'active'
          }`}
          onClick={() => setStep('PersonalData')}
          disabled={step === 'PersonalData' || engineer.cpf === null}
        >
          Dados Pessoais
        </button>
        {engineer.cpf && (
          <button
            type="button"
            className={`small btn-border px-3 me-1 ${
              step === 'Construtech' && 'active'
            }`}
            onClick={() => setStep('Construtech')}
          >
            Minha Construtech
          </button>
        )}
        {/* <button
        type="button"
        className={
          step === 'Payments'
            ? 'small btn-border px-3 me-1 active'
            : 'small btn-border px-3'
        }
        onClick={() => setStep('Payments')}
        disabled={step === 'Payments' || cpf === null}
      >
        Financeiro
      </button> */}
      </div>
    </Container>
  );
};

export default TabButtons;
