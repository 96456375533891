import styled from 'styled-components';

export const Container = styled.div`
  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;
    color: #b8b9ba;
  }
`;
