import { Modal as ModalComponent } from 'react-bootstrap';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .modal-content {
    .form-input {
      border: none;
      border-bottom: 0.3px solid #b8b9ba;
      border-radius: 0;
    }
    .add-financing {
      background-color: #e6e7e8;
      border-radius: 25px;
      width: 40px;
      height: 40px;
    }

    .profile-icon {
      background-color: #03c2ff;
      border-radius: 25px;
      height: 40px;
      width: 40px;
      p {
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #000000;
        -webkit-text-fill-color: #fff;
        font-weight: bold;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      color: #414142;
    }

    .border-none {
      border: none;
    }

    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #707070;
    }

    label {
      font-weight: 500;
      color: #707070;
      font-size: 14px;
      line-height: 17px;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .btn-take-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid transparent;
      color: #ffffff;
      font-weight: bold;
      background: #2e90fd;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
    }

    .buttons-group {
      button {
        width: 300px;
        border-radius: 30px;
        border: 2px solid transparent;
      }

      .btn-cancel,
      .btn-another {
        color: #d9d9d9;
        font-weight: 500;
        border-color: #d9d9d9;
      }

      .btn-submit {
        color: #ffffff;
        font-weight: bold;
        background: #39a887;
      }
    }
  }
`;
