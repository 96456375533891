import axios, { AxiosError, AxiosResponse } from 'axios';
import { authMiddlewareGetToken } from '~/hooks/contexts/Auth/middleware';
import { deletetPathURIStorage } from '~/utils/parseURL';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((request) => {
  try {
    const AUTHORIZATION_TOKEN = authMiddlewareGetToken();
    const PATHNAME = window.location.pathname;
    const WIHTOUT_LOGIN = !PATHNAME.includes('login');
    // LOW SECURITY LEVEL, ONLY FOR RUNTIME FLOW
    const WHITE_ALLOWED_URLS = [
      'auth',
      'me',
      'auth/me',
      'builders/auth/me',
      'builders/engineer',
      'admins/forgot-password'
    ];
    const WHITE_ALLOWED_URLS_WITHOUT_AUTH = [
      'admins/forgot-password',
      'builders/construtech/my-page/'
    ];
    const URI_PARSED = request.url?.substring((request.baseURL?.length || 0) + 1, request.url.length)

    // if(!WHITE_ALLOWED_URLS_WITHOUT_AUTH.includes()) {
    //   if (!AUTHORIZATION_TOKEN && WIHTOUT_LOGIN) {
    //     // window.location.href = '/login';
    //     console.log(URI_PARSED, WHITE_ALLOWED_URLS_WITHOUT_AUTH.includes(URI_PARSED || ''), WHITE_ALLOWED_URLS_WITHOUT_AUTH)
    //     throw new Error('The actual URI is not valid')
    //   }
    // }
    //

    // console.table({
    //   allowed: WHITE_ALLOWED_URLS,
    //   WIHTOUT_LOGIN,
    //   is_allowed:
    //     WIHTOUT_LOGIN || WHITE_ALLOWED_URLS.includes(request.url || ''),
    //   url: request.url,
    //   AUTHORIZATION_TOKEN,
    // });

    if (!request.url?.match(process.env.REACT_APP_SENTRY_DSN_HOST || '')) {
      request.headers['X-Sentry-Token'] =
        process.env.REACT_APP_SENTRY_X_TOKEN || '';
    }

    if (WIHTOUT_LOGIN || WHITE_ALLOWED_URLS.includes(request?.url|| '')) {
      if (!request.headers.authorization && !request.url?.includes('login')) {
        console.warn('The Authorization header is missing');
        console.warn('The Authorization header will be inject again');

      }
      request.headers['Authorization'] = `Bearer ${AUTHORIZATION_TOKEN}`;
    }

    return request;
  } catch (error) {
    return Promise.reject(error);
  }
});

api.interceptors.response.use(
  (request) => {
    try {
      return request;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (reject: AxiosResponse & AxiosError) => {
    try {
      if (
        reject.response?.status === 401 &&
        reject.request.responseURL.split('/').pop() !== 'login'
      ) {
        api.defaults.headers.Authorization = '';
        // localStorage.removeItem('@HarveyBuilder:token');
        // localStorage.removeItem('@HarveyBuilder:auth');
        deletetPathURIStorage();
        // setCookie('@HarveyBuilder:token', '');

        if (window?.location && window.location.pathname !== '/login')
          window.location.assign('/login');
      }

      return Promise.reject(reject);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default api;
