import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { SelectItemOptionsType } from 'primereact/selectitem';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import api from '~/services/api';
import { IMunicipality, IState } from '~/models/Simulation/RequestQuote';
import Loading from '~/components/Loading';
import { IMTerrain } from '~/models/Terrain';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';
import Dropdown from '~/components/Dropdown';

import { Container } from './styles';

interface IParams {
  setStep: (step: number) => void;
}

const TerrainData: React.FC<IParams> = ({ setStep }) => {
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<SelectItemOptionsType>();
  const [state, setState] = useState<number | undefined>(undefined);
  const [municipalities, setMunicipalities] = useState<SelectItemOptionsType>();
  const [municipality, setMunicipality] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (serviceOrder.terreno?.estado_id) {
      setState(serviceOrder.terreno?.estado_id);
    }
  }, [serviceOrder.terreno]);

  const handleLoadState = useCallback(() => {
    setLoading(true);
    api.get<IState[]>('builders/states').then((response) => {
      const data: SelectItemOptionsType = response.data.map((stateData) => ({
        value: stateData.id,
        label: stateData.nome,
      }));
      setStates(data);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    handleLoadState();
  }, [handleLoadState]);

  useEffect(() => {
    if (state) {
      api
        .get<IMunicipality[]>(`municipalities/state/${state}`)
        .then((response) => {
          const data: SelectItemOptionsType = response.data.map(
            (municipalityData) => ({
              value: municipalityData.name,
              label: municipalityData.name,
            })
          );
          setMunicipalities(data);
          if (serviceOrder.terreno?.municipio) {
            const city = data.find(
              (municipalityData) => municipalityData.value === serviceOrder.terreno?.municipio
            );
            setMunicipality(city?.value);
          }
        });
    }
  }, [state, serviceOrder.terreno?.municipio]);

  const handleSubmit = useCallback(
    async (data: IMTerrain) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.string().required('O CEP é obrigatório'),
          endereco: Yup.string().required('O endereço é obrigatório'),
          bairro: Yup.string().required('O bairro é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!state) {
          formRef.current?.setErrors({
            estado_id: 'O estado é obrigatório',
          });
          return;
        }

        if (!municipality) {
          formRef.current?.setErrors({
            municipio: 'O município é obrigatório',
          });
          return;
        }

        const terreno = {
          ...serviceOrder.terreno,
          ...data,
          estado_id: state,
          municipio: municipality,
        };

        api.put<IMTerrain>(
          `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${serviceOrder.id}/terrain/${serviceOrder.terreno?.id}`,
          terreno
        );

        setServiceOrder({
          ...serviceOrder,
          terreno,
        });
        setStep(3);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      }
    },
    [serviceOrder, setServiceOrder, state, municipality, setStep]
  );

  return (
    <>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={serviceOrder?.terreno}
          className="col-12"
        >
          <div className="row px-3">
            <div className="row px-3">
              <div className="col-lg-6 mb-5">
                <label className="w-100">
                  CEP: <b className="text-primary">*</b>
                  <InputMask
                    kind="zip-code"
                    name="cep"
                    placeholder="00000-000"
                    className="input mt-2 maskInput-style"
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-5">
                <label className="w-100">
                  Endereço: <b className="text-primary">*</b>
                  <Input
                    name="endereco"
                    placeholder="ex: Rua"
                    className="input mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-5">
                <label className="w-100">
                  Bairro: <b className="text-primary">*</b>
                  <Input
                    name="bairro"
                    placeholder="ex: Bairro"
                    className="input mt-2"
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-5">
                <label className="w-100">
                  Estado: <b className="text-primary">*</b>
                </label>
                <Dropdown
                  name="estado_id"
                  options={states}
                  placeholder="Escolha um estado"
                  handleChange={(e) => setState(e.value)}
                />
              </div>
              <div className="col-lg-6 mb-5">
                <label className="w-100">
                  Município: <b className="text-primary">*</b>
                </label>
                <Dropdown
                  name="municipio"
                  value={municipality}
                  options={municipalities}
                  disabled={!state}
                  placeholder="Escolha uma cidade"
                  handleChange={(e) => setMunicipality(e.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-auto d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-primary fw-semibold px-2"
              onClick={() => setStep(1)}
            >
              <MdOutlineKeyboardDoubleArrowLeft size={20} />
            </button>
            <button type="submit" className="btn btn-primary fw-semibold px-5">
              Próximo
            </button>
          </div>
        </Form>
      </Container>
      <Loading active={loading} />
    </>
  );
};

export default TerrainData;
