import React from 'react';
import { IconContext } from 'react-icons';
import {
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsLinkedin,
  BsInstagram,
  BsWhatsapp,
} from 'react-icons/bs';
import { useProfile } from '~/hooks/Profile';

export const Social: React.FC = () => {
  const { construtech } = useProfile();

  const handleRedirect = (url: string) => {
    window.open(`${url}`, '_blank');
  };

  const handleRedirectWa = (url: string) => {
    const number = url.replace(/\D/g, '');
    window.open(`https://wa.me/55${number}`, '_blank');
  };

  return (
    <div>
      {construtech?.about || construtech.engineer?.social_works?.id ? (
        <div className="py-1 mt-5 align-items-center">
          <div className="separator" />
          {construtech?.about && (
            <>
              <h4 className="py-3 text-title">Quem somos:</h4>
              <h6 className="mb-5 text-quem2 w-100">{construtech.about}</h6>
            </>
          )}
          <div className="d-flex">
            {construtech.engineer?.social_works?.facebook && (
              <button
                type="button"
                className="border-0 py-4 px-1 bg-transparent"
                onClick={() => handleRedirect(
                  construtech.engineer?.social_works?.facebook || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsFacebook className="h3" />
                </IconContext.Provider>
              </button>
            )}
            {construtech.engineer?.social_works?.youtube && (
              <button
                type="button"
                className="border-0 py-4 px-2 bg-transparent"
                onClick={() => handleRedirect(
                  construtech.engineer?.social_works?.youtube || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsYoutube className="h3" />
                </IconContext.Provider>
              </button>
            )}
            {construtech.engineer?.social_works?.linkedin && (
              <button
                type="button"
                className="border-0 py-4 px-2 bg-transparent"
                onClick={() => handleRedirect(
                  construtech.engineer?.social_works?.linkedin || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsLinkedin className="h3" />
                </IconContext.Provider>
              </button>
            )}
            {construtech.engineer?.social_works?.instagram && (
              <button
                type="button"
                className="border-0 py-4 px-2 bg-transparent"
                onClick={() => handleRedirect(
                  construtech.engineer?.social_works?.instagram || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsInstagram className="h3" />
                </IconContext.Provider>
              </button>
            )}
            {construtech.engineer?.social_works?.twitter && (
              <button
                type="button"
                className="border-0 py-4 px-2 bg-transparent"
                onClick={() => handleRedirect(
                  construtech.engineer?.social_works?.twitter || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsTwitter className="h3" />
                </IconContext.Provider>
              </button>
            )}
            {construtech.engineer?.social_works?.whatsapp && (
              <button
                type="button"
                className="border-0 py-4 px-2 bg-transparent"
                onClick={() => handleRedirectWa(
                  construtech.engineer?.social_works?.whatsapp || ''
                )
                }
              >
                <IconContext.Provider value={{ color: '707070' }}>
                  <BsWhatsapp className="h3" />
                </IconContext.Provider>
              </button>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
