import styled from 'styled-components';

export const Container = styled.div`
  .ancho {
    display: flex;
    width: fit-content;

    @media (min-width: 768px) {
      display: flex;
      margin-left: 0px;
      width: 100%;
    }
  }

  .bannner {
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    display: block;
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  width: 110%;
  height: 250px;
  padding-right: 120px;

  @media (max-width: 768px) {
    padding-right: 0px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .textBanner {
    position: absolute;
    top: 20%;
    left: 10%;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
`;
