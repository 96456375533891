import React, { useCallback, useEffect, useState } from 'react';

import { useStep } from '~/pages/Simulation/Hooks/useStep';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import Select from '~/components/Select';
import { IMImmobileType } from '~/models/InmobileTypes';

import PreviousButton from '../PreviousButton';
import NextButton from '../NextButton';
import { Container } from './styles';

const InputPropertyType: React.FC = () => {
  const {
    step, setStep, typesimovel, setIDImovel
  } = useStep();
  const [errorData, setErrorData] = useState('');
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [property_type, setPropertyType] = useState<IMImmobileType>();

  const handleNextStep = useCallback(async () => {
    if (!property_type) {
      setErrorData('O valor do tipo é obrigatório');
    } else {
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...serviceOrder.simulacao,
          tipo_imovel_id: property_type.id,
        },
      });
      setStep(4);
    }
  }, [property_type, setServiceOrder, serviceOrder, setStep]);

  const handleChangePropertyType = useCallback(
    (option) => {
      setPropertyType(option);
    },
    [setPropertyType]
  );

  const handleClickPrevious = useCallback(
    (target_step) => {
      setStep(target_step);
    },
    [setStep]
  );

  useEffect(() => {
    if (serviceOrder.simulacao !== undefined) {
      setIDImovel(Number(serviceOrder.simulacao.tipo_imovel_id));
      handleChangePropertyType(
        typesimovel.find(
          (type) => type.id === serviceOrder.simulacao?.tipo_imovel_id
        )
      );
    }
  }, [
    handleChangePropertyType,
    serviceOrder.simulacao,
    setIDImovel,
    typesimovel,
  ]);

  return (
    <Container>
      <p>Qual será o tipo de imóvel?</p>
      <label htmlFor="register" className="mb-2 fw-medium">
        Tipo de imóvel:
      </label>
      <Select
        type="name"
        placeholder="Selecione"
        name="property_type"
        className="input w-50"
        options={typesimovel}
        onChange={handleChangePropertyType}
      />
      {errorData && (
        <span className="small text-danger error">{errorData}</span>
      )}
      <div className="row d-flex justify-content-end">
        <div className="col-1 d-flex justify-content-end">
          <PreviousButton
            onClick={() => handleClickPrevious(step - 1)}
            target_step={step - 1}
          />
        </div>
        <div className="col-auto d-flex justify-content-start">
          <NextButton onClick={handleNextStep} />
        </div>
      </div>
    </Container>
  );
};

export default InputPropertyType;
