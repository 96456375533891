import { PropsWithChildren } from "react";
import { IEnvironment } from "~/hooks/BuildYourHouse/types";

export interface IStepProps extends PropsWithChildren<any> {
    stepType: TStepTargets | null
    generalID?: any;
    showNavigation: boolean
    
    // ASYNC DATAS
    externalEnvironments: IEnvironment[]
    
    // URL's
    urlEnvinromentCallback?: string;
    urlFinishCallback?: string | null;
    urlParametersCallback?: string;
    urlRequestCallback?: string | null;
    
    // Service Order
    hasOS?: boolean

    // CALLBACKS EVENT's
    onSelectedStepSlug?: (args: string) => void
    onSelectedStepPosition?: (args: number) => void
    onSelectedStep?: (args: IStep) => void
    onOptionsSelected?: (arg: IOption[]) => void
    onCompletedStatus?: (arg: boolean) => void    
    onFinishedCallback?: () => unknown
}

export type TOption = "tipo_acabamento" | "tipo_terreno" | "estilo_construcao" | "tipo_eletrica" | "tipo_hidraulica" | "pe_direito"

export interface IOptionType {
  tipo_acabamento: string;
  tipo_terreno: string;
  estilo_construcao: string;
  tipo_eletrica: string;
  tipo_hidraulica: string;
  pe_direito: string;
}
export interface IOptionResponse extends Partial<IOptionType> {
  id: number;
  margem: number;
  imagem: string;
  vetor: string; 
}

export interface IOption  extends Partial<IOptionType>{
  id: number;
  step_id: number;
  label: string;
  icon: string;
  photo: string;
  margem: number;
  selected?: boolean;
}

export interface IStep {
    id: number,
    type: string,
    question: string,
    slug?: string;
    options: IOption[],
    photo: string
    [index: string]: any
}

export interface IStepRecovery {
    [index: string]: IStep
}

export type TStepTargets = "acabamento" | "terreno" |  "estilo-da-casa" | "eletrica" | "hidraulica" | "pe-direito"

type TStepTarget = {
    name: TStepTargets;
    id: number;
    slug?: string;    
    question: string
}

export const STEPS_AVAILABLE: TStepTarget[] = [
    {name: "acabamento", slug: 'Acabamento', id: 1, question: 'Qual tipo de acabamento?'}, 
    {name: "terreno", slug: 'Terreno', id: 2, question: 'Em qual tipo de terreno deseja construir?'},
    {name: "estilo-da-casa", slug: 'Estilo da casa', id: 3, question: 'Qual estilo de casa deseja ter?'}, 
    {name: "eletrica", slug: 'Eletrica', id: 4, question: 'Em qual tipo de projeto elétrico?'}, 
    {name: "hidraulica", slug: 'Hidraulica', id: 5, question: 'Em qual tipo de projeto de hidráulica?'}, 
    {name: "pe-direito", slug: 'Pé direito', id: 6, question: 'Pé direito duplo?'}, 
]

export type TParameter = {
  [index in TOption | string]: number
}

export interface IParameter {
  tp_acabamento_id: number;
  tp_terreno_id: number;
  estilo_construcao_id: number;
  tp_eletrica_id: number;
  tp_hidraulica_id: number;
  pe_direito: number;
  [index: string]: number
}

export type TParameterRenamed = {
    [
        index in 
            "tp_acabamento_id" |
            "tp_terreno_id" |
            "estilo_construcao_id" |
            "tp_eletrica_id" |
            "tp_hidraulica_id" |
            "pe_direito" | string
    ]: TOption;
};

export const ParametersRenamed: TParameterRenamed = {
    estilo_construcao_id: 'estilo_construcao',
    pe_direito: 'pe_direito',
    tp_acabamento_id: 'tipo_acabamento',
    tp_eletrica_id: 'tipo_eletrica',
    tp_hidraulica_id: 'tipo_hidraulica',
    tp_terreno_id: 'tipo_terreno'    
}