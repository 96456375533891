import React from 'react';

import { useProject } from '~/pages/Projects/Hooks/useProject';
import { Container } from './styles';

const InputCalculate: React.FC = () => {
  const { sumTotalAmounts } = useProject();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    sumTotalAmounts(event.target.valueAsNumber);
  };

  return (
    <Container className="row m-3">
      <div className="title p-0">Seus projetos selecionados</div>
      <div className="mt-1 subtitle p-0">
        Você irá agregar muito valor, com esses projetos personalizados para seu
        cliente!
      </div>
      <div className="col mt-5 mb-4 p-0">
        <label>Qual o tamanho da construção em m2:</label>
        <div>
          <input
            type="number"
            min={1}
            className="calculate mt-4 ps-4"
            placeholder="Digite aqui"
            onChange={handleChange}
          />
          <hr className="sub-bar mt-2" />
        </div>
      </div>
    </Container>
  );
};

export default InputCalculate;
