import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Sidebar from '~/components/Menu/Sidebar';

import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import { handlerSetNewLastURI } from '~/utils/parseURL';
import { Container, Content } from './styles';

const DefaultLayout: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, token, signOut } = useAuthContext();
  const location = useLocation();
  const history = useHistory()
  const [unShowMenu, setUnshowMenu] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('@HarveyBuilder:token')) {
      api.get('builders/auth/me').catch(() => {
        signOut();
      });
    }
  }, [signOut]);

  useEffect(() => {
    setUnshowMenu(false);

    handlerSetNewLastURI();
  }, [location.pathname]);

  useEffect(() => {        
    handlerSetNewLastURI()
  }, []);

  return user?.id ? (
    // <Persistent>
      <Container className="d-flex justify-lg-content-center align-lg-items-start">
        {!unShowMenu && <Sidebar />}
        <Content fullscreen={unShowMenu}>
          {children}
        </Content>
      </Container>
    // </Persistent>
  ) : null;
};

export default DefaultLayout;
