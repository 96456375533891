import React, { useCallback } from 'react';
import { MdDelete, MdError } from 'react-icons/md';
import Swal from 'sweetalert2';
import api from '~/services/api';

import { Container, Bar } from './styles';

interface IUploadFileRender {
  id: string;
  os_id: string;
  name: string;
  percentage: number;
  loaded: number;
  totalSize: number;
  error: boolean;
  onHandleLoadDocuments(value: string): void;
}

const FileUploadProgressBar: React.FC<IUploadFileRender> = ({
  id,
  os_id,
  name,
  percentage,
  loaded,
  totalSize,
  error,
  onHandleLoadDocuments,
}) => {
  const handleDelete = useCallback(
    async (idValue: string) => {
      try {
        await api.delete(`builders/service-orders/${os_id}/files/${idValue}`);

        onHandleLoadDocuments(idValue);
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }
    },
    [onHandleLoadDocuments, os_id]
  );

  const handleConfirmDelete = useCallback(
    (idValue: string) => {
      Swal.fire({
        icon: 'warning',
        title: 'Tem certeza que\ndeseja excluir?',
        showCloseButton: true,
        confirmButtonText: 'Excluir',
        confirmButtonColor: '#FF1A50',
        iconColor: '#FF1A50',
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleDelete(idValue);
        }
      });
    },
    [handleDelete]
  );

  return (
    <Container className="border mx-3">
      <p className="title">{name}</p>

      <div className="mx-3 row">
        <div className="col-11 ">
          {!error && (
            <Bar percent={percentage === 0 ? 0 : percentage} className="my-2">
              <div>
                <p
                  className="mb-0"
                  style={{
                    color: `${percentage > 0 ? '#ffffff' : '#000000'}`,
                  }}
                />
              </div>
            </Bar>
          )}
        </div>
        <div className="col-1">
          {error !== true ? (
            <>
              {percentage === 100 && (
                <button
                  type="button"
                  className="border-0"
                  onClick={() => handleConfirmDelete(id)}
                >
                  <MdDelete size={25} />
                </button>
              )}
            </>
          ) : (
            <MdError size={25} color="red" />
          )}
        </div>
      </div>

      <div className="mx-3 row">
        <div className="col-6">
          {error !== true ? (
            <p className="mb-0">{percentage === 0 ? 0 : percentage}%</p>
          ) : (
            <b className="mb-0 error-box">
              Não foi possível carregar este documento
            </b>
          )}
        </div>
        <div className="col-5 total-size">
          {!error && <p className="mb-0">{`${loaded}kb de ${totalSize}kb`}</p>}
        </div>
      </div>
    </Container>
  );
};

export default FileUploadProgressBar;
