import React, { useState } from 'react';

import { Container, Step } from './styles';
import CreditAnalysis from '../CreditAnalysis';
import TerrainData from '../TerrainData';
import Owner from '../Owner';
import Contract from '../Contract';

const RequestQuote: React.FC = () => {
  const [step, setStep] = useState(1);
  const stepData = [
    {
      title: 'Confira os dados da análise de crédito',
      subTitle: 'Análise de crédito',
    },
    {
      title: 'Dados do terreno',
      subTitle: 'Dados do terreno',
    },
    {
      title: 'Dados do vendedor',
      subTitle: 'Dados do vendedor',
    },
  ];

  return (
    <>
      <Container>
        <div className="container">
          <div className="row">
            {step !== 4 && (
              <div className="col-12">
                <div className="col-12 container-box-information">
                  <div className="d-flex align-items-center">
                    <Step>
                      <p className="mb-0 fw-semibold title-owner">
                        {stepData[step - 1].title}
                      </p>
                    </Step>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-end px-4">
                  <p className="mb-0 fw-medium text-light-gray">
                    Parte{' '}
                    <span className="fw-bold">
                      {step.toString().padStart(2, '0')}
                    </span>
                    /04
                  </p>
                  <Step>
                    <p className="text-ver">{stepData[step - 1].subTitle}</p>
                  </Step>
                </div>
              </div>
            )}
            {step === 1 && <CreditAnalysis setStep={setStep} />}
            {step === 2 && <TerrainData setStep={setStep} />}
            {step === 3 && <Owner setStep={setStep} />}
            {step === 4 && <Contract />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default RequestQuote;
