import React, { useCallback, useEffect, useState } from 'react';
import { GrSearch } from 'react-icons/gr';
import { useDatatable } from '~/hooks/Datatable';
import { Container } from './styles';

interface ISearch {
  className?: string;
}

const InputSearch: React.FC<ISearch> = ({ className }) => {
  const { setSearch } = useDatatable();
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    setSearchValue(value);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchValue);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, setSearch]);

  return (
    <Container className={className}>
      <div className="d-flex align-items-center search">
        <div className="ps-5 pe-2 me-3">
          <GrSearch />
        </div>
        <div className="w-100">
          <input
            className="border-0 w-100"
            type="text"
            placeholder="Procurar por ID, Data, Nome"
            onChange={handleSearch}
          />
        </div>
      </div>
    </Container>
  );
};

export default InputSearch;
