import React from 'react';

import { useHistory } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';

import { Container } from './styles';

const CreditoAprovado: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();

  const pushemitircontrato = () => {
    history.push(`/emitir-contrato/${serviceOrder?.id}`);
  };

  return (
    <Container className="container col-md-11 col-11 border-3 card-dashboard justify-content-center p-2">
      <div className="p-2">
        <div className="d-flex align-items-center mb-4">
          <div className="w-full d-flex flex-column">
            <h3 className="mb-0 font-weight-700 text-black">
              Seu Crédito foi aprovado! 🎉
            </h3>
            <p className="mb-0">
              Parabéns, seu crédito foi aprovado, agora você pode prosseguir
              para os próximos passos
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex flex-column text-dark text-opacity-50">
            <div>Valor aprovado:</div>
            <div className="fw-bold">
              {formatCurrency(serviceOrder.simulacao?.vlrAprovado || 0)}
            </div>
          </div>
          <div className="col d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary btn-next padding-top fw-semibold px-4 rouder"
              onClick={pushemitircontrato}
            >
              Emitir contrato
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreditoAprovado;
