import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import logoHarvey from '~/assets/logos/logo-harvey.svg';
import mailSuccess from '~/assets/animations/mailSuccess.json';
import { Container, Background, Content } from './styles';

interface IFormData {
  email: string;
}

const EmailSentSucess: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Informe um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('users/sessions/forgot-password', {
        email: data.email,
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    }
  }, []);

  return (
    <Container className="container-fluid">
      <div className="row">
        <Background className="col-lg-6 col-xl-6 col-xxl-7 d-none d-lg-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <div>
            <img
              src={logoHarvey}
              alt="logo-harvey"
              className="mb-5 d-block mx-auto"
            />
            <h2 className="fw-light text-center company">
              <b className="fw-medium">Harvey</b> Construtor
            </h2>
            <hr className="line" />
            <p className="text-center">
              Aqui você encontrará todos os modelos de contratos e
              <br />
              Documentações Exclusivas que serão utilizados no
              <br />
              processos de financiamento habitacional.
            </p>
          </div>
        </Background>
        <Content className="bg-dark-1 col-lg-6 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <Form ref={formRef} onSubmit={handleSubmit} className="px-sm-4">
            <div className="mb-5">
              <h1 className="h2 text-dark-gray fw-semibold mb-1 text-center">
                Email enviado com sucesso
              </h1>
              <div className="row justify-content-center align-items-center">
                <p className="col-lg-10 fw-normal text-gray text-center">
                  Verifique sua caixa de entrada, spam ou lixo eletrônico
                </p>
              </div>
            </div>
            <div className="mb-5 mt-5">
              <Link
                to={`${process.env.PUBLIC_URL}/login`}
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                Voltar para Login
              </Link>
            </div>
          </Form>
        </Content>
      </div>
    </Container>
  );
};

export default EmailSentSucess;
