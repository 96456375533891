import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { formatPrice } from '~/utils/format';
import { useSimulations } from '~/hooks/Simulations';
import {
  maximumDeadline,
  maximumFinancedValue,
} from '~/utils/simulationCalculations';
import { Container, Range } from './styles';

const RangeLimitValueAndYears: React.FC = () => {
  const {
    simulations,
    targetLimitValue,
    setTargetLimitValue,
    setYearSelected,
    yearSelected,
  } = useSimulations();
  const [financedValue, setFinancedValue] = useState<number>(0);
  const yearsTotal = maximumDeadline(simulations) + parseFloat(format(new Date(), 'yyyy'));

  const loadDataOnlyOnce = useCallback(() => {
    if (financedValue === 0) {
      setFinancedValue(maximumFinancedValue(simulations));
    }
  }, [financedValue, simulations]);

  useEffect(() => {
    loadDataOnlyOnce();
  }, [loadDataOnlyOnce, targetLimitValue]);

  const handleChangeLimit = useCallback(
    (e) => {
      setTargetLimitValue(parseFloat(e.target.value));
    },
    [setTargetLimitValue]
  );

  const handleChangeDeadline = useCallback(
    (e) => {
      setYearSelected(parseInt(e.target.value, 10));
    },
    [setYearSelected]
  );

  const handleCalculatePercent = useCallback(
    (totalY: number, selectedY: number) => {
      const numerator = totalY - parseFloat(format(new Date(), 'yyyy')) - (totalY - selectedY);

      const denominator = totalY - parseFloat(format(new Date(), 'yyyy'));

      const percent = (numerator / denominator) * 100;
      return percent;
    },
    []
  );

  return (
    <Container className="col-lg-8 my-lg-5">
      <div className="row px-4 py-5">
        <div className="col-md-6 mb-5 mb-md-0">
          <div>
            <h2 className="h5 fw-medium text-dark-gray">
              Limitar valor de contratação
              <span className="d-none d-md-block d-xl-none">&nbsp;</span>
            </h2>
            <p className="text-light-gray">
              Selecione um valor menor, caso necessite
              <span className="d-none d-xl-block d-xxl-none">&nbsp;</span>
            </p>
            <p className="h3 fw-semibold text-dark-gray my-3">
              {formatPrice(maximumFinancedValue(simulations))}
            </p>
            <Range
              percent={(targetLimitValue * 100) / financedValue}
              className="w-100"
            >
              <input
                type="range"
                min={1000}
                max={financedValue}
                value={targetLimitValue}
                step="100"
                className="w-100"
                onChange={handleChangeLimit}
              />
            </Range>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <h2 className="h5 fw-medium text-dark-gray">
              Quanto pagará ao longo dos anos
            </h2>
            <p className="text-light-gray">
              Quanto maior o prazo, menores são as parcelas
            </p>
            <p className="h3 fw-semibold text-dark-gray my-3">
              {yearSelected - parseFloat(format(new Date(), 'yyyy')) + 1} ano(s)
            </p>
            <Range
              percent={handleCalculatePercent(yearsTotal, yearSelected)}
              className="w-100"
            >
              <input
                type="range"
                min={parseFloat(format(new Date(), 'yyyy'))}
                max={yearsTotal - 1}
                value={yearSelected}
                className="w-100"
                onChange={handleChangeDeadline}
              />
            </Range>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RangeLimitValueAndYears;
