import { useEffect, useState } from "react";

// Icons
import noImage from '~/assets/icons/no-image.svg';

// Types
import { IHouseProps, IHouseURLs } from "./types";

export function Home({ index, onSetHousePosition, name, real_estate_product_id, id, photo, photo_upload_id }: IHouseProps) {
    const [imageURL, setImageURL] = useState<string | null>(null)

    useEffect(() => {
        try {
            let urlParsed = null
            const urls = JSON.parse(sessionStorage.getItem('REP/URLS') || "[]") as unknown as IHouseURLs[]

            const urlAlready = urls.findIndex(url => url.id === id)

            // CLEAR TRASH IN RUNTIME
            urls.forEach(urlCached => {
                // URL.revokeObjectURL(urlCached.url || '')
            })

            if (!photo_upload_id && photo) {
                urlParsed = URL.createObjectURL(photo)
            } else if (photo_upload_id) {
                urlParsed = `${process.env.REACT_APP_API_URL}/builders/real-estate-products/houses/${id}/photo`
            }

            // UPDATE TO LAST REFERENCE OBJECT URL
            if (urlAlready >= 0) {
                urls[urlAlready].url = urlParsed || ''
            } else {
                urls.push({
                    id,
                    url: urlParsed || ''
                })
            }

            sessionStorage.setItem('REP/URLS', JSON.stringify(urls))

            setImageURL(urlParsed)
        } catch (error) {
            // do anything
        }
    }, [])

    return (
        <div
            tabIndex={0}
            role="button"
            onClick={() => onSetHousePosition(index, name || '')}
            onKeyUp={() => onSetHousePosition(index, name || '')}
            className="flex flex-col md:w-[14.75rem] md:h-[7.125rem] max-sm:w-full max-sm:min-h-[7.125rem] max-sm:max-h-[7.125rem] rounded-[0.937rem] bg-white overflow-hidden shadow-sm cursor-pointer"
            title={`Editar ${name || ''}`}
            key={index}
        >
            <div className="flex overflow-hidden items-center justify-center object-cover bg-gray-100 w-full h-[60%]">
                {
                    !imageURL && (
                        <img src={noImage}
                            alt="Imagem da casa"
                            className="md:!max-w-[3rem] max-sm:!max-w-[2rem] h-auto object-cover" loading="lazy" />
                    )
                }
                {
                    imageURL && (
                        <img src={imageURL}
                            alt="Imagem da casa"
                            className="md:!max-w-full h-auto object-cover" loading="lazy" />
                    )
                }
            </div>
            <div className="flex-1 flex flex-row items-center justify-center bg-white text-[#414142] font-[500]">
                <span className="overflow-hidden text-ellipsis break-normal max-w-full h-auto block">
                    {
                        name
                    }
                </span>
            </div>
        </div>
    )
}