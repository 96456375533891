import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { BsChatRightDotsFill } from 'react-icons/bs';
import pic2 from '~/assets/pic/pic2.png';

import UserAvatar from '~/components/UserAvatar';
import { useProfile } from '~/hooks/Profile';
import { Container, CompleteData, Container2 } from './styles';

const AssinaturaDos: React.FC = () => {
  const history = useHistory();
  const { validateEngineerData } = useProfile();

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleBack = (): void => {
    history.goBack();
  };
  const handleHome = (): void => {
    history.push('/dashboard');
  };
  return (
    <div className="bg-white">
      <Container className="container mx-auto position-relative">
        <div className="row justify-content-between h-100">
          <div className="d-flex align-items-center mb-5">
            <div className="d-flex align-items-center me-2">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4 px-4"
                onClick={handleBack}
              >
                <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
              </button>
              <div>
                <h1 className="title-assinatura row mt-4">
                  Assinatura do contrato
                </h1>
                <h1 className="title-sub row">Assinatura Presencial</h1>
              </div>
            </div>

            <div
              className="col-auto"
              style={{
                marginLeft: '50%',
                marginTop: '5%',
              }}
            >
              <div className="col-lg-4 top-align">
                <UserAvatar />
              </div>
            </div>
          </div>
          <div className="ms-5">
            <CompleteData className="row ms-5">
              <div className="title-correspondente mt-4">
                <div className="col-auto me-2">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsChatRightDotsFill className="px-2 h1" />
                  </IconContext.Provider>
                </div>
                Correspondente:
              </div>
              <div className="sub-correspondente pb-4 ms-5">
                Texto variável, pois o correspondente sobe uma mensagem para o
                construtor instruindo
              </div>
            </CompleteData>
          </div>
          <div className="row ms-5">
            <div className="col-4 ms-5 mt-5">
              <img src={pic2} alt="pic2" />

              <Container2>
                <div className="vl" />
              </Container2>
            </div>

            <div className="col pt-5">
              <div className="pt-5 title h1 title-sub">
                Assinatura do
                <div className="title-contrato ms-2"> contrato</div>
              </div>
              <div className=" title2 title-sub">
                Seu contrato foi enviado para
                <div className="title-agencia ms-2"> agência: 00001</div>
              </div>
              <div className="py-2" />
              <div className="title-sub">
                Faça uma visita a agência, crie o relacionamento do seu cliente
                com o gerente e marque um dia para assinatura do contrato.
              </div>
              <div className="py-2" />
              <div className="title-sub">
                O Minha Casa Financiada, já enviou um email para agência com
                todas informações, caso precise de algo, nos informe no chat.
              </div>
              <div className="title-sub">
                Clique no link abaixo e faça a assinatura.
              </div>
              <div className="py-5" />
              <button
                type="button"
                className="button-prox btn  btn-nexttt fw-semibold"
                onClick={handleHome}
              >
                Ir para dashboard
              </button>
            </div>
          </div>
        </div>
      </Container>
      <div className="py-5" />
      <div className="py-5" />
      <div className="py-4" />
    </div>
  );
};

export default AssinaturaDos;
