import { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from '~/components/Input';
import api from '~/services/api';
import { IMBoard } from '~/models/Board';
import { IMTask } from '~/models/BoardTask';
import { Container, Modal } from '../styles';

interface IFormData {
  comentario: string;
}

interface IModalAddProps {
  handleClose: () => void;
  quadro: IMBoard;
  updateDataColumn: (column: IMBoard) => void;
}

const ModalAdd: React.FC<IModalAddProps> = ({
  handleClose,
  quadro,
  updateDataColumn,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [, setDataForm] = useState({});

  const handleAddTask = useCallback(
    async (data: IFormData) => {
      try {
        const dataForm = {
          os_id: 13,
          quadro_id: quadro.id,
          ordem: 1,
          comentario: data.comentario,
          tasks: [] as IMTask[],
        };
        setDataForm(dataForm);
        await api.post(
          `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${quadro.id}/tasks`,
          dataForm
        );

        handleClose();
        updateDataColumn({
          ...quadro,
          ...dataForm,
        });
      } catch (err) {
        //
      }
    },
    [handleClose, quadro, updateDataColumn]
  );

  return (
    <>
      <Form ref={formRef} onSubmit={handleAddTask} className="p-4">
        <Modal.Header closeButton>
          <Modal.Title>Adicionar tarefa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="container">
            <div className="col-lg-12">
              <div className="row">
                <div>
                  <label>Conteúdo da tarefa: *</label>
                  <Input
                    className="form-input mt-4"
                    name="comentario"
                    placeholder="ex: Tarefa nova"
                  />
                </div>
              </div>
            </div>
            <div className="buttons-group">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-white border m-4"
                  onClick={handleClose}
                >
                  Fechar
                </button>
                <button
                  type="submit"
                  className="btn-submit btn btn-secondary m-4"
                >
                  Salvar
                </button>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Form>
    </>
  );
};

export default ModalAdd;
