import { InputNumber } from 'primereact/inputnumber';

// Types
import { IStepData } from '../../types';
import { IAroundProps } from './types';

// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';

// Components
import { MessageError } from '~/pages/RealEstateProduct/Partials/MessageError';
import { Option } from '../../../Partials/Option';

export function Around({ onDataStream, dataStep }: IAroundProps) {
  return (
    <div className="flex flex-col md:items-start md:justify-start max-sm:items-center max-sm:justify-center gap-[1.875rem] w-full h-full max-sm:p-[1rem]">
      <p className="text-[#707070] font-[400] max-sm:w-full max-sm:text-center">
        Preencha as seguintes informações
      </p>

      <div className="w-full h-auto flex flex-col gap-[2.5rem] bg-white rounded-[0.937rem] p-[1.875rem] shadow-sm overflow-hidden">
        <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Quantos lotes tem o condôminio?
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="500"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => onDataStream!({
                quantity_terrains: Number(event.target.value || undefined)
              }, '3')}
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.quantity_terrains || ''}
              inputMode='numeric'
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='quantity_terrains' />
          </div>
          <div className="flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Quantas casas tem construidas e em construção?
            </span>
            <InputNumber
              inputClassName="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] !pt-[0] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="100"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              mode="decimal"
              locale="pt-BR"
              onChange={({ value }) => onDataStream!({
                built_houses: Number(value || '')
              }, '3')}
              // @ts-ignore
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.built_houses || ''}
              inputMode='numeric'
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='built_houses' />
          </div>
        </div>

        <span className="text-[#B8B9BA] text-[0.75rem] font-[400] slashed-zero mb-[2.187rem]">
          Esses dados serão usados para calcular o maturidade do condomínio.
        </span>

        <div className="flex flex-col gap-[2.187rem]">
          <div className="flex flex-col gap-[0.312rem]">
            <span className="text-[#414142] font-semibold text-[0.875rem]">
              Detalhes do condomínio
            </span>
            <span className="text-[#414142] text-[0.875rem] font-[400]">
              Selecione as opções aplicáveis ou adicione as que deseja
            </span>
          </div>

          <Option callerID="3" callerKey="condominiumOptionsIds" dataStep={dataStep} onDataStream={onDataStream} />
        </div>
      </div>
    </div>
  );
}
