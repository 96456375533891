import React, { useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';

import { Modal } from '../../../styles';

interface IUserProps {
  id: number;
  nome: string;
  documento: string;
  profissao: string;
}

interface IModalContactData {
  email: string;
  celular: string;
}

interface IModalContactProps {
  show: boolean;
  user: IUserProps;
  contact: IModalContactData;
  onHide: () => void;
}

const ModalContact: React.FC<IModalContactProps> = ({
  show,
  user,
  contact,
  onHide,
}) => {
  const history = useHistory();
  const formAddContact = useRef<FormHandles>(null);

  const onSubmit = useCallback(
    async (data: IModalContactData) => {
      try {
        formAddContact.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('obrigatório informar o email'),
          celular: Yup.string().required(
            'obrigatório informar o numero de celular'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, celular } = data;

        const formData = {
          nome: user.nome,
          documento: user.documento,
          profissao: user.profissao,
          email,
          celular,
        };

        try {
          api.put(`builders/customers/${user.id}`, formData);
          history.push(`${process.env.PUBLIC_URL}/solicitacoes`);

          onHide();
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErros(err);
            formAddContact.current?.setErrors(errors);
          } else {
            Swal.fire(
              'Opss...',
              'Erro ao atualizar dados de endereço',
              'error'
            );
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formAddContact.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Erro ao atualizar dados de endereço', 'error');
        }
      }
    },
    [history, onHide, user.documento, user.id, user.nome, user.profissao]
  );

  return (
    <>
      <Modal show={show} onHide={onHide} close>
        <Form
          className="py-5 px-3 px-md-5"
          ref={formAddContact}
          initialData={contact}
          onSubmit={onSubmit}
        >
          <Modal.Header className="border-0" closeButton>
            <h4 className="text-center w-100 h5 fw-semibold text-dark-gray">
              Editar Contato
            </h4>
          </Modal.Header>
          <Modal.Body className="pt-4 pb-5">
            <div className="mb-4">
              <label className="w-100">
                <span className="small">
                  E-mail: <span className="text-primary">*</span>
                </span>
                <Input
                  className="input"
                  type="email"
                  placeholder="email@example.com"
                  name="email"
                />
              </label>
            </div>
            <div>
              <label className="w-100">
                <span className="small">
                  Celular: <span className="text-primary">*</span>
                </span>
                <InputMask
                  kind="cel-phone"
                  className="input"
                  placeholder="()"
                  name="celular"
                  value={contact.celular}
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <button
              className="btn btn-primary w-100 w-md-75 py-2"
              type="submit"
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalContact;
