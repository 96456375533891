import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useRef } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import Input from '~/components/Input';
import InputDate from '~/components/InputDate';
import InputMask from '~/components/InputMask';
import InputTag from '~/components/InputTag';
import Textarea from '~/components/Textarea';
import { useValidError } from '~/hooks/ValidError';
import { ITask } from '~/models/Home/Task';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Modal } from './styles';

interface IFormData {
  title: string;
  date: string;
  period_start: string;
  period_end: string;
  categories: string;
  description: string;
}

interface IModalAddEditTask {
  show: boolean;
  taskSelected?: ITask;
  tasksData: ITask[];
  onHandleClose(): void;
  onSetTasks: React.Dispatch<React.SetStateAction<ITask[]>>;
}

const ModalAddEditTask: React.FC<IModalAddEditTask> = ({
  show,
  taskSelected,
  tasksData,
  onHandleClose,
  onSetTasks,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { validError } = useValidError();

  const handleSubmitTask = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O titulo é obrigatória'),
          date: Yup.string().required('A data é obrigatória'),
          period_start: Yup.string().required('Desde é obrigatória'),
          period_end: Yup.string().required('Até é obrigatória'),
          categories: Yup.string().required('Categorias é obrigatória'),
          description: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const [day, month, year] = data.date.split('/');

        const formData = {
          ...data,
          date: `${year}-${month}-${day}`,
        };

        if (taskSelected?.id) {
          const response = await api.put(
            `builders/tasks/${taskSelected.id}`,
            formData
          );

          const newTasks = tasksData.slice();
          const tasksIndex = newTasks.findIndex(
            (task) => task.id === taskSelected.id
          );
          if (tasksIndex >= 0) {
            newTasks[tasksIndex] = {
              ...response.data,
              categories: response.data.categories.split(','),
              date: format(parseISO(response.data.date), 'dd/MM/yyyy'),
              datetime: parseISO(response.data.date),
            };
            onSetTasks(newTasks);
          }
        } else {
          const response = await api.post('builders/tasks', formData);

          onSetTasks((state) => [
            ...state,
            {
              ...response.data,
              categories: response.data.categories.split(','),
              date: format(parseISO(response.data.date), 'dd/MM/yyyy'),
              datetime: parseISO(response.data.date),
            },
          ]);
        }

        Toast.fire({
          icon: 'success',
          title: taskSelected?.id
            ? 'Tarefa editada com suceddo'
            : 'Tarefa criada com suceddo',
        });

        onHandleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(Error(String(error)));
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [onHandleClose, onSetTasks, taskSelected?.id, tasksData, validError]
  );

  return (
    <Modal show={show} onHide={onHandleClose} size="lg">
      <Form
        ref={formRef}
        initialData={taskSelected}
        onSubmit={handleSubmitTask}
        className="p-5"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            {taskSelected?.id ? 'Editar tarefa' : 'Adicionar nova tarefa'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="d-block w-100 mb-3">
            <span className="d-block mb-2">Título</span>
            <Input name="title" />
          </label>
          <label className="d-block w-100 mb-3">
            <span className="d-block mb-2">Data</span>
            <InputDate
              name="date"
              className="input-date"
              value={taskSelected?.datetime}
            />
          </label>
          <div className="my-4">
            <label className="d-block w-100 mb-2">
              <span className="d-block">Selecione o periodo</span>
            </label>
            <div className="d-flex align-items-center justify-content-center border-input-group py-3">
              <label className="px-4 w-25">
                <span className="px-3">Desde</span>
                <InputMask
                  kind="custom"
                  name="period_start"
                  options={{
                    mask: '99:99',
                  }}
                  className="input-period border-0 bg-transparent"
                  placeholder="12:00"
                  value={taskSelected?.period_start}
                />
              </label>
              <IoIosArrowForward size={32} color="#B8B9BA" />
              <label className="px-4 w-25">
                <span className="px-3">Até</span>
                <InputMask
                  kind="custom"
                  name="period_end"
                  options={{
                    mask: '99:99',
                  }}
                  className="input-period border-0 bg-transparent"
                  placeholder="13:00"
                  value={taskSelected?.period_end}
                />
              </label>
            </div>
          </div>
          <label className="d-block w-100 mb-3">
            <span className="d-block mb-2">Categorias</span>
            <InputTag name="categories" tags={taskSelected?.categories} />
          </label>
          <label className="d-block w-100 mb-3">
            <span className="d-block mb-2">Descrição (opcional)</span>
            <Textarea name="description" rows={4} />
          </label>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center pb-4">
          <button
            type="submit"
            className="btn-submit-form border-0 w-50 py-2 fw-bold"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAddEditTask;
