import { useCallback, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IconContext } from 'react-icons';
import { BsTrash } from 'react-icons/bs';

import { IMBoard } from '~/models/Board';
import api from '~/services/api';
import Toast, { ToastParametizer } from '~/utils/toast';
import { useKanban } from '../../Hooks/useKanban';
import ItemColumn from '../ItemColumn';
import TitleColumn from '../TitleColumn';
import ModalAdd from './ModalAdd';
import { CardContainer, Container, Modal } from './styles';

interface IColumnProps {
  data: IMBoard;
  index: number;
  key: number;
}

const Boards: React.FC<IColumnProps> = ({ data, index }) => {
  const { columns, setColumns, updateDataColumn } = useKanban();
  const [board, setBoard] = useState(data?.quadro);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);

  const handleCloseModalAdd = useCallback(() => {
    setShowModalAdd(false);
  }, []);

  const handleDeleteColumn = useCallback(
    async (id?: number) => {
      ToastParametizer(true,true).fire({
        icon: 'warning',
        title: 'Deseja exluir o quadro?',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.delete(
              `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${id}`
            );
            const newColumns = columns.filter((col) => col.id !== id);
            setColumns(newColumns);

            Toast.fire({
              icon: 'success',
              title: 'Quadro excluido com sucesso!',
            });
          } catch (err) {
            Toast.fire({
              icon: 'warning',
              title: 'Erro ao excluir',
            });
          }
        }
      });
    },
    [columns, setColumns]
  );

  return (
    <>
      <Draggable key={`${data?.id}`} draggableId={`${data?.id}`} index={index}>
        {
          // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
          (provided: any) => (
            <Container
              className={data?.id}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            >
              <div className="d-flex gap-2 align-items-center">
                <svg
                  width="31"
                  height="30"
                  viewBox="0 0 31 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.166 20H25.166V25H20.166V20ZM12.666 20H17.666V25H12.666V20ZM5.16602 20H10.166V25H5.16602V20ZM20.166 12.5H25.166V17.5H20.166V12.5ZM12.666 12.5H17.666V17.5H12.666V12.5ZM5.16602 12.5H10.166V17.5H5.16602V12.5ZM20.166 5H25.166V10H20.166V5ZM12.666 5H17.666V10H12.666V5ZM5.16602 5H10.166V10H5.16602V5Z"
                    fill="#C6C6C6"
                  />
                </svg>
                <TitleColumn
                  value={board}
                  setValue={setBoard}
                  completeBoard={data}
                  index={index}
                />
                {data?.id ? (
                  <div className="col d-flex justify-content-end">
                    <button
                      className="borde"
                      type="button"
                      onClick={() => handleDeleteColumn(data?.id)}
                    >
                      <IconContext.Provider value={{ color: '#ff2200' }}>
                        <BsTrash className="h6 ms-1 mt-2" />
                      </IconContext.Provider>
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Droppable droppableId={`${data?.id}-list`}>
                {(providedd) => (
                  <CardContainer
                    ref={providedd.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="ancho" />
                    {data?.tasks?.length !== 0 ? (
                      data?.tasks?.map((task, i) => (
                        <ItemColumn
                          key={`${data?.id}-${task?.id}-${i}`}
                          iDx={`${data?.id}-${task?.id}-${i}`}
                          card={task}
                          index={i}
                          nameList={data?.quadro}
                        />
                      ))
                    ) : (
                      <span style={{ visibility: 'hidden' }}>Vacio</span>
                    )}
                    {providedd.placeholder}
                  </CardContainer>
                )}
              </Droppable>
            </Container>
          )
        }
      </Draggable>
      <Modal show={showModalAdd} onHide={handleCloseModalAdd} centered>
        <ModalAdd
          quadro={data}
          handleClose={handleCloseModalAdd}
          updateDataColumn={updateDataColumn}
        />
      </Modal>
    </>
  );
};

export default Boards;
