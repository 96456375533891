import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 26%;

  .p-relative {
    position: relative;
  }
  .s {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.0241176em;

    /* Subtitle */

    color: #707070;
  }
  .mb-0 {
    color: #707070;
    display: flex;
    align-items: center;
    letter-spacing: -0.0241176em;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .me-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #414142;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #8675fe;
  }
  .dot-2 {
    background-color: #feba6a !important;
  }
  .dot-3 {
    background-color: #ff718b !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }

  @media (max-width: 988px) {
    display: flex;
    height: 23%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    height: auto;
  }
`;

export const Box = styled.div`
  .logo {
    //padding-top: 0%;
    width: 17%;
  }
`;
