import { GrSearch } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";


// Types
import { IHomeProps } from "./types";

// Partials
import { useState } from "react";
import { IMRealStateProduct } from "~/models/RealEstateProduct";
import api from "~/services/api";
import { Box } from "../Box";

export function Home({ data }: IHomeProps) {
    const [repsBySearch, setRepsBySearch] = useState<IMRealStateProduct[]>([])
    const [loading, setLoading] = useState(false)
    const history = useHistory();

    function handleToPage() {
        try {
            history.push('/produtos-imobiliario/novo-projeto');
        } catch (error) {
            Swal.fire({
                text: 'Não foi possivel iniciar o processo de criação de projeto',
                icon: 'warning',
            });
        }
    }

    async function handlerSearchByREPS(repTerms: string | null = null) {
        try {
            setLoading(true)

            if (!repTerms || typeof repTerms !== 'string' || repTerms.trim() === '') {
                setRepsBySearch([])
                throw new Error('')
            }

            setRepsBySearch([])

            const repTermsParsed = repTerms.replace(/[^\w\s]/gi, '')

            const response = await api.get<IMRealStateProduct[]>(`builders/real-estate-products?search=${repTermsParsed}`)

            if (response.status !== 200) throw new Error('')

            if (response.data.length <= 0) {
                Swal.fire({
                    icon: 'warning',
                    text: `Nenhum projeto com o termo '${repTerms}' foi encontrado`
                })

                return
            }

            setLoading(false)
            setRepsBySearch(response.data)
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: 'warning',
                text: 'Não foi possivel buscar pelo projeto'
            })
        }
    }

    return (
        <>
            {
                // Search bar and button to create a new project
            }
            <div className="w-full h-auto flex md:flex-row max-sm:flex-col md:items-center md:justify-between max-sm:items-end max-sm:gap-[20px]">
                <div
                    className="flex-1 max-w-[43.875rem] max-sm:w-full max-sm:max-w-full flex flex-row items-center border-[0.15rem] h-[3.5rem]
                    pt-[1.093rem] pb-[1.093rem] pl-[2.284rem] pr-[2.284rem] gap-[1rem]
                    border-[#e4e4e4] rounded-[2.187rem] bg-white hover:bg-[#f4f4f4] group"
                >
                    <GrSearch color="#707070" />
                    <input
                        type="search"
                        name="search"
                        className="border-none w-full h-full bg-none outline-none placeholder:text-[#B9B8BC]"
                        placeholder="Procurar por nome, local ou data"
                        onKeyPress={(event) => event.charCode === 13 && handlerSearchByREPS(event.currentTarget?.value || '')}
                    />
                </div>
                <button
                    disabled={loading}
                    onClick={handleToPage}
                    type="button"
                    className="flex-1 md:max-w-[20.687rem] max-sm:w-[9.687rem] h-[3.5rem] flex items-center justify-center pl-[1.687rem] pr-[1.687rem]
                    pt-[1.156rem] pb-[1.156rem] md:rounded-[0.9375rem] max-sm:rounded-full font-bold text-white bg-[#39A887] disabled:bg-[#46bd99] disabled:cursor-not-allowed"
                >
                    {
                        loading && repsBySearch.length <= 0 && (
                            <span className="w-full h-auto self-center flex flex-row items-center justify-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </span>
                        )
                    }
                    {
                        !loading && 'Novo estudo'
                    }
                </button>
            </div>

            <div className="flex flex-row flex-wrap  max-sm:justify-center  overflow-y-auto w-full h-auto max-sm:gap-x-[2.5rem] md:gap-x-[1rem] max-2xl:gap-x-[2.5rem] gap-y-[2.5rem] pb-[6.5rem]">

                {
                    !loading && repsBySearch.length > 0 && (
                        <span onClick={() => setRepsBySearch([])} className="w-auto h-auto flex max-sm:items-center md:cursor-pointer" title="Limpar resultados da pesquisa">Limpar pesquisa</span>
                    )
                }
                {
                    !loading && repsBySearch.length > 0 &&
                    <span className="flex flex-row max-sm:justify-center
                        flex-wrap overflow-y-auto 
                        w-full h-[20.062rem]
                        max-sm:gap-x-[2.5rem] md:gap-x-[0.5rem] 
                        max-2xl:gap-x-[2.5rem] 
                        gap-y-[2.5rem] pb-[6.5rem]
                        border-gray-300
                        border-b-[1px]
                        border-solid
                        rounded-tl-[0.9375rem]
                        rounded-tr-[0.9375rem]                        
                    "
                    >

                        {repsBySearch.map((rep, index) => <span key={index}><Box.Project {...rep} /></span>)}
                    </span>
                }
                {
                    data.map((rep, index) => <span key={index}><Box.Project {...rep} /></span>)
                }
            </div>
        </>
    )
}