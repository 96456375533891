import React from 'react';
import { BsCheck2All } from 'react-icons/bs';
import { BsDownload } from 'react-icons/bs';
import { CompleteData } from './styles';

interface IStepMenuDataOs {
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const LaudoAprovado: React.FC<IStepMenuDataOs> = ({ setContentData }) => (
  <CompleteData>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <BsCheck2All size={40} color="#39A887" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>Vistoria do engenheiro aprovada!</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-7">
            <p>Vamos continuar o seu processo para assinatura do contrato.</p>
          </div>

          <div className="col-3 box-button-fill">
            <button
              type="button"
              className="button-fill mb-1 col"
              onClick={() => setContentData('WorkDocuments')}
            >
              <div className="text-extra d-flex align-items-center justify-content-center">
                <BsDownload className="px-2 h1" />
                Visualizar laudo
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </CompleteData>
);

export default LaudoAprovado;
