import React, { useCallback } from 'react';

import { useProject } from '~/pages/Projects/Hooks/useProject';
import formatCurrency from '~/utils/formatCurrency';
import { Container } from './styles';

const FinalPrices: React.FC = () => {
  const { totalNetAmount, totalGrossAmount } = useProject();

  const suggestedPrice = useCallback((value: number) => value * 1.4, []);

  return (
    <Container className="px-1">
      <div className="d-lg-flex flex-row justify-content-around fondo2">
        <div className="py-3">
          <div className="px-3">
            <div className="row amount-title text-center">
              Valor da parceria <br /> a pagar
            </div>
            <div className="row net-amount mt-2 centrar">
              {formatCurrency(totalNetAmount)}
            </div>
            <div className="row row-3 centrar">até 10x</div>
          </div>
        </div>
        <div className="py-3">
          <div className="px-3 ">
            <div className="row amount-title text-center mb-3">
              Valor pelo 123 <br /> projetei
            </div>
            <div className="row amount mt-3 centrar">
              {formatCurrency(totalGrossAmount)}
            </div>
          </div>
        </div>
        <div className="py-3">
          <div className="px-3">
            <div className="row amount-title text-center">
              Valor sugerido para <br />
              você vender
            </div>
            <div className="row amount mt-3 centrar">
              {formatCurrency(suggestedPrice(totalNetAmount))}
            </div>
          </div>
        </div>
        <div className="py-3">
          <div className="px-3">
            <div className="row amount-title mb-3">
              Seu Lucro <br /> &nbsp;
            </div>
            <div className="row amount mt-3 centrar">
              {formatCurrency(suggestedPrice(totalNetAmount) - totalNetAmount)}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FinalPrices;
