import { Main } from "./Main";
import { Navigation } from "./Navigation";

// Local Types
import { Footer } from "./Footer";
import { IFooterProps } from "./Footer/types";
import { IMainProps } from "./Main/types";
import { INavigationProps } from "./Navigation/types";

export const Partials  = {
    Navigation: (args: INavigationProps ) => <Navigation {...args} />,
    Main: (args: IMainProps) => <Main {...args} />,
    Footer: (args: IFooterProps) => <Footer {...args} />
}