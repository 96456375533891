// Icons
import noImage from '~/assets/icons/no-image.svg';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Models

// Interfaces
import { useEffect, useState } from 'react';
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';
import { IImageProps } from './types';

export function Image({
  callerID, callerKey, callerPosition, onDataStream, dataStep, callerName = 'houses'
}: IImageProps) {
  const { user } = useAuthContext();
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  function handleSetImageToDataStream(
    imageToStream: File | null
  ) {
    try {
      if (!imageToStream) throw new Error('');
      if (imagePreview) URL.revokeObjectURL(imagePreview)

      onDataStream!({
        [callerKey]: imageToStream
      }, callerID, callerPosition);

      setImagePreview(URL.createObjectURL(imageToStream))
    } catch (error) {
      // do anything
    }
  }

  useEffect(() => {
    return () => URL.revokeObjectURL(imagePreview || '')
  }, [])

  useEffect(() => {
    if (!dataStep) return

    // ORIGEM FILE FROM WEB BROWSER
    const pathBrowser = (dataStep?.data?.[callerKey] as unknown as File)?.webkitRelativePath

    // CARREGA A IMAGEM DO STATEMENT FILE OU DO MODEL FILE (Carregado em runtime através do back-end)
    if (typeof pathBrowser !== 'undefined' && (pathBrowser === '' || pathBrowser !== '')) {
      setImagePreview(URL.createObjectURL(dataStep.data[callerKey]))
    } else if (dataStep?.data?.photo_upload_id) {
      setImagePreview(`${process.env.REACT_APP_API_URL}/builders/real-estate-products${callerName ? `/${callerName}/` : '/'}${(dataStep.data as unknown as IMRealEstateProductsHouse).id}/photo`)
    }
  }, [dataStep])

  return (
    <div className="md:w-[23.687rem] md:h-[11.562rem] rounded-[0.937rem] overflow-hidden bg-gray-[#F5F5F6]
    border-dashed border-[0.1rem] border-[#CCC]
    flex flex-row flex-nowrap items-center justify-center gap-[2rem] relative
    "
    >

      {
        !imagePreview && <>
          <span>
            <img src={noImage} alt="Imagem generica para adicionar uma nova imagem" />
          </span>
          <span>
            <input
              type="file"
              name="image"
              id="image"
              className="hidden"
              onChange={(event) => handleSetImageToDataStream(event?.target?.files?.[0] || null)}
              accept="image/png, image/jpeg, image/webp"
            />
            <label htmlFor="image" className="text-[#414142] font-[500] text-[0.875rem] cursor-pointer">
              Suba seu arquivo aqui. ou <br />
              <span className="text-[#39A887] font-[700] text-[0.875rem] underline">
                Procurar imagem
              </span>
            </label>
          </span>
        </>
      }

      {
        // REPETE O BLOCK INPUT/LABEL
        imagePreview && (
          <span className='w-full h-full relative flex flex-col items-center justify-center'>
            <img src={imagePreview} alt="" className='!max-w-full !h-full object-cover' />

            <input
              type="file"
              name="image-preview"
              id="image-preview"
              className="hidden"
              onChange={(event) => handleSetImageToDataStream(event?.target?.files?.[0] || null)}
              accept="image/png, image/jpeg, image/webp"
            />

            <label htmlFor="image-preview"
              title='Procurar uma nova imagem'
              className='w-[4rem] h-[4rem] absolute bottom-[1rem] right-[1rem] flex flex-col items-center justify-center 
              gap-[0.5rem] rounded-md transition-all duration-[0.3s] hover:bg-gray-100 hover:bottom-[1.2rem] cursor-pointer'>
              <img src={noImage} alt="Imagem generica para adicionar uma nova imagem" className='w-auto !h-[2rem]' />
            </label>
          </span>
        )
      }
    </div>
  );
}
