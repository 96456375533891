import styled from 'styled-components';
import bgBoxGreenMobile from '~/assets/defaults/bg-box-green-mobile.svg';
import bgBoxGreen from '~/assets/defaults/bg-box-green.svg';
import detail from '~/assets/defaults/detail-ball.svg';

export const Container = styled.div`
  width: 100%;

  @media screen and (min-width: 992px) {
    width: calc(100% - 110px);
  }
`;

export const Header = styled.div`
  .avatar {
    width: 46px;
    height: 46px;
  }

  .name {
    color: #414142;
    font-weight: 600;
  }

  .job {
    color: #747475;
  }
`;

export const Content = styled.div`
  .banner {
    border-radius: 12px;
    background-color: #1bd487;

    img.trophy {
      width: 37px;
      height: 31px;
    }

    h2 {
      color: #414142;
    }

    h3 {
      color: #fff;
    }

    svg {
      color: #fff;
    }

    p {
      color: #fff;
    }

    .ball {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #fff;
    }

    .line {
      margin-top: -5px;

      img {
        width: 100%;
      }
    }
  }

  .box {
    border-radius: 12px;
    border: 0.486px solid #e4e4e4;

    .award {
      border-radius: 7px;
      background: #d9d9d9;
      width: 35px;
      height: 35px;
    }
  }

  .btn-table {
    color: #7a797d;
    font-weight: 500;
    transition-duration: 0.3s;
    border: none;
  }

  .btn-table.active {
    color: #079269;
    background: #e1f1ed;
  }

  .table-data {
    .header > div,
    .body > div > div {
      :nth-child(1) {
        width: 90px;
      }

      :nth-child(2) {
        width: calc(100% - 175px);
      }

      :nth-child(3) {
        width: 85px;
      }
    }

    .header > div {
      p {
        color: #414142;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .header > div {
      padding: 10px 5px;
    }

    .body {
      height: 528px;
      overflow: auto;

      > div {
        > div {
          padding: 10px 5px;
          transition-duration: 0.3s;

          :nth-child(1) {
            img {
              width: 40px;
              height: 40px;
            }

            p {
              color: #707070;
              font-size: 20px;
              font-weight: 600;
            }
          }

          :nth-child(2) {
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }

            p {
              color: #707070;
              font-size: 16px;
              font-weight: 600;
            }
          }

          :nth-child(3) {
            width: 100px;

            p {
              color: #202020;
              font-weight: 500;
              font-size: 16px;

              small {
                font-size: 12px;
              }
            }
          }
        }

        :hover {
          /* background: #f9f9f9; */
        }

        + div {
          border-top: 1px solid #e9e8e8;
        }
      }

      .my-position {
        border-radius: 11px;
        border: 1px solid #e2e5e2;
        background: #ebf6f3;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
        width: 100%;
        bottom: 0px;
        top: 0px;
        position: sticky;
      }
    }
  }

  .box-green {
    background-image: url(${bgBoxGreenMobile});
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 14px 24px 17px;

    p:nth-child(1) {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }

    p:nth-child(2) {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .history {
    .content {
      h4 {
        color: #414142;
      }

      > div {
        padding: 15px;

        :nth-child(even) {
          background: #f9f9f9;
        }

        p {
          color: #707070;
        }

        > div {
          > div {
            width: 100px;
            height: 30px;

            p {
              font-size: 14px;
              font-weight: 600;
            }
          }

          .approved {
            background: #cff9ec;

            p {
              color: #10966e;
            }
          }

          .waiting {
            background: #f0f0f0;

            p {
              color: #6b6c6c;
            }
          }

          .canceled {
            background: #feeaea;

            p {
              color: #e14640;
            }
          }
        }

        + div {
          border-top: 1px solid #e9e8e8;
        }
      }
    }
  }

  .points {
    > div {
      p:nth-child(1) {
        font-size: 18px;
        font-weight: 600;
      }

      p:nth-child(2) {
        font-size: 24px;
        font-weight: 700;
      }

      :nth-child(1) {
        border-radius: 8px;
        background: #c6f9e4;

        p:nth-child(1) {
          color: #536f63;
        }

        p:nth-child(2) {
          color: #33554b;
        }
      }

      :nth-child(2) {
        border-radius: 8px;
        background: #f3f3f3;

        p:nth-child(1) {
          color: #747475;
        }

        p:nth-child(2) {
          color: #27262e;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .banner {
      border-radius: 12px;
      background-image: url(${detail});
      background-color: #cbffe9;
      background-position: -100px center;
      background-size: 475px;
      background-repeat: no-repeat;

      h2,
      h3 {
        color: #414142;
      }

      p {
        color: #4d937e;
      }

      .ball {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #88bba6;
      }

      .line {
        margin-top: -8px;

        img {
          width: 100%;
        }
      }
    }

    .box {
      border-radius: 12px;
      border: 0.486px solid #e4e4e4;

      .award {
        border-radius: 7px;
        background: #d9d9d9;
        width: 35px;
        height: 35px;
      }
    }

    .btn-table {
      color: #7a797d;
      font-weight: 500;
      transition-duration: 0.3s;
      border: none;
    }

    .btn-table.active {
      color: #079269;
      background: #e1f1ed;
    }

    .table-data {
      .header > div,
      .body > div > div {
        :nth-child(1) {
          width: 125px;
        }

        :nth-child(2) {
          width: calc(100% - 225px);
        }

        :nth-child(3) {
          width: 100px;
        }
      }

      .header > div {
        p {
          color: #414142;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .header > div {
        padding: 10px 5px;
      }

      .body {
        height: 528px;
        overflow: auto;

        > div {
          > div {
            padding: 10px 5px;
            transition-duration: 0.3s;

            :nth-child(1) {
              img {
                width: 40px;
                height: 40px;
              }

              p {
                color: #707070;
                font-size: 20px;
                font-weight: 600;
              }
            }

            :nth-child(2) {
              img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }

              p {
                color: #707070;
                font-size: 20px;
                font-weight: 600;
              }
            }

            :nth-child(3) {
              width: 100px;

              p {
                color: #202020;
                font-weight: 500;
                font-size: 20px;

                small {
                  font-size: 12px;
                }
              }
            }
          }

          :hover {
            /* background: #f9f9f9; */
          }

          + div {
            border-top: 1px solid #e9e8e8;
          }
        }

        .my-position {
          border-radius: 11px;
          border: 1px solid #e2e5e2;
          background: #ebf6f3;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
          width: 100%;
          bottom: 0px;
          top: 0px;
          position: sticky;
        }
      }
    }

    .box-green {
      background-image: url(${bgBoxGreen});
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 10px;
      padding: 14px 24px 17px;

      p:nth-child(1) {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }

      p:nth-child(2) {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
    }

    .history {
      .content {
        h4 {
          color: #414142;
        }

        > div {
          padding: 15px;

          :nth-child(even) {
            background: #f9f9f9;
          }

          p {
            color: #707070;
          }

          > div {
            > div {
              width: 100px;
              height: 30px;

              p {
                font-size: 14px;
                font-weight: 600;
              }
            }

            .approved {
              background: #cff9ec;

              p {
                color: #10966e;
              }
            }

            .waiting {
              background: #f0f0f0;

              p {
                color: #6b6c6c;
              }
            }

            .canceled {
              background: #feeaea;

              p {
                color: #e14640;
              }
            }
          }

          + div {
            border-top: 1px solid #e9e8e8;
          }
        }
      }
    }

    .points {
      > div {
        p:nth-child(1) {
          font-size: 18px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 24px;
          font-weight: 700;
        }

        :nth-child(1) {
          border-radius: 8px;
          background: #c6f9e4;

          p:nth-child(1) {
            color: #536f63;
          }

          p:nth-child(2) {
            color: #33554b;
          }
        }

        :nth-child(2) {
          border-radius: 8px;
          background: #f3f3f3;

          p:nth-child(1) {
            color: #747475;
          }

          p:nth-child(2) {
            color: #27262e;
          }
        }
      }
    }
  }
`;
