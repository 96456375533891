import {
  Dispatch, SetStateAction, useCallback, useState
} from 'react';
import api from '~/services/api';
import { IMBoard } from '~/models/Board';
import { Container } from './styles';

interface ITileColumnProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  completeBoard: IMBoard;
  index: number;
}

const TitleColumn: React.FC<ITileColumnProps> = ({
  value,
  setValue,
  completeBoard,
  index,
}) => {
  const [open, setOpen] = useState(false);
  const [newTitle, setNewTitle] = useState<string>(value);

  const handlePostBoardName = useCallback(
    async (e) => {
      try {
        setOpen(false);
        const dataFormProv = {
          quadro: e.target.value,
          ordem: index,
        };
        await api.put(
          `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${completeBoard?.id}`,
          dataFormProv
        );
        setValue(e.target.value);
      } catch (err) {
        //
      }
    },
    [completeBoard?.id, index, setValue]
  );

  return (
    <Container>
      {open ? (
        <input
          onKeyDown={(e) => {
            e.key === 'Enter' && handlePostBoardName(e);
          }}
          type="text"
          defaultValue={newTitle}
          onBlur={() => setOpen(false)}
          placeholder={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
            setValue(e.target.value);
          }}
        />
      ) : (
        <button
          type="button"
          className="bg-transparent border-0"
          onClick={() => (completeBoard?.id ? setOpen(true) : undefined)}
        >
          <h2>{newTitle}</h2>
        </button>
      )}
    </Container>
  );
};

export default TitleColumn;
