/**
 * ----------------------------------------------------------------------------------------------------------------
 * THIS CUSTOM HOOK WILL BE RESPONSIBLE BY CONTROL THE FLOW OF CREATE/UPDATE OF STEPS FROM BYD (BUILD YOUR HOUSE)
 * ----------------------------------------------------------------------------------------------------------------
 * THE MAIN DATA SHARED ACROSS THIS HOOK AND THE OTHERS COMPONENTS WILL BE THE STATELES OF FLOW
 * 
 * - PROGRESS
 * - ERROR
 * - PENDING
 * - SUBMIT 
 * - FINISHED
 * 
 * AND ALL ACTIONS FOR EXEC THE FLOW
 * 
 * - setInterior
 * - setExterior
 */

import { useState } from "react"

// OTHER HOOKS
import { useHistory, useParams } from "react-router-dom"
import { useOrderServiceBuilder } from "../OrderServiceBuilder"
import { useServiceOrder } from "../ServiceOrder"

// Utils
import Toast from "~/utils/toast"

// Models
import { IMBudgetParameter } from "~/models/BudgetParameter"
import { IMBudgetRoom } from "~/models/BudgetRoom"

// Types
import { IStepPayload } from "~/pages/BuildYourHouse/Partials/Steps/Interior/types"
import { IBuildYourHouseProps, IOption, IParams } from "./types"

// Services
import api from "~/services/api"

export function useBuildYourHouse({
        exteriorEnvironments,
        urlEnvinromentCallback = '',
        urlFinishCallback = null,
        urlParametersCallback = '',
        urlRequestCallback = null,
        onFinishedCallback = () => ({}),
        hasOS = false
    }: IBuildYourHouseProps) {
    
    // LOCAL STATE
    const [onSubmiting, setOnSubmiting] = useState<boolean>(false)
    const [onFinished, setOnFinished] = useState<boolean>(false)
    const [onError, setOnError] = useState<boolean>(false)
    const [onPending, setOnPeding] = useState<boolean>(false)
        
    // OTHER HOOKS
    const history = useHistory();
    const { orderServiceBuilder } = useOrderServiceBuilder();
    const { serviceOrder, setServiceOrder } = useServiceOrder();
    const routeParams = useParams<IParams>();

    async function setInterior(interiorParam: IStepPayload | null | undefined = null){
        try {
            setOnSubmiting(true)
            setOnError(false)

            const budgetRoom = await api.post<IMBudgetRoom[]>(urlEnvinromentCallback,interiorParam);
            
            if(hasOS) {
                setServiceOrder({                    
                    orc_ambientes: budgetRoom.data,
                });
            }

            if (orderServiceBuilder?.builder_id && urlRequestCallback){
                const formData = {
                    builder_id: orderServiceBuilder?.builder_id,
                    os_id: routeParams.osId,
                    status_id: 1,
                    answered: 0,
                };
    
                await api.post(urlRequestCallback, formData)
            }
            

            Toast.fire({
                icon: 'success',
                title: 'Ambientes salvos!',
            });

            sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_INTERIOR')
            sessionStorage.removeItem('MCF_BUY@ALREADY_INTERIOR_ENVIRONMENTS')
            localStorage.removeItem('@HarveyClient:OSBuilder');

            setOnSubmiting(false)
            
            if(urlFinishCallback) history.push(urlFinishCallback);
        } catch (error: any) {
            console.table(error)
            setOnSubmiting(false)
            setOnError(true)

            Toast.fire({
                icon: 'error',
                title: error?.message || 'Houve um erro',
            });
        }
    }

    async function setExterior(exteriorParam: IOption[] | null | undefined = null) {
        try {
            setOnSubmiting(true)
            setOnError(false)

            console.table(exteriorParam)

            if(!exteriorParam || exteriorParam.length !== 6) throw new Error('É necessario definir todos os parametros');

            const OPTIONS_SELECTED_ORDERED = exteriorParam;
            const MARGEM = orderServiceBuilder?.bdi ? Number(orderServiceBuilder?.bdi): 0;

            /* -------------------------------------------------------------------------------------------------
            * CAN BE THAT THE ARRAY CALLSIGN optionsSelected ARE UNORDERED
            * The code below will ordered the by your step_id by ASC
            */
            for(let i=0; i < OPTIONS_SELECTED_ORDERED.length; i++) {
                
                if(i + 1 > OPTIONS_SELECTED_ORDERED.length) break;

                for(let j=i+1; j <= OPTIONS_SELECTED_ORDERED.length - 1; j++ ){

                    if(OPTIONS_SELECTED_ORDERED[i].step_id > OPTIONS_SELECTED_ORDERED[j].step_id ){
                        const k = OPTIONS_SELECTED_ORDERED[i]
                        const w = OPTIONS_SELECTED_ORDERED[j]

                        OPTIONS_SELECTED_ORDERED[j] = k
                        OPTIONS_SELECTED_ORDERED[i] = w
                    }
                }
            }

            // console.table(OPTIONS_SELECTED_ORDERED)
            console.table(exteriorParam)
            
            const formData = {
                tp_acabamento_id: OPTIONS_SELECTED_ORDERED[0].id,
                tp_terreno_id: OPTIONS_SELECTED_ORDERED[1].id,
                estilo_construcao_id: OPTIONS_SELECTED_ORDERED[2].id,
                tp_eletrica_id: OPTIONS_SELECTED_ORDERED[3].id,
                tp_hidraulica_id: OPTIONS_SELECTED_ORDERED[4].id,
                pe_direito: OPTIONS_SELECTED_ORDERED[5].id,
                margem: MARGEM
            };

            const parameters = await api.post<IMBudgetParameter>(urlParametersCallback,formData);

            if(parameters.status === 200) {
                setServiceOrder({
                    orc_parametros: parameters.data
                });
            }

            if(hasOS === true) {
                if (exteriorEnvironments && exteriorEnvironments.length > 0) {
                    const budgetRoom = await api.post<IMBudgetRoom[]>(urlEnvinromentCallback, { ambientes: exteriorEnvironments });    

                    setServiceOrder({
                        orc_ambientes: budgetRoom.data
                    });
                }
            }

            Toast.fire({
                icon: 'success',
                title: 'Parametros definidos com sucesso',
            });

            sessionStorage.removeItem('MCF_BUY@STEPS_SELECTED_EXTERIOR')

            setOnSubmiting(false)

            if(urlFinishCallback) history.push(urlFinishCallback);
            if(onFinishedCallback && typeof onFinishedCallback === 'function') onFinishedCallback()
        } catch (error: any) {
            setOnSubmiting(false)
            setOnError(true)

            Toast.fire({
                icon: 'error',
                title: error?.message || 'Houve um erro',
            });
        }
    }

    return {
        status: {
            onSubmiting,
            onFinished,
            onError,
            onPending
        },
        actions: {
            setInterior,
            setExterior
        }
    }
}