import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { useClient } from '~/hooks/Client';

import Input from '~/components/Input';

import Pdf from '~/documents/TERMOMCFC50K.pdf';

import logoHarvey from '~/assets/logos/logo-harvey.svg';

import { useAuthContext } from '~/hooks/contexts/Auth';
import { AuthState } from '~/hooks/contexts/Auth/types';
import getValidationErros from '~/utils/getValidationsErrors';
import {
  Background, Container, Content, Step
} from './styles';

interface IFormData {
  name: string;
  email: string;
  password: string;
  route: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const { client } = useClient();
  const { setAuthTokens } = useAuthContext();
  const formRef = useRef<FormHandles>(null);
  const [, setNameError] = useState(false);
  const [, setStep] = useState(1);
  const [, setStepTitle] = useState('Crie sua conta gratuita');
  const [, setStepMessage] = useState(
    'Para começar esta jornada, insira seus dados, por favor'
  );
  const [errorPassword, setErrorPassword] = useState('');

  const handleChangeName = useCallback((e) => {
    if (formRef.current) {
      formRef.current.setErrors({});
    }
    const nameParts = e.target.value.trim().split(' ');
    if (nameParts.length === 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  }, []);

  /*   const handleClickChangeStep = useCallback(
    (newStep: number) => {
      if (!nameError) {
        switch (newStep) {
          case 2:
            setStepTitle('Queremos te conhecer!');
            setStepMessage(
              'Para sabermos melhor o seu perfil, insira os campos'
            );
            break;
          case 3:
            setStepTitle('Localização');
            setStepMessage('Em qual estado reside?');
            break;

          default:
            setStepTitle('Crie sua conta gratuita');
            setStepMessage(
              'Para começar esta jornada, insira seus dados, por favor'
            );
            break;
        }
        setStep(newStep);
      } else if (formRef.current) {
        formRef.current.setErrors({
          name: 'Preencha seu nome completo',
        });
      }
    },
    [nameError]
  ); */

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(isChecked);
  }, [isChecked]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setErrorPassword('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome completo obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string()
            .min(8, 'senha é muito curta')
            .max(30, 'senha é muito longa')
            .required('Informe uma nova senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!!data.password.match(/^(?=.*[a-z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra minúscula');
        } else if (!!data.password.match(/^(?=.*[A-Z])/) !== true) {
          setErrorPassword('Necessário ao menos uma letra maiúscula');
        } else if (!!data.password.match(/^(?=.*[0-9])/) !== true) {
          setErrorPassword('Necessário ao menos um número');
        } else if (!!data.password.match(/^(?=.*[!@#%&])/) !== true) {
          setErrorPassword('Necessário ao menos uma caractere especial');
        } else {
          const formData = {
            name: data.name,
            email: data.email,
            password: data.password,
            route: `${process.env.PUBLIC_URL}/perfil/dados-pessoais`,
          };          

          const AUTH_DATA: AuthState | null = await (await fetch(`${process.env.REACT_APP_API_URL}/builders`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            }
          })).json()          

          Swal.fire(
            {
              title: 'Cadastro realizado',
              text: 'Agora você precisa ser aprovado como construtor.',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#39A887'
            }
          ).then(({isConfirmed}) => {
            if(isConfirmed) {
              history.push(`${process.env.PUBLIC_URL}/login`);
            }
          })
          .catch(() =>  history.push(`${process.env.PUBLIC_URL}/login`));

          // TODO - DEVIDO A UMA RN (Regra de Negócio) o fluxo de  auto login foi temporiamente depreciado
          // setAuthTokens(AUTH_DATA);

        }
      } catch (error) {
        // console.table(error)
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (
            Object.keys(errors).find(
              (key) => key === 'name' || key === 'email' || key === 'password'
            )
          ) {
            setStep(1);
            setStepTitle('Bem-vindo!');
            setStepMessage('Para iniciar, complete os campos desejados');
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [history, setAuthTokens]
  );

  return (
    <>
      <Container className="container-fluid">
        <div className="row">
          <Background className="col-lg-6 col-xl-6 col-xxl-7 d-none d-lg-flex flex-column justify-content-center align-items-center px-5 pt-4">
            <div>
              <img
                src={logoHarvey}
                alt="logo-harvey"
                className="mb-5 d-block mx-sm-auto"
              />
              <h2 className="fw-light text-center company">
                <b className="fw-medium">Harvey</b> Construtor
              </h2>
              <hr className="line" />
              <p className="text-center">
                Aqui você encontrará todos os modelos de contratos e
                <br />
                Documentações Exclusivas que serão utilizados no
                <br />
                processos de financiamento habitacional.
              </p>
            </div>
          </Background>
          <Content>
            <img src={logoHarvey} alt="logo-harvey" className="logo" />
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="px-sm-4 px-md-5 px-lg-0"
            >
              <div className="mb-5 px-5">
                <h1 className="h2 fw-semibold mb-0 text-dark-gray">
                  Crie sua conta
                </h1>
                <p className="text-gray">
                  Para começar esta jornada, insira seus dados, por favor
                </p>
              </div>
              <Step active>
                <div className="mb-4 px-5">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    Nome e sobrenome
                  </label>
                  <Input
                    placeholder="Nome Completo"
                    name="name"
                    onChange={handleChangeName}
                    className="input"
                  />
                </div>
                <div className="mb-4 px-5">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    E-mail
                  </label>
                  <Input
                    type="email"
                    placeholder="email@example.com"
                    name="email"
                    className="input"
                    value={client?.email}
                  />
                </div>
                <div className="mb-4 px-5">
                  <label htmlFor="email" className="mb-2 fw-medium">
                    Senha
                  </label>
                  <Input
                    type="password"
                    placeholder="******"
                    name="password"
                    className="input"
                  />
                  {errorPassword && (
                    <span className="small text-danger error">
                      {errorPassword}
                    </span>
                  )}
                </div>
                <div className="px-5">
                  <div>
                    <div>
                      <div>
                        <input
                          type="checkbox"
                          id="topping"
                          name="topping"
                          value="Paneer"
                          checked={isChecked}
                          onChange={handleOnChange}
                        />
                        <label htmlFor="email" className="mb-4 px-1 fw-medium">
                          Eu li e aceito os
                        </label>
                        <a
                          href={Pdf}
                          target="_blank"
                          className="mb-5 fw-medium text-decoration-underline text-gray fw-normal fs-6"
                          rel="noreferrer"
                        >
                          termos de uso.
                        </a>
                      </div>
                      <div className="mandatory text-alert">
                        <span>
                          {isChecked === true
                            ? ''
                            : 'O termos de uso é obrigatório'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Step>
              <div className="px-5">
                <button
                  type="submit"
                  className="btn btn-primary mb-4 w-100 fw-semibold"
                  disabled={!isChecked}
                >
                  Continuar
                </button>

                <p className="text-gray text-center">
                  Já possui conta?{' '}
                  <Link
                    to={`${process.env.PUBLIC_URL}/perfil/dados-pessoais`}
                    className="text-green fw-medium"
                  >
                    Entrar
                  </Link>
                </p>
              </div>
            </Form>
          </Content>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
