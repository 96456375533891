import styled from 'styled-components';
import ReactChatView from 'react-chatview';

export const Container = styled.div``;

export const ChatArea = styled.div`
  h2 {
    color: #2a2c30;
    font-size: 18px;
    font-weight: 600;
  }

  button {
    background-color: #39a887;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
  }

  textarea {
    background-color: transparent;
    resize: none;
    color: #3d3f41;
  }

  .border-user {
    border-bottom: 1px solid #eaeaea;
  }

  .border-message {
    border-top: 1px solid #eaeaea;
  }

  .circle-online {
    background-color: #1ee170;
    height: 9px;
    width: 9px;
  }

  .os-user {
    color: #b8b9ba;
    font-weight: 500;
  }

  .icon svg {
    margin: 0 auto !important;
  }

  .change-msg {
    height: 80vh;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }

  .textarea {
    padding: 5px 0px;

    textarea {
      ::placeholder {
        line-height: 47px;
      }

      :focus {
        ::placeholder {
          opacity: 0;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    border: 1px solid #eaeaea;
    border-radius: 30px;

    h2 {
      font-size: 24px;
    }
  }
`;

export const ChatText = styled(ReactChatView)`
  height: calc(80vh - 230px);
  overflow-y: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .bg-dark-gray {
    color: #fff;
    font-weight: 500;
    border-radius: 10px 10px 0 10px;
    background-color: #707070;
  }

  .bg-light-gray {
    color: #3d3f41;
    font-weight: 500;
    border-radius: 10px 10px 10px 0;
    background-color: #ededed;
  }

  .date-message {
    font-weight: 500;
    color: #b0b6c3;
    font-size: 10px;
  }

  .tail-dark-gray {
    position: relative;
    top: 8px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: 28px;
  }

  .tail-light-gray {
    position: relative;
    top: 9px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: -30px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }

  .my-message,
  .your-message {
    .message-box {
      position: relative;
    }
  }

  .my-message .message-box:after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #707070 transparent;
  }

  .your-message .message-box:before {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    left: -7px;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
  }

  @media screen and (min-width: 992px) {
    height: calc(80vh - 280px);
  }
`;
