export function Mock() {
    return (
        <div className="w-full md:min-h-[43.75rem] max-sm:min-h-[10rem] h-auto flex flex-row flex-nowrap">
            <div className="flex flex-col md:min-w-[19rem] md:max-w-[19rem] max-sm:flex-1 max-sm:min-w-[10rem] h-auto pt-[5.437rem]">
                <span className="w-full h-[2.187rem] rounded-l-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                <span className="w-full h-[2.187rem] rounded-l-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                <span className="w-full h-[2.187rem] rounded-l-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                <span className="w-full h-[2.187rem] rounded-l-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                <span className="w-full h-[2.187rem] rounded-l-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
            </div>
            <div className="w-full flex flex-col overflow-hidden rounded-tr-[0.937rem] rounded-tl-[0.937rem]">
                <div className="w-full max-w-[100%] h-auto flex flex-row flex-nowrap overflow-x-auto">
                    <div className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] peer/lastItem">
                        <div className="flex flex-col 
                                        gap-[1rem]
                                        rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                        overflow-hidden object-cover
                                        w-[12.25rem] h-[5.437rem]
                                        bg-gray-300 animate-pulse
                                    "
                        >
                            <img src="" alt="" className="!max-w-full h-auto" />
                            <span></span>
                        </div>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                    </div>

                    <div className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] peer/lastItem">
                        <div className="flex flex-col 
                                        gap-[1rem]
                                        rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                        overflow-hidden object-cover
                                        w-[12.25rem] h-[5.437rem]
                                        bg-gray-300 animate-pulse
                                    "
                        >
                            <img src="" alt="" className="!max-w-full h-auto" />
                            <span></span>
                        </div>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                    </div>

                    <div className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] peer/lastItem">
                        <div className="flex flex-col 
                                        gap-[1rem]
                                        rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                        overflow-hidden object-cover
                                        w-[12.25rem] h-[5.437rem]
                                        bg-gray-300 animate-pulse
                                    "
                        >
                            <img src="" alt="" className="!max-w-full h-auto" />
                            <span></span>
                        </div>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                    </div>

                    <div className="flex flex-col min-w-[19rem] max-w-[19rem] rounded-tl-[0.937rem] peer/lastItem">
                        <div className="flex flex-col 
                                        gap-[1rem]
                                        rounded-tr-[0.937rem] rounded-tl-[0.937rem]
                                        overflow-hidden object-cover
                                        w-[12.25rem] h-[5.437rem]
                                        bg-gray-300 animate-pulse
                                    "
                        >
                            <img src="" alt="" className="!max-w-full h-auto" />
                            <span></span>
                        </div>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-200 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                        <span className="w-full h-[2.187rem] peer-last-of-type/lastItem:rounded-r-[5.437rem] bg-gray-100 animate-pulse flex flex-row flex-nowrap items-center justify-center"></span>
                    </div>

                </div>
            </div>
        </div>
    )
}