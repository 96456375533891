import React, { useCallback, useEffect, useState } from 'react';

import { useStep } from '~/pages/Simulation/Hooks/useStep';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import InputMask from '~/components/InputMask';
import { formatToFloat } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';

import NextButton from '../NextButton';
import PreviousButton from '../PreviousButton';
import { Container } from './styles';

const InputPropertyValue: React.FC = () => {
  const { step, setStep } = useStep();
  const [errorData, setErrorData] = useState('');
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [property_price, setPropertyPrice] = useState('');

  const handleNextStep = useCallback(() => {
    const propertyPriceNumber = parseFloat(
      property_price.replace('R$', '').replaceAll('.', '').replace(',', '.')
    );
    if (propertyPriceNumber === 0) {
      setErrorData('O valor do imovel é obrigatório');
    } else {
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...serviceOrder.simulacao,
          vlrImovel: formatToFloat(property_price),
        },
      });
      setStep(5);
    }
  }, [property_price, setServiceOrder, serviceOrder, setStep]);

  const handleClickPrevious = useCallback(
    (target_step) => {
      setStep(target_step);
    },
    [setStep]
  );

  useEffect(() => {
    setPropertyPrice(formatCurrency(serviceOrder.simulacao?.vlrImovel || 0));
  }, [serviceOrder.simulacao?.vlrImovel]);

  return (
    <Container>
      <p>Qual o valor do imóvel?</p>
      <label htmlFor="register" className="mb-2 fw-medium">
        Valor:
      </label>
      <InputMask
        kind="money"
        name="property_price"
        placeholder="R$ 0,00"
        value={property_price}
        className="input mt-2 w-50"
        onChange={(e) => setPropertyPrice(e.target.value)}
      />
      {errorData && (
        <span className="small text-danger error">{errorData}</span>
      )}
      <div className="row d-flex justify-content-end">
        <div className="col-1 d-flex justify-content-end">
          <PreviousButton
            onClick={() => handleClickPrevious(step - 1)}
            target_step={step - 1}
          />
        </div>
        <div className="col-auto d-flex justify-content-start">
          <NextButton onClick={handleNextStep} />
        </div>
      </div>
    </Container>
  );
};

export default InputPropertyValue;
