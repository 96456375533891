import React, { useCallback, useEffect, useState } from 'react';

import AddFinancing from '~/assets/icons/add-financing.svg';

import { IMParticipant } from '~/models/Participant';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import ModalFormAddParticipant from '../ServiceOrderData/Components/ModalFormAddParticipant';
import ParticipantsName from '../../Components/ParticipantsName';

import EditParticipant from './Components/EditParticipant';
import { MembersNameBox, Container } from './styles';

const DataProponent: React.FC = () => {
  const { participants } = useServiceOrder();
  const [participantSelected, setParticipantSelected] = useState<
    IMParticipant | undefined
  >({} as IMParticipant | undefined);
  const [colorBackground, setColorBackground] = useState('#8675FE');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setParticipantSelected(participants[0]);
  }, [participants]);

  const handleSetSelected = useCallback(
    (participantData: IMParticipant, color) => {
      setColorBackground(color);
      setParticipantSelected(participantData);
    },
    []
  );

  return (
    <Container>
      <div className="row container-box">
        <MembersNameBox className="col-xl-8">
          {participants && (
            <>
              {participants.map((participant, index) => (
                <ParticipantsName
                  key={(participant.type || '') + (participant.id || 0)}
                  participant={participant}
                  number={String(index + 1)}
                  onSelect={handleSetSelected}
                  borderSelected={
                    !!(
                      participantSelected
                      && participantSelected.type === participant.type
                      && participantSelected.id === participant.id
                    )
                  }
                />
              ))}
            </>
          )}
        </MembersNameBox>
        <div className="col-xl-4 d-flex container-financing align-items-center justify-content-center">
          <button
            type="button"
            className="financing-box border-0 bg-transparent"
            onClick={() => setShowModal(true)}
          >
            <span className="d-flex align-items-center text-decoration-underline">
              <div className="circle-icon">
                <img src={AddFinancing} alt="" className="p-2" />
              </div>
              <p className="px-2 mb-0"> Dividir o financiamento</p>
            </span>
          </button>
        </div>
      </div>
      {participants && (
        <EditParticipant
          participant={participantSelected}
          color={colorBackground}
        />
      )}
      <ModalFormAddParticipant
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </Container>
  );
};

export default DataProponent;
