import React, { useCallback, useState } from 'react';

interface IProps {
  onChange: (value: number | null) => void;
}

const ButtonsSearch: React.FC<IProps> = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const handleChangeTab = useCallback(
    (tab: number | null) => {
      onChange(tab);
      setActiveTab(tab);
    },
    [onChange]
  );

  return (
    <div className="flex flex-row flex-wrap items-center justify-between gap-1 ">
      <p className="h3 d-flex justify-content-start sm:px-3 px-lg-0">
        Minhas OS
      </p>
      <div className="flex flex-row flex-wrap items-center justify-center gap-2 ">
        <button
          type="button"
          className={`border-0 btn-build px-2 me-3 ${
            activeTab === 1 ? 'active' : ''
          }`}
          onClick={() => handleChangeTab(activeTab === 1 ? null : 1)}
        >
          Pendentes Construtor
        </button>
        <button
          type="button"
          className={`border-0 btn-build px-2 ${
            activeTab === 2 ? 'active' : ''
          }`}
          onClick={() => handleChangeTab(activeTab === 2 ? null : 2)}
        >
          Pendentes Correspondente
        </button>
      </div>
    </div>
  );
};

export default ButtonsSearch;
