/* FIXEM - eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import casa from '~/assets/icons/casa.svg';
import { IMProject } from '~/models/Project';
import {
  Button, Container,
  Label,
  List
} from './styles';

import { useProject } from '../../Hooks/useProject';
import Details from './Details';

const ProjectsList: React.FC = () => {
  const { projects, setSelectedProjects } = useProject();
  const [show, setShow] = useState<number[]>([]);

  const handleShow = (index: number) => {
    if (show.includes(index)) {
      const temp = [...show];
      const pos = show.indexOf(index);
      temp.splice(pos, 1);
      setShow(temp);
    } else setShow([index, ...show]);
  };

  return (
    <Container className="me-2 mt-3">
      <div className="h5-lg text-dark">Selecione seus projetos:</div>
      <List className="col-auto">
        {Array.from(projects).map((project: IMProject, index: number) => (
          <div className="fondo m-3">
            <div className="col-auto p-3">
              <div className="row d-flex align-items-center my-3 item">
                <Label>
                  <input
                    key={index}
                    type="checkbox"
                    className="mx-3 checkbox"
                    onChange={(e) => setSelectedProjects(project, e)}
                  />
                  <span className="checkmark" />
                </Label>
                <div className="col-auto ms-1">
                  <img
                    src={casa}
                    alt="casa"
                    color="#707070"
                    id="Iconnaranja"
                  />
                </div>
                <div className="col-auto project-title">
                  {project.project}
                </div>
                <Button
                  onClick={() => handleShow(index)}
                  show={show}
                  index={index}
                  className="col-auto"
                >
                  <RiArrowDownSLine size={25} color="#707070" />
                </Button>
                <div className="px-4" />
              </div>

              <Details show={show} index={index} />
            </div>
          </div>
        ))}
      </List>
    </Container>
  );
};

export default ProjectsList;
