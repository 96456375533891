import React from 'react';

import { Container } from './styles';

import ImgProjects from './ImgProjects';
import InputCalculate from './InputCalculate';
import InnerContainer from './InnerContainer';

const ContainerDer: React.FC = () => (
  <Container className="row ms-2 mt-5">
    <div className="px-3 py-4">
      <div className="col">
        <ImgProjects />
        <InputCalculate />
        <InnerContainer />
      </div>
    </div>
  </Container>
);

export default ContainerDer;
