import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 21%;
  .p-relative {
    position: relative;
  }

  .m-4 {
    height: 120px;
  }
  .s {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.0241176em;
  }
  .text-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* or 130% */

    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
  .mb-0 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* or 130% */

    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
  .me-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height */

    display: flex;
    align-items: center;
    color: #414142;
  }
  .dot {
    width: 14px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    width: 14px;
    background-color: #6dfca6 !important;
  }
  .dot-3 {
    width: 14px;
    background-color: #168fff !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }

  @media (max-width: 988px) {
    display: unset;
    height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    height: auto;
  }

  // chart

  #SvgjsSvg1191 {
    margin-top: 10px;
  }
`;
