import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Loading from '~/components/Loading';
import StepMenuDataOs from '~/components/StepMenuDataOs';
import UserDefault from '~/components/UserDefault';
import { useDefaultData } from '~/hooks/DefaultData';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import BuildYourHouse from './Pages/BuildYourHouse';
import DataProponent from './Pages/DataProponent';
import FilesManagement from './Pages/FilesManagement';
import Measurements from './Pages/Measurements';
import ServiceOrderData from './Pages/ServiceOrderData';
import SimulationBank from './Pages/SimulationBank';

import BarInformation from './Components/BarInformation';

import { Container } from './styles';

interface IParams {
  osId: string;
}

const ServiceOrderDashboard: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { getServiceOrderData, serviceOrder } = useServiceOrder();
  const { setDefaultData } = useDefaultData();
  const [loading, setLoading] = useState(true);
  const [contentData, setContentData] = useState<string>('ProponentDataOs');

  useEffect(() => {
    if (params.osId) {
      setDefaultData();
      getServiceOrderData(parseInt(params.osId, 10));
      setLoading(false);
    } else {
      history.push('/home');
    }
  }, [params.osId, getServiceOrderData, setDefaultData, history]);

  useEffect(() => {
    if (serviceOrder.id) {
      if (!serviceOrder?.finalidade_id) {
        history.push(`/finalidades/${params.osId}`);
        return;
      }
      if (!serviceOrder?.simulacao?.vlrFinanciado) {
        history.push(`/simulador/${params.osId}`);
      }
    }
  }, [serviceOrder]);

  return (
    <>
      <div className="container mt-5">
        <div className="py-2 py-lg-0" />
        <div className="mt-5 mt-lg-0 px-3">
          <UserDefault OrdemServico />
          <div className="px-5">
            Complete os dados de todos os proponentes compostos na renda e das
            demais informações para sua ordem de serviço
          </div>
        </div>
        <Container>
          <BarInformation />
          <hr className="mb-5" />

          {Object.keys(serviceOrder).length <= 0 ? (
            <div className="w-full flex flex-row  flex-nowrap items-center gap-4">
              <div className="flex-1 h-[2.3125rem] bg-gray-200 animate-pulse rounded-[0.3125rem]" />
              <div className="flex-1 h-[2.3125rem] bg-gray-200 animate-pulse rounded-[0.3125rem]" />
              <div className="flex-1 h-[2.3125rem] bg-gray-200 animate-pulse rounded-[0.3125rem]" />
              <div className="flex-1 h-[2.3125rem] bg-gray-200 animate-pulse rounded-[0.3125rem]" />
              <div className="flex-1 h-[2.3125rem] bg-gray-200 animate-pulse rounded-[0.3125rem]" />
            </div>
          ) : (
            <StepMenuDataOs
              contentData={contentData}
              setContentData={setContentData}
            />
          )}          

          {contentData === 'ProponentDataOs' && (
            <ServiceOrderData setContentData={setContentData} />
          )}
          {contentData === 'DataProponent' && <DataProponent />}
          {contentData === 'SimulationBank' && <SimulationBank />}
          {contentData === 'BuildYourHouse' && <BuildYourHouse />}
          {contentData === 'WorkDocuments' && <FilesManagement />}
          {contentData === 'Measurements' && <Measurements />}
        </Container>
        <Loading active={loading} />
      </div>
    </>
  );
};

export default ServiceOrderDashboard;
