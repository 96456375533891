/* FIXEM - eslint-disable prefer-object-spread */
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

// Services
import api from '~/services/api';

// Models
import { IMUser } from '~/models/User';

// Types
import {
  AuthCredentials,
  AuthState,
  IContextAuthDefaultData,
  IContextAuthProps,
} from './types';

// Middleware
import { authMiddlewareGetToken, authMiddlewareSignOut, authMiddlewareToken } from './middleware';
import { authGetCacheStorage } from './middleware/cache';

// Actions
import {
  authActionSignOut,
  authActionUpdateAuth,
  authActionUpdateAuthToken,
  authActionUpdateUser,
} from './actions';

// Reducer
import { reducerAuth } from './reducer';

// Context
import { getNavigationDataPage } from '~/utils/navigation';
import { deletetPathURIStorage, getPathURI, getPathURIStorage, setPathURIStorage } from '~/utils/parseURL';
import { AuthContext, AuthContextDispatch, AuthEventsContext } from './context';

export function AuthProvider({ children }: IContextAuthProps): JSX.Element {
  const [state, dispatch] = useReducer(reducerAuth, authGetCacheStorage());
  const [onRecovery, setOnRecovery] = useState(false)
  const [onSignIn, setOnSignIn] = useState(false)
  const [onSignOut, setOnSignOut] = useState(false)
  const [onHydrate, setOnHydrate] = useState(false)
  const history = useHistory();

  const signIn = useCallback(
    async ({ email, password }: AuthCredentials): Promise<AuthState | void> => {
      try {
        setOnSignIn(true)
        await api
          .post<AuthState>(`${process.env.REACT_APP_PREFIX_ROUTE}/auth/login`, {
            email,
            password,
          })
          .then((data) => {
            authMiddlewareToken(data.data.token);            
            dispatch(authActionUpdateAuth(data.data));
            setOnSignIn(false)
          })
          .catch((error: AxiosError) => {
            setOnSignIn(false)
            if (error.response?.data?.error === 'Unauthorized') {
              Swal.fire('Opss...', 'Dados de login inválidos!', 'error');
            } else {
              Swal.fire(
                'Opss...',
                'Assinatura vencida. Renove seu plano para ter acesso novamente ao sistema.',
                'error'
              );
            }
          });
      } catch (error) {
        setOnSignIn(false)
        throw error;
      }
    },
    []
  );

  const recovery = useCallback(async (): Promise<true | void> => {
    try {      
      setOnRecovery(true)

      const response = await api.get<IMUser>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/auth/me`
      );

      dispatch(authActionUpdateUser(response.data));

      const NAVIGATION_STATUS = getNavigationDataPage()

      const LAST_PATHURI = getPathURIStorage() || '/home'

      console.table(NAVIGATION_STATUS)      

      if (
        NAVIGATION_STATUS.navigation === NAVIGATION_STATUS.reload         
      ) {
        console.log("THE NAGIVATION WAS RELOAD","THEN REDIRECT TO LAST URI")
        if(LAST_PATHURI !== getPathURI(window.location.href)) {
          history.push(LAST_PATHURI);
        }
      }else if(
        NAVIGATION_STATUS.navigation === NAVIGATION_STATUS.backForward
        ||
        NAVIGATION_STATUS.navigation === NAVIGATION_STATUS.navigate) {
          setPathURIStorage(getPathURI(window.location.href))

          // TODO - CAN BE THAT THE LOGIN WILL BE STORAGE ON STACK OF HISTORY OF BROWSER SO THIS PAGE
          // WILL BE "NAVIGATE" OR "BACKFORWARD" AND TO GRANTED THAT THE NEXT WILL BE LOADED IS APPLY 
          // THE CONDITION BELOW
          if(!getPathURI(window.location.href)) {
            history.push(LAST_PATHURI);
          }
      } else if (
          (NAVIGATION_STATUS.navigation === NAVIGATION_STATUS.navigate || NAVIGATION_STATUS.navigation === NAVIGATION_STATUS.backForward)
          && 
          getPathURI(window.location.href)?.includes('/login')
        ) {
        history.push('/home');
      }else{
        history.push('/home');
      }

      setOnRecovery(false)
      return true;
    } catch (error: any) {      
      authMiddlewareSignOut();
      throw error;
    } finally {
      setOnRecovery(false);
    }
  }, [state]);  

  const autoSignIn = useCallback(
    async (hash: string) => {
      try {
        setOnHydrate(true)
        const response = await api.post<AuthState>(`builders/auth/auto-login`, {
          hash,
        });
        const { token } = response.data;

        dispatch(authActionUpdateAuthToken(token));
        setOnHydrate(false)
      } catch (error) {
        setOnHydrate(false)
        Swal.fire('Opss...', 'Dados de login inválidos!', 'error');
      }
    },
    [state]
  );

  const signOut = useCallback(async () => {
    deletetPathURIStorage()    
    setOnSignOut(true)
    dispatch(authActionSignOut());
    setOnSignOut(false)
    
    history.push('/login')      
  }, [state]);

  const updateUser = useCallback(
    (data: Partial<IMUser>) => {
      dispatch(authActionUpdateUser(data));
    },
    [state]
  );

  function setAuthTokens(data: AuthState | null = null) {
    try {
      if(!data) throw new Error('Essentials data do not provide');

      authMiddlewareToken(data.token);            
      dispatch(authActionUpdateAuth(data));
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    try {
      if (
        (!state.user && state.token) ||
        (!state?.user && !state.token && authMiddlewareGetToken(true))
      ) {
        recovery()
          .then((_resolver) => {
            // do anything
          })
          .catch((reject) => console.table(reject));
      }
    } catch (error) {
      // do anything
    }
  }, [state]);

  useEffect(() => {
    Sentry.setUser({
      email: state.user?.email,
      username: state.user?.name,
      id: state.user?.id,
    });
  }, [state.user]);

  return (
    <AuthContext.Provider value={state}>
      <AuthContextDispatch.Provider
        value={{ signIn, signOut, recovery, autoSignIn, updateUser, setAuthTokens }}
      >
        <AuthEventsContext.Provider value={{
          onRecovery,
          onSignIn,
          onSignOut,
          onHydrate
        }}>
          {children}
        </AuthEventsContext.Provider>
      </AuthContextDispatch.Provider>
    </AuthContext.Provider>
  );
}

export function useAuthContext(): IContextAuthDefaultData {
  return { ...useContext(AuthContext), ...useContext(AuthContextDispatch), ...useContext(AuthEventsContext)};
}
