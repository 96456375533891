import React, { useEffect, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';

import Card from './components/Card';
import Slider from './components/Slider';
import { Container } from './styles';

interface IDataBudgetE {
  quantity: IQuantityData[];
}

interface IQuantityData {
  cant: number;
  amb: string;
}

interface IProject {
  projectID: number;
  bathroomsNumber: number;
  suitesNumber: number;
  bedroomsNumber: number;
  garagesNumber: number;
  medias: IMedias[];
  link: string;
}

interface IMedias {
  source: string;
  title: string;
  description: string;
}

interface IResponseProject {
  results: IProject[];
}

const ProjeteiSuggestion: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [quantityAmbientes, setQuantityAmbientes] = useState<IQuantityData[]>(
    []
  );
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    if (serviceOrder?.id) {
      api
        .get<IDataBudgetE>(
          `/builders/service-orders/${serviceOrder?.id}/budget/environments/get-quantities`
        )
        .then((array) => {
          const response: IQuantityData[] = array.data.quantity.map(
            (element) => ({
              cant: element.cant,
              amb: element.amb,
            })
          );
          setQuantityAmbientes(response);
        });
    }
  }, [serviceOrder?.id]);

  useEffect(() => {
    if (quantityAmbientes[1]?.cant && quantityAmbientes[0]?.cant) {
      fetch(
        `https://buscador.123projetei.com/architecturalProjects?limit=10&skip=0&bathroomsNumber=${quantityAmbientes[1]?.cant}` +
          `&bedroomsNumber=${quantityAmbientes[0]?.cant}&garagesNumber=${
            quantityAmbientes[10]?.cant ?? 1
          }`
      )
        .then(async (response) => {
          const results: IResponseProject = await response.json();
          if (results?.results) {
            const projeteiData: IProject[] = results?.results;
            setProjects(projeteiData);
          }
        })
        .catch((_rejects) => ({}));
    }
  }, [quantityAmbientes]);

  return (
    <Container className=" card-dashboard col-md-12 col-12 p-4 p-lg-5 pb-5 mx-4 mx-lg-0">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="h6 mb-0">Projetos com base no seu resultado</h2>
      </div>
      <Slider>
        {projects.length > 0 &&
          projects.map((item) => (
            <Card
              bathroomsNumber={item.bathroomsNumber}
              bedroomsNumber={item.bedroomsNumber}
              garagesNumber={item.garagesNumber}
              medias={item.medias}
              projectID={item.projectID}
              suitesNumber={item.suitesNumber}
              key={item.projectID}
            />
          ))}
      </Slider>
      <div className="pb-lg-5" />
    </Container>
  );
};

export default ProjeteiSuggestion;
