import { Dialog } from 'primereact/dialog';
import { PropsWithChildren, useEffect, useState } from 'react';

// Assets
import firstPlace from '~/assets/icons/first-place.svg';
import othersPlace from '~/assets/icons/others-place.svg';
import secondPlace from '~/assets/icons/second-place.svg';
import thirdPlace from '~/assets/icons/third-place.svg';

// Models
import { IMAward } from '~/models/Award';
import ProfilePhoto from '~/utils/ProfilePhoto';

interface IBoxAwardsProps extends PropsWithChildren<any> {
  award: IMAward | null;
  onHide: () => void;
  show: boolean;
  tournament_id: number;
}

export function BoxAward({
  award = null,
  onHide,
  show,
  tournament_id,
}: IBoxAwardsProps) {
  const [awardImage, setAwardImage] = useState<string>('');

  // AUX Varaibles
  const ICONS_PLACES = [firstPlace, secondPlace, thirdPlace];

  async function handleGetAwardImage() {
    try {
      if (!award) return;

      const imageAward = await ProfilePhoto({
        imageName: award.title,
        url: `/admins/tournaments/${tournament_id}/awards/${award?.id || -1
          }/file`,
      });

      if (imageAward) setAwardImage(imageAward);
    } catch (error) {
      // do anything
    }
  }

  useEffect(() => {
    if (award && award.id > 0) handleGetAwardImage();
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps

    return setAwardImage('');
  }, [award]);

  return (
    <Dialog
      onHide={onHide}
      visible={show}
      header={`Premiação ${award?.title}`}
      className="w-1/2 sm-w-full"
    >
      <div className="flex w-full flex-row flex-nowrap items-center justify-center gap-4 mb-4">
        {award && (
          <img
            src={ICONS_PLACES[award.position - 1] ?? othersPlace}
            alt="Icone representando a posição da premiação"
            className="w-[6rem] h-auto"
          />
        )}
      </div>
      <div className="flex flex-col flex-nowrap overflow-hidden rounded-xl">
        {awardImage.trim() === '' ? (
          <div className="w-full h-[20rem] sm-h-[3rem] bg-gray-200 animate-pulse" />
        ) : (
          <img src={awardImage} alt="" />
        )}
      </div>
      <div className="flex flex-col flex-nowrap gap-3 mt-3">
        <p className="font-medium text-md">{award?.description}</p>
      </div>
    </Dialog>
  );
}
