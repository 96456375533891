import styled from 'styled-components';
import { darken } from 'polished';

interface Avatarr {
  src: string;
}

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 0px 0px 12px 12px;
  .text-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Blue - Wodo */

    color: #324351;
  }
  .text-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    font-feature-settings: 'zero' on;

    color: #707070;
  }
  .carddaos {
    /* top-left | top-right | bottom-right | bottom-left */
    border-radius: 15px 15px 15px 15px !important;
  }
  .bg-verdee {
    background: #39a887;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }

  .bg-gris {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 0px 0px 12px 12px;
    height: auto;
  }

  .sup-bar {
    background: #39a887;
    border-radius: 15px;
    width: 73.79px;
    height: 7.2px;
    opacity: 1;
  }

  .carddaos {
    background: #39a887;
    border-radius: 15px;
  }

  .divEndereco {
    padding-top: 25px;
    border: none;
    background: #ffffff;
    border-radius: 15px;
  }

  .endeDados {
    margin-left: 300px;
  }

  .ojobtn {
    background: #414142;
    border-radius: 25px;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
  }

  .ojobtn:hover {
    box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
  }

  .btnShare {
    margin-top: 20px;
    float: right;
    // box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    .btntelegram:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnfacebook:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnwhatsapp:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btntwitter:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnlinkedin:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
    .btnreddit:hover {
      width: 45px;
      height: 45px;
      background: #39a887;
      box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    }
  }

  .alterarbtn {
    margin: 10px;
    background: #079269;
    border-radius: 25px;
    color: #ffffff;
    width: fit-content;
    height: fit-content;
  }

  .alterarbtn:hover {
    box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    background: #06835e;
  }

  .sub-bar {
    border: 0.3px solid #b8b9ba;
  }

  .h2 {
    font-size: 24px;
  }

  .input {
    // border: 0.3px solid #b8b9ba;
    border: none;
    padding: 0px 10px;

    input {
      color: #414141;
    }
  }

  .btn-insert {
    background-color: #7b7b7b;
    width: 100px;
    height: 34px;
    border-radius: 24px;
  }

  .logo {
    font-size: 14px;
  }

  .caract-count {
    color: #b8b9ba;
  }

  .btn-border {
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 0px 0px;
    height: 37px;
    white-space: nowrap;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: transparent;
    -webkit-text-fill-color: #707070;
    transition: 0.3s;

    :hover {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .tabs {
    margin-bottom: -1px;

    .active {
      background: #dcf0ea;
      border-color: #dcf0ea;
      -webkit-text-stroke-color: #414142;
    }
  }

  .folder {
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 10px 10px;
    background: #ffffff;
  }

  .name {
    color: #324351;
  }

  .btn-save {
    background: #39a887;
    border-radius: 20px;
    color: #ffffff;
    width: 100%;
    height: 50px;
  }

  .textArea {
    border: 1px solid #e4e4e4;
  }

  @media screen and (min-width: 414px) {
    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 331px;
      height: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-save {
      background: #39a887;
      border-radius: 20px;
      color: #ffffff;
      width: 335px;
      height: 50px;
    }
  }
`;

export const AvatarCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39a887;
  color: #f5f5f5;
  width: 45px;
  height: 100%;
  border-radius: 50%;
  b {
    font-size: 15px;
    span {
      font-size: 12px;
    }
  }
`;

interface IBoxButtons {
  show?: boolean;
}

export const SectionAddImage = styled.section`
  .section {
    padding: 2rem 0;
    flex-direction: row;
  }

  .icon {
    width: 100%;
  }

  .label {
    // margin: 0 auto;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dotted #000;
    border-radius: 20px;
    width: 12rem;
    height: 12.5rem;
    cursor: pointer;
    font-size: large;
    position: relative;
    box-shadow: rgba(10, 10, 0, 0.35) 0px 2px 15px 5px;
    background-color: #f0faf5;
  }

  .hola {
    // boton para insertar imagenes queda invisible
    display: none;
  }
  .img {
    padding: 0;
    margin: 0;
  }
  .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .image {
    margin: 1rem 0.8rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 20px 3px;
    border-radius: 5px;
  }
  .buttonDelete {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: lightcoral;
  }
  .buttonDelete:hover {
    background-color: red;
  }

  .btnUploadFotos {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 12rem;
    height: 3rem;
    color: #fff;
    background-color: #39a887;
  }
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;

export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const but = styled.button`
  position: absolute;
  background-color: #39a887;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  right: 0px;
  bottom: 0px;
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;

export const Avatarr = styled.div<Avatarr>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: relative;

  .camera {
    position: absolute;
    background-color: #39a887;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    right: 0px;
    bottom: 0px;
  }
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
