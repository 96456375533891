import styled from 'styled-components';

interface IAvatar {
  src: string;
}

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;
export const Container = styled.div`
  .container_dashboard_info_res {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  .chat {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Title */

    color: #414142;
  }
  .color {
    background: #f5f5f5;
  }
  .container_dashboard_info {
    display: flex;
    justify-content: center;
    align-items: center;
    weight: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  h1 {
    color: #414141;
    font-weight: 400 !important;
    text-align: center;

    b {
      font-weight: 700 !important;
    }
  }

  button.back-button {
    margin-bottom: 10px;
    border: 0;
    /* border: 1px solid #ddd;
    border-radius: 30px; */
  }

  .card-dashboard {
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: auto;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
  }
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    border-radius: 50%;
  }
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
