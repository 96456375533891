import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .btn-add-column {
    gap: 0.4rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #39a887;
    border-radius: 48px;
    border: none;
    padding: 14px 16px;
    color: #fff;
    width: 180px;
    height: 36px;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }
  }
  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }
  .modal-header {
    border-bottom: 0 none;
  }
  .modal-footer {
    border-top: 0 none;
  }
`;
