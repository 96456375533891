import styled from 'styled-components';

export const Container = styled.div`
  height: 38.5rem;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;

  .reminder-task {
    background: #f9f9f9;
    border-radius: 10px;

    + .reminder-task {
      margin-top: 1rem;
    }
  }

  .no-data {
    color: #b8b9ba;
  }

  .sub-container-date {
    height: 40%;
  }
  .sub-container-task {
    height: 55%;
  }
`;
