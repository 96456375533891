import styled from 'styled-components';
import banner from '~/assets/banners/Background-landpage.png';
import banner3 from '~/assets/banners/4.png';

export const Container322 = styled.div`
  .text-descricao {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #6a6a6a;

    text-align: center;
    width: 38%;
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 280px;
      padding-left: 20px;
    }
  }
`;

export const Container2 = styled.div`
  .vl {
    border-top: unset !important;
    border-left: 1px solid #b8b9ba;
    height: 40px;
  }
  @media (max-width: 557px) {
    .vl {
      border-top: 1px solid #b8b9ba !important;
      border-left: unset !important;
      width: 150px;
      margin-left: 10px;
      align-items: center;
      text-align: center;
    }
  }
`;
export const ContainerBanner = styled.div`
  .banner {
    font-family: 'Verdana', sans-serif;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    margin: 0;
    color: #fff;
  }

  body {
    margin: 2rem;
    background-color: #212123;
  }

  .conteudo {
    width: 450px;
  }

  .carrousel {
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    gap: 1.2rem;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: 300px;
    animation-duration: 20s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .carrousel::-webkit-scrollbar {
    // display: flex;
    display: none;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #6a6a6a;
  }
  figure {
    position: relative;
    width: 950px;
    height: 600px;
    background-color: #000;
    overflow: hidden;
  }

  @keyframes slidein {
    from {
      margin-left: -220%;
      // width: 300%;
    }
    to {
      margin-left: 0%;
      // width: 100%;
    }
  }

  figure figcaption::before {
    content: '';
    position: absolute;
    inset: 20px;
    border: 2px solid #fff;
    opacity: 0;
    scale: 1.25;
    transition: opacity 0.25s, scale 0.25s;
  }

  figure:hover figcaption::before {
    opacity: 1;
    scale: 1;
  }
  figure img {
    background-size: cover;
    background-position: center;

    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
    scale: 1;
    transition: opacity 0.25s, scale 0.35s;
  }
  figure:hover img {
    opacity: 0.2;
    scale: 1;
  }
  figure figcaption {
    position: absolute;
    inset: 0;
    padding: 50px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    justify-content: center;
  }

  figure h2 {
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  figure p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    text-align: center;

    color: #fff;
    opacity: 0;
    translate: -25px;
    transition: opacity 0.25s, translate 0.25s;
  }

  figure:hover p {
    opacity: 1;
    translate: 0;
  }

  .card {
    scroll-snap-align: start;
    border-radius: 30px;
    min-height: 596px;
    min-width: 960px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 557px) {
    .card {
      min-height: 250px;
      min-width: 350px;
    }
    figure {
      width: 250px;
      height: 350px;
    }
    figure p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 15px;
    }
  }
  .card:nth-child(1) {
  }

  .card:nth-child(2) {
  }

  .card:nth-child(3) {
  }

  .card:nth-child(4) {
  }

  .card:nth-child(5) {
  }

  .card:nth-child(6) {
  }

  .card:nth-child(7) {
  }
  .card:nth-child(8) {
  }
`;
export const Container5 = styled.div`
  @media (max-width: 557px) {
    text-align: center;
    .icons-social {
      width: 100px !important;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100% !important;

  .scroll {
    min-width: 800px;
  }

  .btn-primaryy {
    border-color: #39a887 !important;
    background-color: #39a887 !important;
    color: #ffffff !important;

    :hover {
      color: #707070 !important;
      border-color: #f1f1f1 !important;
      background-color: gray !important;
    }
  }
  .container_images_buttons {
    .button_setImage {
      color: #707070;
      border-color: #ffffff;
      height: 40px;
      :hover {
        border-color: #f1f1f1 !important;
        background-color: #f1f1f1 !important;
      }
      .btn {
        background-color: #39a887;
      }
    }
  }
  .ocultar-scroll {
    overflow: hidden;
  }
  .scroll {
    min-width: 1090px;
  }
  @media (max-width: 557px) {
    .container_images_buttons {
      .button_setImage {
        height: 50px;
      }
    }
  }
`;
export const Container = styled.div`
  .slider-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #343a40;
    color: #fff;
    transition: all 0.3s ease-in-out;
    z-index: 999;
  }

  .button-sin-estilos {
    background-color: transparent;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }

  .cursor-none {
    pointer-events: none;
  }

  .button-sin-estilos2 {
    background-color: transparent;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }

  .slider-menu.open {
    left: 0;
  }

  .menu-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .menu-items li {
    padding: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }

  .menu-items li:hover {
    color: #e9ecef;
  }
  .mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .menu {
    @media (min-width: 600px) {
      display: none;
    }
  }

  .trasparente {
    background-color: transparent;
    border: none;
  }

  .text-harvey {
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 60px;
    color: #414142;
  }

  .text-isso {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    font-size: 400px;
  }
  .line-black {
    margin-left: 36%;
    margin-right: 36%;
  }
  .text-color-gris {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    color: #838383;
  }
  .button-quero {
    background-color: #414142;
    //hover button
    &:hover {
      //delay
      transition: 0.5s;
      background-color: #202020;
    }
  }
  .text-todas {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    color: #212529;
  }
  .content_main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    gap: 0.8rem;
  }
  > div {
    min-height: 100vh;
  }
  .navbar_scroll {
    background-color: linear-gradient(
      90deg,
      rgba(249, 249, 249, 0.4) 4.65%,
      rgba(255, 255, 255, 0.4) 100%
    );
    border-bottom: 1px solid #f2f2f2;
    backdrop-filter: blur(5px);
  }
  .navbar_container {
    position: fixed;
    width: 100%;
    button {
      display: flex;
      gap: 1rem;
      height: 35px;
      align-items: center;
    }
  }
  .arrow {
    position: absolute;
    top: 80%;
    left: 49%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
    cursor: default;

    @media (min-width: 500px) {
    }
  }

  .arrow span {
    display: block;
    width: 6.2vw;
    height: 6.2vw;
    border-bottom: 3px solid #939393;
    border-right: 3px solid #939393;
    margin: -17.2px;
    animation: animate 2s infinite;
    @media (min-width: 500px) {
      width: 1.2vw;
      height: 1.2vw;
    }
  }

  .arrow span:nth-child(2) {
    animation-delay: -0.2s;
  }

  .arrow span:nth-child(3) {
    animation-delay: -0.4s;
  }

  @keyframes animate {
    0% {
      opacity: 0;
      transform: rotate(315deg) translate(-100%, -100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(315deg) translate(100%, 100%);
    }
  }

  .company {
    color: #414142;
  }
  .line {
    color: black;
    border: 2px;
    width: 20%;
    margin: 0 auto;
    justify-content: center;
  }
`;
export const Container31 = styled.div`
  .text-button-2 {
    background: #01cc9d;
    border-radius: 26px;
  }
  .text-plataforma {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #6a6a6a;
  }

  .text-ferramentas {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }
  .text-titles {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;

    color: #5f5f5f;
  }
  .text-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-end;

    color: #aaaaaa;
  }

  .redondeo {
    border-radius: 15px;
  }
  .text-escritorio {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #079269;
  }
  .pl-2 {
    padding-left: 90px !important;
  }
  .pl-3 {
    padding-left: 74px !important;
  }
  .text-isso {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
  }
  .pr-2 {
    padding-right: 250px !important;
  }
`;
export const Container21 = styled.div`
  .text-ferramentas {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
  }

  .text-todas {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 36px;

    color: #000000;

    @media (min-width: 600px) {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      color: #000000;
    }
  }

  .no-mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .mobile {
    @media (min-width: 600px) {
      display: none;
    }
  }
  .espacio {
    @media (min-width: 600px) {
      padding-left: 123px;
    }
  }

  .mobile-espacio {
    @media (max-width: 600px) {
      padding-left: 20px;
    }
  }
  .ordenar {
    display: flex;
  }
  .text-escritorio {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #079269;
  }
  .text-escritorio-mobile {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    /* Secoundary */

    color: #079269;
  }
  .pl-2 {
    padding-left: 10px !important;
    @media (min-width: 600px) {
      padding-left: 115px !important;
    }
  }
  .pl-90 {
    padding-left: 90px !important;
  }
  .pl-3 {
    padding-left: 74px !important;
  }
  .text-isso {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
  }
  .text-isso-mobile {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #000000;
  }
`;
export const Container3 = styled.div`
  > div {
    min-height: 45vh;
    width: 50%;
    @media (max-width: 440px) {
      min-height: 45vh;
      width: 100%;
    }
  }

  .company {
    color: #414142;
  }
  .line {
    color: black;
    border: 2px;
    width: 20%;
    margin: 0 auto;
    justify-content: center;
  }
`;
export const Container4 = styled.div`
  > div {
    min-height: 540px;
  }

  .company {
    color: #414142;
  }
  .line {
    color: black;
    border: 2px;
    width: 20%;
    margin: 0 auto;
    justify-content: center;
  }
`;
export const Title = styled.div`
  div {
    height: 7px;
    width: 60px;
    background: #39a887;
    border-radius: 26px;
    padding-left: 50px;
  }
`;

export const Background = styled.div`
  background: url(${banner}), #f7f9fb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

interface IImageProps {
  image: string;
  description: string;
}
interface IProp {
  image: IImageProps;
}
export const ContainerTextInfo = styled.div`
  .container_text_info {
    border-radius: 30px;
  }

  .text-obras {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    /* Title */

    color: #414142;
  }
`;

export const ContainerFont = styled.div`
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
  }
  .text-pequeno {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
  }
  .text-copy {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    align-items: center;
    text-align: center;

    color: #000000;
  }
  .text-harvey-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* Title */

    color: #414142;
  }
  .text-harvey {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }
`;

export const Background2 = styled.div<IProp>`
  @media (max-width: 557px) {
    min-height: 280px !important;
  }
  background: url(${(props) => props.image.image}), #f7f9fb;
  background-size: 100% 100%;
  background-position: center;
  align-items: center;
  border-radius: 10px;
  // min-height: 74vh !important;
  // min-width: 64vw !important;
  width: 968px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-shadow: -2px 6px 10px 10px rgba(0, 0, 0, 0.1);
  h1 {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
  }
`;
export const Background3 = styled.div`
  background: url(${banner3}), #f7f9fb;
  background-size: cover;
  background-position: center;
  align-items: center;
`;
