// Partials
import { MockStep } from "../../../Mocks";

// Types
import { TStepTargets } from "../../types";
import { INavigationProps } from "./types";

export function Navigation ({onActualStepType, showNavigation, actualStepType, stepsMounted}: INavigationProps) {
    return (
        <nav
          data-showmew={showNavigation}
          className="
            w-full 
            h-auto 
            hidden
            md:data-[showmew=true]:flex
            max-sm:hidden
            overflow-x-auto
          "
        >
          <ul className='w-full flex flex-row items-center justify-start gap-2'>
            {
              stepsMounted.length <= 0 && <MockStep />
            }
            {
              stepsMounted.map(step => (
                <li
                  key={step.id}
                  role="button"
                  tabIndex={-1}
                  title={step.name}
                  onClick={() => onActualStepType(step.type as TStepTargets)}
                  data-active={actualStepType === step.type}
                  className='
                    flex-1
                    min-w-[216px]
                    h-[35px]
                    flex
                    flex-row items-center justify-center
                    list-none
                    px-[12px]
                    pb-[10px]
                    mb-[5px]
                    transition-all
                    duration-[0.3s]
                    border-b-transparent
                    border-b-[3px]
                    text-[#b8b9ba]
                    hover:text-[#414142]
                    cursor-pointer
                    data-[active=true]:cursor-not-allowed
                    data-[active=true]:pointer-events-none
                    data-[active=true]:border-b-[#079269]
                    data-[active=true]:text-[#414142]
                    data-[active=true]:font-semibold
                  '
                >
                  {step.name}
                </li>
              ))
            }
          </ul>
        </nav>
    )
}