import React from 'react';
import { CompleteData } from './styles';

const CompleteDataBox: React.FC = () => (
  <CompleteData>
    <div className="col-md-12 text-center py-2">
      <p>Complete os dados destacados, para prosseguir</p>
      <p>com sua ordem de serviço</p>
    </div>
  </CompleteData>
);

export default CompleteDataBox;
