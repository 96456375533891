import styled from 'styled-components';
import List from 'react-chatview';
import { darken } from 'polished';

interface IMessagesGroup {
  active: boolean;
}
interface IAvatar {
  src: string;
}
export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.div`
  width: calc(100% - var(--sidebar-width));
  .container {
    overflow: hidden;
    height: 100vh;
  }

  h1 {
    font-size: 36px;
    color: #414142;
    font-weight: 600;
  }

  .total-notifications {
    color: #fff;
    background-color: #39a887;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
  }

  .notifications {
    color: #fff;
    background-color: #39a887;
    font-size: 13px;
  }

  @media screen and (min-width: 992px) {
    min-height: 100vh;
    overflow: unset;
  }
`;
export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;

export const UserGroup = styled.div`
  .link-groups {
    a {
      font-weight: 500;
      color: #414142;
      border-bottom: 3px solid transparent;
      transition-duration: 0.3s;
    }

    a.active,
    a:hover {
      border-color: #39a887;
    }
  }
`;

export const ChatLink = styled.button`
  + button {
    border-top: 1px solid #eaeaea !important;
  }
`;

export const Notifications = styled(List)`
  height: calc(100vh - 150px);

  h2 {
    color: #414142;
  }

  h3 {
    color: #b8b9ba;
  }

  p {
    color: #707070;
  }

  .height-notifications {
    height: 100%;
  }

  .notifications-hover {
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#fff')};
    }
  }

  @media screen and(min-width: 992px) {
    height: calc(100vh - 180px);
  }
`;

export const MessagesGroup = styled.div<IMessagesGroup>`
  transition-duration: 0.3s;
  position: absolute;
  background: #fff;
  height: 70vh;
  left: ${(props) => (props.active ? '0' : '1000px')};
  top: 0;
  padding: 0;

  @media screen and (min-width: 992px) {
    position: unset;
  }
`;
