import React, { useCallback, useEffect, useState } from 'react';
import { BsClockFill, BsImage } from 'react-icons/bs';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

import { IMFileType } from '~/models/FileTypes';

import { IMParticipant } from '~/models/Participant';
import ModalShowFiles from '../ModalShowFiles';
import { Container } from './styles';

interface IBoxDocumentGroupsParams {
  fileType: IMFileType;
  participant?: IMParticipant;
  seller?: number;
  onChangeFiles(): void;
}

const BoxDocumentGroups: React.FC<IBoxDocumentGroupsParams> = ({
  fileType,
  participant,
  seller,
  onChangeFiles,
}) => {
  const [resultado, setResultado] = useState<0 | 1 | 2>(0);
  const [showFiles, setShowFiles] = useState(false);

  // AUX Variables
  const FILE_TYPE_TO_CAIXA_BANK = "Autorizacao pesquisa cadastral";
  const status = [
    {
      status: 'Aguardando validação',
      color: '#FFA500',
    },
    {
      status: 'Aprovado',
      color: '#01CC9D',
    },
    {
      status: 'Reprovado',
      color: '#FF1A50',
    },
  ];

  useEffect(() => {
    const awaitingValidation = fileType?.files?.filter((item) => !item.resultado_id) ?? [];
    const aproved = fileType?.files?.filter((item) => item?.resultado_id === 1) ?? [];
    const reproved = fileType?.files?.filter((item) => item?.resultado_id === 2) ?? [];
    if (awaitingValidation.length > 0) {
      setResultado(0);
    } else if (aproved.length > 0) {
      setResultado(1);
    } else if (reproved.length > 0) {
      setResultado(2);
    }
  }, [fileType?.files]);

  const renderEditVisualizer = useCallback(() => (
    <div className="col-4">
      <button
        type="button"
        className="button-left-arrow"
        onClick={() => setShowFiles(true)}
      >
        <MdOutlineArrowForwardIos size={25} color="#BBBBBB" />
      </button>
    </div>
  ), []);

  const renderSendButton = useCallback(() => {
    if (fileType.grupo > 2) {
      return renderEditVisualizer();
    }
    return (
      <div className="col-2 box-button">
        <button
          type="button"
          className="send-button py-2 px-4"
          onClick={() => setShowFiles(true)}
        >
          Enviar
        </button>
      </div>
    );
  }, [fileType, renderEditVisualizer]);

  return (
    <>
      <Container>
        <div className="box-upload border mx-0 row">
          <div className="col-sm-8 box-image">
            <div className="button-modal">
              <div className="box-icon">
                <BsImage size={30} color="#707070" className="icon" />
              </div>
              <span className="mx-3">{fileType.tipo_arquivo}</span>
            </div>
          </div>
          <div className='flex-1 flex flex-row flex-nowrap items-center justify-end gap-2'>
            {fileType.grupo <= 3 ? (
              fileType?.files?.length === 0 ? (
                renderSendButton()
              ) : (
                <div
                  className="col-sm-auto box-status"
                  style={{ color: status[resultado].color }}
                >
                  <div className="col-auto d-flex align-items-center">
                    <BsClockFill
                      size={20}
                      className="me-2"
                      color={status[resultado].color}
                    />{' '}
                    {status[resultado].status}
                  </div>
                  {renderEditVisualizer()}
                </div>
              )
            ) : fileType?.files?.length ? (
              <div
                className="col-sm-auto box-status"
                style={{ color: status[resultado].color }}
              >
                <div className="col-auto d-flex align-items-center">
                  Visualizar
                </div>
                {renderEditVisualizer()}
              </div>
            ) : (
              ''
            )}
            {
              fileType.nomenclatura === FILE_TYPE_TO_CAIXA_BANK && (
                <div className="box-button">
                  <a
                    href={`${process.env.REACT_APP_API_URL}/builders/autorization-for-customer-registry-research`}
                    download
                    title='Baixar arquivo modelo'
                    translate='yes'
                    target='__blank'
                    className="send-button py-2 px-4 
                      whitespace-nowrap text-ellipsis 
                      hover:bg-[#079269]
                    "
                  >
                    Baixar modelo
                  </a>
                </div>          
              )
            }
          </div>
        </div>
      </Container>

      {showFiles && (
        <ModalShowFiles
          fileType={fileType}
          onHide={() => setShowFiles(false)}
          participant={participant}
          seller={seller}
          onChangeFiles={onChangeFiles}
        />
      )}
    </>
  );
};

export default BoxDocumentGroups;
