import styled from 'styled-components';

import banner from '~/assets/banners/background-login.png';

export const Container = styled.div`
  > div {
    min-height: 100vh;
  }

  .company {
    color: #414142;
  }
`;

export const Content = styled.div`
  border-radius: 0px 33px 33px 0px;
  position: relative;
  background: #fff;
  height: 100vh;
  img {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  form {
    width: 100%;
    .input {
      border-radius: 0;
      border-left: none;
      border-top: none;
      border-right: none;
    }

    button.btn-primary {
      border-radius: 20px !important;
      font-size: 18px;
      height: 54px;
    }
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      width: 80%;
    }
  }
`;

export const Background = styled.div`
  background: url(${banner}), #f7f9fb;
  background-size: cover;
  background-position: left center;
`;

// this const help to login whith google in the login page
export const Buttonn = styled.button`
  border-width: 0px;
`;
