import React, { useCallback } from 'react';
import { BsTrash } from 'react-icons/bs';

import { useProfile } from '~/hooks/Profile';
import api from '~/services/api';
import Toast from '~/utils/toast';
import FormCities from './Partials/Form';

const Address: React.FC = () => {
  const { engineer, setEngineer, setLoading } = useProfile();

  const handleDeleteCity = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await api.delete(
          `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/minicipalities/${id}`
        );
        const newEngineer = { ...engineer };
        const index = newEngineer.municipios?.findIndex(
          (municipio) => municipio.id === id
        );
        if (index !== undefined) {
          newEngineer.municipios?.splice(index, 1);
        }
        setEngineer(newEngineer);
      } catch (error) {
        Toast.fire({
          icon: 'success',
          title: 'Falha ao deletar cidade',
        });
      } finally {
        setLoading(false);
      }
    },
    [engineer, setEngineer, setLoading]
  );

  return (
    <div className="row folder mt-4 mx-0 pb-3 pb-lg-5">
      <div className="divEndereco card mx-6">
        <h5 className="card-title ms-2 text-title">Cidades de atuação</h5>
        <FormCities />
        <div className="row px-2 gap-2 mt-2 text-center">
          {engineer.municipios?.map((municipio) => (
            <div className="col-3 folder" key={municipio.id}>
              <div className="row py-1 px-2">
                <div className="col-10 d-flex justify-content-center">
                  {municipio.municipio}/{municipio.estado.sigla}
                </div>
                <div className="col-2">
                  <BsTrash
                    size={15}
                    style={{ color: '#ff0000', cursor: 'pointer' }}
                    onClick={() => municipio?.id && handleDeleteCity(municipio.id)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Address;
