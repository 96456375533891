import React, { useCallback, useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import Swal from 'sweetalert2';

import Loading from '~/components/Loading';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';

import { Link } from 'react-router-dom';
import { IMFileType } from '~/models/FileTypes';
import ModalShowFiles from '../../../FilesManagement/Partials/ModalShowFiles';
import { DownloadDocuments } from './styles';

const DownloadDocumentsBox: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [loading, setLoading] = useState(false);
  const [modalMenssage, setModalMensage] = useState('');
  const [fileType, setFileType] = useState<IMFileType>({} as IMFileType);
  const [showFiles, setShowFiles] = useState(false);

  const loadDocumentLists = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .get<IMFileType[]>(`builders/file-types/${serviceOrder.id}`, {
          params: {
            grupo: 4,
          },
        })
        .then((response) => {
          setFileType(response.data[0]);
        });
    } catch (err) {
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os tipos de documentos!',
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [serviceOrder.id]);

  useEffect(() => {
    (serviceOrder?.status_id === 7 || serviceOrder?.status_id === 8) &&
      loadDocumentLists();
  }, [loadDocumentLists, serviceOrder?.status_id]);

  const handleDownloadProposal = useCallback(async () => {
    setModalMensage('Aguarde, estamos processando sua proposta');
    try {
      setLoading(true);
      const response = await api.get(
        `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${serviceOrder?.id}/proposal`,
        {
          responseType: 'blob',
        }
      );
      const urlData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlData;
      link.download = 'Proposal.pdf';
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(urlData);
        link.remove();
      }, 100);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro, arquivo não encontrado.',
        'error'
      );
    }
  }, [serviceOrder?.id]);

  return (
    <>
      <DownloadDocuments>
        <div className="mt-4 d-lg-flex justify-content-lg-between">
          {(serviceOrder?.status_id === 7 || serviceOrder?.status_id === 8) && (
            <div className="col-xl-4">
              <button
                type="button"
                className="button-credit d-flex"
                onClick={() => fileType && setShowFiles(true)}
              >
                <BsDownload size={20} className="me-2 mb-1" />
                <b>Baixar analise de crédito</b>
              </button>
            </div>
          )}

          <div className="col-xl-4">
            <Link 
              title='Clique para visualizar e baixar sua proposta'
              to={`proposta/${serviceOrder?.id || -1}/impressao`}
              className='
                h-12
                flex flex-row 
                flex-nowrap items-center 
                justify-center gap-3 
                rounded-[25px]
                bg-[#39a887]
                hover:bg-[#2c8168]
                hover:active:bg-[#226450]
                active:bg-[#2c8168]
                scale-100
                hover:scale-105
                hover:active:scale-110
                active:scale-105
                transition-all
                duration-[0.37s]
                text-white
              '
            >
              Visualizar proposta
            </Link>
          </div>
        </div>
      </DownloadDocuments>
      {showFiles && (
        <ModalShowFiles
          fileType={fileType}
          onHide={() => setShowFiles(false)}
        />
      )}
      <Loading active={loading} textMessage={modalMenssage} />
    </>
  );
};

export default DownloadDocumentsBox;
