import styled from 'styled-components';

export const Container = styled.section`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  width: 60%;

  @media (max-width: 1400px) {
    width: 50%;
    height: 80%;
  }

  @media (max-width: 1280px) {
    width: 100%;
    height: 80%;
  }
`;
