import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RiArrowLeftSLine } from 'react-icons/ri';

import UserAvatar from '~/components/UserAvatar';
import { useProfile } from '~/hooks/Profile';
import { Container, CompleteData } from './styles';

interface IParams {
  osId?: string;
}

const SubirArquivos: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { validateEngineerData } = useProfile();

  const changeHistory = (path: string) => {
    history.push(path);
  };

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const handleBack = (): void => {
    history.goBack();
  };
  return (
    <div className="bg-white">
      <Container className="container mx-auto position-relative">
        <div className="row justify-content-between h-100 mb-5">
          <div className="d-flex align-items-center mb-5">
            <div className="d-flex align-items-center me-2">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4 px-4"
                onClick={handleBack}
              >
                <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
              </button>
              <div>
                <h1 className="title-assinatura row mt-4">Subir Arquivos</h1>
                <h1 className="title-sub row">
                  Para dar o próximo passo, preencha os dados para a emissão do
                  seu contrato
                </h1>
                <h1 className="title-sub row">de construção e terreno</h1>
              </div>
            </div>
            <div className="row col-auto mx-auto mt-3">
              <UserAvatar />
            </div>
          </div>
          <div className="ms-5 mt-5">
            <CompleteData className="row ms-5 mt-5">
              <div className="ms-5">
                <div className="ms-4 mb-4">
                  <div className="title-correspondente mt-4">
                    Upload de arquivos
                  </div>
                </div>
              </div>

              <div className="sub-verde ms-5">
                <input
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value="Paneer"
                  className="me-2"
                />
                Quero adiantar duas primeiras parcelas da PFUI (Limitando a 20%
                do valor total da PFUI)
              </div>
              <div className="ms-4">
                <div className="sub-verde pb-4 ms-5"> *Somente SBPE</div>
              </div>
              <div className="ms-4">
                <div className="sub-gris pb-4 ms-5">
                  Não é possível usar recursos do FGTS para obras com
                  adiantamento de parcela.
                </div>
              </div>
            </CompleteData>
          </div>
        </div>
        <button
          type="button"
          className="button-prox btn  btn-nexttt fw-semibold mt-5"
          onClick={() => changeHistory(
            `/proponente-documentos-da-obra/subir-arquivos/${params.osId}`
          )
          }
        >
          Continuar
        </button>
      </Container>
      <div className="py-5" />
      <div className="py-5" />
      <div className="py-4" />
    </div>
  );
};

export default SubirArquivos;
