import { ButtonHTMLAttributes, useEffect, useRef, useState } from "react";

// Types
import { IProject } from "./Partials/Project/type";
import { ICreateYourHouseProps } from "./types";

// Partials
import { List } from "./Partials/List";
import { Mock } from "./Partials/Mock";
import { Project } from "./Partials/Project";

// Context
import UserDefault from "~/components/UserDefault";
import { useAuthContext } from "~/hooks/contexts/Auth";

// Services
import api from "~/services/api";

export function ProjectYourHouse({}: ICreateYourHouseProps) {
    const [projects, setProjects] = useState<IProject[]>([])
    const [onLoading, setOnLoading] = useState<boolean>(false)
    const [project, setProject] = useState<IProject | null>(null)    
    const { user } = useAuthContext();    

    // REFS
    const dialogButtonRef = useRef();
    
    // AUX Variables and Conditions
    if(!user || Object.keys(user).length <= 0) window.location.href = "/login"

    useEffect(() => {
        if(projects.length > 0) return;

        setOnLoading(true)
        api.get<IProject[]>(`builders/build-your-house`)
        .then(resolver => {
            setProjects(resolver.data)            
        })
        .catch(rejects => {
            setOnLoading(false)
        }).finally(() => {
            setOnLoading(false);
        })
    },[])

    function setPickUpProject(data: IProject | null = null){
        try {
            if(!data) throw new Error('The data is missing');


            setProject(data)

            if(dialogButtonRef) {
                // @ts-ignore
                (dialogButtonRef.current as unknown as ButtonHTMLAttributes<HTMLButtonElement>)?.click()
            }
        } catch (error) {
            // do anything
        }
    }

    function setPushBack(data: IProject | null = null){
        try {
            if(!data) throw new Error('The data is missing');


            setProjects(e => [data, ...e])
        } catch (error) {
            // do anything
        }
    }

    function setDropOfProject(){
        try {
            setProject(null)
        } catch (error) {
            // do anything
        }
    }
    
    return (
        <div className="mt-lg-0 pt-5 max-sm:px-[15px] md:px-[60px] bg-[#FCFCFC] max-sm:mb-10">
            <div className="container pr-14 pl-14 pb-14 mt-5 mt-lg-0 space-y-10">
                <UserDefault home />

                <div className="w-full h-auto flex max-sm:flex-col md:flex-row items-center gap-4">
                    <h2 className="text-[#414142] font-medium text-xl leading-8">Monte sua Casa</h2>
                    <Project 
                        data={project} 
                        dialogButtonRef={dialogButtonRef} 
                        onRollback={setDropOfProject}
                        onPushback={setPushBack}
                        setDropOfProject={setDropOfProject}
                    />
                </div>
                <div
                    className="
                        w-full min-h-[431px] 
                        h-auto rounded-[23px]
                        border-[1px] border-[#E4E4E4]
                        max-sm:p-[15px]
                        md:p-[35px]
                        flex                        
                        flex-col
                        max-sm:gap-[16.5px] 
                        md:gap-[33px]
                        shadow-md
                        -mt-5
                    "
                >
                    <div
                        data-hasProjects={projects.length > 0 && true}
                        className="                            
                            w-full
                            h-12
                            flex
                            data-[hasProjects=false]:hidden
                            flex-row
                            items-center
                            justify-start
                            gap-4
                            cursor-default
                            select-none
                        "
                    >
                        <span className="flex-1 flex max-sm:max-w-[50px] md:max-w-[200px] text-[#414142] font-semibold leading-[26px]">ID</span>
                        <span className="flex flex-1 text-[#414142] font-semibold leading-[26px]">Descrição</span>
                    </div>
                    <div
                        data-hasProjects={projects.length > 0 && true}
                        className=" 
                            w-full
                            h-[431px]
                            flex
                            flex-col
                            items-start
                            justify-start
                            data-[hasProjects=false]:items-center
                            data-[hasProjects=false]:justify-center
                            data-[hasProjects=false]:[&_.empty-projects-list]:flex
                            gap-3
                            max-sm:overflow-x-auto
                            md:overflow-y-auto
                        "
                    >
                        <Mock isOnLoading={onLoading} />
                        <List 
                            projects={projects} 
                            isOnLoading={onLoading}
                            onPickUpProject={setPickUpProject}

                        />

                        <span
                            data-hasProjects={projects.length > 0 && true}
                            data-onLoading={onLoading && true}
                            className="empty-projects-list 
                                hidden
                                data-[onLoading=false]:data-[hasProjects=false]:flex
                                w-auto h-auto 
                                text-md text-[#414142] font-semibold
                                text-center
                            "
                        >
                            Nenhum projeto criado...
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}