import styled from 'styled-components';

export const Notification = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 10px;

  .boton-icon-check {
    border-radius: 40px;
    border: none;
    font-size: 50px;
    line-height: 6px;
    color: #ffffff;
    background: #39a887;

    :hover {
      transition: 0.3s;
      background: #ffffff;
      color: #39a887;
    }

    small {
      color: #a8a8a8;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
    }

    div {
      div:last-child {
        width: calc(100% - 50px);
        padding-left: 10px;

        h6 {
          color: #414142;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #b8b9ba;
        }

        a {
          background: #39a887;
          border-radius: 80px;
          border: none;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: #ffffff;
        }
      }
    }

    + div {
      margin-top: 20px;
    }
  }
`;

export const Avatar = styled.div`
  width: 37px;
  height: 37px;
  background: #e8f9f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
