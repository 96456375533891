import React from 'react';

import { Container } from './styles';

interface ISimulation {
  prazo: number;
  taxa: number;
}

interface IParams {
  simulation: ISimulation;
}

const SimulationConditions: React.FC<IParams> = ({ simulation }) => (
  <Container className="container row card-dashboard justify-content-center p-4 mb-5">
    <div className=" mt-0">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="h6 mb-0">Condições da simulação</h2>
      </div>
    </div>
    <div className="">
      <div className="d-flex subtitle align-items-center mb-3">
        <div className="col-7 d-flex align-items-center">
          <h4 className="mb-0">Prazo do financiamento</h4>
        </div>
        <p className="mb-0 font-weight-700 ms-auto text-green">
          {simulation.prazo} anos
        </p>
      </div>
      <div className="d-flex subtitle align-items-center">
        <div className="col-7 d-flex align-items-center">
          <h4 className="mb-0">Taxa de juros</h4>
        </div>
        <p className="mb-0 font-weight-700 ms-auto text-green">
          {simulation.taxa}% a.a
        </p>
      </div>
    </div>
  </Container>
);

export default SimulationConditions;
