// Icons
import banheiraIcon from '~/assets/icons/banheira-produto-imobiliario.svg';
import carroIcon from '~/assets/icons/carrro-produto-imobiliario.svg';
import sofaIcon from '~/assets/icons/sofa-produto-imobiliario.svg';

// Models
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';

// Interfaces
import { IStepData } from '~/pages/RealEstateProduct/RealEstateProject/types';
import { IEspecifyProps } from './types';

// Components
import { IMRealStateProduct } from '~/models/RealEstateProduct';
import { MessageError } from '~/pages/RealEstateProduct/Partials/MessageError';
import { Option } from '~/pages/RealEstateProduct/Partials/Option';

export function Especify({
  onDataHouse, dataStep, onDataStream, housePosition
}: IEspecifyProps) {
  return (
    <div className="flex flex-col w-full gap-[2.187rem]">
      <div className="flex md:flex-row md:flex-nowrap max-sm:flex-col md:items-center justify-between max-sm:gap-[1rem] pb-[0.312rem]">
        <div className="flex flex-col md:w-[11.125rem] max-sm:w-full h-[4.5rem]">
          <div className="flex flex-row flex-nowrap items-center gap-[0.812rem]">
            <span>
              <img src={sofaIcon} alt="" />
            </span>
            <span className="font-bold text-[#707070] text-[0.875rem] slashed-zero">
              Quartos
            </span>
          </div>
          <input
            type="text"
            name=""
            id=""
            className="flex-1 max-h-full outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="Ex. 7"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            onChange={(event) => onDataHouse({
              bedrooms: Number(event.target.value || '')
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.bedrooms || ''}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='bedrooms' />
        </div>
        <div className="flex flex-col md:w-[11.125rem] max-sm:w-full h-[4.5rem]">
          <div className="flex flex-row flex-nowrap items-center gap-[0.812rem]">
            <span>
              <img src={banheiraIcon} alt="" />
            </span>
            <span className="font-bold text-[#707070] text-[0.875rem] slashed-zero">
              Banheiros
            </span>
          </div>
          <input
            type="text"
            name=""
            id=""
            className="flex-1 max-h-full outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="Ex. 7"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            onChange={(event) => onDataHouse({
              bathrooms: Number(event.target.value || '0')
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.bathrooms || ''}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='bathrooms' />
        </div>
        <div className="flex flex-col md:w-[11.125rem] max-sm:w-full h-[4.5rem]">
          <div className="flex flex-row flex-nowrap items-center gap-[0.812rem]">
            <span>
              <img src={carroIcon} alt="" />
            </span>
            <span className="font-bold text-[#707070] text-[0.875rem] slashed-zero">
              Vagas de garagem
            </span>
          </div>
          <input
            type="text"
            name=""
            id=""
            className="flex-1 max-h-full outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
            placeholder="Ex. 7"
            style={{
              borderBottom: '0.081rem solid #B8B9BA',
            }}
            onChange={(event) => onDataHouse({
              parking_spaces: Number(event.target.value || '0')
            })}
            defaultValue={(dataStep as unknown as IStepData<IMRealEstateProductsHouse>)?.data?.parking_spaces || ''}
            inputMode='numeric'
          />
          <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='parking_spaces' />
        </div>
      </div>
      <div className="flex flex-col gap-[2.187rem]">
        <div className="flex flex-col gap-[0.312rem]">
          <span className="text-[#414142] font-semibold text-[0.875rem]">
            Detalhes do imóvel
          </span>
          <span className="text-[#414142] text-[0.875rem] font-[400]">
            Selecione as opções aplicáveis ou adicione as que deseja
          </span>
        </div>

        <Option
          callerID="5"
          callerKey="home_options"
          dataStep={dataStep}
          onDataStream={onDataStream}
          callerPosition={housePosition}
        />
      </div>
    </div>
  );
}
