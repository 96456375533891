import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Icons
import adicionarCasaIcon from '~/assets/icons/adicionar-casa-produto-imobiliario.svg';
import homeIcon from '~/assets/icons/casa-produto-imobiliario.svg';
import dolarIcon from '~/assets/icons/dolar-produto-imobiliario.svg';
import editIcon from '~/assets/icons/edit-produto-imobiliario.svg';
import noImage from '~/assets/icons/no-image.svg';
import projetoIcon from '~/assets/icons/projeto-produto-imobiliario.svg';
import tornoIcon from '~/assets/icons/torno-produto-imobiliario.svg';

// Interfaces
import { IParams } from '../../../types';
import { IStepsProps, ITitleSteps } from './types';

// Utils
import { formatPrice } from '~/utils/format';

export function Step({
  step, standard, localization, name, average_age_residents, average_income_value, origin_residents, photo_upload_id, built_houses, quantity_terrains
}: IStepsProps): JSX.Element {
  const history = useHistory();
  const { id } = useParams<IParams>();

  // AUX Variables
  const ICON_STEP = (() => {
    let ICON_PATH = '';

    switch (step) {
      case 1:
        ICON_PATH = homeIcon;
        break;
      case 2:
        ICON_PATH = projetoIcon;
        break;
      case 3:
        ICON_PATH = tornoIcon;
        break;
      case 4:
        ICON_PATH = dolarIcon;
        break;
      case 5:
        ICON_PATH = adicionarCasaIcon;
        break;
      default:
        ICON_PATH = '';
    }

    return ICON_PATH;
  })();
  const CONTENT_STEP = (() => {
    let CONTENT: JSX.Element = <span />;

    switch (step) {
      case 1:
        CONTENT = (
          <div className="w-full flex flex-col justify-start gap-[0.625rem]">
            <span className="flex-1 flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA] gap-[0.3rem]">
              <span
                className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]"
                title="Tipo de Casa"
              >
                TC
              </span>
              <span title={standard?.standard || ''} className="font-semibold leading-[0.962rem] text-[0.875rem] flex-1 max-w-[60%] h-[1rem] cursor-default select-none overflow-hidden text-left text-ellipsis break-normal">
                {
                  standard?.standard || ''
                }
              </span>
            </span>
          </div>
        );
        break;
      case 2:
        CONTENT = (
          <div className="w-full flex flex-col justify-start gap-[0.625rem]">
            <span className="w-full flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA]">
              <span className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]">
                Nome
              </span>
              <span title={name || ''} className="flex-1 font-semibold leading-[0.962rem] text-[0.875rem] max-w-[50%] h-[1rem] cursor-default select-none overflow-hidden text-left text-ellipsis break-normal">
                {name || ''}
              </span>
            </span>
            <span className="flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA]">
              <span className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]">
                Endereço
              </span>
              <span title={localization || ''} className="flex-1 max-w-[50%] h-[1rem] font-semibold leading-[0.962rem] text-[0.875rem] cursor-default select-none overflow-hidden text-left text-ellipsis break-normal">
                {localization || ''}
              </span>
            </span>
            <div className="w-full h-[5.375rem] flex flex-col items-center justify-center bg-gray-100 rounded-[0.312rem] overflow-hidden object-cover">
              {
                !photo_upload_id && (
                  <img
                    src={noImage}
                    alt="Imagem do projeto"
                    className="max-w-[3.187rem] h-auto"
                  />
                )
              }
              {
                photo_upload_id && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/builders/real-estate-products/${id}/photo`}
                    alt="Imagem do projeto"
                    className="max-w-full h-auto object-cover"
                  />
                )
              }
            </div>
          </div>
        );
        break;
      case 3:
        const t = (quantity_terrains || 0) - (built_houses || 0)
        const y = t > 0 ? t / 100 : t
        const d = quantity_terrains && quantity_terrains > 0 ? quantity_terrains / 100 : quantity_terrains
        const g = `h-[${d}rem]`
        const w = `h-[${y}rem]`

        CONTENT = (
          <div className="w-full flex-1 flex flex-col justify-start gap-[0.625rem]">
            <div className='w-full flex-1 flex flex-col items-start justify-end'>
              <span className='w-full flex-1 max-h-[8.625rem] flex flex-row items-end justify-center gap-[1rem] overflow-hidden'>
                <span className={`w-[1.5rem] ${g} bg-[#FFBA69] cursor-default select-none block`} style={{
                  height: `${d}rem`
                }} />
                <span className={`w-[1.5rem] ${w} bg-[#01CC9D] cursor-default select-none block`} style={{
                  height: `${y}rem`
                }} />
              </span>
              <span className='w-full flex flex-col items-start justify-start text-[#B8B9BA]'>
                <span className='flex flex-row flex-nowrap items-center justify-between w-full'>
                  <span className='flex flex-row items-center gap-[0.312rem]'>
                    <span className='w-[0.687rem] h-[0.687rem] rounded-full bg-[#FFBA69] cursor-default select-none block' />
                    <span className='text-[0.875rem] font-[400] leading-normal tracking-[-0.021rem]'>Lotes de condomínio</span>
                  </span>
                  <span className='text-[1.062rem] font-semibold leading-[1.375rem] tracking-[-0,025rem]'>{quantity_terrains}</span>
                </span>

                <span className='flex flex-row flex-nowrap items-center justify-between w-full'>
                  <span className='flex flex-row items-center gap-[0.312rem]'>
                    <span className='w-[0.687rem] h-[0.687rem] rounded-full bg-[#01CC9D] cursor-default select-none block' />
                    <span className='text-[0.875rem] font-[400] leading-normal tracking-[-0.021rem]'>Casas construídas</span>
                  </span>
                  <span className='text-[1.062rem] font-semibold leading-[1.375rem] tracking-[-0.025rem]'>{built_houses}</span>
                </span>
              </span>
            </div>
          </div>
        );
        break;
      case 4:
        CONTENT = (
          <div className="w-full flex flex-col justify-start gap-[0.625rem]">
            <span className="flex-1 flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA]">
              <span
                className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]"
                title="Valor de Renda Medio"
              >
                VR. medio
              </span>
              <span className="flex-1 font-semibold leading-[0.962rem] text-[0.875rem] max-w-[50%] h-[1rem] cursor-default select-none overflow-hidden text-left text-ellipsis break-normal">
                {formatPrice(average_income_value || 0.00)}
              </span>
            </span>
            <span className="flex-1  w-full flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA]">
              <span
                className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]"
                title="Idade Media dos Moradores"
              >
                IM. moradores
              </span>
              <span className="font-semibold leading-[0.962rem] text-[0.875rem] flex-1 max-w-[50%] h-[1rem] text-left">
                {average_age_residents || 0}
              </span>
            </span>
            <span className="flex-1 flex flex-row flex-nowrap items-center justify-between text-[#B8B9BA]">
              <span className="text-[0.875rem] text-[#B8B9BA] leading-[0.9625rem]">
                Região
              </span>
              <span className="flex-1 font-semibold leading-[0.962rem] text-[0.875rem] max-w-[50%] h-[1rem] cursor-default select-none overflow-hidden text-left text-ellipsis break-normal">
                {
                  origin_residents || ''
                }
              </span>
            </span>
          </div>
        );
        break;
      case 5:
        CONTENT = <span />;
        break;
      default:
        CONTENT = <span />;
    }

    return CONTENT;
  })();
  const TITLE_STEP: ITitleSteps = {
    1: 'Produto imobiliário',
    2: 'Projeto',
    3: 'Em torno',
    4: 'Dados regionais',
    5: 'Casas adicionadas',
  };

  function handleToPage() {
    try {
      history.push(`/produtos-imobiliario/novo-projeto/${id}/${step}`);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel iniciar o processo de criação de projeto',
        icon: 'warning',
      });
    }
  }

  return (
    <div
      className="max-sm:flex-1 max-sm:w-[9.687rem] md:w-[15.625rem] md:min-h-[3.312rem] md:h-auto rounded-[0.937rem] bg-white
      shadow-sm pt-[2.5rem] pl-[1.25rem] pr-[1.25rem] pb-[1.25rem] flex flex-col items-start justify-start gap-[0.937rem]
      max-sm:border-[0.1rem] max-sm:border-[#e0e0e0]"
    >
      <div className="w-100 flex flex-row flex-nowrap items-center justify-between">
        <span>
          <img src={ICON_STEP} alt="icone generico de uma casa em 2d" />
        </span>
        <button
          onClick={handleToPage}
          className="cursor-pointer outline-none border-none"
          type="button"
          title={`Editar ${TITLE_STEP[step]}`}
        >
          <img src={editIcon} alt="icone generico de uma caneta em 2d" />
        </button>
      </div>
      <div className="w-100 flex-1 flex flex-col items-start justify-start gap-[0.625rem]">
        <span className="font-semibold text-[1.125rem] text-[#414142]">
          {TITLE_STEP[step]}
        </span>

        {CONTENT_STEP}
      </div>
    </div>
  );
}
