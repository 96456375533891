/* FIXEM - eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { IMBoard } from '~/models/Board';
import { IMTask } from '~/models/BoardTask';
import Boards from '~/pages/Kanban/Components/Boards';
import api from '~/services/api';
import { useKanban } from '../../Hooks/useKanban';
import { Container } from './styles';

const BoxContainer: React.FC = () => {
  const { columns, setColumns } = useKanban();
  const [showUIElements, setShowUIElements] = useState(false);

  const saveOrderBoard = useCallback((board_id, order) => {
    api.patch(
      `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${board_id}/${order}`,
      { ordem: order }
    );
  }, []);

  const moveTask = useCallback(
    async (board_id: number, task_id?: number, order?: number) => {
      api.patch(
        `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${board_id}/tasks/${task_id}/${order}`
      );
    },
    []
  );

  const handleDeleteTask = useCallback(async (id?: number) => {
    try {
      await api.delete(
        `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/tasks/${id}`
      );
    } catch (err) {
      //
    }
  }, []);

  const handleCreateTask = useCallback(
    async (dragCard: IMTask, board_id: number, order: number) => {
      try {
        const formData = {
          os_id: dragCard.os_id,
          ordem: order,
          quadro_id: board_id,
        };
        await api.post(
          `${process.env.REACT_APP_PREFIX_ROUTE}/boards/${board_id}/tasks`,
          formData
        );
      } catch (err) {
        //
      }
    },
    []
  );

  const handleMoveBoard = useCallback(
    (result: any) => {
      const { draggableId } = result;

      if (result.source.index === 0 || result.destination.index === 0) return;
      const columns_aux = Array.from(columns);
      const [reorderedItem] = columns_aux.splice(result.source.index, 1);
      columns_aux.splice(result.destination.index, 0, reorderedItem);
      setColumns(columns_aux);
      saveOrderBoard(draggableId, result.destination.index - 1);
    },
    [columns, setColumns, saveOrderBoard]
  );

  const handleDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;

      const {
        destination: { droppableId: destDroppableId, index: destIndex },
        source: { droppableId: sourceDroppableId, index: sourceIndex },
        draggableId,
      } = result;

      if (destDroppableId === 'boxOSs') {
        handleMoveBoard(result);
        return;
      }

      const sourceList: IMBoard = columns.find(
        (list: { id: any }) => list.id === Number(sourceDroppableId.replace('-list', ''))
      ) || ({} as IMBoard);

      const destList: IMBoard = columns.find(
        (list: { id: any }) => list.id === Number(destDroppableId.replace('-list', ''))
      ) || ({} as IMBoard);

      const dragCard: IMTask = sourceList.tasks.find(
        (card, i) => card?.id === Number(draggableId.split('-')[1])
          && sourceList.id === Number(draggableId.split('-')[0])
          && sourceIndex === i
      ) || ({} as IMTask);

      if (sourceDroppableId === destDroppableId) {
        const cards_aux: IMTask[] = Array.from(sourceList.tasks);
        const reorderedItem: IMTask[] = cards_aux.splice(sourceIndex, 1);
        cards_aux.splice(destIndex, 0, reorderedItem[0]);
        sourceList.tasks = cards_aux;
        moveTask(sourceList.id, dragCard.id, destIndex);
      } else {
        sourceList.tasks.splice(sourceIndex, 1);
        destList.tasks.splice(destIndex, 0, dragCard);

        if (destList.id === 0) {
          handleDeleteTask(dragCard.id);
        } else {
          sourceList.id !== 0
            ? moveTask(destList.id, dragCard.id, destIndex)
            : handleCreateTask(dragCard, destList.id, destIndex);
        }
      }
    },
    [handleMoveBoard, columns, handleCreateTask, handleDeleteTask, moveTask]
  );

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setShowUIElements(true);
    }
  }, []);

  const aux = columns.map((column) => column?.tasks?.length || 0);

  return (
    <Container>
      <div className="box-column">
        <div className="px-3">
          {showUIElements && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId="boxOSs"
                type="list"
                direction="horizontal"
              >
                {(provided: any) => (
                  <Container
                    color={aux.length}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="row scroll mt-5 gap gap-2 col-12 w-100 position-relative">
                      {columns.map((column, i: number) => (
                        <Boards
                          key={Number(column?.id)}
                          data={column}
                          index={i}
                        />
                      ))}
                    </div>
                  </Container>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </Container>
  );
};

export default BoxContainer;
