import React, { useCallback, useState } from 'react';
import { RiInformationFill } from 'react-icons/ri';

import { addYears, format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSimulations } from '~/hooks/Simulations';
import { IMSimulation } from '~/models/Simulation';
import { IParams } from '~/models/Simulation/Result';
import formatCurrency from '~/utils/formatCurrency';

import ModalDetails from './ModalDetails';
import { Amortization, ButtonIcon } from './styles';

const CardsAmortization: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const [showDetailAmortization, setShowDetailAmortization] = useState(false);
  const { simulations } = useSimulations();
  const { serviceOrder, setServiceOrder } = useServiceOrder();

  const handleClickAmortization = useCallback(
    (simulationData: IMSimulation) => {
      let vlrEntrada = 0;
      const simulation = { ...serviceOrder.simulacao, ...simulationData };
      if (
        serviceOrder?.finalidade_id === 3
        || serviceOrder?.finalidade_id === 4
      ) {
        const p = 100 - (simulation.amortizacao?.funding_limit || 100);
        vlrEntrada = ((simulation.vlrImovel || 0) * p) / 100;
      }
      setServiceOrder({
        ...serviceOrder,
        simulacao: {
          ...simulation,
          vlrEntrada,
        },
      });
      if (params.osId !== undefined) {
        history.push(
          `${process.env.PUBLIC_URL}/simulador/resumo/${params.osId}`
        );
      } else {
        history.push(`${process.env.PUBLIC_URL}/simulador/resumo/`);
      }
    },
    [setServiceOrder, serviceOrder, params.osId, history]
  );

  return (
    <>
      {simulations?.map((simulation) => (
        <div className="col-auto mb-5 mt-5" key={simulation.amortizacao_id}>
          <div
            key={simulation.id}
            className="px-2"
            role="presentation"
            onClick={() => {
              handleClickAmortization(simulation);
            }}
            id="amortization-card"
          >
            <Amortization
              highlight={simulation.amortizacao?.enfase}
              className="px-3 py-5 mb-2"
              titleColor={simulation.amortizacao?.color}
            >
              <div className="d-flex justify-content-between">
                <h2 className="h5 mb-0 fw-semibold">
                  {simulation.amortizacao?.amortizacao}
                </h2>
                <ButtonIcon>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={() => setShowDetailAmortization(true)}
                  >
                    <RiInformationFill size={22} />
                  </button>
                </ButtonIcon>
              </div>
              <div className="mt-4 mb-5">
                <div className="d-flex align-items-center mb-2">
                  <p className="h4 text-dark-gray mb-0 fw-semibold">
                    {formatCurrency(simulation.vlrFinanciado || 0)}
                  </p>
                  <span className="fw-medium ms-2 rate">
                    {simulation.taxa} a.a
                  </span>
                </div>
                <span>Valor total financiado</span>
              </div>
              <div>
                <div className="d-flex pb-3 position-relative">
                  <div className="d-flex justify-content-center chain chain-top my-2" />
                  <div className="installment d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Primeira Parcela</p>
                      <small>
                        {formatCurrency(simulation.vlrParcInic || 0)}
                      </small>
                    </div>
                    <div>
                      <p className="h5 px-2">
                        {format(new Date(), 'dd/MM/yyyy')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex pt-3 position-relative">
                  <div className="d-flex justify-content-center chain chain-bottom my-auto" />
                  <div className="installment d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Última Parcela</p>
                      <small>
                        {formatCurrency(simulation.vlrParcFinal || 0)}
                      </small>
                    </div>
                    <div>
                      <p className="h5">
                        {format(
                          addYears(new Date(), simulation.prazo || 0),
                          'dd/MM/yyyy'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="d-flex align-items-center border-0 bg-transparent mt-5"
              >
                <span className="span_icon">Saiba mais → </span>
              </button>
            </Amortization>
          </div>
        </div>
      ))}

      <ModalDetails
        showDetailsAmortization={showDetailAmortization}
        handleCloseDetails={() => setShowDetailAmortization(false)}
      />
    </>
  );
};

export default CardsAmortization;
