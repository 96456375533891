import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';

import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import TextArea from '~/components/Textarea';

import Input from '~/components/Input';

import { AxiosError } from 'axios';
import InputMask from '~/components/InputMask';
import { useProfile } from '~/hooks/Profile';
import { IMEngineer } from '~/models/Engineer';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';
import { Container } from './styles';

const FormEngineer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { engineer, setEngineer, setLoading } = useProfile();
  const [colorWarningTextArea, setColorWarningTextArea] = useState(
    'caract-count text-end d-block'
  );
  const [counterTextArea, setCounterTextArea] = useState(0);
  const [errorInputName, setErrorInputName] = useState('');
  const [errorInputCpf, setErrorInputCpf] = useState('');

  // FIXME - eslint-disable-next-line consistent-return
  const handleValidateCPF = useCallback((value: string) => {
    setErrorInputCpf('');
    if (value.replace(/\D/g, '').length < 11) return false;
    cpfValidator.isValid(value.replace(/\D/g, ''))
      ? setErrorInputCpf('')
      : setErrorInputCpf('CPF inválido');
  }, []);

  const handleValidateName = useCallback((value: string) => {
    setErrorInputName('');
    value.trim().split(' ').length > 1
      ? setErrorInputName('')
      : setErrorInputName('Informe o nome completo');
  }, []);

  const handleCounterTextArea = useCallback((value: string) => {
    setCounterTextArea(value.trim().split('').length);

    if (value.trim().split('').length > 80) {
      if (value.trim().split('').length > 100) {
        setColorWarningTextArea('caract-count text-end d-block text-danger');
      } else {
        setColorWarningTextArea('caract-count text-end d-block text-warning');
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IMEngineer) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const errorOfCpf = errorInputCpf;
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          celular: Yup.string()
            .required('Telefone obrigatório')
            .min(14, 'O telefone completo é obrigatório'),
          cpf: Yup.string()
            .required('CPF obrigatorio')
            .min(14, 'O CPF completo é obrigatório')
            .test({
              test(value, ctx) {
                if (errorOfCpf.length > 0) {
                  return ctx.createError({ message: errorOfCpf });
                }

                return true;
              },
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (errorInputCpf.length === 0 && errorInputName.length === 0) {
          setErrorInputName('');
          setErrorInputCpf('');
          if (!engineer) {
            await api.post<IMEngineer>(
              `${process.env.REACT_APP_PREFIX_ROUTE}/engineer`,
              data
            );
          } else {
            await api.put<IMEngineer>(
              `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/${engineer?.id}`,
              data
            );
          }
          setLoading(false);
          Toast.fire({
            icon: 'success',
            title: !engineer
              ? 'Cadastro realizado com sucesso!'
              : 'Atualização de cadastro realizado com sucesso!',
          });
          setEngineer(data);
        }
        // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
        } else {
          let message = `Ocorreu um erro ao salvar seus dados.`;
          const errors = ((err as AxiosError)?.response?.data as any).errors as any
          
          if(!errors || (errors && Object.keys(errors).length <= 0)) {
             Toast.fire({
                icon: 'error',
                title: message,
              });

              return
          }

          Object.keys(errors).forEach((key) => {            
              Toast.fire({
                icon: 'error',
                title: errors[key] || message,
              });
          })
        }
      }
    },
    [setLoading, engineer, errorInputCpf, errorInputName, setEngineer]
  );

  return (
    <Container className="row mx-sm-auto mx-lg-n2 mb-3">
      <Form
        className="row mx-0"
        ref={formRef}
        initialData={engineer}
        onSubmit={handleSubmit}
      >
        <div className="container px-3 py-0">
          <div className="row">
            <div className="col-sm-6 col-5 mt-5 mb-3">
              <label className="w-100">
                <span className="small text-subtitle">
                  Nome e sobrenome <span className="text-danger">*</span>
                  <span />
                </span>
                <Input
                  className="input mt-1"
                  placeholder="Nome Completo"
                  name="nome"
                  onChange={(e) => handleValidateName(e.target.value)}
                />
                {errorInputName && (
                  <span className="small text-danger error">
                    {errorInputName}
                  </span>
                )}
              </label>
              <hr className="sub-bar mt-0" />
            </div>
            <div className="col-sm-6 col-5 mt-5 mb-3">
              <label className="w-100">
                <span className="small text-subtitle">
                  CPF <span className="text-danger">*</span>
                  <span />
                </span>
                <InputMask
                  className="input mt-1"
                  placeholder="000.000.000-00"
                  kind="cpf"
                  name="cpf"
                  value={engineer?.cpf || ''}
                  onChange={(e) => handleValidateCPF(e.target.value)}
                />
                {errorInputCpf && (
                  <span className="small text-danger error">
                    {errorInputCpf}
                  </span>
                )}
              </label>
              <hr className="sub-bar mt-0" />
            </div>
            <div className="col-sm-6 col-5 mb-3">
              <label className="w-100">
                <span className="small text-subtitle">
                  Profissão
                  <span />
                </span>
                <Input
                  className="input mt-1"
                  placeholder="Digite"
                  type="text"
                  name="profession"
                />
              </label>
              <hr className="sub-bar mt-0" />
            </div>
            <div className="col-sm-6 col-5 mb-3">
              <label className="w-100">
                <span className="small text-subtitle">
                  Telefone/ Celular <span className="text-danger">*</span>
                  <span />
                </span>
                <InputMask
                  className="input mt-1"
                  placeholder="(  ) "
                  name="celular"
                  kind="cel-phone"
                  value={engineer?.celular || ''}
                />
              </label>
              <hr className="sub-bar mt-0" />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="w-100">
                <span className="small text-subtitle">
                  Curta descrição sobre você
                  <span />
                </span>
                <TextArea
                  className="input-text-area mt-1"
                  placeholder="Digite"
                  name="description"
                  maxLength={140}
                  // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => handleCounterTextArea(e.target.value)}
                />
              </label>
              <hr className="sub-bar" />
              <small className={colorWarningTextArea}>
                {counterTextArea}/140
              </small>
            </div>
            <div className="col-lg-12 mb-3 d-flex justify-content-center">
              <div>
                <button type="submit" className="btn-save fw-semibold border-0">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default FormEngineer;
