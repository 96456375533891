import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// Icons
import addHomeWork from '~/assets/icons/add-home-work.svg';

// Interfaces
import { IStepData } from '../../types';
import { IHomeProps, IParams } from './types';

// Partials
import { Step } from './Partials/Step';

// Models
import { useParams } from 'react-router-dom';
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';

// Partials
import { Box } from './Partials/Box';

export function Home(props: IHomeProps) {
  const { id: idProject, toHouse } = useParams<IParams>();
  const [start, setStart] = useState<boolean>(false);
  const [housePosition, setHousePosition] = useState(-1);
  const [rollbackToHouse, setRollbackToHouse] = useState<boolean>(true);

  useEffect(() => {

    if(props.housesOnLoading) return

    if(((props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length || -1) <= 0) {
      handleSetHousePosition(0)
    }

    // console.table(props.dataStep)
    // console.log(((props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length || -1) <= 0)
  },[props.housesOnLoading])

  useEffect(() => {
    if(props.housesOnLoading) return;
    
    if (!start) {
      props.enableDataDeletesButtonFunction!(false, -1);
      props.enableDataUpdateButtonFunction!(false, -1);

      setStartHousePosition();
    }
  }, [start,props.housesOnLoading]);

  // ESTE BLOCO RODA EM ORDERM DE RENDERIZAÇÃO, APENAS NA PRIMEIRA VEZ E APÒS ALGUMA MUDANÇA EM housePosition, start e props.dataStep
  useEffect(() => {

    if (!start) return

    props.enableDataDeletesButtonFunction!(false, -1);
    props.enableDataUpdateButtonFunction!(false, -1);

    const houseDataAlready = (props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])[housePosition]?.data

    if (!houseDataAlready || !houseDataAlready?.id) return

    // console.table({
    //   idProject, housePosition, start, data: (props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])[housePosition]?.data
    // })

    if ((idProject || props.idProject) && housePosition >= 0) {
      props.enableDataDeletesButtonFunction!(true, housePosition);
      props.enableDataUpdateButtonFunction!(true, housePosition);
    }
  }, [housePosition, start, idProject, props.dataStep]);

  useEffect(() => {
    props.onSetActualHousePositionToUpdate!(housePosition)

    // if (housePosition >= 0 && (props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])[housePosition]?.data) {
    //   props.onSetActualHousePositionToUpdate!(housePosition)
    // } else {
    //   props.onSetActualHousePositionToUpdate!(-1)
    // }

  }, [housePosition]);

  useEffect(() => {
    if (props.processRestart && start) setStart(false);
  }, [props.processRestart]);

  function handleSetStartProcess() {
    try {
      setStart((val) => !val);
    } catch (error) {
      Swal.fire({
        text: 'Não foi possivel iniciar o processo',
        icon: 'warning',
      });
    }
  }

  /**
   * Set a posição da casa selecionada pelo usuário
   * @param position 
   * @param name 
   */
  function handleSetHousePosition(position: number = -1, name: string = '') {
    try {
      if (position <= -1) throw new Error('');

      setHousePosition(position);

      if (position >= 0) handleSetStartProcess();
    } catch (error) {
      Swal.fire({
        text: `Não foi possivel selecionar a casa ${name}`,
        icon: 'warning'
      });
    }
  }

  /**
   * Set um valor posicional default da nova casa
   */
  function setStartHousePosition() {
    try {
      const houseLength = (props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length || -1
          
      if (houseLength >= 1) {
        setHousePosition(
          (props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length
        );
      } else if (houseLength <= 0) {
        setHousePosition(0);
      }

      if ((toHouse as unknown as number) >= 0 && rollbackToHouse) {
        setHousePosition(Number(toHouse));
        setRollbackToHouse(false);
        handleSetStartProcess();
      }

      console.log('setStartHousePosition', houseLength, toHouse)
    } catch (error) {
      // do anything
    }
  }

  return (
    <div className="w-full md:min-h-[33.105rem] h-auto flex flex-col items-start justify-start transition-colors duration-[0.3s] max-sm:p-[1rem]">
      <></>
      {!start && (
        <>        
          {
            (props.dataStep as IStepData<IMRealEstateProductsHouse>[]).length <= 0 && !props.housesOnLoading && (
              <div className="flex-1 flex flex-col items-center justify-center w-full max-h-[16.125rem] gap-[1.875rem]">
                <span>
                  <img src={addHomeWork} alt="" />
                </span>
                <span className="font-[400] text-[#B8B9BA]">
                  Inserir pelo menos 10 casas para o sistema calcular
                </span>
                <div className={`flex flex-row flex-wrap gap-y-[1.875rem] gap-x-[1.4rem] w-full h-auto justify-center`}>
                  <button
                    onClick={handleSetStartProcess}
                    type="button"
                    title="Adicionar informações da nova casa"
                    className={`rounded-[0.937rem] flex-1 min-w-[20.687rem] max-w-[20.687rem] h-[3.375rem] flex flex-row items-center justify-center bg-none outline-none ${(props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length > 0 && 'self-center'}
                    border-[0.1rem] border-[#39A887] font-bold text-[#39A887] group hover:border-[#47c49e] transition-colors duration-[0.3s]`}
                  >
                    <span className="group-hover:text-[#47c49e] transition-colors duration-[0.3s]">
                      Nova casa
                    </span>
                  </button>
                </div>
              </div>
            )
          }

          {
            (props.dataStep as IStepData<IMRealEstateProductsHouse>[]).length >= 1 && !props.housesOnLoading && <>
              <span className="font-[400] text-[#B8B9BA]">
                Inserir pelo menos 10 casas para o sistema calcular
              </span>
              <div className={`flex md:flex-row md:flex-wrap max-sm:flex-col md:gap-y-[1rem] max-sm:gap-[1.875rem] md:gap-x-[4.3rem] w-full h-full overflow-y-auto  justify-sttart pt-[1.875rem] pb-[1.875rem]`}>
                {
                  props.dataStep
                  && (props.dataStep as IStepData<IMRealEstateProductsHouse>[])
                    ?.map((house, index) => (
                      <Box.Home {...house.data} index={index} onSetHousePosition={handleSetHousePosition} />
                    ))
                }

                <button
                  onClick={handleSetStartProcess}
                  type="button"
                  title="Adicionar informações da nova casa"
                  className={`rounded-[0.937rem] flex-1 md:min-w-[14.75rem] md:max-w-[14.75rem] md:h-[3.375rem] max-sm:w-full max-sm:min-h-[3.375rem] max-sm:max-h-[3.375rem] mt-[1.875rem] flex flex-row items-center justify-center bg-none outline-none ${(props.dataStep as unknown as IStepData<IMRealEstateProductsHouse>[])?.length <= 0 && 'self-center'}
                    border-[0.1rem] border-[#39A887] font-bold text-[#39A887] group hover:border-[#47c49e] transition-colors duration-[0.3s]`}
                >
                  <span className="group-hover:text-[#47c49e] transition-colors duration-[0.3s]">
                    Adicionar nova casa
                  </span>
                </button>
                
              </div>
            </>
          }

          {
            (props.dataStep as IStepData<IMRealEstateProductsHouse>[]).length <= 0 && props.housesOnLoading && (<>
              <div className={`flex md:flex-row md:flex-wrap max-sm:flex-col md:gap-y-[1.875rem] max-sm:gap-[1.875rem] md:gap-x-[4.3rem] w-full h-full overflow-y-auto  justify-sttart pt-[1.875rem] pb-[1.875rem]`}>
                <Box.Mock />
                <Box.Mock />
                <Box.Mock />
                <Box.Mock />
              </div>
            </>)
          }
        </>
      )}


      {
        start
        && (
          <Step
            {...props}
            housePosition={housePosition}
            onStartProcess={handleSetStartProcess}
          />
        )
      }
    </div>
  );
}
