import React from 'react';

import UserDefault from '~/components/UserDefault';
import { KanbanProvider } from './Hooks/useKanban';
import { Container } from './styles';
import ButtonAdd from './Components/ButtonAdd';
import BoxContainer from './Components/BoxContainer';

const Kanban: React.FC = () => (
  <KanbanProvider>
    <Container className="ms-lg-5 mb-3">
      <div className="py-4" />
      <div className="mt-5 mt-lg-0">
        <div className="container mt-5 mt-lg-0">
          <UserDefault fluxo />
          <div className="ms-lg-5">
            <p>
              <p className="mb-0">
                Gerencie qualquer processo de trabalho do início ao fim.
              </p>{' '}
              Acompanhe os projetos, contatos e pedidos em um quadro simples.
            </p>
          </div>
          <ButtonAdd />
          <BoxContainer />
        </div>
      </div>
    </Container>
  </KanbanProvider>
);

export default Kanban;
