import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IParams } from '~/models/Simulation/Result';
import getValidationErros from '~/utils/getValidationsErrors';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import { IMSimulation } from '~/models/Simulation';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useDefaultData } from '~/hooks/DefaultData';
import formatCurrency from '~/utils/formatCurrency';

import { Container, Modal } from './styles';

const AddInformation: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [showVideo, setShowVideo] = useState(false);
  const [showDetail2, setShowDetail2] = useState(false);
  const [simulation, setSimulation] = useState<IMSimulation>(
    {} as IMSimulation
  );
  const [amortizationsSelect, setAmortizationsSelect] = useState<IOption[]>(
    [] as IOption[]
  );
  const { amortizations } = useDefaultData();

  useEffect(() => {
    setSimulation({ ...serviceOrder.simulacao });
  }, [serviceOrder.simulacao]);

  const handleCloseDetail2 = useCallback(() => {
    setShowDetail2(false);
  }, []);

  const handleChangeAmortType = useCallback(
    (option?: IOption) => {
      const newAmortization = amortizations.map((amortization) => ({
        id: amortization.id,
        value: amortization.amortizacao,
        selected: amortization.id === option?.id,
      }));
      setAmortizationsSelect(newAmortization);
    },
    [setAmortizationsSelect, amortizations]
  );

  useEffect(() => {
    if (amortizations.length > 0) {
      handleChangeAmortType();
    }
  }, [amortizations, handleChangeAmortType]);

  const handleSubmit = useCallback(
    async (data: IMSimulation) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          vlrImovPronto: Yup.string().required(
            'O Valor do Imóvel Pronto é obrigatório'
          ),
          vlrFinanciado: Yup.string().required(
            'O Valor Financiado é obrigatório'
          ),
          taxa: Yup.string().required('A Taxa é obrigatória'),
          prazo: Yup.string().required('O Prazo é obrigatório'),
          vlrEntrada: Yup.string().required('O Valor de entrada é obrigatório'),
          vlrEntradaReal: Yup.string().required(
            'O Valor de entrada real é obrigatório'
          ),
          vlrParcInic: Yup.string().required(
            'O Valor da Primeira parcela é obrigatório'
          ),
          vlrParcFinal: Yup.string().required(
            'O Valor da última parcela é obrigatório'
          ),
          amortizacao_id: Yup.string().required('A amortização é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const amortization = amortizations.find(
          (amortizationData) => amortizationData.id
            === amortizationsSelect.find(
              (amortizationSelected) => amortizationSelected.selected
            )?.id
        );

        setServiceOrder({
          ...serviceOrder,
          simulacao: {
            ...data,
            amortizacao: amortization,
            amortizacao_id: amortization?.id,
          },
        });

        if (params.osId !== undefined) {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/${params.osId}`
          );
        } else {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento`
          );
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [
      history,
      params.osId,
      setServiceOrder,
      serviceOrder,
      amortizationsSelect,
      amortizations,
    ]
  );

  return (
    <Container>
      <div className="row mt-4 px-5">
        <p className="text-gray mb-4">
          Para nossa equipe analisar o perfil do seu financiamento solicitado
          iremos precisar dos dados a seguir
        </p>
      </div>
      <div className="d-flex align-items-center mb-1 px-5">
        <p className="mb-0 me-2 fw-semibold">
          Quer aumentar o valor ou não sabe qual escolher?{' '}
          <button
            type="button"
            className="button_clickeame border-0 bg-transparent text-primary fw-semibold"
            onClick={() => setShowVideo(true)}
          >
            <u>Clique aqui!</u>
            <BsFillCameraVideoFill size={18} color="#39A887" className="ms-2" />
          </button>
        </p>
      </div>
      {/* <div className="d-flex align-items-center px-5">
        <p className="mb-0 fw-semibold">
          Adicionar informações da Caixa{' '}
          <button
            type="button"
            className="button_clickeame bg-transparent text-primary fw-semibold border-0"
            onClick={() => setShowDetail2(true)}
          >
            <BsPlusCircleFill className="mb-1 mx-2" size={20} />
          </button>
        </p>
      </div> */}
      <Modal
        show={showVideo}
        onHide={() => setShowVideo(false)}
        size="lg"
        centered
      >
        <Modal.Body className="p-0">
          <iframe
            src="https://www.youtube.com/embed/ZSo5L3eiylg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height="450"
            className="w-100 mb-n2"
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showDetail2}
        onHide={handleCloseDetail2}
        close
        size="lg"
        centered
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className="col-12 p-4"
          initialData={simulation}
        >
          <Modal.Header closeButton>
            <div>
              <h2>Adicione suas Informações da CAIXA</h2>
            </div>
          </Modal.Header>
          <Modal.Body className="p-5">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <label className="w-100">Valor Imóvel pronto</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrImovPronto"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrImovPronto
                      ? formatCurrency(simulation.vlrImovPronto)
                      : ''
                  }
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label className="w-100">Valor financiado</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrFinanciado"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrFinanciado
                      ? formatCurrency(simulation.vlrFinanciado)
                      : ''
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3 mt-3">
                <label className="w-100">Prazo (em meses)</label>
                <Input
                  type="number"
                  className="input mt-2"
                  name="prazo"
                  placeholder="Ex: 24"
                  min={0}
                />
              </div>
              <div className="col-lg-6 mb-3 mt-3">
                <label className="w-100">Taxa</label>
                <InputMask
                  className="input mt-2"
                  kind="custom"
                  options={{
                    mask: '99,99',
                  }}
                  name="taxa"
                  placeholder="00,00"
                  value={
                    simulation && simulation.taxa
                      ? String(simulation.taxa).replace('.', ',')
                      : ''
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">Valor de entrada</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrEntrada"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrEntrada
                      ? formatCurrency(simulation.vlrEntrada)
                      : ''
                  }
                />
              </div>
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">Valor de entrada real</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrEntradaReal"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrEntradaReal
                      ? formatCurrency(simulation.vlrEntradaReal)
                      : ''
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">Valor do FGTS</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrFgts"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrFgts
                      ? formatCurrency(simulation.vlrFgts)
                      : ''
                  }
                />
              </div>
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">
                  Amortização
                  <Select
                    name="amortizacao_id"
                    placeholder="Selecione"
                    className="input mt-2"
                    options={amortizationsSelect}
                    onChange={() => handleChangeAmortType()}
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">Primeira parcela</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrParcInic"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrParcFinal
                      ? formatCurrency(simulation.vlrParcFinal)
                      : ''
                  }
                />
              </div>
              <div className="col-lg-6 mb-3 mt-4">
                <label className="w-100">Última parcela</label>
                <InputMask
                  className="input mt-2"
                  kind="money"
                  name="vlrParcFinal"
                  placeholder="R$ 0,00"
                  value={
                    simulation && simulation.vlrParcFinal
                      ? formatCurrency(simulation.vlrParcFinal)
                      : ''
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-none justify-content-center">
            <div className="buttons-group">
              <button type="submit" className="btn btn-primary w-auto py-2">
                Salvar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default AddInformation;
