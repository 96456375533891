import React, { useState } from 'react';
import { IoAdd } from 'react-icons/io5';

import ModalAdd from './ModalAdd';
import { Container, Modal } from './styles';

const ButtonAdd: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Container className="mb-3">
      <button
        className="btn-add-column"
        onClick={() => setShowModal(true)}
        type="button"
      >
        <IoAdd size={24} color="#fff" />
        Novo Quadro
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <ModalAdd handleClose={() => setShowModal(false)} />
      </Modal>
    </Container>
  );
};

export default ButtonAdd;
