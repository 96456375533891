import styled from 'styled-components';

export const Container = styled.div`
  .text-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    font-feature-settings: 'zero' on;

    color: #707070;
  }
  .text-description {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    letter-spacing: -0.408px;

    /* gray */

    color: #414142;
  }
  @media screen and (max-width: 1199px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`;
