// Interfaces
import { IStepsProps } from './Step/types';

// Partials
import { IMRealEstateProductsHouse } from '~/models/RealEstateProductHouse';
import { House } from './House';
import { Mock } from './Mock';
import { Step } from './Step';

export const Box = {
  Mock: (): JSX.Element => <Mock />,
  Step: (args: IStepsProps): JSX.Element => <Step {...args} />,
  House: (args: IMRealEstateProductsHouse & { housePosition: number, projectID: string }): JSX.Element => (
    <House {...args} />
  ),
};
