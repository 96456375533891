import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import InputMask from '~/components/InputMask';
import { IOption } from '~/components/Select';
import { ISimulation, useSimulation } from '~/hooks/Simulation';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import getValidationErros from '~/utils/getValidationsErrors';

import InputRadio from '~/components/InputRadio';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { Modal } from './styles';

interface IAmort2 {
  id: number;
}

interface IAmorts {
  amortization_Types: IAmort2[];
}

interface IParams {
  builderId?: string;
  bdi?: string;
  osId: string;
}

interface IDataForm {
  revenue: number;
  birthdate: Date;
  property_type?: string;
  property_price: number;
  // amortization: {
  id: number;
  // title: string;
  financedValue: string;
  rate: string;
  entryValue: string;
  realEntryValue: string;
  fgtsAmount?: string;
  firstInstallment: string;
  lastInstallment: string;
  deadline: number;
  readyValue: string;
  // };
}

interface IModalInformation {
  showDetail: boolean;
  handleCloseDetail: () => void;
}

const ModalInformation: React.FC<IModalInformation> = ({
  showDetail: showDetail2,
  handleCloseDetail: handleCloseDetail2,
}) => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { simulation, setSimulation } = useSimulation();

  const { serviceOrder } = useServiceOrder();
  const [typesAmortization, setTypesAmortization] = useState<IOption[]>([]);

  const handleLoadAmortization = useCallback(() => {
    if (typesAmortization.length === 0) {
      api
        .get<IAmorts>(`queries/getamortizacoes/${serviceOrder.finalidade_id}`)
        .then((response) => {
          {
            // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
          }
          const data: IOption[] = (response.data as unknown as any[]).map(
            (amortizationData) => {
              let selected = false;
              if (simulation && simulation.amortization) {
                selected = simulation.amortization.title
                  === amortizationData.amortizacao;
              }
              return {
                id: amortizationData.id,
                value: amortizationData.amortizacao,
                selected,
              };
            }
          );
          setTypesAmortization(data);
        });
    }
  }, [serviceOrder.finalidade_id, simulation, typesAmortization.length]);

  useEffect(() => {
    handleLoadAmortization();
  }, [handleLoadAmortization]);

  const handleSubmit = useCallback(
    async (data: IDataForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          readyValue: Yup.string().required(
            'O Valor do Imóvel Pronto é obrigatório'
          ),
          financedValue: Yup.string().required(
            'O Valor Financiado é obrigatório'
          ),
          rate: Yup.string().required('A Taxa é obrigatória'),
          deadline: Yup.string().required('O Prazo é obrigatório'),
          entryValue: Yup.string().required('O Valor de entrada é obrigatório'),
          realEntryValue: Yup.string().required(
            'O Valor de entrada real é obrigatório'
          ),
          firstInstallment: Yup.string().required(
            'O Valor da Primeira parcela é obrigatório'
          ),
          lastInstallment: Yup.string().required(
            'O Valor da última parcela é obrigatório'
          ),
          id: Yup.string().required('A amortização é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const amortization = typesAmortization.find(
          (typeAmortization) => typeAmortization.selected
        );

        const formData = {
          revenue: simulation.revenue,
          birthdate: simulation.birthdate,
          property_type: simulation.property_type,
          property_price: data.property_price,
          amortization: {
            id: amortization?.id as number,
            financedValue: data.financedValue,
            entryValue: data.entryValue,
            realEntryValue: data.realEntryValue,
            fgtsAmount: data.fgtsAmount,
            rate: data.rate,
            firstInstallment: data.firstInstallment,
            lastInstallment: data.lastInstallment,
            deadline: data.deadline,
            selectedDeadline: simulation.amortization?.selectedDeadline,
            targetLimitValue: simulation.amortization?.targetLimitValue,
            limitContractValue: simulation.amortization?.limitContractValue,
          },
        };

        const newSimulation: ISimulation = {
          ...formData,
        };
        setSimulation(newSimulation);
        if (params.builderId) {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/nova-os/${params.builderId}/${params.bdi}`
          );
        } else if (params.osId !== undefined) {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/${params.osId}`
          );
        } else {
          history.push(
            `${process.env.PUBLIC_URL}/simulador/solicitar-orcamento`
          );
        }

        handleCloseDetail2();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ocorreu um erro, tente novamente',
          });
        }
      }
    },
    [
      typesAmortization,
      simulation.revenue,
      simulation.birthdate,
      simulation.property_type,
      simulation.amortization?.selectedDeadline,
      simulation.amortization?.targetLimitValue,
      simulation.amortization?.limitContractValue,
      setSimulation,
      params.builderId,
      params.osId,
      params.bdi,
      handleCloseDetail2,
      history,
    ]
  );

  return (
    <Modal
      show={showDetail2}
      onHide={handleCloseDetail2}
      close
      size="lg"
      centered
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="col-12 p-4"
        initialData={{
          deadline:
            simulation && simulation.amortization
              ? simulation.amortization.deadline
              : '',
        }}
      >
        <Modal.Header closeButton>
          <div>
            <h2>Já possui projeto aprovado em prefeitura? </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="row">
            <div className="col-auto mb-3 mt-3">
              <label>Possui RGI em seu nome?</label>
              <InputRadio
                name="casado"
                options={[
                  { id: 1, value: 'Sim' },
                  { id: 2, value: 'Não/ Nome de terceiros' },
                ]}
                className="mt-3 box-radio"
              />
            </div>
            <div className="col-auto mt-5">
              <div className="w-100">
                <InputRadio
                  name="tipo"
                  className="mt-2 justify-content-start"
                  options={[
                    { id: 1, value: 'PF' },
                    { id: 2, value: 'Nome de PJ' },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto mb-3 mt-3">
              <label>Possui Compra e venda?</label>
              <InputRadio
                name="financiamento"
                options={[
                  { id: 1, value: 'Sim' },
                  { id: 0, value: 'Não' },
                ]}
                className="mt-3 box-radio pe-5"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="w-100">Valor do terreno</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="readyValue"
                placeholder="R$ 0,00"
                value={
                  simulation.amortization?.targetLimitValue
                    ? formatPrice(
                      simulation.amortization?.targetLimitValue / 0.8
                    )
                    : formatPrice(
                      Number(simulation.amortization?.limitContractValue)
                      / 0.8
                    )
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100">Saldo devedor do terreno</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="financedValue"
                placeholder="R$ 0,00"
                value={
                  simulation && simulation.amortization
                    ? simulation.amortization.financedValue
                    : ''
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="w-100">Valor total de obra (Estimado)</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="readyValue"
                placeholder="R$ 0,00"
                value={
                  simulation.amortization?.targetLimitValue
                    ? formatPrice(
                      simulation.amortization?.targetLimitValue / 0.8
                    )
                    : formatPrice(
                      Number(simulation.amortization?.limitContractValue)
                      / 0.8
                    )
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100">Quanto já gastou na obra?</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="financedValue"
                placeholder="R$ 0,00"
                value={
                  simulation && simulation.amortization
                    ? simulation.amortization.financedValue
                    : ''
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <label className="w-100">
                Quanto ainda possui em em recursos próprios(fora o que já foi
                gasto na obra)?
              </label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="readyValue"
                placeholder="R$ 0,00"
                value={
                  simulation.amortization?.targetLimitValue
                    ? formatPrice(
                      simulation.amortization?.targetLimitValue / 0.8
                    )
                    : formatPrice(
                      Number(simulation.amortization?.limitContractValue)
                      / 0.8
                    )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="w-100">Renda mensal formal</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="readyValue"
                placeholder="R$ 0,00"
                value={
                  simulation.amortization?.targetLimitValue
                    ? formatPrice(
                      simulation.amortization?.targetLimitValue / 0.8
                    )
                    : formatPrice(
                      Number(simulation.amortization?.limitContractValue)
                      / 0.8
                    )
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100">Renda mensal informal</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="financedValue"
                placeholder="R$ 0,00"
                value={
                  simulation && simulation.amortization
                    ? simulation.amortization.financedValue
                    : ''
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="w-100">Cidade do terreno e Condomínio</label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="readyValue"
                placeholder="R$ 0,00"
                value={
                  simulation.amortization?.targetLimitValue
                    ? formatPrice(
                      simulation.amortization?.targetLimitValue / 0.8
                    )
                    : formatPrice(
                      Number(simulation.amortization?.limitContractValue)
                      / 0.8
                    )
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100">
                Endereço completo do terreno com CEP
              </label>
              <InputMask
                className="input mt-2"
                kind="money"
                name="financedValue"
                placeholder="R$ 0,00"
                value={
                  simulation && simulation.amortization
                    ? simulation.amortization.financedValue
                    : ''
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-auto mb-3 mt-3">
              <label>Terreno de rua ou condomínio?</label>
              <InputRadio
                name="possuiCompraVenda"
                options={[
                  { id: 1, value: 'Rua' },
                  { id: 2, value: 'Condomínio' },
                ]}
                className="mt-3 box-radio pe-5"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons-group">
            <button
              type="submit"
              className="btn btn-primary w-auto py-2 px-5"
              style={{ borderRadius: '20px' }}
            >
              Salvar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalInformation;
