import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #e6e7e8;
  border-radius: 8px;

  hr {
    margin-top: 2%;
    height: 2px;
  }

  .financing-box {
    display: flex;
    justify-content: end;
    cursor: pointer;
    @media (max-width: 1199px) {
      justify-content: flex-start;
      margin-top: 3%;
    }
  }

  .container-financing {
    @media (max-width: 1199px) {
      margin-top: 6%;
    }
  }

  .financing-text {
    display: flex;
    left: 1250px;
    top: 445px;
    align-items: center;
    color: #9d9d9d;
  }

  .circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #e6e7e8;
    border-radius: 50%;
    margin-left: 10px;
  }
`;
