import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';
import img from '~/assets/defaults/test-marketing.png';

interface IModal {
  size?: 'md';
}
interface IAvatar {
  src: string;
}

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.section`
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .btn-add {
    background: #079269;
    border-radius: 100px;
    font-weight: 700;
    color: #ffffff;
    height: 47px;
    margin-bottom: 20px;
    transition: 0.3s;
    border: none;
    :hover {
      background: ${lighten(0.1, '#079269')};
    }
  }

  .link {
    color: #39a887 !important;
    font-size: small;
  }

  .bd-box {
    border: 1px solid #e9e7e7;
    border-radius: 20px;
    background-color: #fff;
    height: 325px;

    h2 {
      color: #616161;
    }

    p {
      color: #b9b9b9;
    }

    .btn-dots {
      margin-left: auto;
      display: flex;
      background-color: #fff;
    }

    .btn-copy {
      margin-top: 5px;
      margin-right: 10px;
    }

    .img-icon {
      width: 50px;
      height: 50px;
    }
  }

  .bg-img {
    background-image: url(${img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    width: 100%;
    height: 150px;
  }

  .p-description {
    overflow-y: auto;
    text-align: justify;
    @media (max-width: 1060px) {
      height: 90px;
    }
  }

  .box-edit {
    background-color: #dfdfdf;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    right: 20px;

    button {
      color: #939393;
    }

    hr {
      border-top: 1px solid #aeaeae;
      margin: 0 20px;
    }
  }

  .box-post {
    width: 32.5%;
  }
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const Search = styled.div`
  border-radius: 100px;
  border: 1px solid #dddddd;
  background-color: transparent;
  margin-bottom: 20px;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #8c8c8c;
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  .d-none {
    display: none;
  }

  .modalContent {
    max-width: ${(props) => (props.size === 'md' ? '500px' : '700px')};
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .btn-submit {
    background-color: #414142;
    color: #ffffff;
    font-weight: 700;
  }

  .upload-file {
    background: #f5f5f6;
    border: 2px dashed #aaaaaa;
    border-radius: 18px;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #414142;
  }

  .text-file {
    color: #a1c64d;
  }

  .upload-file-bg {
    border-radius: 18px;
    padding: 5px 10px;
    width: 100%;
    height: 266px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .file-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
