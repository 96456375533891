import React from 'react';
import { BsTelegram } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import { Container } from './styles';

const InformarAssinatura: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();

  return (
    <Container>
      <div className="col-md-12 d-flex">
        <div className="icon-information">
          <BsTelegram size={40} color="#FEBA6A" />
        </div>
        <div className="col-md-11 text-box">
          <div className="d-flex mt-3">
            <b>Informar assinatura do contrato </b>
          </div>
          <div className="d-flex mt-3 row box-fill">
            <div className="col-xl-7">
              <p>
                Assim que assinado, aguarde a postagem do contrato, nos informe
                postagem
              </p>
            </div>

            <div className="col-xl-4 box-button-fill">
              <button
                type="button"
                className="button-fill px-4 py-2 mb-1"
                onClick={() => history.push(`/dashboard/${serviceOrder?.id}`)}
              >
                <b>Informar assinatura</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InformarAssinatura;
