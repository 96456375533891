import styled from 'styled-components';

interface IAvatar {
  src: string;
}

interface IResposta {
  accept: number;
}

interface IOs {
  active?: boolean;
}

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.div`
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .color {
    background: #f5f5f5;
  }
  .notify {
    border-radius: 50%;
    background: #f5f5f5;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 46px;
    height: 46px;

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      border-radius: 24px;
      right: 12px;
      top: 12px;
    }
  }

  .ancho {
    width: 400px;
  }

  .chat {
    background: transparent;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Title */

    color: #414142;
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    transition: 0.3ms;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .calendar {
    background: #ffffff;
    border-radius: 10px;
  }

  .reminder-task {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }

  .search {
    border: 1px solid #e4e4e4;
    border-radius: 35px;
    height: 56px;
    background-color: #ffffff;
    :hover {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    :hover input {
      cursor: pointer;
      background-color: #f4f4f4;
    }
    input {
      color: #414142;
      ::placeholder {
        color: #b9b8bc;
      }
    }
  }

  .btn-os {
    border-radius: 26px;
    width: 16px;
    height: 42px;
    color: white;
    background-color: #3aa787;
    :hover {
      background-color: #0a9169;
    }
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 200px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 150px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 260px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 150px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 200px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`;

export const Circle = styled.div<IResposta>`
  background: ${({ accept }) => (accept ? '#e1f1ed' : '#ffe0e7')};
  background-size: cover;
  background-position: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

  :hover {
    background-color: ${({ accept }) => (accept ? 'greenyellow' : 'lightcoral')};
  }
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const Rsts = styled.div`
  border-radius: 50px;
  overflow: hidden;
  border: 1px solid #dedede;
  position: relative;

  .loading-box {
    height: 100%;
    position: absolute;
  }
`;

export const Rst = styled.div<IOs>`
  width: 100%;
  transition-duration: 0.3s;

  p {
    line-height: 32px;
    letter-spacing: 0em;
  }

  p.fw-semibold {
    color: #414142;
  }

  button.btn-dots {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  a.btn-add,
  button.btn-add {
    background: #39a887;
    border-radius: 41px;
    color: #ffffff;
  }

  button.btn-yes,
  button.btn-no {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0em;
    color: #414142;

    span {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 11px;
    }
  }

  button.btn-yes {
    span {
      background: #c7fff2;
    }
  }

  button.btn-no {
    span {
      background: #ffe0e7;
    }
  }

  :hover {
    background: #f9f9f9;

    button.btn-dots {
      opacity: 1;
    }
  }

  + div {
    border-top: 1px solid #e2e2e2;
  }
`;
