import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AutoLogin from '~/pages/AutoLogin';
import BudgetDetail from '~/pages/BudgetDetail';
import Construtechs from '~/pages/Construtechs';
import Ferramentas from '~/pages/Ferramentas';
import ForgotPassword from '~/pages/ForgotPassword';
import EmailSentSucess from '~/pages/ForgotPassword/EmailSentSucess';
import Home from '~/pages/Home';
import Landpage from '~/pages/Landpage';
import Login from '~/pages/Login';
import Marketing from '~/pages/Marketing';
import MyDownloads from '~/pages/Marketing/MyDownloads';
import Projects from '~/pages/Projects';
import RequestedBudget from '~/pages/RequestedBudget';
import ResetPassword from '~/pages/ResetPassword';
import ServiceOrder from '~/pages/ServiceOrder';
import SignUp from '~/pages/SignUp';

import Assinatura from '~/pages/Assinatura';
import AssinaturaDos from '~/pages/AssinaturaDos';
import Awards from '~/pages/Awards';
import BuildYourHouse from '~/pages/BuildYourHouse';
import AboutYourHouse from '~/pages/BuildYourHouse/AboutYourHouse';
import Finishing from '~/pages/BuildYourHouse/Finishing';
import Chat from '~/pages/Chat';
import EmitirContrato from '~/pages/EmitirContrato';
import Finalities from '~/pages/Finalities';
import Kanban from '~/pages/Kanban';
import Profile from '~/pages/Profile';
import ProfileData from '~/pages/ProfileData';
import Requests from '~/pages/Requests';
import ServiceOrderDashboard from '~/pages/ServiceOrderDashboard';
import ServiceOrderOpening from '~/pages/ServiceOrderOpening';
import Simulation from '~/pages/Simulation';
import SimulationResults from '~/pages/Simulation/Results';
import Amortization from '~/pages/Simulation/Results/Amortization';
import SubirArquivos from '~/pages/SubirArquivos';

import { ProjectYourHouse } from '~/pages/BuildYourHouse/Create';
import { RealEstateProduct } from '~/pages/RealEstateProduct';
import { Printable } from "~/pages/ServiceOrderDashboard//Pages/Printable";
import { RouteComponent } from './Route';

const routes: React.FC = () => (
  <Switch>
    <Route path={`${process.env.PUBLIC_URL}/`} exact component={Landpage} />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/login`}
      exact
      component={Login}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/auto-login/:hash`}
      exact
      component={AutoLogin}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
      exact
      component={ForgotPassword}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/usuario/trocarsenha/:token/:email`}
      exact

      component={ResetPassword}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/esqueci-a-senha/email-enviado-com-sucesso`}
      exact
      component={EmailSentSucess}
    />
    {process.env.REACT_APP_DEMO_APP && (
      <RouteComponent
        path={`${process.env.PUBLIC_URL}/cadastre-se`}
        exact
        component={SignUp}
      />
    )}
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/home`}
      exact
      isPrivate
      component={Home}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/ordem-servico`}
      exact
      isPrivate
      component={ServiceOrder}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/orcamento-solicitado`}
      exact
      isPrivate
      component={RequestedBudget}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/detalhes-orcamento/:os_id`}
      exact
      isPrivate
      component={BudgetDetail}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/chat/:type`}
      exact
      isPrivate
      component={Chat}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/chat/:type/:chat_id`}
      exact
      isPrivate
      component={Chat}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/ferramentas`}
      exact
      isPrivate
      component={Ferramentas}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/fluxo-de-acoes`}
      exact
      isPrivate
      component={Kanban}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/projetos`}
      exact
      isPrivate
      component={Projects}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/marketing`}
      exact
      isPrivate
      component={Marketing}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/marketing/meus-downloads`}
      exact
      isPrivate
      component={MyDownloads}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/construtech/:url`}
      exact
      component={Construtechs}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/finalidades`}
      exact
      isPrivate
      component={Finalities}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/simulador/resultado/:osId?`}
      exact
      component={SimulationResults}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/simulador/resumo/:osId?`}
      exact
      isPrivate
      component={Amortization}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/simulador/resultado/amortizacao/nova-os/:builderId/:bdi`}
      exact
      component={Amortization}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/nova-os/:builderId/:bdi`}
      exact
      component={ServiceOrderOpening}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/simulador/solicitar-orcamento/:osId?`}
      exact
      isPrivate
      component={ServiceOrderOpening}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/simulador/:osId?`}
      exact
      isPrivate
      component={Simulation}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId`}
      exact
      isPrivate
      component={BuildYourHouse}
    />
    <Route
      path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/nova-os/:builderId/:bdi`}
      exact
      component={BuildYourHouse}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/projetos/monte-sua-casa`}
      exact
      isPrivate
      component={ProjectYourHouse}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/acabamento`}
      exact
      isPrivate
      component={Finishing}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/acabamento/:slug`}
      exact
      isPrivate
      component={Finishing}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/monte-sua-casa/:osId/sobre-a-casa/:slug/:option`}
      exact
      isPrivate
      component={AboutYourHouse}
    />
    {/* <RouteComponent
        path={`${process.env.PUBLIC_URL}/so-dashboard/:os_id`}
        exact
        isPrivate
        component={Dashboard}
      /> */}
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/solicitacoes`}
      exact
      isPrivate
      component={Requests}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/perfil/dados-pessoais`}
      exact
      isPrivate
      component={ProfileData}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/perfil`}
      exact
      isPrivate
      component={Profile}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/dashboard/:osId`}
      exact
      isPrivate
      component={ServiceOrderDashboard}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/dashboard/proposta/:osId/impressao`}
      exact      
      component={Printable}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/assinatura-online/:osId`}
      exact
      isPrivate
      component={Assinatura}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/assinatura-presencial`}
      exact
      isPrivate
      component={AssinaturaDos}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/emitir-contrato/:osId`}
      exact
      isPrivate
      component={EmitirContrato}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/subir-arquivos/:osId`}
      exact
      isPrivate
      component={SubirArquivos}
    />
    <RouteComponent
      path={`${process.env.PUBLIC_URL}/premiacoes`}
      exact
      isPrivate
      component={Awards}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario`}
      exact
      isPrivate
      component={RealEstateProduct.Projects}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/projeto/:id`}
      exact
      isPrivate
      component={RealEstateProduct.Project}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/novo-projeto/:id?/:toStep?/:toHouse?`}
      exact
      isPrivate
      component={RealEstateProduct.RealEstateProject}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/lotes/:id`}
      exact
      isPrivate
      component={RealEstateProduct.Batches}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/atualizar/lote/:id/:batchID`}
      exact
      isPrivate
      component={RealEstateProduct.Batche}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/novo/lote/:id`}
      exact
      isPrivate
      component={RealEstateProduct.Batche}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/proposta/lote/:id/:terrainID`}
      exact
      isPrivate
      component={RealEstateProduct.Proposal}
    />

    <RouteComponent
      path={`${process.env.PUBLIC_URL}/produtos-imobiliario/compare/:id`}
      exact
      isPrivate
      component={RealEstateProduct.Compare}
    />
    <RouteComponent path="*" exact isPrivate component={Home} />
  </Switch>
);

export default routes;
