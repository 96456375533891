import styled from 'styled-components';

interface IAvatar {
  src: string;
}
export const Container2 = styled.div`
  .vl {
    margin-left: 500px;
    border-left: 5px solid #00d195;
    height: 80px;
    position: absolute;
    height: 84.86px;
    left: 25.01px;
    top: 408.94px;
  }
`;
export const Container = styled.div`
  background: #ffffff;
  .total-notifications {
    color: #fff;
    background-color: #39a887;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .title-assinatura {
    width: 250px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }
  .btn-nextt {
    position: absolute;
    left: 600px;
    top: 800px;
  }
  .btn-next {
    position: absolute;
    left: 800px;
    color: white;
    top: 700px;
    width: 331px;
    height: 54px;
    background: #39a887;
    border-radius: 20px;
  }
  .btn-nexttt {
    position: absolute;
    left: 800px;
    color: white;
    width: 331px;
    height: 54px;
    background: #39a887;
    border-radius: 20px;
  }
  .baixar {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Secoundary */

    color: #079269;
  }

  .title-sub {
    width: 600px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }
  .title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 158.19%;
    /* or 63px */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #202020;
  }

  .title2 {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .title-contrato {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 158.19%;
    /* or 63px */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #202020;
  }

  .title-agencia {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    /* Subtitle */

    color: #707070;
  }

  .notifications {
    color: #fff;
    background-color: #39a887;
    font-size: 13px;
  }
`;
export const CompleteData = styled.div`
  background: #ffffff;
  /* square */

  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 1020px;

  .title-correspondente {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Gray 1 */

    color: #333333;
  }
  .sub-correspondente {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
  .sub-verde {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    /* Secoundary */

    color: #079269;
  }
  .sub-gris {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    /* Subtitle */

    color: #707070;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;
