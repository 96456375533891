// Types
import { IMessageErrorProps } from "./types";

export function MessageError({ dataStep, nameField }: IMessageErrorProps) {
    return (
        <span className="text-red-500 font-semibold text-[0.85rem]">
            {
                dataStep?.errorMap?.flatten().fieldErrors?.[nameField]?.toString() || ''
            }
        </span>

    )
}