import styled from 'styled-components';

export const Container = styled.div`
  .button2 {
    background: #079269;
    border-radius: 10px;
    border: none;
    padding: 2px 4px;
  }
  .total-notifications {
    color: #fff;
    background-color: #39a887;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .emitir {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }
  .emitir-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .title-assinatura {
    width: 500px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Title */

    color: #414142;
  }

  .box-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1199px) {
      margin: 0px auto;
    }
  }

  .box-btn-go-dashboard {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 1199px) {
      justify-content: center;
    }
  }

  .btn-go-dashboard {
    left: 800px;
    color: white;
    width: 331px;
    height: 54px;
    background: #39a887;
    border-radius: 20px;
    margin-right: 18%;
    @media (max-width: 1399px) {
      margin-right: 11%;
    }
    @media (max-width: 1199px) {
      margin-right: 5%;
    }
  }
  .baixar {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Secoundary */

    color: #079269;
  }

  .title-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }
  .title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 158.19%;
    /* or 63px */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #202020;
  }

  .title2 {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    /* Subtitle */

    color: #707070;
  }

  .title-contrato {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 158.19%;
    /* or 63px */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #202020;
  }

  .title-agencia {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    /* Subtitle */

    color: #707070;
  }

  .notifications {
    color: #fff;
    background-color: #39a887;
    font-size: 13px;
  }
`;
