import blobToFile from 'kemis-library/utils/blobToFile';
import Swal from 'sweetalert2';

import api from '~/services/api';

interface IProfilePhotoProps {
  imageName: string;
  url: string;
}

export default async function ProfilePhoto({
  imageName,
  url,
}: IProfilePhotoProps) {
  try {
    const response = await api.get(url, {
      responseType: 'blob',
    });

    const newFile = blobToFile(response.data, imageName);
    const bynaryData = [];
    bynaryData.push(newFile);

    return window.URL.createObjectURL(
      new Blob(bynaryData, { type: response.data.type })
    );
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
    });
    return undefined;
  }
}
