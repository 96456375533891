import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;

  .centrar {
    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
      font-style: normal;
    }
  }

  .arrow {
    color: #707070;
    background: #f0f0f0;
    border-radius: 12px;
    width: 20px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #414142;
  }

  .subtitle {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
  }

  .button-proposal {
    background: #414142;
    border-radius: 26px;
    border: none;
    color: white;
  }

  .ancho {
    display: flex;
    margin-left: 90px;
    width: 250px;
    @media (min-width: 700px) {
      display: flex;
      margin-left: 0px;
      width: 100%;
    }
  }
`;

export const VR = styled.div`
  .vl {
    border-top: unset !important;
    border-left: 1px solid #b8b9ba;
    height: 40px;
  }
  @media (max-width: 557px) {
    .vl {
      border-top: 1px solid #b8b9ba !important;
      border-left: unset !important;
      width: 220px;
    }
  }
`;
