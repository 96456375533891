import React, { useCallback, useEffect, useState } from 'react';
import { BsCoin, BsDownload } from 'react-icons/bs';

import api from '~/services/api';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMFileType } from '~/models/FileTypes';
import Swal from 'sweetalert2';
import ModalShowFiles from '~/pages/ServiceOrderDashboard/Pages/FilesManagement/Partials/ModalShowFiles';
import ModalUpload from '~/pages/ServiceOrderDashboard/Pages/FilesManagement/Partials/ModalUpload';
import { IMServiceOrder } from '~/models/ServiceOrder';
import Loading from '~/components/Loading';
import { Container } from './styles';

const AutorizacaoEngenharia: React.FC = () => {
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [fileType, setFileType] = useState<IMFileType>({} as IMFileType);
  const [showFiles, setShowFiles] = useState(false);
  const [showModalSendDocuments, setShowModalSetDocuments] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const loadDocumentLists = useCallback(async () => {
    try {
      await api
        .get<IMFileType[]>(`builders/file-types/${serviceOrder.id}`, {
          params: {
            grupo: 5,
          },
        })
        .then((response) => {
          setFileType(response.data[0]);
        });
    } catch (err) {
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os tipos de documentos!',
        'error'
      );
    }
  }, [serviceOrder.id]);

  useEffect(() => {
    loadDocumentLists();
  }, [loadDocumentLists]);

  const onSendFile = useCallback(async () => {
    setShowLoading(true);
    await api
      .post<IMServiceOrder>(
        `builders/service-orders/${serviceOrder.id}/send-engineering-authorization`
      )
      .then((response) => {
        setServiceOrder(response.data);
        response.data.status_id !== 13 && setShowModalSetDocuments(false);
        setShowLoading(false);
      });
  }, [serviceOrder.id, setServiceOrder]);

  return (
    <Container>
      <div className="col-md-12 d-flex">
        <div className="icon-information">
          <BsCoin size={40} color="#FEBA6A" />
        </div>
        <div className="col-md-11 text-box p-3">
          <div className="row align-items-center">
            <div className="col-xl-7">
              <b>Autorização de engenharia</b>
              <p className="mt-3 pe-5">
                Sua autorização de engenharia já está disponível. Assine e
                carregue novamente no sistema para prosseguir.
              </p>
            </div>
            <div className="col-xl-5 box-button-fill d-flex">
              <button
                type="button"
                className="button-fill px-4 py-2 mr-0"
                onClick={() => setShowModalSetDocuments(true)}
              >
                <b>Subir Formulário</b>
              </button>
              <button
                type="button"
                onClick={() => fileType && setShowFiles(true)}
                className="d-flex"
              >
                <BsDownload className="pe-2 h1 mb-0" />
                <p className="mt-2">Baixar Formulário</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showFiles && (
        <ModalShowFiles
          fileType={fileType}
          onHide={() => setShowFiles(false)}
        />
      )}
      {showModalSendDocuments && (
        <ModalUpload
          fileType={fileType}
          show={showModalSendDocuments}
          onHide={() => setShowModalSetDocuments(false)}
          onSendFile={onSendFile}
        />
      )}
      <Loading active={showLoading} />
    </Container>
  );
};

export default AutorizacaoEngenharia;
