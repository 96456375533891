import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  background: #fff;

  .green-box {
    border-radius: 12px 12px 0px 0px;
    background: linear-gradient(56deg, #1ea16b 0%, #06ed8d 100%);

    h3 {
      color: #fff;
      font-weight: 600;
    }
  }

  ul {
    li {
      transition-duration: 0.3s;

      div {
        div {
          > p {
            font-size: 14px;
            color: #707070;
            font-weight: 600;
          }

          .avatar {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
        }

        span {
          color: #202020;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .dots {
        width: 25px;
      }

      :last-child {
        border-bottom: none;
      }

      :hover {
        background: #f9f9f9;
      }

      + li {
        border-top: 0.6px solid #e9e8e8;
      }
    }

    .dots-border {
      border: none !important;
    }

    li.you {
      border-radius: 7px;
      border: 0.6px solid #e9e8e8;
      box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.05);

      :hover {
        background: transparent;
      }
    }
  }
`;
