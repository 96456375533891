export function Mock(): JSX.Element {
  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="w-full rounded-xl bg-gray-200 animate-pulse h-[7.75rem]" />
      <div className="flex flex-row flex-nowrap gap-3">
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[33.125rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[33.125rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[33.125rem]" />
      </div>

      <div className="flex flex-row flex-nowrap gap-3">
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
      </div>

      <div className="w-full rounded-xl bg-gray-200 animate-pulse h-[37rem]" />

      <div className="flex flex-row flex-nowrap gap-3 mb-10">
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
        <div className="flex-1 rounded-xl bg-gray-200 animate-pulse h-[15.625rem]" />
      </div>
    </div>
  );
}
