import React, { useCallback, useEffect, useState } from 'react';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';

import Loading from '~/components/Loading';
import { IOption } from '~/components/Select';
import UserDefault from '~/components/UserDefault';
import { useDefaultData } from '~/hooks/DefaultData';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMBudgetParameter } from '~/models/BudgetParameter';
import { IMBudgetRoom } from '~/models/BudgetRoom';
import { IMClient } from '~/models/Client';
import { IMFinality } from '~/models/Finality';
import { IMSimulation } from '~/models/Simulation';
import { IMSpouse } from '~/models/Spouse';
import { IMStates } from '~/models/States';
import { IMTerrain } from '~/models/Terrain';
import BudgetAmount from './Components/BudgetAmount';
import PersonalInformation from './Components/PersonalInformation';
import ProjeteiSuggestion from './Components/ProjeteiSuggestion';
import PropertyEnvironments from './Components/PropertyEnvironments';
import ResourceComposition from './Components/ResourceComposition';
import SimulationConditions from './Components/SimulationConditions';
import UserData from './Components/UserData';
import Avisoama from './Components/aviso-amarillo';
import Avisover from './Components/aviso-verde';
import { Container } from './styles';

interface IParams {
  os_id: string;
}

interface ISimulation {
  id: number;
  amortizacao: {
    id: number;
    amortizacao: string;
  };
  vlrFinanciado: number;
  vlrEntrada: number;
  vlrRenda: number;
  vlrFgts: number;
  vlrSubsidio: number;
  vlrTerreno: number;
  vlrParcInic: number;
  vlrParcFinal: number;
  prazo: number;
  taxa: number;
  vlrSolicitado: number;
  resultAnalise_id: number;
}

const BudgetDetail: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { validateEngineerData } = useProfile();
  const [loading, setLoading] = useState(true);
  const { setDefaultData, estados_brasileiros, finalidades } = useDefaultData();
  const { getServiceOrderData, serviceOrder, recoveryServiceOrderData } = useServiceOrder();
  const [finalityId, setFinalityId] = useState<number>(0);
  const [, setStatesData] = useState([] as IMStates[]);
  const [client, setClient] = useState({} as IMClient);
  const [spouse, setSpouse] = useState({} as IMSpouse);
  const [finality, setFinality] = useState({} as IMFinality);
  const [orc_environments, setEnvironments] = useState([] as IMBudgetRoom[]);
  const [terrain, setTerrain] = useState<IMTerrain | undefined>(
    {} as IMTerrain
  );
  const [numPis, setNumPis] = useState(0);
  const [agency, setAgency] = useState('');
  const [, setModalities] = useState<IOption[]>([
    {
      id: 1,
      value: 'Aquisição de Terreno e construção',
      selected: false,
    },
  ]);
  const [simulation, setSimulation] = useState({} as ISimulation);
  const [orc_parameters, setParameters] = useState<IMBudgetParameter>({
    acabamento: {},
    eletrica: {},
  });

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, []);

  useEffect(() => {
    setLoading(true);

    if (
      (params.os_id && serviceOrder?.key)
      || (params.os_id && params.os_id !== serviceOrder.key)
    ) {
      recoveryServiceOrderData(params.os_id)
        .then((_resolver) => {
          setLoading(false);
          setDefaultData();
        })
        .catch((_rejects) => {
          history.goBack();
        });
    }

    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Maybe this has to be improved
  useEffect(() => {
    if (finalidades?.length) {
      const data: IOption[] = finalidades.map((finalityData: IMFinality) => ({
        id: finalityData.id,
        value: finalityData.finalidade,
        selected: serviceOrder?.finalidade?.id === finalityData.id,
      }));
      setStatesData(estados_brasileiros);
      setModalities(data);
    }
  }, [estados_brasileiros, finalidades, serviceOrder?.finalidade?.id]);

  const simulationTranslate = useCallback(
    (res: IMSimulation) => {
      setSimulation({
        ...simulation,
        id: res.id ? res.id : 1,
        vlrFinanciado: res.vlrFinanciado ? res.vlrFinanciado : 1,
        vlrEntrada: res.vlrEntrada ? res.vlrEntrada : 1,
        vlrRenda: res.vlrRenda ? res.vlrRenda : 0,
        vlrFgts: res.vlrFgts ? res.vlrFgts : 0,
        vlrSubsidio: res.vlrSubsidio ? res.vlrSubsidio : 1,
        vlrTerreno: res.vlrTerreno ? res.vlrTerreno : 1,
        vlrParcInic: res.vlrParcInic ? res.vlrParcInic : 1,
        vlrParcFinal: res.vlrParcFinal ? res.vlrParcFinal : 1,
        amortizacao: {
          id: res?.amortizacao?.id ? res.amortizacao.id : 1,
          amortizacao: res.amortizacao ? `${res.amortizacao}` : '',
        },
        prazo: res.prazo ? res.prazo : 1,
        taxa: res.taxa ? res.taxa : 1,
        vlrSolicitado: res.vlrSolicitado ? res.vlrSolicitado : 1,
        resultAnalise_id: 1,
      });
    },
    [simulation]
  );

  useEffect(() => {
    if (serviceOrder.simulacao) simulationTranslate(serviceOrder.simulacao);
    if (serviceOrder.agencia) setAgency(serviceOrder.agencia);
    if (serviceOrder.cliente) setClient(serviceOrder.cliente);
    if (serviceOrder.finalidade) setFinality(serviceOrder.finalidade);
    if (serviceOrder.finalidade_id) setFinalityId(serviceOrder.finalidade_id);
    if (serviceOrder.numPis) setNumPis(serviceOrder.numPis);
    if (serviceOrder.orc_parametros) setParameters(serviceOrder.orc_parametros);
    if (serviceOrder.orc_ambientes) setEnvironments(serviceOrder.orc_ambientes);
    if (serviceOrder.conjuge) setSpouse(serviceOrder.conjuge);
    if (serviceOrder.terreno) setTerrain(serviceOrder.terreno);
  }, [serviceOrder]);

  const handleClickChat = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/chat/clientes`);
  }, [history]);

  return (
    <>
      <Container className="container">
        <div className="py-4" />
        <div className="mt-5 mt-lg-0">
          <UserDefault DetalhesOS />
        </div>
        <div className="d-flex justify-content-end px-4 py-5">
          <button type="button" className="chat" onClick={handleClickChat}>
            Chat
            <div className="rounded-circle color p-2 ms-2">
              <IoChatbubbleEllipsesSharp
                size={24}
                color="#707070"
                className=" chat"
              />
            </div>
          </button>
        </div>
        <div>
          <PersonalInformation
            client={client}
            spouse={spouse}
            finality={finality}
            terreno={terrain}
            numPis={numPis}
            agency={agency}
          />
          <UserData osId={serviceOrder.id || -1} />
        </div>
        <div className="row mt-0 mt-lg-5 ms-0 ms-lg-5">
          {simulation
            && (simulation.resultAnalise_id === 2 && (
              <div className="row mt-5">
                <Avisoama simulation={simulation} />
              </div>
            ),
              simulation.resultAnalise_id === 1 && (
                <div className="row mt-5 mb-5">
                  <Avisover simulation={simulation} />
                </div>
              ))}
          {/* este es para finalidad 1,2,5 y 7 */}
          {(finalityId === 1
            || finalityId === 2
            || finalityId === 5
            || finalityId === 7) && (
              <div className="row ms-4">
                <div className="col-auto">
                  <ResourceComposition simulation={simulation} />
                </div>
                <div className="col-md-7 col-12">
                  <SimulationConditions simulation={simulation} />
                  <BudgetAmount simulation={simulation} />
                </div>
                {serviceOrder?.id && (
                  <PropertyEnvironments
                    parameters_orc={orc_parameters}
                    simulation={simulation}
                    osId={serviceOrder.id || -1}
                    ambientes_orc={orc_environments}
                  />
                )}

                {serviceOrder?.id && (
                  <ProjeteiSuggestion osId={serviceOrder.id} />
                )}
              </div>
            )}
          {/* Este es para finalidad 3, 4 y 6 */}
          {(finalityId === 3 || finalityId === 4 || finalityId === 6) && (
            <div className="row mt-5 container_dashboard_info_res">
              <SimulationConditions simulation={simulation} />
            </div>
          )}
        </div>
      </Container>
      <Loading active={loading} />
    </>
  );
};

export default BudgetDetail;
