import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import { useProfile } from '~/hooks/Profile';
import { IMSocialWorks } from '~/models/EngineerSocialWorks';
import api from '~/services/api';
import getValidationErrors from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';
import { Container } from './styles';

export const Social: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { setLoading, engineer, setEngineer } = useProfile();

  const handleSubmit = useCallback(
    async (data: IMSocialWorks) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          whatsapp: Yup.string().required('Whatsapp obrigatório'),
          facebook: Yup.string().default(''),
          youtube: Yup.string().default(''),
          linkedin: Yup.string().default(''),
          instagram: Yup.string().default(''),
          twitter: Yup.string().default(''),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!engineer?.social_works) {
          await api
            .post(
              `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/social-works`,
              data
            )
            .then((response) => {
              setEngineer({
                ...engineer,
                social_works: response.data,
              });
            });
        } else {
          await api
            .put(
              `${process.env.REACT_APP_PREFIX_ROUTE}/engineer/social-works/${engineer?.social_works?.id}`,
              data
            )
            .then((response) => {
              setEngineer({
                ...engineer,
                social_works: response.data,
              });
            });
        }

        Toast.fire({
          icon: 'success',
          title: !engineer
            ? 'Cadastro realizado com sucesso!'
            : 'Atualização de cadastro realizado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          Toast.fire({
            icon: 'error',
            title:
              'Ocorreu um erro ao carregar seus dados, verifique seus dados.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [engineer, setEngineer, setLoading]
  );
  return (
    <>
      <Container>
        <Form
          className="row folder mt-4 mx-0 pb-4"
          id="socialSubmit"
          ref={formRef}
          initialData={engineer?.social_works}
          onSubmit={handleSubmit}
        >
          <div className="divEndereco text-black mx-6">
            <h5 className="card-title px-2 px-lg-5">Redes sociais</h5>
            <div className="px-2 px-lg-5 row">
              <div className="col-sm-4 mb-3 mt-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Facebook:
                    <span />
                  </span>
                  <Input className="input mt-1" name="facebook" defaultValue={engineer.social_works?.facebook || ''} />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="col-sm-4 mb-3 mt-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Youtube
                    <span />
                  </span>
                  <Input className="input mt-1" name="youtube" defaultValue={engineer.social_works?.youtube || ''} />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="col-sm-4 mb-3 mt-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Linkedln
                    <span />
                  </span>
                  <Input className="input mt-1" name="linkedin" defaultValue={engineer.social_works?.linkedin || ''} />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="col-sm-4 mb-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Instagram:
                    <span />
                  </span>
                  <Input className="input mt-1" name="instagram" defaultValue={engineer.social_works?.instagram || ''} />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="col-sm-4 mb-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Twitter:
                    <span />
                  </span>
                  <Input className="input mt-1" name="twitter" defaultValue={engineer.social_works?.twitter || ''} />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="col-sm-4 mb-3">
                <label className="w-100">
                  <span className="small text-subtitle">
                    Whatsapp
                    <span />
                  </span>
                  <InputMask
                    className="input mt-1"
                    placeholder="(  ) "
                    name="whatsapp"
                    kind="cel-phone"
                    value={engineer?.social_works?.whatsapp}
                  />
                </label>
                <hr className="sub-bar mt-0" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <button
                    type="submit"
                    className="d-block btn-save fw-semibold border-0 mx-auto"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Social;
