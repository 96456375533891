import styled from 'styled-components';

interface ImageItem {
  src: string;
}

export const SectionAddImage = styled.section`
  .section {
    padding: 2rem 0;
    flex-direction: row;
  }
  .text-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Blue - Wodo */

    color: #324351;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    color: #838383;
  }
  .icon {
    width: 100%;
  }

  .label {
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dotted #000;
    border-radius: 20px;
    width: 12rem;
    height: 12.5rem;
    cursor: pointer;
    font-size: large;
    position: relative;
  }

  .hola {
    // boton para insertar imagenes queda invisible
    display: none;
  }

  .image {
    position: relative;
    border-radius: 10px;
  }
  .buttonDelete {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: #fff;
    background-color: lightcoral;
    border-bottom-right-radius: 8px;
  }
  .buttonDelete:hover {
    background-color: red;
  }

  .btnUploadFotos {
    display: flex;
    flex-direction: row-reverse;
  }

  .box-image {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      height: 12px;
    }
  }
`;

export const ImageItem = styled.div<ImageItem>`
  background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 200px;
`;
