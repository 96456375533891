import { useServiceOrder } from "~/hooks/ServiceOrder";

// Icons
import poolPrintIcon from "~/assets/icons/pool-print.svg";
import roomPrintIcon from "~/assets/icons/room-print.svg";
// import housePrintIcon from "~/assets/icons/house-print.svg";
// import frameworkPrintIcon from "~/assets/icons/framework-print.svg";
import bathroomPrintIcon from "~/assets/icons/bathroom-print.svg";
import closetPrintIcon from "~/assets/icons/closet-print.svg";
import gourmetAreaPrintIcon from "~/assets/icons/gourmet-area-print.svg";
import kitchenPrintIcon from "~/assets/icons/kitchen.svg";
import livingRoomPrintIcon from "~/assets/icons/living-room-print.svg";
import officePrintIcon from "~/assets/icons/office-print.svg";
import paantryPrintIcon from "~/assets/icons/paantry-print.svg";
import parkinPrintIcon from "~/assets/icons/parkin-print.svg";
import serviceAreaPrintIcon from "~/assets/icons/service-area-print.svg";
import toiletPrintIcon from "~/assets/icons/toilet-print.svg";

// Types
import { IEnvironmentsProps, TEnvironemntData, TEnvironmentsIcons } from "./types";

// Styles
import "./styles.css";

// Utils
import { useMemo } from "react";
import { TStepTargets } from "~/pages/BuildYourHouse/Partials/Steps/Interior/types";
import { generateSlug } from '~/utils/slug';

export function Environments(_props: IEnvironmentsProps) {
    const {serviceOrder} = useServiceOrder()

    // AUX Variables
    const ENVIRONMENTS_ICONS: TEnvironmentsIcons = {
        'quarto': {
            icon: roomPrintIcon
        },
        'closet': {
            icon: closetPrintIcon
        },
        'banheiro': {
            icon: bathroomPrintIcon
        },
        'cozinha': {
            icon: kitchenPrintIcon
        },
        'area-gourmet': {
            icon: gourmetAreaPrintIcon
        },
        'lavabo': {
            icon: toiletPrintIcon
        },
        'despensa': {
            icon: paantryPrintIcon
        },
        'piscina': {
            icon: poolPrintIcon
        },
        'escritorio': {
            icon: officePrintIcon
        },
        'garagem': {
            icon: parkinPrintIcon
        },
        'sala': {
            icon: livingRoomPrintIcon
        },
        'area-de-servico': {
            icon: serviceAreaPrintIcon
        }        
    }

    const ENVIRONMENTS_DATA: TEnvironemntData = useMemo(() =>  {
        const ENVIRONMENTS_DATA_RAW: TEnvironemntData = {} as TEnvironemntData

        serviceOrder.orc_ambientes?.forEach((environment) => {
            const ENVIRONMENT_SLUG = generateSlug(environment.ambiente_tamanho?.ambiente?.ambiente || 'quarto')
            const ENVIRONMENT_NAME = environment.ambiente_tamanho?.ambiente?.ambiente || 'quarto'

            if(ENVIRONMENTS_DATA_RAW[ENVIRONMENT_SLUG as TStepTargets]) {
                ENVIRONMENTS_DATA_RAW[ENVIRONMENT_SLUG as TStepTargets].rooms.push({
                    lenght: environment.metragem,
                    item: environment.item,
                    id: environment.id
                })
            }else{
                ENVIRONMENTS_DATA_RAW[ENVIRONMENT_SLUG as TStepTargets] = {
                    name: ENVIRONMENT_NAME,
                    slug: ENVIRONMENT_SLUG as TStepTargets,
                    icon: ENVIRONMENTS_ICONS[ENVIRONMENT_SLUG as TStepTargets]?.icon || '',
                    rooms: [
                        {
                            lenght: environment.metragem,
                            item: environment.item,
                            id: environment.id
                        }
                    ]
                    
                }
            }
        })

        return ENVIRONMENTS_DATA_RAW
    }, [])

    const FORMAT_TOTAL_ROOMS = useMemo(() => (rooms: number = 0) => new Intl.NumberFormat("en", {
        minimumIntegerDigits: 2,
        minimumFractionDigits: 0,
    }).format(rooms), [])

    return (
        <div id="environments-container" className="general-container">
            <div id="environments-header">
                <span className="general-text-bold">Ambientes</span>
                <span className="general-text-label">Ambientes, tamanho e quantidade</span>
            </div>

            <ul className="general-box" id="environments-content">
                {
                    Object.keys(ENVIRONMENTS_DATA).map((environment, index) =>{
                        const ENVIRONMENT = ENVIRONMENTS_DATA[environment as TStepTargets]
                        const HAS_THAN_THAT_ONE = ENVIRONMENT.rooms.length > 1 && true

                        return (
                            <li key={index} className="environments-li-item">
                                <span className="environments-li-header">
                                    <span className="environments-li-icon">
                                        <img src={ENVIRONMENT.icon} alt="" />
                                    </span>
                                    <span className="general-text-bold environments-li-label">{`${ENVIRONMENT.name + (HAS_THAN_THAT_ONE ? 's' : '')}`} ({FORMAT_TOTAL_ROOMS(ENVIRONMENT.rooms.length)})</span>
                                </span>
                                <span className="environments-li-content">
                                    {
                                        ENVIRONMENT.rooms.map((room, key) => (
                                            <span key={key} className="environments-li-tag">{room.lenght}m²</span>
                                        ))
                                    }                                    
                                </span>
                            </li>
                    )})
                }
            </ul>
        </div>
    )
}