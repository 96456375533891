import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import formatCurrency from '~/utils/formatCurrency';

import { getAccumulatedTotal } from '~/utils/serviceOrderCalculations';
import { Container } from './styles';

const PropertyValuation: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [marketValue, setMarketValue] = useState(0);
  const [budget, setBudget] = useState(0);

  useEffect(() => {
    if (serviceOrder.simulacao?.vlrImovPronto) {
      setMarketValue(
        ((serviceOrder.simulacao?.vlrFinanciado ?? 0)
          + (serviceOrder.simulacao.vlrEntrada ?? 0))
          * 1.5
      );
    }
    setBudget(
      getAccumulatedTotal(serviceOrder.orc_parametros, serviceOrder.orc_ambientes)
        + (serviceOrder.simulacao?.vlrTerreno ?? 0)
    );
  }, [serviceOrder]);

  const labels = useMemo(() => {
    const labelsData = [
      'Prestação do financiamento da construção',
      'Quanto custaria a prestação desse imóvel pronto',
      'Quanto custaria o aluguel desse imóvel pronto',
    ];
    return labelsData;
  }, []);

  const options = useMemo(
    () => ({
      options: {
        plotOptions: {
          bar: {
            columnWidth: '60%',
            distributed: true,
          },
        },
        labels,
        colors: ['#8676FF', '#96D9FF', '#FFBA69'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          enable: false,
          style: {
            fontSize: '12px',
          },
          x: {
            show: true,
          },
          y: {
            show: true,
            formatter(val: number) {
              return `${formatCurrency(val)}`;
            },
            title: {
              formatter: () => 'Valor:',
            },
          },
        },
      },
      series: [
        {
          data: [marketValue, budget, marketValue - budget],
        },
      ],
    }),
    [budget, marketValue, labels]
  );

  return (
    <Container className="container col-md-6 col-12 card-dashboard p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h2 className="h6 mb-0">Avaliação do Imóvel</h2>
      </div>
      <div className="position-relative d-flex align-items-center justify-content-center">
        <Chart
          options={options.options}
          series={options.series}
          type="bar"
          className=" mb-0"
          height={220}
        />
      </div>
      <div className="m-1">
        <div className="d-flex subtitle align-items-center mb-3">
          <div className="col-7 d-flex align-items-center">
            <div className="dot me-3 p-1" />
            <p className="mb-0 ">
              Avaliação de mercado do imóvel após executado
            </p>
          </div>
          <p
            className="mb-0 s font-weight-700"
            style={{ marginLeft: '10px', fontSize: '14px' }}
          >
            {formatCurrency(marketValue)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-2">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-2 me-3 p-1" />
            <p className="mb-0">Valor gasto na construção</p>
          </div>
          <p
            className="mb-0 s font-weight-700 "
            style={{
              marginLeft: '10px',
              fontSize: '14px',
            }}
          >
            {formatCurrency(budget)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-2">
          <div className="col-7 d-flex align-items-center">
            <div className="dot dot-3 me-3 p-1" />
            <p className="mb-0">Valor economizado</p>
          </div>
          <p
            className="mb-0 s font-weight-700"
            style={{ marginLeft: '10px', fontSize: '14px' }}
          >
            {formatCurrency(marketValue - budget)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PropertyValuation;
