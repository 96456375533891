import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .font-novo {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #747475;
  }

  .value-box {
    position: relative;
    color: #27262e;

    .red-ball {
      background: #ff1a50;
      border-radius: 6px;
      width: 12px;
      height: 12px;
      position: absolute;
    }
  }

  .inter-box {
    border: 1px solid #e9e9e9;
    border-radius: 12px;
  }

  .pres-box {
    border: 1px solid #e4e4e4;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }

  .in-box {
    color: #747475;
  }
`;
