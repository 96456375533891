// Types
import { IMockProps } from "./types";

export function Mock({isOnLoading}: IMockProps){
    return <>
        {
            isOnLoading && Array(10).fill(null).map((_d, index) => (
                <div
                    key={index}
                    className="
                        w-full
                        h-14
                        flex
                        flex-row
                        flex-nowrap
                        items-center
                        justify-start
                        gap-4
                        transition-all
                        duration-[0.15s]
                        rounded-[15px]
                        p-[0.5rem]
                    "
                >
                    <span className="flex-1 flex max-sm:max-w-[50px] md:max-w-[200px] h-full text-sm font-medium leading-[26px] rounded-[15px] animate-pulse bg-gray-200">
                    </span>
                    <span
                        className="
                            max-sm:min-h-40
                            max-sm:max-h-48
                            flex 
                            max-sm:flex-col
                            md:flex-row
                            md:flex-nowrap
                            md:justify-between
                            md:items-center
                            flex-1
                            h-full
                        "
                    >
                        <p
                            className="
                                md:max-w-[64rem]
                                md:h-8
                                md:flex-1
                                max-sm:w-full
                                md:overflow-hidden
                                md:whitespace-nowrap
                                max-sm:whitespace-pre-line
                                text-ellipsis
                                rounded-[15px] animate-pulse bg-gray-200
                            "
                        >
                        </p>
                        <span
                            aria-label="button"
                            className="flex
                                cursor-pointer
                                max-sm:flex
                                flex-row 
                                items-center 
                                max-sm:justify-end
                                justify-start
                                gap-3
                                min-w-[4rem]
                                text-sm
                                font-semibold leading-[18.23px] 
                                h-full
                                rounded-[15px] animate-pulse bg-gray-200
                            "
                        >
                        </span>
                    </span>
                </div>
            ))
        }
    </>
}