import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';


import logoHarvey from '~/assets/logos/logo-harvey.svg';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';

import api from '~/services/api';
import { Background, Container, Content } from './styles';

interface IFormData {
  email: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // await fetch(`${process.env.REACT_APP_API_URL}/admins/forgot-password`, {
        //   method: 'POST',
        //   headers: {
        //     "Content-Type": 'application/json',
        //   },
        //   body: JSON.stringify({
        //     email: data.email,
        //     origin: 'client',
        //    }
        //   )
        // })        

        await api.post(`${process.env.REACT_APP_API_URL}/admins/forgot-password`, {
          email: data.email,
          origin: 'client',
        });

        history.push(
          `${process.env.PUBLIC_URL}/esqueci-a-senha/email-enviado-com-sucesso`
        );
      } catch (error) {
        console.log(error)
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Verique o email informado', 'error');
        }
      }
    },
    [history]
  );

  return (
    <Container className="container-fluid">
      <div className="row">
        <Background className="col-lg-6 col-xl-6 col-xxl-7 d-none d-lg-flex flex-column justify-content-center align-items-center px-5 pt-4">
          <div>
            <img
              src={logoHarvey}
              alt="logo-harvey"
              className="d-block mx-auto mb-5"
            />
            <h2 className="fw-light text-center company">
              <b className="fw-medium">Harvey</b> Construtor
            </h2>
            <hr className="line" />
            <p className="text-center">
              Aqui você encontrará todos os modelos de contratos e
              <br />
              Documentações Exclusivas que serão utilizados no
              <br />
              processos de financiamento habitacional.
            </p>
          </div>
        </Background>

        <Content className="bg-dark-1 col-lg-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center px-3 px-md-5 py-5">
          <Link
            to={`${process.env.PUBLIC_URL}/home`}
            className="bg-transparent border-0 mb-3 ms-md-2 ms-lg-5 d-block align-self-start mb-5"
          >
            <RiArrowLeftSLine size={28} color="#B8B9BA" />
          </Link>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-lg-0 mt-5"
          >
            <div>
              <h1 className="h2 text-dark-gray fw-semibold mb-1">
                Esqueceu a senha?
              </h1>
              <p className="text-gray">
                Tudo bem, insira seu e-mail para restaurar
              </p>
            </div>
            <div className="mb-5 py-5">
              <label htmlFor="email" className="mb-2 fw-medium">
                E-mail
              </label>
              <Input
                type="email"
                placeholder="email@example.com"
                name="email"
                className="input"
              />
            </div>
            <div className="mb-5">
              <button
                type="submit"
                className="btn btn-primary mb-5 w-100 fw-semibold"
              >
                Enviar
              </button>
            </div>
          </Form>
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4" />
      </div>
    </Container>
  );
};

export default Login;
