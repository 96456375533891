import React from 'react';
import { BsFilePost } from 'react-icons/bs';
import { CompleteData } from './styles';

interface IStepMenuDataOs {
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const AguardandoAssinatura: React.FC<IStepMenuDataOs> = ({
  setContentData,
}) => (
  <CompleteData>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <BsFilePost size={40} color="#FEBA6A" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>Aguardando assinatura das fichas</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-7">
            <p>
              Para prosseguir, preencha as fichas cadastrais enviadas e anexe
              ao sistema
            </p>
          </div>

          <div className="col-xl-4 box-button-fill">
            <button
              type="button"
              className="button-fill px-5 py-2 mb-1"
              onClick={() => setContentData('WorkDocuments')}
            >
              <b>Visualizar fichas</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </CompleteData>
);

export default AguardandoAssinatura;
