export function setCookie(
  cname: string,
  cvalue: string = '',
  exdate: Date = new Date(),
  exdays: number = 0
) {
  try {
    if (cname.trim() === '')
      throw new Error('Cookie name and Cookie Value not can be empty');

    exdate.setTime(exdate.getTime() + exdays * 60 * 60 * 24 * 30); // 30 days until the actual time run

    const expires = '' + exdate.toUTCString();
    document.cookie = `${cname}=${cvalue}; Path=/; max-age=${expires}`;

    localStorage.setItem(cname, cvalue);

    return true;
  } catch (error: any) {
    console.log(`Something is wrong with cookie`, error?.message || '');
  }
}

export function getCookie(cname: string) {
  try {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    // TODO - sendo forçado o uso do localStorage devido a problemas com o cookie do Safari
    return localStorage.getItem(cname);
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  } catch (error: any) {
    console.log(`Something is wrong with cookie`, error?.message || '');
  }
}
