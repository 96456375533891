import styled from 'styled-components';

export const Container = styled.div`
  width: 260px;
  height: 200px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15), 0 3px 7px 0 rgba(0, 0, 0, 0.19);
  padding: 18px 18px 30px 18px;
  min-height: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  :hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    .container_zoom {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 200ms;
    }
  }
  .header-item-column {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    .avatar-container {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;
    }
    .date-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        margin: 0;
      }
    }
  }

  .container_zoom {
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 3px;
    background-color: #eee;
    border-radius: 6px;
    opacity: 0;
    transition: visibility 0s linear 200ms, opacity 200ms;
    visibility: hidden;
    :hover {
      cursor: pointer;
    }
  }
  img {
    border-radius: 8px;
    height: 100px;
    width: 100%;
    object-fit: cover;
  }
  .container_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .container_profile {
    position: absolute;
    bottom: 18px;
    right: 18px;
    .circle_profile {
      text-align: center;
      line-height: 2;
      font-size: 14px;
      width: 28px;
      height: 28px;
      background: orange;
      border-radius: 50%;
      color: #fff;
    }
  }
  .bold-element {
    font-weight: 700;
    color: '#A9A9A9';
  }
`;
