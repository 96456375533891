import React from 'react';

import { useProfile } from '~/hooks/Profile';
import { ImageItem, Container } from './styles';

const Gallery: React.FC = () => {
  const { construtech } = useProfile();
  const total = construtech?.engineer?.gallery?.length;
  return (
    <Container total={total}>
      {construtech?.engineer?.gallery?.length && (
        <div className="mb-5">
          <div className="separator mt-5" />
          <h4 className="py-3 text-title">Projetos</h4>
          <div className="overflow-auto">
            <div className="ancho">
              <div className="row">
                {construtech?.engineer?.gallery
                  .map((image) => (
                    <div className="col-3 col-lg-3 mt-3" key={image.id}>
                      <ImageItem
                        src={`${process.env.REACT_APP_API_URL}/builders/galleries/${construtech?.engineer?.id}/${image.id}`}
                        className="image"
                      />
                    </div>
                  ))
                  .reverse()}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Gallery;
