import Swal, { SweetAlertPosition } from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,  
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const ToastParametizer = (toast: boolean = true, showConfirmButton: boolean = true, position:SweetAlertPosition = 'top-end'  ) => Swal.mixin({
  toast,
  position,
  showConfirmButton,
  timer: 3000,
  timerProgressBar: true,  
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default Toast;
