import { useState } from "react";

// Icons
import { BsPlus } from "react-icons/bs";

// Partials

// Types
import { Modal } from "./Partials/Modal";
import { IProjectsProps } from "./type";

export function Project(props: IProjectsProps ){   
    const [openModal, setOpenModal]= useState(false)

    function setModalVisibility(){
        try {
            setOpenModal(!openModal)
        } catch (error) {
            // do anything
        }
    }

    function handleSetCloseModal() {
        try {            
            setOpenModal(false)
            
            if(props.setDropOfProject && typeof props.setDropOfProject === 'function') 
                props.setDropOfProject();
        } catch (error) {
            // do anything
        }
    }

    return (
        <>
            <button
                ref={props.dialogButtonRef}
                onClick={setModalVisibility}
                title="Criar um novo projeto"
                type="button"
                className="
                    max-sm:w-full max-sm:h-[50px] 
                    md:w-[213px] md:h-[56px] 
                    bg-[#39A887] 
                    active:bg-[#079269]
                    hover:bg-[#079269]
                    text-white
                    max-sm:rounded-[13px] 
                    md:rounded-[26px] 
                    px-[33px]
                    flex flex-row 
                    items-center 
                    justify-center 
                    gap-1
                    transition-all duration-[0.30s]
                "
            >
                <BsPlus className="text-white w-[24px] h-[24px]"/>
                Adicionar casa
            </button>

            {
                openModal && (
                    <Modal {...props} onClose={() => handleSetCloseModal()}/>
                )
            }            
        </>
    )
}