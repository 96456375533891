import * as Zod from "zod"

export const SStandard = Zod.object({
    id: Zod.number({
        required_error: 'Selecione uma Tipo de casa',
        invalid_type_error: 'Seleção incorreta'
    }).refine(val => !((val !== 0 && !val) || val <= 0), {
        message: 'Informe pelo menos 1 (uma) Casa'
    }),
    standard: Zod.string({
        required_error: 'Selecione uma Tipo de casa',
        invalid_type_error: 'Seleção incorreta'
    }),
})

export const SProject = Zod.object({
    name: Zod.any({
        required_error: 'Preencha o campo Nome',
        invalid_type_error: 'Preencha o campo Nome'
    }).refine(val => !(!val || val?.length <= 0), {
        message: 'Preencha o campo Nome'
    }),
    localization: Zod.any({
        required_error: 'Preencha o campo Endereço',
        invalid_type_error: 'Preencha o campo Endereço'
    }).refine(val => !(!val || val?.length <= 0)
        , {
            message: 'Preencha o campo Endereço'
        }),
})

export const SAround = Zod.object({
    quantity_terrains: Zod.number({
        required_error: 'Preencha o campo Quantos lotes tem o condôminio?'
    }).refine(val => !(!val || val <= 0), {
        message: 'Informe pelo menos 1 (um) lote'
    }),
    built_houses: Zod.number({
        required_error: 'Preencha o campo Quantas casas tem construidas e em construção?'
    }).refine(val => !(!val || val <= 0), {
        message: 'Informe pelo menos 1 (uma) casa'
    }),
})

export const SRegionalData = Zod.object({
    average_income_value: Zod.number({
        required_error: 'Preencha o campo Qual valor de renda medio da região?'
    }).refine(val => !(!val || val <= 0), {
        message: 'Informe pelo menos R$ 1,00 (Um Real)'
    }),
    average_age_residents: Zod.number({
        required_error: 'Preencha o campo Qual idade media dos moradores da região?'
    }).refine(val => !(!val || val < 18), {
        message: 'Informe pelo menos 18 (anos)'
    }),
    origin_residents: Zod.string({
        required_error: 'Preencha o campo Qual é a região dos moradores?'
    }).refine(val => !(!val || val?.length <= 0)
        , {
            message: 'Preencha o campo Qual é a região dos moradores?'
        }),
})

export const SHouse = Zod.object({
    name: Zod.string({
        required_error: 'Preencha o campo Nome'
    }).refine(val => !(!val || val.length < 0), {
        message: 'Informe pelo menos R$ 1,00 (Um Real)'
    }),
    ad_link: Zod.string({
        required_error: 'Preencha o campo Link de anuncio da casa na internet'
    }).optional(),
    terrain_area: Zod.number({
        required_error: 'Preencha o campo Área construida'
    }).refine(val => !(!val || val <= 0), {
        message: 'Preencha o campo Área construida'
    }),
    house_area: Zod.number({
        required_error: 'Preencha o campo Área do terreno'
    }).refine(val => !(!val || val <= 0), {
        message: 'Preencha o campo Área do terreno'
    }),
    vgv: Zod.number({
        required_error: 'Preencha o campo VGV'
    }).refine(val => !(!val || val < 0), {
        message: 'Preencha o campo VGV'
    }),
    bedrooms: Zod.number({
        required_error: 'Preencha o campo Quartos'
    }).refine(val => !(!val || val < 0), {
        message: 'Preencha o campo Quartos'
    }),
    bathrooms: Zod.number({
        required_error: 'Preencha o campo Banheiros'
    }).refine(val => !(!val || val < 0), {
        message: 'Preencha o campo Banheiros'
    }),
    parking_spaces: Zod.number({
        required_error: 'Preencha o campo Vagas de garagem'
    }).refine(val => !(!val || val < 0), {
        message: 'Preencha o campo Vagas de garagem'
    }),
})