// Models
import { IMRealEstateProductsHouse } from "~/models/RealEstateProductHouse";

// Partials
import { Home } from "./Home";
import { Mock } from "./Mock";

export const Box = {
    Home: (args: IMRealEstateProductsHouse & { index: number, onSetHousePosition: () => void }): JSX.Element => <Home {...args} />,
    Mock: (): JSX.Element => <Mock />
}