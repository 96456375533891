import React from 'react';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import { Container } from './styles';

const SimulationConditions: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  return (
    <Container className="container card-dashboard justify-content-center p-4 col-md-5 col-12 mb-3">
      <div className="mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h6 mb-3">Condições da simulação</h2>
        </div>
      </div>
      <div className="pb-3">
        <div className="d-flex subtitle align-items-center mb-3">
          <div className="col-7 d-flex align-items-center">
            <h4 className="mb-0">Prazo do financiamento</h4>
          </div>
          <div className="mb-0 ms-auto color-text">
            {serviceOrder.simulacao?.prazo} anos
          </div>
        </div>
        <div className="d-flex subtitle align-items-center">
          <div className="col-7 d-flex align-items-center">
            <h4 className="mb-0">Taxa de juros</h4>
          </div>
          <div className="mb-0 ms-auto color-text">
            {serviceOrder.simulacao?.taxa} a.a
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SimulationConditions;
