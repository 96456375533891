import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import {
  DropdownChangeEvent,
  Dropdown as DropdownPrime,
} from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options?: SelectItemOptionsType;
  error?: string;
  hasError?(hasError: boolean): void;
  handleChange?(e: DropdownChangeEvent): void;
}

const Dropdown: FC<InputProps> = ({
  name,
  options,
  hasError,
  handleChange,
  className,
  error: errorData,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, error, registerField, defaultValue } = useField(name);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <DropdownPrime
            name={name}
            value={value}
            options={options}
            defaultValue={defaultValue}
            showClear
            className="w-100 border-0 focus:border-0 focus:outline-0 bg-transparent"
            {...rest}
            onChange={(e) => {
              setValue(e.value);
              handleChange && handleChange(e);
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </Container>
      {(errorData || error) && (
        <span className="small text-danger error">{errorData || error}</span>
      )}
    </>
  );
};

export default Dropdown;
