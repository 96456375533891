import React, { useCallback, useEffect, useState } from 'react';

import { MdOutlineFileUpload } from 'react-icons/md';
import Swal from 'sweetalert2';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMFileType } from '~/models/FileTypes';
import { IMServiceOrderFile } from '~/models/ServiceOrderFile';

import { IoMdAlert } from 'react-icons/io';
import api from '~/services/api';

import { BsTrashFill } from 'react-icons/bs';
import Loading from '~/components/Loading';
import { IMParticipant } from '~/models/Participant';
import generateUrlBlob from '~/utils/generateUrlBlob';
import ModalUpload from '../ModalUpload';
import { BoxDocument, InformationBox, Modal } from './styles';

interface IFileData {
  id: number;
  file: string;
}

interface IModalShowFilePrams {
  fileType: IMFileType;
  onHide(): void;
  participant?: IMParticipant;
  seller?: number;
  onChangeFiles?(): void;
}

const ModalShowFiles: React.FC<IModalShowFilePrams> = ({
  fileType,
  onHide,
  participant,
  seller,
  onChangeFiles,
}) => {
  const { serviceOrder } = useServiceOrder();
  const [showLoading, setShowLoading] = useState(false);
  const [showModalSendDocuments, setShowModalSetDocuments] = useState(false);
  const [hasFileLoaded, setHasFileLoaded] = useState(false);
  const [fileData, setFileData] = useState<IFileData[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<{ id: number }[]>([]);

  const renderUploadFileButton = useCallback(() => {
    if (fileType.grupo >= 4 && fileType.grupo !== 7) {
      return <></>;
    }
    return (
      <div className="mb-3 new-file-box">
        <button
          type="button"
          className="border-0"
          onClick={() => setShowModalSetDocuments(true)}
        >
          <MdOutlineFileUpload size={23} className="mx-2" />
          <b>Subir outro arquivo</b>
        </button>
      </div>
    );
  }, [fileType.grupo]);

  const handleDeleteDocument = useCallback(async () => {
    try {
      fileType.files?.forEach(async (document) => {
        await api.delete(
          `builders/service-orders/${serviceOrder.id}/files/${document.id}`
        );
      });
    } catch (err) {
      await Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro, tente novamente',
      });
    }
  }, [fileType.files, serviceOrder.id]);

  const handleDownloadFile = useCallback(
    async (file: IMServiceOrderFile) => {
      setShowLoading(true);
      await api
        .get(
          `${process.env.REACT_APP_API_URL}/builders/service-orders/${serviceOrder.id}/files/${file.id}`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const urlData = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = urlData;
          link.download = file.nome;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(urlData);
            link.remove();
          }, 100);
          setShowLoading(false);
        });
    },
    [serviceOrder.id]
  );

  const renderDeleteButton = useCallback(() => {
    if (fileType.id >= 4) {
      return <></>;
    }
    return (
      <button type="button" className="border-0" onClick={handleDeleteDocument}>
        <BsTrashFill size={20} color="#FC5D4A" className="ms-2" />
      </button>
    );
  }, [handleDeleteDocument, fileType.id]);

  useEffect(() => {
    if (fileType.files?.length === 0) {
      setShowModalSetDocuments(true);
    }
  }, [fileType.files?.length, fileType.id]);

  const getFileData = useCallback(
    async (document: IMServiceOrderFile) => {
      api
        .get(
          `${process.env.REACT_APP_API_URL}/builders/service-orders/${
            serviceOrder.id
          }/files/${document?.id || -1}`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          if (response.status === 204) {
            setHasFileLoaded(false);
            return;
          }

          setFileData([
            ...fileData,
            {
              id: document.id,
              file: generateUrlBlob(response),
            },
          ]);

          setHasFileLoaded(true);
        })
        .catch((rejects) => {
          setHasFileLoaded(false);
        });
    },
    [fileData, serviceOrder.id]
  );

  const renderVisualizerDocument = useCallback(
    (document: IMServiceOrderFile) => {
      if (
        !fileData.find((file) => file.id === document.id)?.file &&
        !loadingFiles.find((file) => file.id === document.id)
      ) {
        setLoadingFiles([...loadingFiles, { id: document.id }]);
        getFileData(document);
      }
      if (document?.mimeType?.split('/')[0] === 'image') {
        return (
          <div className="d-flex justify-content-center align-content-center">
            {fileData.find((file) => file.id === document.id)?.file ? (
              <img
                src={
                  fileData.find((file) => file.id === document.id)?.file ?? ''
                }
                alt={document.nome}
                style={{ maxWidth: '100%', height: '300px' }}
              />
            ) : (
              ''
            )}
          </div>
        );
      }
      if (
        document?.mimeType === 'application/pdf' ||
        document?.mimeType === 'application/octet-stream'
      ) {
        return (
          <iframe
            title={`${document?.id?.toString()} - ${document?.nome}`}
            src={fileData.find((file) => file.id === document.id)?.file ?? ''}
            style={{ width: '100%', height: '300px', maxWidth: '100%' }}
          />
        );
      }
      return <></>;
    },
    [fileData, getFileData, loadingFiles]
  );

  return (
    <>
      <Modal show onHide={onHide} size="lg">
        <Modal.Header className="border-0">
          <div className="d-flex align-items-center w-full mt-3">
            <div className="col-11 d-flex align-items-center">
              <h4>{fileType.tipo_arquivo}</h4>
            </div>
            <div className="col-1 d-flex justify-content-end fw-bold">
              <button type="button" className="btn-cancel" onClick={onHide}>
                X
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="border-0 mx-5">
          {(serviceOrder.status_id === 3 ||
            serviceOrder.status_id === 5 ||
            serviceOrder.status_id === 9 ||
            serviceOrder.status_id === 11 ||
            serviceOrder.status_id === 18 ||
            serviceOrder.status_id === 20) &&
            renderUploadFileButton()}

          {fileType.id >= 4 ? (
            <></>
          ) : (
            <div className="mb-3">
              <p className="mb-0">Documentos enviados</p>
            </div>
          )}

          {fileType.files?.map((document) => (
            <div key={document.id}>
              <>
                <BoxDocument className="row border mt-2">
                  <div>
                    <button
                      type="button"
                      onClick={() => handleDownloadFile(document)}
                    >
                      {document.nome}
                    </button>
                    {document.obsCorresp && (
                      <div className="mt-2 obs-corresp">
                        <div className="fw-bold">
                          Observação do correspondente:{' '}
                        </div>
                        {document.obsCorresp}
                      </div>
                    )}
                  </div>
                  <div className="col-1">
                    {document.resultado_id === 0 ? (
                      <IoMdAlert size={25} color="orange" />
                    ) : (
                      document.resultado_id !== 1 &&
                      (serviceOrder.status_id === 3 ||
                        serviceOrder.status_id === 5 ||
                        serviceOrder.status_id === 9 ||
                        serviceOrder.status_id === 11) &&
                      renderDeleteButton()
                    )}
                  </div>
                </BoxDocument>
                {fileType.grupo <= 3 ? (
                  <InformationBox
                    className={document.resultado_id === 1 ? 'aproved' : ''}
                  >
                    <p className="mb-0">
                      {document.resultado_id === 0 || !document.resultado_id
                        ? 'Aguardando análise'
                        : document.resultado_id === 1
                        ? 'Aprovado'
                        : 'Reprovado'}
                    </p>
                  </InformationBox>
                ) : (
                  <div style={{ height: '300px' }}>
                    {renderVisualizerDocument(document)}
                  </div>
                )}
              </>
            </div>
          ))}

          {fileType && fileType?.files && fileType?.files?.length <= 0 && (
            <span className="p-2 mx-auto bg-yellow-400 text-black font-bold rounded-full mb-4 mt-4 select-none cursor-default w-[50%] flex flex-row items-center justify-center gap-4">
              Aguardando envio do arquivo
            </span>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 box-footer">
          <div className="buttons-fileType">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Fechar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalUpload
        fileType={fileType}
        show={showModalSendDocuments}
        onHide={() => setShowModalSetDocuments(false)}
        participant={participant}
        seller={seller}
        onChangeFiles={onChangeFiles}
      />
      <Loading active={showLoading} />
    </>
  );
};

export default ModalShowFiles;
