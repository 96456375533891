export function Mock() {
  return (
    <div
      className="flex flex-col items-start justify-start gap-[0.625rem] md:w-[9.875rem] md:h-[11.375rem] rounded-[0.312rem] pb-[0.375rem]
     bg-white overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-[0.3s] cursor-pointer"
    >
      <div className="flex-1 flex flex-col w-full max-h-[75%] items-start overflow-hidden object-fill bg-gray-100" />
      <div className="flex-1 w-full flex flex-col pl-[0.625rem] pr-[0.625rem] gap-[1rem]">
        <span className="text-[#707070] font-semibold leading-[1.1rem] w-full h-[1rem] bg-gray-100 animated-pulse" />
        <div className="w-full flex flex-col items-start">
          <div className="w-full flex flex-row flex-nowrap items-center justify-between ">
            <span className="flex-1 flex flex-row items-center gap-[0.125rem] h-[1rem] bg-gray-100 animate-pulse">
              <span className="font-[400] text-#B8B9BA text-[0.75rem]" />
            </span>
            <span className="flex-1 flex flex-row items-center gap-[0.125rem] h-[1rem] bg-gray-100 animate-pulse">
              <span className="font-[400] text-#B8B9BA text-[0.75rem]" />
            </span>
          </div>
          <div className="w-full flex flex-row flex-nowrap items-center justify-between ">
            <span className="flex-1 flex flex-row items-center gap-[0.125rem] h-[1rem] bg-gray-100 animate-pulse">
              <span className="font-[400] text-#B8B9BA text-[0.75rem]" />
            </span>
            <span className="flex-1 flex flex-row items-center gap-[0.125rem] h-[1rem] bg-gray-100 animate-pulse">
              <span className="font-[400] text-#B8B9BA text-[0.75rem]" />
            </span>
            <span className="flex-1 flex flex-row items-center gap-[0.125rem] h-[1rem] bg-gray-100 animate-pulse">
              <span className="font-[400] text-#B8B9BA text-[0.75rem]" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
