import styled, { css } from 'styled-components';

interface IContainer {
  show: boolean;
}

interface INav {
  show: boolean;
}

interface IButtonOpen {
  show: boolean;
}

interface IBackgroundOpacity {
  show: boolean;
}

export const Container = styled.div<IContainer>`
  transition-duration: 0.5s;
  width: 100%;
  height: 78px;

  .open-menu-text {
    position: absolute;
    left: ${(props) => (props.show ? '46.8%' : '47.5%')};
    top: ${(props) => (props.show ? '84vh' : '12px')};
    border: none;
    background-color: transparent;
  }

  .group-language {
    min-width: 50px;

    .language {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0;
    }
    .language.show {
      opacity: 1;
      visibility: visible;
      position: relative;
    }
  }

  .logo {
    transition-duration: 0.3s;
    overflow: hidden;
    width: 50px;

    img {
      width: 90px;
      margin-left: 4px;
    }
  }

  div.content {
    z-index: 400;
    position: fixed;
    top: 0;
    transition-duration: 0.3s;
    width: 100%;
    background: #ffffff;
    height: ${(props) => (props.show ? '90vh' : '58px')};

    > div {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      > div {
        .menu {
          width: 100%;
          height: calc(90vh - 58px);
          position: absolute;
          transition-duration: 0.3s;
          top: 58px;
          left: ${(props) => (props.show ? '0' : '-1000px')};
          overflow-y: auto;
          overflow-x: hidden;

          ::-webkit-scrollbar-thumb {
            background-color: #373737;
          }

          h2 {
            font-weight: 600;
            font-size: 24px;
            color: #414142;
          }

          .separator {
            padding: 0;
            width: 3px;
            height: 100px;
            color: #b8b9ba;
            margin-left: 18px;
          }

          .link {
            color: #707070;
            font-size: 18px;
            font-weight: 500;
            .unlock {
              color: #fff;
              background-color: #39a887;
              border: solid 4px #cde9e1;
              border-radius: 50px;
            }
            .lock {
              color: #b2b2b2;
              background-color: #f4f4f4;
              border: solid 4px #f4f4f4;
              border-radius: 50px;
            }
          }

          > div {
            min-height: calc(90vh - 58px);
            display: flex;
            flex-direction: column;
          }
        }
      }

      ::-webkit-scrollbar-thumb {
        transition-duration: 0.3s;
        background-color: #37373700;
        opacity: 0;
      }

      :hover {
        ::-webkit-scrollbar-thumb {
          background-color: #373737;
          opacity: 1;
        }
      }
    }
  }

  div.footer {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.show ? '1' : '0')};
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    overflow: hidden;
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;

    p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #bcbcbc;

      b {
        color: #818181;
      }
    }
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    height: 67px;

    .logo {
      width: ${(props) => (props.show ? '220px' : '50px')};

      img {
        width: unset;
      }
    }

    div.content {
      width: 100%;
      min-height: ${(props) => (props.show ? '90vh' : '67px')};
      height: auto;

      > div {
        height: ${(props) => (props.show ? '90vh' : '67px')};
        box-shadow: 0px 4px 17px rgba(0, 0, 0, 40%);

        > div {
          margin-top: ${(props) => (props.show ? '0' : '-100vh')};

          .menu {
            position: unset;
            left: 0;
            overflow-y: unset;
            overflow-x: unset;
            height: unset;

            > div {
              min-height: calc(67px - 140px);
            }
          }
        }
      }
    }

    div.footer {
      width: ${(props) => (props.show ? '267px' : '50px')};
      margin-bottom: unset;

      p {
        width: 267px;
      }
    }
  }
`;

export const BackgroundOpacity = styled.button<IBackgroundOpacity>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(5, 5, 5, 0.21);
  z-index: 2;
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  border: none;
  cursor: default;
`;

export const Nav = styled.nav<INav>`
  .user-container {
    width: ${(props) => (props.show ? '267px' : '100%')};
    transition-duration: 0.3s;
    overflow: hidden;
    margin: 35px auto 0;

    .user-card {
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? '1' : '0')};
      visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
      background: #292929;
      border-radius: 12px;
      width: 267px;

      p {
        color: #d0d0d0;
      }

      a {
        color: #5e5e5e;
      }

      button {
        color: #fc5d4a;
      }
    }

    hr {
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? '1' : '0')};
      visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
      background: #303030;
    }
  }

  @media screen and (min-width: 992px) {
    border-radius: 0px 20px 20px 0px;
  }
`;

export const ButtonOpen = styled.div<IButtonOpen>`
  width: 50px;
  height: 50px;
  border: none;
  background: transparent;

  > div {
    width: 20px;
    height: 20px;

    span {
      display: block;
      background-color: #8692a6;
      height: 2px;
      width: 100%;
      border-radius: 5px;
      margin-left: auto;
      transition-duration: 0.3s;

      :nth-child(2) {
        width: 50%;
        margin: 5px 0 5px auto;
      }

      ${(props) => props.show
        && css`
          background-color: #cccccc;

          :nth-child(1) {
            transform: rotateZ(45deg);
          }

          :nth-child(2) {
            opacity: 0;
          }

          :nth-child(3) {
            margin-top: -14px;
            transform: rotateZ(-45deg);
          }
        `}
    }
  }

  :focus {
    box-shadow: none !important;
  }

  @media screen and (min-width: 992px) {
    background: #39a887;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 50%;

    svg {
      transition-duration: 0.3s;
      transform: rotateZ(${(props) => (props.show ? '180deg' : '0')});
    }

    :focus {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
    }
  }
`;
