import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

// Interfaces

// FIXME - eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IButtonProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> { }

export function Button({
  className,
  children,
  ...props
}: IButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={`text-[#fff] font-bold bg-[#39a887] w-[18.75rem] h-[3.5rem] rounded-[1.875rem] flex flex-row flex-nowrap items-center justify-center gap-1  p-4 ${className ?? ''
        }`}
    >
      {children}
    </button>
  );
}
