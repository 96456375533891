import React, { useState } from 'react';
import Loading from '~/components/Loading';
import { useProfile } from '~/hooks/Profile';
import { BodyStructure, Container } from './styles';

import TabButtons from '../TabButtons';
import Engineer from '../../Tabs/Engineer';
import Payments from '../../Tabs/Payments';

import Construtech from '../../Tabs/Construtech';

const LayoutProfile: React.FC = () => {
  const { loading, engineer } = useProfile();
  const [step, setStep] = useState<'PersonalData' | 'Construtech' | 'Payments'>(
    'PersonalData'
  );

  return (
    <div className="container">
      <div className="row">
        <BodyStructure className="col">
          <div className="d-flex">
            <Container className="py-5 px-5 px-lg-4 w-100">
              <div className="row ">
                <div className="d-flex col-auto col-lg-8 top-align align-items-center">
                  <h1 className="h4-lg fw-medio text-dark-gray ms-5">Perfil</h1>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-12 mt-2">
                  <div className="row d-flex ms-5">
                    <div className="col-12 mt-2">
                      <div className="row d-flex overflow-auto overflow-sm-none tabs">
                        {!engineer?.cpf && (
                          <p className="small text-danger error">
                            Complete seus dados.
                          </p>
                        )}
                        <TabButtons step={step} setStep={setStep} />
                      </div>
                      <div>
                        {step === 'PersonalData' && <Engineer />}
                        {step === 'Construtech' && <Construtech />}
                        {step === 'Payments' && <Payments />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </BodyStructure>
      </div>
      <Loading active={loading} />
    </div>
  );
};

export default LayoutProfile;
