// Icons
import { FaSpinner } from "react-icons/fa";

// Types
import { INotifyProps } from "./types";

export function Notify({showme}: INotifyProps) {
    return (
        <div
            data-showme={showme}
            className="
                w-full md:w-1/3
                h-16 
                z-[999]
                rounded-lg
                absolute
                -left-full
                data-[showme=true]:left-4                
                transition-all
                duration-[0.3s]
                bottom-4
                bg-slate-100
                border-2 border-slate-200
                shadow-md
                flex flex-row flex-nowrap items-center justify-center
                gap-2
                cursor-default
                select-none
            "
        >
            Preparando suas informações...
            <FaSpinner className="animate-spin text-slate-500 mt-1"  />
        </div>
    )
}