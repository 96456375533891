import React, { useCallback, useEffect, useState } from 'react';
import { BsFillCheckCircleFill, BsStickiesFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

import { IconContext } from 'react-icons';
import { RiArrowLeftSLine } from 'react-icons/ri';
import Swal from 'sweetalert2';
import MiniAvatar from '~/components/MiniAvatar';

import { useProfile } from '~/hooks/Profile';
import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import Toast from '~/utils/toast';
import {
  Avatar,
  Background2,
  Container,
  Container3,
  Container4,
} from './styles';

interface IMarketing {
  id: string;
  title: string;
  description: string;
  url: string;
  created_at: string;
  filename: string;
  marketing_user?: {
    id?: number;
    used?: number;
  };
}

const Marketing: React.FC = () => {
  const { user } = useAuthContext();
  const { validateEngineerData } = useProfile();
  const { photoEngineer } = useProfile();
  const [marketings, setMarketings] = useState<IMarketing[]>([]);
  const [, setShow] = useState(false);
  const history = useHistory();
  const handleBack = (): void => {
    history.goBack();
  };

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_PREFIX_ROUTE}/marketings/`)
      .then((response) => {
        setMarketings(response.data);
      });
  }, []);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister, marketings]);

  // FIXME - eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeCheckbox = (e: any, id: string) => {
    if (!e.target.checked) {
      api
        .delete(`${process.env.REACT_APP_PREFIX_ROUTE}/marketings/${id}`)
        // FIXME - eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          api
            .get(`${process.env.REACT_APP_PREFIX_ROUTE}/marketings/`)
            .then((response) => {
              setMarketings(response.data);
            });
        });
    }
  };
  const handleClickMaterials = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/marketing`);
  }, [history]);

  const handleCopyLink = useCallback((marketing: IMarketing) => {
    try {
      // const completeLink = `https://builder.mcf.house/meu-site`;
      navigator.clipboard.writeText(marketing.description);

      setShow(false);
      Toast.fire({
        icon: 'success',
        title: 'Texto copiado com sucesso!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro, tente novamente',
      });
    }
  }, []);

  return (
    <Container className="py-5">
      <div className="row mb-3 px-4">
        <div className="d-flex col-lg-8 align-items-center">
          <button
            type="button"
            className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4"
            onClick={handleBack}
          >
            <RiArrowLeftSLine className="back" size={28} color="#B8B9BA" />
          </button>
          <h1 className="h4-lg fw-medio text-dark">Marketing</h1>
        </div>
        <div className="col-lg-4">
          <div className="row justify-content-end">
            <div className="col-lg-2">
              <Avatar src={photoEngineer} className="mx-auto" />
            </div>
            <div className="col-lg-6">
              <div>
                <p className="d-flex fw-semibold small text-dark-gray mb-0">
                  {user!.name}
                </p>
                {user!.isAdmin && <p className="fw-light"> Admin </p>}
                {user!.isConstrutor && <p className="fw-light"> Construtor </p>}
              </div>
            </div>
            <div className="col-1">
              <MiniAvatar />
            </div>
          </div>
        </div>
      </div>
      <div className="px-5">
        <div className="row">
          <div className="col-4">
            <h5 className="text-dark ">
              Meus Downloads
              <IconContext.Provider value={{ color: '#39A887' }}>
                <BsFillCheckCircleFill className="px-2 h1 py-1" />
              </IconContext.Provider>
            </h5>
          </div>
        </div>
        <div className="col gap gap-1 justify-content-end text-end">
          <button
            type="button"
            className="bottom-downloads"
            onClick={handleClickMaterials}
          >
            Materiais
          </button>
          <button type="button" className="bottom-materais">
            Meus downloads
          </button>
        </div>
      </div>
      <div className="row gap gap-4 py-5 mb-5 px-5 text-black-50">
        {marketings
          && marketings
            .filter((m) => m.marketing_user)
            .map((marketing) => (
              <Container4 key={marketing.id}>
                <div className="col px-4 shadow bg-body borderr">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="mb-3 title mt-3">{marketing.title}</h5>
                    </div>
                    <h6 className="scroll">{marketing.description}</h6>
                  </div>
                  <div className="row">
                    <div className="col" />
                    <div className="col d-flex justify-content-end">
                      <button
                        type="button"
                        className="border-0 col text-primary bg-verdee mb-4"
                        onClick={() => handleCopyLink(marketing)}
                      >
                        <BsStickiesFill size={25} className="me-2" />
                        Copiar texto
                      </button>
                    </div>
                  </div>

                  <div className="col-12">
                    <Container3 className="d-flex">
                      <Background2
                        className="d-flex justify-content-start shadow p-5 mb-2 bg-body"
                        url={`${process.env.REACT_APP_API_URL}/marketings/download/${marketing.id}`}
                      />
                    </Container3>
                  </div>
                  <div className="row">
                    <div className="col-auto py-2 ">
                      <input
                        type="checkbox"
                        id="topping"
                        name="topping"
                        value="Paneer"
                        defaultChecked={marketing.marketing_user !== null}
                        onChange={(e) => handleChangeCheckbox(e, marketing.id)
                        }
                      />
                    </div>

                    <div className="col px-1 utilizado">Utilizado</div>
                  </div>
                </div>
              </Container4>
            ))}
      </div>
    </Container>
  );
};

export default Marketing;
