import styled from 'styled-components';

export const Container = styled.section`
  .fondo2 {
    @media (min-width: 991px) {
      height: 118px;
      background: #f9f9f9;
      border-radius: 12px;
    }
  }

  .centrar {
    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
      font-style: normal;
    }
  }

  .amount-title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #707070;
  }

  .amount-title p {
    margin: 0;
    width: fit-content;
  }

  .net-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #00b73e;
  }

  .row-3 {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #414142;
  }

  .amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #414142;
  }
`;

export const VR = styled.div`
  .vl {
    border-top: unset !important;
    border-left: 1px solid #b8b9ba;
    height: 40px;
  }

  @media (max-width: 557px) {
    .vl {
      border-top: 1px solid #b8b9ba !important;
      border-left: unset !important;
      width: 220px;
    }
  }
`;
