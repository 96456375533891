import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { HiChartBar, HiCheck } from 'react-icons/hi';
import { IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { RiLogoutCircleFill } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import Loading from '~/components/Loading';
import Notifications from '~/components/Notifications';
import Pagination from '~/components/Pagination';

import avatar from '~/assets/defaults/avatar.png';
import house from '~/assets/defaults/house.svg';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { useSimulation } from '~/hooks/Simulation';
import { useAuthContext } from '~/hooks/contexts/Auth';
import api from '~/services/api';
import {
  Avatar,
  Buttons,
  Container,
  Modal,
  Os,
  Oss,
  Profile,
  RequestsInfo,
} from './styles';

interface IBuilder {
  name: string;
  construtech?: {
    razao_social: string;
  };
  engenheiro?: {
    nome: string;
  };
}

interface IOsResponse {
  id: number;
  created_at: string;
  construtor?: {
    name: string;
  };
  finalidade: {
    id: number;
    finalidade: string;
    descricao: string;
    imagem?: string;
  };
  finality?: {
    finalidade: string;
  };
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    id: number;
    os_id: number;
    amortizacao_id: number;
    vlrRenda: number;
    prazo: number;
    taxa: number;
    vlrParcInic: number;
    vlrParcFinal: number;
    vlrFinanciado: number;
    vlrImovPronto: number;
    vlrEntrada: number;
    vlrEntradaReal: number;
    vlrFgts: number;
    vlrTerreno: number;
    vlrSubsidio: number;
    vlrAvaliado: number;
    vlrAprovado: number;
    vlrSolicitado: number;
    vlrImovel: number;
    tipo_imovel_id?: number;
    comentario_analise?: string;
    dt_analise?: string;
    resultAnalise_id?: number;
    amortizacao?: {
      id: number;
      ordem: number;
      amortizacao: string;
      banco_id: number;
      taxa: number;
      percentual: number;
      prazo: number;
      funcao: string;
      indexador?: number;
      taxa_nominal?: number;
      teto_maximo?: number;
      renda_min?: number;
      renda_max?: number;
      observacao?: string;
      enfase: number;
      banco: {
        id: number;
        banco: string;
      };
    };
  };
  clientes: {
    dt_nascimento: string;
    usuario_id: number;
  };
}

interface IRequestResponse {
  id: number;
  created_at: string;
  os: IOsResponse;
  builder: IBuilder;
  status: {
    id: number;
    status: string;
  };
}

interface IRequestData {
  data: IRequestResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IRequest {
  id: number;
  os_id: string;
  builder: string;
  date: string;
  modality: string;
  status: string;
}

interface IOsData {
  data: IOsResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IOs {
  id: string;
  builder: string;
  date: string;
  modality: string;
  status: string;
  showButton: boolean;
}

interface IData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const Requests: React.FC = () => {
  const { signOut, user } = useAuthContext();
  const { validateEngineerData } = useProfile();
  const [blockPage, setBlockPage] = useState(false);
  const history = useHistory();
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const { setSimulation } = useSimulation();
  const [osSelected, setOsSelected] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [openRequestsQtd, setOpenRequestsQtd] = useState('00');
  const [answeredRequestsQtd, setAnsweredRequestsQtd] = useState('00');
  const [requests, setRequests] = useState<IRequest[]>([]);
  const [requestsData, setRequestsData] = useState<IData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [ossFullData, setOssFullData] = useState<IOsResponse[]>([]);
  const [oss, setOss] = useState<IOs[]>([]);
  const [ossData, setOssData] = useState<IData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [loadingOss, setLoadingOss] = useState(false);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const loadRequests = useCallback(
    async (page: number) => {
      setLoadingRequests(true);
      try {
        const response = await api.get<IRequestData>('builders/requests', {
          params: {
            page,
            take: 3,
          },
        });
        const dataFilterRequest: IRequestResponse[] = response.data.data.filter(
          (request) => request.os.clientes.usuario_id === user!.id
            && request.status.id !== 2
        );
        const data: IRequest[] = dataFilterRequest.map((request) => {
          let builder = request.builder?.name;
          if (request.builder?.construtech) {
            builder = request.builder.construtech.razao_social;
          } else if (request.builder?.engenheiro) {
            builder = request.builder.engenheiro.nome;
          }
          const nameParts = builder ? builder.split(' ') : ' ';

          return {
            id: request.id,
            os_id: request.os.id.toString().padStart(4, '0'),
            builder:
              nameParts.length === 1
                ? `${nameParts[0]} `
                : `${nameParts[0]} ${nameParts[1]}`,
            date: format(parseISO(request.created_at), 'dd/MM/yyyy'),
            modality: request.os.finality
              ? request.os.finality.finalidade
              : '-',
            status: request.status?.status,
          };
        });

        setRequestsData({
          from: response.data.from,
          to: response.data.to,
          total: response.data.total,
          current_page: response.data.current_page,
        });

        setRequests(data);
      } catch (error) {
        Toast.fire({
          icon: 'warning',
          title: 'Erro no carregamento das informações',
        });
      } finally {
        setLoadingRequests(false);
      }
    },
    [user]
  );

  const loadOss = useCallback(async (page: number) => {
    setLoadingOss(true);

    try {
      const responseOSs = await api.get<IOsData>('builders/service-orders', {
        params: {
          page,
          take: 3,
        },
      });
      const dataOss: IOs[] = responseOSs.data.data.map((os) => {
        let status = '-';
        if (os.status) {
          if (os.status.id === 2) {
            if (!os.construtor) {
              if (os.requests.length <= 3) {
                status = 'Selecionar um construtor 🚧';
              } else {
                status = 'Aguardando proposta ⏱️';
              }
            } else {
              status = 'O construtor ja foi selecionado';
            }
          } else {
            status = os.status?.status;
          }
        }
        return {
          id: os.id.toString().padStart(4, '0'),
          builder: os.construtor ? os.construtor.name : '-',
          date: format(parseISO(os.created_at), 'dd/MM/yyyy'),
          modality: os.finalidade ? os.finalidade.finalidade : '-',
          status,
          showButton: os.requests.length <= 3,
        };
      });
      setOssData({
        from: responseOSs.data.from,
        to: responseOSs.data.to,
        total: responseOSs.data.total,
        current_page: responseOSs.data.current_page,
      });
      setOss(dataOss);
      setOssFullData(responseOSs.data.data);
    } catch (error) {
      Toast.fire({
        icon: 'warning',
        title: 'Erro no carregamento da ordem de serviço',
      });
    } finally {
      setLoadingOss(false);
    }
  }, []);

  useEffect(() => {
    loadRequests(1).then(async () => {
      await loadOss(1);

      const responseOpenRequests = await api.get('builders/requests/open');
      const responseAnsweredRequests = await api.get(
        'builders/requests/answered'
      );
      if (typeof responseOpenRequests.data === 'number') {
        setOpenRequestsQtd(
          responseOpenRequests.data.toString().padStart(2, '0')
        );
      }
      if (typeof responseAnsweredRequests.data === 'number') {
        setAnsweredRequestsQtd(
          responseAnsweredRequests.data.toString().padStart(2, '0')
        );
      }
    });
  }, [loadOss, loadRequests, openRequestsQtd, answeredRequestsQtd]);

  const handleClickDelete = useCallback(
    (request_id) => {
      Swal.fire({
        icon: 'warning',
        title: 'Tem certeza que\ndeseja excluir?',
        text: 'Excluindo sua solicitação você apagará ela\u00A0para\u00A0sempre',
        showCloseButton: true,
        confirmButtonText: 'Excluir',
        confirmButtonColor: '#FF1A50',
        iconColor: '#FF1A50',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`builders/requests/${request_id}`);
          const newRequests = requests.filter(
            (request) => request.id !== request_id
          );
          setRequests(newRequests);
          setOsSelected('');
          Toast.fire({
            icon: 'success',
            title: 'Solicitação deletada!',
          });
        }
      });
    },
    [requests]
  );

  const handleAcceptRequest = useCallback(
    async (id: number, status: number, page: number) => {
      // getting requests by its id and setting new status
      const data = await api.get(`builders/requests/${id}`);
      const responseRequest = {
        id: data.data.id,
        os_id: data.data.os_id,
        builder_id: data.data.builder_id,
        status_id: status,
        answered: data.data.answered,
      };

      const requestsByOsId = await api.get(
        `builders/requests/oss/${data.data.os_id}`
      );

      const otherRequestsId: number[] = [];

      requestsByOsId.data.data.forEach(
        (req: {
          id: number;
          os_id: number;
          builder_id: number;
          status_id: number;
          answered: number;
        }) => {
          if (req.id !== id) {
            otherRequestsId.push(req.id);

            const sendData = {
              id: req.id,
              os_id: req.os_id,
              builder_id: req.builder_id,
              status_id: status === 2 ? 3 : req.status_id,
              answered: req.answered,
            };

            api.put(`builders/requests/${req.id}`, sendData);

            api.delete(`builders/requests/${req.id}`);
          }
        }
      );

      if (status === 2) {
        const clientOS = await api.get(
          `builders/customers/oss/${data.data.os_id}`
        );

        const dataOs = await api.get(
          `builders/service-orders/${data.data.os_id}/${clientOS.data[0].cliente_id}`
        );

        // adding bulder_id to serviceOrder
        const modOs = {
          ...dataOs.data,
          construtor_id: data.data.builder_id,
        };

        api.put(
          `builders/service-orders/${data.data.os_id}/${clientOS.data[0].cliente_id}`,
          modOs
        );
      }

      // update current requests status
      api.put(`builders/requests/${id}`, responseRequest);

      Toast.fire({
        icon: 'success',
        title: 'Solicitação modificada com sucesso!',
      });
      // if current requests is being rejected, then delete it
      if (status === 3) {
        handleClickDelete(id);
      }
      // load requests again
      loadRequests(page);
      loadOss(1);
    },

    [handleClickDelete, loadOss, loadRequests, user]
  );

  const handleClose = useCallback(() => {
    setOsSelected('');
    setShowEdit(false);
  }, []);

  const handleChangePageRequest = useCallback(
    async (page: number) => {
      await loadRequests(page);
      const element = document.getElementById('requests');
      if (element) {
        element.scrollIntoView();
      }
    },
    [loadRequests]
  );

  const handleChangePageOss = useCallback(
    async (page: number) => {
      await loadOss(page);
      const element = document.getElementById('oss');
      if (element) {
        element.scrollIntoView();
      }
    },
    [loadOss]
  );

  const handleClickNovaOS = useCallback(() => {
    history.push('/finalidades');
  }, [history]);

  const handleClickArea = useCallback(
    async (url) => {
      const os = ossFullData.find(
        (osData) => osData.id === parseInt(osSelected, 10)
      );
      if (os && os.finalidade) {
        setServiceOrder({
          ...serviceOrder,
          finalidade: {
            id: os.finalidade.id,
            finalidade: os.finalidade.finalidade,
            descricao: os.finalidade.descricao,
            imagem: '',
          },
        });

        const [year, month, day] = os.clientes.dt_nascimento.split('-');
        const birthdate = new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10)
        );

        setSimulation({
          os_id: os.id,
          birthdate,
          revenue: os.simulacao.vlrRenda,
          amortization: os.simulacao.amortizacao
            ? {
              id: os.simulacao.amortizacao.id,
              title: os.simulacao.amortizacao.amortizacao,
              financedValue: os.simulacao.vlrFinanciado.toFixed(2),
              entryValue: os.simulacao.vlrEntrada.toFixed(2),
              realEntryValue: os.simulacao.vlrEntradaReal.toFixed(2),
              fgtsAmount: os.simulacao.vlrFgts.toFixed(2),
              rate: os.simulacao.taxa.toFixed(2),
              firstInstallment: os.simulacao.vlrParcInic.toFixed(2),
              lastInstallment: os.simulacao.vlrParcFinal.toFixed(2),
              selectedDeadline: os.simulacao.prazo,
            }
            : undefined,
        });
        history.push(`${process.env.PUBLIC_URL}${url}`);
      }
    },
    [
      history,
      osSelected,
      ossFullData,
      serviceOrder,
      setServiceOrder,
      setSimulation,
    ]
  );
  const [isChecked, setIsChecked] = useState(false);
  // checkbox para hablitar o botão de enviar
  /* const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  // cierra el modal cuando se envia.
  const handleOnChangEnviar = () => {
    setBlockPage(!blockPage);
  }; */
  useEffect(() => {
    setIsChecked(isChecked);
    setBlockPage(blockPage);
  }, [isChecked, blockPage]);

  const handleFilterModality = useCallback((value: string) => {
    if (value === '-') {
      return value;
    }
    return value.substring(0, value.indexOf('-'));
  }, []);

  return (
    <>
      <Container className="container py-5">
        <div className="row">
          <div className="col-lg-8 mb-4 mb-lg-0">
            <Profile className="d-flex align-items-center px-2">
              <Link
                to={`${process.env.PUBLIC_URL}/perfil`}
                className="border-0 bg-transparent d-flex align-items-center"
              >
                <Avatar src={avatar} className="me-3" />
              </Link>
              <p className="h5 h3-lg mb-0 fw-medium">Olá, {user!.name} 👋</p>
            </Profile>
          </div>

          <div className="col-lg-4">
            <Buttons className="d-flex justify-content-end px-2">
              <div className="d-flex me-3">
                <Link
                  to={`${process.env.PUBLIC_URL}/chat/clientes`}
                  className="border-0 bg-transparent d-flex align-items-center"
                >
                  Chat{' '}
                  <span className="ms-3">
                    <IoChatbubbleEllipsesSharp size={27} color="#707070" />
                  </span>
                </Link>
              </div>
              <Notifications />
              <button
                type="button"
                className="border-0 rounded-circle ms-3"
                onClick={() => signOut()}
              >
                <RiLogoutCircleFill size={27} color="#707070" />
              </button>
            </Buttons>
          </div>
          <div className="col-12 mt-5 mb-4">
            <RequestsInfo className="d-flex flex-wrap">
              <div className="p-2">
                <div className="px-3 px-sm-4 py-4">
                  <p className="h2 h1-lg mt-1 mt-lg-2 mb-3 mb-sm-4">
                    {ossData.total.toString().padStart(2, '0')}
                  </p>
                  <p className="mb-0 pt-lg-2">Quantidade de OS</p>
                </div>
              </div>
              <div className="p-2">
                <div className="px-3 px-sm-4 py-4">
                  <p className="h2 h1-lg mt-1 mt-lg-2 mb-3 mb-sm-4">
                    {openRequestsQtd}
                  </p>
                  <p className="mb-0 pt-lg-2">Solicitações em aberto</p>
                </div>
              </div>
              <div className="p-2">
                <div className="px-3 px-sm-4 py-4">
                  <p className="h2 h1-lg mt-1 mt-lg-2 mb-3 mb-sm-4">
                    {answeredRequestsQtd}
                  </p>
                  <p className="mb-0 pt-lg-2">Solicitações respondidas</p>
                </div>
              </div>
              {(!user!.isConstrutor?.quantity
                || user!.isConstrutor?.quantity > user!.qtdOS) && (
                  <div className="p-2">
                    <div className="px-2 px-sm-4 py-4 d-flex align-items-start justify-content-between position-relative">
                      <button
                        type="button"
                        className="d-flex align-items-center border-0 px-3 px-lg-4 py-1 d-flex align-items-center justify-content-center"
                        onClick={handleClickNovaOS}
                      >
                        <BsPlusCircleFill
                          size={12}
                          color="#FFFFFF"
                          className="me-1 me-lg-2"
                        />
                        <span>Nova OS</span>
                      </button>
                      <img src={house} alt="Casinha" />
                    </div>
                  </div>
                )}
            </RequestsInfo>
          </div>
          <div className="col-12 mb-4">
            <div className="px-2">
              <h2
                id="requests"
                className="fw-semibold pt-5 mb-4 text-dark-gray"
              >
                Solicitações
              </h2>
              <Oss className="w-100">
                {requests.length > 0 ? (
                  <>
                    {requests.map((request) => (
                      <Os
                        key={request.id}
                        active={osSelected === request.os_id}
                        className="py-4 px-4 px-lg-5 position-relative"
                      >
                        <div className="row mb-4">
                          <div className="col-4 col-lg-2 order-0">
                            <p className="fw-semibold">Nº OS</p>
                            <p>{request.os_id}</p>
                          </div>
                          <div className="col-6 col-lg-3 order-0 ">
                            <p className="fw-semibold">Construtor</p>
                            <p>{request.builder}</p>
                          </div>
                          <div className="col-6 col-md-4 col-lg-2 order-1">
                            <p className="fw-semibold">Data</p>
                            <p>{request.date}</p>
                          </div>
                          <div className="col-2 col-lg-5 order-0 order-lg-1 d-flex justify-content-end align-items-start">
                            <button
                              type="button"
                              className="border-0 bg-transparent d-flex align-items-center justify-content-center p-3 text-red"
                              onClick={() => handleClickDelete(request.id)}
                            >
                              <MdDelete
                                size={24}
                                color="#ff1a50"
                                className="me-2"
                              />
                            </button>
                          </div>
                          <div className="col-6 col-lg-3 order-1">
                            <p className="fw-semibold">Modalidade</p>
                            <p>{handleFilterModality(request.modality)}</p>
                          </div>
                          <div className="col-sm-8 col-lg-4 order-1">
                            <p className="fw-semibold">Status</p>
                            <p>{request.status}</p>
                          </div>
                          <div className="col-lg-5 order-1 d-flex justify-content-end align-items-end">
                            {request.status
                              === 'Selecionar um construtor 🚧' && (
                                <Link
                                  to={`${process.env.PUBLIC_URL}/selecionar-construtor`}
                                  className="border-0 btn-add px-5 py-2"
                                >
                                  Selecionar construtor
                                </Link>
                              )}
                            {request.status
                              === 'O construtor aceitou a proposta' && (
                                <div className="d-flex buttons">
                                  <button
                                    type="button"
                                    className="d-flex flex-column align-items-center btn-yes border-0 bg-transparent mx-3"
                                    onClick={() => handleAcceptRequest(
                                      request.id,
                                      2,
                                      requestsData.current_page
                                    )
                                    }
                                  >
                                    <span>
                                      <HiCheck size={18} color="#39A887" />
                                    </span>
                                    Sim
                                  </button>
                                  <button
                                    type="button"
                                    className="d-flex flex-column align-items-center btn-no border-0 bg-transparent mx-3"
                                    onClick={() => handleAcceptRequest(
                                      request.id,
                                      3,
                                      requestsData.current_page
                                    )
                                    }
                                  >
                                    <span>
                                      <CgClose size={18} color="#FF1A50" />
                                    </span>
                                    Não
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      </Os>
                    ))}
                  </>
                ) : (
                  <h4 className="text-center py-5 mb-0">
                    Nenhuma solicitação encontrada
                  </h4>
                )}
                <Loading active={loadingRequests} />
              </Oss>
              <Pagination
                fromData={requestsData.from || 0}
                toData={requestsData.to || 0}
                totalData={requestsData.total || 0}
                selectedPage={requestsData.current_page || 1}
                perPage={3}
                onChangePage={handleChangePageRequest}
              />
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="px-2">
              <h2 id="oss" className="fw-semibold mb-4 text-dark-gray pt-5">
                Minhas OS
              </h2>
              <Oss className="w-100">
                {oss.length > 0 ? (
                  <>
                    {oss.map((os) => (
                      <Os
                        key={os.id}
                        className="py-4 px-4 px-lg-5 position-relative"
                      >
                        <div className="row mb-4">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-4 col-lg-4">
                                <p className="fw-semibold">Nº OS</p>
                                <p>{os.id}</p>
                              </div>
                              <div className="col-6 col-lg-4">
                                <p className="fw-semibold">Construtor</p>
                                <p>{os.builder}</p>
                              </div>
                              <div className="col-6 col-lg-4">
                                <p className="fw-semibold">Data</p>
                                <p>{os.date}</p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6 col-lg-6 order-1">
                                <p className="fw-semibold">Modalidade</p>
                                <p>{handleFilterModality(os.modality)}</p>
                              </div>
                              <div className="col-6 col-lg-6 order-1">
                                <p className="fw-semibold">Status</p>
                                <p>{os.status}</p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-2 col-lg-5 order-0 order-lg-1 d-flex justify-content-end align-items-start">
                            <button
                              type="button"
                              className="border-0 bg-transparent d-flex align-items-center justify-content-center p-3"
                              onClick={() => handleClickEdit(os.id)}
                            >
                              <MdEdit size={24} className="me-2" />
                            </button>
                          </div> */}

                          <div className="d-flex row justify-content-center ms-0 p-0 col-lg-6 mb-2">
                            <div className="d-flex row justify-content-end align-items-end">
                              <div className="col-lg-6 p-1">
                                {os.status === 'Selecionar um construtor 🚧'
                                  && os.builder === '-' && (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/${os.id}/selecionar-construtor`}
                                      className="linkConstructor py-2 d-flex"
                                    >
                                      Selecionar construtor
                                    </Link>
                                  )}
                              </div>
                              <div className="col-lg-6 p-1">
                                <Link
                                  to={`/dashboard/${os.id}`}
                                  className="linkDashboard py-2 d-flex"
                                >
                                  Ir para o dashboard
                                  <HiChartBar size={18} className="ms-2" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Os>
                    ))}
                  </>
                ) : (
                  <h4 className="text-center py-5 mb-0">
                    Nenhuma OS cadastrada
                  </h4>
                )}
                <Loading active={loadingOss} />
              </Oss>
              <Pagination
                fromData={ossData.from || 0}
                toData={ossData.to || 0}
                totalData={ossData.total || 0}
                selectedPage={ossData.current_page || 1}
                perPage={3}
                onChangePage={handleChangePageOss}
              />
            </div>
          </div>
        </div>
      </Container>
      <Modal show={showEdit} onHide={handleClose} close>
        <Modal.Header className="border-0 ps-4 pt-4" closeButton />
        <Modal.Body>
          <div className="d-flex flex-column align-items-start px-5">
            <h4 className="text-center mb-3">Em qual área deseja editar?</h4>
            {/* <button
              type="button"
              onClick={() => handleClickArea('/')}
              className="py-1 my-1 border-0 bg-transparent"
            >
              Modalidade
            </button> */}
            <button
              type="button"
              onClick={() => handleClickArea('/simulador/resultado')}
              className="py-1 my-1 border-0 bg-transparent"
            >
              Banco (Simulação)
            </button>
            <button
              type="button"
              onClick={() => handleClickArea(
                `/monte-sua-casa/${parseInt(osSelected, 10)}/acabamento`
              )
              }
              className="py-1 my-1 border-0 bg-transparent"
            >
              Monte sua casa - Acabamento
            </button>
            <button
              type="button"
              onClick={() => handleClickArea(
                `/monte-sua-casa/${parseInt(
                  osSelected,
                  10
                )}/sobre-a-casa/quarto/quantidade`
              )
              }
              className="py-1 my-1 border-0 bg-transparent"
            >
              Monte sua casa - Sobre a casa
            </button>
            <button
              type="button"
              onClick={() => handleClickArea(`/dashboard/${parseInt(osSelected, 10)}`)
              }
              className="py-1 my-1 border-0 bg-transparent"
            >
              Resultado da simulação
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4" />
      </Modal>
    </>
  );
};

export default Requests;
