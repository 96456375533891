/**
 * THIS FUNCTION WILL RETURN A OBJECT THAT REPRESENT THE STATE OF METRICS FROM ACTUAL PAGE
 * 
 * @description THE METRICS ARE AS TIME, IF THE PAGE WAS RELOAD, NAVIGATE BY URL FROM BROWSER OR LINKS, IF WAS
 * BACK FORWARD FROM HISTORY FROM BROWSER AND OTHERS
 * 
 * ACTUALLY THE OBJECT ONLY CONTAINS DATA OF NAVIGATION
 * 
 * THE 'NAVIGATION' PROPERTY FROM OBJECT OF RETURN WILL BE EQUAL ONLY ONE OF OTHERS PROPERTY'S
 * 
 * @ignore THE ACTUAL VERSION OF TYPESCRIPT MARKS THE API NAVIGATION AS DEPRECATED, BUT THE WINDOW FROM BROWSER WILL ACCPET THE CALL
 * @see https://www.w3.org/TR/navigation-timing/#NavigationTiming2 THE SPECIFICATION FROM API NAVIGATION
 * @returns A object with actually state of navigation from page
 */
export function getNavigationDataPage() {
    try {
        if(!window || !window.performance) throw new Error('Some data is missing on this browser');

        return {
            navigation: window.performance.navigation.type, 
            backForward: window.performance.navigation.TYPE_BACK_FORWARD, 
            reload: window.performance.navigation.TYPE_RELOAD, 
            navigate: window.performance.navigation.TYPE_NAVIGATE, 
            reserved: window.performance.navigation.TYPE_RESERVED
        }

    } catch (error) {
        throw error;
    }
}