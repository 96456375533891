import { TiktokenModel, encodingForModel, getEncoding } from 'js-tiktoken';
import { OpenAI } from 'openai';

// Types
import { IMUser } from '~/models/User';
import api from '../api';
import {
  IChatCompletions,
  IChatUsage,
  IPrompt,
  IUserPrompt,
  IUsersPropmt,
  TAIGPTProperties,
  TCallbackStream,
  TPromptReturn
} from './types';


export class AIGPT {
  #openAI: OpenAI
  #user: IMUser | null
  #prompt: IPrompt | null = null
  prompts: Array<IPrompt> = []
  localyType: string | number = -1
  #model: TiktokenModel | null = null
  
  constructor(user: IMUser | null = null, prompt: IPrompt | null = null, prompts: Array<IPrompt> = [], localyType: string | number = -1, model: TiktokenModel = 'gpt-3.5-turbo') {
    this.#openAI = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_CHAT_GTP || '',
      dangerouslyAllowBrowser: true,
    });

    this.#user = user
    this.#prompt = prompt
    this.prompts = prompts
    this.localyType = localyType
    this.#model = model
  }

  /**
   * Setter
   */
  set(property: TAIGPTProperties | null = null, value: string | number | IPrompt | IPrompt[] | null = null){
    try {
      // @ts-ignore
      if(!property || !this[property]) throw new Error('The property is not valid')

      // @ts-ignore
      this[property] = value
    } catch (error) {
      throw error
    }
  }

  /**
   * Set a new model
   * @returns the statement of model
   */
  setTokenModel(model: TiktokenModel = 'gpt-3.5-turbo') {
    try {
      return encodingForModel(model);
    } catch (error) {
      return getEncoding('cl100k_base');
    }
  }

  /**
   * Tokenizer of message of prompt
   * @param prompt The prompt of completions
   * @returns The total of tokens
   */
  getTokens(prompt: string | null = null) {
    try {
      if (prompt === null) {
        throw new Error('You must provide');
      }
      return this.setTokenModel().encode(prompt);
    } catch (error) {
      return new Uint32Array([]);
    }
  }

  /**
   * Tokenizer all tokens of a list of prompts
   * @param prompts An list of prompts
   * @returns The total of tokens
   */
  getTokensByPrompts(prompts: IPrompt[] = []) {
    try {
      if (prompts.length === 0) {
        throw new Error('you must provide');
      }
      let numTokens = 0;
      prompts.forEach((prompt) => {
        //numTokens += 4;
        Object.keys(prompt).forEach((key) => {
          numTokens += this.getTokens(prompt[key]).length;
        });
        if (prompt?.name) {
          numTokens -= this.getTokens(prompt.name).length;
        }
      });
      //numTokens += 2;
      return numTokens;
    } catch (error) {
      return -1;
    }
  }

  /**
   * Get the main object with all users complementions
   * @returns The main object with all users completions
   */
  getChatCompletionsStoraged() {
    try {
      return JSON.parse(
        localStorage.getItem('MCF@OpenAI_CHAT') || '{}'
      ) as IUsersPropmt;
    } catch (error) {
      console.table(error);
    }
  }

  /**
     * Get a specify data from first level on main storaged object
     * @param keyFrom The key access of user
     * @param keyTo  The key on first level to return some data
     * @returns The value from keyTo of Keyfrom
     */
  getChatCompletionsStorageBy(
    keyFrom: string | null = null,
    keyTo: string | null = null
  ): TPromptReturn {
    try {
      if (keyFrom == null || keyTo == null) throw new Error('The keys are not valid');

      const USERS_PROMPT = JSON.parse(
        localStorage.getItem('MCF@OpenAI_CHAT') || '{}'
      ) as IUsersPropmt;

      if (Object.keys(USERS_PROMPT).length <= 0) throw new Error('The storaged object is not valid');

      return USERS_PROMPT?.[keyFrom]?.[keyTo];
    } catch (error) {
      console.table(error);
    }
  }

  /**
   * Set a especify on first level of main storaged object by keyFrom
    * @param keyFrom The key target, e.g the key access of user
    * @param keyTo The key sub-target rom keyFrom, there will receive the data
    * @param [data=null] The new data for storage on keyTo
    * @returns the main target with new values
    */
  setChatCompletionsStorageBy(
    keyFrom: string | null = null,
    keyTo: string | null = null,
    data: TPromptReturn | IUserPrompt
  ): IUserPrompt | undefined {
    try {
      if (keyFrom == null) throw new Error('The keys are not valid');

      if(!data) throw new Error('The data is not valid')

      const USERS_PROMPT = this.getChatCompletionsStoraged()

      if(!USERS_PROMPT) throw new Error('The main storage object is not valid')

      if(keyTo) {
        USERS_PROMPT[keyFrom][keyTo] = data as TPromptReturn;
      }else{        
        USERS_PROMPT[keyFrom] = data as any as IUserPrompt;
      }

      localStorage.setItem('MCF@OpenAI_CHAT', JSON.stringify(USERS_PROMPT));
      
      return USERS_PROMPT?.[keyFrom]
    } catch (error) {
      console.table(error);
    }
  }

  /**
   * Set a new main storaged object
   * @param [data=null] The data for storage
   * @returns the new storaged object
   */
  setChatCompletionsStorage(
    data: IUsersPropmt
  ): IUsersPropmt | undefined {
    try {    

      if(!data) throw new Error('The data is not valid')

      if(Object.keys(this.getChatCompletionsStoraged() || {}).length <= 0) 
        localStorage.setItem('MCF@OpenAI_CHAT', JSON.stringify(data));

      return this.getChatCompletionsStoraged()
    } catch (error) {
      console.table(error);
    }
  }

   /**
   * Get and storage localy the completions data
   * @param completions The completions data
   * @returns 
   */
  async setChatStreamDataToStorage(completions: IChatCompletions | null) {
    try {
      if(!completions) throw new Error('The completions is not valid');
      
      if(!this.#user?.unique_id) throw new Error('The user data is not valid');

      if(!this.prompts || this.prompts.length <= 0) throw new Error('The prompt cannot be empty')
            
      const DATA_CHAT_STORAGED = this.getChatCompletionsStoraged()?.[this.#user.unique_id]      
      const TOTAL_TOKENS_ON_PROMPT = this.getTokensByPrompts(this.prompts)

      const DATA_CHAT_TO_STORAGE: IUsersPropmt = {
        [this.#user.unique_id]: {
          id: this.#user.unique_id,
          model: {
            name: completions.model || this.#model as string,
            name_from_completions: completions.model,
            price: 0.0005,
            output_price: 0.0015,
            price_by_token: 0.005 / 1000
          },
          total_price: TOTAL_TOKENS_ON_PROMPT * 0.0005,
          total_price_described: (TOTAL_TOKENS_ON_PROMPT * 0.0005)
          .toLocaleString('pt-BR', { currency: 'BRL', style: 'currency'}),
          prompts: this.prompts.map(p => {
            const TOTAL_TOKENS_BY_PROMPT = this.getTokens(p.content).length

            return {
              content: p.content,
              role: p.role,
              name: p.name,
              origin: this.localyType,
              date: new Date(),
              price: TOTAL_TOKENS_BY_PROMPT * 0.0005,
              price_described: (TOTAL_TOKENS_BY_PROMPT * 0.0005)
                .toLocaleString('pt-BR', { currency: 'BRL', style: 'currency'}),
              tokens: TOTAL_TOKENS_BY_PROMPT
            }
          }),
          choices: completions.choices,
          usage: completions.usage,
          created_at: new Date(completions.created),
          updated_at: null,
        },
      }

      if(!DATA_CHAT_STORAGED || Object.keys(DATA_CHAT_STORAGED).length <= 0) {
        this.setChatCompletionsStorage(DATA_CHAT_TO_STORAGE);
        return
      }  

      const USAGE_RAW: IChatUsage = {
        completion_tokens: (DATA_CHAT_TO_STORAGE[this.#user.unique_id].usage?.completion_tokens || 0) + (DATA_CHAT_STORAGED.usage?.completion_tokens || 0),
        prompt_tokens:  (DATA_CHAT_TO_STORAGE[this.#user.unique_id].usage?.prompt_tokens || 0) + (DATA_CHAT_STORAGED.usage?.prompt_tokens || 0),
        total_tokens:  (DATA_CHAT_TO_STORAGE[this.#user.unique_id].usage?.total_tokens || 0) + (DATA_CHAT_STORAGED.usage?.total_tokens || 0),
      }      

      DATA_CHAT_STORAGED.total_price += DATA_CHAT_TO_STORAGE[this.#user.unique_id].total_price
      DATA_CHAT_STORAGED.total_price_described = DATA_CHAT_TO_STORAGE[this.#user.unique_id].total_price_described
      DATA_CHAT_STORAGED.model = DATA_CHAT_TO_STORAGE[this.#user.unique_id].model
      DATA_CHAT_STORAGED.prompts = [...DATA_CHAT_TO_STORAGE[this.#user.unique_id].prompts,...DATA_CHAT_STORAGED.prompts]
      DATA_CHAT_STORAGED['choices'] = [...DATA_CHAT_TO_STORAGE[this.#user.unique_id].choices,...(DATA_CHAT_STORAGED.choices || [])]
      DATA_CHAT_STORAGED.usage = {
        completion_tokens: USAGE_RAW.completion_tokens,
        prompt_tokens: USAGE_RAW.prompt_tokens,
        total_tokens: USAGE_RAW.total_tokens
      }
      DATA_CHAT_STORAGED.updated_at = new Date()

      this.setChatCompletionsStorageBy(this.#user.unique_id, null, DATA_CHAT_STORAGED)
    } catch (error) {
      throw error
    }
  }

  async onChatStream(
      messages: IPrompt[] | null = null, 
      model: TiktokenModel | null = 'gpt-3.5-turbo', 
      callback: TCallbackStream, forwadback: TCallbackStream, autoStoraged: boolean = true) {
    try {

      if(!messages && (!this.prompts || this.prompts.length <= 0)) throw new Error();

      const completions:IChatCompletions = await this.#openAI.chat.completions.create(
        {
          messages: messages || this.prompts,
          model: (model || this.#model) as TiktokenModel,
        }
      )

      if(!completions || completions.choices.length <= 0) throw new Error('The completions fails');
      
      if(callback && typeof callback === 'function') callback(true);
      
      if(forwadback && typeof forwadback === 'function') forwadback(completions);

      if(autoStoraged) await this.setChatStreamDataToStorage(completions);

      return completions
    } catch (error) {
      throw error
    }
  }

  /**
   * Send all chat open ai data to external database
   * @param data The user metrics and conversations
   * @param pTarget The index target to get the correct prompt
   * @returns 
   */
  async setOnExternalChatData(data: IUserPrompt | null = null, pTarget: number = -1, rep_id: string | number | null = null ) {
    try {
      const PROMPTS = (data?.prompts || []).filter(d => d.origin === this.localyType).slice(0, 3)      

      if(!data || !data?.choices) throw new Error('No choices available');

      if(!PROMPTS || PROMPTS.length <= 0) throw new Error(`No prompts available to ${this.localyType}`);

      if(pTarget <= -1 || !PROMPTS[pTarget] || pTarget > PROMPTS.length ) throw new Error('3');

      const NEXT_PROMPT_TARGET = (pTarget + 1)

      const response = await api.post(`builders/real-estate-products/${rep_id}/prompts`, {
        type_id: this.localyType,
        prompt: PROMPTS[pTarget].content,
        response: data.choices[0].message.content || '',
        model: data.model.name,
        price: data.model.price,
        output_price: data.model.output_price,
        price_by_token: data.model.price_by_token,
        token: data.prompts[pTarget].tokens,
      })

      if(response.status !== 201) throw new Error('4')

      console.table(PROMPTS)

      if(NEXT_PROMPT_TARGET <= PROMPTS.length && PROMPTS[NEXT_PROMPT_TARGET]) {
        await this.setOnExternalChatData(data, NEXT_PROMPT_TARGET, rep_id)
        return
      }

      return;
    } catch (error) {
      console.table(error)
    }
  }

}
