import React from 'react';
import textPrimary from '~/assets/banners/TextPrimary.svg';
import textSecondary from '~/assets/banners/TextSecondary.svg';
import projectos from '~/assets/banners/rectangle15.svg';
import projetos from '~/assets/defaults/mcf-projetoss.png';
import { ProjectProvider } from './Hooks/useProject';

import Header from './Components/Header';
import ProjectsList from './Components/ProjectsList';
import Summary from './Components/Summary';
import { BannerContainer, Container } from './styles';

const Projetos: React.FC = () => (
  <ProjectProvider>
    <div className="container">
      <Container className="row">
        <div className="col">
          <Header />
          <div className="px-2">
            <div className="px-4">
              {BannerContainer ? (
                <BannerContainer>
                  <div className="textBanner">
                    <img src={textPrimary} alt="Meus Projectos" />
                    <img
                      src={textSecondary}
                      alt="info banner meus projects"
                      className=""
                    />
                  </div>
                  <img
                    src={projectos}
                    alt="new"
                    color="#707070"
                    id="Iconnaranja"
                    className="naranja"
                  />
                </BannerContainer>
              ) : (
                <div className="bannner">
                  <img src={projetos} alt="projetos" className="bannner" />
                </div>
              )}

              <div className="py-5" />
              <div className="row d-flex justify-content-center">
                <ProjectsList />
                <Summary />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </ProjectProvider>
);

export default Projetos;
