import React, { createContext, useState, useContext, useMemo } from 'react';

interface DatatableContextData {
  per_page: number;
  totalRecords: number;
  page?: number;
  pageCount?: number;
  search?: string;
  column?: string;
  order?: string;
  setPerPage(perPage: number): void;
  setTotalRecords(totalRecords: number): void;
  setPage(page: number): void;
  setPageCount(pageCount?: number): void;
  setSearch(searchData?: string): void;
  setColumn(column?: string): void;
  setOrder(order?: 'asc' | 'desc'): void;
}

export const DatatableContext = createContext<DatatableContextData>(
  {} as DatatableContextData
);

interface IProps {
  children: React.ReactNode;
}

export const DatatableProvider: React.FC<IProps> = ({ children }) => {
  const [per_page, setPerPage] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [search, setSearch] = useState<string>();
  const [column, setColumn] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>();

  const params = useMemo(() => {
    return {
      per_page,
      totalRecords,
      page,
      pageCount,
      search,
      column,
      order,
      setPerPage,
      setTotalRecords,
      setPage,
      setPageCount,
      setSearch,
      setColumn,
      setOrder,
    };
  }, [
    per_page,
    totalRecords,
    page,
    pageCount,
    search,
    column,
    order,
    setPerPage,
    setTotalRecords,
    setPage,
    setPageCount,
    setSearch,
    setColumn,
    setOrder,
  ]);

  return (
    <DatatableContext.Provider value={params}>
      {children}
    </DatatableContext.Provider>
  );
};

export function useDatatable(): DatatableContextData {
  const context = useContext(DatatableContext);

  if (!context) {
    throw new Error('useDatatable must be used within an DatatableProvider');
  }

  return context;
}
