import styled from 'styled-components';

export const Container = styled.div`
  .title-owner {
    font-weight: 600;
    font-size: 18px;
    color: #707070;
  }

  .text-ver {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;

    /* Primary */

    color: #39a887;
  }

  .container-box-information {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
    }
  }

  .container-counter {
    display: flex;
    justify-content: center;
    margin-top: 4%;
  }

  form {
    padding: 0px 6rem;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .button-group {
      button {
        border-radius: 15px;
        height: 54px;
        width: 25%;
        @media (max-width: 970px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 0px 3rem;
    }
    @media (max-width: 425px) {
      padding: 0px 1rem;
    }
    @media (max-width: 375px) {
      padding: 0px 0px;
    }
  }

  .credit-analysis-input-value {
    margin-top: 1.5rem;
    background-color: transparent;
    @media (max-width: 1399px) {
      margin-top: 3rem;
    }
  }
`;

export const Step = styled.div`
  .letra {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #079269;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c9;

    .date-input {
      border: none;
      border-radius: 0;
    }

    input {
      color: #414142;

      ::placeholder {
        color: #b8b9ba;
      }
    }
  }

  .input-date {
    position: unset;

    .calendar {
      width: 100%;
      top: unset;
      bottom: 0;
      left: 0;

      .DayPicker {
        padding: 0 15.25rem;
        height: 420px;
        background: #ffffff;
        border-radius: 22px 22px 0px 0px;
        border: 0;
        filter: drop-shadow(0px -4px 20px rgba(0, 0, 0, 0.15));

        .DayPicker-wrapper {
          max-width: 364px;
          background: transparent;
          margin-right: auto !important;
          margin-left: auto !important;

          .DayPicker-Caption {
            div {
              color: #161616;
            }
          }

          .DayPicker-Weekday {
            color: #333333;
          }

          .DayPicker-Day--available:not(.DayPicker-Day--outside) {
            background: transparent;
            border-radius: 50% !important;
            color: #afafaf;

            :hover {
              color: #ffffff !important;
              background: #079269 !important;
            }
          }

          .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
            color: #ffffff !important;
            background: #079269 !important;
          }

          .DayPicker-Day--outside {
            opacity: 0;
          }
        }
      }

      :before {
        content: '';
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: -60vh;
      }
    }
  }
`;
