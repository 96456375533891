import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import logoHarvey from '~/assets/logos/logo-harvey.svg';
import api from '~/services/api';

import Loading from '~/components/Loading';
import { useProfile } from '~/hooks/Profile';
import { IMConstrutech } from '~/models/Construtech';
import Gallery from './Components/Gallery';

import Swal from 'sweetalert2';
import { handlerSetNewLastURI } from '~/utils/parseURL';
import { Social } from './Components/Social';
import { Avatar, Banner, Container } from './styles';

interface IParams {
  url?: string;
}

const Construtechs: React.FC = () => {
  const params = useParams<IParams>();
  const { construtech, setConstrutech } = useProfile();
  const [loading, setLoading] = useState(false);

  const loadConstrutechData = useCallback(async () => {
    setLoading(true);
    try {
      console.log(`${process.env.REACT_APP_PREFIX_ROUTE}/construtech/my-page/${params.url}`)
      await api
        .get<IMConstrutech>(
          `${process.env.REACT_APP_PREFIX_ROUTE}/construtech/my-page/${params.url}`
        )
        .then((response) => {

          if(Object.keys(response.data).length <= 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Opss!',
              text: 'Nenhum construtor foi encontrado'
            })
            return
          }
          setConstrutech(response.data);
        });
    } finally {
      setLoading(false);
    }
  }, [params.url, setConstrutech]);

   useEffect(() => {        
    handlerSetNewLastURI()
  }, []);

  useEffect(() => {
    loadConstrutechData();
  }, [loadConstrutechData]);

  const handleRedirectOSConstrutech = () => {
    if (construtech?.user_id && construtech?.bdi) {
      window.open(
        `https://cliente.mcf.house/nova-os/${construtech?.user_id}/${construtech?.bdi}`,
        '_blank',
        'noreferrer'
      );
    }
  };

  return (
    <>
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-md-6 bg-defalt order-2 order-lg-1">
              <div className="mx-container h-100 d-flex align-items-center">
                <div className="">
                  <div className="d-flex justify-content-center d-lg-flex justify-content-lg-start">
                    <Avatar
                      src={construtech?.logo_url ? construtech.logo_url : ''}
                      className="mb-5 margin-top-negative "
                    />
                  </div>
                  <h1 className="text-sub-2 py-5">
                    <span>{construtech?.nome_fantasia}</span>
                  </h1>
                  <button
                    type="button"
                    className="text-white mb-5 btn btn-dark px-5 py-3 h5 bg-gris3"
                    onClick={handleRedirectOSConstrutech}
                  >
                    Faça uma simulação
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-lg-2">
              <Banner
                src={
                  construtech?.engineer?.gallery
                    ? `${process.env.REACT_APP_API_URL}/builders/galleries/${construtech?.engineer?.id}/${construtech?.engineer?.gallery[0]?.id}`
                    : ''
                }
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Social />
              </div>
              <div className="col-12">
                <Gallery />
              </div>
            </div>
          </div>

          <div className="bg-gris mt-5">
            <div className="container">
              <div className="d-flex justify-content-between py-5">
                <div className="text-bot">
                  ©{new Date().getFullYear()} {construtech?.razao_social}. Todos
                  os direitos direitos reservados.
                  <br />
                  Site oferecido pela Minha Casa Financiada
                </div>
                <div>
                  <img src={logoHarvey} alt="logo-harvey" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Loading active={loading} />
    </>
  );
};

export default Construtechs;
