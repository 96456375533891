import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import {
  FC, useCallback, useEffect, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { format, parseISO } from 'date-fns';
import { IoIosArrowBack } from 'react-icons/io';
import InputMask from '~/components/InputMask';
import InputRadio from '~/components/InputRadio';
import Loading from '~/components/Loading';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';
import { formatToFloat } from '~/utils/format';
import formatCurrency from '~/utils/formatCurrency';
import getValidationErrors from '~/utils/getValidationsErrors';

interface IMonetaryData {
  amount_entry: string;
  terrain_amount: string;
  has_fgts: number;
  fgts_value?: string;
  is_married?: number;
  spouse_document?: string;
}

interface IProps {
  setStep: (step: number) => void;
  step: number;
}

const MonetaryData: FC<IProps> = ({ setStep, step }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [os, setOS] = useState({} as IMonetaryData);
  const [hasFgts, setHasFgts] = useState(false);
  const [isMarried, setIsMarried] = useState(false);
  const [hasAdvance, setHasAdvance] = useState(false);
  const [cpfSpouse, setCpfSpouse] = useState('');
  const [loteValueOS, setLoteValueOS] = useState(0);
  const [inputValueOS, setInputValueOS] = useState(0);
  const [errorInputValue, setErrorInputValue] = useState(false);
  const [errorLotValue, setErrorLotValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleValidateCPF = useCallback((value: string) => {
    if (!value) return false;
    return cpfValidator.isValid(value.replace(/\D/g, ''));
  }, []);

  const handleChangeHasFgts = useCallback((option) => {
    setHasFgts(option.id === 'Sim');
  }, []);

  const handleChangeIsMarried = useCallback((option) => {
    setIsMarried(option.id === 'Sim');
  }, []);

  const handleChangeHasAdvance = useCallback((option) => {
    setHasAdvance(option.id === 'Sim');
  }, []);

  const handleSubmit = useCallback(
    async (data: IMonetaryData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          amount_entry: Yup.string().when('$amount_entry', {
            is: (entryValueCheck: boolean) => entryValueCheck,
            then: Yup.string().required('O valor de entrada é obrigatório'),
            otherwise: Yup.string(),
          }),
          terrain_amount: Yup.string().when('$terrain_amount', {
            is: (lotValueCheck: boolean) => lotValueCheck,
            then: Yup.string().required('O valor do lote é obrigatório'),
            otherwise: Yup.string(),
          }),
          has_fgts: Yup.string(),
          fgts_value: Yup.string().when('$fgts_value', {
            is: (fgtsValueCheck: boolean) => fgtsValueCheck,
            then: Yup.string().required('O valor de FGTS é obrigatório'),
            otherwise: Yup.string(),
          }),
          is_married: Yup.string(),
          spouse_document: Yup.string().when('$spouse_document', {
            is: (spouseDocumentCheck: boolean) => spouseDocumentCheck,
            then: Yup.string().required('O CPF do cônjuge é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            fgts_value: hasFgts,
            spouse_document: isMarried,
            terrain_amount: serviceOrder?.finalidade?.id === 1,
            amount_entry: serviceOrder?.finalidade?.id === 1,
          },
        });

        if (isMarried) {
          if (
            data.spouse_document
            && handleValidateCPF(data.spouse_document) === false
          ) {
            setCpfSpouse('CPF incorreto');
            return;
          }
          setCpfSpouse('');
        }

        const is_married = data.is_married as any;

        // WILL FORMAT THE DATE IF BE A STRING OR RETURN ORIGINAL ENTRY VALUE
        const dataNascimentoParsed =  serviceOrder.cliente.dt_nascimento && typeof serviceOrder.cliente.dt_nascimento === "string" ? 
          format(parseISO(serviceOrder.cliente.dt_nascimento),'yyyy-MM-dd') : 
          serviceOrder.cliente.dt_nascimento

          // @ts-ignore
          console.table({dataNascimentoParsed,dateRaw:serviceOrder.cliente.dt_nascimento, dataParsed: parseISO(serviceOrder.cliente.dt_nascimento || '') })

          // return 
        const formData = {
          os: {
            ...serviceOrder,
            origem_id: 2,
            fgts: hasFgts,
            cliente: {
              ...serviceOrder.cliente,
              dt_nascimento: dataNascimentoParsed,
              casado: is_married === 'Sim',
            },
            conjuge: {
              ...serviceOrder.conjuge,
              cpf: data.spouse_document,
            },
          },
          simulacao: {
            ...serviceOrder.simulacao,
            vlrTerreno: data?.terrain_amount
              ? formatToFloat(data.terrain_amount) || 0
              : 0,
            vlrEntrada: data?.amount_entry
              ? formatToFloat(data.amount_entry) || 0
              : 0,
            vlrFgts: data.fgts_value ? formatToFloat(data.fgts_value) : 0,
          },
        };

        const response = await api.post('builders/service-orders', formData);
        setServiceOrder(response.data);

        if (
          response.data.finalidade_id <= 2
          && (!response.data?.orc_ambientes
            || !response.data?.orc_ambientes?.length)
        ) {
          history.push(
            `${process.env.PUBLIC_URL}/monte-sua-casa/${response.data.id}`
          );
        } else {
          history.push(
            `${process.env.PUBLIC_URL}/dashboard/${response.data.id}`
          );
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        setLoading(false);
      }
    },
    [
      hasFgts,
      isMarried,
      serviceOrder,
      setServiceOrder,
      history,
      handleValidateCPF,
    ]
  );

  const handleClickPrevious = useCallback(
    (target_step) => {
      setStep(target_step);
    },
    [setStep]
  );

  const handleChangeLotValue = useCallback(
    (value: string) => {
      setLoteValueOS(
        parseFloat(
          value.replace('R$', '').replaceAll('.', '').replace(',', '.')
        )
      );

      const lotValue = value
        .replace('R$', '')
        .replaceAll('.', '')
        .replace(',', '.');

      if (serviceOrder.finalidade_id === 1 && parseFloat(lotValue) === 0) {
        setErrorLotValue(true);
      } else {
        setErrorLotValue(false);
      }
    },
    [serviceOrder.finalidade_id]
  );

  useEffect(() => {
    if (serviceOrder.finalidade_id === 1 && loteValueOS * 0.2 > inputValueOS) {
      setErrorInputValue(true);
    } else {
      setErrorInputValue(false);
    }
  }, [
    loteValueOS,
    inputValueOS,
    serviceOrder.simulacao,
    serviceOrder.finalidade_id,
  ]);

  useEffect(() => {
    setOS({
      amount_entry: serviceOrder?.simulacao?.vlrEntrada?.toFixed(2) || '0',
      terrain_amount: serviceOrder?.simulacao?.vlrTerreno?.toFixed(2) || '0',
      has_fgts: serviceOrder?.fgts ? 1 : 0,
      fgts_value: serviceOrder?.vlrFgts?.toFixed(2),
      is_married: serviceOrder?.conjuge ? 1 : 0,
    });
    setLoteValueOS(serviceOrder?.simulacao?.vlrTerreno || 0);
  }, [serviceOrder]);

  const handleChangeInputValue = useCallback((e) => {
    setInputValueOS(
      parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      )
    );
    if (formRef.current) {
      formRef.current.setErrors({});
      const value = parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      );
      if (value >= 100000000) {
        formRef.current.setErrors({
          input_value: 'Valor maior que o permitido.',
        });
      }
    }
  }, []);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={os}
        className="col-12"
      >
        <div className="row px-3">
          {serviceOrder.finalidade_id <= 2 ? (
            <div className="col-lg-6 mb-5">
              <label className="w-100">
                Insira o valor do lote:{' '}
                {serviceOrder.finalidade_id === 1 ? (
                  <b className="text-primary">*</b>
                ) : (
                  <span className="text-light-gray">(se possuir)</span>
                )}
                <InputMask
                  kind="money"
                  name="terrain_amount"
                  placeholder="ex: 0,00"
                  className="input mt-2"
                  value={
                    parseFloat(os.terrain_amount) !== 0 ? os.terrain_amount : ''
                  }
                  onChange={(e) => handleChangeLotValue(e.target.value)}
                />
              </label>
              {serviceOrder.finalidade_id === 1 && errorLotValue && (
                <p style={{ color: 'red' }}>O valor do lote é obrigatorio</p>
              )}
            </div>
          ) : (
            ''
          )}
          {serviceOrder.finalidade_id !== 3
          && serviceOrder.finalidade_id !== 4
          && serviceOrder.finalidade_id !== 6 ? (
            <div className="col-lg-6 mb-5">
              <label className="w-100">
                Valor de entrada:{' '}
                {serviceOrder.finalidade_id === 1 ? (
                  <b className="text-primary">*</b>
                ) : (
                  <span className="text-light-gray">(se possuir)</span>
                )}
                <InputMask
                  kind="money"
                  name="amount_entry"
                  placeholder="ex: 0,00"
                  className="input mt-2"
                  value={
                    parseFloat(os.amount_entry) !== 0 ? os.amount_entry : ''
                  }
                  onChange={handleChangeInputValue}
                />
              </label>
              {errorInputValue && (
                <p style={{ color: 'red' }}>
                  Valor de entrada deve ser maior ou igual a{' '}
                  {formatCurrency(loteValueOS * 0.2)}
                </p>
              )}
            </div>
            ) : (
              ''
            )}
          {(serviceOrder.finalidade_id === 1
            || serviceOrder.finalidade_id === 2) && (
            <div className="col-lg-6 mb-5">
              <div className="w-100">
                <label>Possui FGTS?</label>
                <InputRadio
                  name="has_fgts"
                  className="mt-2 justify-content-start"
                  options={[
                    {
                      id: 'Sim',
                      value: 'Sim',
                    },
                    {
                      id: 'Não',
                      value: 'Não',
                    },
                  ]}
                  onChange={handleChangeHasFgts}
                  selected={
                    hasFgts
                      ? {
                        id: 'Sim',
                        value: 'Sim',
                      }
                      : {
                        id: 'Não',
                        value: 'Não',
                      }
                  }
                />
              </div>
              {!hasFgts && (
                <div className="col-lg-6 mt-5">
                  <div className="w-100">
                    <label>Adiantar 2 parcelas?</label>
                    <InputRadio
                      name="has_advance"
                      className="mt-2 justify-content-start"
                      options={[
                        {
                          id: 'Sim',
                          value: 'Sim',
                        },
                        {
                          id: 'Não',
                          value: 'Não',
                        },
                      ]}
                      onChange={handleChangeHasAdvance}
                      selected={
                        hasAdvance
                          ? {
                            id: 'Sim',
                            value: 'Sim',
                          }
                          : {
                            id: 'Não',
                            value: 'Não',
                          }
                      }
                    />
                  </div>
                </div>
              )}
              {hasFgts && (
                <label className="w-100 mt-5">
                  Quanto?
                  <InputMask
                    kind="money"
                    name="fgts_value"
                    placeholder="ex: 0,00"
                    className="input mt-2"
                    value={os.fgts_value}
                  />
                </label>
              )}
            </div>
          )}
          <div className="col-lg-6 mb-5">
            <div className="w-100">
              <label>
                Casado (a): <b className="text-primary">*</b>
              </label>
              <InputRadio
                name="is_married"
                className="mt-2 justify-content-start"
                options={[
                  {
                    id: 'Sim',
                    value: 'Sim',
                  },
                  {
                    id: 'Não',
                    value: 'Não',
                  },
                ]}
                onChange={handleChangeIsMarried}
                selected={
                  isMarried
                    ? {
                      id: 'Sim',
                      value: 'Sim',
                    }
                    : {
                      id: 'Não',
                      value: 'Não',
                    }
                }
              />
            </div>
            {isMarried && (
              <label className="w-100 mt-5">
                CPF do cônjuge: <b className="text-primary">*</b>
                <InputMask
                  kind="cpf"
                  name="spouse_document"
                  placeholder="000.000.000-00"
                  className="input mt-2"
                  value={serviceOrder?.conjuge?.cpf}
                />
                {cpfSpouse && (
                  <span className="small text-danger error">{cpfSpouse}</span>
                )}
              </label>
            )}
          </div>
        </div>
        <div className="button-group d-flex justify-content-end mt-5 mb-5 px-3">
          <button
            type="button"
            className="btn btn-primary btn-back d-flex align-items-center justify-content-center me-2 px-3"
            onClick={() => handleClickPrevious(step - 1)}
          >
            <IoIosArrowBack size={24} color="#fff" />
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-next fw-semibold"
            disabled={
              (errorInputValue && serviceOrder.finalidade_id === 1)
              || (errorLotValue && serviceOrder.finalidade_id === 1)
              || loading
            }
          >
            Próximo
          </button>
        </div>
      </Form>
      <Loading active={loading} />
    </>
  );
};

export default MonetaryData;
