
// Types
import { IHomeProps } from "./types";

// Styles
import { useServiceOrder } from "~/hooks/ServiceOrder";
import "./styles.css";

export function Home(_props: IHomeProps) {
    const {serviceOrder} = useServiceOrder()

    // AUX Variables
    const CONSTRUCTER_NAME_RAW = serviceOrder.construtor?.name?.split(' ') || []
    const CONSTRUCTER_NAME = `${(CONSTRUCTER_NAME_RAW?.[0]?.substring(0,1) || 'N/') || ''}${(CONSTRUCTER_NAME_RAW?.[1]?.substring(0,1) || 'A')}`

    return (
        <div id="home-container" className="general-container">
            <div id="home-header">
                <span>Meu projeto</span>
                <span>{new Date().getFullYear()}</span>
            </div>
            <div id="home-footer">
                <span>{CONSTRUCTER_NAME}</span>
                <span>
                    Por: {serviceOrder.construtor?.name || 'N/A'}
                </span>
            </div>
        </div>
    )
}