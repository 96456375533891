import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { MdCheckCircle } from 'react-icons/md';
import { BsClockFill, BsImageFill } from 'react-icons/bs';
import { IoAlertCircle } from 'react-icons/io5';
import { HiOutlinePaperClip } from 'react-icons/hi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { IMeasurementData } from '../..';
import ModalVisualizer from './ModalVisualizer';

import { Container, ContainerBox } from './styles';

interface ISimulationCard {
  measurement: IMeasurementData;
}

export interface IStatus {
  id: number;
}

const SimulationCard: React.FC<ISimulationCard> = ({ measurement }) => {
  const [aperte, setAperte] = useState(true);
  const [animation, setAnimation] = useState('');
  const [showModalVisualizer, setShowModalVisualizer] = useState(false);

  const handleVisible = useCallback((value: boolean) => {
    value === true ? setAperte(false) : setAperte(true);
  }, []);

  const handleClick = useCallback(() => {
    animation === 'direita'
      ? setAnimation('esquerda')
      : setAnimation('direita');
    setTimeout(() => handleVisible(aperte), 500);
  }, [animation, aperte, handleVisible]);

  // RENNDERIZAR OS STATUS CORRESPONDENTES
  const renderElement = useCallback(({ id }: IStatus) => {
    let elementRendered;
    if (id === 1) {
      elementRendered = (
        <div className="mx-3 mt-2 box-status" style={{ color: '#01CC9D' }}>
          <div className="col-12">
            <MdCheckCircle size={23} className="me-2" color="#01CC9D" />
            Aprovado
          </div>
        </div>
      );
    } else if (id === 0) {
      elementRendered = (
        <div className="mx-3 mt-2 box-status" style={{ color: '#FFA500' }}>
          <div className="col-auto">
            <BsClockFill size={20} className="me-2" color="#FFA500" />
            Aguardando análise
          </div>
        </div>
      );
    } else if (id === 2) {
      elementRendered = (
        <div className="mx-3 mt-2 box-status" style={{ color: '#FF1A50' }}>
          <div className="col-12">
            <IoAlertCircle size={23} className="me-2" color="#FF1A50" />
            Reprovado
          </div>
        </div>
      );
    }
    return elementRendered;
  }, []);

  return (
    <>
      <ContainerBox>
        {aperte === true ? (
          <Container className="row" animation={animation}>
            <div className="col-12 mt-3">
              <h2 className="h6 mb-4">
                Medição{' '}
                {Number(measurement.numero) > 9
                  ? measurement.numero
                  : `0${measurement.numero}`}
              </h2>
            </div>

            <div className="col-12 mt-2">
              <div className="row">
                <div className="col-6">
                  <h4>Solicitação</h4>
                  <p className="mb-0 mt-3">
                    {format(
                      new Date(measurement.dt_solicitacao),
                      'dd/MM/yyyy',
                      {
                        locale: ptBr,
                      }
                    )}
                  </p>
                </div>

                <div className="col-6">
                  <h4>Aprovado</h4>
                  {measurement.dt_aprovacao !== null ? (
                    <p className="mb-0 mt-3 aproved-box">
                      {format(
                        new Date(measurement.dt_aprovacao),
                        'dd/MM/yyyy',
                        {
                          locale: ptBr,
                        }
                      )}
                    </p>
                  ) : (
                    <p className="mb-0 mt-3 await-box"> - </p>
                  )}
                </div>
              </div>
            </div>

            <div className="hr-box">
              <hr />
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-6 my-1">
                  <h4 className="mb-0 mt-2">
                    {measurement.porcentagem_estimada}% medição
                  </h4>
                </div>

                <div className="col-6 my-1">
                  {measurement.dt_aprovacao !== null ? (
                    <h4 className="mb-0 mt-2 aproved-box">
                      {measurement.porcentagem_aprovada}% medição
                    </h4>
                  ) : (
                    <h4 className="mb-0 mt-2 await-box"> - </h4>
                  )}
                </div>
              </div>
            </div>

            <div className="hr-box">
              <hr />
            </div>

            <div className="col-12">
              <div className="">
                {measurement.arquivo_solicitacao_id === null ? (
                  <h4 className="mb-0 mt-2 no-file-box"> - </h4>
                ) : (
                  <button
                    type="button"
                    className="border-0"
                    onClick={handleClick}
                  >
                    <h4 className="mb-0 mt-2 mx-3">
                      <HiOutlinePaperClip size={26} />
                      <span className="mx-2">Visualizar uploads</span>
                    </h4>
                  </button>
                )}
              </div>
            </div>
          </Container>
        ) : (
          <Container animation={animation}>
            <div className="col-12 mt-5">
              <button type="button" className="border-0" onClick={handleClick}>
                <IoIosArrowBack size={23} /> Voltar para dados
              </button>
            </div>

            <div className="col-12 mt-3 border box-button-visualize">
              <button
                type="button"
                className="border-0 d-flex align-items-center"
                onClick={() => setShowModalVisualizer(true)}
              >
                <BsImageFill />
                <span className="mx-2"> Comprovante Medição </span>
                <IoIosArrowForward size={23} />
              </button>
            </div>
          </Container>
        )}
        {renderElement({ id: Number(measurement.resultado_id) })}
      </ContainerBox>
      <ModalVisualizer
        show={showModalVisualizer}
        id_document={measurement.arquivo_solicitacao_id}
        measurement={
          Number(measurement.numero) > 9
            ? measurement.numero
            : `0${measurement.numero}`
        }
        onHide={() => setShowModalVisualizer(false)}
      />
    </>
  );
};

export default SimulationCard;
