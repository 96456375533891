import { FC, useCallback, useRef, useState } from 'react';
import { FaAward } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

// Icons
import { FiLogOut } from 'react-icons/fi';
import { IoChatbubbleEllipsesSharp, IoGrid, IoReader } from 'react-icons/io5';
import { MdShare } from 'react-icons/md';
import { RiToolsFill } from 'react-icons/ri';
import { TiGroup } from 'react-icons/ti';
import mcfHandshakeGrey from '~/assets/icons/handshake-grey.svg';
import mcfHandshake from '~/assets/icons/handshake.svg';
import mcfConstrutorWhatsUpGrey from '~/assets/icons/mcfConstructorWhatsUp.svg';
import mcfConstrutorWhatsUp from '~/assets/icons/mcfConstructorWhatsUpGreen.svg';
import mcfHomeGear from '~/assets/icons/mcfHomeGear.svg';
import mcfHomeGearFillGrey from '~/assets/icons/mcfHomeGearFillGrey.svg';

// Assets
import apparel from '~/assets/icons/apparel.svg';
import apparelGreen from '~/assets/icons/apparel_green.svg';
import incomobi from '~/assets/icons/incomobi.svg';
import mcfProjetosMenu from '~/assets/icons/mcf-projetosMenu.svg';
import mcfshopmobi from '~/assets/icons/mcfshopmobi.svg';
import logo from '~/assets/logos/logo-mcf.svg';

// Services
import api from '~/services/api';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Partials

// Styles

const Sidebar: FC = () => {
  const { user, signOut } = useAuthContext();
  const [active] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // AUX Variables
  const ACTION_MENU_ELEMENT = useRef<any>()

  const handleClickLeiaut = useCallback(async () => {
    try {
      setIsOpen(!isOpen);

      const response = await api.post('leiaut/users/generate-login-hash', {
        email: user!.email,
      });

      window.open(
        `${process.env.REACT_APP_LEIAUT_URL}/auto-login/${response.data.hash}`,
        '_blank'
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro ao abrir a Leiaut tente novamente ou entre em contato com suporte',
        'error'
      );
    }
  }, [isOpen, user!.email]);

  const handleClickMcfShop = useCallback(async () => {
    window.open(` https://www.mcfshop.com/`, '_blank');
  }, []);
  const handleClickLoja = useCallback(async () => {
    window.open(`https://www.reserva.ink/usemcf`, '_blank');
  }, []);
  const handleClickInco = useCallback(async () => {
    window.open(` https://investidor.mcf.house/`, '_blank');
  }, []);

  const handleToggle = () => {
    if(!ACTION_MENU_ELEMENT) return;

    ACTION_MENU_ELEMENT.current?.click();
  };

  return (
    <div className="fixed flex h-[50px] 
        w-full items-center 
        max-sm:gap-0 pt-4 pb-4
        md:h-screen md:w-[106px] 
        md:flex-col gap-4 
        max-sm:shadow-md
        max-sm:border-r-[0px]
        bg-white
        z-[999]
      "
      style={
        {
          boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.1)'
        }
      }
    >
      <input ref={ACTION_MENU_ELEMENT} type="checkbox" name="show-mobile-menu" id="show-mobile-menu" className="peer/ShowMobileMenu hidden" />

      <label htmlFor="show-mobile-menu"
        className="max-sm:flex 
          h-auto w-[20px] 
          flex-col items-start 
          space-y-[3.2px] 
          hidden 
          peer-checked/ShowMobileMenu:[&._last-bar-menu]:w-full
          max-sm:ml-4
        " 
        role="button" tabIndex={-1}
      >
        <span className="flex h-[2.3px] w-full bg-[#707070] max-sm:active:bg-[#39A887] transition-all duration-[0.37s]"></span>
        <span className="flex h-[2.3px] w-full bg-[#707070] max-sm:active:bg-[#39A887] transition-all duration-[0.37s]"></span>
        <span className="last-bar-menu flex h-[2.3px] w-1/2 bg-[#707070] max-sm:active:bg-[#39A887] transition-all duration-[0.37s]"></span>
      </label>

      <span className="flex h-[70px] 
          w-[70px] flex-row 
          items-center justify-center 
          overflow-hidden rounded-full
          max-sm:hidden
        "
      > 
        <img src={logo} alt="logo" className="logo" />
      </span>

      <nav className="transition-alll absolute
        bg-white
          w-full overflow-y-auto  overflow-x-hidden
          duration-[0.37s] 
          peer-checked/ShowMobileMenu:left-0
          max-sm:-left-full max-sm:top-[50px] 
          md:relative md:h-full
          z-[999]
        "
        style={{
          height: 'calc(100vh - 50px)', // 50px FROM HEIGHT OF "THE MAIN HEADER"
        }}
      >
        <ul className="nav-content m-0 flex h-full w-full flex-col gap-4 overflow-y-auto max-sm:p-4 z-[999]">
          <NavLink
            to={`${process.env.PUBLIC_URL}/home`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <IoGrid size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">Pagina Inicial </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/ordem-servico`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <IoReader size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Ordem de serviço
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/orcamento-solicitado`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <TiGroup size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Solicitações clientes
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/produtos-imobiliario`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img
                src={mcfHandshakeGrey}
                alt="Duas mão se apertando, ambas são verde e nãos seguem um desenho anatomico"
                className="group-hover:!hidden block"
              />

              <img
                src={mcfHandshake}
                alt="Duas mão se apertando, ambas são verde e nãos seguem um desenho anatomico"
                className="group-hover:!block hidden"
              />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Produto imobiliário
            </small>
          </NavLink>

          <a
            href={`${process.env.PUBLIC_URL}/orcamento-solicitado`}
            target='__blank'
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img
                src={mcfConstrutorWhatsUpGrey}
                alt="A silhueta de um homem com capacete de trabalho e a lado superior direito uma engrenagem"
                className="group-hover:!hidden block"
              />

              <img
                src={mcfConstrutorWhatsUp}
                alt="A silhueta de um homem com capacete de trabalho e a lado superior direito uma engrenagem"
                className="group-hover:!block hidden"
              />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              MCF  Construtora
            </small>
          </a>

          <NavLink
            to={`${process.env.PUBLIC_URL}/projetos/monte-sua-casa`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img
                src={mcfHomeGearFillGrey}
                alt="Um desenho em formato de casa"
                className="group-hover:!hidden block"
              />

              <img
                src={mcfHomeGear}
                alt="Um desenho em formato de casa"
                className="group-hover:!block hidden"
              />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Monte Sua Casa
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/chat/clientes`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <IoChatbubbleEllipsesSharp size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
             Chat
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/marketing`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <MdShare size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
             Marketing
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/fluxo-de-acoes`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <RiToolsFill size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Fluxo de ações
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/ferramentas`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <RiToolsFill size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Ferramentas
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/projetos`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img src={mcfProjetosMenu} alt="MCF Projetos" />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              MCF Projetos
            </small>
          </NavLink>

          <NavLink
            to={`${process.env.PUBLIC_URL}/premiacoes`}
            activeClassName="active"
            onClick={() => handleToggle()}
            className="group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <FaAward size={20} color="#707070" className='group-hover:fill-[#39a887]'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Premiações
            </small>
          </NavLink>

          <button
            type='button'
            onClick={handleClickInco}
            className="border-0  outline-0 group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img src={incomobi} alt="incomobi" />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Inco
            </small>
          </button>

          <button
            type='button'
            onClick={handleClickMcfShop}
            className="border-0  outline-0 group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img src={mcfshopmobi} alt="incomobi" />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              BioCasa
            </small>
          </button>

          <button
            type='button'
            onClick={handleClickLoja}
            className="border-0  outline-0 group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#e9f9f4]">
              <img
                src={apparel}
                alt="Duas mão se apertando, ambas são verde e nãos seguem um desenho anatomico"
                className="group-hover:!hidden block"
              />

              <img
                src={apparelGreen}
                alt="Duas mão se apertando, ambas são verde e nãos seguem um desenho anatomico"
                className="group-hover:!block hidden"
              />
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-[#39a887] w-full max-sm:w-auto">
              Loja
            </small>
          </button>

          <button
            type='button'
            onClick={signOut}
            className="border-0  outline-0 group flex h-auto w-full cursor-pointer items-center gap-2  max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:odd:bg-gray-100 max-sm:active:bg-gray-100 md:flex-col max-sm:active:bg-gray-100"
          >
            <span className="flex h-14 w-14 items-center justify-center rounded-[0.75rem] transition-all duration-[0.37s] group-hover:bg-[#ffdede]">
              <FiLogOut size={20} color="#707070" className='group-hover:text-red-500'/>
            </span>
            <small className="text-center font-bold text-[#707070] transition-all duration-[0.37s] group-hover:text-red-500 w-full max-sm:w-auto">
              Sair
            </small>
          </button>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
