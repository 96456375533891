import React, { useCallback } from 'react';
import { BsFillEyeFill, BsFillShareFill } from 'react-icons/bs';
import Swal from 'sweetalert2';

import { useProfile } from '~/hooks/Profile';
import { setToExternalURI } from '~/utils/parseURL';
import Toast from '~/utils/toast';
import { Container } from './styles';

const Banner: React.FC = () => {
  const { construtech, engineer } = useProfile();
  const link = `../construtech/${construtech.url}`;

  const handleCopyLink = useCallback(() => {
    try {
      const completeLink = link;
      navigator.clipboard.writeText(completeLink);

      Toast.fire({
        icon: 'success',
        title: 'Link copiado com sucesso!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro, tente novamente',
      });
    }
  }, [link]);

  const handleClickRedirectMeuSite = useCallback(() => {
    if (!engineer?.gallery?.length) {
      Swal.fire({
        icon: 'error',
        title: 'Precisa carregar ao menos uma imagem para visualizar seu site',
      });
    } else {
      setToExternalURI(link)
      // window.open(link, '_blank', 'noreferrer');
    }
  }, [link, engineer?.gallery?.length]);

  return (
    <Container className="card-body">
      <h5 className="card-title">Dados da sua conta</h5>
      <p className="card-text">
        Nós utilizamos os dados da sua conta para preencher seu site, não
        esqueça de preenchê-los!
      </p>
      {construtech.url && (
        <div className="d-flex justify-content-between flex-wrap">
          <div className="row col-lg-auto mx-2">
            <button
              type="button"
              className="ojobtn btn mt-2 mx-2 px-5 d-flex flex-wrap align-items-center justify-content-center"
              onClick={handleClickRedirectMeuSite}
            >
              <BsFillEyeFill
                size={17}
                color="#fff"
                name="avatar"
                className="me-2"
              />
              Visualizar meu site
            </button>
          </div>

          <button
            type="button"
            className="px-5 mx-4 d-flex align-items-center border-0 text-white bg-verdee"
            onClick={handleCopyLink}
          >
            Compartilhar
            <BsFillShareFill size={25} className="ms-2" />
          </button>
        </div>
      )}
    </Container>
  );
};

export default Banner;
