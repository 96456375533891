import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useProfile } from '~/hooks/Profile';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { SimulationsProvider } from '~/hooks/Simulations';
import { IAmortizationResponse } from '~/models/Simulation/Result';
import api from '~/services/api';

import AddInformation from './Components/AddInformation';
import CardMoreInfo from './Components/CardMoreInfo';
import CardPlotComparison from './Components/CardPlotComparison';
import CardSimulationResults from './Components/CardSimulationResults';
import CardsAmortization from './Components/CardsAmortization';
import Header from './Components/Header';
import LoadingResults from './Components/LoadingResults';
import RangeLimitValueAndYears from './Components/RangeLimitValueAndYears';
import SimulationRedoButton from './Components/SimulationRedoButton';
import Slider from './Components/Slider';
import { Container } from './styles';

const ResultsSimulator: React.FC = () => {
  const history = useHistory();
  const { validateEngineerData } = useProfile();
  const { serviceOrder } = useServiceOrder();
  const [amortizationsNumber, setAmortizationsNumber] = useState('');

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  useEffect(() => {
    if (!serviceOrder?.finalidade_id) {
      history.push('/finalidades');
    }
  }, [history, serviceOrder?.finalidade_id]);

  const getAmortizations = useCallback(async () => {
    const payload = `?revenue=${serviceOrder.simulacao?.vlrRenda}${
      serviceOrder.simulacao?.vlrImovel
        ? `&property_value=${serviceOrder.simulacao?.vlrImovel || 0.0} `
        : ''
    }`;

    const amortizations = await api.get<IAmortizationResponse[]>(
      `builders/amortizations/${serviceOrder.finalidade_id}${payload}`
    );
    const aux = amortizations.data?.map((index) => index.id).length;

    setAmortizationsNumber(aux.toString());
  }, [serviceOrder]);

  useEffect(() => {
    getAmortizations();
  }, [getAmortizations]);

  return (
    <SimulationsProvider>
      <Container
        color={amortizationsNumber}
        className="container px-3 px-sm-0 py-3"
      >
        <div className="row">
          <div className="row">
            <div className="col-lg-12">
              <Header />
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="w-full flex max-sm:flex-col md:flex-row max-sm:justify-center items-center gap-2 ">              
                <AddInformation />
                            
                <SimulationRedoButton />              
            </div>
          </div>
          <div className="overflow-auto mx-5 mb-5">
            <div className="ancho">
              <Slider>
                <CardsAmortization />
              </Slider>
            </div>
          </div>
          <div className="row box-container-secundary">
            <CardSimulationResults />
            <CardPlotComparison />
            <CardMoreInfo />
            <RangeLimitValueAndYears />
          </div>
        </div>
      </Container>
      <LoadingResults />
    </SimulationsProvider>
  );
};

export default ResultsSimulator;
