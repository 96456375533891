import styled from 'styled-components';
import { shade } from 'polished';

interface ICalendar {
  active: boolean;
}

export const Container = styled.div`
  position: relative;

  .error {
    position: absolute;
  }

  .date-input {
    input {
      ::placeholder {
        color: #ccc;
      }
    }
  }
`;

export const Calendar = styled.aside<ICalendar>`
  position: absolute;
  width: 380px;
  z-index: 100;
  bottom: unset !important;
  top: 25px;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};

  .DayPicker {
    border-radius: 10px;
    border: 1px solid #cccccc;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    background: #e8e4e4;
    border-radius: 10px;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: #444 !important;
    left: 11.5rem;
    @media (max-width: 1733px) {
      left: 11rem;
    }
    @media (max-width: 1600px) {
      left: 10rem;
    }
    @media (max-width: 1460px) {
      left: 9rem;
    }
    @media (max-width: 1360px) {
      left: 8rem;
    }
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 10rem;
    @media (max-width: 1733px) {
      left: 9rem;
    }
    @media (max-width: 1600px) {
      left: 8rem;
    }
    @media (max-width: 1460px) {
      left: 7rem;
    }
    @media (max-width: 1360px) {
      left: 6rem;
    }
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 0 0.1 0 0;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: 1em;
    padding: 0 1em;
    color: #4444;
    > div {
      text-align: center;
    }
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #e8e4e4;
    border-radius: 10px;
    color: #444;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background: #c4c4c4;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: ${shade(0.02, '#c4c4c4')} !important;
    border-radius: 10px;
    color: #232129 !important;
  }

  @media screen and (max-width: 575px) {
    width: 300px;
    left: -10px;

    .DayPicker-Day--available {
      padding: 0 !important;
      width: 20px !important;
      height: 20px !important;
      min-width: 0;
      min-height: 0;
      font-size: 12px;
      line-height: 0;
    }
  }
`;

export const DayPickerCaption = styled.div`
  > div {
    display: flex;
    align-items: left;
    justify-content: left;

    select {
      border: 0;
      color: #161616;
      background-color: #fff;
      font-weight: 700;

      + select {
        margin-left: 6px;
        font-size: 20px;
      }
    }
  }
`;
