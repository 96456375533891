import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}
interface IModal {
  size?: 'md';
}
interface IBoxButtons {
  show?: boolean;
}

export const Container = styled.div`
  .user-login {
    display: flex;
    justify-content: end;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;

export const AvatarTable = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;
export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  .d-none {
    display: none;
  }

  .modalContent {
    max-width: ${(props) => (props.size === 'md' ? '400px' : '500px')};
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .btn-submit {
    background-color: #39a887;
    color: #ffffff;
    font-weight: 600;
  }

  .link {
    color: #1762e1;
  }
`;
