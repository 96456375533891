import React from 'react';

import UserDefault from '~/components/UserDefault';

import { Container } from './styles';

const Header: React.FC = () => (
  <Container className="d-flex align-items-center">
    <div className="container mt-5 mt-lg-0">
      <UserDefault Simulacao />
    </div>
  </Container>
);

export default Header;
