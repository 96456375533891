import React from 'react';
import { IoMdInformationCircle } from 'react-icons/io';

import { Container } from './styles';

interface IStepMenuDataOs {
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const LaudoReprovado: React.FC<IStepMenuDataOs> = ({ setContentData }) => (
  <Container>
    <div className="col-md-12 d-flex">
      <div className="icon-information">
        <IoMdInformationCircle size={40} color="#FF1A50" />
      </div>
      <div className="col-md-11 text-box">
        <div className="d-flex mt-3">
          <b>O engenheiro reprovou o laudo</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-8">
            <p>
              Acesse a página de arquivos e veja o que precisa ser corrigido
            </p>
          </div>

          <div className="col-xl-4 box-button-fill">
            <button
              type="button"
              className="button-fill px-5 py-2 mb-1"
              onClick={() => setContentData('WorkDocuments')}
            >
              <b>Revisar arquivos</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default LaudoReprovado;
