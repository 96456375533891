import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 250px;
  gap: 25px;
  .p-relative {
    position: relative;
  }

  .color-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    text-align: right;
    letter-spacing: -0.5px;

    /* Primary */

    color: #39a887;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;

    /* Title */

    color: #414142;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #8676ff;
  }
  .dot-2 {
    background-color: #96d9ff !important;
  }
  .dot-3 {
    background-color: #ffba69 !important;
  }
  .font-weight-700 {
    font-weight: 700;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Subtitle */

    color: #707070;
  }

  @media (max-width: 988px) {
    //display: unset;
    //height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }
`;
