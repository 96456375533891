import React from 'react';

import bannerpro from '~/assets/icons/bannerpro.svg';

import { Container } from './styles';

const ImgProjects: React.FC = () => (
  <Container>
    <div className="row m-1">
      <img src={bannerpro} alt="bannerpro" className="logo" />
    </div>
  </Container>
);

export default ImgProjects;
