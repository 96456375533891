/* FIXEM - eslint-disable react/jsx-no-comment-textnodes */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { BsPlus } from 'react-icons/bs';
import { MdClose, MdContentCopy } from 'react-icons/md';
import Input from '~/components/Input';

import { useAuthContext } from '~/hooks/contexts/Auth';
import { Container, Modal } from './styles';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const ButtonsHome: React.FC = () => {
  const { user } = useAuthContext();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [margin, setMargin] = useState('');
  const [baseLink, setBaseLink] = useState('');
  const history = useHistory();

  useEffect(() => {
    const builderLink = `https://cliente.mcf.house/nova-os/${user!.id}/`;
    setBaseLink(builderLink);
  }, [user]);

  const handleClickNewOs = useCallback(() => {
    history.push('/finalidades');
  }, [history]);

  const handleShow = useCallback(() => setShow(true), []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleCopyLink = useCallback(async () => {
    try {
      const completeLink = baseLink + margin;
      navigator.clipboard.writeText(completeLink);

      setShow(false);
      Toast.fire({
        icon: 'success',
        title: 'Link copiado com sucesso!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro, tente novamente',
      });
    }
  }, [baseLink, margin]);

  const handleChangeMargin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMargin(event.target.value);
  };

  return (
    <Container>
      <div className="flex justify-between align-content-lg-center flex-row sm:justify-center gap-1">        
        {(!user!.isConstrutor?.quantity
          || user!.isConstrutor?.quantity > user!.qtdOS) && (
            <>
              <button
                type="button"
                className="border-0 btn-bdi md:ms-5 ms-lg-0 d-flex align-items-center justify-content-center"
                onClick={handleShow}
              >
                <BsPlus size={22} color="#ffffff" className="me-2" />
                Link BDI
              </button>
              <button
                type="button"
                className="border-0 btn-os btn-primary md:ms-5 ms-lg-0 d-flex align-items-center justify-content-center"
                onClick={handleClickNewOs}
              >
                <BsPlus size={22} color="#ffffff" className="me-2" />
                Nova OS
              </button>
            </>
          )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleCopyLink}>
          <Modal.Header className="sin-border pe-4 " closeButton>
            <div className="w-100 flex flex-row items-center justify-between">
              <h4 className="mt-5 ms-3 text-gerar">Gerar link</h4>
              <button
                type="button"
                className="mt-5 cursor-pointer"
                title="Fechar"
                onClick={handleClose}
              >
                <MdClose color="red" />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="sin-border ">
            <div className="d-flex flex-wrap">
              <div className="w-100 px-3 mb-5">
                <label htmlFor="margem">BDI:</label>
                <b className="text-primary"> *</b>

                <Input
                  name="margem"
                  value={margin}
                  className="input"
                  placeholder="%"
                  onChange={handleChangeMargin}
                  required
                />
              </div>
              <div className="w-100 px-3 mb-3">
                <label htmlFor="link" className="pb-3">
                  Link de compartilhamento
                </label>
                <div className="row border p-2 redondeo bg-light">
                  <div className="col-10 link ">
                    {baseLink}
                    {margin}
                  </div>
                  <button
                    type="button"
                    className="border-0 col bg-light"
                    onClick={handleCopyLink}
                  >
                    <MdContentCopy size={25} />
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="mt-4 sin-border">
            <button
              type="submit"
              className="border-0 btn-os btn-primary ms-5 ms-lg-0 d-flex align-items-center justify-content-center p-3 rounded-xl"
            >
              Gerar link
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default ButtonsHome;
