import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

// Icons
import { MdClose } from 'react-icons/md';

// Hooks
import { useAuthContext } from '~/hooks/contexts/Auth';

// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';
import { IMRealEstateProductCondominiumOption } from '~/models/RealEstateProductCondominiumOption';
import { IMRealEstateProductHomeOptions } from '~/models/RealEstateProductHomeOption';

// Utils
import { debounce } from '~/utils/debounce';

// Services
import api from '~/services/api';

// Interfaces
import { IStepData } from '../../RealEstateProject/types';
import { IOption, IOptionProps } from './types';

// Styles
import "./styles.css";

export function Option({
  callerID, callerKey, callerPosition, onDataStream, dataStep
}: IOptionProps) {
  const { user } = useAuthContext();
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionInSelection, setOptionInSelection] = useState<boolean>(false);
  const [optionInDelete, setOptionInDelete] = useState<boolean>(false);

  // AUX State for force update in nested array objects
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  // AUX Variables
  const OPTION_PATH = (() => {
    let path = '';
    switch (callerID) {
      case '5':
        path = 'home-options';
        break;
      case '3':
        path = 'condominium-options';
        break;
      default:
      // do anything
    }

    return path;
  })();

  // RECOVERY ALL OPTIONS FROM DATASTEP STATE
  useEffect(() => {
    handleGetOptionFromStorage()
  }, [])

  useEffect(() => {
    const optionsRecovery = ((dataStep as unknown as IStepData<IMRealStateProduct>)
      ?.data?.[callerKey] || []).map((option) => ({
        ...option,
        name: option.option,
        selected: true
      }));

    if (optionsRecovery.length > 0) {
      setOptions(optionsRecovery);
    }
  }, []);

  function handleSetOptionSelected(name = '', index = -1) {
    try {
      setOptionInSelection(true);
      if (options.length <= 0 || index <= -1) throw new Error('');

      const optionsCached = options;
      optionsCached[index].selected = !optionsCached[index].selected;

      setOptions(optionsCached);

      setForceUpdate((d) => !d);

      if (optionsCached[index].selected) {
        handleSetOptionToDataStream(
          optionsCached[index] as IMRealEstateProductCondominiumOption | IMRealEstateProductHomeOptions
        );

        setOptionInSelection(false);
      } else {
        handleDeleteOptionToStorage(optionsCached[index]).then(
          (_resolver) => {
            handleDeleteOptionToDataStream(
              optionsCached[index] as IMRealEstateProductCondominiumOption | IMRealEstateProductHomeOptions
            )

            setOptionInSelection(false);
          }
        );
      }
    } catch (error) {
      setOptionInSelection(false);
      Swal.fire({
        text: `Não foi possivel selecionar a opção ${name}`,
        icon: 'warning',
      });
    }
  }

  function handleSetOptionAdd() {
    try {
      setOptionInSelection(true);

      // READ DOM TREE EVERY TIME
      const inputOpionElement = document.querySelector("#option-input") as HTMLInputElement

      if (!inputOpionElement || inputOpionElement.value.trim() === '') throw new Error('');

      const name = inputOpionElement.value.trim()

      handleSetOptionToStorage({
        name,
        selected: false,
      }).then((resolver) => {
        setOptions((optionsAlready) => [
          {
            ...(resolver || {}),
            name,
            selected: false,
          },
          ...optionsAlready,
        ]);

        setOptionInSelection(false);

        inputOpionElement?.focus()
      }).catch((_rejects) => setOptionInSelection(false));
    } catch (error) {
      console.table(error)
      setOptionInSelection(false);
    }
  }

  // TODO - POR PADRÃO TODAS AS TAGS QUE VIEREM DE FORA DO FLUXO DO COMPONENTTE VÃO SER EXIBIDAS COMO "OPÇÃO DUPLICADA"
  async function handleGetOptionFromStorage() {
    try {
      const response = await api.get<IMRealEstateProductCondominiumOption[] | IMRealEstateProductHomeOptions[]>(`builders/real-estate-products/${OPTION_PATH}`);

      if (response.status !== 200) throw new Error('');

      setOptions(op => [...op, ...response.data])
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function handleSetOptionToStorage(option: IOption) {
    try {
      const response = await api.post<IMRealEstateProductCondominiumOption>(`builders/real-estate-products/${OPTION_PATH}`, {
        user_id: user?.id || -1,
        option: option.name
      });

      if (response.status !== 201) throw new Error('');

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function handleDeleteOptionToStorage(option: IOption) {
    try {
      const response = await api.delete<IMRealEstateProductCondominiumOption>(`builders/real-estate-products/${OPTION_PATH}/${option.id}`);

      if (response.status !== 204) throw new Error('');

      return true;
    } catch (error) {
      // do anything
    }
  }

  function handleDeleteOptionByUser(option: IOption | null = null){
    try {
      setOptionInDelete(true);

      if(!option) throw new Error('The option is missing');

      if(!option.user_id) throw new Error('The user only can delete yourself option');

      const OPTIONS_CACHED = options.filter(d => d.id !== option.id);

      handleDeleteOptionToStorage(option).then(resolver => {
        if(resolver) {
          setOptions(OPTIONS_CACHED);
          setOptionInDelete(false);
        }
      })
    } catch (error) {
      setOptionInDelete(false);
    }
  }

  function handleSetOptionToDataStream(
    optionToStream: IOption | null = null
  ) {
    try {
      if (!optionToStream) throw new Error('');

      onDataStream!({
        [callerKey]: [
          ...(((dataStep as unknown as IStepData<IMRealStateProduct>)?.data || {})
          [callerKey] || []),
          optionToStream
        ]
      }, callerID, callerPosition);
    } catch (error) {
      // do anything
    }
  }

  function handleDeleteOptionToDataStream(
    optionToStream: IOption | null = null
  ) {
    try {
      if (!optionToStream) throw new Error('');

      const dataToStreamCached = ((dataStep as unknown as IStepData<IMRealStateProduct>)?.data || {});

      onDataStream!({
        [callerKey]: dataToStreamCached[callerKey]!.filter(
          (option: IMRealEstateProductHomeOptions
            |
            IMRealEstateProductCondominiumOption) => (option.option !== optionToStream.option || option.id !== optionToStream.id)
        )
      }, callerID, callerPosition);
    } catch (error) {
      // do anything
    }
  }

  return (
    <div className="w-full min-h[8.812rem] h-auto flex flex-row flex-wrap gap-[0.937rem]">
      {(forceUpdate || !forceUpdate) && options.map((option, index) => (
        <span 
          key={index}
          data-byuser={!!option.user_id}
          data-selected={option.selected}
          className='w-auto h-auto
            option-item-container
            flex flex-row 
            flex-nowrap 
            items-center 
            gap-2
            relative
            z-50
            data-[byuser=true]:hover:pl-[12px]
            data-[selected=true]:hover:pl-0
            transition-all
            duration-[0.2s]
          '
        >
          <span
            title='Remover'
            onClick={() => handleDeleteOptionByUser(option)}
            role="button"
            data-byuser={!!option.user_id}
            data-ondelete={optionInDelete}
            data-selected={option.selected}
            className='w-8 h-8
              delete-option-item
              cursor-pointer
              absolute
              left-[50%]
              delete-option
              transition-all
              duration-[0.3s]
              
              data-[ondelete=true]:cursor-not-allowed              
              data-[selected=true]:cursor-not-allowed              
              data-[byuser=false]:hidden
              data-[selected=true]:hidden
              
              flex flex-row flex-nowrap items-center justify-center
            '
          >
            <MdClose 
              data-ondelete={optionInDelete} 
              size={18} 
              className='text-red-500 data-[ondelete=true]:text-red-300'
            />
          </span>
          <button
            disabled={optionInSelection}
            onClick={() => debounce(100, handleSetOptionSelected, option.name, index)}
            type="button"
            data-selected={option.selected}
            data-byuser={!!option.user_id}
            className="w-auto
              pl-[1.5rem] pr-[1.5rem]
              pt-[0.625rem] pb-[0.625rem]
              text-[#707070] text-[0.875rem]
              data-[selected=true]:bg-[#A4DECD]
              border-[0.1rem]
              data-[selected=false]:border-[#E4E4E4]
              rounded-[0.625rem]
              overflow-hidden
              text-ellipsis
              break-normal
              
            "
          >
            {option.name || option.option || ''}
          </button>
        </span>
      ))}

      <span
        title="Adicionar opção"
        className="rounded-[0.625rem] flex flex-row flex-nowrap items-center
          justify-center  gap-[0.2rem] border-[0.1rem] border-[#E4E4E4] bg-white overflow-hidden pr-[0.2rem]"
      >
        <input
          id="option-input"
          type="text"
          disabled={optionInSelection}
          className="w-full h-full oultline-none bg-none border-none pl-[1.5rem] pr-[1.5rem] pt-[0.625rem] pb-[0.625rem]
          text-[#B8B9BA] text-[0.875rem] font-[500] group-disabled:cursor-wait"
          placeholder="+ Adicionar opção aqui"
          onKeyPress={(event) => event.charCode === 13 && handleSetOptionAdd()}
        />
        {optionInSelection && '...'}
      </span>
    </div>
  );
}
