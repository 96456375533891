import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import UserDefault from '~/components/UserDefault';
import Swal from 'sweetalert2';

import { Container } from './styles';
import Steps from './Steps';

const Simulation: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();

  useEffect(() => {
    if (!serviceOrder?.finalidade_id) {
      history.push('/finalidades');
      return;
    }
    if (serviceOrder?.id && !serviceOrder?.simulacao?.vlrFinanciado) {
      Swal.fire(
        'Opss...',
        'Não conseguimos guardar os dados da sua simulação. Será necessário preencher os dados novamnete. Lamentamos o ocorrido.',
        'error'
      );
    }
  }, [history, serviceOrder?.finalidade_id]);

  return (
    <Container className="container">
      <div className="w-100 px-0 px-md-5 py-5">
        <div className="">
          <div className="container mt-5 mt-lg-0">
            <UserDefault IniciarSimulacao />
            <p className="ps-lg-5 ms-4 mt-4 text-subtitle">
              Para nossa equipe analisar o seu perfil do seu financiamento
              <br />
              solicitado iremos precisar dos dados a seguir:
            </p>
          </div>
        </div>
        <div
          className="barra mt-5"
          style={{
            width: '70%',
            margin: 'auto',
            border: '1px solid #F5F5F5',
          }}
        />
        <div className="centro">
          <Steps />
        </div>
      </div>
    </Container>
  );
};

export default Simulation;
