import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { BsDownload } from 'react-icons/bs';
import Swal from 'sweetalert2';

import pic4 from '~/assets/pic/pic4.png';
import api from '~/services/api';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import Loading from '~/components/Loading';
import { Container } from './styles';

const Contract: React.FC = () => {
  const history = useHistory();
  const { serviceOrder, setServiceOrder, getServiceOrderData } = useServiceOrder();
  const [loading, setLoading] = useState(false);
  const [modalMensage, setModalMensage] = useState('');

  const handleHome = (): void => {
    history.push(`/dashboard/${serviceOrder.id}`);
  };

  const handleDownloadContract = useCallback(async () => {
    setModalMensage('Aguarde, estamos processando seu contrato');
    try {
      setLoading(true);
      const response = await api.get(
        `${process.env.REACT_APP_PREFIX_ROUTE}/oss/${serviceOrder.id}/contractdrafts`,
        {
          responseType: 'blob',
        }
      );
      const urlData = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlData;
      link.download = 'Contract.zip';
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(urlData);
        link.remove();
      }, 100);

      const { id } = serviceOrder;
      setServiceOrder({ ...serviceOrder, id: undefined });
      id && getServiceOrderData(id);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire('Opss...', 'Ocorreu um erro, arquivo não encontrado.', 'error');
    }
  }, [serviceOrder, getServiceOrderData, setServiceOrder]);

  return (
    <>
      <div>
        <Container className="container mx-auto position-relative">
          <div className="row justify-content-between h-100">
            <div className="row ms-0">
              <div className="col-5 pt-5">
                <div className="pt-5 title h1 title-sub">
                  Baixe seu
                  <div className="title-contrato ms-2"> contrato</div>
                </div>
                <div className="py-1" />
                <div className="title-sub">
                  Estamos aqui para tirar o seu sonho do papel e
                </div>
                <div className="title-sub">construir sua casa juntos.</div>
                <div className="py-2" />

                <div className="row">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="button2"
                      onClick={handleDownloadContract}
                    >
                      <IconContext.Provider value={{ color: 'white' }}>
                        <BsDownload className="p-1" size={35} />
                      </IconContext.Provider>
                    </button>
                  </div>
                  <div className="col-auto">
                    <h1 className="baixar row mt-3">Baixar contrato</h1>
                  </div>
                </div>
                <div className="py-5" />
              </div>
              <div className="col-auto box-pic">
                <img src={pic4} alt="pic2" />
              </div>
            </div>
            <div className="row box-btn-go-dashboard mx-5">
              <button
                type="button"
                className="btn btn-go-dashboard fw-semibold mt-5"
                onClick={handleHome}
              >
                Ir para dashboard
              </button>
            </div>
          </div>
        </Container>
      </div>
      <Loading active={loading} textMessage={modalMensage} />
    </>
  );
};

export default Contract;
