// Types
import { IoChevronForward } from "react-icons/io5";
import { IPropsList } from "./type";

export function List({projects, isOnLoading, onPickUpProject}: IPropsList){
    return <>
        {
            !isOnLoading && projects.map((project, index) => (
                <div
                    key={project.id}
                    className="
                        w-full
                        h-auto
                        flex
                        flex-row
                        flex-nowrap
                        items-center
                        justify-start
                        gap-4
                        transition-all
                        duration-[0.15s]
                        rounded-[15px]
                        hover:bg-[#e1f1ed]
                        p-[0.5rem]
                    "
                >
                    <span className="flex-1 flex max-sm:max-w-[50px] md:max-w-[200px] text-sm font-medium leading-[26px] text-[#707070]">
                        #{project.id}
                    </span>
                    <span 
                        className="
                            max-sm:min-h-40
                            max-sm:max-h-48
                            flex 
                            max-sm:flex-col
                            md:flex-row
                            md:flex-nowrap
                            md:justify-between
                            md:items-center
                            flex-1
                            text-sm font-medium
                            leading-[26px] text-[#707070]
                        "
                    >
                        <p
                            className="
                                md:max-w-[64rem]
                                md:h-8
                                md:flex-1
                                max-sm:w-full
                                md:overflow-hidden
                                md:whitespace-nowrap
                                max-sm:whitespace-pre-line
                                text-ellipsis
                            "
                        >
                            {project.description}
                        </p>
                        <span
                            aria-label="button"
                            onClick={() => onPickUpProject(project)}
                            className="flex
                                cursor-pointer
                                max-sm:flex
                                flex-row 
                                items-center 
                                max-sm:justify-end
                                justify-start
                                gap-3
                                min-w-[4rem]
                                text-sm
                                font-semibold leading-[18.23px] 
                                text-[#39A887]
                                hover:text-[#079269] active:text-[#079269]
                            "
                        >
                            Ver mais 
                            <IoChevronForward 
                                className="
                                    max-sm:w-[20px] max-sm:h-[20px]
                                    md:w-[24px] md:h-[24px]
                                    text-[#39A887]
                                    hover:text-[#079269] active:text-[#079269]
                                " 
                            />
                        </span>
                    </span>
                </div>
            ))
        }
    </>
}