import React, { Ref, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useParams } from 'react-router-dom';
import Documents from '~/assets/img/Documents.png';
import Input from '~/components/Input';
import { IMFileType } from '~/models/FileTypes';
import FileUploadProgressBar from '../FileUploadProgressBar';

import { IFileObject } from '../..';

import { Modal, DocumentImage, InputArea } from './styles';

interface IFormSendDocument {
  show: boolean;
  fileType: IMFileType;
  fileDataElement: IFileObject[];
  formRef: Ref<FormHandles>;
  onHandleClose(): void;
  onHandleSubmit(): void;
  onHandleDragDrop(value: never): void;
  onSelectFile(value: never): void;
  onHandleRefreshArray(value: string): void;
  onSetObservationClient(value: string): void;
}

interface IParams {
  osId: string;
}

const FormSendDocument: React.FC<IFormSendDocument> = ({
  show,
  fileType,
  fileDataElement,
  formRef,
  onHandleClose,
  onHandleSubmit,
  onHandleDragDrop,
  onSelectFile,
  onHandleRefreshArray,
  onSetObservationClient,
}) => {
  const params = useParams<IParams>();

  const handlePrevent = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onChangeInputText = useCallback(
    (event) => {
      onSetObservationClient(event.target.value);
    },
    [onSetObservationClient]
  );

  const returnAccept = useCallback(() => {
    if (fileType.id === 11) {
      return (
        <Input
          type="file"
          name="fileInput"
          className="image-input"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template"
          onChange={onSelectFile}
        />
      );
    }
    return (
      <Input
        type="file"
        name="fileInput"
        className="image-input"
        accept="image/jpeg,application/pdf"
        onChange={onSelectFile}
      />
    );
  }, [onSelectFile, fileType.id]);

  return (
    <Modal show={show} onHide={onHandleClose} size="lg">
      <Form ref={formRef} onSubmit={onHandleSubmit}>
        <Modal.Header className="border-0">
          <h4>Enviar {fileType.tipo_arquivo}</h4>
        </Modal.Header>
        <Modal.Body className="border-0 mx-5">
          <DocumentImage
            onDragEnter={handlePrevent}
            onDragLeave={handlePrevent}
            onDragOver={handlePrevent}
            onDrop={onHandleDragDrop}
          >
            <img src={Documents} alt={Documents} className="img-props" />

            <div className="box-image-input">
              <span>Solte seu arquivo aqui ou</span>
              <label>
                <div className="box-select">
                  <span>Procurar arquivo</span>
                </div>
                {returnAccept()}
              </label>
            </div>
          </DocumentImage>

          {fileDataElement.map((dataFile) => (
            <FileUploadProgressBar
              id={dataFile.id}
              os_id={params.osId}
              name={dataFile.file.name}
              percentage={dataFile.percentage}
              loaded={dataFile.loaded}
              totalSize={dataFile.totalSize}
              error={dataFile.error}
              onHandleLoadDocuments={onHandleRefreshArray}
            />
          ))}

          <InputArea>
            <label>
              Caso queira, deixe alguma observação: <span>(Opcional)</span>
            </label>
            <Input
              className="form-input"
              name="note"
              placeholder="Digite"
              onChange={onChangeInputText}
            />
          </InputArea>
        </Modal.Body>
        <Modal.Footer className="border-0 box-footer">
          <div className="buttons-group">
            <button
              type="button"
              className="btn-cancel me-2"
              onClick={onHandleClose}
            >
              Fechar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormSendDocument;
