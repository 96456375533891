import { createContext } from 'react';

// Middleware
import { IMUser } from '~/models/User';
import { authGetCacheStorage } from '../middleware/cache';

// Types
import { AuthCredentials, AuthEventsState, AuthState, IAuthDispatch } from '../types';

export const AuthContext = createContext<AuthState>(authGetCacheStorage());

export const AuthEventsContext = createContext<AuthEventsState>({
  onRecovery: false,
  onSignIn: false,
  onSignOut: false,
  onHydrate: false,
});

if (AuthContext) AuthContext.displayName = 'Auth@alpha';

export const AuthContextDispatch = createContext<IAuthDispatch>({
  signIn: async ({ email, password }: Omit<AuthCredentials, 'onLoading'>) => ({
    api_token: '',
    token: '',
    user: {} as IMUser,      
  }),
  // FIXME - eslint-disable-next-line @typescript-eslint/no-empty-function
  autoSignIn: async (hash = '') => { },
  signOut: () => ({}),
  recovery: async () => false,
  setAuthTokens: (data = null) => ({}),
  updateUser: (data: Partial<IMUser>) => false,
});

if (AuthContext) AuthContextDispatch.displayName = 'AuthContextDispatch@alpha';
