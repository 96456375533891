import styled from 'styled-components';

interface ITitle {
  color: string;
}

export const Container = styled.div`
  display: flex;
  height: 21%;
  width: 50%;

  .fechas {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Gray/600 */

    color: #b8b9ba;
  }

  .numeros {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    letter-spacing: -0.5px;

    /* Title */

    color: #414142;
  }

  .p-relative {
    position: relative;
  }
  .h6 {
    color: #5b4dbe;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */
  }
  .valore {
    padding-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Subtitle */

    color: #707070;
  }
  .numerico {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    text-align: right;

    /* Title */

    color: #414142;
  }
  h3 {
    font-weight: 600;
    color: #414142;
  }
  .s {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    letter-spacing: -0.5px;

    /* Title */

    color: #414142;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.5px;

    /* Subtitle */

    color: #707070;
  }
  .w-75 {
    p {
      font-size: 12px;
    }
  }
  .font-weight-700 {
    font-weight: 700;
  }
  .dot {
    width: 11px;
    height: 11px;
    border-radius: 25px;
    background-color: #cbcbcb;
    position: relative;
    z-index: 1;
  }
  hr {
    height: 100px;
    width: 2px;
    margin-top: -44px;
    margin-bottom: -21px;
    margin-left: 4px;
    position: relative;
    z-index: 0;
    color: #e7e7e7;
  }

  @media (max-width: 988px) {
    display: unset;
    height: 17%;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    width: 100%;
    height: auto;
  }

  .value-box {
    height: 17px;
    display: flex;
    justify-content: space-between;
  }
`;
export const Title = styled.h1<ITitle>`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.5px;
`;
