import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts';

import logo from '~/assets/logos/logo-gray.svg';

import EditTable from '~/assets/icons/edit-table.svg';
import formatCurrency from '~/utils/formatCurrency';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { Container, Box } from './styles';

const ResourceComposition: React.FC = () => {
  const history = useHistory();
  const { serviceOrder } = useServiceOrder();
  const [series, setSeries] = useState([0]);

  useEffect(() => {
    setSeries([
      serviceOrder.simulacao?.vlrFinanciado || 0,
      serviceOrder.simulacao?.vlrEntrada || 0,
      serviceOrder.simulacao?.vlrFgts || 0,
    ]);
  }, [serviceOrder.simulacao]);

  const options = useMemo(
    () => ({
      options: {
        labels: ['Valor financiado', 'Valor de entrada', 'Valor do FGTS'],
        colors: ['#8675FE', '#FEBA6A', '#FF718B'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            show: true,
            formatter(val: number) {
              return `${formatCurrency(val)}`;
            },
            title: {
              formatter: (seriesName: string) => `${seriesName}: `,
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
      },
      series,
    }),
    [series]
  );

  const handleClickEditar = useCallback(() => {
    history.push(
      `${process.env.PUBLIC_URL}/simulador/resultado/${serviceOrder?.id}`
    );
  }, [history, serviceOrder?.id]);

  return (
    <Container className="container col-md-5 col-12 card-dashboard p-4 mb-3">
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h2 className="h6 mb-0">Composição do recursos</h2>
        <button
          type="button"
          className="d-flex align-items-center btn py-0"
          onClick={handleClickEditar}
        >
          <img src={EditTable} alt="EditTable" className="me-1" /> editar{' '}
        </button>
      </div>

      <Box>
        <div className="position-relative d-flex align-items-center justify-content-center">
          <Chart
            options={options.options}
            series={options.series}
            type="donut"
            className="py-4"
            height={220}
          />
          <img src={logo} alt="logo" className="logo position-absolute" />
        </div>
      </Box>
      <div className="m-1">
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot me-3" />
          <p className="mb-0">Valor financiado</p>
          <p className="mb-0 s font-weight-700 ms-auto">
            {formatCurrency(serviceOrder.simulacao?.vlrFinanciado || 0)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-2 me-3" />
          <p className="mb-0">Valor de entrada</p>
          <p className="mb-0  s font-weight-700 ms-auto">
            {formatCurrency(serviceOrder.simulacao?.vlrEntrada || 0)}
          </p>
        </div>
        <div className="d-flex subtitle align-items-center mb-4">
          <div className="dot dot-3 me-3" />
          <p className="mb-0">Valor do FGTS</p>
          <p className="mb-0 s font-weight-700 ms-auto">
            {formatCurrency(serviceOrder.simulacao?.vlrFgts || 0)}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ResourceComposition;
