import React, { useCallback, useEffect, useState } from 'react';

import Input from '~/components/Input';
import { Container, RadioInput } from './styles';

export interface IOption {
  id: string | number;
  value: string;
}

interface IInputRadio {
  name: string;
  options: IOption[];
  className?: string;
  onChange?(value: IOption): void;
  selected?: IOption;
  noForm?: boolean;
}

const InputRadio: React.FC<IInputRadio> = ({
  name,
  options,
  className,
  selected,
  onChange,
  noForm,
}) => {
  const [valueSelected, setValueSelected] = useState<string | number>('');

  useEffect(() => {
    if (selected) {
      setValueSelected(selected.id);
    }
  }, [selected]);

  const handleChange = useCallback(
    (value) => {
      setValueSelected(value.id);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <>
      <Container className={className}>
        {options.map((option, index) => (
          <RadioInput
            htmlFor={`${name}_${index}`}
            selected={valueSelected === option.id}
          >
            <div />
            <input
              type="radio"
              id={`${name}_${index}`}
              name={`${name}_radio`}
              value={option.id}
              onChange={() => handleChange(option)}
            />
            <span>{option.value}</span>
          </RadioInput>
        ))}
      </Container>
      {!noForm && (
        <Input name={name} value={valueSelected} className="d-none" />
      )}
    </>
  );
};

export default InputRadio;
