import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}
interface IModal {
  size?: 'md';
}
interface IBoxButtons {
  show?: boolean;
}

export const GeneralContainer = styled.div`
  /* > div:nth-child(1) { */
  width: calc(100% - var(--sidebar-width));
  /* } */

  .no-mobile {
    @media (min-width: 991px) {
      display: none;
    }
  }

  .mobile {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .top-menu {
  }

  .ancho-space {
    margin-left: 30px;

    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
`;

export const Container = styled.div`
  .col-1 {
    width: 10%;
    margin: auto -6%;
    @media (min-width: 1400px) {
      margin: auto 1%;
    }
  }

  .notify {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 10%;
    left: 120%;
    width: 46px;
    height: 46px;
    @media (min-width: 500px) {
      left: 100%;
    }
    @media (min-width: 700px) {
      top: 8%;
    }
    @media (min-width: 1000px) {
      top: 5%;
      left: 150%;
    }
    @media (min-width: 1200px) {
      top: 8%;
      left: 150%;
    }
    @media (min-width: 1440px) {
      left: 90%;
      top: 55px;
    }
    @media (min-width: 1700px) {
      left: 74%;
      top: 6%;
    }
    @media (min-width: 3000px) {
      left: 99%;
      top: 4%;
    }

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      right: -15px;
      top: 11px;
      @media (min-width: 1440px) {
        right: -2px;
        top: 10px;
      }
    }
  }

  #ContainerAvatar {
    z-index: 2;
    margin: auto;
    top: 43%;
    @media (min-width: 500px) {
      top: 31%;
      left: 32%;
    }
    @media (min-width: 700px) {
      width: 30%;
      top: 18%;
      left: 70%;
    }
    @media (min-width: 1000px) {
      top: 13%;
    }
    @media (min-width: 1200px) {
      top: 19%;
    }
    @media (min-width: 1440px) {
      top: 21%;
      width: 22%;
      margin-left: 20%;
      border-radius: 10px;
    }
    @media (min-width: 1700px) {
      left: 55%;
      top: 20%;
    }
    @media (min-width: 3000px) {
      top: 9%;
      left: 75%;
    }
  }

  .contenedorOS-bdi {
    position: absolute;
    width: 30%;
    height: 80px;
    display: flex;
    margin-left: 800px;
    @media (min-width: 1445px) {
      margin-left: 910px;
    }
  }

  .app-drop-down-container {
    z-index: 1;
    top: 10%;
    @media (max-width: 600px) {
      position: absolute;
      left: 125%;
      top: 9%;
      height: 30%;
      width: 30%;
    }
    @media (max-width: 1440px) {
      left: 125%;
      top: 9%;
      height: 30%;
      width: 10%;
    }

    .border-0 {
      margin-left: 30%;
    }
    @media (min-width: 300px) {
      margin-left: -20%;
    }
  }

  .nav-item {
    margin: 40px auto;
    height: 25%;
    width: 150px;
    z-index: 1;
    margin-left: -120px;
    position: absolute;
    background-color: #fcfcfc;
    border: 2px solid #e5e5e5;
    /* border-top: none; */
    /* border-radius: 5%; */
    @media (max-width: 1440px) {
      width: 25%;
      margin-left: -20%;
    }
    .border-0 {
      width: 100%;
      height: 38%;
      margin: 2% auto;

      background-color: #fcfcfc;
      :hover {
        background-color: #ebebeb;
      }
    }

    .linea {
      width: 70%;
      text-align: center;
    }
  }

  input {
    color: #414142;
    font-size: 130%;
    @media (min-width: 700px) {
      height: 100%;
    }
    ::placeholder {
      color: #b9b8bc;
    }
  }

  .carousel {
    @media (min-width: 1440px) {
      width: 755px;
    }
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev {
    display: none;
  }

  .carousel-control-next {
    display: none;
  }

  .circle11 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 480px;
    top: -30px;
    background: #ffffff;
  }

  .circle22 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 480px;
    top: -50px;
    background: #ffffff;
  }

  .circle33 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 480px;
    top: -70px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle44 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 480px;
    top: -10px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle111 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 520px;
    top: -30px;
    background: #ffffff;
  }

  .circle222 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 520px;
    top: -50px;
    background: #ffffff;
  }

  .circle333 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 520px;
    top: -70px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle444 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 520px;
    top: -10px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle1111 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 540px;
    top: -20px;
    background: #ffffff;
  }

  .circle2222 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 540px;
    top: -40px;
    background: #ffffff;
  }

  .circle3333 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 540px;
    top: -60px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle11111 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 460px;
    top: -20px;
    background: #ffffff;
  }

  .circle22222 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 460px;
    top: -40px;
    background: #ffffff;
  }

  .circle33333 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 460px;
    top: -60px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle1 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 500px;
    top: -40px;
    background: #ffffff;
  }

  .circle2 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 500px;
    top: -60px;
    background: #ffffff;
  }

  .circle3 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 500px;
    top: -80px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle4 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 500px;
    top: -20px;
    opacity: 0.2;
    background: #ffffff;
  }

  .circle5 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 500px;
    top: 0px;
    opacity: 0.2;
    background: #ffffff;
  }

  .carousell {
    width: 180%;
    height: 100%;
    @media (min-width: 500px) {
      width: 180%;
      margin-top: 3%;
    }
    @media (min-width: 1440px) {
      width: 167%;
      border-radius: 12px;
    }
    .mt-4 {
      width: 90%;
      height: 25%;
      @media (min-width: 1020px) {
        width: 85%;
        height: 25%;
      }
      .col-lg-5 {
        width: 80%;
        position: absolute;
        top: -4%;
      }
    }
    .col-lg-8 {
      position: absolute;
      top: 35%;
      width: 85%;
    }

    .dollar {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 70%;
      left: 70%;
    }

    background: linear-gradient(
        75.33deg,
        rgba(0, 0, 0, 0.2) -10.41%,
        rgba(0, 0, 0, 0) 62.93%
      ),
      #f4b000;
    height: 350px;
    border-radius: 12px;
  }

  .sun {
    color: #202020;
  }

  .btn-know {
    height: 46px;
    background: #ffffff;
    border-radius: 40px;
  }
  /* .h-100 {
    width: 180%;
    @media (min-width: 500px) {
      width: 100%;
    }
    @media (min-width: 1000px) {
      width: 100%;
      margin-left: 20%;
    }
  } */

  .btn-build {
    background-color: #fcfcfc;
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .tasks {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 12px;
  }

  .tasks {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    width: 65.2px;
    height: 60.28px;
    border-radius: 10px;
    transition-duration: 0.3s;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .calendar {
    background: #ffffff;
    border-radius: 10px;
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }
`;

export const Container2 = styled.div`
  .notify {
    border-radius: 50%;
    background: #f5f5f5;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 46px;
    height: 46px;

    .notify-alert {
      border-radius: 50%;
      background: #ff1a50;
      position: absolute;
      width: 7.5px;
      height: 7.5px;
      border-radius: 24px;
      right: 12px;
      top: 12px;
    }
  }

  .espaciado3 {
    width: 240px;
    @media (min-width: 2000px) {
      width: 160px;
    }
  }

  .chat {
    background-color: #f5f5f5;
    border-radius: 50%;
  }

  .agrandar {
    width: 460px;
  }

  .btn-build {
    border-radius: 30px;
    height: 27px;
    transition: 0.3ms;
    color: #7a797d;

    :hover {
      background-color: #e1f1ed;
      color: #079269;
    }
  }

  .active {
    background: #e1f1ed;
    color: #079269;
  }

  .my-so {
    color: #27262e;
  }

  .date-task {
    background: #ffffff;
    color: #414142;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    transition: 0.3ms;

    .week {
      color: #b8b9ba;
    }

    :hover {
      background-color: #39a887;
      color: #ffffff;

      .week {
        color: #ffffff;
      }
    }
  }

  .period-task {
    color: #707070;
  }

  .active-date {
    background: #39a887;
    color: #ffffff;

    .week {
      color: #ffffff;
    }
  }

  .reminder {
    font-size: 12px;
  }

  .clock {
    font-size: 10px;
  }

  .os-table {
    > div:nth-child(1) {
      ::before,
      ::after {
        border: none;
      }

      .content {
        .tb-header,
        .tb-body {
          .tb-row {
            button {
              font-size: 16px;
            }
            div {
              font-size: 14px;
              p {
                font-size: 14px;
              }
            }
            > button:nth-child(1),
            > div:nth-child(1) {
              width: 250px;
            }
            > button:nth-child(2),
            > div:nth-child(2) {
              width: 250px;
            }
            > button:nth-child(3),
            > div:nth-child(3) {
              width: 250px;
            }
            > button:nth-child(4),
            > div:nth-child(4) {
              width: 250px;
            }
            > button:nth-child(5),
            > div:nth-child(5) {
              width: 200px;
            }
            > button:nth-child(6),
            > div:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 42px;
  height: 45px;
  border-radius: 50%;
`;

export const Buttons = styled.div`
  a {
    color: #414142;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      height: 46.29px;
      width: 46.29px;
      border-radius: 50%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    height: 46.29px;
    width: 46.29px;
    //border-radius: 50%;
  }
`;

export const BoxButtons = styled.div<IBoxButtons>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  background: #ededed;
  border-radius: 10px;
  position: absolute;
  right: 70px;
  top: 35px;

  button {
    :hover {
      color: ${darken(0.1, '#707070')};
    }

    + button {
      border-top: 1px solid #b8b9ba !important;
    }
  }

  button.text-red {
    color: #ff1a50;

    :hover {
      color: ${darken(0.1, '#ff1a50')};
    }
  }

  :after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ededed transparent;
    transform: rotate(180deg);
  }
`;

export const Modal = styled(ModalComponent)<IModal>`
  .d-none {
    display: none;
  }

  .modalContent {
    max-width: ${(props) => (props.size === 'md' ? '400px' : '500px')};
  }

  label {
    color: #8a8b8b;
    font-weight: 500;
  }

  .input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e6e7e8;

    input,
    textarea {
      color: #505050;
    }

    input::placeholder,
    textarea::placeholder {
      color: #e6e7e8;
    }
  }

  .btn-submit {
    background-color: #39a887;
    color: #ffffff;
    font-weight: 600;
  }

  .link {
    color: #1762e1;
  }

  .border-input-group {
    background: #f9f9f9;
    border-radius: 10px;
  }

  .input-period {
    input {
      color: #1e293b;
      font-size: 24px;
    }
  }
`;
