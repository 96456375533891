import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  .btn-logout {
    color: #ff1a50;
  }
  .rouded-customs {
    border-radius: 10px;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background: url(${(props) => props.src}), #c4c4c4;
  background-size: cover;
  background-position: center;
  width: 102px;
  height: 102px;
  border-radius: 50%;
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #c6c6c9;

      input::placeholder {
        color: #b8b9ba;
      }
    }
  }
`;
