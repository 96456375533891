import React from 'react';

// Hooks
import { useServiceOrder } from '~/hooks/ServiceOrder';

// Styles
import { Mock } from './Partials/Mock';
import { Container } from './styles';

const PersonalInformationBox: React.FC = () => {
  const { serviceOrder } = useServiceOrder();

  return (
    <>
      {Object.keys(serviceOrder).length <= 0 ? (
        <Mock />
      ) : (
        <Container className="container me-5 mt-5 card-dashboard p-0 mb-5">
          <div className="ms-4 mt-4 m-lg-4">
            <div className="d-flex  align-items-center mb-4">
              <h4 className="mb-0 font-weight-700 text-black">
                Informações pessoais
              </h4>
            </div>
          </div>
          <div className="row mb-lg-4 ms-3">
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  OS:
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.id || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Tipo:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.finalidade?.finalidade || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Amortização:{' '}
                  <strong className="text-2 ps-2">
                    <small>
                      {serviceOrder.simulacao?.amortizacao?.amortizacao}
                    </small>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-lg-4 ms-3">
            <div className="col-auto order-0 mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Cliente:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.cliente?.nome || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto order-1 mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Telefone:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.cliente?.celular || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto order-3 mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Email:
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder?.cliente?.email || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-lg-4 ms-3">
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  CPF:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder?.cliente?.documento || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Estado do Terreno:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.terreno?.estado?.sigla || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Municipio do Terreno:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder.terreno?.municipio || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
            {serviceOrder?.agencia && (
              <div className="col-auto mb-2 mb-lg-0">
                <div className="row">
                  <div className="col-auto text">
                    Agência:{' '}
                    <strong className="text-2 ps-2">
                      <small>{serviceOrder?.agencia}</small>
                    </strong>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row mb-4 ms-3">
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Casado:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder?.conjuge ? 'Sim' : 'Não'}</small>
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-auto mb-2 mb-lg-0">
              <div className="row">
                <div className="col-auto text">
                  Cônjuge:{' '}
                  <strong className="text-2 ps-2">
                    <small>{serviceOrder?.conjuge?.nome || '-'}</small>
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default PersonalInformationBox;
