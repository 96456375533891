import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';

import { Modal } from '../../../styles';

interface IMunicipality {
  id: number;
  name: string;
}

interface IState {
  id: number;
  name: string;
  code: number;
}

interface IUserProps {
  id: number;
  nome: string;
  documento: string;
  profissao: string;
}

interface IModalAdressData {
  cep: string;
  endereco: string;
  numero: number;
  bairro: string;
  estado?: string;
  estado_id: string;
  municipio: string;
}

interface IModalAdressProps {
  show: boolean;
  adressData: IModalAdressData;
  user: IUserProps;
  onHide: () => void;
}

const ModalAdress: React.FC<IModalAdressProps> = ({
  show,
  adressData,
  user,
  onHide,
}) => {
  const history = useHistory();
  const [states, setStates] = useState<IOption[]>([]);
  const [municipalities, setMunicipalities] = useState<IOption[]>([]);
  const [municipality, setMunicipality] = useState({} as IOption);
  const [stateIdSelected, setStateIdSelected] = useState(0);
  const formAddAdress = useRef<FormHandles>(null);

  // AO INICIAR
  // CARREGAR CIDADE
  const handleLoadMunicipality = useCallback(async () => {
    const response = await api.get<IMunicipality[]>(
      `municipalities/state/${adressData.estado_id}`
    );

    const data: IOption[] = response.data.map((municipalityData) => ({
      id: municipalityData.id,
      value: municipalityData.name,
      selected: Number(municipalityData.id) === Number(adressData.municipio),
    }));

    setMunicipalities(data);
  }, [adressData.municipio, adressData.estado_id]);

  // CARREGAR ESTADO
  const handleLoadState = useCallback(() => {
    api.get<IState[]>('builders/states/').then((response) => {
      const data: IOption[] = response.data.map((state) => ({
        id: state.id,
        value: state.name,
        selected: Number(state.id) === Number(adressData.estado_id),
      }));

      setStates(data.filter((state) => state.id === adressData.estado_id));
      handleLoadMunicipality();
    });
  }, [adressData.estado_id, handleLoadMunicipality]);

  // AO PESQUISAR NOVO ESTADO
  const handleSearchState = useCallback((value) => {
    api
      .get<IState[]>('builders/states', {
        params: {
          search: value,
        },
      })
      .then((response) => {
        const data: IOption[] = response.data.map((state) => ({
          id: state.id,
          value: state.name,
          selected: false,
        }));

        setStates(
          data.sort((x, y) => {
            if (x.value > y.value) return 1;
            if (x.value < y.value) return -1;
            return 0;
          })
        );
      });
  }, []);

  // INICIAR
  useEffect(() => {
    if (adressData.estado_id !== null) {
      handleLoadState();
    } else {
      handleSearchState('');
    }
  }, [adressData.estado_id, handleLoadState, handleSearchState]);

  // CIDADES
  const handleChangeStateLoad = useCallback(
    async (option) => {
      const response = await api.get<IMunicipality[]>(
        `municipalities/state/${option.id}`
      );

      const data: IOption[] = response.data.map((municipalityData) => ({
        id: municipalityData.id,
        value: municipalityData.name,
        selected: municipalityData.id === Number(adressData.municipio),
      }));

      setMunicipalities(data);
    },
    [adressData.municipio]
  );

  const handleChangeState = useCallback(
    (state) => {
      handleChangeStateLoad(state);
      setStateIdSelected(state.id);
      handleSearchState(state.value);
    },
    [handleChangeStateLoad, handleSearchState]
  );

  // RESETAR MUNICIPIOS
  const handleResetMunicipialitys = useCallback(() => {
    setMunicipalities([]);
  }, []);

  const handleStateMunicipiality = useCallback(
    (state) => {
      handleSearchState(state);

      setMunicipalities([
        {
          id: 0,
          value: 'Selecione',
          selected: true,
          notSelectable: true,
        },
      ]);

      setTimeout(handleResetMunicipialitys, 5);
    },
    [handleResetMunicipialitys, handleSearchState]
  );

  // PESQUISAR CIDADE
  const handleChangeMunicipality = useCallback((option) => {
    setMunicipality(option);
  }, []);

  const handleSearchMunicipality = useCallback(
    async (value) => {
      const response = await api.get<IMunicipality[]>(
        `municipalities/state/${
          stateIdSelected !== Number(adressData.estado_id)
            ? adressData.estado_id
            : stateIdSelected
        }`,
        {
          params: {
            search: value,
          },
        }
      );

      const data: IOption[] = response.data.map((municipalityData) => ({
        id: municipalityData.id,
        value: municipalityData.name,
        selected: municipality.id === Number(adressData.municipio),
      }));

      setMunicipalities(data);
    },
    [
      adressData.estado_id,
      adressData.municipio,
      municipality.id,
      stateIdSelected,
    ]
  );

  // AO FECHAR
  const handleClose = useCallback(() => {
    if (adressData.estado_id !== null) {
      handleLoadState();
      onHide();
    } else {
      onHide();
    }
  }, [adressData.estado_id, handleLoadState, onHide]);

  // SUBMETER
  const onSubmit = useCallback(
    async (data: IModalAdressData) => {
      try {
        formAddAdress.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.string().required('obrigatório informar o CEP'),
          endereco: Yup.string().required('obrigatório informar a rua'),
          numero: Yup.string().required('obrigatório informar o numero'),
          bairro: Yup.string().required('obrigatório informar o bairro'),
          estado: Yup.string().required('obrigatório informar o estado'),
          municipio: Yup.string().required('obrigatório informar a cidade'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          bairro, cep, endereco, estado, municipio, numero
        } = data;

        const formData = {
          nome: user.nome,
          documento: user.documento,
          profissao: user.profissao,
          bairro,
          cep,
          endereco,
          estado_id: estado,
          municipio,
          numero,
        };

        try {
          api.put(`builders/customers/${user.id}`, formData);
          history.push(`${process.env.PUBLIC_URL}/solicitacoes`);

          onHide();
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErros(err);
            formAddAdress.current?.setErrors(errors);
          } else {
            Swal.fire(
              'Opss...',
              'Erro ao atualizar dados de endereço',
              'error'
            );
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formAddAdress.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Erro ao atualizar dados de endereço', 'error');
        }
      }
    },
    [history, onHide, user.documento, user.id, user.nome, user.profissao]
  );
  return (
    <>
      <Modal show={show} onHide={handleClose} close size="lg">
        <Form
          className="py-5 px-3 px-md-5"
          ref={formAddAdress}
          initialData={adressData}
          onSubmit={onSubmit}
        >
          <Modal.Header className="border-0" closeButton>
            <h4 className="text-center w100 h5 fw-semibold text-dark-gray">
              Editar Endereço
            </h4>
          </Modal.Header>
          <Modal.Body className="pt-4 pb-5">
            <div className="mb-4">
              <label className="w-100">
                <span className="small">
                  CEP: <span className="text-primary">*</span>
                </span>
                <InputMask
                  kind="zip-code"
                  className="input"
                  name="cep"
                  value={adressData.cep}
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="w-100">
                <span className="small">
                  Rua: <span className="text-primary">*</span>
                </span>
                <Input className="input" name="endereco" />
              </label>
            </div>
            <div className="mb-4 d-lg-flex">
              <label className="w-100 pe-2 mb-4 mb-lg-0">
                <span className="small">
                  Número: <span className="text-primary">*</span>
                </span>
                <Input className="input" name="numero" />
              </label>
              <label className="w-100 ps-2">
                <span className=" small">
                  Bairro: <span className="text-primary">*</span>
                </span>
                <Input className="input" name="bairro" />
              </label>
            </div>
            <div className="mb-4 d-lg-flex">
              <label className="w-100 pe-2 mb-4 mb-lg-0">
                <span className="small">
                  Estado: <span className="text-primary">*</span>
                </span>
                <Select
                  name="estado"
                  placeholder="Selecione"
                  className="input mt-2"
                  options={states}
                  onChange={handleChangeState}
                  onChangeText={handleStateMunicipiality}
                  autoComplete="new-password"
                />
              </label>
              <label className="w-100 ps-2">
                <span className="small">
                  Cidade: <span className="text-primary">*</span>
                </span>
                <Select
                  name="municipio"
                  placeholder="Selecione"
                  className="input mt-2"
                  options={municipalities}
                  onChange={handleChangeMunicipality}
                  onChangeText={handleSearchMunicipality}
                  autoComplete="new-password"
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <button
              className="btn btn-primary w-100 w-md-75 py-2"
              type="submit"
            >
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAdress;
