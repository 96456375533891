import React, { useCallback, useState } from 'react';

interface IProps {
  onChange: (value: number | null) => void;
}

const ButtonsSearch: React.FC<IProps> = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const handleChangeTab = useCallback(
    (tab: number | null) => {
      onChange(tab);
      setActiveTab(tab);
    },
    [onChange]
  );

  return (
    <div className="row justify-content-end">
      <div className="col-6">
        <button
          type="button"
          className={`border-0 btn-build px-2 me-3 bg-transparent ${
            activeTab === 1 ? 'active' : ''
          }`}
          onClick={() => handleChangeTab(activeTab === 1 ? null : 1)}
        >
          Pendentes Construtor
        </button>
      </div>
      <div className="col-6">
        <button
          type="button"
          className={`border-0 btn-build px-2 bg-transparent ${
            activeTab === 2 ? 'active' : ''
          }`}
          onClick={() => handleChangeTab(activeTab === 2 ? null : 2)}
        >
          Pendentes Correspondente
        </button>
      </div>
    </div>
  );
};

export default ButtonsSearch;
