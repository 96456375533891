import styled from 'styled-components';

export const DownloadDocuments = styled.div`
  .button-credit {
    border: 0;
    background-color: #01cc9d;
    color: #ffffff;
    border-radius: 20px;
    padding: 0.5rem 2.5rem;

    width: auto;
    height: auto;
    @media (max-width: 1199px) {
      margin-top: 3%;
    }
  }

  .button-proposal {
    border: 0;
    background-color: #414142;
    color: #ffffff;
    border-radius: 20px;

    width: auto;
    height: auto;
    @media (max-width: 1199px) {
      margin-top: 3%;
    }
  }

  .financing-box {
    cursor: pointer;
    @media (max-width: 1199px) {
      padding-top: 20px;
    }
  }
  .roww {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .coll {
    display: flex;
    align-items: center;
  }

  .financing-text {
    color: #9d9d9d;
  }

  .circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #e6e7e8;
    border-radius: 50%;
    margin-left: 10px;
  }
`;
