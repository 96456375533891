import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import randomColor from '~/utils/colorGenerator';
import { Container, GroupBox, BoxTag } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  tags?: string[];
  error?: string;
  hasError?(hasError: boolean): void;
}

interface ITag {
  color: string;
  value: string;
}

const Input: FC<InputProps> = ({
  name,
  tags,
  hasError,
  className,
  error: errorData,
  type,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [tagsData, setTagsData] = useState<ITag[]>([]);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  useEffect(() => {
    if (tags) {
      const data: ITag[] = tags.map((tag) => ({
        color: randomColor(),
        value: tag,
      }));
      setTagsData(data);
    }
  }, [tags]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (e.key === ',' || e.keyCode === 188 || e.which === 188) {
        e.preventDefault();
        setTagsData((state) => [
          ...state,
          {
            color: randomColor(),
            value,
          },
        ]);
        e.target.value = '';
      }
      if (
        (e.key === 'Backspace' || e.keyCode === 8 || e.which === 8) &&
        value.length === 0
      ) {
        e.preventDefault();
        const newTags = tagsData.slice();
        const oldTag = newTags.pop()?.value;
        setTagsData(newTags);
        if (oldTag) {
          e.target.value = oldTag;
        }
      }
    },
    [tagsData]
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <GroupBox className="d-flex flex-wrap">
          {tagsData.map((tag, index) => (
            <BoxTag
              key={index.toString()}
              color={tag.color}
              className="rounded-pill px-3 py-1 d-flex"
            >
              <span className="d-inline-block ms-2">{tag.value}</span>
            </BoxTag>
          ))}
          <input
            type={type || 'text'}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleChange}
            {...rest}
          />
        </GroupBox>
      </Container>
      <input
        defaultValue={defaultValue}
        ref={inputRef}
        value={tagsData.map((tag) => tag.value)}
        type="hidden"
        className="d-none"
      />
      <small>
        <small>Use , para separar.</small>
      </small>
      {(errorData || error) && (
        <span className="small text-danger error">{errorData || error}</span>
      )}
    </>
  );
};

export default Input;
