import React, {
  Ref, useCallback, useEffect, useState
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { BsTrash } from 'react-icons/bs';
import { IMParticipant } from '~/models/Participant';
import initialsName from '~/utils/initialsName';

import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputRadio, { IOption as IOptionRadio } from '~/components/InputRadio';
import Select, { IOption as IOptionSelect } from '~/components/Select';
import { useDefaultData } from '~/hooks/DefaultData';

import api from '~/services/api';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import { Container } from './styles';

interface IFormEdit {
  participant?: IMParticipant;
  color?: string;
  nameLengthError: string;
  cpfError: string;
  hasMarried: boolean;
  hasFGTS: boolean;
  formRef: Ref<FormHandles>;
  onHandleSubimt(data: IMParticipant): void;
  onHandleValidateName(value: string): void;
  onHandleValidateCPFInput(value: string): void;
  onHandleChangeHasMarried(value: never): void;
  onHandleChangeHasFGTS(value: never): void;
}

const FormEdit: React.FC<IFormEdit> = ({
  participant,
  color,
  nameLengthError,
  cpfError,
  hasMarried,
  hasFGTS,
  formRef,
  onHandleSubimt,
  onHandleValidateName,
  onHandleValidateCPFInput,
  onHandleChangeHasMarried,
  onHandleChangeHasFGTS,
}) => {
  const { setServiceOrder, serviceOrder } = useServiceOrder();
  const { schooling_levels, setDefaultData } = useDefaultData();
  const [updatadedSchoolingLevels, setUpdatadedSchoolingLevels] = useState<
    IOptionSelect[]
  >([]);
  const [choiceData] = useState<IOptionRadio[]>([
    { id: 1, value: 'Sim' },
    { id: 0, value: 'Não' },
  ]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [pisNumber, setPisNumber] = useState('');

  useEffect(() => {
    if (participant) {
      setName(participant?.nome !== 'Conjuge' ? participant?.nome ?? '' : '');
      setEmail(participant.email ?? '');
      setProfession(participant.profissao ?? '');
      setPisNumber(participant.numPis ?? '');
    }
  }, [participant]);

  useEffect(() => {
    if (schooling_levels.length === 0) {
      setDefaultData();
    }
  }, [setDefaultData, schooling_levels]);

  const salary = () => {
    let salaryParticipant = '';
    if (participant?.vlrRenda !== 0) {
      salaryParticipant = parseFloat(String(participant?.vlrRenda)).toFixed(2);
    }
    return salaryParticipant;
  };

  const handleChangeSchoolLevel = useCallback(
    (option) => {
      const newSchoolingLevel = schooling_levels.map((schoolingLevel) => {
        if (schoolingLevel.id === option.id) {
          return { ...schoolingLevel, selected: true };
        }
        return { ...schoolingLevel };
      });
      setUpdatadedSchoolingLevels(newSchoolingLevel);
    },
    [schooling_levels]
  );

  useEffect(() => {
    const newSchoolingLevel = schooling_levels.map((schoolingLevel) => ({
      ...schoolingLevel,
      selected:
          String(schoolingLevel.id) === String(participant?.nvEscolar_id),
    }));

    setUpdatadedSchoolingLevels(newSchoolingLevel);
  }, [participant?.nvEscolar_id, schooling_levels]);

  const handleDeleteParticipant = useCallback(async () => {
    await api.delete(`/builders/participants/${participant?.id}`);
    const newParticipants = serviceOrder?.participantes?.filter(
      (participantItem) => participantItem.id !== participant?.id
    );
    setServiceOrder({ ...serviceOrder, participantes: newParticipants });
  }, [participant?.id, serviceOrder, setServiceOrder]);

  return (
    <Container className="row">
      <div className="col-xl-2 mt-5 initials-box">
        <div
          className="name-initials mx-2"
          style={{
            backgroundColor: color,
          }}
        >
          {initialsName(String(participant?.nome ? participant.nome : ''))}
        </div>
      </div>
      <div className="col-xl-10">
        <Form
          ref={formRef}
          initialData={participant}
          onSubmit={onHandleSubimt}
          className=""
        >
          <div className="row">
            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">
                Nome Completo: <b className="text-primary">*</b>
                <Input
                  className="form-input"
                  name="nome"
                  placeholder="Nome Completo"
                  onChange={(e) => {
                    onHandleValidateName(e.target.value);
                    setName(e.target.value);
                  }}
                  value={name}
                />
                {nameLengthError && (
                  <span className="small text-danger error">
                    {nameLengthError}
                  </span>
                )}
              </label>
            </div>

            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">
                CPF: <b className="text-primary">*</b>
                <InputMask
                  kind="cpf"
                  className="form-input"
                  name="cpf"
                  placeholder="000.000.000-00"
                  onChange={(e) => onHandleValidateCPFInput(e.target.value)}
                  value={participant?.cpf ? participant.cpf : ''}
                />
                {cpfError && (
                  <span className="small text-danger error">{cpfError}</span>
                )}
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">
                Email: <b className="text-primary">*</b>
                <Input
                  className="form-input"
                  name="email"
                  placeholder="email@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </label>
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">
                Celular: <b className="text-primary">*</b>
                <InputMask
                  className="form-input"
                  placeholder="(  ) "
                  name="celular"
                  kind="cel-phone"
                  value={participant?.celular}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">
                Valor de Renda: <b className="text-primary">*</b>
                <InputMask
                  kind="money"
                  className="form-input"
                  name="vlrRenda"
                  placeholder="R$ 0,00"
                  value={salary()}
                />
              </label>
            </div>
            <div className="col-lg-6 mb-3 mt-4">
              <label className="w-100">Profissão</label>
              <Input
                className="form-input"
                name="profissao"
                placeholder="Profissão"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            {participant?.type !== 'spouse' && (
              <div className="col-lg-6 mb-3 mt-4">
                <label>
                  Casado(a)? <b className="text-primary">*</b>
                </label>
                <InputRadio
                  name="casado"
                  options={choiceData}
                  selected={hasMarried ? choiceData[0] : choiceData[1]}
                  onChange={onHandleChangeHasMarried}
                  className="mt-4 box-radio"
                />
              </div>
            )}
            <div className="col-lg-6 mb-3 mt-4">
              <label>
                Nível de escolaridade: <b className="text-primary">*</b>
              </label>
              <Select
                type="name"
                placeholder="Selecione"
                name="nvEscolar_id"
                className="form-input"
                options={updatadedSchoolingLevels}
                onChange={handleChangeSchoolLevel}
              />
            </div>
            <div className="col-lg-6">
              <div className="row mb-3 mt-4">
                <label className="w-100">Possui FGTS ?</label>
                <InputRadio
                  name="fgts"
                  options={choiceData}
                  selected={hasFGTS ? choiceData[0] : choiceData[1]}
                  onChange={onHandleChangeHasFGTS}
                  className="mt-4 box-radio"
                />
              </div>
              {hasFGTS && (
                <div className="col-lg-6 mb-3 mt-4">
                  <label className="w-100">Valor FGTS</label>
                  <InputMask
                    kind="money"
                    className="form-input"
                    name="vlrFgts"
                    placeholder="R$ 0,00"
                    value={
                      participant?.vlrFgts
                        ? parseFloat(String(participant?.vlrFgts)).toFixed(2)
                        : ''
                    }
                  />
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <div className="col-lg-12 mb-3 mt-4">
                <label>PIS:</label>
                <Input
                  className="form-input"
                  name="numPis"
                  placeholder="PIS"
                  value={pisNumber}
                  onChange={(e) => setPisNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="col mb-3 mt-5 gap-2 button-save-box">
                {participant?.type === 'participant' && (
                  <button
                    type="button"
                    className="button button-delete mt-3"
                    onClick={handleDeleteParticipant}
                  >
                    <BsTrash size={24} />
                  </button>
                )}
                <button type="submit" className="button button-save mt-3">
                  <b>Salvar</b>
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default FormEdit;
