import React, { useCallback, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsBoxArrowInRight } from 'react-icons/bs';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
// import { BsPlusLg } from 'react-icons/bs';
import { BsBuilding, BsCashStack, BsChevronDoubleDown, BsExclamationTriangleFill, BsFacebook, BsFillBookmarkStarFill, BsInstagram, BsPeopleFill, BsWhatsapp, BsYoutube } from 'react-icons/bs';
import menu from '~/assets/icons/menu.svg';

import requestOrcamento from '~/assets/banners/Soli.png';
import chaat from '~/assets/banners/chaat.png';
import gestionObraa from '~/assets/banners/gestionObraa.png';
import learning from '~/assets/banners/leiuaut.png';
import markett from '~/assets/banners/markett.png';
import meuSite from '~/assets/banners/meuSitee.png';
import shop from '~/assets/banners/shop.png';
import orderService from '~/assets/banners/simulacaos.png';
import logoHarvey from '~/assets/logos/logo-harvey.svg';
import logo from '~/assets/logos/logoBlack.svg';
import {
  Background,
  Background2,
  Background3,
  Container,
  Container2,
  Container21,
  Container3,
  Container31,
  Container322,
  Container4,
  ContainerButtons,
  ContainerFont,
  ContainerTextInfo,
  Title,
} from './styles';

interface IImageProps {
  image: string;
  description: string;
}

const Login: React.FC = () => {
  const history = useHistory();
  const [navbar, setNavbar] = useState(false);
  const descriptions = {
    orderService:
      'Gerencie suas ordens de serviço, Visualize os dados dos seus clientes ou obras, resolvemos todo o processo burocrático do crédito',
    requestOrcamento:
      'Receba as solicitações dos seus clientes diretamente pelo harvey, e contate-os',
    chaat:
      'Tenha contrato direto com seus clientes, com nossos correspondetes e clientes por links externos',
    gestionObraa:
      'Tenha gestão total de todas as atividades, insumos, financeiro. Compartilhe os protejo com seus clientes, tenha total agilidade.',
    markett:
      'Tenha contrato direto com seus clientes, com nossos correspondentes e clientes por links externos',
    learning:
      'Se atualize siempre que possível, temos cursos acompanhando o mercado de trabalho, tenha acesso a plataforma <b>Leiaut</b>',
    meuSite:
      'Tenha um site personalizado só para você, compartilhe com seus clientes e receba as solicitações direto pelo harvey, exponha todos os seus trabalhos',
    shop: 'Faltou algo na obra? Não se preocupe, com o MCF Shop você encontra tudo que precisa em um só lugar!',
  };
  const [image, setImage] = useState<IImageProps>({
    image: orderService,
    description: descriptions.orderService,
  });

  const classNavbar = 'row py-4 d-flex align-items-center navbar_container';
  const handleClickLogin = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/login`);
  }, [history]);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener('scroll', changeBackground);
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRedirect = (url: string) => {
    window.open(`https:${url}`, '_blank');
  };

  return (
    <>
      <Container className="">
        <Background className="">
          <div className={classNavbar + (navbar ? ' navbar_scroll' : '')}>
            <div className="d-flex align-items-center gap-2 gap-md-4">
              <div className="col">
                <img src={logo} alt="logo" className="px-5 ms-5" />
              </div>
              <div className="col-md-auto text-dark h6 mobile">
                <button
                  type="button"
                  onClick={() => handleRedirect('www.minhacasafinanciada.com/')}
                  className="button-sin-estilos"
                >
                  Minha Casa Financiada
                </button>
              </div>
              <div className="col-md-auto text-dark h6 mobile">
                Obter meu harvey
              </div>
              <div className="col col-lg-2 text-end text-md-start mobile">
                <button
                  type="button"
                  className="text-white rounded-pill px-4 py-1 h6"
                  style={{
                    border: 'unset',
                    background: '#333333',
                  }}
                  onClick={handleClickLogin}
                >
                  <BsBoxArrowInRight className="" />
                  <span className="d-none d-md-block">Entrar</span>
                </button>
              </div>
              <div className="menu">
                <button
                  type="button"
                  onClick={handleToggle}
                  className="trasparente"
                >
                  <img src={menu} alt="menu" />
                </button>

                <div className={`slider-menu ${isOpen ? 'open' : ''}`}>
                  <button
                    type="button"
                    className="menu-button"
                    onClick={handleToggle}
                  >
                    {isOpen ? <FaTimes /> : <FaBars />}
                  </button>
                  <ul className="menu-items">
                    <li className="col-md-auto text-white h6">
                      <button
                        type="button"
                        onClick={() => handleRedirect('www.minhacasafinanciada.com/')
                        }
                        className="col-md-auto text-white button-sin-estilos2 h6"
                      >
                        Minha Casa Financiada
                      </button>
                    </li>
                    <li className="col-md-auto text-white h6 cursor-none">
                      Obter meu harvey
                    </li>
                    <li className="col col-lg-2 text-end text-md-start">
                      <button
                        type="button"
                        className="text-white rounded-pill px-4 py-1 h6"
                        style={{
                          border: 'unset',
                          background: '#333333',
                        }}
                        onClick={handleClickLogin}
                      >
                        <BsBoxArrowInRight className="" />
                        <span className="d-none d-md-block">Entrar</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content_main">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="row d-flex justify-content-center">
              <h4 className="text-center col-md-auto">
                <IconContext.Provider value={{ color: '#39A887' }}>
                  <BsExclamationTriangleFill />
                </IconContext.Provider>
              </h4>
              <h4
                className="text-center col-md-auto "
                style={{ color: '#5F5F5F' }}
              >
                Conteúdo Exclusivo
              </h4>
            </div>
            <h2
              className="fw-light text-center company"
              style={{ fontSize: '2.5rem' }}
            >
              <b className="text-harvey">Harvey Construtor</b>
            </h2>
            <div>
              <hr className="line-black" />
            </div>
            <div className="pb-4">
              <p className="text-center text-color-gris">
                Aqui você encontrará todos os modelos de contratos e
                <br />
                Documentações Exclusivas que serão utilizados no processos
                <br className="mobile" />
                de financiamento habitacional.
              </p>
            </div>

            {/* <div className="justify-content-center text-center">
              <button
                type="button"
                className="text-white rounded-pill px-5 py-3 h6 button-quero"
                style={{
                  border: 'unset',
                }}
              >
                <BsPlusLg className="" /> Quero ser parceiro
              </button>
            </div> */}
            <p
              className="text-center"
              style={{
                color: '#939393',
              }}
            >
              <br />
              <br />
              <br />
              Saiba mais
            </p>
            <p
              className="text-center"
              style={{
                color: '#939393',
              }}
            >
              <BsChevronDoubleDown className="h2" />
            </p>
            <br />
            <br />
          </div>
        </Background>
      </Container>
      <div className="bg-white">
        <Container21 className="bg-white">
          <br />
          <br />
          <br />
          <div className="no-mobile">
            <div className="row py-4 px-5 d-flex align-items-center ">
              <div className="col text-escritorio pl-90">
                Seu escritório, digital
              </div>
              <div className="col-md-auto text-isso">Isso é o</div>
              <div className="col-auto text-primary text-isso">futuro</div>
            </div>
          </div>

          <div className="mobile">
            <div className="d-flex justify-content-end pe-3 mb-5">
              <div className="text-isso-mobile pe-2">Isso é o</div>
              <div className="text-isso-mobile text-primary">futuro</div>
            </div>
            <div className="text-escritorio-mobile ps-4 ">
              Seu escritório, digital
            </div>
          </div>

          <div className="row  d-flex align-items-center">
            <Title className="col-lg-6 col-xl-4 px-3 md:px-1 ">
              <br />
              <br />
              <p className="espacio mobile-espacio ">
                <div />
              </p>
            </Title>
          </div>
          <h4 className=" ">
            <div className="no-mobile">
              <div className="row px-4 d-flex align-items-center text-todas ">
                <b className="pl-2 text-todas">
                  Todas as ferramentas que você precisa,
                  <br />
                </b>
                <b className="pl-2 text-todas">em um só lugar.</b>
              </div>
            </div>
            <div className="row px-4 d-flex align-items-center text-todas mobile">
              <b className="pl-2 text-todas">
                Todas as ferramentas que você precisa, em um só lugar.
              </b>
            </div>
            <br />
            <br />
            <br />
            <br />
          </h4>
        </Container21>
        <ContainerButtons className="bg-white row gap gap-1 justify-content-center text-center">
          <div className="overflow-scroll ocultar-scroll">
            <div className="scroll">
              <div className="py-5 d-flex gap-1 justify-content-center container_images_buttons">
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success py-2 px-3 h6 col-auto ${
                    image.image === orderService ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: orderService,
                    description: descriptions.orderService,
                  })
                  }
                >
                  Ordem de serviço
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-auto ${
                    image.image === requestOrcamento ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: requestOrcamento,
                    description: descriptions.requestOrcamento,
                  })
                  }
                >
                  Solicitações clientes
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-md-auto ${
                    image.image === chaat ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: chaat,
                    description: descriptions.chaat,
                  })
                  }
                >
                  Chat
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-md-auto ${
                    image.image === gestionObraa ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: gestionObraa,
                    description: descriptions.gestionObraa,
                  })
                  }
                >
                  Gestão das obras
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-md-auto ${
                    image.image === markett ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: markett,
                    description: descriptions.markett,
                  })
                  }
                >
                  Marketing
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-md-auto ${
                    image.image === learning ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: learning,
                    description: descriptions.learning,
                  })
                  }
                >
                  E-learning
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0 btn-outline-success px-3 h6 col-md-auto ${
                    image.image === meuSite ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: meuSite,
                    description: descriptions.meuSite,
                  })
                  }
                >
                  Meu site
                </button>
                <button
                  type="button"
                  className={`button_setImage border-0   py-2 px-3 h6 col-md-auto ${
                    image.image === shop ? 'btn-primaryy' : ''
                  }`}
                  onClick={() => setImage({
                    image: shop,
                    description: descriptions.shop,
                  })
                  }
                >
                  BioCasa
                </button>
              </div>
            </div>
          </div>
          <Container4>
            <Background2 image={image} />
          </Container4>
        </ContainerButtons>
        <Container322>
          <div className="text-descricao py-5">{image.description}</div>
        </Container322>
      </div>

      <Container31>
        <br />
        <div className="row">
          <div className="col-md-5 col-12">
            <div className="row py-4 px-5 d-flex align-items-center">
              <div className="col text-escritorio">O seu sucesso importa</div>
              <Title className="">
                <br />
                <br />
                <div className="px-5" />
              </Title>

              <h1 className="fw-light company py-5">
                <b className="fw-medium">Muito mais</b> do que
                <br />
                um software
              </h1>
              <div className="text-plataforma">
                A plataforma mais inteligente e moderna do
                <br />
                mercado, feita sob medida para você.
              </div>
              <br />
              <br />
              <div className="">
                <br />
                <br />
                <button
                  type="button"
                  className="text-white btn rounded-pill px-5 py-3 h5 text-button-2"
                >
                  Obter meu Harvey
                </button>
              </div>
            </div>
          </div>
          <div className="col gap gap-4 gap-lg-4 px-5">
            <div className="row gap gap-3 gap-lg-5 py-3 text-black-50">
              <div className="col-12 col-lg-5 px-4 bg-body redondeo shadow-sm">
                <br />

                <IconContext.Provider value={{ color: '#8675FE' }}>
                  <BsCashStack className="h1 px-1" />
                </IconContext.Provider>
                <br />
                <p className="text-titles">Simulação de investidores</p>
                <div className="text-subtitle">
                  Apresente os melhores números
                  <br />
                  para conseguir um investidor na
                  <br />
                  incorporação imobiliária unifamiliar
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-12 col-lg-5  px-4 bg-body py-2 redondeo shadow-sm">
                <br />
                <IconContext.Provider value={{ color: '#00BCD4' }}>
                  <BsFillBookmarkStarFill className="h1 px-1" />
                </IconContext.Provider>
                <br />
                <p className="text-titles">Marcas parceiras</p>
                <div className="text-subtitle">
                  Encontre as melhores marcas que
                  <br />
                  terão desconto para você e seu
                  <br />
                  cliente
                  <br />
                </div>
                <br />
              </div>
            </div>
            <div className="py-lg-1 py-0" />
            <div className="row gap gap-3 gap-lg-5">
              <div className="col-12 col-lg-5  px-4 bg-body py-2 redondeo shadow-sm ">
                <br />
                <IconContext.Provider value={{ color: '#FFBA69' }}>
                  <BsBuilding className="h1 px-1" />
                </IconContext.Provider>
                <p className="text-titles">
                  Simulação de kitnet
                  <br />
                  (home equity)
                </p>
                <div className="text-subtitle">
                  Tenha os melhores números para
                  <br />
                  apresentar e investir com home
                  <br />
                  equity
                </div>

                <br />
              </div>
              <div className="col-12 col-lg-5  px-4 bg-body py-2 redondeo shadow-sm">
                <br />
                <IconContext.Provider value={{ color: '#01CC9D' }}>
                  <BsPeopleFill className="h1 px-1" />
                </IconContext.Provider>
                <br />
                <p className="text-titles">
                  Orçamento
                  <br />
                  parametrizado 2022
                </p>
                <div className="text-subtitle">
                  Defina o preço de venda das
                  <br /> suas casas antes de orçar
                  <br />
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </Container31>

      <p className="text-start text-sm-center ps-4 ps-sm-0 py-5">
        <br />
        <br />
        E Muito mais...
        <br />
      </p>
      <div className="bg-white pt-0 pt-lg-5">
        <div className="row pt-lg-5">
          <div className="col-12 col-md-6">
            <Container3 className="d-flex justify-content-center">
              <Background3 />
            </Container3>
          </div>
          <div className="col-12 col-md-6">
            <div className="text-success px-5 ">
              <h4 style={{ color: '#079269' }}>Para todos os tamanhos</h4>
            </div>
            <div className="row py-2 px-5 d-flex align-items-center">
              <Title className="col-lg-6 col-xl-4">
                <br />
                <br />
                <div
                  className="px-5"
                  style={{ width: '73px', height: '7px' }}
                />
              </Title>
              <h2 className="fw-medium py-5" style={{ color: '#000000' }}>
                Temos
                <b className="fw-bolder"> a solução</b>
                <br />
                para cada momento
                <br />
                da sua jornada
              </h2>
              <br />
              <br />
              <div className="">
                <button
                  type="button"
                  className="text-white btn px-5 py-3 h5"
                  style={{
                    backgroundColor: '#01CC9D',
                    borderRadius: '26px',
                  }}
                >
                  Obter meu Harvey
                </button>
              </div>
            </div>
          </div>
        </div>
        <ContainerTextInfo className="d-flex justify-content-center py-5">
          <div className="d-flex align-items-centerounded-pill px-5 px-lg-5 shadow bg-body py-2 flex-column flex-md-row container_text_info">
            <div className="text-center px-5 px-lg-5">
              <div className="text-dark text-center">
                <div className="col text-center mt-4 mt-lg-0">
                  <h5 className="text-dark text-obras">+ 530 Mil</h5>
                </div>
                <div className="text-center">
                  <h6 className="text-muted text-obras">Obras finalizadas</h6>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center px-5 mt-3 mt-lg-0">
              <Container2>
                <div className="vl" />
              </Container2>
            </div>
            <div className="px-5">
              <div className="text-dark">
                <div className="text-center">
                  <h5 className="text-dark text-obras">+10M</h5>
                </div>
                <div className="text-center">
                  <h6 className="text-muted text-obras">Projetos</h6>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center px-5 mt-3 mt-lg-0">
              <Container2>
                <div className="vl" />
              </Container2>
            </div>
            <div className="text-center d-lg-flex align-items-center mb-4 mb-lg-0">
              <h6 className="text-muted text-obras">Contracts made</h6>
            </div>
          </div>
        </ContainerTextInfo>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <ContainerFont>
        <div className="row mt-4">
          <div className="col-12 col-md-5 my-5 my-md-0 px-5">
            <img
              src={logoHarvey}
              alt="logo-harvey"
              className="mb-5 d-block px-0 px-md-5"
            />
            <h5 className="text-dark fw-bolder px-0 px-md-5 text-harvey-title">
              Harvey Construtor
            </h5>
            <div
              className="px-0 px-md-5 text-harvey"
              style={{ color: '#949494' }}
            >
              Aqui você encontrará todos os modelos de contratos e
              <br />
              Documentações Exclusivas que serão utilizados no
              <br />
              processos de financiamento habitacional.
            </div>
          </div>
          <div className="col-6 col-md-2 d-flex flex-column">
            <h4 className="text-dark px-5 px-md-0 fw-bolder title">Produtos</h4>

            <h6 className="text-pequeno px-5 px-md-0 py-2">
              Minha Casa Financiada
            </h6>

            <h6 className="text-pequeno px-5 px-md-0">Harvey</h6>

            <h6 className="text-pequeno px-5 px-md-0 py-2">MCF Bank</h6>

            <h6 className="text-pequeno px-5 px-md-0">Leiaut</h6>

            <h6 className="text-pequeno px-5 px-md-0 py-2">MCF Growth</h6>

            <br />
          </div>
          <div className="col-6 col-md-2 d-flex flex-column ">
            <h4 className="text-dark fw-bolder title">Empresa</h4>

            <h6 className="text-pequeno py-2">Sobre Nós</h6>

            <h6 className="text-pequeno">FAQs</h6>

            <h6 className="text-pequeno py-2">Nosso Time</h6>

            <h6 className="text-pequeno">Contate nos</h6>

            <br />
            <br />
          </div>
          <div className="col-12 col-md-2">
            <div className="px-4 py-4">
              <div className="d-flex gap-5 justify-content-center">
                <div className="col-md-auto">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsFacebook
                      className="h3"
                      onClick={() => handleRedirect(
                        'www.facebook.com/profile.php?id=100064105211984'
                      )
                      }
                    />
                  </IconContext.Provider>
                </div>
                <div className="col-md-auto">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsWhatsapp
                      className="h3"
                      onClick={() => handleRedirect(
                        'api.whatsapp.com/send?phone=5521997406173&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20como%20conseguir%20um%20financiamento'
                      )
                      }
                    />
                  </IconContext.Provider>
                </div>
                <div className="col-md-auto">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsInstagram
                      className="h3"
                      onClick={() => handleRedirect(
                        'www.instagram.com/minha.casafinanciada/'
                      )
                      }
                    />
                  </IconContext.Provider>
                </div>
                <div className="col-md-auto">
                  <IconContext.Provider value={{ color: '#39A887' }}>
                    <BsYoutube
                      className="h3"
                      onClick={() => handleRedirect(
                        'www.youtube.com/channel/UCO-ESNs6i32ED8U4IRKlSHQ'
                      )
                      }
                    />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
            <div>
              <h6 className="col text-center" style={{ color: '#949494' }}>
                @Minhacasafinanciada
              </h6>
            </div>
          </div>
        </div>
        <p className="text-copy text-center">
          © 2023 Todos os direitos reservados Minha Casa Financiada
        </p>
      </ContainerFont>
    </>
  );
};

export default Login;
