// Services

// Types
import { setCookie } from '~/utils/cookeis';
import { AuthState } from '../../types';

/**
 * Get and mount the default value
 * @returns A object that represent all data of authentication or a cache from authentication
 */
export function authGetCacheStorage(): AuthState {
  try {
    const authBase: AuthState = {
      api_token: null,
      token: null,
      user: null,
    };

    return authBase;
  } catch (error: any) {
    throw new Error(`Do not possible assing the cache`);
  }
}

export function authSetTokenCacheStorage(authState: AuthState): void {
  try {
    if(authState?.token) {
      // console.log(`CALLED authSetTokenCacheStorage -> ${authState.token}`)
      setCookie('@HarveyBuilder:token', authState.token);    
    }

    localStorage.setItem(
      '@HarveyBuilder:auth',
      JSON.stringify(authState || '{}')
    );
  } catch (error: any) {
    throw new Error(`Do not possible assing the cache`);
  }
}
