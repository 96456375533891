import React from 'react';
import { BsClockFill } from 'react-icons/bs';
import { CompleteData } from './styles';

const AguardandoAnalise: React.FC = () => (
  <CompleteData>
    <div className="col-md-auto d-flex">
      <div className="icon-information">
        <BsClockFill size={40} color="#FEBA6A" />
      </div>
      <div className="col-md-auto text-box">
        <div className="d-flex mt-3">
          <b>Aguardando análise do correspondente</b>
        </div>
        <div className="d-flex mt-3 row box-fill">
          <div className="col-xl-7">
            <p>Aguarde nosso correspondente análisar seus documentos</p>
          </div>
        </div>
      </div>
    </div>
  </CompleteData>
);

export default AguardandoAnalise;
