import { format } from 'date-fns';
import FormDialog from 'kemis-library/components/Form/FormDialog';
import InputDate from 'kemis-library/components/Form/InputDate';
import InputFile from 'kemis-library/components/Form/InputFile';
import InputNumber from 'kemis-library/components/Form/InputNumber';
import InputText from 'kemis-library/components/Form/InputText';
import { Dialog } from 'primereact/dialog';
import { PropsWithChildren, useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

// Utils
import getValidationErros from '~/utils/getValidationsErrors';

// Components
import { Button } from '~/components/Button';
import Loading from '~/components/Loading';

// Services
import api from '~/services/api';

// Styles
import { Modal } from './styles';

// Interfaces
interface IAddPointProps extends PropsWithChildren<any> {
  show?: boolean;
  onHide?: () => void;
  callback?: (...params: any[]) => void;
  tournament_id: number;
}

// Constants
const SCHEMA_FORM = Yup.object({
  product: Yup.string().required('Informe o nome do produto'),
  value: Yup.string().required('Informe o valor do produto'),
});

export function AddPoint({
  show = false,
  onHide = () => ({}),
  callback = (...params) => params,
  tournament_id,
}: IAddPointProps): JSX.Element {
  const [showLoading, setShowLoading] = useState(false);
  const form = useForm();

  const handleGetFormData = useCallback(
    (data: FieldValues) => {
      const formData = new FormData();

      if (
        form.getValues('voucher')
        && (form.getValues('voucher') as unknown as File[])?.length > 0
      ) {
        formData.append(
          'voucher',
          (form.getValues('voucher') as unknown as File[])[0]
        );
      }

      if (data.date) {
        formData.append(
          'date',
          format(new Date(data.date), 'yyyy-MM-dd') || ''
        );
      }

      formData.append('product', data.product || '');
      formData.append('tournament_id', `${tournament_id}`);
      formData.append('value', data.value || '');
      return formData;
    },
    [form, tournament_id]
  );

  const handleOnSubmit = useCallback(async (data: FieldValues) => {
    try {
      setShowLoading(true);
      form.clearErrors();

      // SETUP THE FORM
      const formData = handleGetFormData(data);

      await SCHEMA_FORM.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('builders/purchased-products', formData, {
        headers: {
          'Content-Type': 'application/multipart-form',
        },
      });

      if (response.status !== 200) {
        throw new Error('Não foi possivel concluir o cadastro dos pontos');
      }

      if (callback) callback(response.data);

      setShowLoading(false);

      Swal.fire({
        icon: 'success',
        title: `Produto ${data?.product || ''} cadastrado com sucesso`,
      });

      if (onHide) onHide();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);

        Object.keys(errors).forEach((key) => {
          form.setError(key, {
            message: errors[key],
          });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro, tente novamente',
        });
      }

      setShowLoading(false);
    }
    // FIXME - eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!tournament_id
        || (tournament_id <= -1 && (
          <Dialog visible={show} onHide={onHide} header="Cadastrar produto">
            Nenhuma competição disponível
          </Dialog>
        ))}

      <FormDialog
        form={form}
        getFormData={handleGetFormData}
        onSubmit={(data: any) => handleOnSubmit(data)}
        url="builders/purchased-products"
        api={api}
        visible={show}
        header="Cadastrar Produtos"
        onHide={onHide}
        classNameDialog="w-5/6 lg:w-2/6"
        hiddenSubmitButton
      >
        <div className="row">
          <div className="col-lg-12 mb-3 flex flex-col">
            <input
              type="hidden"
              className="d-none"
              defaultValue={tournament_id}
              {...form.register('tournament_id')}
            />
            <div className="flex flex-col">
              <InputText
                className="form-input mb-4"
                name="product"
                label="Produto"
                form={form}
              />
            </div>

            <div className="flex flex-col">
              <InputNumber
                className="mb-4"
                name="value"
                form={form}
                label="Valor"
                rules={{ required: 'Preencha o campo' }}
              />
            </div>
            <div className="flex flex-col">
              <InputDate
                className="form-input mb-4"
                name="date"
                form={form}
                label="Data"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-[500] text-[#707070] text-[0.875rem] mb-2">
                Comprovante de pagamento
              </label>
              <InputFile
                name="voucher"
                accept={{
                  'image/*': ['.png', '.jpg', '.jpeg'],
                }}
                maxFiles={1}
                form={form}
              />
            </div>
          </div>
        </div>
        <Modal.Footer className="border-none justify-content-center">
          <div className="buttons-group">
            <div>
              <Button type="submit" className="btn-submit py-3">
                Cadastrar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </FormDialog>
      <Loading active={showLoading} />
    </>
  );
}
