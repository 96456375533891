import React, { useCallback, useState } from 'react';

import { IMFileType } from '~/models/FileTypes';
import { useServiceOrder } from '~/hooks/ServiceOrder';

import { IMServiceOrderFile } from '~/models/ServiceOrderFile';

import { Container, SendAnalysis } from './styles';
import CustomersDocuments from './Partials/CustomersDocuments';
import ProcessOpening from './Partials/ProcessOpening';
import ExtraDocuments from './Partials/ExtraDocuments';
import ModalConclude from './Partials/ModalConclude';
import SellersDocuments from './Partials/SellersDocuments';

export interface IFiles extends IMFileType {
  files?: IMServiceOrderFile[];
}

const WorkDocuments: React.FC = () => {
  const { serviceOrder } = useServiceOrder();
  const [showConclude, setShowConclude] = useState(false);

  const handleOpenConclude = useCallback(() => {
    setShowConclude(true);
  }, []);

  return (
    <Container>
      <CustomersDocuments />
      {serviceOrder.status_id >= 9 && (
        <>
          {serviceOrder.finalidade_id === 1 && <SellersDocuments />}
          <ProcessOpening />
        </>
      )}
      {serviceOrder.status_id > 6 && <ExtraDocuments />}

      <ModalConclude
        show={showConclude}
        onHide={() => setShowConclude(false)}
      />

      <SendAnalysis>
        <button
          type="button"
          className="sendAnalisys"
          onClick={() => handleOpenConclude()}
        >
          Enviar para análise
        </button>
      </SendAnalysis>
    </Container>
  );
};

export default WorkDocuments;
