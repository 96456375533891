import { InputNumber } from "primereact/inputnumber";
import { useContext, useEffect, useMemo, useState } from "react";

// Services
import api from "~/services/api";
import { AIGPT } from "~/services/openAI";

// Types
import { IChatCompletions, IPrompt, ISavedPrompt } from "~/services/openAI/types";
import { IStepData } from '../../types';
import { IDataProps } from './types';

// Models
import { IMRealStateProduct } from '~/models/RealEstateProduct';

// Partials
import { AuthContext } from "~/hooks/contexts/Auth/context";
import { MessageError } from "~/pages/RealEstateProduct/Partials/MessageError";

export function Data({
  onDataStream,
  dataStep,
  projectData,
  rep_id
}: IDataProps) {

  const { user } = useContext(AuthContext)
  const [hasAIResponse, setHasAIResponse] = useState(false)
  
  // AUX Variables  
  const USER_PROMPT = (user?.name || `${new Date().getTime()}`).split(' ').join('-').toLocaleLowerCase()
  const PROMPT_TARGET = (projectData?.data.localization || '').split('-')
  const PROMPT: IPrompt = {
    content: `Me informe com identificação por tags  a renda por família e a idade média da região de ${PROMPT_TARGET[2]} em ${PROMPT_TARGET[3]}, informe a renda em R$ brasileiro`,
    name: USER_PROMPT,
    role: "user"
  };
  const PROMPTS: Array<IPrompt> = [
    {
      content: `Faça uma simulação ou utilize fontes reais, e retorne a renda media da região do seguinte endereço: ${projectData?.data.localization}. Retorne apenas a renda media dentro de uma tag nomeada rm, por exemplo <rm>R$ 1.000,00</rm>, caso não tenha as informações, responda com valor <rm>R$ 00,00</rm>`,
      name: USER_PROMPT,
      role: "user"
    },
    {
      content: `Faça uma simulação ou utilize fontes reais, e retorne a faixa etária media da região do seguinte endereço: ${projectData?.data.localization}. Retorne apenas a renda media dentro de uma tag nomeada fe, por exemplo <fe>36</fe>, caso não tenha as informações, responda com valor <fe>00</fe>`,
      name: USER_PROMPT,
      role: "user"
    },
    {
      content: `Faça uma estimativa com base nos valores das perguntas anteriores ou caso seja possivel utilize fontes reais, e retorne o valor de venda (VGV) dos imóveis da região do seguinte endereço: ${projectData?.data.localization}. Retorne apenas o valor medio de venda dentro de uma tag nomeada vgv, por exemplo <vgv>R$ 36.000,00</vgv>, caso não tenha as informações, responda com o valor <vgv>R$ 00,00</vgv>! E não deixe de retornar apenas a tag dessa pergunta, mas também as tags das perguntas anteriores`,
      name: USER_PROMPT,
      role: "user"
    }
  ];
  const ORIGIN_RESIDENTS = projectData?.data.localization || (dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.origin_residents || ''
  const OPEN_AI_GPT = useMemo(() => new AIGPT(user,null, PROMPTS, '4','gpt-3.5-turbo'),[])

  async function onChatStream(messages: IPrompt[] = []){
    try {
      if(messages.length == 0){
        throw new Error ('you must provide');
      }

      await OPEN_AI_GPT.onChatStream(null,null,setHasAIResponse,getChatStreamData)
    } catch (error) {
      console.table(error);
    }
  }  

  /**
   * Get and set localy all data output from completions
   * @param completions The completions
   * @returns 
   */
  function getChatStreamData(completions: IChatCompletions | null) {
    try {
      if(!completions) throw new Error('The completions is not valid');

      const COMPLETIONS_DATA_RAW = completions.choices[0].message.content?.split('\n') || []
      const AVERAGE_INCOME = Number(COMPLETIONS_DATA_RAW[0].replace(/[^0-9.,]/g, '').slice(0,-3).replace('.',''))
      const AVERAGE_AGE = Number(COMPLETIONS_DATA_RAW[1].replace(/[^0-9.]/g, ''))      
      
      // console.table({
      //   COMPLETIONS_DATA_RAW,
      //   AVERAGE_INCOME,
      //   AVERAGE_AGE,
      //   ORIGIN_RESIDENTS,
      //   COMPLETIONS: completions.choices[0].message.content
      // })

      if(!onDataStream || typeof onDataStream !== 'function') return
      
      if((AVERAGE_AGE <=0 || !AVERAGE_AGE) || (AVERAGE_INCOME <=0 || !AVERAGE_INCOME)) return

      onDataStream(
  {
          average_income_value: AVERAGE_INCOME,
          average_age_residents: AVERAGE_AGE,
          origin_residents: ORIGIN_RESIDENTS
        }, 
        '4'
      );
      
    } catch (error) {
      // do anything
    }
  }

  /**
   * Get and set localy all data output from external saved completions
   * @description This function will do same thing that the function above, but the strucuture of data is not same
   * @param completions The saved completions
   * @returns
   */
  function getChatStreamDataByExternal(completions: ISavedPrompt | null) {
    try {
      if(!completions) throw new Error('The completions is not valid');

      const COMPLETIONS_DATA_RAW = completions.response?.split('\n') || []
      const AVERAGE_INCOME = Number(COMPLETIONS_DATA_RAW[0].replace(/[^0-9.,]/g, '').slice(0,-3).replace('.',''))
      const AVERAGE_AGE = Number(COMPLETIONS_DATA_RAW[1].replace(/[^0-9.]/g, ''))      
      
      // console.table({
      //   COMPLETIONS_DATA_RAW,
      //   AVERAGE_INCOME,
      //   AVERAGE_AGE,
      //   ORIGIN_RESIDENTS,
      //   COMPLETIONS: completions.choices[0].message.content
      // })

      if(!onDataStream || typeof onDataStream !== 'function') return
      
      if((AVERAGE_AGE <=0 || !AVERAGE_AGE) || (AVERAGE_INCOME <=0 || !AVERAGE_INCOME)) return

      onDataStream(
  {
          average_income_value: AVERAGE_INCOME,
          average_age_residents: AVERAGE_AGE,
          origin_residents: ORIGIN_RESIDENTS
        }, 
        '4'
      );
      
    } catch (error) {
      // do anything
    }
  }

  /**
   * Get all already completion from this "page", otherwise will be called the AIGPT Chat Stream
   * @returns 
   */
  async function getChatDataFromDatabase(){
    try {
      const response = await api.get<ISavedPrompt[]>(`builders/real-estate-products/${rep_id}/prompts`)

      if(response.status !== 200 || response.data.length <= 0) {
        onChatStream(PROMPTS)
        return
      }

      const lasResponseFromAI = response.data.slice(-1)[0]

      if(lasResponseFromAI) setHasAIResponse(true);

      // TODO - ACTUALLY THE MODEL WILL RESPONSE WITH A UNIQUE MESSAGE FOR ALL PROMPTS, ONLY ONE PROMPT WILL BE NECESSARY FOR GET THE RESPONSE
      getChatStreamDataByExternal(response.data.slice(-1)[0])
    } catch (error) {
      onChatStream(PROMPTS)
    }
  }

  useEffect(() =>{

    if(!projectData?.data.localization ) return
    
    getChatDataFromDatabase()
  }, []);
  
  return (
    <div className="flex flex-col items-start justify-start gap-[1.875rem] w-full h-full max-sm:p-[1rem]">
      <p className="text-[#707070] font-[400] max-sm:w-full max-sm:text-center">
        Preencha as seguintes informações
      </p>

      <div className="w-full h-auto flex flex-col gap-[2.5rem] bg-white rounded-[0.937rem] p-[1.875rem] shadow-sm overflow-hidden">
        <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex-1 md:max-w-[50%] max-sm:w-full flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem] flex flex-row items-center gap-2">
              Qual valor de renda médio da região?
              <span data-hasairesponse={hasAIResponse} className="hidden data-[hasairesponse=true]:flex font-bold text-xs text-[#4acea6] select-none cursor-default">Gerado por AI</span>
            </span>
            <InputNumber
              inputClassName="outline-none border-none pl-[1.145rem] !pt-[0] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="R$ 25.000,00"
              currency="BRL"
              mode="currency"
              locale="pt-BR"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={({ value }) => onDataStream!({
                average_income_value: Number(value || undefined)
              }, '4')}
              // @ts-ignore
              value={((dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.average_income_value) || undefined}
              inputMode="numeric"
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='average_income_value' />
          </div>
          <div className="flex-1 md:max-w-[50%] max-sm:w-full flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem] flex flex-row items-center gap-2">
              Qual idade media dos moradores da região?
              <span data-hasairesponse={hasAIResponse} className="hidden data-[hasairesponse=true]:flex font-bold text-xs text-[#4acea6] select-none cursor-default">Gerado por AI</span>
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none border-b-[0.081rem] border-b-[#B8B9BA] pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="40 anos"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              maxLength={3}
              onChange={(event) => onDataStream!({
                average_age_residents: Number(event.target.value || '')
              }, '4')}
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.average_age_residents || ''}
              inputMode="numeric"
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='average_age_residents' />
          </div>
        </div>
        <div className="flex max-sm:flex-col md:flex-row md:flex-nowrap gap-[2.187rem]">
          <div className="flex-1 md:max-w-[48%] max-sm:w-full flex flex-col gap-[0.812rem]">
            <span className="text-[#707070] font-semibold text-[0.875rem]">
              Qual é a região dos moradores?
            </span>
            <input
              type="text"
              name=""
              id=""
              className="outline-none border-none pl-[1.145rem] pr-[1.292rem] pb-[0.687rem] leading-[1.375rem] text-[1.062rem] placeholder:text-[#e8e9eb] text-[#414142]"
              placeholder="Cidade ou Estado"
              style={{
                borderBottom: '0.081rem solid #B8B9BA',
              }}
              onChange={(event) => onDataStream!({
                origin_residents: event.target.value || ''
              }, '4')}
              value={(dataStep as unknown as IStepData<IMRealStateProduct>)?.data?.origin_residents || ''}
              inputMode="text"
            />
            <MessageError dataStep={dataStep as unknown as IStepData<IMRealStateProduct>} nameField='origin_residents' />
          </div>
        </div>
      </div>
    </div>
  );
}
