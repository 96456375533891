import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { CgInfo } from 'react-icons/cg';

import api from '~/services/api';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';
import { Modal } from './styles';

interface IModalShowDocumentsStatus {
  show: boolean;
  onHide(): void;
}

interface IResponseSendFiles {
  status: 'success' | 'fail';
  message: string;
  os: IMServiceOrder;
}

const ModalConclude: React.FC<IModalShowDocumentsStatus> = ({
  show,
  onHide,
}) => {
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [loading, setLoading] = useState(false);

  const sentToAnalisys = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .patch<IResponseSendFiles>(
          `builders/service-orders/${serviceOrder.id}/files/conclude`
        )
        .then(async (resp) => {
          if (resp.data.status === 'success') {
            await Swal.fire(
              'Sucesso!',
              'Todos os documentos foram aprovados!',
              'success'
            );
            setLoading(false);
            setServiceOrder(resp.data.os);
            onHide();
          } else {
            await Swal.fire(
              'Opss...',
              'Ocorreu um erro ao enviar os documentos!',
              'error'
            );
            setLoading(false);
          }
        });
    } catch (err) {
      setLoading(false);
      await Swal.fire(
        'Opss...',
        'Ocorreu um erro ao carregar os documentos!',
        'error'
      );
    }
  }, [serviceOrder.id, setLoading, setServiceOrder, onHide]);

  return (
    <>
      <Modal show={show} onHide={onHide} close size="md">
        <Form onSubmit={sentToAnalisys}>
          <Modal.Header className="border-0 pt-3" closeButton />
          <Modal.Body className="border-0 mx-5">
            <div className="w-100 d-flex justify-content-center py-5">
              <CgInfo className="icon" />
            </div>
            <div className="mb-3 text-center">
              <p className="h4">Confirma o envio para análise?</p>
              <p className="mb-0">
                Após o envio, seus arquivoc serão analisados pelo correspondente
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 box-footer">
            <div className="buttons-group">
              <button
                type="button"
                className="btn-cancel me-2"
                onClick={onHide}
              >
                Não
              </button>
              <button type="submit" className="btn-cancel me-2">
                Enviar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading active={loading} />
    </>
  );
};

export default ModalConclude;
