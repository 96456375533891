import React from 'react';

import { Container } from './styles';

const CreditoAprovado: React.FC = () => (
  <Container className="container col-md-11 col-11 border-3 card-dashboard justify-content-center p-2 mb-5">
    <div className="m-4">
      <div className="d-flex  align-items-center mb-4">
        <div className="col-7 d-flex align-items-center">
          <h3 className="mb-0 font-weight-700 text-black">
            Seu Crédito foi reprovado
          </h3>
        </div>
      </div>
    </div>
  </Container>
);

export default CreditoAprovado;
