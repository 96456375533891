import React from 'react';

import logo from '~/assets/logos/logo.svg';
import { INotification } from '~/models/Notification';

import { Notification, Avatar } from './styles';

const CardNofitication: React.FC<INotification> = ({
  id,
  date,
  content,
  url,
}) => {
  return (
    <Notification key={id} className="px-4 py-2 my-3">
      <div className="row py-1">
        <div className="col-12 d-flex justify-content-end">
          <small className="">{date}</small>
        </div>

        <div className="col-12 d-flex px-0">
          <div className="col-3 d-flex justify-content-center">
            <Avatar>
              <img src={logo} alt="logo" />
            </Avatar>
          </div>

          <div className="col-9 px-2">
            <div className="col-12">
              <p className="text-gray small py-2 mt-0 mb-3">{content}</p>
            </div>

            <div className="col-12 d-flex justify-content-center">
              <a
                href={url}
                target="_blank"
                className="btn btn-primary px-6 py-1 mr-auto w-100"
                rel="noreferrer"
              >
                Ir
              </a>
            </div>
          </div>
        </div>
      </div>
    </Notification>
  );
};

export default CardNofitication;
