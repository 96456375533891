export function Mock(): JSX.Element {
  return (
    <div className="max-sm:w-[9.687rem] max-sm:h-[9rem] md:w-[20.687rem] md:h-[20.062rem] max-sm:rounded-[0.937rem] md:rounded-[0.9375rem] flex flex-col items-start justify-start bg-white overflow-hidden shadow-sm hover:shadow-gray-700 transition-all duration-[0.3s] cursor-pointer">
      <div className="overflow-hidden bg-gray-100 w-full flex-1 max-h-[11.25rem] min-h-[11.25rem] animate-pulse" />
      <div className="flex-1 w-full flex flex-col items-start justify-start gap-[0.875rem] pt-[0.875rem] pl-[1rem] pr-[1rem] pb-[0.875rem] box-border bg-white">
        <span className="w-1/2 h-[1.5rem] font-semibold text-[1.125rem] text-[#707070] bg-gray-100 rounded-sm animate-pulse" />

        <ul className="flex flex-col items-start w-full flex-1 gap-[0.7rem]">
          <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
            <span className="w-[1rem] h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
            <span className="flex-1 h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
          </span>
          <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
            <span className="w-[1rem] h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
            <span className="flex-1 h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
          </span>
          <span className="w-full flex flex-row items-center gap-1 text-[0.75rem] text-[#B8B9BA] font-normal leading-[0.825rem] gap-[0.25rem]">
            <span className="w-[1rem] h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
            <span className="flex-1 h-[1rem] rounded-sm bg-gray-100 animate-pulse" />
          </span>
        </ul>
      </div>
    </div>
  );
}
