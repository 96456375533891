import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import {
  FC, useCallback, useEffect, useRef, useState
} from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import * as Yup from 'yup';

import Loading from '~/components/Loading';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import api from '~/services/api';
import getValidationErrors from '~/utils/getValidationsErrors';
import { Container, Step } from './styles';

interface IState {
  id: number;
  nome: string;
}

interface IMunicipality {
  id: number;
  name: string;
}

interface IProps {
  setStep: (step: number) => void;
  step: number;
}

const Location: FC<IProps> = ({ setStep, step }) => {
  const formRef = useRef<FormHandles>(null);
  const { serviceOrder, setServiceOrder } = useServiceOrder();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState<SelectItemOptionsType>([]);
  const [state, setState] = useState<number | undefined>(undefined);
  const [municipalities, setMunicipalities] = useState<SelectItemOptionsType>();
  const [municipality, setMunicipality] = useState<string | undefined>(
    undefined
  );
  const [municipalityError, setMunicipalityError] = useState('');
  const [stateError, setStateError] = useState('');

  useEffect(() => {
    if (serviceOrder.terreno?.estado_id) {
      setState(serviceOrder.terreno?.estado_id);
    }
  }, [serviceOrder.terreno]);

  const handleLoadState = useCallback(
    (value?: string) => {
      setLoading(true);
      api
        .get<IState[]>('builders/states', {
          params: {
            search: value ?? serviceOrder.terreno?.estado?.nome,
          },
        })
        .then((response) => {
          const data: SelectItemOptionsType = response.data.map(
            (stateData) => ({
              value: stateData.id,
              label: stateData.nome,
            })
          );
          setStates(data);
        });
      setLoading(false);
    },
    [serviceOrder.terreno]
  );

  useEffect(() => {
    handleLoadState();
  }, [handleLoadState]);

  useEffect(() => {
    if (state) {
      api
        .get<IMunicipality[]>(`municipalities/state/${state}`)
        .then((response) => {
          const data: SelectItemOptionsType = response.data.map(
            (municipalityData) => ({
              value: municipalityData.name,
              label: municipalityData.name,
            })
          );
          setMunicipalities(data);
          if (serviceOrder.terreno?.municipio) {
            const city = data.find(
              (municipalityData) => municipalityData.value === serviceOrder.terreno?.municipio
            );
            setMunicipality(city.value);
          }
        });
    }
  }, [state, serviceOrder.terreno?.municipio]);

  const handleSubmit = useCallback(async () => {
    try {
      if (!state || !municipality) {
        !state ? setStateError('O estado é obrigatório') : setStateError('');
        !municipality
          ? setMunicipalityError('O município é obrigatório')
          : setMunicipalityError('');
      } else {
        setStateError('');
        setMunicipalityError('');

        setServiceOrder({
          ...serviceOrder,
          terreno: {
            ...serviceOrder.terreno,
            estado_id: state,
            municipio: municipality,
          },
        });
        setStep(3);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }
  }, [
    serviceOrder,
    setServiceOrder,
    setStep,
    state,
    municipality,
    setStateError,
    setMunicipalityError,
  ]);

  const handleClickPrevious = useCallback(
    (target_step) => {
      setStep(target_step);
    },
    [setStep]
  );

  return (
    <Container>
      <Step active={step === 2}>
        <Form ref={formRef} onSubmit={handleSubmit} className="col-12">
          <div className="row px-3">
            <div className="col-12 mb-5 px-3">
              <label className="w-100">
                Finalidade:
                <b className="text-primary">*</b>
                {/* Attention!!!, do not enable the select, only H4 appears */}
                <h4 className="p-2">{serviceOrder.finalidade?.finalidade}</h4>
              </label>
            </div>
            <div className="col-lg-6 mb-5 gap-2">
              <label className="w-100">
                Estado: <b className="text-primary">*</b>
              </label>
              <Dropdown
                value={state}
                onChange={(e) => setState(e.value)}
                options={states}
                showClear
                placeholder="Escolha um estado"
                className="w-100"
              />
              {stateError && (
                <span className="small text-danger error">{stateError}</span>
              )}
            </div>
            <div className="col-lg-6 mb-5">
              <label className="w-100">
                Município: <b className="text-primary">*</b>
              </label>
              <Dropdown
                value={municipality}
                onChange={(e) => setMunicipality(e.value)}
                options={municipalities}
                showClear
                placeholder="Escolha um municipio"
                className="w-100"
                disabled={!state}
              />
              {municipalityError && (
                <span className="small text-danger error">
                  {municipalityError}
                </span>
              )}
            </div>
          </div>
          <div className="button-group d-flex justify-content-end mt-5 mb-5 px-3">
            <button
              type="button"
              className="btn btn-primary btn-back d-flex align-items-center justify-content-center me-2 px-3"
              onClick={() => handleClickPrevious(step - 1)}
            >
              <IoIosArrowBack size={24} color="#fff" />
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-next fw-semibold"
            >
              Próximo
            </button>
          </div>
        </Form>
      </Step>
      <Loading active={loading} />
    </Container>
  );
};

export default Location;
