import React, { useCallback, useState } from 'react';
import { HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { IoIosArrowUp } from 'react-icons/io';

import { Link, useParams } from 'react-router-dom';
import { useServiceOrder } from '~/hooks/ServiceOrder';
import {
  BackgroundOpacity,
  ButtonOpen,
  Container,
  Nav
} from './styles';

interface IParams {
  os_id: string;
  // TODO - ON THE PAGE OF "ACABAMENTOS" IN RUNTIME THE PARAM THAT HAVE THE ID (Identification) OF SERVICE ORDER, HAVE THE CALLSIGN OF osID
  // BUT ON THIS PAGE THE PARA IS CALLED OF os_id
  osId: string
}

interface ITopMenu {
  dashboardView: boolean;
  establishedParameters: boolean;
}

const TopMenu: React.FC<ITopMenu> = ({
  dashboardView,
  establishedParameters,
}) => {
  const params = useParams<IParams>();
  const {serviceOrder} = useServiceOrder()
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  // AUX Variables
  const OS_ID = params?.os_id || params?.osId || serviceOrder?.id || -1

  console.log('PARAMS OS FINISH')
  console.table(params)

  return (
    <Container show={open}>
      <BackgroundOpacity show={open} onClick={handleClose} />
      <div id="content" className="content">
        <button
          type="button"
          className="open-menu-text"
          onClick={!open ? handleOpen : handleClose}
        >
          <p
            className="mb-2"
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontSize: '16px',
              lineHeight: '19px',
              display: 'flex',
              alignItems: 'center',

              /* Primary */

              color: '#39A887',
            }}
          >
            {!open ? 'Abrir Menu' : 'Fechar Menu'}
          </p>
          <ButtonOpen
            className="d-flex flex-column flex-lg-row justify-content-center align-items-center btn-show mx-auto"
            show={!open}
          >
            <IoIosArrowUp
              size={30}
              color="#D0D0D0"
              className="d-none d-lg-block"
            />
            <div className="d-flex flex-column justify-content-center align-items-center d-lg-none">
              <span />
              <span />
              <span />
            </div>
          </ButtonOpen>
        </button>
        <div>
          <div className="d-flex flex-column justify-content-between pt-2 pb-0 py-lg-4 px-4">
            <div className="menu">
              <div>
                <Nav show={open} className="mx-md-5 px-md-5">
                  {open === true && (
                    <div className="navbar d-flex flex-column mt-5 pt-3 align-items-center align-items-lg-start justify-content-center justify-content-lg-between p-lg-0">
                      <div className="d-none d-lg-block mb-5 me-n5 pt--5" />
                      <h2>Selecione para onde quer ir 😉</h2>
                      <br />
                      <br />
                      <div className="my-2">
                        {/* <a href="/" className="link d-flex align-items-center">
                          <div className="unlock d-flex align-items-center">
                            <HiLockOpen className="m-2" />
                          </div>
                          <span className="ms-5">Selecionar Modalidade</span>
                        </a>
                        <div>
                          <hr className="separator my-0" />
                        </div>
                        <Link
                          to={`${process.env.PUBLIC_URL}/simulador/resultado`}
                          className="link d-flex align-items-center"
                        >
                          <div className="unlock d-flex align-items-center">
                            <HiLockOpen className="m-2" />
                          </div>
                          <span className="ms-5">Banco (Simulação)</span>
                        </Link> */}
                        <div>
                          <hr className="separator my-0" />
                        </div>
                        <Link
                          to={`${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/acabamento`}
                          className="link d-flex align-items-center"
                        >
                          <div className="unlock d-flex align-items-center">
                            <HiLockOpen className="m-2" />
                          </div>
                          <span className="ms-5">
                            Monte sua casa - Acabamento
                          </span>
                        </Link>
                        <div>
                          <hr className="separator my-0" />
                        </div>
                        {establishedParameters ? (
                          <Link
                            to={`${process.env.PUBLIC_URL}/monte-sua-casa/${OS_ID}/sobre-a-casa/quarto/quantidade`}
                            className="link d-flex align-items-center"
                          >
                            <div className="unlock d-flex align-items-center">
                              <HiLockOpen className="m-2" />
                            </div>
                            <span className="ms-5">
                              Monte sua casa - Sobre a casa
                            </span>
                          </Link>
                        ) : (
                          <div className="link d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="lock d-flex align-items-center">
                                <HiLockClosed className="m-2" />
                              </div>
                            </div>
                            <span className="ms-5">
                              Monte sua casa - Sobre a casa
                            </span>
                          </div>
                        )}

                        {dashboardView && (
                          <>
                            <div>
                              <hr className="separator my-0" />
                            </div>
                            <Link
                              to={`${process.env.PUBLIC_URL}/dashboard/${OS_ID}`}
                              className="link d-flex align-items-center"
                            >
                              <div className="unlock d-flex align-items-center">
                                <HiLockOpen className="m-2" />
                              </div>
                              <span className="ms-5">Dashboard da OS</span>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TopMenu;
