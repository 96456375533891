import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { RiArrowLeftSLine } from 'react-icons/ri';
import Swal from 'sweetalert2';
import api from '~/services/api';
import { disconnectSocket } from '~/services/socket';

import MessagesArea from '~/components/MessagesArea';
import Loading from '~/components/Loading';
import UserDefault from '~/components/UserDefault';
import { useProfile } from '~/hooks/Profile';

import {
  Container,
  UserGroup,
  GeneralContainer,
  ChatLink,
  Notifications,
  MessagesGroup,
} from './styles';

interface IChatResponse {
  id: number;
  os_id: number;
  name: string;
  message: string;
}

interface IChat {
  id: number;
  os_id: number;
  name: string;
  message: string;
}

interface IChatData {
  data: IChatResponse[];
  last_page: number;
}

interface IParams {
  type: 'clientes' | 'correspondentes';
  chat_id?: string;
}

const Chat: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { validateEngineerData } = useProfile();
  const [totalNotifications] = useState(0);
  const [notifications] = useState(0);
  const [chats, setChats] = useState<IChat[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState<'clientes' | 'correspondentes'>('clientes');

  useEffect(() => {
    setType(params.type);
  }, [params.type]);

  const validateUserRegister = useCallback(async () => {
    !validateEngineerData && history.push('/perfil/dados-pessoais');
  }, [history, validateEngineerData]);

  useEffect(() => {
    validateUserRegister();
  }, [validateUserRegister]);

  const loadChats = useCallback(
    async (pageData: number) => {
      const response = await api.get<IChatData>('builders/chats/all', {
        params: {
          page: pageData,
          type: type === 'clientes' ? 'client' : 'correspondent',
        },
      });

      const data: IChat[] = response.data.data.map((chat) => ({
        id: chat.id,
        os_id: chat.os_id,
        name: chat.name,
        message: chat.message,
      }));

      if (pageData === 1) {
        setChats(data);
      } else {
        setChats((state) => [...state, ...data]);
      }
      setLastPage(response.data.last_page);
    },
    [type]
  );

  useEffect(() => {
    loadChats(1).finally(() => {
      setLoading(false);
    });
  }, [loadChats]);

  const handleLoad = useCallback(async () => {
    try {
      loadChats(1);
      if (page < lastPage) {
        loadChats(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire('Opss...', 'Ocorreu um erro ao carregar', 'error');
    }
  }, [lastPage, loadChats, page]);

  const handleSelectChat = useCallback(
    (chat_id) => {
      try {
        disconnectSocket();
      } catch (error) {
        Swal.fire('Opss...', 'Ocorreu um erro ao carregar', 'error');
      }
      history.push(`${process.env.PUBLIC_URL}/chat/${type}/${chat_id}`);
    },
    [history, type]
  );

  const handleBack = (): void => {
    history.goBack();
  };
  return (
    <>
      <GeneralContainer className="ms-lg-5">
        <div className="py-4" />
        <div className="mt-5 mt-lg-0">
          <div className="container mt-5 mt-lg-0">
            <UserDefault chat />
          </div>
          <Container className="w-100 px-lg-3">
            <div className="container">
              <div className="container mx-auto position-relative">
                <div className="row justify-content-between h-100">
                  <UserGroup className="col-lg-3">
                    <div className="mb-5">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center me-2">
                          <button
                            type="button"
                            className="border-0 bg-transparent d-flex align-items-center justify-content-center me-4 px-4"
                            onClick={handleBack}
                          >
                            <RiArrowLeftSLine
                              className="back"
                              size={28}
                              color="#B8B9BA"
                            />
                          </button>
                        </div>
                        <h2
                          className={`${
                            totalNotifications > 0 ? 'block' : 'hidden'
                          } total-notifications px-3 py-1 ms-2 my-auto`}
                        >
                          {totalNotifications}
                        </h2>
                      </div>
                      <div className="d-flex link-groups mt-3">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/chat/clientes`}
                          className="px-3 me-3"
                        >
                          Clientes
                        </NavLink>
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/chat/correspondentes`}
                          className="px-3"
                        >
                          Correspondentes
                        </NavLink>
                      </div>
                    </div>

                    <Notifications
                      scrollLoadThreshold={100}
                      onInfiniteLoad={handleLoad}
                    >
                      <div className="overflow-y-auto">
                        {chats.map((chat) => (
                          <ChatLink
                            key={chat.id}
                            type="button"
                            className="d-flex justify-content-between cursor-pointer notifications-hover px-2 py-4 rounded w-100 border-0 text-start"
                            onClick={() => handleSelectChat(chat.id)}
                          >
                            <div>
                              <h2 className="h5 fw-medium mb-2">{chat.name}</h2>
                              <h3 className="h6 fw-medium mb-2">
                                OS: {chat.os_id.toString().padStart(4, '0')}
                              </h3>
                              <p className="small mb-0">{chat.message}</p>
                            </div>

                            <span
                              className={`${
                                notifications > 0 ? 'd-block' : 'd-none'
                              } notifications mx-5 my-auto px-2 py-1 rounded-circle`}
                            >
                              {notifications}
                            </span>
                          </ChatLink>
                        ))}
                      </div>
                    </Notifications>
                  </UserGroup>
                  <MessagesGroup
                    active={!loading && !!params.chat_id}
                    className="col-lg-9 pt-lg-5"
                    style={{
                      width: '80vh',
                      marginTop: '12%',
                    }}
                  >
                    <MessagesArea setLoading={setLoading} />
                  </MessagesGroup>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </GeneralContainer>

      <Loading active={loading} />
    </>
  );
};

export default Chat;
