/* FIXEM - eslint-disable @typescript-eslint/ban-ts-comment */
/* FIXEM - eslint-disable @typescript-eslint/no-explicit-any */
import useSWR, { SWRConfiguration } from 'swr';

// Services
import api from '~/services/api';

// Interfaces
interface IParams<TData> {
  url?: string;
  params?: any;
  forward?: {
    [key: string]: (data: TData) => any;
  };
  config?: SWRConfiguration;
}

interface IReturn<TDataReturn> {
  data: TDataReturn | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  url: string | undefined;
}

export default function useFetch<TData>({
  url,
  params,
  config,
  forward,
}: IParams<TData>): IReturn<TData> {
  const { data, error, isLoading, isValidating } = useSWR<TData>(
    url,
    async (path: any) => {
      const response = await api.get(path, { params: { ...params } });
      const datable = response.data;

      if (forward && datable) {
        Object.keys(datable).forEach((key) => {
          if (!forward[key] && typeof forward[key] !== 'function') return;

          // @ts-ignore
          if (!datable[key]) return;

          // if(typeof data[key] !== typeof newKeyValue) return

          // @ts-ignore
          datable[key] = forward[key](datable);
        });
      }

      return datable;
    },
    config
  );

  return { data, error, isLoading, isValidating, url };
}
