import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { BsPlus } from 'react-icons/bs';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import Select, { IOption } from '~/components/Select';
import { IDayOfMonth } from '~/models/Home/DayOfMonth';
import CarouselDate from '../../Carousels/CarouselDate';

interface ICardDate {
  months: IOption[];
  years: IOption[];
  currentIndex: number;
  daysOfMonth: IDayOfMonth[][];
  daySelected: IDayOfMonth;
  onHandleSelect(value: IDayOfMonth): void;
  onHandleChangeMonth(value: never): void;
  onHandleChangeYear(value: never): void;
  onHandlePrev(): void;
  onHandleNext(): void;
  onSetShowModaAddlTask(value: boolean): void;
}

const CardDate: React.FC<ICardDate> = ({
  months,
  years,
  currentIndex,
  daysOfMonth,
  daySelected,
  onHandleSelect,
  onHandleChangeMonth,
  onHandleChangeYear,
  onHandlePrev,
  onHandleNext,
  onSetShowModaAddlTask,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h2 className="h4 fw-bold">Tarefas</h2>
        </div>
        <div className="col-lg-6 d-flex justify-content-end">
          <button
            type="button"
            className="fw-bold new-task border-0 text-primary bg-transparent flex flex-row flex-nowrap items-center"
            onClick={() => onSetShowModaAddlTask(true)}
          >
            Nova Tarefa
            <BsPlus size={24} color="##39A887" className="" />
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="d-flex">
            <label className="me-2">
              <Select
                name="mouth"
                options={months}
                onChange={onHandleChangeMonth}
                className="border-0 fw-semibold period-task"
              />
            </label>
            <label className="me-2">
              <Select
                name="year"
                options={years}
                onChange={onHandleChangeYear}
                className="border-0 fw-semibold period-task"
              />
            </label>
          </div>
        </Form>
      </div>
      <div className="row mt-4">
        <CarouselDate
          currentIndex={currentIndex}
          daysOfMonth={daysOfMonth}
          daySelected={daySelected}
          onHandleSelect={onHandleSelect}
        />
      </div>
      <div className="col-12 mb-4 mt-3 d-flex justify-content-end">
        <button
          type="button"
          className="border-0 ms-3 bg-transparent"
          onClick={onHandlePrev}
        >
          <IoIosArrowBack size={22} color="#707070" />
        </button>
        <button
          type="button"
          className="border-0 ms-3 bg-transparent"
          onClick={onHandleNext}
        >
          <IoIosArrowForward size={22} color="#707070" />
        </button>
      </div>
    </>
  );
};

export default CardDate;
