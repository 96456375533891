import styled from 'styled-components';

interface IBar {
  percent: number;
}

export const Bar = styled.div<IBar>`
  background: #e1fcf4;
  border-radius: 38px;
  height: 25px;
  position: relative;
  overflow: hidden;

  div {
    transition-duration: 0.3s;
    border-radius: 38px;
    width: ${(props) => (props.percent >= 100 ? 100 : props.percent)}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00cc9d;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
`;

export const Container = styled.div`
  hr {
    margin-top: 2%;
    height: 1px;
  }

  .button-box {
    display: flex;
    justify-content: end;
    margin-top: 3rem;
  }

  .new-button {
    color: #414142;
    button {
      border: none;
      background-color: #414142;
      border-radius: 20px;
      height: 54px;
      width: 274px;
      color: #ffffff;
      left: 72%;
      top: 43%;
    }
  }

  .box-bar-progress {
    margin-top: 3%;
    padding: 0px 2%;
  }
`;

export const CardBoxInformationsSub = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .container_dashboard_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
  }

  .card-dashboard {
    flex-direction: column;
    width: 33%;
    height: 250px;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f2f2f2;
    box-shadow: 4px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 21px;
    @media (max-width: 1440px) {
      height: 280px;
    }
    @media (max-width: 1290px) {
      height: 300px;
    }
    @media (max-width: 1216px) {
      height: 320px;
    }
    @media (max-width: 1059px) {
      width: 60%;
      height: auto;
    }
    @media (max-width: 768px) {
      width: 80%;
    }
    @media (max-width: 563px) {
      width: 100%;
    }
  }
`;
